import React, { PureComponent } from 'react';
import MUIDataTable from "mui-datatables";
import PropTypes from 'prop-types';
import * as actions from '../../../Store/actions/index';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import Modals from './modals/modals'
import { Button, Spinner } from 'reactstrap';
import { Icon } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment-timezone';
import { isValidNumber } from 'libphonenumber-js';
//Dont remove ChangeAppLanguage
import ChangeAppLanguage from '../Layout/ChangeAppLanguage';
import * as ChangeAppLanguageFront from '../Layout/ChangeAppLanguageFront';

let tlang  = ChangeAppLanguageFront.translateLanguage;

moment.tz.setDefault('Europe/Paris');

const userlist_label_staff = tlang('userlist_label_staff') || 'Plan History';
const user_request_label_staff = tlang('user_request_label_staff') || 'Plan History';
const user_detail_label_staff = tlang('user_detail_label_staff') || 'Plan History';
const user_accept_decline_label_staff = tlang('user_accept_decline_label_staff') || 'Plan History';
const user_add_label_staff = tlang('user_add_label_staff') || 'Plan History';
const user_plan_label_staff = tlang('user_plan_label_staff') || 'Plan History';
const user_language_label_staff = tlang('user_language_label_staff') || 'Plan History';
//const user_staff_label_staff = tlang('user_staff_label_staff') || 'Plan History';
const user_view_staff_detail_label_staff = tlang('user_view_staff_detail_label_staff') || 'Plan History';
const user_update_staff_label_staff = tlang('user_update_staff_label_staff') || 'Plan History';
const user_block_staff_label = tlang('user_block_staff_label') || 'Plan History';
const user_delete_staff_label = tlang('user_delete_staff_label') || 'Plan History';
const user_add_staff_label = tlang('user_add_staff_label') || 'Plan History';
const user_masters_staff_label = tlang('user_masters_staff_label') || 'Plan History';
const user_project_staff_label = tlang('user_project_staff_label') || 'Plan History';
const adminmenu_licenceRequest = tlang('adminmenu_licenceRequest') || 'Plan History';
const adminmenu_planRequest = tlang('adminmenu_planRequest') || 'Plan History';
const adminmenu_projectToken = tlang('adminmenu_projectToken') || 'Plan History';
const adminmenu_send_notification = tlang('adminmenu_send_notification') || 'Plan History';
const adminmenu_email_management = tlang('adminmenu_email_management') || 'Plan History';
const adminmenu_notification_management = tlang('adminmenu_notification_management') || 'Plan History';
const STAFFLIST_SRNO_KEY = tlang('staff_list_srno') || 'Sr.No';
const STAFFLIST_EMPID_KEY = tlang('staff_list_emp_id') || 'Employee Id';
const STAFFLIST_EMP_KEY = tlang('staff_list_employee') || 'Name of employee';
const STAFFLIST_EMAIL_KEY = tlang('staff_list_email') || 'Email id';
const STAFFLIST_PHONENUM_KEY = tlang('staff_list_phone_number') || 'Phone';
const STAFFLIST_LASTLOGIN_KEY = tlang('staff_list_last_login') || 'Last Log in';
const STAFFLIST_STATUS_KEY = tlang('staff_list_status') || 'Status';
const STAFFLIST_ACTIONS_KEY = tlang('staff_list_actions') || 'Actions';
const sort_text = tlang('sort_text') || 'Sort';
const rows_per_page_text = tlang('rows_per_page_text') || 'Rows per page:';
const no_match_record_found = tlang('no_match_record_found') || 'Sorry, no matching records found';
const search_text = tlang('search_text') || 'Search';
const staff_adduser_btn = tlang('staff_adduser_btn') || 'Add User';
const tooltip_view = tlang('tooltip_view') || 'View';
const tooltip_edit = tlang('tooltip_edit') || 'Edit';
const tooltip_delete = tlang('tooltip_delete') || 'Delete';
const block_btn = tlang('block_btn') || 'Block';
const unblock_btn = tlang('unblock_btn') || 'Unblock';
const user_staff_label_staff = tlang('user_staff_label_staff') || 'Staff';


// Staff managemnet component
class Staff extends PureComponent {

    static propTypes = {
        i18n: PropTypes.shape({ changeLanguage: PropTypes.func }).isRequired,
        t: PropTypes.func.isRequired
    };

    // initial state
    constructor(props) {
        super(props);

        this.state = {
            staffList: [],
            btnColor: 'danger',
            openModal: false,
            deleteModal: false,
            deleteUser: [],
            isUpdate: false,
            staffModalData: {
                firstname: "",
                lastname: "",
                email: "",
                phone_number: "",
                accesspages: null,
                error: null
            },
            error: [],
            accessList: {
                user_list: {
                    //page: 'User List',
                    page: userlist_label_staff,
                    status: false,
                    sub: []
                },
                user_request: {
                    page: user_request_label_staff,
                    status: false,
                    sub: [
                        { page: user_detail_label_staff, key: 'view_user' },
                        { page: user_accept_decline_label_staff, key: 'user_accept' },
                        { page: user_add_label_staff, key: 'add_user' },
                    ]
                },
                // plan: {
                //     page: user_plan_label_staff,   
                //     status: false,
                //     sub: []
                // },
                language: {
                    page: user_language_label_staff,
                    status: false,
                    sub: []
                },
                staff: {
                    page: user_staff_label_staff,
                    status: false,
                    sub: [
                        { page: user_view_staff_detail_label_staff, key: 'view_staff' },
                        { page: user_update_staff_label_staff, key: 'update_staff' },
                        { page: user_block_staff_label, key: 'block_staff' },
                        { page: user_delete_staff_label, key: 'delete_staff' },
                        { page: user_add_staff_label, key: 'add_staff' },
                    ]
                },
                masters: {
                    page: user_masters_staff_label,
                    status: false,
                    sub: []
                },
                project: {
                    page: user_project_staff_label,
                    status: false,
                    sub: []
                }
                ,licence_request:{
                    page: adminmenu_licenceRequest,
                    status: false,
                    sub: []
                }
                ,plan_request:{
                    page: adminmenu_planRequest,
                    status: false,
                    sub: []
                }
                ,project_token:{
                    page: adminmenu_projectToken,
                    status: false,
                    sub: []
                }
                ,send_notification:{
                    page: adminmenu_send_notification,
                    status: false,
                    sub: []
                }
            },
            loading: false,
            userAccess: {},
        };
    }

    componentDidMount() {
        //call initial actions
        this.props.getStaffList(this.props.apolloClient.client, [])
        this.setState({
            loading: true
        })
    }

    componentWillReceiveProps(props) {

        this.setState({
            userAccess: props.authReducer.userData.accesspages.staff
        })

        //will recieve staff list
        if (props.staffReducer.staffList) {
            this.setState({
                staffList: props.staffReducer.staffList
            })
        }

        // will recieve notifications data
        if (props.notification) {
            this.setState({
                loading: false
            })
            if (props.notification.success) {
                this.resetStaffData();
                this.setState({
                    openModal: false,
                    deleteModal: false
                })
            }
        }

    }

    //reset state data
    resetStaffData = () => {
        this.setState({
            openModal: false,
            deleteModal: false,
            staffModalData: {
                firstname: "",
                lastname: "",
                email: "",
                accesspages: {
                    user_list: {
                        status: false,
                        sub: {}
                    },
                    user_request: {
                        status: false,
                        sub: {
                            view_user: false,
                            user_accept: false,
                            add_user: false
                        }
                    },
                    // plan: {
                    //     status: false,
                    //     sub: {}
                    // },
                    language: {
                        status: false,
                        sub: {}
                    },
                    staff: {
                        status: false,
                        sub: {
                            view_staff: false,
                            update_staff: false,
                            block_staff: false,
                            delete_staff: false,
                            add_staff: false
                        },

                    },
                    masters: {
                        status: false,
                        sub: {}
                    },
                    project: {
                        status: false,
                        sub: {}
                    }
                    ,licence_request: {
                        status: false,
                        sub: {}
                    }
                    ,plan_request: {
                        status: false,
                        sub: {}
                    }
                    ,project_token: {
                        status: false,
                        sub: {}
                    }
                    ,send_notification: {
                        status: false,
                        sub: {}
                    }
                    // ,email_management: {
                    //     status: false,
                    //     sub: {}
                    // }
                    // ,notification_management: {
                    //     status: false,
                    //     sub: {}
                    // }
                }
            },
            blockModal: false,
            blockUser: null,
            viewModal: false
        })
    }

    // handle state change functions
    handleChange = (name) => event => {
        this.setState({
            staffModalData: {
                ...this.state.staffModalData,
                [name]: event.target.value
            }
        })
    }

    // validate update and create form
    validateForm = () => {
        let error = [];

        if (!this.state.staffModalData.firstname && this.state.staffModalData.firstname == '') {
            error.push('firstname')
        }
        if (!this.state.staffModalData.lastname && this.state.staffModalData.lastname == '') {
            error.push('lastname')
        }
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!this.state.staffModalData.email || this.state.staffModalData.email == '' || !re.test(String(this.state.staffModalData.email).toLowerCase())) {
            error.push('email')
        }
        if (!this.state.staffModalData.email && this.state.staffModalData.email == '') {
            error.push('email')
        }
        if (!this.state.staffModalData.phone_number || !isValidNumber(this.state.staffModalData.phone_number)) {
            error.push('phone_number')
        }
        this.setState({
            error: error
        })

        return error;
    }

    // this will handle assign or decline the access page
    handleAccessPage = (evt) => {
        let checked = evt.target.checked;
        let value = false;
        if (checked) {
            value = true;
        }

        let sub;
        if (value == false && this.state.staffModalData.accesspages[evt.target.value].sub) {
            sub = {};
            Object.keys(this.state.staffModalData.accesspages[evt.target.value].sub).map(function (key) {
                sub[key] = false;
            })
        }

        this.setState({
            staffModalData: {
                ...this.state.staffModalData,
                accesspages: {
                    ...this.state.staffModalData.accesspages,
                    [evt.target.value]: {
                        ...this.state.staffModalData.accesspages[evt.target.value],
                        status: value,
                        //sub: sub ? sub : this.state.staffModalData.accesspages[evt.target.value].sub
                        sub: this.state.staffModalData.accesspages[evt.target.value] == undefined  ? sub : this.state.staffModalData.accesspages[evt.target.value].sub
                    }
                }
            },
        })
    }

    handleAccessSubPage = (evt, key) => {
        let checked = evt.target.checked;
        let value = false;
        if (checked) {
            value = true;
        }

        this.setState({
            staffModalData: {
                ...this.state.staffModalData,
                accesspages: {
                    ...this.state.staffModalData.accesspages,
                    [key]: {
                        ...this.state.staffModalData.accesspages[key],
                        sub: {
                            ...this.state.staffModalData.accesspages[key].sub,
                            [evt.target.value]: value
                        }
                    }
                }
            },
        })
    }

    // call create user action
    createUser = () => {
        let error = this.validateForm();
        if (error.length == 0) {
            let request = {
                ...this.state.staffModalData,
            }
            this.props.createStaffUser(this.props.apolloClient.client, request);
            this.setState({
                loading: true
            })
        }
    }

    // will call update user action
    updateUser = () => {
        let error = this.validateForm()

        if (error.length == 0) {
            let request = {
                ...this.state.staffModalData,
                action_type : "staff_update"
            }

            this.props.updateStaffUser(this.props.apolloClient.client, request)

            this.setState({
                loading: true
            })
        }
    }

    //toggle delete user alert modal
    toggleDeleteUser = (val) => {
        this.setState({
            deleteModal: !this.state.deleteModal,
            deleteUser: val ? val : null
        })
    }

    //toggle block user alert modal
    toggleBlockUser = (val) => {
        if (val) {
            this.setState({
                blockModal: !this.state.blockModal,
                blockUser: val ? val : null,
                btnColor: val.active ? 'danger' : 'success'
            })
        }else{
            this.setState({
                blockModal: !this.state.blockModal,
                blockUser: val ? val : null
            })
        }
    }

    //will calll delete user action
    deleteUser = () => {
        this.props.deleteStaffUser(this.props.apolloClient.client, this.state.deleteUser)
        this.toggleDeleteUser()
        this.setState({
            loading: true
        })
    }

    //will call block user action
    blockUser = () => {
        this.props.blockStaffUser(this.props.apolloClient.client, this.state.blockUser)
        this.toggleBlockUser()
        this.setState({
            loading: true
        })
    }

    //set value and open mdoal for staff user
    openCreateUpdateModal = (val) => {
        this.setState({
            error: [],
            isUpdate: true,
            staffModalData: val,
            openModal: !this.state.openModal
        })
    }

    //set value and open mdoal for staff user
    toggleCreateUpdateModal = () => {
        this.resetStaffData();
        this.setState({
            error: [],
            isUpdate: false,
            openModal: !this.state.openModal
        })
    }

    //reder formated data for mui table
    fetchTableData = (data) => {
        let renderData = [];
        data.map((val, index) => {
            renderData.push([
                Math.abs(index + 1),
                val.employee_id,
                val.firstname + ' ' + val.lastname,
                val.email,
                val.phone_number,
                val.last_login ? moment.unix(val.last_login / 1000).format("DD/MM/YYYY HH:mm") : '-',
                //val.active ? <span className="text-success">Active</span> : <span className="text-danger">Blocked</span>,
                <>
                    {val.active  ? <span className={"statusOpen"}>Active</span> : <span className={"statusClose"}>Blocked</span>}
                </>,
                val.active ? "Active" : "Blocked",
                val
            ])
            return null;
        })
        return renderData
    }

    // toggle details view
    toggleView = (value) => {
        this.setState({
            viewData: value,
            viewModal: !this.state.viewModal
        })
    }

    //handle phone number and store in state
    handlePhoneChange = phoneNo => {
        this.setState({
            staffModalData: {
                ...this.state.staffModalData,
                phone_number: phoneNo
            }
        })

    };

    //redner component
    render() {
        const { t } = this.props;
        
        const columns = [
            {name: STAFFLIST_SRNO_KEY,
                options: {
                    filter: false,  
                },
            },
            {name: STAFFLIST_EMPID_KEY,
                options: {
                    filter: false,  
                },
            },
            {name: STAFFLIST_EMP_KEY,
                options: {
                    filter: false,  
                },
            },
            {name: STAFFLIST_EMAIL_KEY,
                options: {
                    filter: false,  
                },
            },
            {name: STAFFLIST_PHONENUM_KEY,
                options: {
                    filter: false,  
                },
            },
            {name: STAFFLIST_LASTLOGIN_KEY,
                options: {
                    filter: false,  
                },
            },
            {name: STAFFLIST_STATUS_KEY,
                options: {
                    filter: false,  
                    filterList: [],
                },
            },
            {name: STAFFLIST_STATUS_KEY,
                options: {
                    filter: true,  
                    display: false,
                    filterList: [],
                },
            },
            {
                name: STAFFLIST_ACTIONS_KEY,
                options: {
                    filter: false,
                    customBodyRender: (value) => {
                        if (value) {
                            return (<div className="min__width_150">
                                {this.state.userAccess.sub && this.state.userAccess.sub.view_staff &&
                                    <Button className={"system-btn clear-border"} outline={true} color={'success'} onClick={() => this.toggleView(value)}><Tooltip title={tooltip_view}><Icon>remove_red_eye</Icon></Tooltip></Button>
                                }
                                {this.state.userAccess.sub && this.state.userAccess.sub.update_staff &&
                                    <Button className={"system-btn clear-border"} outline={true} color={'success'} onClick={() => this.openCreateUpdateModal(value)}><Tooltip title={tooltip_edit}><Icon>edit</Icon></Tooltip></Button>
                                }
                                {this.props.authReducer.userData.email !== value.email &&
                                    <>
                                        {this.state.userAccess.sub && this.state.userAccess.sub.block_staff &&
                                            <Button className={"system-btn clear-border "+(value.active ?"":"text-primary")} outline={true} color={value.active ? 'success' : 'primary'} onClick={() => this.toggleBlockUser(value)}><Tooltip title={value.active ? block_btn : unblock_btn}><Icon>block</Icon></Tooltip></Button>
                                        }
                                        {this.state.userAccess.sub && this.state.userAccess.sub.delete_staff &&
                                            <Button className={"system-btn clear-border"} outline={true} color={'success'} onClick={() => this.toggleDeleteUser(value)}><Tooltip title={tooltip_delete}><Icon>delete</Icon></Tooltip></Button>
                                        }
                                    </>
                                }
                            </div>
                            );
                        }
                        return '';
                    }
                }
            },
        ];

        const options = {
            filterType: 'dropdown',
            selectableRows: "none",
            print: false,
            download: false,
            viewColumns: false,
            filter: true,
            search: true,
            responsive: "stacked",
            textLabels: {
                body: {
                  noMatch: no_match_record_found,
                  toolTip: sort_text
                },
                pagination: {
                  rowsPerPage: rows_per_page_text,
                },
                toolbar: {
                  search: search_text
                },
            },
            customToolbar: () => {
                if (this.state.userAccess.sub && this.state.userAccess.sub.add_staff) {
                    return (
                        <React.Fragment>
                            <Tooltip title={staff_adduser_btn}><Button className={"add-new-btn"} outline={true} color={'success'} onClick={() => this.toggleCreateUpdateModal()}><Icon>person_add</Icon></Button></Tooltip>
                        </React.Fragment>
                    );
                } else {
                    return '';
                }
            }
        };

        return (
            <div>
                <h3 className="page-title" style={{ marginBottom: "20px" }}>
                  {user_staff_label_staff}
                </h3>
                <div className="div-spinner">{this.state.loading && <Spinner size="500" className="success" />}</div>
                {!this.state.loading && <MUIDataTable
                    data={this.fetchTableData(this.state.staffList)}
                    columns={columns}
                    options={options}
                />}
                {this.state.openModal &&
                    Modals.createUpdateStaff(this)
                }
                {this.state.deleteModal &&
                    Modals.deleteStaffUser(this)
                }
                {this.state.blockModal &&
                    Modals.blockStaffUser(this)
                }
                {this.state.viewModal &&
                    Modals.viewStaffDetails(this)
                }
            </div>
        );
    }
}


Staff.propTypes = {
    t: PropTypes.func.isRequired,
};

//load reducers
const mapStateToProps = state => {
    return {
        authReducer: state.authReducer,
        apolloClient: state.apolloClient,
        staffReducer: state.staffReducer,
        notification: state.notification,
    }
}

//Load actions
const mapDispatchToProps = dispatch => {
    return {
        getStaffList: (client, request) => { dispatch(actions.getStaffList(client, request)) },
        createStaffUser: (client, request) => { dispatch(actions.createStaffUser(client, request)) },
        updateStaffUser: (client, request) => { dispatch(actions.updateStaffUser(client, request)) },
        deleteStaffUser: (client, request) => { dispatch(actions.deleteStaffUser(client, request)) },
        blockStaffUser: (client, request) => { dispatch(actions.blockStaffUser(client, request)) },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Staff)); // export component
