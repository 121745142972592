import React from 'react';
import { Switch, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function TwoLabelsSwitch({
	options,
	value,
	onChange,
	fullWidth = true,
	style = {},
}) {
	if (!Array.isArray(options) || options.length !== 2) {
		console.error('TwoLabelsSwitch : options must be an array of two elements {value, label}');
		return null;
	}
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				gap: '0.25rem',
				alignItems: 'center',
				width: fullWidth ? '100%' : 'auto',
				justifyContent: 'center',
				marginBlock: '0.5rem',
				cursor: 'pointer',
				...style,
			}}
		>
			<Typography style={{ color: 'var(--clr-aexdo)' }} onClick={() => onChange(options[0].value)}>
				{options[0].label}
			</Typography>
			<Switch
				checked={value === options[1].value}
				onChange={event => onChange(options[event.target.checked ? 1 : 0].value)}
				color="secondary"
			/>
			<Typography style={{ color: 'var(--clr-aexdo)' }} onClick={() => onChange(options[1].value)}>
				{options[1].label}
			</Typography>
		</div>
	);
}

TwoLabelsSwitch.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.any,
			label: PropTypes.string,
		})
	),
	value: PropTypes.any.isRequired,
	onChange: PropTypes.func.isRequired,
};
