import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon, Tooltip } from '@material-ui/core';
import Lightbox from 'react-images';
import {
	Button,
	Col,
} from 'reactstrap';
import * as ChangeAppLanguageFront from '../../../Layout/ChangeAppLanguageFront';

const reLast = /-\d+$/;
const tlang = ChangeAppLanguageFront.translateLanguage;

const project_documents = tlang('project_documents') || 'Documents';
const tooltip_delete = tlang('tooltip_delete') || 'Delete';
const no_documents_found = tlang('no_documents_found') || 'Sorry, no documents found';

export default function UploadedDocuments({ documents, onDeleteDoc }) {
	const [lightBoxUrl, setLightBoxUrl] = useState('');

	function openInNewTab(url) {
		const link = document.createElement("a");
		link.href = url;
		link.target = "_blank";
		link.rel = "noreferrer";
		link.click();
	}

	function getImage(docType, docExt) {
		switch (docType) {
			case 'pdf':
				return '/img/pdf.svg';
			case 'excel':
				return '/img/excel.svg';
			default:
				if (docExt === 'pptx' || docExt === 'ppt')
					return '/img/ppt.svg';
				else
					return '/img/doc.svg';
		}
	}

	function DocumentComponent({ docType }) {
		return (
			<div className="documents">
				{documents[docType].map((doc, idx) => (
					<div className="document">
						<p>
							{docType === 'images' ? (
								<>
									<button onClick={() => setLightBoxUrl(doc.url)}>
										<Tooltip title={`${doc.displayName}`}>
											<img src={doc.url} alt="" />
										</Tooltip>
									</button>
									<Lightbox
										images={[{ src: lightBoxUrl }]}
										isOpen={lightBoxUrl !== ''}
										showImageCount={false}
										backdropClosesModal={true}
										onClose={() => setLightBoxUrl('')}
										width={1100}
									/>
								</>
							)
								: (
									<Tooltip title={`${doc.displayName}`}>
										<img className='imgDoc' onClick={() => openInNewTab(doc.url)} src={getImage(docType, doc.ext)} alt="Image" />
									</Tooltip>
								)}
							<div className={docType === 'images' ? "button" : "button1"}>
								<Tooltip title={tooltip_delete}>
									<Button
										className={'system-btn clear-border'}
										outline={true}
										color={'success'}
										onClick={() => onDeleteDoc({ type: 'deleteDocs', docType: docType, idx })}
										disabled={false}
									>
										<Icon>delete</Icon>
									</Button>
								</Tooltip>
							</div>
						</p>
					</div>
				))}
			</div>
		);
	}

	return (
		<>
			<Col xs={12} className="margin__top">
				<h4 style={{ color: 'var(--clr-aexdo)', fontWeight: 700 }}>
					{project_documents}
				</h4>
			</Col>
			<DocumentsContainer>
				{(!documents.images?.length && !documents.pdf?.length && !documents.excel?.length && !documents.other?.length) ? (
					<Col xs={12}>
						<p className='message'>{no_documents_found}</p>
					</Col>
				) : (
					<>
						{documents.images?.length ? (
							<DocumentComponent
								docType='images'
							/>
						) : null}
						{documents.pdf?.length ? (
							<DocumentComponent
								docType='pdf'
							/>
						) : null}
						{documents.excel?.length ? (
							<DocumentComponent
								docType='excel'
							/>
						) : null}
						{documents.other?.length ? (
							<DocumentComponent
								docType='other'
							/>
						) : null}
					</>
				)}
			</DocumentsContainer>
		</>
	);
}

const iconList = { pdf: 'picture_as_pdf', excel: 'explicit', other: 'article' }

const DocumentsContainer = styled.div`
	padding: 0.5rem;
	display: flex;
	flex-direction: row;
	gap: 5px;
	flex-wrap: wrap;
	.message {
		text-align: center;
		width: 100%;
		color: var(--clr-aexdo);
		font-size: 1rem;
	}
	.documents {
		display: contents;
	}
	.document {
		position: relative;
		display: flex;
		flex-direction: column;
		margin: 10px;
		justify-content: space-between;
		p {
			margin: 0;
		}
		p:first-child {
			font-weight: 700;
			color: var(--clr-aexdo);
		}
		p:last-child {
			font-size: 1.2em;
		}
		button {
			border: none;
			background: none;
		}
		img {
			min-height: 70px;
			width: 120px;
			height: 70px;
			border: 'solid 1px #dadada';
			z-index: 1;
			margin-inline-end: 2px;
		}
		.imgDoc {
			width: auto;
			margin-inline-end: 15px;
			cursor: pointer;
		}
		.button {
			position: absolute;
			right: 0.6em;
			bottom: 0.2em;
			button {
				background-color: rgba(255, 255, 255, 0.4);
			}
		}
		.button1 {
			position: absolute;
			right: -0.2em;
			bottom: 0.1em;
		}
	}
`;

