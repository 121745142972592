import React, { PureComponent } from "react";
import MUIDataTable from "mui-datatables";
import Modals from "../modals/modals";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import * as actions from "../../../../Store/actions/index";
import { Icon } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { Card, CardBody, Col, Button, Spinner } from "reactstrap";
import moment from "moment-timezone";
import { gql } from "apollo-boost";
import { Encrypt } from "../../../../Store/shared/encrpt";
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';

moment.tz.setDefault('Europe/Paris');

//start language
let tlang = ChangeAppLanguageFront.translateLanguage;

const USERREQUEST_SRNO_KEY = tlang('user_request_srno') || 'Sr.No';
const USERREQUEST_CMPNAME_KEY = tlang('user_request_company_name') || 'Name of Company';
const USERREQUEST_EMAIL_KEY = tlang('user_request_email') || 'Email';
const USERREQUEST_CMPADDRESS_KEY = tlang('user_request_company_address') || 'Address';
const USERREQUEST_NUMBER_KEY = tlang('user_request_number') || 'Number';
const USERREQUEST_NAME_KEY = tlang('user_request_actmngrname') || 'Account Manager';
const USERREQUEST_COUNTRY_KEY = tlang('user_request_country') || 'Country';
const USERREQUEST_CREATEDON_KEY = tlang('user_request_created_on') || 'Requested Time';
const USERREQUEST_STATUS_KEY = tlang('user_request_status') || 'Status';
const USERREQUEST_ACTION_KEY = tlang('user_request_actions') || 'Actions';
const sort_text = tlang('sort_text') || 'Sort';
const rows_per_page_text = tlang('rows_per_page_text') || 'Rows per page:';
const no_match_record_found = tlang('no_match_record_found') || 'Sorry, no matching records found';
const search_text = tlang('search_text') || 'Search';
const user_request_view_btn = tlang('user_request_view_btn') || 'View';
const block_btn = tlang('block_btn') || 'Block';
const unblock_btn = tlang('unblock_btn') || 'Unblock';
const common_company_list = tlang('common_company_list') || 'Company List';
//end

// this will manage the request of new clients.
class RequestList extends PureComponent {
  static propTypes = {
    i18n: PropTypes.shape({ changeLanguage: PropTypes.func }).isRequired,
    t: PropTypes.func.isRequired,
  };

  /* Load init states */
  constructor() {
    super();
    this.state = {
      btnColor: "danger",
      activeTab: "1",
      requestList: [],
      error: [],
      is_error: false,
      loading: false,
      requestData: [],
      company_profile: "",
      openBlockModal: false,
      pageno: 0,
      limit: 10,
      totalrecord: 0,
      searchtext: "",
      sortcolumn: "created_at",
      sorttype: "asc",
    };
  }

  componentDidMount() {
    /* Load init actions */
    let request = {
      status: "",
      confirm: true,
      pageno: this.state.pageno,
      limit: this.state.limit,
      searchtext: "",
      sortcolumn: this.state.sortcolumn,
      sorttype: this.state.sorttype,
    };
    /* get new users */
    this.getNewUserRequest(this.props.apolloClient.client, request);
    this.setState({
      loading: true,
    });
  }

  componentWillReceiveProps(props) {
    this.setState({
      userAccess: props.authReducer.userData.accesspages.user_request,
    });

    /* recive users pending request */
    if (props.userReducer.requestList) {
      this.setState({
          requestList: props.userReducer.requestList,
        },() => this.forceUpdate());
    }

    /* receive details */
    if (props.userReducer.requestDetails) {
      let profile = JSON.parse(props.userReducer.requestDetails.attachments);
      let companyProfilePic = "";
      const latestCmpLogoRec = profile[profile.length - 1];
      companyProfilePic = Object.values(latestCmpLogoRec)[0]

      if (companyProfilePic) {
        this.setState({
          company_profile: companyProfilePic
        });
      }
    }

    /* receive notification */
    if (props.notification) {
      this.setState({
        loading: false,
      });
      if (props.notification.success) {
        this.setState({
          openBlockModal: false,
        });
      }
    }
  }

  /* toggle Block Modal */
  toggleBlockModal = (value) => {
    if (value) {
      this.setState({
        openBlockModal: !this.state.openBlockModal,
        requestDetail: value,
        btnColor: value.account_status ? "danger" : "success",
      });
    } else {
      this.setState({
        openBlockModal: !this.state.openBlockModal,
        requestDetail: value,
      });
    }
  };

  /* block Account*/
  blockAccount = () => {
    let request = {
      account_status: !this.state.requestDetail.account_status,
      companyname: this.state.requestDetail.company_name,
    };
    this.blockUserAccount(this.props.apolloClient.client, request);
    this.setState({
      loading: true,
    });
  };

  /* toggle request form */
  toggleRequestDetailModal = (value) => {

    if (value && value.status == "approve") {
      let request = {
        email: value.email,
        companyname: value.company_name,
      };
      this.props.getUserRequestDetailAdmin(
        this.props.apolloClient.client,
        request
      );
    }
    this.setState({
      openRequestDetail: !this.state.openRequestDetail,
      requestDetail: value,
    });
  };

  /* render table data */
  rendertableData = (data) => {
    let renderData = [];
    data.map((val, index) => {
      const date = moment(new Date(val.created_at) / 1000).format(
        "DD/MM/YYYY HH:mm"
      );
      var dateToFormat = moment(date, "DD/MM/YYYY HH:mm", true).isValid();
      renderData.push([
        // Math.abs(index + 1),
        this.state.pageno == 0 ? index + 1 : this.state.pageno * 10 + index + 1,
        val.display_name,
        val.email,
        val.company_address,
        val.contact_number,
        val.name,
        val.registration_country,
        dateToFormat
          ? moment
            .unix(new Date(val.created_at) / 1000)
            .format("DD/MM/YYYY HH:mm")
          : moment.unix(val.created_at / 1000).format("DD/MM/YYYY HH:mm"),
        <>
          {val.account_status  ? <span className={"statusOpen"}>Active</span> : <span className={"statusDeleted"}>Blocked</span>}
        </>,
        val.account_status ? "Active" : "Blocked",
        val,
      ]);
      return null;
    });
    return renderData;
  };

  /* render Invite Users */
  renderInviteUsers = () => {
    let renderData = [];
    if (this.props.userReducer.requestDetails) {
      this.props.userReducer.requestDetails.memberusers.map((val, index) => {
        let rolename = "-";
        if (val.other_company == false) {
          rolename = val.role_name;
        }
        renderData.push([
          Math.abs(index + 1),
          val.email,
          val.name,
          rolename
        ]);
        return null;
      });
    }
    return renderData;
  };

  getTotalUnUsedLicense = () => {
    let total = 0;
    if (this.props.userReducer.requestDetails) {
      this.props.userReducer.requestDetails.allLicenceList.map((val, index) => {
        if (!val.used && moment() < moment.unix(val.expirydate)) {
        total += 1;
        }
      });
    }
    return total;
  };

  getTotalUsedLicense = () => {
    let total = 0;
    if (this.props.userReducer.requestDetails) {
      this.props.userReducer.requestDetails.allLicenceList.map((val, index) => {
        if (val.used || moment() > moment.unix(val.expirydate)) {
        total += 1;
        }
      });
    }
    return total;
  };

  getTotalUnUsedToken = () => {
    let total = 0;
    if (this.props.userReducer.requestDetails) {
      this.props.userReducer.requestDetails.tokenList.map((val, index) => {
        if (!val.used) {
        total += 1;
        }
      });
    }
    return total;
  };


  getTotalUsedToken = () => {
    let total = 0;
    if (this.props.userReducer.requestDetails) {
      this.props.userReducer.requestDetails.tokenList.map((val, index) => {
        if (val.used) {
        total += 1;
        }
      });
    }
    return total;
  };




  /* get Total License */
  getTotalLicense = () => {
    let total = 0;
    if (this.props.userReducer.requestDetails) {
      this.props.userReducer.requestDetails.licence.map((row) => {
        total += row.count ? row.count : 0;
      });
    }
    return total;
  };

  
  /* get Total Token */
  getTotalToken = () => {
    let total = 0;
    if (this.props.userReducer.requestDetails) {
      if(this.props.userReducer.requestDetails.tokenList) {
      total =  this.props.userReducer.requestDetails.tokenList.length;
      } 
    }
    return total;
  };

  /* get Total Amount */
  getTotalAmount = () => {
    let total = 0;
    if (this.props.userReducer.requestDetails) {
      this.props.userReducer.requestDetails.licence.map((row) => {
        total += row.count ? row.cost * row.count : 0;
      });
    }
    return '$' + total;
  };

  /* render Plans */
  renderPlans = () => {
    let renderData = [];
    if (
      this.props.userReducer.requestDetails &&
      this.props.userReducer.requestDetails.plan
    ) {
      renderData.push([
        1,
        this.props.userReducer.requestDetails.plan.planname,
        this.getTotalLicense(),
        this.getTotalToken(),
        moment
          .unix(this.props.userReducer.requestDetails.plan.purchasedate)
          .format("DD/MM/YYYY HH:mm"),
        moment
          .unix(this.props.userReducer.requestDetails.plan.expirydate)
          .format("DD/MM/YYYY HH:mm"),
        this.getTotalAmount(),
      ]);
    }
    return renderData;
  };

  /* toggle */
  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  /* get user signup request list with pagination */
  getNewUserRequest = (client, request) => {
    if (!request || request.length == 0) {
      request.status = "";
      request.confirm = false;
    }
    new Promise((resolve) => {
      const query = gql`
        query new_get_company_request_list(
          $status: String
          $confirm: Boolean!
          $pageno: Int!
          $limit: Int!
          $searchtext: String
          $sortcolumn: String!
          $sorttype: String!
        ) {
          new_get_company_request_list(
            status: $status
            confirm: $confirm
            pageno: $pageno
            limit: $limit
            searchtext: $searchtext
            sortcolumn: $sortcolumn
            sorttype: $sorttype
          ) {
            message
            error
            success
            data
            totalpage
            totalrecord
            sorttype
            searchtext
            sortcolumn
          }
        }
      `;
      client
        .query({
          query: query,
          fetchPolicy: "no-cache",
          variables: {
            status: request.status,
            confirm: request.confirm,
            pageno: request.pageno,
            limit: request.limit,
            searchtext: request.searchtext,
            sortcolumn: request.sortcolumn,
            sorttype: request.sorttype,
          },
        })
        .then((response) => {
          let tempArray = [];
          let dataNew = response.data.new_get_company_request_list.data;
          dataNew.map((val, index) => {
            tempArray.push(val["document"]);
          });
          this.setState(
            {
              pageno: request.pageno,
              limit: request.limit,
              totalrecord:
                response.data.new_get_company_request_list.totalrecord,
              sortcolumn: request.sortcolumn,
              sorttype: request.sorttype,
            },
            () => this.forceUpdate()
          );
          this.props.getListSuccess(tempArray);
        })
        .catch((error) => {
          this.props.notificationFail(error);
        });
    });
  };

/* block User Account */
  blockUserAccount = (client, request) => {
    let tlang1 = ChangeAppLanguageFront.translateLanguage;
    const UnblockedCmp = tlang1('notificationSuccess_list_UnblockedCmp') || 'Company is Unblocked';
    const blockedCmp = tlang1('notificationSuccess_list_blockedCmp') || 'Company is Blocked';
    new Promise((resolve) => {
      const mutation = gql`
        mutation block_company($account_status: String!, $companyname: String!) {
            block_company(account_status: $account_status, companyname: $companyname) {
              error
              success
              message
            }
          }
        `;
      client.mutate({
        mutation: mutation,
        variables: {
          account_status: Encrypt(request.account_status),
          companyname: Encrypt(request.companyname)
        }
      }).then(response => {
        let requestParam = {
          status: "",
          confirm: true,
          pageno: this.state.pageno,
          limit: this.state.limit,
          searchtext: "",
          sortcolumn: this.state.sortcolumn,
          sorttype: this.state.sorttype,
        };
        this.getNewUserRequest(client, requestParam)
        this.props.notificationSuccess(request.account_status ? UnblockedCmp : blockedCmp);
      }).catch((error) => {
        this.props.notificationFail(error)
      });
    });
  }

  /* change Page */
  changePage = (page, limit, searchtext, sortcolumn, sorttype) => {
    let request = {
      status: "",
      confirm: true,
      pageno: page,
      limit: limit,
      searchtext: searchtext,
      sortcolumn: sortcolumn,
      sorttype: sorttype,
    };
    this.setState({
        searchtext: searchtext,
        sortcolumn: sortcolumn,
        sorttype:sorttype
      },() => this.forceUpdate());
    this.getNewUserRequest(this.props.apolloClient.client, request);
  };

  /* render component */
  render() {
    const { t } = this.props;
    const state = this.state;

    // console.log(state.sortcolumn,state.sorttype)
    const columns = [
      {
        name: USERREQUEST_SRNO_KEY,
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "Name of Company",
        label: USERREQUEST_CMPNAME_KEY,
        options:
          state.sortcolumn == "display_name"
            ? {
              filter: false,
              sortDirection: state.sorttype,
            }
            : {
              filter: false,
            },
      },
      {
        name: "Email",
        label: USERREQUEST_EMAIL_KEY,
        options:
          state.sortcolumn == "email"
            ? {
              filter: false,
              sortDirection: state.sorttype,
            }
            : {
              filter: false,
            },
      },
      {
        name: "Address",
        label: USERREQUEST_CMPADDRESS_KEY,
        options:
          state.sortcolumn == "company_address"
            ? {
              filter: false,
              sortDirection: state.sorttype,
            }
            : {
              filter: false,
            },
      },
      {
        name: "Number",
        label: USERREQUEST_NUMBER_KEY,
        options:
          state.sortcolumn == "contact_number"
            ? {
              filter: false,
              sortDirection: state.sorttype,
            }
            : {
              filter: false,
            },
      },
      {
        name: "Account Manager",
        label: USERREQUEST_NAME_KEY,
        options:
          state.sortcolumn == "name"
            ? {
              filter: false,
              sortDirection: state.sorttype,
            }
            : {
              filter: false,
            },
      },
      {
        name: "Country",
        label: USERREQUEST_COUNTRY_KEY,
        options:
          state.sortcolumn == "registration_country"
            ? {
              filter: false,
              sortDirection: state.sorttype,
            }
            : {
              filter: false,
            },
      },
      {
        name: "Requested Time",
        label: USERREQUEST_CREATEDON_KEY,
        options:
          state.sortcolumn == "created_at"
            ? {
              filter: false,
              sortDirection: state.sorttype,
            }
            : {
              filter: false,
            },
      },
      {
        name: "Status",
        label: USERREQUEST_STATUS_KEY,
        options:
          state.sortcolumn == "account_status"
            ? {
              filter: false,
              sortDirection: state.sorttype,
            }
            : {
              filter: false,
            },
      },
      {
        name: "Status",
        label: USERREQUEST_STATUS_KEY,
        options:
          state.sortcolumn == "account_status"
            ? {
              filter: false,
              sortDirection: state.sorttype,
              display: false
            }
            : {
              filter: false,
              display: false
            },
      },
      {
        name: USERREQUEST_ACTION_KEY,
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value) => {
            if (value) {
              if (
                this.state.userAccess.sub &&
                this.state.userAccess.sub.view_user
              ) {
                return (
                  <>
                    <Button
                      className={"system-btn clear-border"}
                      outline={true}
                      color={"success"}
                      onClick={() => this.toggleRequestDetailModal(value)}
                    >
                      {" "}
                      <Tooltip title={user_request_view_btn}>
                        <Icon>remove_red_eye</Icon>
                      </Tooltip>
                    </Button>
                    <Button
                      className={"system-btn clear-border "+(value.account_status ?"":"text-primary")}
                      outline={true}
                      color={value.account_status ? "success" : "success"}
                      onClick={() => this.toggleBlockModal(value)}
                    >
                      {" "}
                      <Tooltip
                        title={
                          value.account_status
                            ? block_btn
                            : unblock_btn
                        }
                      >
                        <Icon>block</Icon>
                      </Tooltip>
                    </Button>
                  </>
                );
              } else {
                return "-";
              }
            }
            return "-";
          },
        },
      },
    ];

    const options = {
      filterType: "dropdown",
      selectableRows: "none",
      print: false,
      download: false,
      viewColumns: false,
      filter: false,
      responsive: "stacked",
      textLabels: {
        body: {
          noMatch: no_match_record_found,
          toolTip: sort_text
        },
        pagination: {
          rowsPerPage: rows_per_page_text,
        },
        toolbar: {
          search: search_text
        },
      },
      serverSide: true,
      limit: state.limit,
      page: state.pageno,
      count: state.totalrecord,
      searchText: state.searchtext,
      rowsPerPage: 10,
      onTableChange: (action, tableState) => {
        let searchtext = tableState.searchText ? tableState.searchText : "";
        let page = tableState.page;
        let rowsPerPage = tableState.rowsPerPage;
        switch (action) {
          case "changeRowsPerPage":
            this.changePage(
              page,
              rowsPerPage,
              searchtext,
              state.sortcolumn,
              state.sorttype
            );
            break;
          case "changePage":
            this.changePage(
              page,
              rowsPerPage,
              searchtext,
              state.sortcolumn,
              state.sorttype
            );
            break;
          case "search":
            this.changePage(
              page,
              rowsPerPage,
              searchtext,
              state.sortcolumn,
              state.sorttype
            );
            break;
        }
      },
      onColumnSortChange: (changedColumn, direction) => {
        let sorttype;
        let sortcolumn;      
        switch (changedColumn) {
          case "Name of Company":
            sorttype = direction === "ascending" ? "asc" : "desc";
            sortcolumn = "display_name";
            this.changePage(
              state.pageno,
              state.limit,
              "",
              sortcolumn,
              sorttype
            );
            break;
          case "Email":
            sorttype = direction === "ascending" ? "asc" : "desc";
            sortcolumn = "email";
            this.changePage(
              state.pageno,
              state.limit,
              "",
              sortcolumn,
              sorttype
            );
            break;
          case "Address":
            sorttype = direction === "ascending" ? "asc" : "desc";
            sortcolumn = "company_address";
            this.changePage(
              state.pageno,
              state.limit,
              "",
              sortcolumn,
              sorttype
            );
            break;
          case "Number":
            sorttype = direction === "ascending" ? "asc" : "desc";
            sortcolumn = "contact_number";
            this.changePage(
              state.pageno,
              state.limit,
              "",
              sortcolumn,
              sorttype
            );
            break;
          case "Account Manager":
            sorttype = direction === "ascending" ? "asc" : "desc";
            sortcolumn = "name";
            this.changePage(
              state.pageno,
              state.limit,
              "",
              sortcolumn,
              sorttype
            );
            break;
          case "Country":
            sorttype = direction === "ascending" ? "asc" : "desc";
            sortcolumn = "registration_country";
            this.changePage(
              state.pageno,
              state.limit,
              "",
              sortcolumn,
              sorttype
            );
            break;
          case "Requested Time":
            sorttype = direction === "ascending" ? "asc" : "desc";
            sortcolumn = "created_at";
            this.changePage(
              state.pageno,
              state.limit,
              "",
              sortcolumn,
              sorttype
            );
            break;
          case "Status":
            sorttype = direction === "ascending" ? "asc" : "desc";
            sortcolumn = "account_status";
            this.changePage(
              state.pageno,
              state.limit,
              "",
              sortcolumn,
              sorttype
            );
            break;
          default:
        }
      },
    };
    return (
      <div>
        <h3 className="page-title" style={{ marginBottom: "20px" }}>
          {common_company_list}
        </h3>
        <div className="div-spinner">
          {this.state.loading && <Spinner size="500" className="success" />}
        </div>
        {!this.state.loading && (
          <MUIDataTable
            data={this.rendertableData(this.state.requestList)}
            columns={columns}
            options={options}
          />
        )}
        {this.state.openRequestDetail &&
          Modals.userRequestDetailAdmin(this)
        }
        {this.state.openBlockModal &&
          Modals.blockAccountModal(this)
        }
      </div>
    );
  }
}

RequestList.propTypes = {
  t: PropTypes.func.isRequired,
};

/* load reducers */
const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer,
    apolloClient: state.apolloClient,
    userReducer: state.userReducer,
    notification: state.notification,
  };
};

/* Load actions */
const mapDispatchToProps = (dispatch) => {
  return {
    getListSuccess: (data) => {
      dispatch(actions.getListSuccess(data));
    },
    notificationFail: (data) => {
      dispatch(actions.notificationFail(data));
    },
    notificationSuccess: (data) => {
      dispatch(actions.notificationSuccess(data));
    },
    signUpRequestAdmin: (client, request) => {
      dispatch(actions.signUpRequestAdmin(client, request));
    },
    requestResult: (client, request) => {
      dispatch(actions.requestResult(client, request));
    },
    getUserRequestDetailAdmin: (client, request) => {
      dispatch(actions.getUserRequestDetailAdmin(client, request));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(RequestList)); // export component
