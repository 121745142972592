import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Select from 'react-select';
// import TextField from '@material-ui/core/TextField';
// import MenuItem from '@material-ui/core/MenuItem';
// import ChangeAppLanguage from './Layout/ChangeAppLanguage';
import FormGroupRequired from './Client/components/formGroupRequired';
import * as actions from '../../Store/actions/index';
import { Spinner, Button, Row, Col, Label } from 'reactstrap';
import Particles from 'react-particles-js';
import { ReactComponent as Logo } from "../../loading.svg";
import * as ChangeAppLanguageFront from './Layout/ChangeAppLanguageFront';

let tlang = ChangeAppLanguageFront.translateLanguage;
const go_to_dashboard = tlang('go_to_dashboard') || 'Go to Dashboard';
const common_company = tlang('common_company') || 'Company';
const common_choose = tlang('common_choose') || 'Choose';
const topbar_link_logout = tlang('topbar_link_logout') || 'Logout';
const please_wait_login_to_admin = tlang('please_wait_login_to_admin') || 'Please Wait, Login to Super Admin';
const please_select_company = tlang('please_select_company') || 'Please select company';
const select_company = tlang('select_company') || 'Select Company';
const select_project = tlang('select_project') || 'Select Project';

// Company slection component login step 2
class Company extends PureComponent {

  static propTypes = {
    i18n: PropTypes.shape({ changeLanguage: PropTypes.func }).isRequired,
    t: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    // init states
    this.state = {
      company: null,
      selectedCompany: [],
      companyList: [],
      projectList: [],
      selectedProject: [],
      companyDisplayNameList: [],
      project: null,
      error: [],
      loading: false,
    };
    this.showPassword = this.showPassword.bind(this);
  }

  componentDidMount() {

    let companyList = JSON.parse(this.props.authReducer.userData.company_name);
    let companyDisplayNameList = JSON.parse(this.props.authReducer.userData.display_name);

    let arr = [];
    companyDisplayNameList.map((l, idx) => {
      arr.push({ label: l, value: companyList[idx] });
    })


    if (companyList.length == 1 && companyList[0] == "superadmin") {
      let req = {
        email: this.props.authReducer.userData.email,
        company: "superadmin",
        projectid: null
      }

      this.props.companyLogIn(this.props.apolloClient.client, req);
    }

    if (this.props.authReducer.userData) {
      this.setState({
        companyList,
        companyDisplayNameList: arr
      })
    }
  }

  componentWillReceiveProps(props) {
    if (props.notification) {
      this.setState({
        loading: false
      })
    }

    //Get all projects of selected company
    if (props.authReducer?.companyList) {

      if (props.authReducer?.companyList.length) {
        props.authReducer.companyList.sort(function compare(a, b) {
          var dateA = new Date(a.startDate);
          var dateB = new Date(b.startDate);
          return dateB - dateA;
        });

        const allProjectList = [...props.authReducer.companyList];
        const projects = allProjectList.map(l => {
          return {
            label: l.projectname,
            value: l.projectid
          }
        });
        this.setState({ projectList: projects });
      } else {
        this.setState({ projectList: [] });
      }
    }


  }

  changeLanguage = (lng) => {
    const { i18n } = this.props;
    i18n.changeLanguage(lng);
  };

  componentWillMount() {
    let languagaeData = localStorage.getItem('language');
    this.changeLanguage(languagaeData);
  }

  showPassword(e) {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  }

  handleChangeCompany = (event) => {
    this.setState({ selectedCompany: event, company: event.value, selectedProject: [], project: null, error: [] });
    const request = {
      companyname: event.value,
      userid: this.props.authReducer.userData.userid,
    }
    this.props.getCompanyProjectList(this.props.apolloClient.client, request);
  }

  handleChangeProject = (event) => {
    this.setState({ selectedProject: event, project: event.value });
  }

  //login to perticular company or super admin section
  logInAccount = () => {

    let error = [];
    if (!this.state.company) {
      error.push('company')
    }

    if (error.length == 0) {
      this.setState({ error: [] })
      let req = {
        email: this.props.authReducer.userData.email,
        company: this.state.company,
        projectid: this.state.project
      }

      this.props.companyLogIn(this.props.apolloClient.client, req);
      this.setState({
        loading: true
      })
    } else {
      this.setState({ error: error })
    }

  }

  logoutUser = () => {
    // localStorage.clear();
    localStorage.removeItem("_user_");
    localStorage.removeItem("_userDetail_");
    window.location = "/signin";
  }


  render() {
    const { error } = this.state;
    const { t } = this.props;

    if (this.state.companyList.length == 1 && this.state.companyList[0] == "superadmin") {
      return (
        <>
          {/* Loader */}
          <div class="load">
            <div class="load__icon-wrap">
              <Logo />
              <div class="loaderMsg">{please_wait_login_to_admin}</div>
            </div>
          </div>
          {/* Loader */}
        </>
      )
    }

    return (
      <div>
        <Particles
          style={{ position: "absolute" }}
          params={{
            "particles": {
              "number": {
                "value": 70
              },
              "size": {
                "value": 5
              }
            },
            "interactivity": {
              "events": {
                "onhover": {
                  "enable": true,
                  "mode": "repulse"
                }
              }
            }
          }}
        />
        <div className="theme-light">
          <div className="wrapper">
            <main>
              <div className="account sign-in-backround">
                <div className="account__wrapper">
                  <div className="account__card sign-in-card">
                    <Link style={{ float: "right" }} className="topbar__logo" to="/" />
                    <div className="account__head">
                      <h3 className="account__title">{common_choose}
                        <span className="account__logo"> {common_company} </span>
                      </h3>
                    </div>
                    <form className="form">
                      <div className="form__form-group mt-2">
                        <div className="">
                          <form className="">
                            <Row>

                              <Col xs={11} sm={11} md={11} className="">
                                <FormGroupRequired>
                                  <Label>{select_company}</Label>
                                  <Select
                                    options={this.state.companyDisplayNameList}
                                    isMulti={false}
                                    name="selCompany"
                                    //placeholder={select_company}
                                    value={this.state.selectedCompany ? this.state.selectedCompany : []}
                                    onChange={(e) => this.handleChangeCompany(e)}
                                    closeMenuOnSelect={true}
                                    className="basic-multi-select"
                                    classNamePrefix="select "
                                    isSearchable={true}
                                  />
                                  {this.state.error && this.state.error.includes("company") && (
                                    <span className="error-message">{please_select_company}</span>
                                  )}
                                </FormGroupRequired>
                              </Col>
                              <Col xs={11} sm={11} md={11} className="margin__bottom">
                                <Label>{select_project}</Label>
                                <Select
                                  options={this.state.projectList}
                                  isMulti={false}
                                  name="selCompany"
                                  //placeholder={select_project}
                                  value={this.state.selectedProject ? this.state.selectedProject : []}
                                  onChange={(e) => this.handleChangeProject(e)}
                                  closeMenuOnSelect={true}
                                  className="basic-multi-select"
                                  classNamePrefix="select "
                                  isSearchable={true}
                                />
                              </Col>

                            </Row>
                          </form>
                        </div>
                      </div>
                      <div className="account__btns">

                        <Button className="btn account__btn system-btn" style={{ marginRight: "5px" }} color="success" outline={true} type="button" onClick={e => this.logoutUser()} disabled={this.state.loading}>
                          {topbar_link_logout}
                        </Button>

                        <button style={{ marginRight: "5px" }} className="btn btn-success account__btn system-btn" type="button" onClick={e => this.logInAccount()} disabled={this.state.loading}>
                          {go_to_dashboard}
                          {this.state.loading ? <Spinner size="sm" style={{ marginLeft: "10px", color: "#fff" }} /> : null}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}

Company.propTypes = {
  t: PropTypes.func.isRequired,
};


//load reducers
const mapStateToProps = state => {
  return {
    authReducer: state.authReducer,
    apolloClient: state.apolloClient,
    notification: state.notification
  }
}

//Load actions
const mapDispatchToProps = dispatch => {
  return {
    companyLogIn: (client, request) => { dispatch(actions.companyLogIn(client, request)) },
    getCompanyProjectList: (client, request) => { dispatch(actions.getCompanyProjectList(client, request)) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Company)); // export component
