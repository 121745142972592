import moment from 'moment-timezone';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Button,
	ButtonToolbar,
	Col,
	ListGroup,
	ListGroupItem,
	Modal,
	Row,
	Spinner,
} from 'reactstrap';
import styled from 'styled-components';
import * as actions from '../../../../Store/actions/index';
import { getMaterialListfilter } from '../../Common/materialList';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import { TodosContainer } from './todo';

moment.tz.setDefault('Europe/Paris');

let tlang = ChangeAppLanguageFront.translateLanguage;
const client_probing_category = tlang('client_probing_category') || 'Category';
const client_probing_lithology = tlang('client_probing_lithology') || 'Lithology';
const client_Moisture = tlang('client_Moisture') || 'Moisture';
const client_campaign_color = tlang('client_campaign_color') || 'Color';
const laboratory_txt = tlang('laboratory_txt') || 'Laboratory';
const client_probing_layer = tlang('client_probing_layer') || 'Layer';
const client_lithology_from = tlang('client_lithology_from') || 'from';
const client_lithology_to = tlang('client_lithology_to') || 'To';
const client_Inclutions = tlang('client_Inclutions') || 'Inclutions';
const client_Anomaly = tlang('client_Anomaly') || 'Anomaly';
const material_masters_soil_family = tlang('materail_master_soil_family') || 'Soil Family';
const client_probing_from_color_name = tlang('client_probing_from_color_name') || 'From Color Name';
const client_probing_to_color_name = tlang('client_probing_to_color_name') || 'To Color Name';
const client_probing_inclution_color_name =
	tlang('client_probing_inclution_color_name') || 'Inclution Color Name';
const client_Observation = tlang('client_Observation') || 'Observation';
const label_sample = tlang('label_sample') || 'Sample';
const todo_history = tlang('todo_history') || 'Todo history';
const common_btn_okay = tlang('common_btn_okay') || 'Okay';
const no_todo_available = tlang('no_todo_available') || 'No todo available';
const dashboard_log_date = tlang('dashboard_log_date') || 'Date';
const client_probing_method = tlang('client_probing_method') || 'Probing Method';
const masterData = [
	'category',
	'color',
	'lithology',
	'anomaly',
	'geology',
	'nature',
	'organoleptic',
	'moisture',
	'reason',
	'observation',
];
const initialState = {
	loading: false,
	loadingCamp: false,
	loadingProbs: false,
	selectedId: 0,
	selectedLayerId: 0,
	logsCampaign: {
		show: false,
		methods: [],
	},
	logs: [],
	probingMethodList: [],
};
function reducer(state, action) {
	switch (action.type) {
		case 'select-probing':
			if (state.logs && state.logs.length)
				return {
					...state,
					selectedId: action.value,
					selectedLayerId: 0,
				};
			return state;
		case 'select-layer':
			if (state.logs && state.logs.length)
				return {
					...state,
					selectedLayerId: action.value,
				};
			return state;
		default:
			const upd = {};
			for (const key of Object.keys(action)) {
				upd[key] = action[key];
			}
			return { ...state, ...upd };
	}
}

export default function ModalHistory({ isOpen, onClose, campaignId, probingId }) {
	const dispatch = useDispatch();
	const apolloClient = useSelector(state => state.apolloClient);
	const authReducer = useSelector(state => state.authReducer);
	const probingReducer = useSelector(state => state.probingReducer);
	const [state, localDispatch] = useReducer(reducer, initialState);

	const layers = useMemo(() => {
		if (!state.logs) return [];
		if (!Array.isArray(state.logs)) return [];
		if (!state.logs[state.selectedId]) return [];
		const tab = Array.from(state.logs[state.selectedId][1]);
		return tab || [];
	}, [state.logs, state.selectedId]);

	const logs = useMemo(() => {
		if (!state.logs) return [];
		if (!Array.isArray(state.logs)) return [];
		if (!state.logs[state.selectedId]) return [];
		const tab = Array.from(state.logs[state.selectedId][1]);
		if (!tab.length) return [];
		if (!tab[state.selectedLayerId]) return [];
		const tab2 = Array.from(tab[state.selectedLayerId][1]);
		return tab2 || [];
	}, [state.logs, state.selectedId, state.selectedLayerId]);

	useEffect(() => {
		localDispatch({ loading: state.loadingCamp || state.loadingProbs });
	}, [state.loadingCamp, state.loadingProbs]);

	useEffect(() => {
		if (!campaignId) return;
		const requestParams = {
			campaignid: campaignId,
			projectid: authReducer.userData.projectid,
		};
		dispatch(actions.getCampaignProbingTodoLogList(apolloClient.probing, requestParams));
		dispatch(actions.getProbingList(apolloClient.probing, requestParams));
		dispatch(actions.getProbingMethod(apolloClient.masters, []));
		const params = {
			type_list: JSON.stringify(masterData),
			lang_code: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
		};
		dispatch(actions.getTypeWiseOtherMatreials(apolloClient.masters, params));
		localDispatch({ loadingCamp: true, loadingProbs: true });
	}, [campaignId]);

	useEffect(() => {
		const mLogs = new Map();
		const filteredLogs = probingReducer.campignTodoLogList.filter(
			l => probingId === '' || l.probingid === '' || l.probingid === probingId
		);
		for (const todo of filteredLogs) {
			if (todo.probingid === '') {
				const logsCampaign = state.logsCampaign;
				logsCampaign.show = true;
				logsCampaign.methods.push(todo.todo_details);
			} else {
				const prob = mLogs.get(todo.probingid) || new Map();
				const details = todo.todo_details;
				const layer = prob.get(`Layer-${details.layer_index}`) || new Map();
				const section = layer.get(details.section) || [];
				section.push(details);
				layer.set(details.section, section);
				prob.set(`Layer-${details.layer_index}`, layer);
				mLogs.set(todo.probingid, prob);
			}
		}
		const logs = Array.from(mLogs);
		localDispatch({ logs, loadingCamp: false });
		return () => localDispatch({ logsCampaign: { show: false, methods: [] } });
	}, [probingReducer.campignTodoLogList, probingReducer.getProbingList]);

	useEffect(() => {
		if (!probingReducer.getProbingList) return;
		localDispatch({ loadingProbs: false });
	}, [probingReducer.getProbingList, probingReducer.campignTodoLogList]);

	function probingName(pId) {
		if (!Array.isArray(probingReducer.getProbingList)) return '-';
		const prob = probingReducer.getProbingList.find(p => p.probingid === pId);
		return prob?.probingcode || '?';
	}

	function layerNumber(layer) {
		const sL = layer.split('-');
		if (sL.length) return String(Number(sL[1]) + 1);
		return '?';
	}

	return (
		<Modal
			isOpen={isOpen}
			toggle={() => onClose()}
			className={`modal-dialog--success modal-dialog--header width__70`}
		>
			<div className="modal__header">
				<button
					className="lnr lnr-cross modal__close-btn"
					type="button"
					onClick={() => onClose()}
				/>
				<h4 className="bold-text  modal__title">{todo_history}</h4>
			</div>
			<div className="modal__body">
				{state.loading ? (
					<Spinner size="sm" className="btn-spinner" />
				) : state.logs.length ? (
					<TodosContainer>
						{state.logsCampaign.show && (
							<Col xs={12}>
								<LogEntryContainer>
									<h4>{client_probing_method}</h4>
									<div className="entry">
										{state.logsCampaign.methods.map((v, idx) => (
											<ProbMethod key={`METHOD-${idx}`} data={v} />
										))}
									</div>
								</LogEntryContainer>
							</Col>
						)}
						<Row>
							<Col sm={6} md={2}>
								<ListGroup>
									{state.logs.map((probing, idx) => {
										return (
											<ListGroupItem
												key={`PROB-${idx}`}
												tag="div"
												className="probing-item"
												active={idx === state.selectedId}
												onClick={() => localDispatch({ type: 'select-probing', value: idx })}
											>
												{probingName(probing[0])}
											</ListGroupItem>
										);
									})}
								</ListGroup>
							</Col>
							<Col sm={6} md={2}>
								<ListGroup>
									{layers.map((layer, idx) => {
										return (
											<ListGroupItem
												key={`LAY-${idx}`}
												tag="div"
												className="todo-item"
												active={idx === state.selectedLayerId}
												onClick={() => localDispatch({ type: 'select-layer', value: idx })}
											>
												<div className="todo-line">
													{`${client_probing_layer} - ${layerNumber(layer[0])}`}
												</div>
											</ListGroupItem>
										);
									})}
								</ListGroup>
							</Col>
							<Col sm={4} md={8}>
								{logs.map((log, idx) => (
									<LogEntry key={`ENTRY-${idx}`} mode={log[0]} data={log[1]} />
								))}
							</Col>
						</Row>
					</TodosContainer>
				) : (
					<Row>
						<Col xs={12}>
							<p
								style={{
									textAlign: 'center',
									width: '100%',
									color: 'var(--clr-aexdo)',
									fontSize: '2rem',
								}}
							>
								{no_todo_available}
							</p>
						</Col>
					</Row>
				)}

				<ButtonToolbar className="modal__footer">
					<Button color={'success'} onClick={onClose}>
						{common_btn_okay}
					</Button>
				</ButtonToolbar>
			</div>
		</Modal>
	);
}

function LogEntry({ mode, data }) {
	switch (mode) {
		case 'category':
			return (
				<LogEntryContainer>
					<h4>{client_probing_category}</h4>
					<div className="entry">
						{data.map((v, idx) => (
							<Category key={`CATEGORY-${idx}`} data={v} />
						))}
					</div>
				</LogEntryContainer>
			);
		case 'color':
			return (
				<LogEntryContainer>
					<h4>{client_campaign_color}</h4>
					<div className="entry">
						{data.map((v, idx) => (
							<Color key={`COLOR-${idx}`} data={v} />
						))}
					</div>
				</LogEntryContainer>
			);
		case 'moisture':
			return (
				<LogEntryContainer>
					<h4>{client_Moisture}</h4>
					<div className="entry">
						{data.map((v, idx) => (
							<Moisture key={`MOISTURE-${idx}`} data={v} />
						))}
					</div>
				</LogEntryContainer>
			);
		case 'lithology':
			return (
				<LogEntryContainer>
					<h4>{client_probing_lithology}</h4>
					<div className="entry">
						{data.map((v, idx) => (
							<Lithology key={`LITHO-${idx}`} data={v} />
						))}
					</div>
				</LogEntryContainer>
			);
		case 'observation':
			return (
				<LogEntryContainer>
					<h4>{client_Observation}</h4>
					<div className="entry">
						{data.map((v, idx) => (
							<Observation key={`OBSERVATION-${idx}`} data={v} />
						))}
					</div>
				</LogEntryContainer>
			);
		case 'sample':
			return (
				<LogEntryContainer>
					<h4>{label_sample}</h4>
					<div className="entry">
						{data.map((v, idx) => (
							<Laboratory key={`OBSERVATION-${idx}`} data={v} />
						))}
					</div>
				</LogEntryContainer>
			);
		default:
			return <p>{mode}</p>;
	}
}

function ProbMethod({ data }) {
	const masterReducer = useSelector(state => state.masterReducer);
	const [list, setList] = useState([]);

	useEffect(() => {
		const probingMethodList = masterReducer.probingMethodList;
		if (probingMethodList && probingMethodList.length > 0) {
			const list = getMaterialListfilter(probingMethodList);
			setList(list.map(l => ({ value: l._id, label: l.name })));
		}
	}, [masterReducer.probingMethodList]);

	return (
		<div className="entry-container">
			<div className="date">
				<div className="label">{dashboard_log_date}</div>
				<div className="data">{moment(data.created_date).format('DD/MM/YYYY HH:mm')}</div>
			</div>
			<div className="data-container">
				<div className="label">{client_probing_method}</div>
				{data.section_data.map((ds, idx) => (
					<div className="value" key={`PM-${idx}`}>
						{list.find(m => m.value === ds?.value)?.label || '-'}
					</div>
				))}
			</div>
		</div>
	);
}

function Category({ data }) {
	const masterReducer = useSelector(state => state.masterReducer);
	const [list, setList] = useState([]);

	useEffect(() => {
		const categoryList = masterReducer.categoryList;
		if (categoryList && categoryList.length > 0) {
			const list = getMaterialListfilter(categoryList);
			setList(list.map(l => ({ value: l._id, label: l.name })));
		}
	}, [masterReducer.categoryList]);

	return (
		<div className="entry-container">
			<div className="date">
				<div className="label">{dashboard_log_date}</div>
				<div className="data">{moment(data.created_date).format('DD/MM/YYYY HH:mm')}</div>
			</div>
			<div className="data-container">
				<div className="label">{client_probing_category}</div>
				{data.section_data.map((ds, idx) => (
					<div className="value" key={`CAT-${idx}`}>
						{ds.isDropDoun
							? list.find(m => m.value === ds.selectedCategory)?.label || '-'
							: ds.manualCategory || '-'}
					</div>
				))}
			</div>
		</div>
	);
}

function Color({ data }) {
	const masterReducer = useSelector(state => state.masterReducer);
	const [list, setList] = useState([]);

	useEffect(() => {
		const colorList = masterReducer.colorList;
		if (colorList && colorList.length > 0) {
			const list = getMaterialListfilter(colorList);
			setList(list.map(l => ({ value: l._id, label: l.name })));
		}
	}, [masterReducer.colorList]);

	return (
		<div className="entry-container">
			<div className="date">
				<div className="label">{dashboard_log_date}</div>
				<div className="data">{moment(data.created_date).format('DD/MM/YYYY HH:mm')}</div>
			</div>
			<div className="data-container">
				{data.section_data.map((ds, idx) => (
					<React.Fragment key={`C-${idx}`}>
						{data.isDropDoun ? (
							<>
								<div className="label">{client_lithology_from}</div>
								<div className="data">{list.find(m => m.value === ds?.from)?.label || '-'}</div>
								<div className="label">{client_lithology_to}</div>
								<div className="data">{list.find(m => m.value === ds?.to)?.label || '-'}</div>
								<div className="label">{client_Inclutions}</div>
								<div className="data">
									{list.find(m => m.value === ds?.inclutions)?.label || '-'}
								</div>
							</>
						) : (
							<>
								<div className="data-color">
									<div className="label">{client_probing_from_color_name}</div>
									<ColorBox color={ds.from} />
									<div className="data">{ds?.fromname || '-'}</div>
								</div>
								<div className="data-color">
									<div className="label">{client_probing_to_color_name}</div>
									<ColorBox color={ds.to} />
									<div className="data">{ds?.toname || '-'}</div>
								</div>
								<div className="data-color">
									<div className="label">{client_probing_inclution_color_name}</div>
									<ColorBox color={ds.inclutions} />
									<div className="data">{ds?.inclutionsname || '-'}</div>
								</div>
							</>
						)}
					</React.Fragment>
				))}
			</div>
		</div>
	);
}

function Moisture({ data }) {
	const masterReducer = useSelector(state => state.masterReducer);
	const [list, setList] = useState([]);

	useEffect(() => {
		const moistureList = masterReducer.moistureList;
		if (moistureList && moistureList.length > 0) {
			const list = getMaterialListfilter(moistureList);
			setList(list.map(l => ({ value: l._id, label: l.name })));
		}
	}, [masterReducer.moistureList]);

	return (
		<div className="entry-container">
			<div className="date">
				<div className="label">{dashboard_log_date}</div>
				<div className="data">{moment(data.created_date).format('DD/MM/YYYY HH:mm')}</div>
			</div>

			{data.section_data.map((ds, idx) => (
				<div className="data-container" key={`M-${idx}`}>
					{data.isDropDoun
						? ds.selectedmoisturelist.map((sm, idx) => (
								<div key={`SM-${idx}`} className="multi">
									{list.find(m => m.value === sm.value)?.label || '-'}
								</div>
						  ))
						: ds.value}
				</div>
			))}
		</div>
	);
}

function Lithology({ data }) {
	const masterReducer = useSelector(state => state.masterReducer);
	const [list, setList] = useState([]);
	const [anomalyList, setAnomalyList] = useState([]);
	const [geologyList, setGeologyList] = useState([]);

	useEffect(() => {
		const lithologyList = masterReducer.lithologyList;
		if (lithologyList && lithologyList.length > 0) {
			const list = getMaterialListfilter(lithologyList);
			setList(list.map(l => ({ value: l._id, label: l.name })));
		}
	}, [masterReducer.lithologyList]);

	useEffect(() => {
		const anomalyList = masterReducer.anomalyList;
		if (anomalyList && anomalyList.length > 0) {
			const list = getMaterialListfilter(anomalyList);
			setAnomalyList(list.map(l => ({ value: l._id, label: l.name })));
		}
	}, [masterReducer.anomalyList]);

	useEffect(() => {
		const geologyList = masterReducer.geologyList;
		if (geologyList && geologyList.length > 0) {
			const list = getMaterialListfilter(geologyList);
			setGeologyList(list.map(l => ({ value: l._id, label: l.name })));
		}
	}, [masterReducer.anomalyList]);

	return (
		<div className="entry-container">
			<div className="date">
				<div className="label">{dashboard_log_date}</div>
				<div className="data">{moment(data.created_date).format('DD/MM/YYYY HH:mm')}</div>
			</div>
			<div className="data-container">
				{data.section_data.map((ds, idx) => (
					<React.Fragment key={`C-${idx}`}>
						<div className="label">{client_lithology_from}</div>
						<div className="data">
							{data.isDropDoun
								? list.find(m => m.value === ds.from)?.label || '-'
								: ds?.fromname || '-'}
						</div>
						<div className="label">{client_lithology_to}</div>
						<div className="data">
							{data.isDropDoun
								? list.find(m => m.value === ds.to)?.label || '-'
								: ds?.toname || '-'}
						</div>

						{data.isDropDoun ? (
							<>
								<div className="label">{client_Anomaly}</div>
								<div className="data">
									{ds.selectedanomaly.length
										? ds.selectedanomaly.map((sm, idx) => (
												<div key={`SM-${idx}`} className="multi">
													{anomalyList.find(m => m.value === sm.value)?.label || '-'}
												</div>
										  ))
										: '-'}
								</div>
							</>
						) : (
							<>
								<div className="label">{material_masters_soil_family}</div>
								<div className="data">
									{geologyList.find(m => m.value === ds.soil_family)?.label || '-'}
								</div>
							</>
						)}
					</React.Fragment>
				))}
			</div>
		</div>
	);
}

function Observation({ data }) {
	const masterReducer = useSelector(state => state.masterReducer);
	const [list, setList] = useState([]);

	useEffect(() => {
		const observationList = masterReducer.observationList;
		if (observationList && observationList.length > 0) {
			const list = getMaterialListfilter(observationList);
			setList(list.map(l => ({ value: l._id, label: l.name })));
		}
	}, [masterReducer.observationList]);

	return (
		<div className="entry-container">
			<div className="date">
				<div className="label">{dashboard_log_date}</div>
				<div className="data">{moment(data.created_date).format('DD/MM/YYYY HH:mm')}</div>
			</div>

			{data.section_data.map((ds, idx) => (
				<div className="data-container" key={`M-${idx}`}>
					{ds.value ||
						ds.selectedobservation.map((sm, idx) => (
							<div key={`SM-${idx}`} className="multi">
								{list.find(m => m.value === sm.value)?.label || '-'}
							</div>
						))}
				</div>
			))}
		</div>
	);
}

function Laboratory({ data }) {
	return (
		<div className="entry-container">
			<div className="date">
				<div className="label">{dashboard_log_date}</div>
				<div className="data">{moment(data.created_date).format('DD/MM/YYYY HH:mm')}</div>
			</div>
			{data.section_data.map((ds, idx) => (
				<div className="data-container" key={`LAB-${idx}`}>
					<div className="label">{laboratory_txt}</div>
					<div className="data">{ds.laboratory}</div>
				</div>
			))}
		</div>
	);
}

const ColorBox = styled.div`
	height: 1em;
	width: 3em;
	background-color: ${props => props.color || '#000'};
`;

const LogEntryContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 5fr;
	gap: 0.5rem;
	margin-block-end: 0.5rem;
	h4 {
		color: var(--clr-aexdo);
		font-weight: 700;
		margin: 0;
	}
	.entry {
		.entry-container {
			border: 5px solid transparent;
			&:nth-child(even) {
				background-color: rgb(230 72 35 / 10%);
			}
			.data {
				display: flex;
				flex-wrap: wrap;
				gap: 5px;
			}
		}
		border: 1px solid var(--clr-aexdo);
		display: flex;
		flex-direction: column;
		padding: 0;
		.date,
		.data-container {
			display: flex;
			gap: 5px;
			flex-wrap: wrap;
			.label {
				color: var(--clr-aexdo);
				font-weight: 700;
			}
			.label::after {
				content: ':';
				padding-inline: 5px;
			}
			.multi {
				padding: 3px 8px;
				background-color: var(--clr-aexdo);
				color: white;
				border-radius: 3px;
			}
		}
		.data-color {
			display: flex;
			flex-direction: column;
			gap: 5px;
			justify-content: center;
			align-items: center;
		}
	}
`;
