import Cryptr from 'cryptr';

export const Encrypt = (value) => {
    const cryptr = new Cryptr(process.env.REACT_APP_CRYPTO_KEY);
    let encrypted = cryptr.encrypt(value);
    return encrypted;
}

export const Decrypt = (value) => {
    const cryptr = new Cryptr(process.env.REACT_APP_CRYPTO_KEY);
    let decrypted = cryptr.decrypt(value);
    return decrypted;
}

