import { Icon } from '@material-ui/core';
import moment from 'moment-timezone';
import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
	Button,
	ButtonToolbar,
	Col,
	FormGroup,
	Input,
	Label,
	ListGroup,
	ListGroupItem,
	Modal,
	Row,
	Spinner,
} from 'reactstrap';
import styled from 'styled-components';
import * as actions from '../../../../Store/actions/index';
import { getMaterialListfilter } from '../../Common/materialList';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import ColorPicker from '../components/colorPicker';
import Form from '../components/form';
import SelectProbingMethod from './selectProbingMethod';
import ModalHistory from './todoHistory';

moment.tz.setDefault('Europe/Paris');

let tlang = ChangeAppLanguageFront.translateLanguage;
const Campaign_todo_list = tlang('Campaign_todo_list') || 'Campaign todo list';
const cancel_btn = tlang('cancel_btn') || 'Cancel';
const common_btn_save = tlang('common_btn_save') || 'Save';
const client_probing_category = tlang('client_probing_category') || 'Category';
const client_probing_lithology = tlang('client_probing_lithology') || 'Lithology';
const client_Moisture = tlang('client_Moisture') || 'Moisture';
const client_campaign_color = tlang('client_campaign_color') || 'Color';
const laboratory_txt = tlang('laboratory_txt') || 'Laboratory';
const client_probing_layer = tlang('client_probing_layer') || 'Layer';
const client_lithology_from = tlang('client_lithology_from') || 'from';
const client_lithology_to = tlang('client_lithology_to') || 'To';
const client_Inclutions = tlang('client_Inclutions') || 'Inclutions';
const client_Anomaly = tlang('client_Anomaly') || 'Anomaly';
const material_masters_soil_family = tlang('materail_master_soil_family') || 'Soil Family';
const client_probing_from_color_name = tlang('client_probing_from_color_name') || 'From Color Name';
const client_probing_to_color_name = tlang('client_probing_to_color_name') || 'To Color Name';
const client_probing_inclution_color_name =
	tlang('client_probing_inclution_color_name') || 'Inclution Color Name';
const client_Observation = tlang('client_Observation') || 'Observation';
const label_sample = tlang('label_sample') || 'Sample';
const todo_history = tlang('todo_history') || 'Todo history';
const no_todo_available = tlang('no_todo_available') || 'No todo available';
const client_select_probing_method =
	tlang('client_select_probing_method') || 'Select Probing Method';

const initialState = {
	loading: false,
	selectedId: 0,
	selectedLayerId: 0,
	selectedTodoId: 0,
	todoCampaign: {
		show: false,
		probingid: '',
		probingname: '',
		modified: false,
	},
	todo: {},
	probings: [],
	showHistory: false,
	probingList: [],
};

const masterData = [
	'category',
	'color',
	'lithology',
	'anomaly',
	'geology',
	'nature',
	'organoleptic',
	'moisture',
	'reason',
	'observation',
];

function reducer(state, action) {
	switch (action.type) {
		case 'select-probing':
			if (Array.isArray(state.probings) && state.probings.length)
				return {
					...state,
					selectedId: action.value,
					selectedLayerId: 0,
					selectedTodoId: 0,
					todo: state.probings[action.value].layers[0].todos[0],
				};
			return state;
		case 'select-layer':
			if (Array.isArray(state.probings) && state.probings.length)
				return {
					...state,
					selectedLayerId: action.value,
					selectedTodoId: 0,
					todo: state.probings[state.selectedId].layers[action.value].todos[0],
				};
			return state;
		case 'select-todo':
			if (Array.isArray(state.probings) && state.probings.length)
				return {
					...state,
					selectedTodoId: action.value,
					todo: state.probings[state.selectedId].layers[state.selectedLayerId].todos[action.value],
				};
			return state;
		case 'update-todo':
			const probings = [...state.probings];
			const todo =
				probings[action.todo.idProb].layers[action.todo.idLayer].todos[action.todo.idTodo];
			let modified = false;
			switch (action.mode) {
				case 'category':
					if (todo.value.isDropDoun) todo.value.selectedCategory = action.value.value;
					else todo.value.manualCategory = action.value;
					modified = true;
					break;
				case 'color':
					if (action.dest === 'from') {
						if (todo.value.isDropDoun) {
							todo.value.from = action.value.value;
							todo.value.selectedcolorfrom = action.value;
						} else todo.value.from = action.value;
					}
					if (action.dest === 'to') {
						if (todo.value.isDropDoun) {
							todo.value.to = action.value.value;
							todo.value.selectedcolorto = action.value;
						} else todo.value.to = action.value;
					}
					if (action.dest === 'inclutions') {
						if (todo.value.isDropDoun) {
							todo.value.inclutions = action.value.value;
							todo.value.selectedcolorinclutions = action.value;
						} else todo.value.inclutions = action.value;
					}
					if (action.dest === 'fromname') todo.value.fromname = action.value;
					if (action.dest === 'toname') todo.value.toname = action.value;
					if (action.dest === 'inclutionsname') todo.value.inclutionsname = action.value;
					modified = true;
					break;
				case 'lithology':
					if (todo.value.isDropDoun) {
						if (action.dest === 'from') {
							todo.value.selectedlithologyfrom = action.value;
							todo.value.from = action.value.value;
						}
						if (action.dest === 'to') {
							todo.value.selectedlithologyto = action.value;
							todo.value.to = action.value.value;
						}
						if (action.dest === 'anomaly') todo.value.selectedanomaly = action.value;
					} else {
						if (action.dest === 'from') todo.value.from = action.value;
						if (action.dest === 'to') todo.value.to = action.value;
						if (action.dest === 'soil_family') todo.value.soil_family = action.value.value;
					}
					modified = true;
					break;
				case 'moisture':
					if (todo.value.isDropDoun) todo.value.selectedmoisturelist = action.value;
					else todo.value.value = action.value;
					modified = true;
					break;
				case 'observation':
					if (todo.value.isDropDoun) todo.value.selectedobservation = action.value;
					else todo.value.value = action.value;
					modified = true;
					break;
				case 'sample':
					todo.value.laboratory = action.value;
					modified = true;
					break;
				default: {
					console.error('unknown mode', action.mode);
				}
			}
			if (modified) {
				todo.modified = true;
				probings[action.todo.idProb].layers[action.todo.idLayer].modified = true;
				probings[action.todo.idProb].modified = true;
			}
			return { ...state, probings };
		default:
			const upd = {};
			for (const key of Object.keys(action)) {
				upd[key] = action[key];
			}
			// console.log(`upd`, { upd });
			return { ...state, ...upd };
	}
}

export default function ModalTodo({ isOpen, onClose, campaignId, probingId }) {
	const dispatch = useDispatch();
	const [state, localDispatch] = useReducer(reducer, initialState);
	const apolloClient = useSelector(state => state.apolloClient);
	const authReducer = useSelector(state => state.authReducer);
	const probingReducer = useSelector(state => state.probingReducer);
	const campaignReducer = useSelector(state => state.campaignReducer);

	useEffect(() => {
		const params = {
			type_list: JSON.stringify(masterData),
			lang_code: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
		};
		dispatch(actions.getTypeWiseOtherMatreials(apolloClient.masters, params));
	}, []);

	useEffect(() => {
		if (!campaignId) return;
		dispatch(actions.getCampaignDetails(apolloClient.campaign, { campaignid: campaignId }));
		const requestParams = {
			campaignid: campaignId,
			projectid: authReducer.userData.projectid,
		};
		dispatch(actions.getProbingList(apolloClient.probing, requestParams));
		localDispatch({ loading: true });
	}, [campaignId]);

	useEffect(() => {
		if (campaignReducer.campaignDetails?.isprobingmethod)
			localDispatch({
				todoCampaign: {
					show: true,
					probingid: campaignReducer.campaignDetails.probingid,
					probingname: campaignReducer.campaignDetails.probingname,
				},
			});
	}, [campaignReducer.campaignDetails]);

	useEffect(() => {
		if (!probingReducer.getProbingList) return;
		const probingList = probingReducer.getProbingList.filter(row => {
			if (row.status === 'Delete') return false;
			if (probingId && row.probingid !== probingId) return false;
			return true;
		});
		const probings = [];
		for (const pb of probingList) {
			if (!Array.isArray(pb.layer)) break;
			const layers = [];
			let layerNumber = 1;
			for (const lay of pb.layer) {
				const todos = [];
				const todo = {
					idProb: probings.length,
					idLayer: layers.length,
					modified: false,
				};
				if (lay?.category?.catisactive)
					todos.push({
						...todo,
						type: 'category',
						label: client_probing_category,
						value: { ...lay.category },
						idTodo: todos.length,
					});
				if (lay?.observation?.isactive)
					todos.push({
						...todo,
						type: 'observation',
						label: client_Observation,
						value: { ...lay.observation },
						idTodo: todos.length,
					});
				for (const color of lay?.color || []) {
					if (color?.isactive)
						todos.push({
							...todo,
							type: 'color',
							label: client_campaign_color,
							value: { ...color },
							idTodo: todos.length,
						});
				}
				for (const lithology of lay?.lithology || []) {
					if (lithology?.isactive)
						todos.push({
							...todo,
							type: 'lithology',
							label: client_probing_lithology,
							value: { ...lithology },
							idTodo: todos.length,
						});
				}
				for (const moisture of lay?.moisture || []) {
					if (moisture?.isactive)
						todos.push({
							...todo,
							type: 'moisture',
							label: client_Moisture,
							value: { ...moisture },
							idTodo: todos.length,
						});
				}
				let idSample = 0;
				for (const sample of lay?.sample || []) {
					if (sample?.labactive)
						todos.push({
							...todo,
							type: 'sample',
							label: `${label_sample} ${sample.sampleNo}`,
							value: { ...sample },
							idTodo: todos.length,
							idSample,
						});
					idSample += 1;
				}
				if (todos.length) layers.push({ layerNumber, modified: false, todos });
				layerNumber += 1;
			}
			if (layers.length)
				probings.push({
					probingCode: pb.probingcode,
					probingId: pb.probingid,
					modified: false,
					layers,
				});
		}
		localDispatch({ loading: false, probings, probingList });
		if (probings.length) localDispatch({ type: 'select-probing', value: 0 });
	}, [probingReducer.getProbingList]);

	function handleSave() {
		const logs = [];
		const probingsToUpdate = new Map();
		// probing method
		if (state.todoCampaign.modified) {
			const obj = {
				campaignid: campaignId,
				probingid: '',
				projectid: authReducer.userData.projectid,
				todo_details: {
					created_date: moment().valueOf(),
					isTodoCompleted: true,
					isactive: false,
					section: 'probMethod',
					section_data: [{ value: state.todoCampaign.probingid }],
				},
			};
			logs.push(obj);
			// update campaign
			console.log(campaignReducer.campaignDetails);
			const requestParams = {
				...campaignReducer.campaignDetails,
				isprobingmethod: false,
				iscompletedprobingmethod: true,
				probingid: state.todoCampaign.probingid,
				probingname: state.todoCampaign.probingname,
				sinceDate: moment(campaignReducer.campaignDetails.sincedate),
				startDate: moment(campaignReducer.campaignDetails.startdate),
				deletedCampaignInviteInfo: '[]',
				member: JSON.stringify(campaignReducer.campaignDetails.member),
			};
			delete requestParams.sincedate;
			delete requestParams.__typename;
			delete requestParams._id;
			console.log(requestParams);

			dispatch(actions.editCampaign(apolloClient.campaign, requestParams));
		}
		// other todos
		for (const prob of state.probings)
			for (const lay of prob.layers)
				for (const todo of lay.todos) {
					if (todo.modified) {
						const probing = state.probingList.find(p => p.probingid === prob.probingId);
						let layer;
						if (probing) {
							layer = probing.layer[todo.idLayer];
							probingsToUpdate.set(probing.probingid, probing);
						}
						const section_data = [];
						let isDropDoun;
						switch (todo.type) {
							case 'category':
								section_data.push({
									value: todo.value.selectedCategory,
									selectedCategory: todo.value.selectedCategory,
									manualCategory: todo.value.manualCategory,
									isDropDoun: todo.value.isDropDoun,
								});
								if (layer) {
									layer.category.catisTodoCompleted = true;
									layer.category.catisactive = false;
								}
								break;
							case 'lithology':
								section_data.push({
									from: todo.value.from,
									inclutions: todo.value.inclutions,
									to: todo.value.to,
									soil_family: todo.value.soil_family,
									selectedanomaly: todo.value.selectedanomaly,
								});
								isDropDoun = todo.value.isDropDoun;
								if (layer) {
									layer.lithology[0].isTodoCompleted = true;
									layer.lithology[0].isactive = false;
								}
								break;
							case 'moisture':
								section_data.push({
									selectedmoisturelist: todo.value.selectedmoisturelist,
									manualMoisture: todo.value.manualMoisture,
									value: todo.value.value,
									is_change: todo.value.is_change,
								});
								isDropDoun = todo.value.isDropDoun;
								if (layer) {
									layer.moisture[0].isTodoCompleted = true;
									layer.moisture[0].isactive = false;
								}
								break;
							case 'color':
								section_data.push({
									from: todo.value.from,
									inclutions: todo.value.inclutions,
									to: todo.value.to,
									fromname: todo.value.fromname,
									toname: todo.value.toname,
									inclutionsname: todo.value.inclutionsname,
								});
								isDropDoun = todo.value.isDropDoun;
								if (layer) {
									layer.color[0].isTodoCompleted = true;
									layer.color[0].isactive = false;
								}
								break;
							case 'extraInformation':
								section_data.push({
									moisture: todo.value.moisture,
									value: todo.value.value,
								});
								if (layer) {
									layer.extraInformation.isTodoCompleted = true;
									layer.extraInformation.isactive = false;
								}
								break;
							case 'anomaly':
								section_data.push({
									color: todo.value.color,
									value: todo.value.value,
								});
								if (layer) {
									layer.anomaly.isTodoCompleted = true;
									layer.anomaly.isactive = false;
								}
								break;
							case 'observation':
								section_data.push({
									value: todo.value.value,
									selectedobservation: todo.value.selectedobservation,
								});
								isDropDoun = todo.value.isDropDoun;
								if (layer) {
									layer.observation.isTodoCompleted = true;
									layer.observation.isactive = false;
								}
								break;
							case 'sample':
								section_data.push({
									laboratory: todo.value.laboratory,
								});
								if (layer && layer.sample && layer.sample[todo.idSample]) {
									layer.sample[todo.idSample].isTodoCompleted = true;
									layer.sample[todo.idSample].labactive = false;
								}
								break;
							default:
								console.error('unknown type', todo.type);
						}
						const obj = {
							campaignid: campaignId,
							probingid: prob.probingId,
							projectid: authReducer.userData.projectid,
							todo_details: {
								layer_index: todo.idLayer,
								created_date: moment().valueOf(),
								isTodoCompleted: true,
								isactive: false,
								section: todo.type,
								section_data,
							},
						};
						if (typeof isDropDoun !== 'undefined')
							obj.todo_details.isDropDoun = todo.value.isDropDoun;
						if (typeof todo.idSample !== 'undefined') obj.todo_details.index = todo.idSample;
						logs.push(obj);
					}
				}

		const aProbings = Array.from(probingsToUpdate.values());
		if (aProbings.length) {
			const req = {
				companyname: authReducer.userData.company_name,
				req_data: JSON.stringify({
					campaign: [],
					probing: aProbings,
				}),
			};
			dispatch(actions.addEditCampaignProbing(apolloClient.probing, req));
		}
		if (logs.length)
			dispatch(
				actions.createMultipleProbingTodoLog(apolloClient.probing, {
					todo_log_array: JSON.stringify(logs),
				})
			);
		onClose();
	}

	return (
		<>
			<Modal
				isOpen={isOpen}
				toggle={() => onClose()}
				className={`modal-dialog--success modal-dialog--header width__70`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={() => onClose()}
					/>
					<h4 className="bold-text  modal__title">{Campaign_todo_list}</h4>
				</div>
				<div className="modal__body">
					<TodosContainer>
						<Row>
							{state.probings.length === 0 ? (
								<Col xs={12}>
									<p
										style={{
											textAlign: 'center',
											width: '100%',
											color: 'var(--clr-aexdo)',
											fontSize: '2rem',
										}}
									>
										{no_todo_available}
									</p>
								</Col>
							) : (
								<>
									{state.todoCampaign.show && (
										<Col xs={12} style={{ zIndex: 3 }}>
											<FormGroup>
												<div style={{ display: 'flex', gap: '1em' }}>
													<Label>{client_select_probing_method}</Label>
													{state.todoCampaign.modified ? (
														<Icon style={{ color: 'red' }}>warning</Icon>
													) : (
														''
													)}
												</div>
												<SelectProbingMethod
													probingMethod={state.todoCampaign.probingid}
													onChange={pm =>
														localDispatch({
															todoCampaign: {
																show: true,
																probingid: pm.value,
																probingname: pm.label,
																modified: true,
															},
														})
													}
												/>
											</FormGroup>
										</Col>
									)}
									<Col sm={4} md={2}>
										<ListGroup>
											{state.probings.map((probing, idx) => (
												<ListGroupItem
													key={`PROB-${idx}`}
													tag="div"
													className="probing-item"
													active={idx === state.selectedId}
													onClick={() => localDispatch({ type: 'select-probing', value: idx })}
												>
													<div className="todo-line">
														{probing.probingCode}
														{probing.modified ? (
															<Icon style={{ color: idx === state.selectedId ? 'white' : 'red' }}>
																warning
															</Icon>
														) : (
															''
														)}
													</div>
												</ListGroupItem>
											))}
										</ListGroup>
									</Col>
									<Col sm={4} md={2}>
										<ListGroup>
											{state.probings.length > 0 &&
												state.probings[state.selectedId].layers.map((layer, idx) => (
													<ListGroupItem
														key={`LAY-${idx}`}
														tag="div"
														className="todo-item"
														active={idx === state.selectedLayerId}
														onClick={() => localDispatch({ type: 'select-layer', value: idx })}
													>
														<div className="todo-line">
															{`${client_probing_layer} - ${layer.layerNumber}`}
															{layer.modified ? (
																<Icon
																	style={{ color: idx === state.selectedLayerId ? 'white' : 'red' }}
																>
																	warning
																</Icon>
															) : (
																''
															)}
														</div>
													</ListGroupItem>
												))}
										</ListGroup>
									</Col>
									<Col sm={4} md={2}>
										<ListGroup>
											{state.probings.length > 0 &&
												state.probings[state.selectedId].layers[state.selectedLayerId].todos.map(
													(todo, idx) => (
														<ListGroupItem
															key={`TODO-${idx}`}
															tag="div"
															className="todo-item"
															active={idx === state.selectedTodoId}
															onClick={() => localDispatch({ type: 'select-todo', value: idx })}
														>
															<div className="todo-line">
																{todo.label}
																{todo.modified ? (
																	<Icon
																		style={{
																			color: idx === state.selectedTodoId ? 'white' : 'red',
																		}}
																	>
																		warning
																	</Icon>
																) : (
																	''
																)}
															</div>
														</ListGroupItem>
													)
												)}
										</ListGroup>
									</Col>
									<Col sm={12} md={6}>
										<Form>
											{state.todo?.type === 'category' && (
												<Category
													todo={state?.todo}
													onChange={(todo, value) =>
														localDispatch({ type: 'update-todo', mode: 'category', todo, value })
													}
												/>
											)}
											{state.todo?.type === 'color' && (
												<Color
													todo={state?.todo}
													onChange={(todo, dest, value) =>
														localDispatch({ type: 'update-todo', mode: 'color', dest, todo, value })
													}
												/>
											)}
											{state.todo?.type === 'lithology' && (
												<Lithology
													todo={state?.todo}
													onChange={(todo, dest, value) =>
														localDispatch({
															type: 'update-todo',
															mode: 'lithology',
															dest,
															todo,
															value,
														})
													}
												/>
											)}
											{state.todo?.type === 'moisture' && (
												<Moisture
													todo={state?.todo}
													onChange={(todo, value) =>
														localDispatch({ type: 'update-todo', mode: 'moisture', todo, value })
													}
												/>
											)}
											{state.todo?.type === 'observation' && (
												<Observation
													todo={state?.todo}
													onChange={(todo, value) =>
														localDispatch({ type: 'update-todo', mode: 'observation', todo, value })
													}
												/>
											)}
											{state.todo?.type === 'sample' && (
												<Laboratory
													todo={state?.todo}
													onChange={(todo, value) =>
														localDispatch({ type: 'update-todo', mode: 'sample', todo, value })
													}
												/>
											)}
										</Form>
									</Col>
								</>
							)}
						</Row>
					</TodosContainer>
					<ButtonToolbar className="modal__footer">
						<Button
							color={'success'}
							outline={true}
							onClick={() => localDispatch({ showHistory: true })}
						>
							<div style={{ display: 'flex', gap: '1em' }}>
								<Icon>history</Icon>
								{todo_history}
							</div>
						</Button>
						<Button color={'success'} outline={true} onClick={() => onClose()}>
							{cancel_btn}
						</Button>
						<Button color={'success'} onClick={() => handleSave()} disabled={state.loading}>
							{state.loading && <Spinner size="sm" className="btn-spinner" />}
							{common_btn_save}
						</Button>
					</ButtonToolbar>
				</div>
			</Modal>
			{state.showHistory && (
				<ModalHistory
					isOpen={state.showHistory}
					onClose={() => localDispatch({ showHistory: false })}
					campaignId={campaignId}
					probingId={probingId}
				/>
			)}
		</>
	);
}

export const TodosContainer = styled.div`
	width: 100%;
	height: 100%;
	.probing-item,
	.todo-item {
		cursor: pointer;
		&.active {
			background-color: var(--clr-aexdo) !important;
			border-color: var(--clr-aexdo) !important;
		}
	}
	.todo-line {
		display: flex;
		justify-content: space-between;
	}
`;

function Category({ todo, onChange }) {
	const masterReducer = useSelector(state => state.masterReducer);
	const [list, setList] = useState([]);

	useEffect(() => {
		const categoryList = masterReducer.categoryList;
		if (categoryList && categoryList.length > 0) {
			const list = getMaterialListfilter(categoryList);
			setList(list.sort((a, b) => a.name.localeCompare(b.name)).map(l => ({ value: l._id, label: l.name })));
		}
	}, [masterReducer.categoryList]);

	if (!todo?.value) return null;
	if (!todo.value.catisactive) return null;
	if (todo.value.isDropDoun)
		return (
			<FormGroup>
				<Label>{client_probing_category}</Label>
				<Select
					value={list.find(m => m.value === todo.value.selectedCategory)}
					onChange={v => onChange(todo, v)}
					options={list}
					className="react-select"
				/>
			</FormGroup>
		);
	return (
		<FormGroup>
			<Label>{client_probing_category}</Label>
			<Input
				type="text"
				value={todo.value.manualCategory}
				onChange={e => onChange(todo, e.target.value)}
			/>
		</FormGroup>
	);
}

function Color({ todo, onChange }) {
	const masterReducer = useSelector(state => state.masterReducer);
	const [list, setList] = useState([]);

	useEffect(() => {
		const colorList = masterReducer.colorList;
		if (colorList && colorList.length > 0) {
			const list = getMaterialListfilter(colorList);
			setList(list.sort((a, b) => a.name.localeCompare(b.name)).map(l => ({ value: l._id, label: l.name })));
		}
	}, [masterReducer.colorList]);

	if (!todo?.value) return null;
	if (!todo.value.isactive) return null;
	if (todo.value.isDropDoun)
		return (
			<>
				<FormGroup>
					<Label>{client_lithology_from}</Label>
					<Select
						value={list.find(m => m.value === todo.value.from)}
						onChange={v => onChange(todo, 'from', v)}
						options={list}
						className="react-select"
					/>
				</FormGroup>
				<FormGroup>
					<Label>{client_lithology_to}</Label>
					<Select
						value={list.find(m => m.value === todo.value.to)}
						onChange={v => onChange(todo, 'to', v)}
						options={list}
						className="react-select"
					/>
				</FormGroup>
				<FormGroup>
					<Label>{client_Inclutions}</Label>
					<Select
						value={list.find(m => m.value === todo.value.inclutions)}
						onChange={v => onChange(todo, 'inclutions', v)}
						options={list}
						className="react-select"
					/>
				</FormGroup>
			</>
		);
	return (
		<>
			<ColorPicker
				label={client_lithology_from}
				value={todo.value.from}
				onChange={color => onChange(todo, 'from', color)}
			/>
			<FormGroup>
				<Label>{client_probing_from_color_name}</Label>
				<Input
					type="text"
					value={todo.value.fromname}
					onChange={e => onChange(todo, 'fromname', e.target.value)}
				/>
			</FormGroup>
			<ColorPicker
				label={client_lithology_to}
				value={todo.value.to}
				onChange={color => onChange(todo, 'to', color)}
			/>
			<FormGroup>
				<Label>{client_probing_to_color_name}</Label>
				<Input
					type="text"
					value={todo.value.toname}
					onChange={e => onChange(todo, 'toname', e.target.value)}
				/>
			</FormGroup>
			<ColorPicker
				label={client_Inclutions}
				value={todo.value.inclutions}
				onChange={color => onChange(todo, 'inclutions', color)}
			/>
			<FormGroup>
				<Label>{client_probing_inclution_color_name}</Label>
				<Input
					type="text"
					value={todo.value.inclutionsname}
					onChange={e => onChange(todo, 'inclutionsname', e.target.value)}
				/>
			</FormGroup>
		</>
	);
}

function Moisture({ todo, onChange }) {
	const masterReducer = useSelector(state => state.masterReducer);
	const [list, setList] = useState([]);

	useEffect(() => {
		const moistureList = masterReducer.moistureList;
		if (moistureList && moistureList.length > 0) {
			const list = getMaterialListfilter(moistureList);
			setList(list.sort((a, b) => a.name.localeCompare(b.name)).map(l => ({ value: l._id, label: l.name })));
		}
	}, [masterReducer.moistureList]);

	if (!todo?.value) return null;
	if (!todo.value.isactive) return null;
	if (todo.value.isDropDoun)
		return (
			<FormGroup>
				<Label>{client_Moisture}</Label>
				<Select
					value={todo.value.selectedmoisturelist}
					onChange={v => onChange(todo, v)}
					options={list}
					className="basic-multi-select"
					isSearchable={true}
					isMulti={true}
					closeMenuOnSelect={false}
				/>
			</FormGroup>
		);
	return (
		<FormGroup>
			<Label>{client_Moisture}</Label>
			<Input
				type="text"
				value={todo.value.manualMoisture}
				onChange={e => onChange(todo, e.target.value)}
			/>
		</FormGroup>
	);
}

function Lithology({ todo, onChange }) {
	const masterReducer = useSelector(state => state.masterReducer);
	const [list, setList] = useState([]);
	const [anomalyList, setAnomalyList] = useState([]);
	const [geologyList, setGeologyList] = useState([]);

	useEffect(() => {
		const lithologyList = masterReducer.lithologyList;
		if (lithologyList && lithologyList.length > 0) {
			const list = getMaterialListfilter(lithologyList);
			setList(list.sort((a, b) => a.name.localeCompare(b.name)).map(l => ({ value: l._id, label: l.name })));
		}
	}, [masterReducer.lithologyList]);

	useEffect(() => {
		const anomalyList = masterReducer.anomalyList;
		if (anomalyList && anomalyList.length > 0) {
			const list = getMaterialListfilter(anomalyList);
			setAnomalyList(list.sort((a, b) => a.name.localeCompare(b.name)).map(l => ({ value: l._id, label: l.name })));
		}
	}, [masterReducer.anomalyList]);

	useEffect(() => {
		const geologyList = masterReducer.geologyList;
		if (geologyList && geologyList.length > 0) {
			const list = getMaterialListfilter(geologyList);
			setGeologyList(list.map(l => ({ value: l._id, label: l.name })));
		}
	}, [masterReducer.anomalyList]);

	if (!todo?.value) return null;
	if (!todo.value.isactive) return null;
	if (todo.value.isDropDoun)
		return (
			<>
				<FormGroup>
					<Label>{client_lithology_from}</Label>
					<Select
						value={list.find(m => m.value === todo.value.from)}
						onChange={v => onChange(todo, 'from', v)}
						options={list}
						className="react-select"
					/>
				</FormGroup>
				<FormGroup>
					<Label>{client_lithology_to}</Label>
					<Select
						value={list.find(m => m.value === todo.value.to)}
						onChange={v => onChange(todo, 'to', v)}
						options={list}
						className="react-select"
					/>
				</FormGroup>
				<FormGroup>
					<Label>{client_Anomaly}</Label>
					<Select
						value={todo.value.selectedanomaly}
						onChange={v => onChange(todo, 'anomaly', v)}
						options={anomalyList}
						className="basic-multi-select"
						isSearchable={true}
						isMulti={true}
						closeMenuOnSelect={false}
					/>
				</FormGroup>
			</>
		);
	return (
		<>
			<FormGroup>
				<Label>{client_lithology_from}</Label>
				<Input
					type="text"
					value={todo.value.from}
					onChange={e => onChange(todo, 'from', e.target.value)}
				/>
			</FormGroup>
			<FormGroup>
				<Label>{client_lithology_to}</Label>
				<Input
					type="text"
					value={todo.value.to}
					onChange={e => onChange(todo, 'to', e.target.value)}
				/>
			</FormGroup>
			<FormGroup>
				<Label>{material_masters_soil_family}</Label>
				<Select
					value={geologyList.find(m => m.value === todo.value.soil_family)}
					onChange={v => onChange(todo, 'soil_family', v)}
					options={geologyList}
					className="react-select"
				/>
			</FormGroup>
		</>
	);
}

function Observation({ todo, onChange }) {
	const masterReducer = useSelector(state => state.masterReducer);
	const [list, setList] = useState([]);

	useEffect(() => {
		const observationList = masterReducer.observationList;
		if (observationList && observationList.length > 0) {
			const list = getMaterialListfilter(observationList);
			setList(list.map(l => ({ value: l._id, label: l.name })));
		}
	}, [masterReducer.observationList]);

	if (!todo?.value) return null;
	if (!todo.value.isactive) return null;
	if (todo.value.isDropDoun)
		return (
			<FormGroup>
				<Label>{client_Observation}</Label>
				<Select
					value={todo.value.selectedobservation}
					onChange={v => onChange(todo, v)}
					options={list}
					className="basic-multi-select"
					isSearchable={true}
					isMulti={true}
					closeMenuOnSelect={false}
				/>
			</FormGroup>
		);
	return (
		<FormGroup>
			<Label>{client_Observation}</Label>
			<Input type="text" value={todo.value.value} onChange={e => onChange(todo, e.target.value)} />
		</FormGroup>
	);
}

function Laboratory({ todo, onChange }) {
	if (!todo?.value) return null;
	if (!todo.value.labactive) return null;
	return (
		<FormGroup>
			<Label>{laboratory_txt}</Label>
			<Input
				type="text"
				value={todo.value.laboratory}
				onChange={e => onChange(todo, e.target.value)}
			/>
		</FormGroup>
	);
}
