import React, { PureComponent } from 'react';
import { Card, CardBody, Container, Row, Col, Spinner } from 'reactstrap';
import Layout from './Layout';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../Store/actions/index';
import loadable from '@loadable/component';
// import ViewProbing from '../Client/Probing/viewProbing';
import ListProbing from '../Client/Probing/listProbing';
import CompanyList from '../Admin/users/list';
import Staff from '../Admin/staff';
import LicenceRequest from '../Admin/licenceRequest';
import PlanRequest from '../Admin/planRequest';
import Invoice from '../Client/invoice';
import ViewExcelData from '../Client/excelUpload/viewExcelData';
import ExcelSettings from '../Client/excelUpload/excelSettings';
import manageDisposal from '../Client/Disposal/manageDisposal';
import Invoices from '../Admin/invoices';
import ClientSystemLog from '../Client/systemlog';
import AdminSystemLog from '../Admin/systemlog';
import ProbingAnalysis from '../Client/probingAnalysis';
import PollutantAnalysis from '../Client/pollutantAnalysis';
import FieldAnalysis from '../Client/fieldAnalysis';
import { withRouter } from 'react-router-dom';
import { Icon, IconButton, Tooltip } from '@material-ui/core';
import allCompanyUsers from '../Admin/users/allCompanyUsers';
import ProjectsList from '../Client/Project/projectsList';
import ViewProbingImportSheet from '../Client/viewProbingImportSheet';
import * as changeLanguage1 from '../Layout/ChangeAppLanguageFront';

let tlang = changeLanguage1.translateLanguage;
const toggle_sidebar = tlang('toggle_sidebar') || 'Toggle sidebar';
const tooltip_show_sidebar = tlang('tooltip_show_sidebar') || 'Show Sidebar';

const LoadingSpinner = () => {
	return (
		<Card>
			<CardBody>
				<Row>
					<Col xs={12} sm={12} md={12} className="margin__top text-center">
						<Spinner size="sm" className="btn-spinner spinner_color spinner_size" />
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
};

const Dashboard = loadable(() => import('../Client/dashboard'), {
	fallback: <LoadingSpinner />,
});
// const AddCampaign = loadable(() => import('../Client/Campaign/addCampaign'), {
const AddCampaign = loadable(() => import('../Client/Campaign/addEditCampaign'), {
	fallback: <LoadingSpinner />,
});
// const ViewCampaign = loadable(() => import('../Client/Campaign/viewCampaign'), {
// 	fallback: <LoadingSpinner />,
// });
const ViewCampaign = loadable(() => import('../Client/Campaign/listCampaign'), {
	fallback: <LoadingSpinner />,
});
const AddProbing = loadable(() => import('../Client/Probing/addProbing'), {
	fallback: <LoadingSpinner />,
});
// const ViewProbing = loadable(() => import('../Client/Probing/viewProbing'), {
//   fallback: <LoadingSpinner/>,
// })
const AddAnalyses = loadable(() => import('../Client/Analysis/addAnalysis'), {
	fallback: <LoadingSpinner />,
});
const ViewAnalyses = loadable(() => import('../Client/Analysis/viewAnalysis'), {
	fallback: <LoadingSpinner />,
});
const AddProject = loadable(() => import('../Client/Project/newProject'), {
	fallback: <LoadingSpinner />,
});
const Work_room = loadable(() => import('../Client/work_room'), {
	fallback: <LoadingSpinner />,
});
const Documents = loadable(() => import('../Client/documents'), {
	fallback: <LoadingSpinner />,
});
const Profile = loadable(() => import('../Client/profile'), {
	fallback: <LoadingSpinner />,
});
const Setting = loadable(() => import('../Client/setting'), {
	fallback: <LoadingSpinner />,
});
// const CompanyList = loadable(() => import('../Admin/users/list'), {
//   fallback: <LoadingSpinner/>,
// })
const UserRequest = loadable(() => import('../Admin/users/request'), {
	fallback: <LoadingSpinner />,
});
const Plan = loadable(() => import('../Admin/plan'), {
	fallback: <LoadingSpinner />,
});
const Language = loadable(() => import('../Admin/language'), {
	fallback: <LoadingSpinner />,
});
// const Staff = loadable(() => import('../Admin/staff'), {
//   fallback: <LoadingSpinner/>,
// })
const MaterialMaster = loadable(() => import('../Admin/materialMaster'), {
	fallback: <LoadingSpinner />,
});
const Projects = loadable(() => import('../Admin/projects'), {
	fallback: <LoadingSpinner />,
});
const Notfound = loadable(() => import('./notFound'), {
	fallback: <LoadingSpinner />,
});
const Notfoundroute = loadable(() => import("./notFoundRoute"), {
	fallback: <LoadingSpinner />,
});
const SignUpWizard = loadable(() => import('../SignUp/signUpWizard'), {
	fallback: <LoadingSpinner />,
});
const AdminProfile = loadable(() => import('../Admin/profile'), {
	fallback: <LoadingSpinner />,
});
// const LicenceRequest = loadable(() => import('../Admin/licenceRequest'), {
//   fallback: <LoadingSpinner/>,
// })
// const PlanRequest = loadable(() => import('../Admin/planRequest'), {
//   fallback: <LoadingSpinner/>,
// })
const ProjectToken = loadable(() => import('../Admin/projectToken'), {
	fallback: <LoadingSpinner />,
});
const PlanMasters = loadable(() => import('../Admin/planMaster'), {
	fallback: <LoadingSpinner />,
});
const TestMasters = loadable(() => import('../Admin/typeMasters'), {
	fallback: <LoadingSpinner />,
});
const ContentManagement = loadable(() => import('../Admin/content_management'), {
	fallback: <LoadingSpinner />,
});
const DataVisualization = loadable(() => import('../Client/dataVisualization'), {
	fallback: <LoadingSpinner />,
});
const DataExploitation = loadable(() => import('../Client/dataExploitation'), {
	fallback: <LoadingSpinner />,
});
const ExcelUpload = loadable(() => import('../Client/excelUpload/excelUpload'), {
	fallback: <LoadingSpinner />,
});
// const ViewExcelData = loadable(() => import('../Client/excelUpload/viewExcelData'), {
//   fallback: <LoadingSpinner/>,
// })
// const ExcelSettings = loadable(() => import('../Client/excelUpload/excelSettings'), {
//   fallback: <LoadingSpinner/>,
// })
const SendNotification = loadable(() => import('../Admin/sendNotification'), {
	fallback: <LoadingSpinner />,
});
const NotificationManagement = loadable(() => import('../Admin/notification_management'), {
	fallback: <LoadingSpinner />,
});
const EmailComponent = loadable(() => import('../Client/emailcomponent'), {
	fallback: <LoadingSpinner />,
});
const NotificationComponent = loadable(() => import('../Client/notificationComponent'), {
	fallback: <LoadingSpinner />,
});

const Settings = loadable(() => import('../Client/settings'), {
	fallback: <LoadingSpinner />,
});

const Reposts = loadable(() => import('../Client/Analysis/reports'), {
	fallback: <LoadingSpinner />,
});
const SampleDisposalSetting = loadable(() => import('../Client/Analysis/sampleDisposalSetting'), {
	fallback: <LoadingSpinner />,
});
const ImportProbing = loadable(() => import('../Client/importProbing'), {
	fallback: <LoadingSpinner />,
});

//This class will call when system found the session in app.
//Here it will chek its admin or client
class Index extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			totalCampaign: 0,
			isclosed: false,
			hideMenuForClosedProject: [
				'/client/newCampaign',
				'/client/newProbing',
				'/client/importProbing',
				'/client/excelSettings',
				'/client/excelUpload',
				'/client/dataCreation',
				'/client/dataExploitation',
				'/client/reports',
				'/client/sampleDisposalSetting',
				'/client/disposal',
			],
			showMenu: false,
			showSidebar: true,
		};
	}

	componentDidMount() {
		// let request = {
		//   email: this.props.authReducer.userData.email,
		//   projectid: this.props.authReducer.userData.projectid
		//  }
		// this.props.getAccess(this.props.apolloClient.client, request);

		/* get campaign list */
		if (this.props.authReducer.userData.projectid && this.props.authReducer.userData.company_name) {
			let isadmin =
				this.props.authReducer.userData.email === this.props.authReducer.userData.owner
					? true
					: false;
			let request = {
				companyname: this.props.authReducer.userData.company_name,
				projectid: this.props.authReducer.userData.projectid,
				other_company: this.props.authReducer.userData.other_company,
				isadmin: isadmin,
			};
			this.props.getCampaignList(this.props.apolloClient.campaign, request);
		}
	}
	componentWillReceiveProps(props) {
		if (props.authReducer.companyList && props.authReducer.companyList.length > 0) {
			let activeProject = props.authReducer.companyList.filter(
				val => val.projectid === props.authReducer.userData.projectid
			);
			if (activeProject.length > 0) {
				this.setState({
					isclosed: activeProject[0].isclosed ? activeProject[0].isclosed : false,
				});
			}
		}

		if (props.campaignReducer.campaignDetails) {
			/* set campaingcampaign list*/
			if (props.campaignReducer.campaignList) {
				let campaignListArray = props.campaignReducer.campaignList;
				let totalCampaign = campaignListArray.length;
				this.setState({ totalCampaign: totalCampaign });
			}
		}
	}
	// This will return all routes depending on user rights.( IN PROGRESS )
	loadUserRoutes = () => {
		const uData = this.props.authReducer.userData;
		const proClosed = this.state.hideMenuForClosedProject;
		const isclosed = this.state.isclosed;
		let UrlName = this.props.history.location.pathname;
		return (
			<React.Fragment>
				{/* this are Client routes IN PROGRESS  */}
				{!uData.staffuser && (
					<>
						<Switch>
							<Route exact path="/" component={Dashboard} />
							<Route exact path="/client/dashboard" component={Dashboard} />

							{UrlName.includes('/client/newCampaign') &&
								(uData.projectid &&
									uData.useraccesspage['campaign_management'] &&
									(!isclosed || (isclosed && !proClosed.includes('/client/newCampaign'))) &&
									((uData.newuseraccesspage != null &&
										uData.newuseraccesspage['is_eo_license'] == true &&
										this.state.totalCampaign <= 0) ||
										uData.newuseraccesspage == null ||
										typeof uData.newuseraccesspage['is_eo_license'] == 'undefined' ||
										(uData.newuseraccesspage != null &&
											uData.newuseraccesspage['is_eo_license'] == false)) &&
									typeof uData.camp_invite != 'undefined' &&
									uData.camp_invite == false ? (
									<Route exact path="/client/newCampaign" component={AddCampaign} />
								) : (
									<Route component={Notfound} />
								))}
							{UrlName.includes('/client/newProbing') &&
								(uData.projectid &&
									uData.useraccesspage['probing_management'] &&
									(!isclosed || (isclosed && !proClosed.includes('/client/newProbing'))) ? (
									<Route exact path="/client/newProbing" component={AddProbing} />
								) : (
									<Route component={Notfound} />
								))}
							{UrlName.includes('/client/importProbing') &&
								(uData.projectid &&
									uData.useraccesspage['probing_management'] &&
									(!isclosed || (isclosed && !proClosed.includes('/client/importProbing'))) ? (
									<Route exact path="/client/importProbing" component={ImportProbing} />
								) : (
									<Route component={Notfound} />
								))}
							{UrlName.includes('/client/newProject') &&
								(uData.plan != null &&
									uData.plan.planid !== 'AXD_PL_01' &&
									uData.other_company != 'undefined' &&
									uData.other_company == false ? (
									<Route exact path="/client/newProject" component={AddProject} />
								) : (
									<Route component={Notfound} />
								))}

							{UrlName.includes('/client/dataCreation') &&
								(uData.projectid &&
									uData.useraccesspage['probing_management'] &&
									(!isclosed || (isclosed && !proClosed.includes('/client/dataCreation'))) ? (
									<Route exact path="/client/dataCreation" component={DataVisualization} />
								) : (
									<Route component={Notfound} />
								))}

							{UrlName.includes('/client/dataExploitation') &&
								(uData.projectid &&
									uData.useraccesspage['probing_management'] &&
									(!isclosed || (isclosed && !proClosed.includes('/client/dataExploitation'))) ? (
									<Route exact path="/client/dataExploitation" component={DataExploitation} />
								) : (
									<Route component={Notfound} />
								))}

							{UrlName.includes('/client/excelUpload') &&
								(uData.projectid &&
									uData.useraccesspage['probing_management'] &&
									(!isclosed || (isclosed && !proClosed.includes('/client/excelUpload'))) ? (
									<Route exact path="/client/excelUpload" component={ExcelUpload} />
								) : (
									<Route component={Notfound} />
								))}

							{UrlName.includes('/client/disposal') &&
								(uData.projectid &&
									uData.useraccesspage['disposal_management'] &&
									(!isclosed || (isclosed && !proClosed.includes('/client/disposal'))) ? (
									<Route exact path="/client/disposal" component={manageDisposal} />
								) : (
									<Route component={Notfound} />
								))}

							{/* sampleDisposalSetting menu should be visible for closed project */}
							{UrlName.includes('/client/sampleDisposalSetting') &&
								(uData.projectid &&
									uData.useraccesspage['probing_management'] &&
									(!isclosed || (isclosed && !proClosed.includes('/client/sampleDisposalSetting'))) ? (
									<Route exact path="/client/sampleDisposalSetting" component={SampleDisposalSetting} />
								) : isclosed ? (
									<Route exact path="/client/sampleDisposalSetting" component={SampleDisposalSetting} />
								) : (
									<Route component={Notfound} />
								))}

							{/* Reports menu should be visible for closed project */}
							{UrlName.includes('/client/reports') &&
								(uData.projectid &&
									uData.useraccesspage['probing_management'] &&
									(!isclosed || (isclosed && !proClosed.includes('/client/reports'))) ? (
									<Route exact path="/client/reports" component={Reposts} />
								) : isclosed ? (
									<Route exact path="/client/reports" component={Reposts} />
								) : (
									<Route component={Notfound} />
								))}

							<Route exact path="/client/campaignList" component={ViewCampaign} />
							<Route exact path="/client/probingList" component={ListProbing} />
							<Route exact path="/client/add_analysis" component={AddAnalyses} />
							<Route exact path="/client/view_analysis" component={ViewAnalyses} />
							<Route exact path="/client/projectList" component={ProjectsList} />
							<Route exact path="/client/workroom" component={Work_room} />
							<Route exact path="/client/documents" component={Documents} />
							<Route exact path="/client/profile" component={Profile} />
							<Route exact path="/client/invoices" component={Invoice} />
							<Route exact path="/client/setting" component={Setting} />
							<Route exact path="/client/dataVisualization" component={DataVisualization} />
							<Route exact path="/client/excelUpload/edit/:id" component={ExcelUpload} />
							<Route exact path="/client/viewExcelData" component={ViewExcelData} />
							<Route exact path="/client/probingImportSheet" component={ViewProbingImportSheet} />
							<Route exact path="/client/excelSettings" component={ExcelSettings} />
							<Route exact path="/client/emailSetting" component={EmailComponent} />
							<Route exact path="/client/notificationSetting" component={NotificationComponent} />
							<Route exact path="/client/settings" component={Settings} />
							<Route exact path="/client/systemLog" component={ClientSystemLog} />
							<Route exact path="/client/probingAnalysis" component={ProbingAnalysis} />
							<Route exact path="/client/pollutantAnalysis" component={PollutantAnalysis} />
							<Route exact path="/client/fieldAnalysis" component={FieldAnalysis} />
							<Route exact path="*" component={Notfoundroute} />
							{/* <Route exact path="/client/projectList" component={ViewProject} /> */}
							{/* <Route exact path="/client/probingList" component={ViewProbing} /> */}
							{/* <Route exact path="/client/mapdemo" component={DataVisualization} /> */}
							{/* <Route exact path="/client/excelUpload" component={ExcelUpload} /> */}
							{/* <Route exact path="/client/disposal" component={manageDisposal} /> */}
							{/* <Route exact path="/client/reports" component={Reposts} /> */}
						</Switch>
					</>
				)}
				{/* this are Admin routes */}
				{uData.staffuser && (
					<>
						<Switch>
							<Route exact path="/" component={Dashboard} />
							<Route exact path="/admin/companyList" component={CompanyList} />
							<Route exact path="/admin/request" component={UserRequest} />
							<Route exact path="/admin/companyUsers" component={allCompanyUsers} />
							<Route exact path="/admin/plan" component={Plan} />
							<Route exact path="/admin/language" component={Language} />
							<Route exact path="/admin/staff" component={Staff} />
							<Route exact path="/admin/materialMasters" component={MaterialMaster} />
							<Route exact path="/admin/planMasters" component={PlanMasters} />
							<Route exact path="/admin/testMasters" component={TestMasters} />
							<Route exact path="/admin/projects" component={Projects} />
							<Route exact path="/admin/licenceRequest" component={LicenceRequest} />
							<Route exact path="/admin/planRequest" component={PlanRequest} />
							<Route exact path="/admin/projectToken" component={ProjectToken} />
							<Route exact path="/account/profile" component={AdminProfile} />
							<Route exact path="/admin/sendNotification" component={SendNotification} />
							<Route exact path="/admin/notificationManagement" component={NotificationManagement} />
							<Route exact path="/admin/emailManagement" component={ContentManagement} />
							<Route exact path="/admin/invoices" component={Invoices} />
							<Route exact path="/admin/systemLog" component={AdminSystemLog} />
							<Route exact path="*" component={Notfoundroute} />
						</Switch>
					</>
				)}

				{/* <Route exact path="/client/signup" component={SignUpWizard} /> */}

			</React.Fragment>
		);
	};
	toggle = () => {
		this.setState({
			showMenu: !this.state.showMenu,
		});
		this.props.MenuToggle(!this.state.showMenu);
	};

	mouseMoveHandler = (event) => {
		if(event.clientX === 0) {
			this.setState({
				showMenu: false,
			});
		}
		if(this.state.showSidebar === false && event.clientX >= 250) {
			this.setState({
				showMenu: true,
			});
		}
  	}

	toggleStickSidebar = () => {
		if(this.state.showSidebar) {
			this.toggle();
		}
		this.setState({
			showSidebar: !this.state.showSidebar,
		});
	}

	
	//render component
	render() {
		window.addEventListener('mousemove', this.mouseMoveHandler);
		const uData = this.props.authReducer.userData;
		return (
			<div>
				<div className="theme-light">
					<div className="wrapper">
						<main>
							<div>
								<Layout toggleMenu={this.toggle} showMenu={this.state.showMenu} showSidebar={this.state.showSidebar} toggleStickSidebar={this.toggleStickSidebar} />
								<div
									className="container__wrap"
									style={{ paddingLeft: (!this.state.showMenu && this.state.showSidebar) ? '250px' : '4%' }}
								>
									<Container className="dashboard">
										{this.state.showMenu && (
											<IconButton
												aria-label="menu"
												onClick={(e) => this.toggle() || this.toggleStickSidebar()}
												className="bg-white "
												style={{
													position: 'fixed',
													padding: '6px',
													marginLeft: '-4%',
												}}
											>
												<Tooltip title={tooltip_show_sidebar}>
													<Icon style={{ color: 'var(--clr-aexdo)' }}>keyboard_double_arrow_right</Icon>
												</Tooltip>
											</IconButton>
										)}
										{uData.confirm_account || uData.staffuser ? (
											this.loadUserRoutes()
										) : (
											<SignUpWizard />
										)}
									</Container>
								</div>
							</div>
						</main>
					</div>
				</div>
			</div>
		);
	}
}

//load reducers
const mapStateToProps = state => {
	return {
		authReducer: state.authReducer,
		apolloClient: state.apolloClient,
		campaignReducer: state.campaignReducer,
	};
};

//Load actions
const mapDispatchToProps = dispatch => {
	return {
		MenuToggle: request => {
			dispatch(actions.MenuToggle(request));
		},
		getAccess: (client, request) => {
			dispatch(actions.getAccess(client, request));
		},
		getCampaignList: (client, request) => {
			dispatch(actions.getCampaignList(client, request));
		},
	};
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index)); //export component
