import React, { PureComponent } from 'react';
import Plot from 'react-plotly.js';
import { Card, CardBody, Row, Col } from 'reactstrap';
import * as actions from '../../../Store/actions/index';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../../assets/mapbox/css/mapbox-gl-draw.css';
import LoadingIcon from 'mdi-react/LoadingIcon';
import * as ChangeAppLanguageFront from '../Layout/ChangeAppLanguageFront';
import { formatLngLat } from '../../lib/formatDepth';

let tlang = ChangeAppLanguageFront.translateLanguage;
const client_field_analysis = tlang('client_field_analysis') || 'Field Analysis';

class ProbingAnalysis extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			chart: [],
			refresh: false,
		};
	}

	componentDidMount() {
		this.setState({ refresh: true });
		let param = {
			projectid: this.props.authReducer.userData.projectid,
		};
		this.props.getTerrainAnalysisGraph(this.props.apolloClient.probing, param);
	}

	componentWillReceiveProps(props) {
		/* received 3d analysis graph data */
		let terrainAnalysisGraphData = props.analysisReducer.terrainAnalysisGraphData;

		let ArrayData = [],
			longitude = [],
			latitude = [],
			dep = [];
		if (terrainAnalysisGraphData.length > 0) {
			terrainAnalysisGraphData.map((val1, index) => {
				let measure_unit = props.authReducer.userData.default_unit
					? this.props.authReducer.userData.default_unit
					: 'M';
				let middel_depth = measure_unit == 'M' ? Number(val1.altitude) / 100 : val1.altitude;

				let a = formatLngLat(val1.latitude);
				let b = formatLngLat(val1.longitude);
				latitude.push(a);
				longitude.push(b);
				dep.push(middel_depth);
			});
			ArrayData.push(latitude);
			ArrayData.push(longitude);
			ArrayData.push(dep);

			let newChart = {
				z: ArrayData,
				type: 'surface',
			};

			this.setState(
				{
					chart: [newChart],
					refresh: false,
				},
				() => this.forceUpdate()
			);
		} else {
			this.setState({
				refresh: false,
			});
		}
	}

	render() {
		let threeDConfig = {
			displaylogo: false,
		};

		return (
			<>
				<Row>
					<Col md={12}>
						<h3 className="page-title">{client_field_analysis}</h3>
					</Col>
				</Row>
				<Card>
					<CardBody>
						{this.state.refresh ? (
							<div className="panel__refresh">
								<LoadingIcon />
							</div>
						) : (
							''
						)}
						<Plot
							data={this.state.chart}
							ref={plotlyRef => {
								this.plotlyRef = plotlyRef;
							}}
							style={{ width: '100%', height: '100%' }}
							layout={{
								// autosize: true,
								// width: 600,
								// height: 450,
								showlegend: false,
								margin: {
									l: 0,
									r: 0,
									b: 0,
									t: 30,
									pad: 0,
								},
							}}
							config={threeDConfig}
						/>
					</CardBody>
				</Card>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		authReducer: state.authReducer,
		apolloClient: state.apolloClient,
		analysisReducer: state.analysisReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getTerrainAnalysisGraph: (client, request) => {
			dispatch(actions.getTerrainAnalysisGraph(client, request));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('common')(ProbingAnalysis)); // export component
