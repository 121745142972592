import { Icon, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, PopoverBody, Row, UncontrolledPopover } from 'reactstrap';
import ModalSendEmail from '../../modals/sendEmail';
import * as ChangeAppLanguageFront from '../../../Layout/ChangeAppLanguageFront';

let tlang = ChangeAppLanguageFront.translateLanguage;
const client_external_user = tlang('client_external_user') || 'External user';

export default function StakeHolder({ member, idx, title }) {
	const key = `stackholderpopover${idx}`;
	const authReducer = useSelector(state => state.authReducer);
	const [showEmail, setShowEmail] = useState(false);

	//External Invited user not able to see other External Invited user exist in the project
	const userOwnCmp = authReducer.userDetail.company_name;
	const isadmin = authReducer.userData.email === authReducer.userData.owner;
	const isCmpMember = authReducer.userDetail.company_name === authReducer.userData.company_name;

	return (
		<>
			{showEmail && (
				<ModalSendEmail
					isOpen={showEmail}
					onClose={() => setShowEmail(false)}
					from={authReducer.userData.email}
					to={member.email}
					title={title}
				/>
			)}
			{(isadmin || isCmpMember || (member?.inviteid ? member.companyname === userOwnCmp : true)) ?
				<div
					className={`border-class-detail ${member.roleid === 'AXD_RL_02' && member.status === 'approve'
						? 'selectedColorCampaign'
						: 'bgHover1'
						}`}
					style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
					id={key}
				>
					{member.name || `Request Pending${member.emailid ? ` (${member.emailid})` : ''}`} {member?.inviteid ? <Tooltip title={client_external_user}><Icon style={{ color: 'var(--clr-aexdo)' }}>explicit</Icon></Tooltip> : ''}
					{member.email && (
						<UncontrolledPopover trigger="legacy" placement="bottom" target={key}>
							<PopoverBody style={{ textAlign: 'center' }}>
								<h5>{member.name}</h5>
								<h4>{member?.inviteid ? member.membercompanydisplayname : member.displayname}</h4>
								<br />
								<div>
									<Row>
										<Col xs={10}>
											<h6>{member.email}</h6>
										</Col>
										<Col xs={2} className="email-icon">
											<Icon onClick={() => setShowEmail(true)} style={{ verticalAlign: 'bottom' }}>
												email
											</Icon>
										</Col>
									</Row>
								</div>
							</PopoverBody>
						</UncontrolledPopover>
					)}
				</div> : null
			}
		</>
	);
}
