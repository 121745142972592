import { Icon } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Button, CustomInput } from 'reactstrap';
import styled from 'styled-components';

export default function MapButtonGroup({ title, buttons }) {
	const [visible, setVisible] = useState(false);
	useEffect(() => {
		setVisible(buttons.filter(b => b.visible).length > 0);
	}, [buttons]);

	if (!visible) return null;
	return (
		<ButtonsContainer>
			{title && <div className="title">{title}</div>}
			{buttons
				.filter(b => b.visible)
				.map(button => (
					<div key={button.id} data-for={`btn-${button.id}`} data-tip={button.tooltip}>
						{button.switch ? (
							<div style={{ marginLeft: '0.5rem' }}>
								<CustomInput
									disabled={button.isDisabled}
									onChange={e => button.onClick(e.target.checked)}
									type="switch"
									name={button.id}
									id={button.id}
									checked={button.checked}
								/>
							</div>
						) : (
							<Button
								disabled={button.isDisabled}
								className={'system-btn clear-border'}
								outline
								color="success"
								size="sm"
								onClick={() => button.onClick()}
							>
								<Icon>{button.icon}</Icon>
							</Button>
						)}
						<ReactTooltip
							className="reactglobaltooltip"
							id={`btn-${button.id}`}
							place={button.place || 'bottom'}
							type={'dark'}
							effect={'solid'}
							multiline
						/>
					</div>
				))}
		</ButtonsContainer>
	);
}

const ButtonsContainer = styled.div`
	display: flex;
	border: 1px solid var(--clr-aexdo);
	border-radius: 5px;
	align-items: center;
	gap: 5px;
	button {
		margin: 0;
		padding: 0;
		border: none;
		background: none;
	}
	.title {
		padding-inline: 0.5rem;
		color: var(--clr-aexdo);
		font-size: 12px;
		border-right: 1px solid var(--clr-aexdo);
	}
`;
