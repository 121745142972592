import React, { useState } from 'react';
import { Button, ButtonToolbar, Col, Modal, Row, Spinner, Card, CardBody, Label, Input } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import * as actions from '../../../../Store/actions/index';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

let tlang = ChangeAppLanguageFront.translateLanguage;
const cancel_btn = tlang('cancel_btn') || 'Cancel';
const submit_btn = tlang('submit_btn') || 'Submit';
const client_cmp_no_records_found = tlang('client_cmp_no_records_found') || 'No records found';
const tooltip_disposal_family_reorder = tlang('tooltip_disposal_family_reorder') || 'Reorder Disposal Family';

export default function ModalDisposalFamilyReOrder({ isOpen, onClose, initialData }) {
	const dispatch = useDispatch();
	const apolloClient = useSelector(state => state.apolloClient);
	const authReducer = useSelector(state => state.authReducer);
	const notification = useSelector((state) => state.notification);
	const [loading, setLoading] = useState(false);
	const [disposalList, setDisposalList] = useState([]);

	useEffect(() => {
		if (notification) {
		  if (notification.success) {
			setLoading(false);
			onClose();
		  }
		}
	  }, [notification?.success]);

	useEffect(() => {
		if (!Array.isArray(initialData.test_list)) return;
		setDisposalList(initialData.test_list);
	}, []);

	function onDragStart(result) {
	}

	function reorder(list, startIndex, endIndex) {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	}

	function onDragEnd(result) {
		if (!result.destination) {
			return;
		}
		const arrayList = reorder(
			disposalList,
			result.source.index,
			result.destination.index
		);
		setDisposalList(arrayList);
	}

	function handleSave() {
		const isSuperAdmin = authReducer.userData.company_name === '["superadmin"]' ? true : false;
		let desposal = initialData;
		if (!disposalList.length) {
			onClose();
			return;
		}

		// edit
		const requestParams = {
			name: desposal.name,
			standard: desposal.standard,
			address: desposal.address,
			state: desposal.state,
			country: desposal.country,
			color: desposal.color,
			test_list: JSON.stringify(disposalList),
			start: "",
			end: "",
			percentage: "",
			id: desposal._id,
			company_name: isSuperAdmin ? "" : initialData.company_name,
			removed_test_ids: JSON.stringify([]),
			clone_desposal_id: "",
			clone_desposal_status: false,
			type: isSuperAdmin ? "master" : initialData.type,
			userId: isSuperAdmin ? "" : initialData.userId,
			projectId: isSuperAdmin ? "" : initialData.projectId,
			index_no: desposal.index_no,
			email: authReducer.userData.email, //I pass this for get_user_data api
		}
		dispatch(actions.createDesposal(apolloClient.masters, requestParams));
		setLoading(true);
	}

	function checkTextColor(hexcolor) {
		let color = hexcolor.substring(1);
		let r = parseInt(color.substr(0, 2), 16);
		let g = parseInt(color.substr(2, 2), 16);
		let b = parseInt(color.substr(4, 2), 16);
		let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
		return yiq > 200 ? '' : '#fff';
	}

	return (
		<Modal
			isOpen={isOpen}
			//toggle={onClose}
			className={`modal-dialog--success modal-dialog--header`}
		>
			<div className="modal__header">
				<button className="lnr lnr-cross modal__close-btn" type="button" onClick={onClose} />
				<h4 className="bold-text modal__title">{`${tooltip_disposal_family_reorder} - ${initialData.name}`}</h4>
			</div>
			<div className="modal__body">
				<Row>
					{disposalList.length > 0 &&
						<Card>
							<CardBody>
								<Col xs="12">
									<DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
										<Droppable droppableId="droppable">
											{(provided, snapshot) => (
												<Container
													ref={provided.innerRef}
													className={`list1 ${snapshot.isDraggingOver && 'draggingOver'}`}
													{...provided.droppableProps}
												>
													{disposalList.map((item, index) => {
														return (
															<Draggable key={item._id.toString()} draggableId={item._id.toString()} index={index} isDragDisabled={false}>
																{(provided, snapshot) => {
																	provided.draggableProps.style = {
																		...provided.draggableProps.style,
																		backgroundColor: initialData.color,
																		cursor: "pointer"
																	};
																	return (
																		<Item
																			id={"item_" + index}
																			className={`${snapshot.isDragging && 'dragging'}`}
																			style={provided.draggableProps.style}
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			{...provided.dragHandleProps}
																		>
																			<span style={{ color: checkTextColor(initialData.color), fontSize: 'medium' }}>{item.name}</span>
																		</Item>
																	)
																}}
															</Draggable>
														)
													})}
													{provided.placeholder}
												</Container>
											)}
										</Droppable>
									</DragDropContext>
								</Col>
							</CardBody>
						</Card>
					}
					{disposalList.length == 0 &&
						<h4 style={{ margin: "0 auto" }}>{client_cmp_no_records_found}</h4>
					}
				</Row>
			</div>
			<ButtonToolbar className="modal__footer_project_detail">
				<Button color={'success'} outline={true} onClick={() => onClose()}>
					{cancel_btn}
				</Button>
				<Button color={'success'} onClick={() => handleSave()} disabled={loading}>
					{submit_btn} {loading && <Spinner size="sm" className="btn-spinner" />}
				</Button>
			</ButtonToolbar>
		</Modal>
	);
}

const Container = styled.div`
	margin: 0 auto;
	padding: 5px;
	width: 90%;
	background-color: lightgrey;
`;

const Item = styled.div`
	padding: 10px;
	margin-bottom: 8px;
`;
