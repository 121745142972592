import MUIDataTable from 'mui-datatables';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Button, ButtonToolbar, Col, Modal, Row, Spinner, Label, FormGroup } from 'reactstrap';
import * as ChangeAppLanguageFront from '../../../../Layout/ChangeAppLanguageFront';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../../../Store/actions/index';
import styled from 'styled-components';
import moment from 'moment-timezone';
import __ from 'underscore';
import FormGroupRequired from '../../formGroupRequired';

moment.tz.setDefault('Europe/Paris');

let tlang = ChangeAppLanguageFront.translateLanguage;
const submit_btn = tlang('submit_btn') || 'Submit';
const cancel_btn = tlang('cancel_btn') || 'Cancel';
const select_company = tlang('select_company') || 'Select Company';
const select_project = tlang('select_project') || 'Select Project';
const please_select_company = tlang('please_select_company') || 'Please select company';
const please_select_project = tlang('please_select_project') || 'Please select project';

export default function ModalSwitchCompany({ isOpen, onClose }) {
	const [allCompany, setAllCompany] = useState([]);
	const [selectedCmp, setSelectedCmp] = useState('');
	const [selCmpProjects, setSelCmpProjects] = useState([]);
	const [selectedProject, setSelectedProject] = useState('');
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const apolloClient = useSelector(state => state.apolloClient);
	const authReducer = useSelector(state => state.authReducer);

	// useEffect(() => {
	// 	if (__.isEmpty(initialData)) return;
	// 	const allCompany = [];
	// 	initialData.allCmpDisName.map((l, i) => {
	// 		allCompany.push(
	// 			{ label: l, value: initialData.allCmpName[i] }
	// 		);
	// 	});
	// 	setAllCompany(allCompany);
	// }, [initialData]);

	useEffect(() => {
		const allCmpName = JSON.parse(authReducer.userData.all_company_name);
		const allCmpDisName = JSON.parse(authReducer.userData.all_display_name);

		const allCompany = [];
		allCmpDisName.map((l, i) => {
			allCompany.push(
				{ label: l, value: allCmpName[i] }
			);
		});
		setAllCompany(allCompany);
	}, []);

	useEffect(() => {
		if (
			!Array.isArray(authReducer.switchProjectList) ||
			!selectedCmp
		)
			return;
			
		const projectList = authReducer.switchProjectList;

		projectList.sort(function compare(a, b) {
			const dateA = new Date(a.startDate);
			const dateB = new Date(b.startDate);
			return dateB - dateA;
		})

		const selCmpProjects = [];
		projectList.map((l) => {
			selCmpProjects.push(
				{ label: l.projectname, value: l.projectid }
			);
		})
		console.log("selCmpProjects ===> ", selCmpProjects);
		setSelCmpProjects(selCmpProjects);
	}, [authReducer.switchProjectList])

	const getProjectsOfSelCmp = (value) => {
		setSelectedProject('');
		setSelectedCmp(value);
		setErrors({});

		const request = {
			companyname: value,
			userid: authReducer.userData.userid,
		}
		dispatch(actions.getCompanyProjectListForSwitchCompany(apolloClient.client, request));
	}
	function handleSave() {
		const errs = {};
		if (selectedCmp === '') errs.selectedCmp = true;
		setErrors(errs);
		if (__.isEmpty(errs)) {
			//If user select same existing company
			if (selectedCmp === allCompany[0].value && selectedCmp === authReducer.userData.company_name) {
				if (selectedProject && selectedProject === authReducer.userData.projectid) {
					onClose();
				} else {
					setLoading(true);
					const request = {
						email: authReducer.userData.email,
						projectid: selectedProject,
						isRefresh: true,
					};
					dispatch(actions.getAccess(apolloClient.client, request));
				}
			} else {
				if (selectedProject && selectedProject === authReducer.userData.projectid) {
					onClose();
					return;
				}
				else if (selectedCmp !== allCompany[0].value && !selectedProject) {
					errs.selectedProject = true;
					setErrors(errs);
					return;
				}
				else {
					setLoading(true);
					//If user select other invited company
					const request = {
						email: authReducer.userData.email,
						company: selectedCmp,
						projectid: selectedProject
					}
					dispatch(actions.companyLogIn(apolloClient.client, request));
				}
			}
		}
	}
	return (
		<Modal
			isOpen={isOpen}
			toggle={onClose}
			className={`modal-dialog--success modal-dialog--header width__50`}
		>
			<div className="modal__header">
				<button className="lnr lnr-cross modal__close-btn" type="button" onClick={onClose} />
				<h4 className="bold-text  modal__title">{select_company}</h4>
			</div>
			<div className="modal__body">
				<>
					<Row>
						<Col xs={12}>
							<FormGroupRequired>
								<Label>{select_company}</Label>
								<Select
									value={allCompany.find(c => c.value === selectedCmp)}
									onChange={value => getProjectsOfSelCmp(value?.value || '')}
									options={allCompany}
									className="react-select"
								/>
								{errors.selectedCmp && (
									<span className="error-message">{please_select_company}</span>
								)}
							</FormGroupRequired>
						</Col>
					</Row>
					<Row>
						<Col xs={12}>
							{allCompany.length && selectedCmp && selectedCmp !== allCompany[0].value ?
								<Label>{select_project}<Star>*</Star></Label> :
								<Label>{select_project}</Label>
							}
							<Select
								value={selCmpProjects.find(c => c.value === selectedProject) || ''}
								onChange={value => setSelectedProject(value?.value || '')}
								options={selCmpProjects}
								className="react-select"
							/>
							{errors.selectedProject && (
								<span className="error-message">{please_select_project}</span>
							)}
						</Col>
					</Row>
				</>
			</div>
			<ButtonToolbar className="modal__footer">
				<Button color={'success'} outline={true} onClick={onClose} size="sm">
					{cancel_btn}
				</Button>
				<Button disabled={loading} color={'success'} onClick={handleSave}>
					{submit_btn}
				</Button>
				{loading && (
					<Spinner size="sm" className="btn-spinner spinner_color spinner_size" />
				)}
			</ButtonToolbar>
		</Modal>
	);
}

const Star = styled.div`
	color: red;
	margin-inline-start: 0.5em;
`;
