import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import 'bootstrap/dist/css/bootstrap.css';
import '../src/App/assets/scss/app.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import store from './Store/store.js';
import MainContainer from '../src/mainContainer';
import { config as i18nextConfig } from '../src/Store/translations';
import firebase from 'firebase/app';
// import ErrorBoundry from "./errorBoundary";

if (firebase.apps.length === 0) {
	firebase.initializeApp({
		apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
		authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
		databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
		projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
		storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
		messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID,
		appId: process.env.REACT_APP_FIREBASE_APP_ID,
		measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
	});
}
// Init language configuration
i18next.init(i18nextConfig);

// Init class for react app
class App extends Component {
	render() {
		return (
			<Provider store={store}>
				{/* <ErrorBoundry> */}
				<BrowserRouter>
					<I18nextProvider i18n={i18next}>
						<MainContainer />{' '}
						{/* this will load the main component which load the components depending on the session */}
					</I18nextProvider>
				</BrowserRouter>
				{/* </ErrorBoundry> */}
			</Provider>
		);
	}
}

export default App; //export component
