const env = process.env.REACT_APP_ENVIRONMENT;

export default {   
   API: env === "LIVE" ? process.env.REACT_APP_API : process.env.REACT_APP_API_LOCAL,
   PROJECT_API: env === "LIVE" ? process.env.REACT_APP_PROJECT_API : process.env.REACT_APP_PROJECT_API_LOCAL,
   MASTER_API: env === "LIVE" ? process.env.REACT_APP_MASTER_API : process.env.REACT_APP_MASTER_API_LOCAL,
   CAMPAIGN_API: env === "LIVE" ? process.env.REACT_APP_CAMPAIGN_API : process.env.REACT_APP_CAMPAIGN_API_LOCAL,
   PROBING_API: env === "LIVE" ? process.env.REACT_APP_PROBING_API : process.env.REACT_APP_PROBING_API_LOCAL,
   WS_API: env === "LIVE" ? process.env.REACT_APP_WS_API_LOCAL : process.env.REACT_APP_WS_API_LOCAL,
   WS_PROJECT_API: env === "LIVE" ? process.env.REACT_APP_WS_PROJECT_API_LOCAL : process.env.REACT_APP_WS_PROJECT_API_LOCAL,
   WS_MASTER_API: env === "LIVE" ? process.env.REACT_APP_WS_MASTER_API_LOCAL : process.env.REACT_APP_WS_MASTER_API_LOCAL,
   WS_CAMPAIGN_API: env === "LIVE" ? process.env.REACT_APP_WS_CAMPAIGN_API_LOCAL : process.env.REACT_APP_WS_CAMPAIGN_API_LOCAL,
   WS_PROBING_API: env === "LIVE" ? process.env.REACT_APP_WS_PROBING_API_LOCAL : process.env.REACT_APP_WS_PROBING_API_LOCAL,
   ImageAPI: process.env.REACT_APP_IMAGEAPI,
   IMAGE_URL: process.env.REACT_APP_IMAGE_URL,
   APP_API_IP: process.env.REACT_APP_API_IP,
   IMAGE_PORT: process.env.REACT_APP_IMAGE_PORT,
   COUCH_DATABASE: process.env.REACT_APP_COUCH_DATABASE,
   MAPBOX_ACCESS_TOKEN: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
}

