import React, { Component } from 'react';
import './App.css';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import LogIn from './App/view/LogIn';
import Register from './App/view/Register';
import Company from './App/view/Company'; // work in progress
import Supportcompany from './App/view/Supportcompany'; // work in progress
import Landing from './App/view/Landing/index';
import Dashboard from './App/view/Layout/index';
import { ApolloProvider } from '@apollo/react-hooks';
import * as actions from './Store/actions/index';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import Notfound from './notFound';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import jwt from 'jsonwebtoken';

const SUPPORT_ADMIN_COMPANY = 'support'

// This class used to call diffrent components.
// mainly use to diffrentiate session and login components
class MainContainer extends Component {
	constructor() {
		super();
		this.state = {
			isLogin: false,
			warning: false,
			firstLogin: false,
			renderNow: false,
			isSupportLogin: false,
			notification: {
				message: null,
				success: true,
			},
		};
	}

	componentWillMount() {
		// setup initially language
		if (!localStorage.getItem('language')) localStorage.setItem('language', 'fr');

		// check initially that user is login or not
		if (this.props.authReducer.userData) {

			let companyList = this.isJson(this.props.authReducer.userData?.company_name) === true ? JSON.parse(this.props.authReducer.userData?.company_name) : this.props.authReducer.userData?.company_name;
			let isSupportLogin = this.state.isSupportLogin;
			if (companyList[0] == SUPPORT_ADMIN_COMPANY) {
				isSupportLogin = true;
			}
			let credential = jwt.verify(this.props.authReducer.userData.token, process.env.REACT_APP_JWT_SECRET_KEY);
			if (credential && credential.companyname) {
				this.setState({
					isLogin: true,
					renderNow: true,
					isSupportLogin: isSupportLogin
				});
			} else {
				this.setState({
					isLogin: true,
					firstLogin: true,
					renderNow: true,
					isSupportLogin: isSupportLogin
				});
			}
		} else {
			this.setState({
				renderNow: true,
			});
		}
		if (!localStorage.getItem('language_data')) {
			this.props.getLanguageMasterListFront(this.props.apolloClient.language, []);
		}
	}
	componentWillReceiveProps(props) {
		// new notification recived in state
		if (props.notification && props.notification.message && !this.state.warning) {
			this.setState(
				{
					warning: true,
					notification: props.notification,
				},
				() => {
					setTimeout(() => {
						this.setState({
							warning: false,
						});
						props.clearNotif();
					}, 5000);
				}
			);
		}
	}

	isJson = (str) => {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}

	//toggle notifications
	toggleWarning = () => {
		this.setState({
			warning: !this.state.warning,
		});
	};

	//render component
	render() {

		const urlName = this.props.history.location.pathname;

		return (
			<ThemeProvider theme={theme}>
				{this.state.renderNow && (
					<ApolloProvider client={this.props.apolloClient.client}>
						{this.state.isLogin ? (
							this.state.firstLogin ? 
								(this.state.isSupportLogin ? ( <Supportcompany />) : (<Company />)) 
								: (<Dashboard />)
						) : (
							// this is main user dashboard this will load when system ge session
							//Routes without session
							<React.Fragment>
								<Switch>
									<Route exact path="/" component={Landing} />
									<Route exact path="/signin" component={LogIn} />
									<Route exact path="/register" component={Register} />
									<Route exact path="/company" component={Company} />
									<Route component={Notfound} />
								</Switch>
							</React.Fragment>
						)}
					</ApolloProvider>
				)}
				{this.state.warning && (
					<Snackbar
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						style={{
							marginRight: (urlName === '/client/newProbing' || urlName === '/client/probingList') ? '12%': '0px', 
							marginTop: (urlName === '/client/newProbing' || urlName === '/client/probingList') ? '0.4%': '0px' 
						}}
						onClose={this.toggleWarning}
						open={this.state.warning}
						TransitionComponent={Fade}
						ContentProps={{
							style: this.state.notification.success
								? { background: 'green' }
								: { background: 'red' },
						}}
						message={this.state.notification.message}
						variant="success"
					/>
				)}
			</ThemeProvider>
		);
	}
}

//load reducers
const mapStateToProps = state => {
	return {
		authReducer: state.authReducer,
		notification: state.notification,
		apolloClient: state.apolloClient,
		//languageReducer: state.languageReducer,
	};
};

//Load actions
const mapDispatchToProps = dispatch => {
	return {
		logInUser: request => {
			dispatch(actions.logIn(request));
		},
		clearNotif: () => {
			dispatch(actions.clearNotif());
		},

		getLanguageMasterListFront: (master, request) => {
			dispatch(actions.getLanguageMasterListFront(master, request));
		},
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainContainer)); //export components

//this is common style for system material theme which apply in whole system
const theme = createTheme({
	typography: {
		useNextVariants: true,
		fontSize: 13,
	},
	palette: {
		primary: { main: '#1098F7' },
		secondary: { main: '#e64823' },
		background: {
			default: '#f2f4f7',
		},
	},
	overrides: {
		// .MuiPickersModal-dialogRoot-653
		MuiPickersModal: {
			dialogRoot: {
				padding: '0px',
			},
		},
		MuiSnackbarContent: {
			message: {
				fontSize: '14px',
				fontWeight: '500',
			},
		},
		MuiFilledInput: {
			root: {
				backgroundColor: '#f2f4f7 !important',
			},
		},
		MuiFormLabel: {
			root: {
				'&$error': {
					color: '#000000de !important',
				},
			},
			// error: {
			//   color: "#000000de !important"
			// },
		},
		MuiMenu: {
			paper: {
				padding: '0',
			},
		},
		MUIDataTableHeadCell: {
			data: {
				width: '100%',
			},
		},
		MuiIcon: {
			root: {
				fontSize: '20px',
			},
		},
		MuiTableHead: {
			root: {
				textAlign: 'center',
			},
		},
		MuiTableCell: {
			root: {
				padding: '4px 0px 0px 15px',
				textAlign: 'left',
			},
			head: {
				color: '#646777',
				fontWeight: '700',
				padding: '4px 0px 0px 15px',
				textAlign: 'left',
				backgroundColor: '#fafbfe !important',
				fontSize: 13,
				borderBottom: '1px solid #c6c6c6',
				borderTop: '1px solid #c6c6c6',
				'&:last-child': {
					// textAlign: 'center',
					minWidth: '150px',
				},
			},
		},
		MuiPaper: {
			elevation4: {
				boxShadow: 'none',
			},
			root: {
				padding: '0px 20px 20px 20px',
			},
		},
		MuiInputLabel: {
			root: {
				fontWeight: '400',
				'&$focused': {
					color: 'var(--clr-aexdo)',
				},
			},
		},
		MuiInput: {
			underline: {
				'&:after': {
					borderBottom: '2px solid var(--clr-aexdo)',
				},
			},
		},
		MuiOutlinedInput: {
			root: {
				'&$focused $notchedOutline': {
					borderColor: 'var(--clr-aexdo)',
					borderWidth: 2,
				},
			},
		},
	},
});
