import { legacy_createStore as createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk"
import authReducer from './reducers/authentication/auth';
import apolloClient from './reducers/apolloClient';
import notification from './reducers/notification';
import staffReducer from './reducers/staff/staff';
import userReducer from './reducers/users/users';
import masterReducer from './reducers/masters/masters';
import memberReducer from './reducers/member/member';
import projectReducer from './reducers/project/project';
import campaignReducer from './reducers/campaign/campaign';
import documentReducer from './reducers/document/document';
import probingReducer from './reducers/probing/probing';
import contentManagementReducer from './reducers/contentManagement/contentManagement';
import commonReducer from './reducers/common/common';
import invoiceReducer from './reducers/invoice/invoice';
import dataVisualizaionReducer from './reducers/dataVisualizaion/dataVisualizaion';
import languageReducer from './reducers/language/language';
import languagenewReducer from './reducers/languagenew/languagenew';
import languagecodeReducer from './reducers/languagecode/languagecode';
import excelUploadReducer from './reducers/excelUpload/excelUpload';
import analysisReducer from './reducers/analysis/analysis';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const rootReducer = combineReducers({
  authReducer: authReducer,
  apolloClient: apolloClient,
  notification: notification,
  staffReducer: staffReducer,
  userReducer: userReducer,
  masterReducer: masterReducer,
  memberReducer: memberReducer,
  projectReducer: projectReducer,
  campaignReducer:campaignReducer,
  documentReducer:documentReducer,
  probingReducer:probingReducer,
  contentManagementReducer:contentManagementReducer,
  commonReducer:commonReducer,
  invoiceReducer:invoiceReducer,
  dataVisualizaionReducer:dataVisualizaionReducer,
  languageReducer:languageReducer,
  languagecodeReducer:languagecodeReducer,
  languagenewReducer:languagenewReducer,
  excelUploadReducer:excelUploadReducer,
  analysisReducer:analysisReducer
})

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk))
);

export default store
