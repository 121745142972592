import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../Store/actions/index';
import {
	compareAnalysisAndDisposalPollutant,
	prepareDataForAnalysis,
} from '../view/Client/Analysis/compareAnalysisAndDisposalPollutant';
import { sampleDisposalCalulation } from '../view/Common/sampleDisposalCalulation';
import useDisposalList from './useDisposalList';
import * as ChangeAppLanguageFront from '../view/Layout/ChangeAppLanguageFront';
import __ from 'underscore';

const CLR_NO_SAMPLE_ANALYSIS_UPLOADED = '#e3e3e3';

const tlang = ChangeAppLanguageFront.translateLanguage;
const mapbox_sample_has_no_analysis = tlang('mapbox_sample_has_no_analysis') || 'Sample has no analysis';

export default function useSampleInfo(probings, selectedDisposals = null, options = {}) {
	const [exceptionList, setExceptionList] = useState();
	const [excelDataList, setExcelDataList] = useState();
	const [desposalRuleList, setDesposalRuleList] = useState();
	const projectReducer = useSelector(state => state.projectReducer);
	const excelUploadReducer = useSelector(state => state.excelUploadReducer);
	const probingReducer = useSelector(state => state.probingReducer);
	const dispatch = useDispatch();
	const apolloClient = useSelector(state => state.apolloClient);
	const dataVisualizaionReducer = useSelector(state => state.dataVisualizaionReducer);
	const authReducer = useSelector(state => state.authReducer);
	const masterReducer = useSelector(state => state.masterReducer);
	const { disposalList: disposals, loading: loadingDisposals } = useDisposalList(
		options?.disposalOptions
	);
	const [campaigns, setCampaigns] = useState([]);
	const [pollutantList, setPollutantList] = useState([]);

	const [loading, setLoading] = useState(true);
	const [sampleInfo, setSampleInfo] = useState([]);
	const [samples, setSamples] = useState([]);
	const [error, setError] = useState();
	const [initialForcedSampleData, setInitialForcedSampleData] = useState([]);

	useEffect(() => {
		if (authReducer.userData.company_name === '["superadmin"]') return;
		if (authReducer.userData.projectid) {

			//Get Forcefully selected Disposal to samples
			// const requestParam = {
			// 	userid: authReducer.userData.userid,
			// 	projectid: authReducer.userData.projectid,
			// 	default_disposal_type: authReducer.userData.default_disposal_type || 'master',
			// 	default_disposal_list: authReducer.userData?.default_disposal_list || '',
			// }
			// dispatch(actions.getForcedDisposalToSample(apolloClient.probing, requestParam));

			/* get campaigns for map */
			const isadmin = authReducer.userData.email === authReducer.userData.owner;
			const request = {
				other_company: authReducer.userData.other_company,
				companyname: authReducer.userData.company_name,
				projectid: authReducer.userData.projectid,
				isadmin,
			};

			dispatch(actions.getCampaignsForMap(apolloClient.campaign, request));
			dispatch(
				actions.getExceptionSetting(apolloClient.masters, {
					company_id: authReducer.userData.company_id,
				})
			);
		}
	}, [authReducer.userData.projectid]);

	useEffect(() => {
		//Get Forcefully selected Disposal to samples
		const requestParam = {
			userid: authReducer.userData.userid,
			projectid: authReducer.userData.projectid,
			default_disposal_type: options.disposalOptions?.selectedDefaultDisposalType || (authReducer.userData.default_disposal_type || 'master'),
			default_disposal_list: options.disposalOptions?.selectedDefaultDisposalList || (authReducer.userData?.default_disposal_list || ''),
		}
		dispatch(actions.getForcedDisposalToSample(apolloClient.probing, requestParam));
	}, [
		authReducer.userData.projectid,
		options.disposalOptions?.selectedDefaultDisposalType,
		options.disposalOptions?.selectedDefaultDisposalList
	]);

	useEffect(() => {
		if (probingReducer.getForcedDisposalToSample) {
			const forced_sampledata = JSON.parse(probingReducer.getForcedDisposalToSample.forced_sampledata);
			setInitialForcedSampleData(forced_sampledata);
		} else {
			setInitialForcedSampleData([]);
		}
	}, [probingReducer?.getForcedDisposalToSample]);

	useEffect(() => {
		if (!disposals?.length) return;
		const requestParams = {
			desposal_ids: JSON.stringify(disposals.map(d => d._id)),
		};
		dispatch(actions.getRuleListOfAllDisposal(apolloClient.masters, requestParams));
	}, [disposals, disposals?.length]);

	useEffect(() => {
		if (!Array.isArray(dataVisualizaionReducer.campaigns)) return;
		const campaigns = dataVisualizaionReducer.campaigns;
		setCampaigns(campaigns);
		let nbp = 0;
		campaigns.forEach(campaign => {
			nbp += campaign.probing_data.length;
		});
		if (!nbp) {
			if (campaigns.length) {
				setLoading(false);
				setError('No probing found');
			}
			return;
		}
		const req = {
			campaign_names: JSON.stringify(campaigns.map(c => c.title)),
			campaign_ids: JSON.stringify(campaigns.map(c => c.campaignid)),
			projectid: authReducer.userData.projectid,
		};
		dispatch(
			actions.getMultipleCampaignTestAnalysisDataListForAnalysisReport(apolloClient.probing, req)
		);
	}, [dataVisualizaionReducer.campaigns?.length]);

	useEffect(() => {
		if (!excelUploadReducer?.exceptionSettingsList?.exception_strings) return;
		setExceptionList(excelUploadReducer.exceptionSettingsList?.exception_strings);
	}, [excelUploadReducer?.exceptionSettingsList?.exception_strings?.length]);

	useEffect(() => {
		if (!masterReducer.alldesposalRuleList?.length) return;
		setDesposalRuleList(masterReducer.alldesposalRuleList);
	}, [masterReducer.alldesposalRuleList?.length, JSON.stringify(masterReducer.alldesposalRuleList)]);

	useEffect(() => {
		if (!projectReducer?.multiplecampaignTestAnalysisData?.data?.length) {
			if (projectReducer?.multiplecampaignTestAnalysisData?.data) {
				setLoading(false);
				setError('No analysis found');
			}
			return;
		}
		setExcelDataList(projectReducer.multiplecampaignTestAnalysisData.data);
		const testList = new Map();
		for (const list of projectReducer.multiplecampaignTestAnalysisData.data) {
			for (const test of list.sheet_resp_data) {
				if (test.Test_Parent_Id) {
					const testType = masterReducer.testTypeList.find(t => t._id === test.Test_Parent_Id);
					if (testType) {
						const chld = testType.children.find(c => c.id === test.Test_Child_Id);
						if (chld) {
							testList.set(chld.id, {
								parentId: testType._id,
								parentName: testType.name,
								testName: chld.name,
							});
						}
					}
				}
			}
		}
		const pollutantList = Array.from(testList).map(p => ({ id: p[0], ...p[1] }));
		setPollutantList(pollutantList);
	}, [
		projectReducer?.multiplecampaignTestAnalysisData?.data,
		projectReducer?.multiplecampaignTestAnalysisData?.data?.length,
	]);

	/* ===================== Recheck Last Matched Disposal Again START ===================== */

	//Find the Next Disposals of all Problemetic Pollutants
	const matchWithNextDisposals = (sampleData, problematicPollutant, allDisposalList) => {

		const lastMatchedDisposalIndex = allDisposalList.findIndex(l => l.desposal_id === sampleData.matched_desp.desposal_id);
		const nextDisposal = allDisposalList.filter((l, i) => i > lastMatchedDisposalIndex);

		const problematicTestChildId = problematicPollutant.problematicPollutants.map(
			value => value.tst_child_id
		);

		const probingSamplesToIterate = samples.filter(
			s => s.sampleNo === sampleData.sample_name
		);

		const req = {
			excelDataList: excelDataList,
			selectedDisposalFamily: [],
			alldesposalRuleList: desposalRuleList,
			activedesposalList: nextDisposal,
			allProbingSamples: probingSamplesToIterate,
			exceptionConst: exceptionList,
		};
		let sampleValueAnalysis = sampleDisposalCalulation(req);
		sampleValueAnalysis = sampleValueAnalysis.filter(s => { return problematicTestChildId.includes(s.tst_child_id) });
		sampleValueAnalysis = sampleValueAnalysis.map(({
			matched_desp_info: matched_desp,
			matched_desp: matched_desp_info,
			...rest
		}) => ({
			matched_desp,
			matched_desp_info,
			...rest
		}));
		return sampleValueAnalysis;
	};

	//Find Problemetic pollutants that are not matched with a selected matched disposal
	const checkAllPollutantsValueMatchedWithSelectedDispo = sampleData => {
		if (!Array.isArray(excelDataList) || __.isEmpty(excelDataList)) return;
		if (!Array.isArray(samples) || __.isEmpty(samples)) return;
		if (!Array.isArray(desposalRuleList) || __.isEmpty(desposalRuleList)) return;
		const activedesposalList = [sampleData.matched_desp]; //A sample disposal that is finally selected

		//Find one sample to iterate
		const probingSamplesToIterate = samples.filter(
			s => s.sampleNo === sampleData.sample_name
		);

		const req = {
			excelDataList: excelDataList,
			selectedDisposalFamily: [],
			alldesposalRuleList: desposalRuleList,
			activedesposalList: activedesposalList,
			allProbingSamples: probingSamplesToIterate,
			exceptionConst: exceptionList,
		};

		const sampleValueAnalysis = sampleDisposalCalulation(req);
		let samplewithRuleExist = sampleValueAnalysis.filter(s => s.rule_exist === true);
		samplewithRuleExist = samplewithRuleExist.map(({
			matched_desp_info: matched_desp,
			matched_desp: matched_desp_info,
			...rest
		}) => ({
			matched_desp,
			matched_desp_info,
			...rest
		}));

		let getProblematicPollutnats = [];
		getProblematicPollutnats = samplewithRuleExist.filter(l => Object.keys(l.matched_desp).length === 0);

		let problematicPollutnatsOfSample = {
			sampleName: sampleData.sample_name,
			problematicPollutants: getProblematicPollutnats,
		};

		return problematicPollutnatsOfSample;
	};

	//Check If all pollutants values are matched with last matched disposal threshold values, If not matche then find new next disposal that satisfied with all pollutants values
	const checkSelectedDisposalMatchedWithAllPollutant = (sample_data) => {

		//Check all pollutants values are satisfied with the last matched disposal or not
		const allPollutantMatchedWithDisposal = checkAllPollutantsValueMatchedWithSelectedDispo(
			sample_data
		);

		//If any sample pollutants value is not matched with selected disposal threshold value
		if (allPollutantMatchedWithDisposal?.problematicPollutants.length > 0) {

			const dispo = selectedDisposals || disposals;

			//Check all problematic pollutants (that are not satisfied with the current selected disposal) are satisfied with the all next disposals or not
			const matchedWithNextDisposal = matchWithNextDisposals(
				sample_data,
				allPollutantMatchedWithDisposal,
				dispo
			);

			if (matchedWithNextDisposal.length > 0) {
				const matchedDisposalIndex = [];
				matchedWithNextDisposal.map((mdata, mindex) => {
					//if pollutant matched with next disposal then store disposal index, so we are get the highest disposal index
					if (Object.keys(mdata.matched_desp).length > 0) {
						//Store the next disposal matched index of problematic pollutants
						const disposalIndex = dispo.findIndex(
							t => t.desposal_id == mdata.matched_desp.desposal_id
						);
						matchedDisposalIndex.push(disposalIndex);
					}
				});

				//Check all problematic pollutants matched with next disposal or not
				const isAllProblematicPollutantMatchedWithNextDispo = matchedWithNextDisposal.filter(
					l => Object.keys(l.matched_desp).length === 0
				);
				//If all problematic pollutants are matched with the next disposals
				if (isAllProblematicPollutantMatchedWithNextDispo.length == 0) {
					//Finding the maximum disposal index of problematic pollutants of this sample
					const maxDisposalIndex = Math.max(...matchedDisposalIndex);
					const finalDisposal = dispo[maxDisposalIndex];

					const tempSdata = {
						matched_desp: finalDisposal,
						sample_campaign: sample_data.sample_campaign,
						sample_name: sample_data.sample_name,
					};

					//Check Again with the Max disposal of all problematic pollutant
					const allPollutantMatchedWithDisposal = checkAllPollutantsValueMatchedWithSelectedDispo(
						tempSdata
					);

					//Max disposal contains the problematic pollutants.
					if (allPollutantMatchedWithDisposal.problematicPollutants.length > 0) {
						//Cycling the process again by calling the same function with the Max disposal
						checkSelectedDisposalMatchedWithAllPollutant(
							tempSdata
						);
					} else {
						//If there is not any problematic pollutant in the Max disposal then Assign the next disposal to this sample
						sample_data.matched_desp = {
							desposal_id: finalDisposal.desposal_id,
							name: finalDisposal.name,
							color: finalDisposal.color
						};
						sample_data.desposal_color = finalDisposal.color;
						sample_data.desposal_id = finalDisposal.desposal_id;
						sample_data.desposal_name = finalDisposal.name;
					}
				} else {
					sample_data.matched_desp = {};
					sample_data.desposal_color = '';
					sample_data.desposal_id = '';
					sample_data.desposal_name = '';
				}
			} else {
				sample_data.matched_desp = {};
				sample_data.desposal_color = '';
				sample_data.desposal_id = '';
				sample_data.desposal_name = '';
			}
		}

		return sample_data;
	};

	/* ===================== Recheck Last Matched Disposal Again END ===================== */

	const findSampleInfo = samples =>
		new Promise((resolve, reject) => {
			try {
				// console.time('findSampleInfo');
				const dispo = selectedDisposals || disposals;
				const disp = dispo.map(sd => ({
					desposal_id: sd.desposal_id || sd.value,
					name: sd.name || sd.label,
					color: sd.color,
				}));
				const req = {
					excelDataList: excelDataList,
					selectedDisposalFamily: [],
					alldesposalRuleList: desposalRuleList, // .filter(r => r.desposal_id in disposalIds)
					activedesposalList: disp,
					allProbingSamples: samples,
					exceptionConst: exceptionList,
				};
				// console.time('sampleDisposalCalulation');
				const sampl_finl1 = sampleDisposalCalulation(req);
				// console.timeEnd('sampleDisposalCalulation');
				// console.time('reduce');
				const group_smpl = sampl_finl1.reduce((r, a) => {
					r[a.sample_name] = [...(r[a.sample_name] || []), a];
					return r;
				}, {}); //Group by sample_name
				// console.timeEnd('reduce');
				// console.log(`group_smpl`, group_smpl);
				//contains all sample name array
				const group_smpl_keys = Object.keys(group_smpl);
				let all_smpl_info = [];
				//Loop through all samples
				// console.time('loop');
				for (const fil_keys of group_smpl_keys) {
					const fil_grp = group_smpl[fil_keys];
					const sampleCampaignId = fil_grp[0].campaignid;
					let final_Desp = {};
					let all_test_match = true;
					let chkForMissingPollutant = true;

					//Find out if any pollutant value is matched with No limit* rule
					let polluMtcWithNolmtFinlVal = fil_grp.filter(l => l.chkForMissingPollutant === false);

					//loop of all testnames (pollutants) of particular samples
					//consider only sample who have rules value in selected disposal for disposal selection process (itr_fil_grp.rule_exist == true)
					//itr_fil_grp.stringnotmatched == false, do not consider the sample value like n.a, not or any string while the final disposal selection. stringnotmatched = true for all values like n.a, not etc...
					for (let itr_fil_grp of fil_grp) {
						if (!polluMtcWithNolmtFinlVal.length) {
							if (
								!itr_fil_grp.matched_desp &&
								itr_fil_grp.rule_exist &&
								!itr_fil_grp.stringnotmatched
							) {
								all_test_match = false;
							}
							if (
								itr_fil_grp.rule_exist &&
								itr_fil_grp.stringnotmatched &&
								!__.isEmpty(itr_fil_grp.value)
							) {
								all_test_match = false;
							}
						}

						if (itr_fil_grp.chkForMissingPollutant === false) {
							chkForMissingPollutant = false;
						}
					}

					//Get the Highest Order Disposal
					if (all_test_match) {
						for (const fil_desp of disp) {
							const match_desp = fil_grp.filter(val => val.matched_desp === fil_desp.desposal_id);
							if (match_desp.length > 0) {
								final_Desp = fil_desp; //highest order matched
							}
						}
					}
					let keyIndex = 0;
					all_smpl_info.push({
						sample_name: fil_keys,
						sample_campaign: sampleCampaignId,
						chkForMissingPollutant: chkForMissingPollutant,
						isForcedDisposal: false, //initially false, if any sample matched forcefully then it is true
						desposal_id: final_Desp?.desposal_id || '',
						desposal_color: final_Desp?.color || '',
						desposal_name: final_Desp?.name || '',
						middel_depth: fil_grp[keyIndex].middel_depth,
						matched_desp: final_Desp,
						value: fil_grp[keyIndex].value,
					});
				}

				//My code For checking selected disposal family and child ids with excel analysis data that all pollutants of selected disposal are present in analysis campaignwise
				if (all_smpl_info.length > 0) {
					const preparedData = prepareDataForAnalysis(sampl_finl1);
					const req = {
						all_smpl_info, //Final samples arr with matched disposal id [ Required argument : sample_campaign, desposal_id ]
						all_excel_uploaded_sample: sampl_finl1, //All excel uploaded sample info [ Required argument : campaignid, parent_testfamily_id, tst_child_id ]
						alldesposalRuleList: desposalRuleList, //All selected disposal rule list
						needMissingPollutantsArr: false, //if you need arr of all missing pollutants ids
						preparedData,
					};
					// console.timeEnd('loop');
					// complete sample info with layer and probing data
					console.time('compareAnalysisAndDisposalPollutant');
					const returnInfo = compareAnalysisAndDisposalPollutant(req);
					console.timeEnd('compareAnalysisAndDisposalPollutant');

					/*================ Code start for disposal checking ================*/
					//Loop through all samples to recheck its last matched disposal that all pollutant values are satisfied with the selected disposal or not
					returnInfo.all_smpl_info.map((sample_data) => {
						if (!__.isEmpty(sample_data.matched_desp) &&
							sample_data.chkForMissingPollutant === true
						) {
							sample_data = checkSelectedDisposalMatchedWithAllPollutant(sample_data);
						}
					});
					/*================ Code end for disposal checking ================*/


					/*================== Code start for Set Forcefully Disposal to a sample ==================*/
					if (initialForcedSampleData.length > 0) {
						returnInfo.all_smpl_info.map((d) => {
							if (!__.isEmpty(d.matched_desp) || __.isEmpty(d.matched_desp)) {
								const getForcedDispRec = initialForcedSampleData.find(f => f.sampleName === d.sample_name && f.sampleCampaign === d.sample_campaign);
								if (!__.isEmpty(getForcedDispRec)) {
									const getDispoIdx = disp.findIndex(i => i.desposal_id === getForcedDispRec.desposalId);
									d.matched_desp = { ...disp[getDispoIdx] };
									d.desposal_color = d.matched_desp.color;
									d.desposal_id = d.matched_desp.desposal_id;
									d.desposal_name = d.matched_desp.name;
									d.isForcedDisposal = true;
								}
							}
						})
					}
					/*================== Code end for Set Forcefully Disposal to a sample ==================*/

					// console.timeEnd('findSampleInfo');

					resolve(returnInfo.all_smpl_info);
				}
				reject('No samples found');
			} catch (error) {
				reject(error);
			}
		});

	useEffect(() => {
		// if (!Array.isArray(excelDataList)) return;
		if (!Array.isArray(desposalRuleList)) return;
		if (!Array.isArray(exceptionList)) return;
		if (!Array.isArray(probings)) return;
		if (!probings.length) return;
		if (loadingDisposals) return;
		const samples = [];
		for (const prob of probings) {
			const layers = prob.layer || prob.layers;
			if (layers) {
				for (const layer of layers) {
					for (const sample of layer.sample) {
						samples.push({
							probingCode: prob.probingcode || prob.probingname,
							probingName: prob.probingname || prob.probingcode,
							sampleNo: sample.sampleNo,
							sampleName: sample.sampleName,
							campaignid: prob.campaignid,
							altitude: Number(prob.altitude),
							longitude: prob.longitude || prob.lng,
							latitude: prob.latitude || prob.lat,
							top: Number(sample.sampleNameTop || 0),
							bottom: Number(sample.sampleNameBottom || 0),
							length: Number(sample.sample_length || 0),
							observation: sample.observation,
							color: CLR_NO_SAMPLE_ANALYSIS_UPLOADED,
							disposal: mapbox_sample_has_no_analysis,
						});
					}
				}
			}
		}
		setSamples(samples);
		if (!Array.isArray(excelDataList)) return; //i move here because samples array blank if no exceldata uploaded, but we want samples array in probing graph in data creation
		findSampleInfo(samples)
			.then(all_smpl_info => {
				setError(null);
				setSampleInfo([...all_smpl_info]);
			})
			.catch(error => setError(error))
			.finally(() => setLoading(false));
	}, [excelDataList, desposalRuleList, exceptionList, probings, disposals, selectedDisposals]);

	return {
		loading: loading || loadingDisposals,
		sampleInfo,
		samples,
		error,
		campaigns,
		pollutantList,
		excelDataList,
		exceptionList,
		disposalList: disposals,
	};
}
