import React from 'react';
import * as actionTypes from "../actionTypes";
import { gql } from "apollo-boost";
import { Encrypt } from "../../shared/encrpt";
import * as actions from "../index";
import * as ChangeAppLanguageFront from "../../../App/view/Layout/ChangeAppLanguageFront";
import notificationMsg  from '../../../App/view/Layout/NotificationMessage';
let tlang = ChangeAppLanguageFront.translateLanguage;

const mapsaved = tlang('notificationSuccess_dataVisulization_mapsaved') || 'Map Saved Successfully';
const mapupdate = tlang('notificationSuccess_dataVisulization_mapupdate') || 'Map Updated Successfully';
const mapdel = tlang('notificationSuccess_dataVisulization_mapdel') || 'Map Deleted Successfully';
const mapaddimg = tlang('notificationSuccess_dataVisulization_addimg') || 'Image Added Successfully';
const mapdelimg = tlang('notificationSuccess_dataVisulization_delimg') || 'Image Deleted Successfully';
const error_something_wrong = tlang('error_something_wrong') || 'Something Went Wrong!';
const map_export_data = tlang('notificationSuccess_dataVisulization_map_export_data') || 'Map Document Saved Successfully';
const map_export_data_delete = tlang('map_export_data_delete') || 'Map Document Deleted Successfully';
const mapnameupdate = tlang('notificationSuccess_dataVisulization_mapnameupdate') || 'Map Name Updated Successfully';

// notification Fail
const notificationFail = err => {
  let msg = error_something_wrong;
  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    // msg = err.graphQLErrors[0].message;
    let translateMsg =notificationMsg(err.graphQLErrors[0].statusCode)
    msg = translateMsg;
  } else if (err.message) {
    msg = err.message;
  }
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_FAIL,
    state: msg
  };
};

// get Campaign For Map Success
const getCampaignForMapSuccess = data => {
  return {
    type: actionTypes.GET_CAMPAIGNS_FOR_MAP,
    state: data
  };
};

// get Campaigns for map
export const getCampaignsForMap = (campaign, request) => {
  return dispatch => {
    const query = gql`
      query get_campaign_probing_map($companyname: String! , $projectid: String! , $other_company: Boolean , $isadmin: Boolean!) {
        get_campaign_probing_map(companyname: $companyname, projectid: $projectid, other_company: $other_company, isadmin: $isadmin) { 
          _id
          campaignid
          title
          description
          projectid
          projectname
          probingid
          probingname
          companyid
          reference
          startDate
          noOfProbing
          expectedProbing      
          brandname
          model
          sincedate
          symbol
          color
          isapproved
          status
          onsiteExpertName
          probing_data
        }
      }
    `;
    campaign
      .query({
        query: query,
        variables: {
          companyname: Encrypt(request.companyname),
          projectid: Encrypt(request.projectid),
          other_company: request.other_company,
          isadmin: request.isadmin
        }
      })
      .then(response => {
        dispatch(getCampaignForMapSuccess(response.data.get_campaign_probing_map));
      })
      .catch(error => {
        //dispatch(notificationFail(error));
      });
  };
};

// notification Success
const notificationSuccess = msg => {
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_SUCCESS,
    state: msg
  };
};

// save Map Details Success
const saveMapDetailsSuccess = data => {
  return {
    type: actionTypes.SAVE_MAPVISUALIZE_DATA,
    state: data
  };
};

// update Map Details Success
const updateMapDetailsSuccess = data => {
  return {
    type: actionTypes.UPDATED_MAPVISUALIZE_DATA,
    state: data
  };
};


// save map data
export const saveMapDetails = (campaign, request) => { 
  return dispatch => {
    const mutation = gql`
      mutation create_mapvisualize_data(
        $name: String!
        $projectid: String!
        $user_id: String!
        $couch_ref_id: String!
        $boundries: String!
        $origins: String!
        $destinations: String!
        $grids: String!
        $images: String!
        $lines_polygons: String!
        $labels: String!
        $paths: String!
        $tables: String!
        $charts: String!
        $map_filters: String!
        $campaigns_group: String!
        $origins_group: String!
        $boundries_group: String!
        $destinations_group: String!
        $pollution_zone_group: String!
        $group_check_status: String!
        $campaigns: String
      ) {
        create_mapvisualize_data(
          name: $name
          projectid: $projectid
          user_id: $user_id
          couch_ref_id: $couch_ref_id
          boundries: $boundries
          origins: $origins
          destinations: $destinations
          grids: $grids
          images: $images
          lines_polygons: $lines_polygons
          labels: $labels
          paths: $paths
          tables: $tables
          charts: $charts
          map_filters: $map_filters
          campaigns_group: $campaigns_group
          origins_group: $origins_group
          boundries_group: $boundries_group
          destinations_group: $destinations_group
          pollution_zone_group: $pollution_zone_group
          group_check_status: $group_check_status
          campaigns: $campaigns
        ) {
          _id
          data_id
          name
          projectid
          user_id
          couch_ref_id
          boundries
          origins
          destinations
          grids
          images
          lines_polygons
          labels
          paths
          tables
          charts
          map_filters
          campaigns_group
          origins_group
          boundries_group
          destinations_group
          pollution_zone_group
          group_check_status
          campaigns
        }
      }
    `;
    campaign
      .mutate({
        mutation: mutation,
        variables: {
          name: Encrypt(request.name),
          projectid: Encrypt(request.projectid),
          user_id: Encrypt(request.user_id),
          couch_ref_id: Encrypt(request.couch_ref_id),
          boundries: Encrypt(request.boundries),
          origins: Encrypt(request.origins),
          destinations: Encrypt(request.destinations),
          grids: Encrypt(request.grids),
          images: Encrypt(request.images),
          lines_polygons: Encrypt(request.lines_polygons),
          labels: Encrypt(request.labels),
          paths: Encrypt(request.paths),
          tables: Encrypt(request.tables),
          charts: Encrypt(request.charts),
          map_filters: Encrypt(request.map_filters),
          campaigns_group: Encrypt(request.campaigns_group),
          origins_group: Encrypt(request.origins_group),
          boundries_group: Encrypt(request.boundries_group),
          destinations_group: Encrypt(request.destinations_group),
          pollution_zone_group: Encrypt(request.pollution_zone_group),
          group_check_status: Encrypt(request.group_check_status),
          campaigns: Encrypt(request.campaigns)
        }
      })
      .then(response => {
        dispatch(notificationSuccess(mapsaved));
        dispatch(saveMapDetailsSuccess(response.data.create_mapvisualize_data));
        dispatch(updateMapDetailsSuccess(response.data.create_mapvisualize_data));

        let mapParams = {
          companyname: request.companyname,
          projectid: request.projectid,
          other_company: request.other_company,
          isadmin: request.isadmin,
        }

        dispatch(actions.getmapvisualizedatalist(campaign, mapParams));

      })
      .catch(error => {
        dispatch(notificationFail(error));
      });

  };
};

// get Map Visualize Data List Success
const getMapVisualizeDataListSuccess = data => {
  return {
    type: actionTypes.GET_MAPVISUALIZE_DATA_LIST,
    state: data
  };
};

// get saved map data list
export const getmapvisualizedatalist = (campaign, request) => {
  return dispatch => {
    const query = gql`
      query get_mapvisualize_data_list($companyname: String! , $projectid: String!) {
        get_mapvisualize_data_list(companyname: $companyname, projectid: $projectid) { 
          _id
          data_id
          name
          projectid
          user_id
          couch_ref_id
          boundries
          origins
          destinations
          grids
          images
          lines_polygons
          labels
          paths
          tables
          charts
          map_filters
          campaigns_group
          origins_group
          boundries_group
          destinations_group
          pollution_zone_group
          group_check_status
          campaigns
        }
      }
    `;
    campaign
      .query({
        query: query,
        variables: {
          companyname: Encrypt(request.companyname),
          projectid: Encrypt(request.projectid)
        }
      })
      .then(response => {
        dispatch(getMapVisualizeDataListSuccess(response.data.get_mapvisualize_data_list));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

// get Selected Map Success
const getSelectedMapSuccess = data => {
  return {
    type: actionTypes.GET_SELECTED_MAPVISUALIZE_DATA,
    state: data
  };
};


// get selected map data
export const getselectedmap = (campaign, requestNew) => {

  let request = {
    companyname: requestNew.companyname,
    mapid : requestNew.mapid,
    projectid : requestNew.projectid ? requestNew.projectid : "",
    map_name : requestNew.map_name ? requestNew.map_name : ""
  }
  
  return dispatch => {
    const query = gql`
      query get_mapvisualize_detail($companyname: String! , $mapid: String!, $projectid: String, $map_name: String) {
        get_mapvisualize_detail(companyname: $companyname, mapid: $mapid, projectid: $projectid, map_name: $map_name) { 
          _id
          data_id
          name
          projectid
          user_id
          couch_ref_id
          boundries
          origins
          destinations
          grids
          images
          lines_polygons
          labels
          paths
          tables
          charts
          map_filters
          campaigns_group
          origins_group
          boundries_group
          destinations_group
          pollution_zone_group
          group_check_status
          campaigns
        }
      }
    `;
    campaign
      .query({
        query: query,
        variables: {
          companyname: Encrypt(request.companyname),
          mapid: Encrypt(request.mapid),
          projectid: Encrypt(request.projectid),
          map_name: Encrypt(request.map_name)
        }
      })
      .then(response => {
        dispatch(getSelectedMapSuccess(response.data.get_mapvisualize_detail));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

// save map data
export const updateMapName = (campaign, request) => {
  return dispatch => {
    const mutation = gql`
      mutation update_map_name(
        $id: String!
        $name: String!
      ) {
        update_map_name(
          id: $id
          name: $name
        ) {
          _id
          data_id
          name
          projectid
          user_id
          couch_ref_id
          boundries
          origins
          destinations
          grids
          images
          lines_polygons
          labels
          paths
          tables
          charts
          map_filters
          origins_group
          boundries_group
          destinations_group
          pollution_zone_group
          group_check_status
          campaigns
        }
      }
    `;
    campaign
      .mutate({
        mutation: mutation,
        variables: {
          id: Encrypt(request.id),
          name: Encrypt(request.name),
        }
      })
      .then(response => {
        let newRequest = {
          companyname: request.companyname,
          projectid: request.projectid,
        }
        dispatch(actions.getmapvisualizedatalist(campaign, newRequest))
        dispatch(notificationSuccess(mapnameupdate));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });

  };
};

// save map data
export const updateMapDetails = (campaign, request) => {
  return dispatch => {
    const mutation = gql`
      mutation update_mapvisualize_data(
        $data_id: String!
        $name: String!
        $projectid: String!
        $user_id: String!
        $boundries: String!
        $origins: String!
        $destinations: String!
        $grids: String!
        $images: String!
        $lines_polygons: String!
        $labels: String!
        $paths: String!
        $tables: String!
        $charts: String!
        $map_filters: String!
        $campaigns_group: String!
        $origins_group: String!
        $boundries_group: String!
        $destinations_group: String!
        $pollution_zone_group: String!
        $group_check_status: String!
        $campaigns: String
      ) {
        update_mapvisualize_data(
          data_id: $data_id
          name: $name
          projectid: $projectid
          user_id: $user_id
          boundries: $boundries
          origins: $origins
          destinations: $destinations
          grids: $grids
          images: $images
          lines_polygons: $lines_polygons
          labels: $labels
          paths: $paths
          tables: $tables
          charts: $charts
          map_filters: $map_filters
          campaigns_group: $campaigns_group
          origins_group: $origins_group
          boundries_group: $boundries_group
          destinations_group: $destinations_group
          pollution_zone_group: $pollution_zone_group
          group_check_status: $group_check_status
          campaigns: $campaigns
        ) {
          _id
          data_id
          name
          projectid
          user_id
          couch_ref_id
          boundries
          origins
          destinations
          grids
          images
          lines_polygons
          labels
          paths
          tables
          charts
          map_filters
          campaigns_group
          origins_group
          boundries_group
          destinations_group
          pollution_zone_group
          group_check_status
          campaigns
        }
      }
    `;
    campaign
      .mutate({
        mutation: mutation,
        variables: {
          data_id: Encrypt(request.data_id),
          name: Encrypt(request.name),
          projectid: Encrypt(request.projectid),
          user_id: Encrypt(request.user_id),
          boundries: Encrypt(request.boundries),
          origins: Encrypt(request.origins),
          destinations: Encrypt(request.destinations),
          grids: Encrypt(request.grids),
          images: Encrypt(request.images),
          lines_polygons: Encrypt(request.lines_polygons),
          labels: Encrypt(request.labels),
          paths: Encrypt(request.paths),
          tables: Encrypt(request.tables),
          charts: Encrypt(request.charts),
          map_filters: Encrypt(request.map_filters),
          campaigns_group: Encrypt(request.campaigns_group),
          origins_group: Encrypt(request.origins_group),
          boundries_group: Encrypt(request.boundries_group),
          destinations_group: Encrypt(request.destinations_group),
          pollution_zone_group: Encrypt(request.pollution_zone_group),
          group_check_status: Encrypt(request.group_check_status),
          campaigns: Encrypt(request.campaigns),
        }
      })
      .then(response => {
        dispatch(updateMapDetailsSuccess(response.data.update_mapvisualize_data));
        if(!request?.disSuccessNoti) { //Not display this notification while import probing process
          dispatch(notificationSuccess(mapupdate));
        }
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });

  };
};

// delete map data
export const deleteMapDetails = (campaign, request) => {
  return dispatch => {
    const mutation = gql`
      mutation delete_mapvisualize_data(
        $data_id: String!
      ) {
        delete_mapvisualize_data(
          data_id: $data_id
        ) {
          message
          status
        }
      }
    `;
    campaign
      .mutate({
        mutation: mutation,
        variables: {
          data_id: Encrypt(request.data_id)
        }
      })
      .then(response => {
        dispatch(notificationSuccess(mapdel));
        window.location.reload();
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });

  };
};

// get Image Gallery List Success
const getImageGalleryListSuccess = data => {
  return {
    type: actionTypes.GET_MAP_IMAGE_GALLERY,
    state: data
  };
};

// get image gallery list
export const getImageGalleryList = (campaign, request) => {
  return dispatch => {
    const query = gql`
      query get_map_image_gallery($projectid: String!) {
        get_map_image_gallery(projectid: $projectid) { 
          _id
          projectid
          userid
          type
          file_name
          name
          description
          url
          created_at
          updated_at
        }
      }
    `;
    campaign
      .query({
        query: query,
        variables: {
          projectid: Encrypt(request.projectid)
        }
      })
      .then(response => {
        dispatch(getImageGalleryListSuccess(response.data.get_map_image_gallery));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

// Create image gallery
export const createImageGallery = (client, request) => {
  return dispatch => {
    const mutation = gql`
          mutation create_map_image_gallery($id: String, $projectid: String!, $name: String!, $description: String!, $type: String!, $file_name: String!, $companyid: String!) {
            create_map_image_gallery(id: $id, projectid: $projectid, name: $name, description: $description, type: $type, file_name: $file_name, companyid: $companyid) {
              _id
              projectid
              type
              file_name
              name
              description
            }
          }
        `;
    client.mutate({
      mutation: mutation,
      variables: {
        id: Encrypt(request.id),
        projectid: Encrypt(request.projectid),
        companyid: Encrypt(request.companyid),
        type: Encrypt(request.type),
        file_name: Encrypt(request.filename),
        name: Encrypt(request.name),
        description: Encrypt(request.description)
      }
    })
      .then(response => {
        dispatch(notificationSuccess(mapaddimg))
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}

// delete image gallery
export const deleteImageGallery = (client, request) => {
  return dispatch => {
    const mutation = gql`
          mutation delete_map_image_gallery($id: String!) {
            delete_map_image_gallery(id: $id) {
                message
                error
                success
            }
          }
        `;
    client.mutate({
      mutation: mutation,
      variables: {
        id: Encrypt(request.id)
      }
    })
      .then(response => {
        let newRequest = {
          projectid: request.projectid,
        }
        dispatch(actions.getImageGalleryList(client, newRequest))
        dispatch(notificationSuccess(mapdelimg))
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}

// get Test Analysis Data By Probing Success
const getTestAnalysisDataByProbingSuccess = data => {
  return {
    type: actionTypes.GET_TEST_ANALYSIS_PROBING_DATA,
    state: data
  };
};

// get test analysis data by probing
export const getTestAnalysisDataByProbing = (campaign, request) => {
  return dispatch => {
    const query = gql`
      query get_test_analysis_data_by_probing($projectid: String!,$probingid: String!) {
        get_test_analysis_data_by_probing(projectid: $projectid,probingid: $probingid) { 
          status
          resp_data
        }
      }
    `;
    campaign
      .query({
        query: query,
        variables: {
          projectid: Encrypt(request.projectid),
          probingid: Encrypt(request.probingid)
        }
      })
      .then(response => {
        dispatch(getTestAnalysisDataByProbingSuccess(response.data.get_test_analysis_data_by_probing));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};


// get Test Analysis Data By Probing Success
const getMultiplePollutantwiseTestAnalysisDataSuccess = data => {
  return {
    type: actionTypes.GET_MULTIPLE_POLLUTANT_WISE_TEST_ANALYSIS_DATA,
    state: data
  };
};


// get test analysis data by probing
export const getMultiplePollutantwiseTestAnalysisData = (campaign, request) => {
  return dispatch => {
    const query = gql`
      query get_sample_multiple_pollutantwise_analysis_data($projectid: String!,$test_arr: String!) {
        get_sample_multiple_pollutantwise_analysis_data(projectid: $projectid,test_arr: $test_arr) { 
          status
          resp_data
        }
      }
    `;
    campaign
      .query({
        query: query,
        variables: {
          projectid: Encrypt(request.projectid),
          test_arr: Encrypt(request.test_arr)
        }
      })
      .then(response => {
        dispatch(getMultiplePollutantwiseTestAnalysisDataSuccess(response.data.get_sample_multiple_pollutantwise_analysis_data));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};





// get Test Analysis Data By Probing Success
const getTestAnalysisDataByProjectWiseSuccess = data => {
  return {
    type: actionTypes.GET_TEST_ANALYSIS_DATA_PROJECT_WISE,
    state: data
  };
};

export const getTestAnalysisDataByProjectWise = (campaign, request) => {
  return dispatch => {
    const query = gql`
      query get_test_analysis_data_by_project_wise($projectid: String!) {
        get_test_analysis_data_by_project_wise(projectid: $projectid) { 
          status
          resp_data
        }
      }
    `;
    campaign
      .query({
        query: query,
        variables: {
          projectid: Encrypt(request.projectid)
        }
      })
      .then(response => {
        dispatch(getTestAnalysisDataByProjectWiseSuccess(response.data.get_test_analysis_data_by_project_wise));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};



// get Test Analysis Data By Multiple Probing Success
const getTestAnalysisDataByMultipleProbingSuccess = data => {
  return {
    type: actionTypes.GET_TEST_ANALYSIS_MULTIPLE_PROBING_DATA,
    state: data
  };
};

// get Test Analysis Data By Multiple Probing
export const getTestAnalysisDataByMultipleProbing = (campaign, request) => {
  return dispatch => {
    const query = gql`
      query get_test_analysis_data_by_multiple_probing($projectid: String!,$probing_list: String!) {
        get_test_analysis_data_by_multiple_probing(projectid: $projectid,probing_list: $probing_list) { 
          status
          resp_data
        }
      }
    `;
    campaign
      .query({
        query: query,
        variables: {
          projectid: Encrypt(request.projectid),
          probing_list: Encrypt(request.probing_list)
        }
      })
      .then(response => {
        dispatch(getTestAnalysisDataByMultipleProbingSuccess(response.data.get_test_analysis_data_by_multiple_probing));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

// get map export data list success
const getMapExportDataListSuccess = data => {
  return {
    type: actionTypes.GET_MAP_EXPORT_DATA_LIST,
    state: data
  };
};


// get map export data
export const getMapExportDataList = (campaign, request) => {
  return dispatch => {
    const query = gql`
      query get_map_export_data_list($projectid: String!) {
        get_map_export_data_list(projectid: $projectid) { 
          _id
          projectid
          name
          file_name
          comments
          img_url
          userid
          created_at
          updated_at
        }
      }
    `;
    campaign
      .query({
        query: query,
        variables: {
          projectid: Encrypt(request.projectid)
        }
      })
      .then(response => {
        dispatch(getMapExportDataListSuccess(response.data.get_map_export_data_list));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

// create map export data
export const createMapExportData = (client, request) => {
  return dispatch => {
    const mutation = gql`
          mutation create_map_export_data($projectid: String!,$name: String!,$comments: String!,$img_url: String!) {
            create_map_export_data(projectid: $projectid,name: $name,comments: $comments,img_url: $img_url) {
              status
              error
              message
            }
          }
        `;
    client.mutate({
      mutation: mutation,
      variables: {
        projectid: Encrypt(request.projectid),
        name: Encrypt(request.name),
        comments: Encrypt(request.comments),
        img_url: request.img_url
      }
    })
      .then(response => {
        let newRequest = {
            projectid : request.projectid
        }
        dispatch(actions.getMapExportDataList(client, newRequest))

        dispatch(notificationSuccess(map_export_data))
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}

// delete map export data
export const deleteMapExportData = (client, request) => {
  return dispatch => {
    const mutation = gql`
          mutation delete_map_export_data($id: String!) {
            delete_map_export_data(id: $id) {
                status
                error  
                message
            }
          }
        `;
    client.mutate({
      mutation: mutation,
      variables: {
        id: Encrypt(request.id)
      }
    })
      .then(response => {
        let newRequest = {
            projectid : request.projectid
        }
        dispatch(actions.getMapExportDataList(client, newRequest))

        dispatch(notificationSuccess(map_export_data_delete))
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}
