import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from './sidebar';
import Topbar from './topbar';
import { Icon, Tooltip, IconButton } from '@material-ui/core';

// Main layout of app system. contail sidebar and topbar
class Layout extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className='layout'>
        <Topbar />
        {!this.props.showMenu &&
          <Sidebar toggleMenu={this.props.toggleMenu} showMenu={this.props.showMenu} showSidebar={this.props.showSidebar} toggleStickSidebar={this.props.toggleStickSidebar} />
        } 
      </div>
    );
  }
}

export default withRouter(Layout); //export comnponent
