import React from 'react';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import * as ChangeAppLanguageFront from '../../../../Layout/ChangeAppLanguageFront';
import __ from 'underscore';

const tlang = ChangeAppLanguageFront.translateLanguage;
const logout_confirmation = tlang('logout_confirmation') || 'Logout Confirmation';
const logout_confirmation_msg = tlang('logout_confirmation_msg') || 'Are you sure you want to logout from Aexdo ?';
const cancel_btn = tlang('cancel_btn') || 'Cancel';
const yes_logout = tlang('yes_logout') || 'Yes, Logout';

export default function ModalLogoutConfirmation({ isOpen, onClose, onSave }) {

	return (
		<Modal
			isOpen={isOpen}
			toggle={onClose}
			className={`modal-dialog--success modal-dialog--header`}
		>
			<div className="modal__header">
				<button className="lnr lnr-cross modal__close-btn" type="button" onClick={onClose} />
				<h4 className="bold-text  modal__title">{logout_confirmation}</h4>
			</div>
			<div className="modal__body">
				{logout_confirmation_msg}
			</div>

			<ButtonToolbar className="modal__footer">
				<Button outline={true} color={'success'} onClick={() => onClose()}>
					{cancel_btn}
				</Button>
				<Button color={'success'} onClick={() => onSave()}>
					{yes_logout}
				</Button>
			</ButtonToolbar>
		</Modal>
	);
}
