// useNotifications.js
import React from 'react';
import { toast } from 'react-toastify';

const useNotifications = () => {
  const showSuccess = (message) => {
    toast.success(<div dangerouslySetInnerHTML={{ __html: message }} />);
  };

  const showError = (message) => {
    toast.error(<div dangerouslySetInnerHTML={{ __html: message }} />);
  };

  const showInfo = (message) => {
    toast.info(<div dangerouslySetInnerHTML={{ __html: message }} />);
  };

  const showWarning = (message) => {
    toast.warn(<div dangerouslySetInnerHTML={{ __html: message }} />);
  };

  return {
    showSuccess,
    showError,
    showInfo,
    showWarning,
  };
};

export default useNotifications;