import React, { PureComponent } from 'react';
import Plot from 'react-plotly.js';
import { Card, CardBody, Row, Col } from 'reactstrap';
import * as actions from '../../../Store/actions/index';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../../assets/mapbox/css/mapbox-gl-draw.css';
import LoadingIcon from 'mdi-react/LoadingIcon';
import * as ChangeAppLanguageFront from '../Layout/ChangeAppLanguageFront';
import * as classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Icon } from '@material-ui/core';
import __ from 'underscore';
import * as localeDictionary from 'plotly.js/lib/locales/fr.js';
import * as sampleDisposalCalulation from '../Common/sampleDisposalCalulation';
import {
	compareAnalysisAndDisposalPollutant,
	prepareDataForAnalysis,
} from './Analysis/compareAnalysisAndDisposalPollutant';
import * as checkAllPollutantsMatchedWithSelectedDispo from '../Common/allPollutantsMatchedWithSelectedDispo';
import * as problematicPollutantmatchWithNextDisposals from '../Common/matchWithNextDisposals';

let tlang = ChangeAppLanguageFront.translateLanguage;
let allPollutantsMatchedWithSelectedDispo =
	checkAllPollutantsMatchedWithSelectedDispo.allPollutantsMatchedWithSelectedDispo;
let matchWithNextDisposals = problematicPollutantmatchWithNextDisposals.matchWithNextDisposals;

const client_probing_analysis = tlang('client_probing_analysis') || 'Probing Analysis';
const client_probing_disposal_list = tlang('client_probing_disposal_list') || 'Disposal List';
const client_probing_disposal_order = tlang('client_probing_disposal_order') || 'Disposal Order';
const MIDDLE_DEPTH = tlang('middle_depth') || 'Middel Depth';
const client_probing_analysis_pls_upload_excelsheet = tlang(
	'client_probing_analysis_pls_upload_excelsheet'
) || 'Please upload excelsheet to generate the graph';

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

class ProbingAnalysis extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			chart: [],
			desposalList: [],
			setDesposalListData: false,
			refresh: false,
			allProbingSamples: [],
			excelDataList: [],
			alldesposalRuleList: [],
			standredOnly: false,
			exceptionConst: [],
		};
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	componentDidMount() {
		this.setState({ refresh: true });

		let reqParam = {
			company_name: this.props.authReducer.userData.company_name,
		};
		this.props.getDesposal(this.props.apolloClient.masters, reqParam);

		/* get probing list */
		let requestParams = {
			campaignid: '',
			projectid: this.props.authReducer.userData.projectid,
		};
		this.props.probingListForProbingAnalysis(this.props.apolloClient.probing, requestParams);

		//Get all Exceptions list
		let requestData = {
			company_id: this.props.authReducer.userData.company_id,
		};
		this.props.getExceptionSetting(this.props.apolloClient.masters, requestData);
	}

	componentWillReceiveProps(props) {
		if (!this.state.setDesposalListData) {
			//My code to remove other project disposal and keep selected project disposal
			let projectId = this.props.authReducer.userData.projectid;
			let projectWiseAllDisposal = props.masterReducer.desposalList.filter(l => {
				if (l.type === 'project' && l.projectId !== projectId) {
					return false;
				}
				return true;
			});

			/* received desposal list */
			//  if (props.masterReducer.desposalList.length > 0) {
			if (projectWiseAllDisposal.length > 0) {
				let chart = [];
				let desposalList = [];
				let onlyDesposalId = [];
				//  props.masterReducer.desposalList.map((val,index)=>{
				projectWiseAllDisposal.map((val, index) => {
					let data = {
						name: val.name,
						standard: val.standard,
						index_no: val.index_no,
						text: '',
						desposal_id: val._id,
						color: val.color,
						visible: false,
						x: [],
						y: [],
						z: [],
						mode: 'markers',
						type: 'scatter3d',
						hovertemplate: '',
						marker: {
							color: val.color,
							size: 5,
						},
						hoverlabel: {
							font: {
								//color : 'white'
							},
						},
					};
					chart.push(data);

					let data2 = {
						desposal_id: val._id,
						name: val.name,
						color: val.color,
					};
					desposalList.push(data2);

					onlyDesposalId.push(val._id);

					if (index == 0) {
						// let obj = {
						//     desposal_id : val._id,
						//     name : val.name,
						//     color : val.color
						// }
						// firstDisposal.push(obj);
					}
				});

				//Get Test Rules of All Disposal Id
				let requestParams = {
					desposal_ids: JSON.stringify(onlyDesposalId),
				};
				this.props.getRuleListOfAllDisposal(this.props.apolloClient.masters, requestParams);

				// let requestParams = {
				//     projectid: this.props.authReducer.userData.projectid,
				//     desposal_list: JSON.stringify(firstDisposal)
				// };
				// this.props.getProbing3dAnalysisGraph(this.props.apolloClient.probing, requestParams)

				//For displaying the gray dots initially even if they are not matched with any disposal
				let notuseddisposal = {
					name: '',
					desposal_id: '',
					standard: '',
					index_no: '',
					color: 'grey',
					visible: true,
					x: [],
					y: [],
					z: [],
					mode: 'markers',
					type: 'scatter3d',
					hovertemplate: '',
					marker: {
						color: 'grey',
						size: 5,
					},
					hoverlabel: {
						font: {
							color: 'white',
						},
					},
				};
				chart.push(notuseddisposal);

				this.setState(
					{
						desposalList: desposalList,
						chart: chart,
						setDesposalListData: true,
						// refresh: false
					},
					() => this.forceUpdate()
				);
			}
		}

		/* get All disposal rule list */
		if (props.masterReducer.alldesposalRuleList) {
			if (props.masterReducer.alldesposalRuleList.length > 0) {
				this.setState(
					{
						alldesposalRuleList: props.masterReducer.alldesposalRuleList,
					},
					() => this.forceUpdate()
				);
			}
		}

		/* probing list */
		let getProbingList = props.probingReducer.getProbingList;
		if (getProbingList && this.state.allProbingSamples.length <= 0) {
			let smpl_coll = [];
			for (let fil_prob_data of getProbingList) {
				if (fil_prob_data.status != 'Delete') {
					for (let fil_layer of fil_prob_data.layer) {
						for (let fil_smpl of fil_layer.sample) {
							Object.assign(fil_smpl, {
								probingcode: fil_prob_data.probingname || fil_prob_data.probingcode,
								campaignid: fil_prob_data.campaignid,
								altitude: fil_prob_data.altitude,
								longitude: fil_prob_data.longitude,
								latitude: fil_prob_data.latitude,
							});
							smpl_coll.push(fil_smpl);
						}
					}
				}
			}

			this.setState(
				{
					allProbingSamples: smpl_coll,
				},
				function() {
					if (smpl_coll.length <= 0) {
						this.setState({ refresh: false });
						return;
					}

					/* fire get Excel Data List API after getting the result of all probing list samples */
					if (this.props.authReducer.userData.projectid) {
						let isadmin =
							this.props.authReducer.userData.email == this.props.authReducer.userData.owner
								? true
								: false;
						let request = {
							companyname: this.props.authReducer.userData.company_name,
							projectid: this.props.authReducer.userData.projectid,
							other_company: this.props.authReducer.userData.other_company,
							isadmin: isadmin,
						};
						this.props.getExcelDataListForProbingAnalysis(this.props.apolloClient.probing, request);
					}
				}
			);
		}

		if (props.excelUploadReducer) {
			//Get Compnay Exception list
			if (
				props.excelUploadReducer.exceptionSettingsList &&
				props.excelUploadReducer.exceptionSettingsList != null
			) {
				if (props.excelUploadReducer.exceptionSettingsList.exception_strings.length > 0) {
					let allexceptionlist = props.excelUploadReducer.exceptionSettingsList.exception_strings;

					this.setState({
						exceptionConst: allexceptionlist,
					});
				}
			}

			/* received excel Data List */
			if (props.excelUploadReducer.excelDataList) {
				if (props.excelUploadReducer.excelDataList.length > 0) {
					this.setState(
						{
							excelDataList: props.excelUploadReducer.excelDataList,
						},
						function() {
							let excelDataList = props.excelUploadReducer.excelDataList;
							this.calculateRules(excelDataList);
						}
					);
				} else {
					//Hide the loader if no excelsheet uploaded
					this.setState({ refresh: false });
				}
			}
		}
	}

	checkInnerRule2 = (
		rules,
		sample_val,
		sheet_data,
		fsampl,
		desposal_list,
		auto_true_arr,
		tst_child_id,
		inr_tst_child_id_1
	) => {
		//Here in above argument tst_child_id is the child id of pollutant beign checked.
		//so if child id of pollutant being checked found in rule-2 test pollutants rules then pollutant beign checked getting false automatically.
		//inr_tst_child_id_1 is the childid of the testname that is selected in the dropdown in rule 2
		//so solve the Infinite loops error in Circulr Rule-2 and page getting stuck error.

		let flag = false;
		let condition = rules.formula.condition;

		let my_rules_val = rules.value;
		if (typeof sample_val == 'number') {
			if (!my_rules_val) {
				flag = false;
			} else if (condition == 'less') {
				if (sample_val <= my_rules_val) {
					flag = true;
				}
			} else if (condition == 'greter') {
				if (sample_val >= my_rules_val) {
					flag = true;
				}
			}
		}

		if (flag == false) {
			let all_test_truefalse_arr = [];

			let checkpollutantbeingcheckedexist = rules.formula.test.filter(l => l.id == tst_child_id)
				.length; //Check this to solve the infinite loop issue
			let checkpollutantbeingcheckedexist1 = rules.formula.test.filter(
				l => l.id == inr_tst_child_id_1
			).length; //Check this to solve the infinite loop issue
			if (checkpollutantbeingcheckedexist > 0 || checkpollutantbeingcheckedexist1 > 0) {
				flag = false;
			} else {
				if (typeof sample_val == 'number') {
					for (let inr_tst of rules.formula.test) {
						let inr_flag = false;
						let find_fil = sheet_data.filter(
							t => t.Test_Child_Id == inr_tst.id && t.Test_Parent_Id == inr_tst.Test_Parent_Id
						);
						if (find_fil[0] && find_fil[0][fsampl]) {
							let inr_val = find_fil[0][fsampl];
							let inr_Test_Parent_Id = find_fil[0].Test_Parent_Id;
							let inr_tst_child_id = find_fil[0].Test_Child_Id;

							if ((typeof inr_val == 'number' || typeof inr_val == 'string') && inr_val != '-') {
								for (let fil_desp1 of desposal_list) {
									let rules1 = this.state.alldesposalRuleList.filter(
										t =>
											t.desposal_id == fil_desp1.desposal_id &&
											t.test_id == inr_Test_Parent_Id &&
											t.child_id == inr_tst_child_id
									)[0];

									if (typeof inr_val == 'string' && inr_val != '-' && rules1) {
										for (let fil_text of auto_true_arr) {
											if (inr_val.includes(fil_text) && inr_flag == false) {
												inr_flag = true;
												all_test_truefalse_arr.push(inr_flag);
											}
										}
									}

									if (!inr_flag && rules1 && rules1.option_id == '1') {
										let condition = rules1.formula.condition;
										//rules_val = rules1.value;
										let my_rules_val = rules1.value;
										if (typeof inr_val == 'number') {
											if (!my_rules_val) {
												inr_flag = false;
												all_test_truefalse_arr.push(inr_flag);
											} else if (condition == 'less') {
												if (inr_val <= my_rules_val) {
													inr_flag = true;
													all_test_truefalse_arr.push(inr_flag);
												}
											} else if (condition == 'greter') {
												if (inr_val >= my_rules_val) {
													inr_flag = true;
													all_test_truefalse_arr.push(inr_flag);
												}
											}
										}
									} else if (!inr_flag && rules1 && rules1.option_id == '2') {
										inr_flag = this.checkInnerRule2(
											rules1,
											inr_val,
											sheet_data,
											fsampl,
											desposal_list,
											auto_true_arr,
											tst_child_id,
											inr_tst_child_id_1
										);
										all_test_truefalse_arr.push(inr_flag);
									} else if (!inr_flag && rules1 && rules1.option_id == '3') {
										if (typeof inr_val == 'number') {
											//rules_val = rules.value;
											//rules_val = "No Limit";
											inr_flag = true;
											all_test_truefalse_arr.push(inr_flag);
										}
									} else if (!inr_flag && rules1 && rules1.option_id == '4') {
										let condition = rules1.formula.condition;
										//rules_val = rules.value;
										//rules_val = (condition == "greter" ? ">" : "<") + "" + rules1.formula.percentage + "% of " + rules.formula.test[0].name;
										if (typeof inr_val == 'number') {
											for (let inr_tst2 of rules1.formula.test) {
												let find_fil2 = sheet_data.filter(
													t =>
														t.Test_Child_Id == inr_tst2.id &&
														t.Test_Parent_Id == inr_tst2.Test_Parent_Id
												);
												if (find_fil2[0] && find_fil2[0][fsampl]) {
													let inr_val2 = find_fil2[0][fsampl];
													if (typeof inr_val2 == 'number') {
														//New added line condition
														let calc_val = (inr_val2 * rules1.formula.percentage) / 100;
														if (condition == 'less' && inr_val < calc_val) {
															inr_flag = true;
															all_test_truefalse_arr.push(inr_flag);
														} else if (condition == 'greter' && inr_val > calc_val) {
															inr_flag = true;
															all_test_truefalse_arr.push(inr_flag);
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}

			let trueCount = all_test_truefalse_arr.filter(Boolean).length;
			let totalAddedTest = rules.formula.test.length;
			//All added test pollutats must be satisfied to approve this current pollutant
			if (totalAddedTest == trueCount) {
				flag = true;
			}
		}

		return flag;
	};

	checkSelectedDisposalMatchedWithAllPollutant = (sample_data, all_smpl_info, sample_index) => {
		let request = {
			sample_data: sample_data,
			selectedDisposalFamily: [],
			activedesposalList: sample_data.matched_desp,
			allProbingSamples: this.state.allProbingSamples,
			excelDataList: this.state.excelDataList,
			allPollutants: [],
			alldesposalRuleList: this.state.alldesposalRuleList,
			exceptionConst: this.state.exceptionConst,
		};

		// //Check all pollutants values are satisfied with the last matched disposal or not
		let allPollutantMatchedWithDisposal = allPollutantsMatchedWithSelectedDispo(request);
		//let allPollutantMatchedWithDisposal = this.checkAllPollutantsValueMatchedWithSelectedDispo(sample_data);

		//If any sample pollutants value is not matched with selected disposal threshold value
		if (allPollutantMatchedWithDisposal.problematicPollutants.length > 0) {
			let request1 = {
				sample_data: sample_data,
				allPollutantMatchedWithDisposal: allPollutantMatchedWithDisposal,
				allDisposalList: sample_data.desposal_list,
				selectedDisposalFamily: [],
				//activedesposalList: sample_data.matched_desp,
				allProbingSamples: this.state.allProbingSamples,
				excelDataList: this.state.excelDataList,
				allPollutants: [],
				alldesposalRuleList: this.state.alldesposalRuleList,
				exceptionConst: this.state.exceptionConst,
			};

			//Check all problematic pollutants (that are not satisfied with the current selected disposal) are satisfied with the all next disposals
			let matchedWithNextDisposal = matchWithNextDisposals(request1);

			//let matchedWithNextDisposal = this.matchWithNextDisposals(sample_data,allPollutantMatchedWithDisposal,sample_data.desposal_list);

			//IF next disposal exist to check then it has records
			if (matchedWithNextDisposal.length > 0) {
				let matchedDisposalIndex = [];
				matchedWithNextDisposal.map((mdata, mindex) => {
					//if pollutant matched with next disposal then store its disposal index to array
					if (Object.keys(mdata.matched_desp).length > 0) {
						//Store the next disposal matched index of problematic pollutants
						let disposalIndex = sample_data.desposal_list.findIndex(
							t => t.desposal_id == mdata.matched_desp.desposal_id
						);
						matchedDisposalIndex.push(disposalIndex);
					} else {
					}
				});

				//Check all problematic pollutants matched with next disposal or not
				let isAllProblematicPollutantMatchedWithNextDispo = matchedWithNextDisposal.filter(
					l => Object.keys(l.matched_desp).length === 0
				);

				//If all problematic pollutants are matched with the next disposals
				if (isAllProblematicPollutantMatchedWithNextDispo.length == 0) {
					//Finding the maximum disposal index of problematic pollutants of this sample
					let maxDisposalIndex = Math.max(...matchedDisposalIndex);
					let finalDisposal = sample_data.desposal_list[maxDisposalIndex];
					//finalDisposal.disposal_key = maxDisposalIndex;

					let tempSdata = {
						matched_desp: finalDisposal,
						sample_campaign: sample_data.sample_campaign,
						sample_name: sample_data.sample_name,
						desposal_list: sample_data.desposal_list,
					};

					//Check Again with the Max disposal of all problematic pollutant
					//let allPollutantMatchedWithDisposal = this.checkAllPollutantsValueMatchedWithSelectedDispo(tempSdata);

					let request2 = {
						sample_data: tempSdata,
						selectedDisposalFamily: [],
						activedesposalList: tempSdata.matched_desp,
						allProbingSamples: this.state.allProbingSamples,
						excelDataList: this.state.excelDataList,
						allPollutants: [],
						alldesposalRuleList: this.state.alldesposalRuleList,
						exceptionConst: this.state.exceptionConst,
					};
					let allPollutantMatchedWithDisposal = allPollutantsMatchedWithSelectedDispo(request2);

					//Max disposal contains the problematic pollutants.
					if (allPollutantMatchedWithDisposal.problematicPollutants.length > 0) {
						//Cycling the process again by calling the same function with the Max disposal
						this.checkSelectedDisposalMatchedWithAllPollutant(
							tempSdata,
							all_smpl_info,
							sample_index
						);
					} else {
						//If there is not any problematic pollutant in the Max disposal then Assign the next disposal to this sample
						sample_data.matched_desp = finalDisposal;
						sample_data.desposal_color = finalDisposal.color;
						sample_data.desposal_id = finalDisposal.desposal_id;
						sample_data.desposal_name = finalDisposal.name;
						all_smpl_info[sample_index] = sample_data;
					}
				} else {
					sample_data.matched_desp = {};
					sample_data.desposal_color = '';
					sample_data.desposal_id = '';
					sample_data.desposal_name = '';
					all_smpl_info[sample_index] = sample_data;
				}
			} else {
				sample_data.matched_desp = {};
				sample_data.desposal_color = '';
				sample_data.desposal_id = '';
				sample_data.desposal_name = '';
				all_smpl_info[sample_index] = sample_data;
			}
		}

		return all_smpl_info;
	};

	/* For Calculate rules and display the color dots */
	calculateRules = (excelDataList, selectedDisposalFamily = '') => {
		if (excelDataList.length > 0) {
			//getting all enabled disposal listing
			let activedesposalList = [];
			this.state.chart.map((val, index) => {
				if (val.visible == true && val.name != '') {
					let data2 = {
						desposal_id: val.desposal_id,
						name: val.name,
						color: val.color,
						disposal_key: index,
					};
					activedesposalList.push(data2);
				}
			});

			let matchingSampleWithDisposalRules = sampleDisposalCalulation.sampleDisposalCalulation;

			let requestParams = {
				excelDataList: excelDataList,
				selectedDisposalFamily: selectedDisposalFamily != '' ? selectedDisposalFamily : [],
				alldesposalRuleList: this.state.alldesposalRuleList,
				activedesposalList: activedesposalList,
				allProbingSamples: this.state.allProbingSamples,
				exceptionConst: this.state.exceptionConst,
				default_unit: this.props.authReducer.userData.default_unit,
			};

			let sampl_finl1 = matchingSampleWithDisposalRules(requestParams);

			if (sampl_finl1.length >= 0 && this.state.chart.length > 0) {
				if (sampl_finl1.length > 0 && this.state.chart.length > 0) {
					let all_smpl_info = [];

					//each sample name array contains all pollutants column wise with matched desposal id.
					let group_smpl = sampl_finl1.reduce((r, a) => {
						r[a.sample_name] = [...(r[a.sample_name] || []), a];
						return r;
					}, {}); //Group by sample_name

					//contains all sample name array
					let group_smpl_keys = Object.keys(group_smpl);

					//Loop through all samples
					for (let fil_keys of group_smpl_keys) {
						let fil_grp = group_smpl[fil_keys];
						let sampleCampaignId = fil_grp[0].campaignid;
						let final_Desp = '';
						let final_Desp_obj = {};
						let all_test_match = true;
						let chkForMissingPollutant = true;

						//Find out if any pollutant value is matched with No limit* rule
						let polluMtcWithNolmtFinlVal = fil_grp.filter(l => l.chkForMissingPollutant === false);

						//loop of all testnames (pollutants) of particular samples
						//consider only sample who have rules value in selected disposal for disposal selection process (itr_fil_grp.rule_exist == true)
						//itr_fil_grp.stringnotmatched == false, do not consider the sample value like n.a, not or any string while the final disposal selection. stringnotmatched = true for all values like n.a, not etc...
						for (let itr_fil_grp of fil_grp) {
							if (!polluMtcWithNolmtFinlVal.length) {
								if (
									itr_fil_grp.matched_desp == '' &&
									itr_fil_grp.rule_exist == true &&
									itr_fil_grp.stringnotmatched == false
								) {
									all_test_match = false;
								}

								if (
									itr_fil_grp.rule_exist &&
									itr_fil_grp.stringnotmatched &&
									!__.isEmpty(itr_fil_grp.value)
								) {
									all_test_match = false;
								}
							}

							if (itr_fil_grp.chkForMissingPollutant === false) {
								chkForMissingPollutant = false;
							}
						}

						//Get the Highest Order Disposal
						if (all_test_match) {
							for (let fil_desp of activedesposalList) {
								let match_desp = fil_grp.filter(val => val.matched_desp == fil_desp.desposal_id);
								if (match_desp.length > 0) {
									final_Desp = fil_desp.desposal_id; //highest order matched
									final_Desp_obj = fil_desp;
								}
							}
						}

						//Final Array of all excel uploaded samples with highest order disposal id
						all_smpl_info.push({
							sample_name: fil_keys,
							sample_name_range: fil_grp[0].sample_name_range,
							chkForMissingPollutant: chkForMissingPollutant,
							sample_campaign: sampleCampaignId,
							altitude: fil_grp[0].altitude,
							longitude: fil_grp[0].longitude,
							latitude: fil_grp[0].latitude,
							middel_depth: fil_grp[0].middel_depth,
							desposal_id: final_Desp,
							matched_desp: final_Desp_obj,
							desposal_list: activedesposalList,
						});
					}

					//My code to //Loop through all samples to recheck its last matched disposal that all pollutant values are satisfied with the selected disposal or not
					if (all_smpl_info.length > 0 && activedesposalList.length > 0) {
						all_smpl_info.map((sample_data, sample_index) => {
							//Check if sample matched with any disposal or not
							if (sample_data.matched_desp != '' && sample_data.chkForMissingPollutant === true) {
								all_smpl_info = this.checkSelectedDisposalMatchedWithAllPollutant(
									sample_data,
									all_smpl_info,
									sample_index
								);
							}
						});
					}

					//My code For checking selected disposal family and child ids with excel analysis data that all pollutants of selected disposal are present in analysis campaignwise
					//if any disposal is selected from the left side at disposal column
					if (
						all_smpl_info.length > 0 &&
						this.state.chart.filter(option => option.name != '' && option.visible == true).length >
							0
					) {
						const preparedData = prepareDataForAnalysis(sampl_finl1);

						let requestParams = {
							all_smpl_info: all_smpl_info, //Final samples arr with matched disposal id [ Required argument : sample_campaign, desposal_id ]
							all_excel_uploaded_sample: sampl_finl1, //All excel uploaded sample info [ Required argument : campaignid, parent_testfamily_id, tst_child_id ]
							alldesposalRuleList: this.state.alldesposalRuleList, //All selected disposal rule list
							needMissingPollutantsArr: false, //if you need arr of all missing pollutants ids
							preparedData,
						};
						let returnInfo = compareAnalysisAndDisposalPollutant(requestParams);
						all_smpl_info = returnInfo.all_smpl_info;
					}

					let measure_unit = this.props.authReducer.userData.default_unit
						? this.props.authReducer.userData.default_unit
						: 'M';

					let chart = this.state.chart;
					chart.map((val2, index2) => {
						let x = [],
							y = [],
							z = [],
							sampleNo = [];
						//probing3dAnalysisGraphData.graphData.map((val1,index1)=>{
						all_smpl_info.map((val1, index1) => {
							if (val2.desposal_id == val1.desposal_id) {
								let middel_depth =
									measure_unit == 'M' ? Number(val1.middel_depth) / 100 : val1.middel_depth;

								x.push(val1.latitude);
								y.push(val1.longitude);
								z.push(middel_depth);

								let temp =
									val1.sample_name_range +
									'<br>' +
									MIDDLE_DEPTH +
									': ' +
									middel_depth +
									'<br>' +
									'<extra>' +
									val2.name +
									'</extra>';
								//sampleNo.push(val1.sample_name)
								sampleNo.push(temp);
							}
						});
						val2.x = x;
						val2.y = y;
						val2.z = z;
						//val2.text = sampleNo
						val2.hovertemplate = sampleNo;
					});

					this.setState(
						{
							chart: chart,
							refresh: false,
						},
						() => this.forceUpdate()
					);
				} else {
					this.setState(
						{
							refresh: false,
						},
						() => this.forceUpdate()
					);
				}
			}
		} else {
			this.setState({ refresh: false });
		}
	};

	getChildPollutantIdsOfMatchedDisposal = (d, matchedDisposalId) => {
		let strArr = [];
		let pollutantDataOfFamily = this.state.alldesposalRuleList.filter(
			l => l.desposal_id == matchedDisposalId && d == l.test_id
		);
		if (pollutantDataOfFamily.length > 0) {
			let childpollutantIdsOfFamily = pollutantDataOfFamily.map(value => value.child_id);
			strArr = childpollutantIdsOfFamily.map(function(e) {
				return e.toString();
			});
			return strArr;
		} else {
			return strArr;
		}
	};

	getChildPollutantIdsOfAnalysis = (d, test_list) => {
		let strArr = [];
		let pollutantDataOfFamily = test_list.filter(l => l.parent_testfamily_id == d);
		if (pollutantDataOfFamily.length > 0) {
			let childpollutantIdsOfFamily = pollutantDataOfFamily.map(value => value.tst_child_id);
			strArr = childpollutantIdsOfFamily.map(function(e) {
				return e.toString();
			});
			return strArr;
		} else {
			return strArr;
		}
	};

	//For checking selected disposal family and child ids with excel analysis data that all pollutants of selected disposal are present in analysis campaignwise
	CompareExcelPollutantWithDisposalPollutant = (h, tempAllSample) => {
		if (typeof h.last_matched_desposal.desposal_id != 'undefined') {
			let missingPollutant = {
				status: true,
				requiredPollutant: [],
			};

			//Get all unique parent family ids from analysis
			let analysisFamilyIds = [
				...new Set(
					tempAllSample.map(
						item => item.campaignid == h.sample_campaign && item.parent_testfamily_id
					)
				),
			];
			analysisFamilyIds = analysisFamilyIds.filter(x => x != false);

			let matchedDisposalId = h.last_matched_desposal.desposal_id;

			//Get all unique parent family ids from selected disposal
			let disposalFamilyIds = [
				...new Set(
					this.state.alldesposalRuleList.map(
						item => item.desposal_id == matchedDisposalId && item.test_id
					)
				),
			];
			disposalFamilyIds = disposalFamilyIds.filter(x => x != false);

			//analysis parent family ids must be greate than or equal selected disposal ids of particular sample
			if (analysisFamilyIds.length < disposalFamilyIds.length) {
				missingPollutant.status = false;
			}

			//analysis parent family ids greate than or equal selected disposal ids
			if (analysisFamilyIds.length >= disposalFamilyIds.length && missingPollutant.status == true) {
				//Loop through matched disposal parent family ids to check all childs of disposal parent family present in uploaded excel
				disposalFamilyIds.map(d => {
					let childPollutantIdsOfMatchedDisposal = this.getChildPollutantIdsOfMatchedDisposal(
						d,
						matchedDisposalId
					);
					let childPollutantIdsOfAnalysis = this.getChildPollutantIdsOfAnalysis(d, tempAllSample);

					//length of analysis child family ids of this looped family must be greater than or equal selected disposal childs ids
					if (childPollutantIdsOfAnalysis.length < childPollutantIdsOfMatchedDisposal.length) {
						var getNotUploadedPollutantIds = childPollutantIdsOfMatchedDisposal.filter(function(n) {
							return !this.has(n);
						}, new Set(childPollutantIdsOfAnalysis));
						if (getNotUploadedPollutantIds.length > 0) {
							missingPollutant.requiredPollutant.push(...getNotUploadedPollutantIds);
						}

						missingPollutant.status = false;
					}

					//length of analysis child family ids of this looped family greater than or equal selected disposal childs ids
					if (childPollutantIdsOfAnalysis.length >= childPollutantIdsOfMatchedDisposal.length) {
						//Check all child ids of looped parent family of selected disposal present in the analysis parent child ids array
						var res1 = childPollutantIdsOfMatchedDisposal.filter(function(n) {
							return !this.has(n);
						}, new Set(childPollutantIdsOfAnalysis));
						if (res1.length > 0) {
							missingPollutant.requiredPollutant.push(...res1);
							missingPollutant.status = false;
						}
					}
				});
			}

			return missingPollutant;
		} else {
			return '';
		}
	};

	onDragEnd(result) {
		if (!result.destination) {
			return;
		}

		//My code that user can move the disposal only at the end of the active disposal and not at the disable disposal to solve the fetal error
		let endindex = this.state.chart.filter(l => l.visible == true && l.name != '').length;
		if (result.destination.index >= endindex) {
			result.destination.index = endindex - 1;
		}

		const chart = reorder(this.state.chart, result.source.index, result.destination.index);

		//Call the API after disposal order change
		let desposalList = [];
		chart.map((val, index) => {
			if (val.visible == true && val.name != '') {
				let data2 = {
					desposal_id: val.desposal_id,
					name: val.name,
					color: val.color,
				};
				desposalList.push(data2);
			}
		});

		this.setState(
			{
				chart,
				refresh: desposalList.length > 1 ? true : false,
			},
			function() {
				if (desposalList.length > 1) {
					//Get All selected disposal Testfamily id
					let selectedDisposalFamily = this.getselectedDisposalFamily(desposalList);

					this.calculateRules(this.state.excelDataList, selectedDisposalFamily);
				}
			}
		);
	}

	onDragStart(result) {}

	visibleSurface = index => {
		let selectedBtn = document.querySelector('#btn_' + index);
		let classExist = selectedBtn.classList.contains('btn-success');

		if (classExist) {
			selectedBtn.classList.remove('btn-success');
		} else {
			selectedBtn.classList.add('btn-success');
		}
	};

	visibleChart = (index, visibility) => {
		let selectedBtn = document.querySelector('#btn_' + index);
		let classExist = selectedBtn.classList.contains('btn-success');

		if (classExist) {
			selectedBtn.classList.remove('btn-success');
		} else {
			selectedBtn.classList.add('btn-success');
		}

		this.state.chart[index]['visible'] = !visibility;
		this.forceUpdate();
	};

	getselectedDisposalFamily = desposalList => {
		if (desposalList.length >= 0) {
			let selectedDisposalFamilyArr = [];
			let selectedDisposalFamily = [];

			desposalList.map(l => {
				//Get the Selected Disposal Testfamily
				if (this.props.masterReducer.desposalList.length > 0) {
					let getDisposalTestFamily = this.props.masterReducer.desposalList.filter(
						q => q._id == l.desposal_id
					);
					getDisposalTestFamily[0].test_list.map((z, x) => {
						selectedDisposalFamilyArr.push(z._id);
					});
				}
			});

			selectedDisposalFamily = [...new Set(selectedDisposalFamilyArr)];

			return selectedDisposalFamily;
		}
	};

	render3dAnalysisGraphAgain = () => {
		let desposalList = [];
		this.state.chart.map((val, index) => {
			if (val.visible == true && val.name != '') {
				let data2 = {
					desposal_id: val.desposal_id,
					name: val.name,
					color: val.color,
				};
				desposalList.push(data2);
			}
		});

		if (desposalList.length >= 0) {
			this.setState({
				refresh: desposalList.length > 0 ? true : false,
			});

			//Get All selected disposal Testfamily id
			let selectedDisposalFamily = this.getselectedDisposalFamily(desposalList);

			this.calculateRules(this.state.excelDataList, selectedDisposalFamily);
		}
	};

	arraymove = (arr, fromIndex, toIndex) => {
		var element = arr[fromIndex];
		arr.splice(fromIndex, 1);
		arr.splice(toIndex, 0, element);
		return arr;
	};

	visibleChartNew = (index, visibility) => {
		//this.state.chart[index]['visible'] = !visibility
		//this.forceUpdate()

		let selectedBtn = document.querySelector('#btn_' + index);
		let classExist = selectedBtn.classList.contains('btnRemoveContent');

		if (classExist) {
			let endindex = this.state.chart.filter(l => l.visible == true && l.name != '').length;

			let newOrder = this.arraymove(this.state.chart, index, endindex);
			newOrder[endindex]['visible'] = !visibility;
			this.setState({ chart: newOrder }, () => this.forceUpdate());

			//if(this.state.chart[index].x.length > 0 || this.state.chart[index].y.length > 0 || this.state.chart[index].z.length > 0){
			this.render3dAnalysisGraphAgain();
			//}

			selectedBtn.classList.remove('btnRemoveContent');
		} else {
			let endindex = this.state.chart.filter(l => l.visible == true && l.name != '').length;
			endindex = endindex - 1;

			if (endindex == '-1') {
				endindex = 0;
			}

			let newOrder = this.arraymove(this.state.chart, index, endindex);
			newOrder[endindex]['visible'] = !visibility;
			this.setState({ chart: newOrder }, () => this.forceUpdate());

			//this.state.chart[index]['visible'] = !visibility
			//this.forceUpdate()

			this.render3dAnalysisGraphAgain();
			selectedBtn.classList.add('btnRemoveContent');
		}
	};

	render() {
		let threeDConfig =
			localStorage.getItem('language') == 'fr'
				? {
						displaylogo: false,
						locales: { fr: localeDictionary },
						locale: 'fr',
				  }
				: {
						displaylogo: false,
				  };

		let desposalOrder = [];
		this.state.chart.map((val, index) => {
			if (val.visible == true && val.name != '') {
				let temp = {
					name: val.name,
					color: val.color,
				};
				desposalOrder.push(temp);
			}
		});

		//console.log("chart = ", this.state.chart);

		return (
			<>
				<Row>
					<Col md={12}>
						<h3 className="page-title">{client_probing_analysis}</h3>
					</Col>
				</Row>
				{this.state.refresh ? (
					<div style={{ height: '105%' }} className="panel__refresh">
						<LoadingIcon />
					</div>
				) : (
					''
				)}

				<Row>
					<Card style={{ width: '20%', paddingBottom: '10px' }}>
						<CardBody
							style={{ padding: '0px 0px', height: 'auto', overflowY: 'auto', overflowX: 'hidden' }}
						>
							<Col xs="12" style={{ textAlign: 'center', padding: '5px' }}>
								<h4 style={{ fontWeight: '500' }}>{client_probing_disposal_list}</h4>
							</Col>
						</CardBody>
					</Card>

					<Card style={{ width: '77%', marginLeft: '2%', paddingBottom: '10px' }}>
						<CardBody
							style={{
								lineHeight: '32px',
								padding: '0px 0px',
								height: 'auto',
								overflowY: 'auto',
								overflowX: 'hidden',
							}}
						>
							<Row>
								{this.state.excelDataList.length > 0 && (
									<>
										<Col md={3} style={{ textAlign: 'center' }}>
											<h4 style={{ marginTop: '4px', fontWeight: '500' }}>
												{client_probing_disposal_order}:{' '}
											</h4>
										</Col>
										<Col md={9}>
											{desposalOrder.map((despo, despoindex) => {
												return (
													<>
														<span style={{ marginLeft: '2%', color: despo.color }}>
															{despo.name}
														</span>
														{despoindex + 1 != desposalOrder.length && (
															<span>
																<Icon style={{ marginLeft: '1%', paddingTop: '4px' }}>
																	arrow_forward
																</Icon>
															</span>
														)}
													</>
												);
											})}
										</Col>
									</>
								)}

								{/* {desposalOrder.length == 0 &&
                            <Col md={12} style={{textAlign : "center", padding: "5px"}}>
                            <h4 style={{fontWeight: "500"}}>Please select disposal to generate the graph</h4>
                            </Col>
                        } */}

								{this.state.excelDataList.length == 0 && (
									<Col md={12} style={{ textAlign: 'center', padding: '5px' }}>
										<h4 style={{ fontWeight: '500' }}>
											{client_probing_analysis_pls_upload_excelsheet}
										</h4>
									</Col>
								)}
							</Row>
						</CardBody>
					</Card>
				</Row>

				<Row>
					{/* <Card style={{width:"20%"}}>
                    <CardBody style={{padding:"22px 10px", height:"510px", overflowY:"auto",overflowX: "hidden"}}>
                        <Col xs="2">
                            {this.state.chart.map((val,index)=>{
                                let activeGraph = val.visible ? val.color : ''
                                return(
                                    <Row id={val.id}>
                                        <Col>
                                            <Button className="btnRemoveContent" id={"btn_"+index}
                                            onClick={(e) => this.visibleChart(index,val.visible)} 
                                            color="success" size="sm" style={{ backgroundColor : activeGraph, width:"180px", borderColor: "#E64823" }}  >{(val.name).toUpperCase()}</Button>{' '}
                                        </Col>
                                    </Row>
                                )
                            })}
                        </Col>
                    </CardBody>
                </Card> */}

					<Card style={{ width: '20%' }}>
						<CardBody
							style={{
								padding: '22px 0px',
								height: '510px',
								overflowY: 'auto',
								overflowX: 'hidden',
							}}
						>
							<Col xs="2">
								<DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
									<Droppable droppableId="droppable">
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												className={classNames('list1', snapshot.isDraggingOver && 'draggingOver')}
												{...provided.droppableProps}
											>
												{this.state.chart
													.filter(option => option.name != '')
													.map((item, index) => {
														let activeGraph = item.visible ? item.color : '';
														return (
															<Draggable
																key={item.desposal_id}
																draggableId={`${item.desposal_id}-id`}
																index={index}
																isDragDisabled={item.visible == true ? false : true}
															>
																{(provided, snapshot) => {
																	provided.draggableProps.style = {
																		...provided.draggableProps.style,
																		backgroundColor: activeGraph,
																		cursor: 'pointer',
																	};

																	return (
																		<div
																			id={'btn_' + index}
																			onClick={e => this.visibleChartNew(index, item.visible)}
																			className={classNames(
																				'item1 btnRemoveContent',
																				snapshot.isDragging && 'dragging'
																			)}
																			style={provided.draggableProps.style}
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			{...provided.dragHandleProps}
																		>
																			{item.name}
																		</div>
																	);
																}}
															</Draggable>
														);
													})}
												{provided.placeholder}
											</div>
										)}
									</Droppable>
								</DragDropContext>
							</Col>
						</CardBody>
					</Card>

					<Card style={{ width: '77%', marginLeft: '2%' }}>
						<CardBody>
							<Plot
								data={this.state.chart}
								ref={plotlyRef => {
									this.plotlyRef = plotlyRef;
								}}
								style={{ width: '100%', height: '100%' }}
								layout={{
									autosize: true,
									//   width: 600,
									//   height: 450,
									showlegend: false,
									margin: {
										l: 0,
										r: 0,
										b: 0,
										t: 30,
										pad: 0,
									},
								}}
								config={threeDConfig}
							/>
						</CardBody>
					</Card>
				</Row>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		authReducer: state.authReducer,
		apolloClient: state.apolloClient,
		notification: state.notification,
		masterReducer: state.masterReducer,
		projectReducer: state.projectReducer,
		dataVisualizaionReducer: state.dataVisualizaionReducer,
		analysisReducer: state.analysisReducer,
		probingReducer: state.probingReducer,
		excelUploadReducer: state.excelUploadReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getDesposal: (client, request) => {
			dispatch(actions.getDesposal(client, request));
		},
		getRuleListOfAllDisposal: (client, request) => {
			dispatch(actions.getRuleListOfAllDisposal(client, request));
		},
		getProbing3dAnalysisGraph: (client, request) => {
			dispatch(actions.getProbing3dAnalysisGraph(client, request));
		},
		probingListForProbingAnalysis: (probing, request) => {
			dispatch(actions.probingListForProbingAnalysis(probing, request));
		},
		getExcelDataListForProbingAnalysis: (client, request) => {
			dispatch(actions.getExcelDataListForProbingAnalysis(client, request));
		},
		getExceptionSetting: (masters, request) => {
			dispatch(actions.getExceptionSetting(masters, request));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('common')(ProbingAnalysis)); // export component
