import * as actionTypes from "../actionTypes";
import { gql } from "apollo-boost";
import { Encrypt } from "../../shared/encrpt";
import axios from "axios";
import Server from "../../../Common/Server";
import * as actions from "../index";
import moment from "moment-timezone";
import * as ChangeAppLanguageFront from "../../../App/view/Layout/ChangeAppLanguageFront";
import notificationMsg from "../../../App/view/Layout/NotificationMessage";
let tlang = ChangeAppLanguageFront.translateLanguage;

moment.tz.setDefault('Europe/Paris');

const createcampaign =
  tlang("notificationSuccess_createcampaign") ||
  "Campaign Created Successfully";
const updatecampaign =
  tlang("notificationSuccess_updatecampaign") ||
  "Campaign Updated Successfully";
const campaigndelete =
  tlang("notificationSuccess_campaigndelete") ||
  "Campaign Deleted Successfully";
const assigncampaign =
  tlang("notificationSuccess_assigncampaign") ||
  "Campaign Assigned Successfully";
const closecampaign =
  tlang("notificationSuccess_closecampaign") || "Campaign Closed Successfully";
const opencampaign =
  tlang("notificationSuccess_opencampaign") || "Campaign Re-open Successfully";
const error_something_wrong =
  tlang("error_something_wrong") || "Something Went Wrong!";
const reorderCampaign =
  tlang("reorderCampaign") || "Campaign Re-ordered Successfully";
const creating_account_reviewed_msg =
  tlang(
    "other_notification_sucess_your_request_for_creating_account_has_been_reviewed"
  ) || "creating_account_reviewed_msg";

// notification Success
const notificationSuccess = (msg) => {
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_SUCCESS,
    state: msg,
  };
};

// notification Fail
const notificationFail = (err) => {
  let msg = error_something_wrong;
  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    // msg = err.graphQLErrors[0].message;
    let translateMsg = notificationMsg(err.graphQLErrors[0].statusCode);
    msg = translateMsg;
  } else if (err.message) {
    msg = err.message;
  }
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_FAIL,
    state: msg,
  };
};

//upload file
const uploadFile = async (data, method) => {
  return new Promise(async (resolve, reject) => {
    axios
      .post(Server.ImageAPI + method, data)
      .then(function (response) {
        resolve(creating_account_reviewed_msg);
      })
      .catch(function (error) {
        resolve(creating_account_reviewed_msg);
        // reject(error);
      });
  });
};

// getCampaign List Success
const getCampaignListSuccess = (data) => {
  return {
    type: actionTypes.GET_CAMPAIGN_LIST,
    state: data,
  };
};

// getCampaign List dashboard Success
const getCampaignListDashboardSuccess = (data) => {
  return {
    type: actionTypes.GET_CAMPAIGN_LIST_DASHBOARD,
    state: data,
  };
};

// get staff list action
export const reorderCampaigns = (campaign, request) => {
  return (dispatch) => {
    const mutation = gql`
    mutation reorder_campaign_list(
        $reoderCampaigns: String!
      ) {
        reorder_campaign_list(
          reoderCampaigns: $reoderCampaigns
        ) {
          message
          error
          success
        }
      }
    `;
    campaign
      .mutate({
        mutation: mutation,
        variables: {
          reoderCampaigns: Encrypt(request.reoderCampaigns),
        },
      })
      .then((response) => {
        dispatch(notificationSuccess(reorderCampaign));
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};


// get staff list action
export const getCampaignList = (campaign, request) => {
  return (dispatch) => {
    const query = gql`
      query get_campaign_list(
        $companyname: String!
        $projectid: String!
        $other_company: Boolean
        $isadmin: Boolean
      ) {
        get_campaign_list(
          companyname: $companyname
          projectid: $projectid
          other_company: $other_company
          isadmin: $isadmin
        ) {
          _id
          campaignid
          title
          description
          projectid
          projectname
          probingid
          probingname
          companyid
          reference
          startDate
          noOfProbing
          expectedProbing
          onsiteExpertName
          brandname
          model
          sincedate
          symbol
          color
          isapproved
          status
          member
          isclosed
          probing_code
        }
      }
    `;
    campaign
      .query({
        query: query,
        variables: {
          companyname: Encrypt(request.companyname),
          projectid: Encrypt(request.projectid),
          other_company: request.other_company,
          isadmin: request.isadmin,
        },
      })
      .then((response) => {
        dispatch(getCampaignListSuccess(response.data.get_campaign_list));
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// get Campaign Details Success
const getCampaignDetailsSuccess = (data) => {
  return {
    type: actionTypes.GET_CAMPAIGN_DETAILS,
    state: data,
  };
};

// get campaign details
export const getCampaignDetails = (campaign, request) => {
  return (dispatch) => {
    const query = gql`
      query get_campaign_detail($campaignid: String!) {
        get_campaign_detail(campaignid: $campaignid) {
          _id
          campaignid
          title
          description
          projectid
          projectname
          probingid
          probingname
          companyid
          reference
          startDate
          noOfProbing
          expectedProbing
          onsiteExpertName
          brandname
          model
          sincedate
          symbol
          color
          isapproved
          probing_code
          isprobingmethod
          status
          member
          isclosed
          campaignimage
          iscompletedprobingmethod
          tokentype
        }
      }
    `;
    campaign
      .query({
        query: query,
        variables: {
          campaignid: Encrypt(request.campaignid),
        },
      })
      .then((response) => {
        dispatch(getCampaignDetailsSuccess(response.data.get_campaign_detail));
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// get Not Project Member List Success
const getNotProjectMemberListSuccess = (data) => {
  return {
    type: actionTypes.GET_NOT_PROJECT_MEMBER_LIST,
    state: data,
  };
};

// get not project member list
export const getNotProjectMemberList = (campaign, request) => {
  return (dispatch) => {
    const query = gql`
      query get_not_project_member_list($projectid: String!) {
        get_not_project_member_list(projectid: $projectid) {
          _id
          name
          firstname
          lastname
          email
          userid
          firebase_id
          role_id
          role_name
          company_id
          company_name
          display_name
          company_address
          registration_country
          contact_number
          phone_number
          status
          approve_reject_by
          company_strength
          system_flag
          other_company
          confirm_account
          staffuser
          last_login
          created_at
          updated_at
          token
          attachments
          userattachments
          owner
        }
      }
    `;
    campaign
      .query({
        query: query,
        variables: {
          projectid: Encrypt(request.projectid),
        },
      })
      .then((response) => {
        dispatch(
          getNotProjectMemberListSuccess(
            response.data.get_not_project_member_list
          )
        );
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// edit new campaign
export const createNewCampaign = (campaign, request) => {
  return (dispatch) => {
    const mutation = gql`
      mutation create_campaign(
        $title: String!
        $description: String!
        $projectid: String!
        $projectname: String!
        $companyid: String!
        $probingid: String
        $probingname: String
        $reference: String!
        $startDate: String!
        $noOfProbing: Int!
        $expectedProbing: String
        $onsiteExpertName: String!
        $brandname: String!
        $model: String!
        $sincedate: String!
        $symbol: String!
        $color: String!
        $member: String!
        $isapproved: Boolean
        $probing_code: String!
        $isprobingmethod: Boolean
      ) {
        create_campaign(
          title: $title
          description: $description
          projectid: $projectid
          projectname: $projectname
          companyid: $companyid
          probingid: $probingid
          probingname: $probingname
          reference: $reference
          startDate: $startDate
          noOfProbing: $noOfProbing
          expectedProbing: $expectedProbing
          onsiteExpertName: $onsiteExpertName
          brandname: $brandname
          model: $model
          sincedate: $sincedate
          symbol: $symbol
          color: $color
          member: $member
          isapproved: $isapproved
          probing_code: $probing_code
          isprobingmethod: $isprobingmethod
        ) {
          _id
          campaignid
          title
          description
          projectid
          projectname
          probingid
          probingname
          companyid
          reference
          startDate
          noOfProbing
          expectedProbing
          onsiteExpertName
          status
          member
          symbol
          color
          isapproved
        }
      }
    `;
    campaign
      .mutate({
        mutation: mutation,
        variables: {
          title: Encrypt(request.title),
          description: Encrypt(request.description),
          projectid: Encrypt(request.projectid),
          projectname: Encrypt(request.projectname),
          companyid: Encrypt(request.companyid),
          probingid: Encrypt(request.probingid),
          probingname: Encrypt(request.probingname),
          reference: Encrypt(request.reference),
          startDate: Encrypt(request.startDate),
          noOfProbing: Encrypt(request.noOfProbing),
          expectedProbing: Encrypt(request.expectedProbing),
          onsiteExpertName: Encrypt(request.onsiteExpertName),
          // operator: Encrypt(request.operator),
          symbol: Encrypt(request.symbol),
          color: Encrypt(request.color),
          brandname: Encrypt(request.brandname),
          model: Encrypt(request.model),
          sincedate: Encrypt(request.sinceDate),
          member: Encrypt(request.member),
          isapproved: request.isapproved,
          probing_code: Encrypt(request.probing_code),
          isprobingmethod: request.isprobingmethod,
        },
      })
      .then((response) => {
        if (request.files.length > 0) {
          var data = new FormData();
          request.files.map((val) => {
            data.append("files", val, val.name);
          });
          data.append(
            "campaignid",
            response.data.create_campaign.campaignid.toString()
          );

          uploadFile(data, "insert_campaign_attach_files/")
            .then((response) => { })
            .catch((err) => {
              dispatch(notificationFail(err));
            });
        }

        //todo history log
        if (request.isprobingmethod === true) {
          var probingHistory = [
            {
              campaignid: response.data.create_campaign.campaignid.toString(),
              probingid: "",
              projectid: request.projectid,
              todo_details: {
                created_date: moment().valueOf(),
                isTodoCompleted: false,
                isactive: true,
                section: "probMethod",
                section_data: [
                  {
                    value: request.probingid,
                  },
                ],
              },
            },
          ];

          var histryReq = {
            todo_log_array: JSON.stringify(probingHistory),
          };
          console.log(
            "histryReqhistryReqhistryReqhistryReqhistryReq",
            histryReq
          );
          dispatch(
            actions.createMultipleProbingTodoLog(request.client, histryReq)
          );
        }

        dispatch(notificationSuccess(createcampaign));
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// edit campaign
export const editCampaign = (campaign, request) => {
  let requestParams = {
    projectid: request.projectid,
    companyname: request.companyname,
  };
  return (dispatch) => {
    const mutation = gql`
      mutation update_campaign(
        $title: String!
        $campaignid: String!
        $description: String!
        $projectid: String!
        $projectname: String!
        $companyid: String!
        $probingid: String
        $probingname: String
        $reference: String!
        $startDate: String!
        $noOfProbing: Int!
        $expectedProbing: String
        $onsiteExpertName: String!
        $brandname: String!
        $model: String!
        $sincedate: String!
        $symbol: String!
        $color: String!
        $member: String!
        $isapproved: Boolean!
        $probing_code: String!
        $isprobingmethod: Boolean
        $isclosed: Boolean!
        $deletedCampaignInviteInfo: String
      ) {
        update_campaign(
          title: $title
          campaignid: $campaignid
          description: $description
          projectid: $projectid
          projectname: $projectname
          companyid: $companyid
          probingid: $probingid
          probingname: $probingname
          reference: $reference
          startDate: $startDate
          noOfProbing: $noOfProbing
          expectedProbing: $expectedProbing
          onsiteExpertName: $onsiteExpertName
          brandname: $brandname
          model: $model
          sincedate: $sincedate
          symbol: $symbol
          color: $color
          member: $member
          isapproved: $isapproved
          probing_code: $probing_code
          isprobingmethod: $isprobingmethod
          isclosed: $isclosed
          deletedCampaignInviteInfo: $deletedCampaignInviteInfo
        ) {
          _id
          campaignid
          title
          description
          projectid
          projectname
          probingid
          probingname
          companyid
          reference
          startDate
          noOfProbing
          expectedProbing
          onsiteExpertName
          status
          member
        }
      }
    `;
    campaign
      .mutate({
        mutation: mutation,
        variables: {
          title: Encrypt(request.title),
          campaignid: Encrypt(request.campaignid),
          description: Encrypt(request.description),
          projectid: Encrypt(request.projectid),
          projectname: Encrypt(request.projectname),
          companyid: Encrypt(request.companyid),
          probingid: Encrypt(request.probingid),
          probingname: Encrypt(request.probingname),
          reference: Encrypt(request.reference),
          startDate: Encrypt(request.startDate),
          noOfProbing: Encrypt(request.noOfProbing),
          expectedProbing: Encrypt(request.expectedProbing),
          onsiteExpertName: Encrypt(request.onsiteExpertName),
          // operator: Encrypt(request.operator),
          symbol: Encrypt(request.symbol),
          color: Encrypt(request.color),
          brandname: Encrypt(request.brandname),
          model: Encrypt(request.model),
          sincedate: Encrypt(request.sinceDate),
          member: Encrypt(request.member),
          isapproved: request.isapproved,
          probing_code: Encrypt(request.probing_code),
          isprobingmethod: request.isprobingmethod,
          isclosed: request.isclosed,
          deletedCampaignInviteInfo: Encrypt(request.deletedCampaignInviteInfo),
        },
      })
      .then((response) => {
        if (request.files.length > 0) {
          var data = new FormData();
          request.files.map((val) => {
            data.append("files", val, val.name);
          });
          data.append("campaignid", request.campaignid);
          uploadFile(data, "insert_campaign_attach_files/")
            .then((response) => { })
            .catch((err) => {
              dispatch(notificationFail(err));
            });
        }
        dispatch(actions.getCampaignList(campaign, requestParams));

        let req = {
          campaignid: request.campaignid,
        };

        dispatch(actions.getCampaignDetails(campaign, req));
        dispatch(notificationSuccess(updatecampaign));
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// delete campaign
export const deleteCampaign = (campaign, request) => {
  let requestParams = {
    projectid: request.projectid,
    companyname: request.companyname,
  };
  return (dispatch) => {
    const query = gql`
      query delete_campaign($campaignid: String!) {
        delete_campaign(campaignid: $campaignid) {
          message
          error
          success
        }
      }
    `;
    campaign
      .query({
        query: query,
        variables: {
          campaignid: Encrypt(request.campaignid),
        },
      })
      .then((response) => {
        dispatch(actions.getCampaignList(campaign, requestParams));
        dispatch(notificationSuccess(campaigndelete));
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// accept Campaign Invite
export const acceptCampaignInvite = (campaign, request, client2) => {
  return (dispatch) => {
    const mutation = gql`
      mutation accept_campaign_invite_request(
        $campaignid: String!
        $inviteid: String!
        $userid: String!
        $name: String!
        $licenceid: String!
        $licencename: String!
      ) {
        accept_campaign_invite_request(
          campaignid: $campaignid
          inviteid: $inviteid
          userid: $userid
          name: $name
          licenceid: $licenceid
          licencename: $licencename
        ) {
          message
          error
          success
        }
      }
    `;
    campaign
      .mutate({
        mutation: mutation,
        variables: {
          campaignid: Encrypt(request.campaignid),
          inviteid: Encrypt(request.inviteid),
          userid: Encrypt(request.userid),
          name: Encrypt(request.name),
          licenceid: Encrypt(request.licenceid),
          licencename: Encrypt(request.licencename),
        },
      })
      .then((response) => {
        if (response.data.accept_campaign_invite_request.error === "true") {
          dispatch(
            notificationFail(response.data.accept_campaign_invite_request)
          );
        } else {
          let newRequest = {
            companyname: request.companyname,
          };
          dispatch(actions.getUserRequestData(client2, newRequest));
          dispatch(actions.getCompanylicence(client2, request));
          dispatch(notificationSuccess(assigncampaign));
        }
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// get Closed Campaign List Success
const getClosedCampaignListSuccess = (data) => {
  return {
    type: actionTypes.GET_CLOSED_CAMPAIGN_LIST,
    state: data,
  };
};

// get Closed Campaign List Success
export const getClosedCampaignList = (campaign, request) => {
  return (dispatch) => {
    const query = gql`
      query get_closed_campaign_list(
        $companyname: String!
        $projectid: String!
      ) {
        get_closed_campaign_list(
          companyname: $companyname
          projectid: $projectid
        ) {
          _id
          campaignid
          title
          description
          projectid
          projectname
          probingid
          probingname
          companyid
          reference
          startDate
          noOfProbing
          expectedProbing
          member
          brandname
          model
          sincedate
          symbol
          color
          probing_code
          isapproved
          isprobingmethod
          isclosed
          status
          onsiteExpertName
        }
      }
    `;
    campaign
      .query({
        query: query,
        variables: {
          companyname: Encrypt(request.companyname),
          projectid: Encrypt(request.projectid),
        },
      })
      .then((response) => {
        dispatch(
          getClosedCampaignListSuccess(response.data.get_closed_campaign_list)
        );
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// get Approved Campaign List Success
const getApprovedCampaignListSuccess = (data) => {
  return {
    type: actionTypes.GET_APPROVED_CAMPAIGN_LIST,
    state: data,
  };
};

// get staff list action
export const getApprovedCampaignList = (campaign, request) => {
  return (dispatch) => {
    const query = gql`
      query get_approved_campaign_list(
        $companyname: String!
        $projectid: String!
      ) {
        get_approved_campaign_list(
          companyname: $companyname
          projectid: $projectid
        ) {
          _id
          campaignid
          title
          description
          projectid
          projectname
          probingid
          probingname
          companyid
          reference
          startDate
          noOfProbing
          expectedProbing
          onsiteExpertName
          brandname
          model
          sincedate
          symbol
          color
          isapproved
          status
          member
          isclosed
        }
      }
    `;
    campaign
      .query({
        query: query,
        variables: {
          companyname: Encrypt(request.companyname),
          projectid: Encrypt(request.projectid),
        },
      })
      .then((response) => {
        dispatch(
          getApprovedCampaignListSuccess(
            response.data.get_approved_campaign_list
          )
        );
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// close campaign
export const closeCampaign = (campaign, request) => {
  let requestParams = {
    projectid: request.projectid,
    companyname: request.companyname,
  };
  return (dispatch) => {
    const query = gql`
      query close_campaign($campaignid: String!, $isclosed: Boolean!) {
        close_campaign(campaignid: $campaignid, isclosed: $isclosed) {
          message
          error
          success
        }
      }
    `;
    campaign
      .query({
        query: query,
        variables: {
          campaignid: Encrypt(request.campaignid),
          isclosed: request.isclosed,
        },
      })
      .then((response) => {
        if (response.data.close_campaign.error === "false") {
          if (request.isclosed) {
            dispatch(notificationSuccess(closecampaign));
          } else {
            dispatch(notificationSuccess(opencampaign));
          }
          dispatch(actions.getCampaignList(campaign, requestParams));
          let req = {
            campaignid: request.campaignid,
          };
          dispatch(actions.getCampaignDetails(campaign, req));
        } else {
          dispatch(notificationFail(response.data.close_campaign));
        }
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// delete campaign document
export const deleteCampaignDocument = (docArray) => {
  return (dispatch) => {
    new Promise(async (resolve, reject) => {
      axios
        .post(Server.ImageAPI + "delete_attechment/", docArray)
        .then(function (response) {
        })
        .catch(function (error) {
          console.log("error", error);
          dispatch(notificationFail(error));
        });
    });
  };
};

// all campaign list for dashboard
export const getCampaignListDashboard = (campaign, req) => {
  return (dispatch) => {
    const query = gql`
      query get_campaign_list(
        $companyname: String!
        $projectid: String!
        $other_company: Boolean
      ) {
        get_campaign_list(
          companyname: $companyname
          projectid: $projectid
          other_company: $other_company
        ) {
          _id
          campaignid
          title
          description
          projectid
          projectname
          probingid
          probingname
          companyid
          reference
          startDate
          noOfProbing
          expectedProbing
          member
          brandname
          model
          sincedate
          symbol
          color
          probing_code
          isapproved
          isprobingmethod
          iscompletedprobingmethod
          isclosed
          status
          onsiteExpertName
          campaignimage
        }
      }
    `;
    campaign
      .query({
        query: query,
        variables: {
          companyname: Encrypt(req.companyname),
          projectid: Encrypt(""),
          other_company: req.other_company,
        },
      })
      .then((response) => {
        dispatch(
          getCampaignListDashboardSuccess(response.data.get_campaign_list)
        );
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};
