import React from 'react';
import * as actionTypes from '../actionTypes';
import { gql } from "apollo-boost";
import { Encrypt } from "../../shared/encrpt";
import axios from 'axios';
import Server from '../../../Common/Server';
import * as actions from '../index';
import store from '../../store';
import notificationMsg from '../../../App/view/Layout/NotificationMessage';
import * as ChangeAppLanguageFront from "../../../App/view/Layout/ChangeAppLanguageFront";
let tlang = ChangeAppLanguageFront.translateLanguage;

const docdelete = tlang('notificationSuccess_docdelete') || 'Document deleted successfully.';
const docupload = tlang('notificationSuccess_docupload') || 'Document Uploaded Successfully';
const docshared = tlang('notificationSuccess_docshared') || 'Document Shared Successfully';
const error_something_wrong = tlang('error_something_wrong') || 'Something Went Wrong!';
const creating_account_reviewed_msg = tlang('other_notification_sucess_your_request_for_creating_account_has_been_reviewed') || 'Your request for creating account has been received. You will get an email on your email from registration@aexdo.com once the request has been reviewed';

// notification Success
export const notificationSuccess = (msg) => {
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_SUCCESS,
    state: msg,
  }
}

// notification Fail
const notificationFail = (err) => {
  let msg = error_something_wrong;
  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    let translateMsg = notificationMsg(err.graphQLErrors[0].statusCode)
    msg = translateMsg;
    // msg = err.graphQLErrors[0].message;
  } else if (err.message) {
    msg = err.message;
  }
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_FAIL,
    state: msg
  }
}

// notification Error
const notificationError = (msg) => {
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_FAIL,
    state: msg
  }
}

// get List Success
const getListSuccess = (data) => {
  return {
    type: actionTypes.GET_USER_DOCUMENT_LIST,
    state: data
  }
}

// get Share Doc List Success
const getShareDocListSuccess = (data) => {
  return {
    type: actionTypes.GET_SHARE_DOCUMENT_LIST,
    state: data
  }
}

// get Info Success
const getInfoSuccess = (data) => {
  return {
    type: actionTypes.GET_USER_DOCUMENT_INFO,
    state: data
  }
}

// get Info Success
const getCompanyDocSuccess = (data) => {
  return {
    type: actionTypes.GET_COMP_DOCUMENT_LIST,
    state: data
  }
}

// get document list action
export const getDocumentList = (client, request) => {
  return dispatch => {
    const query = gql`
        query get_user_document($userid: String!) {
              get_user_document(userid: $userid) {
                file_name
                length
                type
                url
                rev_id
          }
        }
        `;
    client.query({
      query: query,
      variables: {
        userid: Encrypt(request.userid),
      }
    }).then(response => {
      dispatch(getListSuccess(response.data.get_user_document))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// get List Success
const getProjectDocumentListSuccess = (data) => {
  return {
    type: actionTypes.GET_PROJECT_DOCUMENT_LIST,
    state: data
  }
}

// get document list action
export const getProjectDocumentList = (project, request) => {
  return dispatch => {
    const query = gql`
        query get_project_documents($projectId: String!) {
          get_project_documents(projectId: $projectId) {
                file_name
                length
                type
                url
                rev_id
          }
        }
        `;
    project.query({
      query: query,
      variables: {
        projectId: request.projectId ? Encrypt(request.projectId) : '',
      }
    }).then(response => {
      dispatch(getProjectDocumentListSuccess(response.data.get_project_documents))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}


export const deleteProjectDocuments = (project, request) => {
  let reqProDocs = {
    projectId: request.projectId,
  }
  return dispatch => {
    const mutation = gql`
    mutation delete_project_documents($deletedoc: String!, $projectId: String!) {
      delete_project_documents(deletedoc: $deletedoc, projectId: $projectId) {
                message
                error
                success
          }
        }
        `;
    project.mutate({
      mutation: mutation,
      variables: {
        deletedoc: Encrypt(request.deletedoc),
        projectId: Encrypt(request.projectId),
      }
    }).then(response => {
      dispatch(notificationSuccess(docdelete));
      dispatch(actions.getProjectDocumentList(project, reqProDocs));
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}


// get document list action
// export const deleteShareDoc = (client, request, project) => {
//   let requestParams = {
//     userid: request.id,
//   }
//   return dispatch => {
//     const query = gql`
//         query delete_share_documents($deletedoc: String!) {
//               delete_share_documents(deletedoc: $deletedoc) {
//                 message
//                 error
//                 success
//           }
//         }
//         `;
//     client.query({
//       query: query,
//       variables: {
//         deletedoc: Encrypt(request.deletedoc)
//       }
//     }).then(response => {
//       dispatch(notificationSuccess(docdelete))
//       dispatch(actions.getDocumentList(project, requestParams))
//       dispatch(actions.getShareDocumentList(client, requestParams))
//       dispatch(getDocumentList(requestParams))
//     }).catch((error) => {
//       dispatch(notificationFail(error))
//     });
//   }
// }


export const deleteMultipleShareDoc = (client, request) => {

  return dispatch => {
    const mutation = gql`
    mutation delete_multiple_share_documents($deletedoc: String!) {
          delete_multiple_share_documents(deletedoc: $deletedoc) {
                message
                error
                success
          }
        }
        `;
    client.mutate({
      mutation: mutation,
      variables: {
        deletedoc: Encrypt(request.deletedoc)
      }
    }).then(response => {
      dispatch(notificationSuccess(docdelete));
      if (request.userid) {
        let requestParamsShareDoc = {
          userid: request.userid,
        }
        dispatch(actions.getShareDocumentList(client, requestParamsShareDoc));
      }
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// get document list action
export const deleteShareDoc = (client, request, project) => {
  let requestParamsShareDoc = {
    userid: request.id,
  }
  let requestParamsDoc = {
    userid: request.userid,
  }
  return dispatch => {
    const query = gql`
        query delete_share_documents($deletedoc: String!) {
              delete_share_documents(deletedoc: $deletedoc) {
                message
                error
                success
          }
        }
        `;
    client.query({
      query: query,
      variables: {
        deletedoc: Encrypt(request.deletedoc)
      }
    }).then(response => {
      dispatch(notificationSuccess(docdelete))
      dispatch(actions.getDocumentList(project, requestParamsDoc))
      dispatch(actions.getShareDocumentList(client, requestParamsShareDoc))
      // dispatch(getDocumentList(requestParams))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}


//upload file
const uploadFile = async (data, method) => {
  return new Promise(async (resolve, reject) => {

    axios.post(Server.ImageAPI + method, data)
      .then(function (response) {
        resolve(creating_account_reviewed_msg);
      })
      .catch(function (error) {
        resolve(creating_account_reviewed_msg);
        // reject(error);
      })
  })
}

//upload document
const uploadDocument = async (data, method) => {
  return new Promise(async (resolve, reject) => {

    axios.post(Server.ImageAPI + method, data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.log("error ", error);
        resolve(creating_account_reviewed_msg);
        // reject(error);
      })
  })
}

// upload personal document 
export const uploadPersonalDocument = (client, request, project) => {

  return dispatch => {
    if (request.files.length > 0) {
      let data = new FormData();
      request.files.map((val) => {
        data.append('files', val, val.name);
      })
      data.append('id', request.userid);
      uploadDocument(data, 'upload_user_attechment/').then((response) => {
        if (response.data && response.data.error) {
          dispatch(notificationError(response.data.message))
        } else {
          dispatch(notificationSuccess(docupload))
        }

        if (request.docType) {
          if (request.docType === 'Personal') {
            dispatch(actions.getDocumentList(project, request));
            dispatch(actions.getDocumentInfo(project, request));
          }
          else if (request.docType === 'Company') {
            const cmpReq = {
              email: store.getState().authReducer.userData.email,
              companyname: store.getState().authReducer.userData.company_name
            }
            dispatch(actions.getCompanyDocuments(client, cmpReq))
          }
          else if (request.docType === 'Project') {
            const proReq = {
              projectId: store.getState().authReducer.userData.projectid,
            }
            dispatch(actions.getProjectDocumentList(project, proReq))
          }
        } else {
          dispatch(actions.getDocumentList(project, request))
        }
      }).catch((err) => {
        dispatch(notificationFail(err))
      })
    } else {
      // dispatch(notificationFail(err))
    }
  }
}

// delete personal document 
export const deleteDocument = (client, request, project) => {
  return dispatch => {
    new Promise(async (resolve, reject) => {
      axios.post(Server.ImageAPI + 'delete_user_attechment/', request)
        .then(function (response) {
          dispatch(actions.deleteShareDoc(client, request, project));

          const req = { userid: request.userid };
          dispatch(actions.getDocumentInfo(store.getState().apolloClient.project, req));
        })
        .catch(function (error) {
          dispatch(notificationFail(error))
        })
    })
  }
}

// get share document action
export const shareDocument = (client, request, project) => {
  return dispatch => {
    const mutation = gql`
        mutation create_share_file($files: String!,$users: String!) {
              create_share_file(files: $files,users: $users) {
                message
                error
                success
          }
        }
        `;
    client.mutate({
      mutation: mutation,
      variables: {
        files: Encrypt(request.files),
        users: Encrypt(request.users)
      }
    }).then(response => {
      dispatch(notificationSuccess(docshared));
      //fetch the shared from documents list
      let request = {
        userid: store.getState().authReducer.userData.userid,
      }
      dispatch(actions.getShareDocumentsToOthers(client, request));
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// get document list action
export const getShareDocumentList = (client, request) => {
  return dispatch => {
    const query = gql`
        query get_share_documents($userid: String!) {
              get_share_documents(userid: $userid) {
                _id
                sharetouserid
                sharetousername
                sharefromuserid
                sharefromusername
                filesize
                filename
                filerevisionid
                fileurl
                created_at
          }
        }
        `;
    client.query({
      query: query,
      variables: {
        userid: Encrypt(request.userid),
      }
    }).then(response => {
      dispatch(getShareDocListSuccess(response.data.get_share_documents))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// get Share Doc List Success
const getShareDocToOthersListSuccess = (data) => {
  return {
    type: actionTypes.GET_SHARE_DOCUMENT_LIST_TO_OTHERS,
    state: data
  }
}

// get share document list to others action
export const getShareDocumentsToOthers = (client, request) => {
  return dispatch => {
    const query = gql`
        query get_share_documents_to_others($userid: String!) {
            get_share_documents_to_others(userid: $userid) {
                _id
                sharetouserid
                sharetousername
                sharefromuserid
                sharefromusername
                filesize
                filename
                filerevisionid
                fileurl
                created_at
          }
        }
        `;
    client.query({
      query: query,
      variables: {
        userid: Encrypt(request.userid),
      }
    }).then(response => {
      dispatch(getShareDocToOthersListSuccess(response.data.get_share_documents_to_others))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// get document info
export const getDocumentInfo = (client, request) => {
  return dispatch => {
    const query = gql`
  query get_document_info($userid: String!) {
  get_document_info(userid: $userid) {
  totalSpace
  }
  }
  `;
    client.query({
      query: query,
      variables: {
        userid: Encrypt(request.userid)
      }
    }).then(response => {
      dispatch(getInfoSuccess(response.data.get_document_info))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// delete layer image
export const deleteLayerImage = (client, request) => {
  return dispatch => {
    new Promise(async (resolve, reject) => {
      axios.post(Server.ImageAPI + 'delete_user_attechment/', request)
        .then(function (response) {
          // dispatch(actions.deleteShareDoc(client, request, project))
        })
        .catch(function (error) {
          dispatch(notificationFail(error))
        })
    })
  }
}

// get getCompanyDocuments
export const getCompanyDocuments = (client, request) => {
  return dispatch => {
    const query = gql`
      query super_get_company_details($email: String!, $companyname: String!) {
          super_get_company_details (email: $email, companyname: $companyname) {
              _id
              name                     
              attachments             
            }          
        }
      `;
    client.query({
      variables: {
        email: Encrypt(request.email),
        companyname: Encrypt(request.companyname),
      },
      query: query,
      fetchPolicy: 'no-cache'
    }).then(response => {
      dispatch(getCompanyDocSuccess(response.data.super_get_company_details))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}