import React from 'react';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import * as ChangeAppLanguageFront from '../../../../Layout/ChangeAppLanguageFront';
import __ from 'underscore';
import moment from 'moment-timezone';
import styled from 'styled-components';

moment.tz.setDefault('Europe/Paris');

const tlang = ChangeAppLanguageFront.translateLanguage;
const client_renew_btn = tlang('client_renew_btn') || 'Renew';
const client_close_btn = tlang('client_close_btn') || 'Close';
const client_modal_licence_exp_header = tlang('client_modal_licence_exp_header') || 'Licence expired Warning';
const client_modal_licence_exp_text = tlang('client_modal_licence_exp_text') || 'Your Current Licence was expired. Details are as below';
const client_modal_licence_id = tlang('client_modal_licence_id') || 'Licence Id';
const client_modal_licence_name = tlang('client_modal_licence_name') || 'Licence Name';
const client_modal_licence_amount = tlang('client_modal_licence_amount') || 'Licence Amount';
const client_modal_licence_exp_date = tlang('client_modal_licence_exp_date') || 'Licence Expired Date';

export default function ModalLicenceExpiredWarning({ isOpen, onClose, licence, onSave }) {

	return (
		<Modal
			isOpen={isOpen}
			toggle={onClose}
			className={`modal-dialog--success modal-dialog--header`}
		>
			<div className="modal__header">
				<button className="lnr lnr-cross modal__close-btn" type="button" onClick={onClose} />
				<h4 className="bold-text  modal__title">{client_modal_licence_exp_header}</h4>
			</div>
			<div className="modal__body">
				<DetailsContainer>
					<h5 className="bold-text orange_color">{client_modal_licence_exp_text}</h5>
					<div className="detail">
						<p>{client_modal_licence_id}</p>
						<p>{licence.licenceid} </p>
					</div>
					<div className="detail">
						<p>{client_modal_licence_name}</p>
						<p>{licence.licencename} </p>
					</div>
					<div className="detail">
						<p>{client_modal_licence_amount}</p>
						<p>${licence.cost} </p>
					</div>
					<div className="detail">
						<p>{client_modal_licence_exp_date}</p>
						<p>{moment.unix(licence.expirydate).format('DD/MM/YYYY HH:mm')} </p>
					</div>
				</DetailsContainer>
			</div>

			<ButtonToolbar className="modal__footer">
				<Button color={'success'} outline={true} onClick={onClose}>
					{client_close_btn}
				</Button>
				<Button color={'success'} onClick={() => onSave()}>
					{client_renew_btn}
				</Button>
			</ButtonToolbar>
		</Modal>
	);
}

const DetailsContainer = styled.div`
	text-align: center;
	border: 1px solid var(--clr-aexdo);
	border-radius: 5px;
	padding: 0.5rem;
	display: flex;
	flex-direction: column;
	gap: 5px;
	.detail {
		display: flex;
		justify-content: space-between;
		p {
			margin: 0;
		}
		p:first-child {
			font-weight: 700;
			color: var(--clr-aexdo);
		}
		p:last-child {
			font-size: 1.2em;
		}
	}
`;
