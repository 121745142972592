const Menu = [
  {
    label: "clientmenu_dashboard",
    icon: "dashboard",
    to: "/client/dashboard",
    key: "access_to_the_dashboard",
    isAdmin: false,
  },
  {
    label: "clientmenu_project",
    icon: "add_circle_outline",
    isProject: true,
    hasChild: true,
    child: [
      {
        //label: 'clientmenu_add_project',
        icon: 'chevron_right',
        to: "/client/newProject",
        key: "create_project",
        isAdmin: false,
      },
      {
        //label: 'clientmenu_view_project',
        icon: 'chevron_right',
        to: "/client/projectList",
        key: "project_list",
        isAdmin: false,
      },
      {
        label: "clientmenu_add_campaign",
        icon: "chevron_right",
        to: "/client/newCampaign",
        key: "campaign_management",
        isAdmin: false,
        isProject: true,
      },
      {
        label: "clientmenu_view_campaign",
        icon: "chevron_right",
        to: "/client/campaignList",
        key: "view_campaign",
        isAdmin: false,
        isProject: true,
      },
      {
        label: "clientmenu_add_probing",
        icon: "chevron_right",
        to: "/client/newProbing",
        key: "probing_management",
        isAdmin: false,
        isProject: true,
      },
      {
        label: "clientmenu_view_probing",
        icon: "chevron_right",
        to: "/client/probingList",
        key: "view_probing",
        isAdmin: false,
        isProject: true,
      },
      {
        label: "clientmenu_import_probing",
        icon: "chevron_right",
        to: "/client/importProbing",
        key: "probing_management",
        isAdmin: false,
        isProject: true,
      },
    ],
  },
  {
    label: "clientmenu_mapdemo",
    icon: "map",
    to: "/client/dataCreation",
    key: "all",
    isAdmin: true,
    isProject: true,
  },
  {
    label: "clientmenu_datavisualization",
    icon: "map",
    to: "/client/dataVisualization",
    key: "all",
    isAdmin: true,
    isProject: true,
  },
  {
    label: "clientmenu_dataexploitation",
    icon: "map",
    to: "/client/dataExploitation",
    key: "all",
    isAdmin: true,
    isProject: true,
  },
  // {
  //   label: "clientmenu_analysis",
  //   icon: "work",
  //   hasChild: true,
  //   isProject: true,
  //   child: [
  //     {
  //       label: "clientmenu_probing_analysis",
  //       icon: "plus-circle",
  //       to: "/client/probingAnalysis",
  //       key: "all",
  //       isAdmin: false,
  //     },
  //     {
  //       label: "clientmenu_pollutant_analysis",
  //       icon: "plus-circle",
  //       to: "/client/pollutantAnalysis",
  //       key: "all",
  //       isAdmin: false,
  //     },
  //     {
  //       label: "clientmenu_field_analysis",
  //       icon: "plus-circle",
  //       to: "/client/fieldAnalysis",
  //       key: "all",
  //       isAdmin: false,
  //     },
  //   ],
  // },
  {
    label: "clientmenu_analysis_reports",
    icon: "equalizer",
    to: "/client/reports",
    key: "all",
    //key: "analysis_management",
    isProject: true,
    isAdmin: false,
  },
  {
    label: "clientmenu_sample_disposal_setting",
    icon: "playlist_add_check_circle",
    to: "/client/sampleDisposalSetting",
    //key: "disposal_management",
    key: "all",
    isAdmin: false,
    isProject: true,
  },
  {
    label: "clientmenu_disposal",
    icon: "album",
    to: "/client/disposal",
    //key: "disposal_management",
    key: "all",
    isAdmin: false,
    isProject: true,
  },

  {
    label: "clientmenu_import",
    icon: "import_export",
    hasChild: true,
    isProject: true,
    child: [
      {
        label: "clientmenu_sheet_settings",
        icon: "chevron_right",
        to: "/client/excelSettings",
        key: "analysis_management",
        isAdmin: false,
      },
      {
        label: "clientmenu_excel_upload",
        icon: "chevron_right",
        to: "/client/excelUpload",
        key: "analysis_management",
        isAdmin: false,
      },
      {
        label: "analysis_view_analysis_reports",
        icon: "chevron_right",
        to: "/client/viewExcelData",
        key: "view_analysis",
        isAdmin: false,
      },
    ],
  },
  {
    label: "clientmenu_work_room",
    icon: "chat",
    to: "/client/workroom",
    key: "all",
    isAdmin: true,
  },
  {
    label: "clientmenu_my_document",
    icon: "library_books",
    to: "/client/documents",
    // key: "personal_document_upload",
    key: "all",
    isAdmin: true,
  },
  {
    //label: 'dashboard_system_log',
    //icon: 'assignment',
    to: "/client/systemLog",
    key: "system_log",
    isAdmin: true,
  },

  {
    //label: 'clientmenu_setting',
    //icon: 'settings',
    hasChild: false,
    child: [
      {
        //label: 'clientmenu_notification_disable',
        //icon: 'plus-circle',
        to: "/client/notificationSetting",
        key: "all",
        isAdmin: false,
      },
      {
        //label: 'clientmenu_email_disable',
        //icon: 'plus-circle',
        to: "/client/emailSetting",
        key: "all",
        isAdmin: false,
      },
    ],
  },

  {
    //label: 'clientmenu_invoice',
    //icon: 'money',
    to: "/client/invoices",
    key: "invoice_management",
    isAdmin: true,
  },
  {
    //label: 'clientmenu_profile',
    //icon: 'person',
    to: "/client/profile",
    key: "all",
    isAdmin: true,
  },
];

export default Menu;
