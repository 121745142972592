import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../Store/actions/index';
// import __ from 'underscore';

const DEFAULT_OPTIONS = { standardOnly: false, allTypes: false };

export default function useDisposalList(optionsOverride = {}) {
	const dispatch = useDispatch();
	const masterReducer = useSelector(state => state.masterReducer);
	const authReducer = useSelector(state => state.authReducer);
	const apolloClient = useSelector(state => state.apolloClient);
	const [loading, setLoading] = useState(true);
	const [disposalList, setDisposalList] = useState([]);

	const options = { ...DEFAULT_OPTIONS, ...optionsOverride };
	// if (!options || typeof options !== 'object' || __.isEmpty(options)) options = DEFAULT_OPTIONS;

	useEffect(() => {
		if (authReducer.userData?.company_name)
			dispatch(
				actions.getDesposal(apolloClient.masters, {
					company_name: authReducer.userData.company_name,
				})
			);
	}, [authReducer.userData]);

	useEffect(() => {
		/* received desposal list */
		if (!authReducer?.userData) return;
		if (!masterReducer?.desposalList?.length) return;
		const default_disposal_type = options?.selectedDefaultDisposalType || (authReducer.userData.default_disposal_type || 'master'); //If disposal category selected in probing graph then use it priorly
		const company_name = authReducer.userData.company_name;
		const projectId = authReducer.userData.projectid;
		const userid = authReducer.userData.userid;

		const disposals = masterReducer.desposalList.sort((a, b) => a.index_no - b.index_no);

		if (options.allTypes) {
			const list = disposals
				.filter(d => {
					if (options.standardOnly && !d.standard) return false;
					if (d.type === 'company' && d.company_name !== company_name) return false;
					if (d.type === 'project' && d.projectId !== projectId) return false;
					if (d.type === 'account' && d.userId !== userid) return false;
					return true;
				})
				.map(d => ({
					...d,
					desposal_id: d._id,
					disposal_key: d.index_no - 1,
				}));
			setDisposalList(list);
			setLoading(false);
		} else {
			//Code for getting disposals from selected disposal list of selected category (company, project or account)
			const listDisposal = [];
			const selDispListName = options?.selectedDefaultDisposalList || authReducer.userData?.default_disposal_list; //If disposal category selected in probing graph then use it priorly
			const selAllDispListData = authReducer.userData?.disposal_data?.filter(
				l => l.disposalType === default_disposal_type
			) || [];
			if (selAllDispListData.length > 0) {
				const parseDispListData = JSON.parse(selAllDispListData[0].disposalData);
				if (
					typeof parseDispListData[selDispListName] !== 'undefined' &&
					parseDispListData[selDispListName].length
				) {
					const actDispData = parseDispListData[selDispListName];
					for (const disposal of actDispData) {
						const getDisposal = disposals.find(l => l._id === disposal._id);
						if (getDisposal) {
							listDisposal.push({
								...getDisposal,
								index_no: disposal.index_no,
								desposal_id: disposal._id,
								disposal_key: disposal.index_no - 1,
							});
						}
					}
				}
			}

			const list = [];
			for (const d of disposals) {
				if (!options.standardOnly || d.standard) {
					let status = false;

					if (default_disposal_type === 'master' && d.type === 'master') {
						status = true;
					} else if (
						default_disposal_type === 'company' &&
						d.type === 'company' &&
						d?.company_name === company_name
					) {
						status = true;
					} else if (
						default_disposal_type === 'project' &&
						d?.type === 'project' &&
						d.projectId === projectId
					) {
						status = true;
					} else if (
						default_disposal_type === 'account' &&
						d?.type === 'account' &&
						d.userId === userid
					) {
						status = true;
					}

					if (status) {
						//desposalList.push({ desposal_id: d._id, name: d.name, color: d.color, index_no: d.index_no });
						list.push({
							...d,
							desposal_id: d._id,
							disposal_key: d.index_no - 1,
						});
					}
				}
			}

			// setDisposalList(list);
			const newList = listDisposal.length ? listDisposal : list;
			setDisposalList(newList);
			setLoading(false);
		}
	}, [masterReducer?.desposalList, masterReducer?.desposalList?.length, authReducer?.userData, JSON.stringify(options)]);

	return { disposalList, loading };
}
