import React, { useEffect, useState } from 'react';
import * as actions from '../../../../Store/actions/index';
import { useDispatch, useSelector } from 'react-redux';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import Select from 'react-select';
import { Button, ButtonToolbar, Modal, Spinner } from 'reactstrap';
import { getAllTranslatedRoles } from '../../Common/commonFunctions';

const tlang = ChangeAppLanguageFront.translateLanguage;
const client_signup_wizard_role = tlang('client_signup_wizard_role') || 'Role';
const client_change_role = tlang('client_change_role') || 'Change Role';
const cancel_btn = tlang('cancel_btn') || 'Cancel';
const language_savebtn = tlang('language_savebtn') || 'Save';

export default function ModalRoleList({
	isOpen,
	onClose,
	onNewRole,
	initialRole,
	includingPromoter = false,
}) {
	const dispatch = useDispatch();
	const apolloClient = useSelector(state => state.apolloClient);
	const masterReducer = useSelector(state => state.masterReducer);
	const [loading, setLoading] = useState(true);
	const [roleList, setRoleList] = useState([]);
	const [role, setRole] = useState({});

	useEffect(() => {
		dispatch(actions.getRoleList(apolloClient.client, []));
	}, []);

	useEffect(() => {
		if (!masterReducer.roleList?.length) return;
		const roleListArr = getAllTranslatedRoles(masterReducer.roleList);
		const rl = roleListArr
			.filter(r => includingPromoter || r.roleid !== 'AXD_RL_02')
			.map(r => ({
				value: r.roleid,
				label: r.rolename,
			}));
		setRoleList(rl);
		setLoading(false);
	}, [masterReducer.roleList?.length]);

	useEffect(() => {
		if (loading) return;
		if (!initialRole) return;
		const r = roleList.find(r => r.value === initialRole);
		setRole(r || {});
	}, [initialRole, loading]);

	return (
		<Modal
			isOpen={isOpen}
			toggle={() => onClose()}
			className={`modal-dialog--success modal-dialog--header`}
		>
			<div className="modal__header">
				<button
					className="lnr lnr-cross modal__close-btn"
					type="button"
					onClick={() => onClose()}
				/>
				<h4 className="bold-text  modal__title">{client_change_role}</h4>
			</div>
			<div className="modal__body">
				<Select
					placeholder={client_signup_wizard_role}
					value={roleList.find(m => m.value === role.value)}
					onChange={value => setRole(value)}
					options={roleList}
					className="react-select"
				/>
			</div>
			<ButtonToolbar className="modal__footer">
				<Button color={'success'} outline={true} onClick={() => onClose()}>
					{cancel_btn}
				</Button>
				<Button color={'success'} onClick={() => onNewRole(role)} disabled={loading}>
					{language_savebtn} {loading && <Spinner size="sm" className="btn-spinner" />}
				</Button>
			</ButtonToolbar>
		</Modal>
	);
}
