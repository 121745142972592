import Server from '../../../Common/Server';
import React from 'react';
import { useSelector } from 'react-redux';
import { getMaterialListfilter } from './materialList';

/* get type wise other materials */
export const getBrowserStatus = () => {

    var sBrowser, sUsrAg = navigator.userAgent;

    let browserDetails = [
        { name: "Mozilla Firefox", code: "1" },
        { name: "Samsung Internet", code: "2" },
        { name: "Opera", code: "3" },
        { name: "Microsoft Internet Explorer", code: "4" },
        { name: "Microsoft Edge", code: "5" },
        { name: "Google Chrome", code: "6" },
        { name: "Apple Safari", code: "7" },
        { name: "unknown", code: "8" },
    ];

    // The order matters here, and this may report false positives for unlisted browsers.

    if (sUsrAg.indexOf("Firefox") > -1) {
        sBrowser = "Mozilla Firefox";
        // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
        sBrowser = "Samsung Internet";
        // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
        sBrowser = "Opera";
        // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf("Trident") > -1) {
        sBrowser = "Microsoft Internet Explorer";
        // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf("Edg") > -1 || sUsrAg.indexOf("Edge") > -1) {
        sBrowser = "Microsoft Edge";
        // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf("Chrome") > -1) {
        sBrowser = "Google Chrome";
        // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf("Safari") > -1) {
        sBrowser = "Apple Safari";
        // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
        sBrowser = "unknown";
    }
    
    let getBrowserCode = browserDetails.filter((l) => l.name == sBrowser);
    let browserStatus = false;
    let browserCode = "";
    if(getBrowserCode.length){
        browserCode = getBrowserCode[0].code
    }
    
    if(browserCode != "" && browserCode != "6" && browserCode != "3" && browserCode != "5" && browserCode != "2"){
        browserStatus = true;
    }

    return browserStatus;
}

export const convertedImageUrl = (url) => {
        
    let newUrl = url.replace(Server.IMAGE_URL, Server.APP_API_IP);
    return newUrl;
}

export const formatGroupLabel = data => (
    <div className="font-weight-bold text-muted">
        <span>{data.label}</span>
        <span className="border-left float__right pl-2">{data.options.length}</span>
    </div>
);

export const findCommonElements = (arr1, arr2) => {
    return arr1.some(item => arr2.includes(item))
  }

export const getProjectMembers = () => {
    const projectReducer = useSelector(state => state.projectReducer);

    const allMembers = projectReducer?.projectDetails[0].active_member || [];
    const externalMembers = allMembers.filter(l => l.inviteid);
    return {
        active_member: allMembers,
        external_member: externalMembers,
    }
}

export const getAllTranslatedRoles = (roleList) => {
    roleList = getMaterialListfilter(roleList);
    roleList = roleList.map(obj => ({
      ...obj,
      rolename: obj?.name || obj?.rolename
    }));

    return roleList;
}

export const getTranslatedRoleName = (roleList, roleId) => {
    roleList = getAllTranslatedRoles(roleList);
    if(roleId === 'AXD_RL_01') roleId = "AXD_RL_02";
    const roleName = roleList
    .filter(l => l.roleid === roleId) // Adjust the filter condition as needed
    .map(l => l.rolename);

    return roleName;
  }

export const supremeRole = ['AXD_RL_02', 'AXD_RL_15'];
export const freePlan = ['AXD_PL_01'];