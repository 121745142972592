import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    licenceList: [],
    tokenList: [],
    planList: [],
    roleList: [],
    activeProjcetList: [],
    colorList: [],
    natureList: [],
    geologyList: [],
    organolepticList: [],
    categoryList: [],
    lithologyList: [],
    moistureList: [],
    anomalyList: [],
    reasonList: [],
    otherMaterialDetails: [],
    materialDetails: [],
    probingMethodList: [],
    probingMethodDetails: [],
    testTypeList: [],
    testTypeDetails: [],
    desposalList: [],
    desposalDetails: [],
    testList: [],
    testDetails: [],
    alldesposalRuleList:[],
    projectTokenPrice: [],
    projectTokenRequest: [],
    projectTokenList: [],
    planRenewalList: [],
    licenceRenewalList: [],
    usersWithPlanList: [],
    planHistory:[],
    allUsersListDashboard:[],
    observationList:[],
    defaultColorList : [],
    defaultLithologyList : [],
    isMenuToggle:false,
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.GET_PLAN_LIST:
            return updateObject(state, { planList: action.state })
        case actionTypes.GET_ROLE_LIST:
            return updateObject(state, { roleList: action.state })
        case actionTypes.GET_LICENCE_LIST:
            return updateObject(state, { licenceList: action.state })
        case actionTypes.GET_TOKEN_LIST:
            return updateObject(state, { tokenList: action.state })    
        case actionTypes.GET_MATERIAL_MASTER_LIST:
            return updateObject(state, { materialMaster: action.state })
        case actionTypes.GET_MATERIAL_DETAILS:
            return updateObject(state, { materialDetails: action.state })
        case actionTypes.GET_LICECNCE_REQUST_LIST:
            return updateObject(state, { licenceRequest: action.state })
        case actionTypes.GET_LICECNCE_RENEW_REQUST_LIST:
            return updateObject(state, { licenceRenewRequestList: action.state })
        case actionTypes.GET_LICECNCE_REQUST_DETAIL:
            return updateObject(state, { licenceRequestDetails: action.state })
        case actionTypes.GET_TOKEN_REQUST_DETAIL:
            return updateObject(state, { tokenRequestDetails: action.state })
        case actionTypes.GET_PLAN_REQUST_DETAIL:
            return updateObject(state, { planRequestDetails: action.state })
        case actionTypes.GET_ACTIVE_PROJECT_LIST:
            return updateObject(state, { activeProjcetList: action.state })
        case actionTypes.GET_COLOR_LIST:
            return updateObject(state, { colorList: action.state })
        case actionTypes.GET_DEFAULT_COLOR_LIST:
            return updateObject(state, { defaultColorList: action.state })
        case actionTypes.GET_NATURE_LIST:
            return updateObject(state, { natureList: action.state })
        case actionTypes.GET_GEOLOGY_LIST:
            return updateObject(state, { geologyList: action.state })
        case actionTypes.GET_ORGANOLEPTIC_LIST:
            return updateObject(state, { organolepticList: action.state })
        case actionTypes.GET_CATEGORY_LIST:
            return updateObject(state, { categoryList: action.state })
        case actionTypes.GET_LITHOLOGY_LIST:
            return updateObject(state, { lithologyList: action.state })
        case actionTypes.GET_DEFAULT_LITHOLOGY_LIST:
            return updateObject(state, { defaultLithologyList: action.state })
        case actionTypes.GET_MOISTURE_LIST:
            return updateObject(state, { moistureList: action.state })
        case actionTypes.GET_ANOMALY_LIST:
            return updateObject(state, { anomalyList: action.state })
        case actionTypes.GET_REASON_LIST:
            return updateObject(state, { reasonList: action.state })
        case actionTypes.GET_OTHER_MATERIAL_DETAILS:
            return updateObject(state, { otherMaterialDetails: action.state })
        case actionTypes.GET_PROBING_METHOD_LIST:
            return updateObject(state, { probingMethodList: action.state })
        case actionTypes.GET_PROBING_METHOD_DETAILS:
            return updateObject(state, { probingMethodDetails: action.state })
        case actionTypes.GET_TEST_TYPE_LIST:
            return updateObject(state, { testTypeList: action.state })
        case actionTypes.GET_TEST_TYPE_DETAILS:
            return updateObject(state, { testTypeDetails: action.state })
        case actionTypes.GET_DESPOSAL_LIST:
            return updateObject(state, { desposalList: action.state })
        case actionTypes.GET_STANDARD_DESPOSAL_LIST:
            return updateObject(state, { standarddesposalList: action.state })
        case actionTypes.GET_ALL_DESPOSAL_RULELIST:
            return updateObject(state, { alldesposalRuleList: action.state })
        case actionTypes.GET_DESPOSAL_DETAILS:
            return updateObject(state, { desposalDetails: action.state })
        case actionTypes.GET_TEST_RULE_LIST:
            return updateObject(state, { testRuleList: action.state })
        case actionTypes.GET_TEST_LIST:
            return updateObject(state, { testList: action.state })
        case actionTypes.GET_TEST_DETAILS:
            return updateObject(state, { testDetails: action.state })
        case actionTypes.GET_PROJECT_TOKEN_PRICE:
            return updateObject(state, { projectTokenPrice: action.state })
        case actionTypes.GET_PROJECT_TOKEN_REQUEST:
            return updateObject(state, { projectTokenRequest: action.state })
        case actionTypes.GET_PROJECT_TOKEN_LIST:
            return updateObject(state, { projectTokenList: action.state })
        case actionTypes.GET_PLAN_RENEWAL_LIST:
            return updateObject(state, { planRenewalList: action.state })
        case actionTypes.GET_LICENCE_RENEWAL_LIST:
            return updateObject(state, { licenceRenewalList: action.state })
        case actionTypes.GET_SEARCH_TEST_FAMILY:
            return updateObject(state, { searchTestFamily: action.state })
        case actionTypes.GET_USERS_WITH_PLAN_LIST:
            return updateObject(state, { usersWithPlanList: action.state })
        case actionTypes.GET_COMPANY_WISE_PLAN_HISTORY:
            return updateObject(state, { planHistory: action.state })
        case actionTypes.GET_COMPANY_WISE_LICENCE_HISTORY:
            return updateObject(state, { licenceHistory: action.state })
        case actionTypes.GET_SUPERADMIN_DASHBOARD_DATA:
            return updateObject(state, { superAdminDashboardData: action.state })
        case actionTypes.GET_CLIENT_DASHBOARD_DATA:
            return updateObject(state, { clientDashboardData: action.state })
        case actionTypes.GET_SYSTEM_LOG:
            return updateObject(state, { systemLog: action.state })
        case actionTypes.GET_ALL_USERS_LIST_DASHBOARD:
            return updateObject(state, { allUsersListDashboard: action.state })
        case actionTypes.GET_OBSERVATION_LIST:
            return updateObject(state, { observationList: action.state })
        case actionTypes.GET_PATTERN_LIST:
            return updateObject(state, { patternList: action.state })
        case actionTypes.MENU_TOGGLE:
                return updateObject(state, { isMenuToggle: action.state })
        case actionTypes.GET_SUPPORT_INVITATION_LIST:
            return updateObject(state, { supportInvitationRequestList: action.state })
        default:
            return state;
    }
}

export default reducer