import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import jsPDF from 'jspdf';
import {
	DataTableCell,
	Table,
	TableBody,
	TableCell,
	TableHeader,
	Utils,
} from '@david.kucsai/react-pdf-table';
import moment from 'moment-timezone';
import * as ChangeAppLanguageFront from '../Layout/ChangeAppLanguageFront';
import 'jspdf-autotable';

moment.tz.setDefault('Europe/Paris');

let tlang = ChangeAppLanguageFront.translateLanguage;
const client_invoice_txt = tlang('client_invoice_txt') || 'Invoice';
const client_invoice_cmp = tlang('client_invoice_cmp') || 'Company';
const USERREQUEST_EMAIL_KEY = tlang('user_request_email') || 'Email';
const client_invoice_date = tlang('client_invoice_date') || 'Invoice Date';
const signup_wizard_item = tlang('signup_wizard_item') || 'Item';
const client_invoice_cost = tlang('client_invoice_cost') || 'Cost';
const signup_wizard_grand_total = tlang('signup_wizard_grand_total') || 'Grand Total';
const signup_wizard_month = tlang('signup_wizard_month') || 'month';
const styles = StyleSheet.create({
	logo: {
		width: '80px',
		marginBottom: 10,
	},
	invoiceHeading: {
		fontSize: 25,
		// color:"#646777",
		marginBottom: 10,
	},
	invoiceTexts: {
		fontSize: 12,
		lineHeight: 1.3,
		// color:"#646777"
	},
	invoiceDate: {
		marginTop: 20,
		fontSize: 12,
		lineHeight: 1.3,
	},
	invoiceTitle: {
		fontSize: 15,
		// color:"#646777",
		marginTop: 20,
		marginBottom: 10,
	},
	row_data_heading: {
		width: '70%',
		flexDirection: 'row',
		fontSize: 11,
		fontWeight: 700,
	},
	row_price_heading: {
		width: '30%',
		flexDirection: 'row',
		fontSize: 11,
		textAlign: 'right',
		fontWeight: 700,
	},
	row_data: {
		width: '70%',
		flexDirection: 'row',
		fontSize: 11,
		color: '#525252',
	},
	row_data_2: {
		width: '35%',
		flexDirection: 'row',
		fontSize: 11,
		color: '#525252',
	},
	row_price: {
		width: '30%',
		flexDirection: 'row',
		fontSize: 11,
		textAlign: 'right',
		color: '#525252',
	},
	page: {
		backgroundColor: '#ffffff',
		padding: 20,
	},
	coloumn: {
		flexDirection: 'row',
		marginTop: 5,
		paddingBottom: 2,
		borderBottom: '1 solid #e0e0e0',
	},
	total_coloumn: {
		flexDirection: 'row',
		marginTop: 10,
	},
});

// export function PdfDocument(invoice) {
// 	const date = moment(new Date(invoice.data.created_at) / 1000).format('DD/MM/YYYY HH:mm');
// 	var invoice_date = moment(date, 'DD/MM/YYYY HH:mm', true).isValid();
// 	let dateDispaly = invoice_date
// 		? moment.unix(new Date(invoice.data.created_at) / 1000).format('DD/MM/YYYY HH:mm')
// 		: moment.unix(invoice.data.created_at / 1000).format('DD/MM/YYYY HH:mm');

// 	return (
// 		<Document>
// 			<Page size="A4" style={styles.page} wrap>
// 				<View fixed>
// 					<Image
// 						src={`${process.env.PUBLIC_URL}/img/aexdo_logo.png`}
// 						alt="images"
// 						style={styles.logo}
// 					></Image>

// 					<Text style={styles.invoiceHeading}>{client_invoice_txt}</Text>
// 				</View>
// 				<View fixed>
// 					<Text style={styles.invoiceTexts}>
// 						{client_invoice_cmp + ': '}
// 						{invoice.data.company_name}
// 					</Text>
// 					<Text style={styles.invoiceTexts}>
// 						{USERREQUEST_EMAIL_KEY + ': '}
// 						{invoice.data.company_email}
// 					</Text>
// 					<Text style={styles.invoiceDate}>
// 						{client_invoice_date + ': '}
// 						{dateDispaly}
// 					</Text>
// 					<Text style={styles.invoiceTitle}>{invoice.data.title}</Text>
// 				</View>
// 				{invoice.data.category_name === 'SIGNUP' && (
// 					<>
// 						<View>
// 							<View style={styles.coloumn}>
// 								<Text style={styles.row_data_heading}>{signup_wizard_item}</Text>
// 								<Text style={styles.row_price_heading}>{client_invoice_cost}</Text>
// 							</View>
// 						</View>
// 						<View>
// 							{invoice.data.invoice_detail.map((detail, i) => {
// 								return (
// 									<>
// 										{(detail.plan_name || detail.planname || detail.type) && (
// 											<View style={styles.coloumn} key={i}>
// 												<Text style={styles.row_data}>
// 													{detail.planname && detail.planname}
// 													{detail.plan_name && detail.plan_name}
// 													{detail.type === 'renew' && 'Plan Renew'}
// 													{detail.type === 'extend' && 'Plan Extend'}
// 												</Text>
// 												<Text style={styles.row_price}>
// 													{detail.month && detail.month} {signup_wizard_month}
// 												</Text>
// 												<Text style={styles.row_price}>${detail.price}</Text>
// 											</View>
// 										)}
// 										{detail.plan && (
// 											<View style={styles.coloumn}>
// 												<Text style={styles.row_data}>
// 													{detail.plan.planname && detail.plan.planname}
// 													{detail.plan.type === 'renew' && 'Plan Renew'}
// 													{detail.plan.type === 'extend' && 'Plan Extend'}
// 												</Text>
// 												<Text style={styles.row_price}>
// 													{detail.plan.month && detail.plan.month} {signup_wizard_month}
// 												</Text>
// 												<Text style={styles.row_price}>
// 													${detail.plan.price * detail.plan.month}
// 												</Text>
// 											</View>
// 										)}
// 										{detail.licence &&
// 											detail.licence
// 												.filter((value, index1) => value.count != 0)
// 												.map((val, index) => {
// 													return (
// 														<>
// 															<View style={styles.coloumn}>
// 																<Text style={styles.row_data_2}>
// 																	{val.licencename && val.licencename}
// 																</Text>
// 																<Text style={styles.row_data_2}>{val.count ? val.count : '1'}</Text>
// 																<Text style={styles.row_data_2}>
// 																	{val.month ? val.month : '1'} {signup_wizard_month}
// 																</Text>
// 																<Text style={styles.row_price}>
// 																	${val.count ? val.count * val.price * val.month : val.price}
// 																</Text>
// 															</View>
// 														</>
// 													);
// 												})}
// 									</>
// 								);
// 							})}
// 							<View style={styles.total_coloumn}>
// 								<Text style={styles.row_data}></Text>
// 								<Text style={styles.row_price}>
// 									{signup_wizard_grand_total}: ${getTotalAmount(invoice.data)}
// 								</Text>
// 							</View>
// 						</View>
// 					</>
// 				)}
// 				{invoice.data.category_name === 'TOKEN' && (
// 					<>
// 						<View>
// 							<View style={styles.coloumn}>
// 								<Text style={styles.row_data_heading}>{'Item'}</Text>
// 								<Text style={styles.row_data_heading}>{'Count'}</Text>
// 								<Text style={styles.row_data_heading}>{'Cost'}</Text>
// 								<Text style={styles.row_price_heading}>{'Amount'}</Text>
// 							</View>
// 						</View>
// 						<View>
// 							{invoice.data.invoice_detail.map(detail => {
// 								return (
// 									<View style={styles.coloumn}>
// 										<Text style={styles.row_data}>{'Token'}</Text>
// 										<Text style={styles.row_data}>{detail.count}</Text>
// 										<Text style={styles.row_data}>${detail.price / detail.count}</Text>
// 										<Text style={styles.row_price}>${detail.price}</Text>
// 									</View>
// 								);
// 							})}
// 							<View style={styles.total_coloumn}>
// 								<Text style={styles.row_data}></Text>
// 								<Text style={styles.row_price}>
// 									{signup_wizard_grand_total}: ${getTotalAmount(invoice.data)}
// 								</Text>
// 							</View>
// 						</View>
// 					</>
// 				)}
// 				{invoice.data.category_name === 'LICENCE' && (
// 					<>
// 						<View>
// 							<View style={styles.coloumn}>
// 								<Text style={styles.row_data_heading}>{'Item'}</Text>
// 								<Text style={styles.row_data_heading}>{'Count'}</Text>
// 								<Text style={styles.row_data_heading}>{'Cost'}</Text>
// 								<Text style={styles.row_price_heading}>{'Amount'}</Text>
// 							</View>
// 						</View>
// 						<View>
// 							{invoice.data.invoice_detail
// 								.filter((value, index1) => value.count != 0)
// 								.map(detail => {
// 									return (
// 										<View style={styles.coloumn}>
// 											<Text style={styles.row_data}>
// 												{detail.licencename ? detail.licencename : '-'}
// 											</Text>
// 											<Text style={styles.row_data}>{detail.count ? detail.count : '1'}</Text>
// 											<Text style={styles.row_data}>${detail.price}</Text>
// 											<Text style={styles.row_price}>
// 												${detail.count ? detail.count * detail.price : detail.price}
// 											</Text>
// 										</View>
// 									);
// 								})}
// 							<View style={styles.total_coloumn}>
// 								<Text style={styles.row_data}></Text>
// 								<Text style={styles.row_price}>
// 									{signup_wizard_grand_total}: ${getTotalAmount(invoice.data)}
// 								</Text>
// 							</View>
// 						</View>
// 					</>
// 				)}
// 			</Page>
// 		</Document>
// 	);
// }

//Modified function use jsPdf library
export const PdfDocument = invoice => {
	const date = new Date(parseInt(invoice.created_at)).toLocaleString();
	const pdf = new jsPDF();

	// Add logo as image
	const logoImgSrc = `${process.env.PUBLIC_URL}/img/aexdo_logo.png`;
	pdf.addImage(logoImgSrc, 'PNG', 5, 10, 25, 10);

	const startY = 100; // Starting Y position for the table

	pdf.setFontSize(16);
	pdf.text('Invoice', 10, 40);

	pdf.setFontSize(10);
	pdf.setTextColor(0); // Set text color for company details

	// Display Company details
	pdf.text(`Company: ${invoice.company_name}`, 10, 50);
	pdf.text(`Email: ${invoice.company_email}`, 10, 55);
	pdf.text(`Invoice Date: ${date}`, 10, 60);

	pdf.setFontSize(14);
	pdf.setTextColor(0); // Reset text color

	// Display Title above the table
	pdf.text(`${invoice.title}`, 10, startY - 10);

	if (invoice.category_name === 'SIGNUP') {
		const tableData = [];
		tableData.push(['Item', 'Price', 'Total']);

		invoice.invoice_detail.forEach(detail => {
			const rowData = [];
			rowData.push(detail.plan_name || detail.planname || detail.type || detail.plan.planname);
			rowData.push(`$${detail.price ? detail.price : detail.plan.price}`);
			rowData.push(`$${detail.price ? detail.price : detail.plan.price}`);

			tableData.push(rowData);
		});

		// Set table header background color
		pdf.setFillColor(230, 72, 35);

		pdf.autoTable({
			startY: startY,
			head: tableData.slice(0, 1),
			body: tableData.slice(1),
			theme: 'grid',
			headStyles: {
				fillColor: [230, 72, 35], // Background color for the header row
				textColor: [255], // Text color for the header row
				fontStyle: 'bold', // Font style for the header row
			},
			styles: {
				textColor: [0], // Text color for the body rows
			},
		});

		const totalAmount = invoice.invoice_detail.reduce((acc, detail) => {
			if (detail.plan) {
				return acc + detail.plan.price * detail.plan.month;
			} else {
				return acc + detail.price;
			}
		}, 0);

		pdf.setFont('helvetica', 'bold');
		pdf.setFontSize(14);
		pdf.text(`Total: $${totalAmount}`, 150, pdf.autoTable.previous.finalY + 20);
	}

	if (invoice.category_name === 'TOKEN' || invoice.category_name === 'LICENCE') {
		const tableData = [];
		tableData.push(['Item', 'Count', 'Price', 'Total']);

		invoice.invoice_detail.forEach(detail => {
			const rowData = [];
			rowData.push(detail.tokenName || detail.licencename);
			rowData.push(detail.count);
			rowData.push(`$${detail.price}`);
			rowData.push(`$${detail.count * detail.price}`);
			tableData.push(rowData);
		});

		// Set table header background color
		pdf.setFillColor(230, 72, 35);

		pdf.autoTable({
			startY: startY,
			head: tableData.slice(0, 1),
			body: tableData.slice(1),
			theme: 'grid',
			headStyles: {
				fillColor: [230, 72, 35], // Background color for the header row
				textColor: [255], // Text color for the header row
				fontStyle: 'bold', // Font style for the header row
			},
			styles: {
				textColor: [0], // Text color for the body rows
			},
		});

		const totalAmount = invoice.invoice_detail.reduce(
			(acc, detail) => acc + detail.count * detail.price,
			0
		);

		pdf.setFont('helvetica', 'bold');
		pdf.setFontSize(14);
		pdf.text(`Total: $${totalAmount}`, 150, pdf.autoTable.previous.finalY + 20);
	}

	pdf.save('invoice.pdf');
};
