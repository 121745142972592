import { useEffect, useState, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../Store/actions/index';
import useDisposalList from './useDisposalList';
import * as ChangeAppLanguageFront from '../view/Layout/ChangeAppLanguageFront';
import { sampleDisposalCalulation } from '../view/Common/sampleDisposalCalulation';
import * as MaterialListFilter from '../view/Common/materialList';
import {
	CompareExcelPollutantWithDisposalPollutant,
	compareAnalysisAndDisposalPollutant,
	prepareDataForAnalysis,
} from '../view/Client/Analysis/compareAnalysisAndDisposalPollutant';
import uniqBy from 'lodash/uniqBy';
import __, { filter } from 'underscore';
import moment from 'moment-timezone';
import { findCommonElements } from '../view/Common/commonFunctions';
moment.tz.setDefault('Europe/Paris');

let getMaterialListfilter = MaterialListFilter.getMaterialListfilter;
const tlang = ChangeAppLanguageFront.translateLanguage;
//const mapbox_layer_has_no_sample = tlang('mapbox_layer_has_no_sample') || 'Layer has no sample';
const analysis_no_sample_matched_with_filter =
	tlang('analysis_no_sample_matched_with_filter') || 'No sample matched with your filter';
const analysis_pollutant_testfamily_not_matched_with_disposal_testfamily =
	tlang('analysis_pollutant_testfamily_not_matched_with_disposal_testfamily') ||
	'Pollutant testfamily is not matched with selected disposal testfamily';

const initialState = {
	selCampaignsId: [],
	excelDataList: [],
	selCmpNamFrmApi: [],
	allPollutantFamilies: [],
	loadingforbtn: false,
	exceptionConst: [],
	allDisposalList: [],
	allTestTypeList: [],
	alldesposalRuleList: [],
	selectedDisposalFamily: [],
	otherMaterialsPameters: ['lithology', 'color', 'category', 'geology', 'moisture', 'anomaly'],
	colorList: [],
	lithologyList: [],
	anomalyList: [],
	geologyList: [],
	testTypeList: [],
	testNameList: [],
	allProbingSamples: [],
	sample_list: [],
	reportData: {},
	tempHigestMatchedDisposals: [],
	initialForcedSampleData: [],
};

function reducer(state, action) {
	switch (action.type) {
		default:
			const upd = {};
			for (const key of Object.keys(action)) {
				upd[key] = action[key];
			}
			// console.log(`upd`, { upd });
			return { ...state, ...upd };
	}
}

function isEmpty(obj) {
	return Object.keys(obj).length === 0;
}

export default function useSampleInfoForReport(
	probings,
	selectedCampaigns,
	selectedDisposals = null,
	options = {},
	filters = {},
	otherSettings = {},
	selectedDisposalCategory = {}
) {
	const [state, localDispatch] = useReducer(reducer, initialState);
	const dispatch = useDispatch();
	const apolloClient = useSelector(state => state.apolloClient);
	const authReducer = useSelector(state => state.authReducer);
	const excelUploadReducer = useSelector(state => state.excelUploadReducer);
	//const campaignReducer = useSelector(state => state.campaignReducer);
	const probingReducer = useSelector(state => state.probingReducer);
	const projectReducer = useSelector(state => state.projectReducer);
	//const dataVisualizaionReducer = useSelector(state => state.dataVisualizaionReducer);
	const masterReducer = useSelector(state => state.masterReducer);
	//const notification = useSelector(state => state.notification);
	const [loading1, setLoading2] = useState(true);

	if (typeof otherSettings?.expand == 'undefined' || otherSettings?.expand === '') {
		otherSettings.expand = true;
	}

	if (
		typeof filters?.filterBySampleAltitude == 'undefined' ||
		filters?.filterBySampleAltitude === ''
	) {
		filters.filterBySampleAltitude = false;
	}
	if (
		typeof filters?.filterByDepthInProbing == 'undefined' ||
		filters?.filterByDepthInProbing === ''
	) {
		filters.filterByDepthInProbing = false;
	}
	if (typeof filters?.sampleAltitdueVal == 'undefined' || filters?.sampleAltitdueVal === '') {
		filters.sampleAltitdueVal = '';
	}
	if (typeof filters?.depthInProbingVal == 'undefined' || filters?.depthInProbingVal === '') {
		filters.depthInProbingVal = '';
	}
	if (typeof filters?.depthInProbingType == 'undefined' || filters?.depthInProbingType === '') {
		filters.depthInProbingType = '';
	}
	if (typeof filters?.depthInProbingRange == 'undefined' || filters?.depthInProbingRange === '') {
		filters.depthInProbingRange = [0, 10];
	}
	if (typeof filters?.allPollutants == 'undefined' || filters?.allPollutants === '') {
		filters.allPollutants = false;
	}
	if (
		typeof filters?.selectedPollutantFamilies == 'undefined' ||
		filters?.selectedPollutantFamilies === ''
	) {
		filters.selectedPollutantFamilies = [];
	}
	if (typeof filters?.hideDisposalInReport == 'undefined' || filters?.hideDisposalInReport === '') {
		filters.hideDisposalInReport = false;
	}
	if (
		typeof filters?.disposalThresholdBeforeSamples == 'undefined' ||
		filters?.disposalThresholdBeforeSamples === ''
	) {
		filters.disposalThresholdBeforeSamples = false;
	}
	if (
		typeof filters?.onlyPollutatsWhoHaveDisposalThreshold == 'undefined' ||
		filters?.onlyPollutatsWhoHaveDisposalThreshold === ''
	) {
		filters.onlyPollutatsWhoHaveDisposalThreshold = false;
	}
	if (typeof filters?.pollutantsNextToEach == 'undefined' || filters?.pollutantsNextToEach === '') {
		filters.pollutantsNextToEach = true;
	}
	if (typeof filters?.selectedOrigins == 'undefined' || filters?.selectedOrigins === '') {
		filters.selectedOrigins = [];
	}
	if (typeof filters?.selectedDestinations == 'undefined' || filters?.selectedDestinations === '') {
		filters.selectedDestinations = [];
	}
	if (typeof filters?.selectedBoundaries == 'undefined' || filters?.selectedBoundaries === '') {
		filters.selectedBoundaries = [];
	}
	if (
		typeof filters?.selectedPollutionzone == 'undefined' ||
		filters?.selectedPollutionzone === ''
	) {
		filters.selectedPollutionzone = [];
	}

	const { disposalList, loading: loadingDisposals } = useDisposalList(options?.disposalOptions);

	//const isadmin = authReducer.userData.email === authReducer.userData.owner;

	useEffect(() => {
		//Get Forcefully selected Disposal to samples
		const requestParam = {
			userid: authReducer.userData.userid,
			projectid: authReducer.userData.projectid,
			// default_disposal_type: authReducer.userData.default_disposal_type || 'master',
			// default_disposal_list: authReducer.userData?.default_disposal_list || '',
			default_disposal_type: !__.isEmpty(selectedDisposalCategory)
				? selectedDisposalCategory.type
				: authReducer.userData.default_disposal_type || 'master',
			default_disposal_list:
				!__.isEmpty(selectedDisposalCategory) && selectedDisposalCategory.type === 'master'
					? ''
					: !__.isEmpty(selectedDisposalCategory)
					? selectedDisposalCategory.list
					: authReducer.userData?.default_disposal_list || '',
		};
		dispatch(actions.getForcedDisposalToSample(apolloClient.probing, requestParam));

		//Get all disposal list of all users of logged in company
		const reqParam = {
			company_name: authReducer.userData.company_name,
		};
		dispatch(actions.getDesposal(apolloClient.masters, reqParam));

		//Get all materia list (passed in arguments) from the masters
		const languageCode = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
		const otherMaterialsParams = {
			type_list: JSON.stringify(state.otherMaterialsPameters),
			lang_code: languageCode,
		};
		dispatch(actions.getTypeWiseOtherMatreials(apolloClient.masters, otherMaterialsParams));

		//Get List of All Pollutant Families with its all children pollutants
		dispatch(actions.getTestType(apolloClient.masters, []));
	}, []);

	useEffect(() => {
		if (probingReducer.getForcedDisposalToSample) {
			const forced_sampledata = JSON.parse(
				probingReducer.getForcedDisposalToSample.forced_sampledata
			);
			localDispatch({ initialForcedSampleData: forced_sampledata });
		} else {
			localDispatch({ initialForcedSampleData: [] });
		}
	}, [probingReducer?.getForcedDisposalToSample]);

	useEffect(() => {
		if (masterReducer.desposalList) {
			const projectId = authReducer.userData.projectid;
			const projectWiseAllDisposal = masterReducer.desposalList.filter(l => {
				if (l.type === 'project' && l.projectId !== projectId) {
					return false;
				}
				return true;
			});
			const projectWiseAllTestFamily = masterReducer.testTypeList;
			localDispatch({
				allDisposalList: projectWiseAllDisposal,
				allTestTypeList: projectWiseAllTestFamily,
			});
		}
	}, [masterReducer?.desposalList?.length, masterReducer?.testTypeList?.length]);

	useEffect(() => {
		if (masterReducer.colorList.length) {
			const colorList = masterReducer.colorList;
			const colorArray = getMaterialListfilter(colorList);
			localDispatch({
				colorList: colorArray,
			});
		}
	}, [masterReducer?.colorList?.length]);

	useEffect(() => {
		if (masterReducer.geologyList.length) {
			const geologyList = masterReducer.geologyList;
			const geologyArray = getMaterialListfilter(geologyList);
			localDispatch({
				geologyList: geologyArray,
			});
		}
	}, [masterReducer?.geologyList?.length]);

	useEffect(() => {
		if (masterReducer.lithologyList.length) {
			const lithologyList = masterReducer.lithologyList;
			const lithologyArray = getMaterialListfilter(lithologyList);
			localDispatch({
				lithologyList: lithologyArray,
			});
		}
	}, [masterReducer?.lithologyList?.length]);

	useEffect(() => {
		const anomalyList = masterReducer.anomalyList;
		if (anomalyList && anomalyList.length > 0) {
			const anomalyArray = getMaterialListfilter(anomalyList);
			localDispatch({
				anomalyList: anomalyArray,
			});
		}
	}, [masterReducer?.anomalyList?.length]);

	useEffect(() => {
		if (masterReducer.testTypeList.length > 0) {
			const testTypeList = masterReducer.testTypeList;

			const testTypeListArr = [];
			const testNameListArr = [];
			testTypeList?.map((val1, index1) => {
				const data1 = {
					id: val1._id,
					name: val1.name,
				};
				testTypeListArr.push(data1);

				val1.children?.map(cval => {
					const childrenData = {
						id: cval.id,
						name: cval.name,
					};
					testNameListArr.push(childrenData);
				});
			});

			localDispatch({
				testTypeList: testTypeListArr,
				testNameList: testNameListArr,
			});
		}
	}, [masterReducer?.testTypeList?.length]);

	/*================== GET UPLOADED EXCEL SHEET DATA OF SELECTED CAMPAIGNS ==================*/

	useEffect(() => {
		if (!Array.isArray(selectedCampaigns)) return;
		if (selectedCampaigns.length) {
			localDispatch({ selCampaignsId: selectedCampaigns });
			const req = {
				campaign_names: JSON.stringify(selectedCampaigns?.map(c => c.label)),
				campaign_ids: JSON.stringify(selectedCampaigns?.map(c => c.value)),
				projectid: authReducer.userData.projectid,
			};
			dispatch(
				actions.getMultipleCampaignTestAnalysisDataListForAnalysisReport(apolloClient.probing, req)
			);
		}
	}, [JSON.stringify(selectedCampaigns)]);

	useEffect(() => {
		if (projectReducer.multiplecampaignTestAnalysisData) {
			let selCmpNamFrmApi = [];
			if (Object.keys(projectReducer.multiplecampaignTestAnalysisData).length > 0) {
				selCmpNamFrmApi = JSON.parse(
					projectReducer.multiplecampaignTestAnalysisData.campaign_names
				);
			}

			if (Object.keys(projectReducer.multiplecampaignTestAnalysisData).length > 0) {
				if (projectReducer.multiplecampaignTestAnalysisData.data.length) {
					//Sort the excelsheet data based on seected campaigns, so sample display as per selected campaign wise
					let selectedCampaignsIds = selectedCampaigns?.map(c => c.value);
					projectReducer.multiplecampaignTestAnalysisData.data.sort(function(a, b) {
						return (
							selectedCampaignsIds.indexOf(a.campaignid) -
							selectedCampaignsIds.indexOf(b.campaignid)
						);
					});

					// Get all unique pollutant families from uploaded excels
					let allPollutantFamilies = [];
					for (let sht_tst_data_1 of projectReducer.multiplecampaignTestAnalysisData.data) {
						const sheet_data = sht_tst_data_1.sheet_resp_data;
						for (const sht_tst_data of sheet_data) {
							const Test_Parent_Familyname = sht_tst_data.Test_Parent_Familyname;
							const Test_Parent_Id = sht_tst_data.Test_Parent_Id;
							if (Test_Parent_Id !== '') {
								const obj = {
									label: Test_Parent_Familyname,
									value: Test_Parent_Id,
								};
								allPollutantFamilies.push(obj);
							}
						}
					}
					//Get unique pollutant families only
					allPollutantFamilies = [
						...new Map(allPollutantFamilies?.map(item => [item['value'], item])).values(),
					];

					const excelDataSheets = projectReducer.multiplecampaignTestAnalysisData.data.sort(
						function(a, b) {
							return (
								selectedCampaignsIds.indexOf(a.campaignid) -
								selectedCampaignsIds.indexOf(b.campaignid)
							);
						}
					);

					localDispatch({
						excelDataList: excelDataSheets,
						selCmpNamFrmApi: selCmpNamFrmApi,
						allPollutantFamilies: allPollutantFamilies,
						loadingforbtn: false,
					});
				}
			}
		}
	}, [projectReducer?.multiplecampaignTestAnalysisData]);

	/*================== GET UPLOADED EXCEL SHEET DATA OF SELECTED CAMPAIGNS (END) ==================*/

	/*================== GET EXCEPTION ==================*/

	useEffect(() => {
		if (authReducer.userData.company_name === '["superadmin"]') return;
		if (authReducer.userData.projectid) {
			dispatch(
				actions.getExceptionSetting(apolloClient.masters, {
					company_id: authReducer.userData.company_id,
				})
			);
		}
	}, [authReducer.userData.projectid]);

	useEffect(() => {
		if (!excelUploadReducer?.exceptionSettingsList?.exception_strings) return;
		localDispatch({
			exceptionConst: excelUploadReducer.exceptionSettingsList?.exception_strings,
		});
	}, [excelUploadReducer?.exceptionSettingsList?.exception_strings?.length]);

	/*================== GET EXCEPTION (END) ==================*/

	/*================== GET SELECTED OR STANDARD DISPOSAL RULE LIST ==================*/

	useEffect(() => {
		if (!disposalList?.length && !selectedDisposals?.length) return;

		const dispo = selectedDisposals || disposalList;

		//Get the Selected Disposal Testfamily
		const selectedDisposalFamilyArr = [];
		if (dispo.length > 0) {
			dispo?.map(l => {
				const getDisposalTestFamily = state.allDisposalList.filter(
					q => q._id == (l.value || l._id)
				);
				getDisposalTestFamily.length &&
					getDisposalTestFamily[0].test_list?.map((z, x) => {
						selectedDisposalFamilyArr.push(z._id);
					});
			});
		}

		const selectedDisposalFamily = [...new Set(selectedDisposalFamilyArr)];
		localDispatch({ selectedDisposalFamily: selectedDisposalFamily });

		const requestParams = {
			desposal_ids: JSON.stringify(dispo?.map(d => d.value || d._id)),
		};
		dispatch(actions.getRuleListOfAllDisposal(apolloClient.masters, requestParams));
	}, [
		disposalList,
		disposalList?.length,
		selectedDisposals,
		selectedDisposals?.length,
		state.allDisposalList?.length,
	]);

	useEffect(() => {
		if (!masterReducer.alldesposalRuleList?.length) return;
		localDispatch({
			alldesposalRuleList: masterReducer.alldesposalRuleList,
		});
	}, [JSON.stringify(masterReducer.alldesposalRuleList)]);

	/*================== GET SELECTED OR STANDARD DISPOSAL RULE LIST (END) ==================*/


	//Report step 1
	useEffect(() => {
		if (
			probings.length &&
			selectedCampaigns.length &&
			(selectedDisposals || disposalList.length) &&
			state.allDisposalList.length &&
			state.testTypeList.length &&
			state.testNameList.length &&
			state.excelDataList.length &&
			state.selectedDisposalFamily.length &&
			//state.alldesposalRuleList.length &&
			otherSettings.expand === true
		) {
			const smpl_coll = [];
			const sample_list = [];
			const filterBySampleAltitude = filters.filterBySampleAltitude;
			const filterByDepthInProbing = filters.filterByDepthInProbing;
			const sampleAltitdueVal = filters.sampleAltitdueVal;
			const depthInProbingVal = filters.depthInProbingVal;
			const depthInProbingType = filters.depthInProbingType;
			const depthInProbingRange = filters.depthInProbingRange;
			const measure_unit = authReducer.userData.default_unit
				? authReducer.userData.default_unit
				: 'M';

			//Loop through all Probings list of selected project
			for (const fil_prob_data of probings) {
				if (fil_prob_data.status != 'Delete') {
					let totalLayerLength = 0;
					for (let [index2, fil_layer] of fil_prob_data.layer.entries()) {
						//for calculating sample up and low range
						if (index2 === 0) {
							totalLayerLength = 0;
						} else {
							if (index2 - 1 < fil_prob_data.layer.length) {
								totalLayerLength += fil_prob_data.layer[index2 - 1].generalDetails.layerLength
									? Number(fil_prob_data.layer[index2 - 1].generalDetails.layerLength)
									: 0;
							}
						}
						const mainCount = totalLayerLength > 0 ? totalLayerLength / 100 : 0;

						//Get Layer Observation
						const layerObservation = fil_layer.observation?.value || '';

						//Get Layer Anomaly name
						let layerAnomaly = '';
						if (fil_layer.lithology[0].isDropDoun == true) {
							if (
								fil_layer.lithology[0].selectedanomaly.length > 0 &&
								typeof fil_layer.lithology[0].selectedanomaly != 'undefined'
							) {
								let tempAnomaly = [];
								fil_layer.lithology[0].selectedanomaly?.map(a => {
									const getAnomalyData = state.anomalyList.filter(val => val._id === a.value);
									if (getAnomalyData.length > 0) {
										tempAnomaly.push(getAnomalyData[0].name);
									}
								});
								layerAnomaly = tempAnomaly.join(', ');
							}
						} else {
							layerAnomaly = fil_layer.lithology[0]?.manualAnomaly || '';
						}

						//Get Layer Lithology name
						let lithologyName = ' - ';
						if (fil_layer.lithology[0].isDropDoun == true) {
							if (
								fil_layer.lithology[0].selectedlithologyfrom != '' &&
								typeof fil_layer.lithology[0].selectedlithologyfrom != 'undefined'
							) {
								const getLithoData = state.lithologyList.filter(
									val => val._id === fil_layer.lithology[0].selectedlithologyfrom.value
								);
								if (getLithoData.length > 0) {
									lithologyName = getLithoData[0].name;
								}
							}
						} else {
							const fromname = fil_layer.lithology[0].from;
							lithologyName = fromname != '' ? fromname : ' - ';

							// if(fil_layer.lithology[0].soil_family != ""){
							//     let patternObj = getGeologyData(fil_layer.lithology[0].soil_family);
							//     lithologyName = fromname + " ( "+ patternObj.name +" )";
							// }
						}

						//Get Layer Colour name
						let colourName = ' - ';
						let colourCode = '';
						if (fil_layer.color[0].isDropDoun == true) {
							if (
								fil_layer.color[0].selectedcolorfrom != '' &&
								typeof fil_layer.color[0].selectedcolorfrom != 'undefined'
							) {
								let getColorData = state.colorList.filter(
									val => val._id === fil_layer.color[0]?.selectedcolorfrom?.value
								);
								if (getColorData.length > 0) {
									colourName = getColorData[0].name;
									colourCode = getColorData[0].value;
								}
							}
						} else {
							let fromcolourCode = fil_layer.color[0].from;
							colourCode = fromcolourCode != '' ? fromcolourCode : ' - ';
							colourName = fromcolourCode != '' ? fromcolourCode : ' - ';
							if (fil_layer.color[0].fromname != '') {
								colourName = fil_layer.color[0].fromname;
								// let manualColorName = getColorDataName(fil_layer.color[0].from);
								// if(manualColorName != ""){
								//     colourName = manualColorName;
								// }
							}
						}
						//Prepare all sample sist of all probings
						for (let fil_smpl of fil_layer.sample) {
							//for calculating sample up and low range
							let ups = '';
							let lows = '';
							if (fil_smpl.isCompleteLayer) {
								ups = mainCount.toFixed(2);
								lows = (mainCount + Number(fil_layer.generalDetails.layerLength) / 100).toFixed(2);
							} else {
								ups = (fil_smpl.upper_depth ? Number(fil_smpl.upper_depth) / 100 : 0) + mainCount;
								lows = (fil_smpl.lower_depth ? Number(fil_smpl.lower_depth) / 100 : 0) + mainCount;

								ups = ups === parseInt(ups) ? ups : ups.toFixed(2);
								lows = lows === parseInt(lows) ? lows : lows.toFixed(2);
							}

							let condition = true;
							//Sample Altitude Filter
							if (filterBySampleAltitude == true && sampleAltitdueVal != '') {
								if (typeof fil_smpl.middel_depth != 'undefined') {
									let middel_depth =
										measure_unit == 'M'
											? Number(fil_smpl.middel_depth) / 100
											: fil_smpl.middel_depth;
									if (middel_depth > sampleAltitdueVal) {
										condition = false;
									}
								}
							}
							//Depth in probing Filter
							if (filterByDepthInProbing == true && depthInProbingType != '') {
								if (typeof fil_smpl.sample_depth_in_probing != 'undefined') {
									const sample_depth_in_probing =
										measure_unit == 'M'
											? Number(fil_smpl.sample_depth_in_probing) / 100
											: fil_smpl.sample_depth_in_probing;
									if (
										depthInProbingType == 'lessthanequal' &&
										depthInProbingVal != '' &&
										sample_depth_in_probing > depthInProbingVal
									) {
										condition = false;
									}
									if (
										depthInProbingType == 'greaterthanequal' &&
										depthInProbingVal != '' &&
										sample_depth_in_probing < depthInProbingVal
									) {
										condition = false;
									}
									if (depthInProbingType == 'betweenrange' && depthInProbingRange.length) {
										const depthInProbingRangeMin =
											measure_unit == 'M'
												? depthInProbingRange[0]
												: Number(depthInProbingRange[0]) * 100;
										const depthInProbingRangeMax =
											measure_unit == 'M'
												? depthInProbingRange[1]
												: Number(depthInProbingRange[1]) * 100;
										if (
											sample_depth_in_probing < depthInProbingRangeMin ||
											sample_depth_in_probing > depthInProbingRangeMax
										) {
											condition = false;
										}
									}
								}
							}

							Object.assign(fil_smpl, {
								probingid: fil_prob_data.probingid,
								campaignid: fil_prob_data.campaignid,
								probingcode: fil_prob_data.probingname || fil_prob_data.probingcode,
								altitude: fil_prob_data.altitude,
								longitude: fil_prob_data.longitude,
								latitude: fil_prob_data.latitude,
								lithology: lithologyName,
								colourname: colourName,
								colourcode: colourCode,
								uplowrange: ups + ' - ' + lows,
								uplowrangeForSearch:
									(Number.isInteger(ups) ? ups + '.00' : ups) +
									' - ' +
									(Number.isInteger(lows) ? lows + '.00' : lows), //for analysis report depth search
								layerObservation: layerObservation,
								layerAnomaly: layerAnomaly,
							});

							//Filter search textbox value of analysis report
							if (otherSettings?.findSearchValue) {
								const findSearchValue = otherSettings?.findSearchValue.toLowerCase();
								if (
									!fil_smpl.lithology.toLowerCase().includes(findSearchValue) &&
									!fil_smpl?.observation?.toLowerCase().includes(findSearchValue) &&
									!fil_smpl?.sampleName?.toLowerCase().includes(findSearchValue) &&
									!fil_smpl?.uplowrange?.toLowerCase().includes(findSearchValue) &&
									!fil_smpl?.uplowrangeForSearch?.toLowerCase().includes(findSearchValue) && //for analysis report depth search
									!fil_smpl.colourname.toLowerCase().includes(findSearchValue) &&
									!fil_smpl.probingcode.toLowerCase().includes(findSearchValue) &&
									(otherSettings?.from === 'sampleDisposalSetting'
										? !fil_smpl.layerAnomaly.toLowerCase().includes(findSearchValue)
										: true) &&
									(otherSettings?.from === 'sampleDisposalSetting'
										? !fil_smpl.layerObservation.toLowerCase().includes(findSearchValue)
										: true)
								) {
									condition = false;
								}
							}

							if (condition == true) {
								sample_list.push(fil_smpl.sampleNo);
								smpl_coll.push(fil_smpl);
							}
						}
					}
				}
			}

			localDispatch({
				allProbingSamples: smpl_coll, //All probings full sample info
				sample_list: sample_list, //All probings sample number array
				//allowReportGenerate: false
			});
		}
	}, [
		disposalList,
		state.allDisposalList,
		state.testTypeList,
		state.testNameList,
		state.excelDataList,
		state.selectedDisposalFamily,
		state.alldesposalRuleList,
		otherSettings?.valGreToFirstDisThrshold,
		otherSettings?.findSearchValue,
		otherSettings.expand,
		filters?.allPollutants,
		filters?.onlyPollutatsWhoHaveDisposalThreshold,
	]);

	//Report step 2
	useEffect(() => {
		generateReportPdf();
	}, [state.allProbingSamples]);
	//=========================== ALL FUNCTIONS START ===========================

	//Report step 3
	function generateReportPdf() {
		const excelDataList = state.excelDataList;
		if (!__.isEmpty(excelDataList)) {
			const dispo = selectedDisposals || disposalList;
			const activedesposalList = dispo?.map((val, index) => {
				return {
					desposal_id: val.value || val._id,
					name: val.label || val.name,
					color: val.color,
				};
			});
			const firstActiveDisposalId = activedesposalList.length
				? activedesposalList[0].desposal_id
				: '';

			const allProbingSamples = state.allProbingSamples?.map(prob => {
				return {
					probingcode: prob.probingcode,
					sampleNo: prob.sampleNo,
					campaignid: prob.campaignid,
					altitude: Number(prob.altitude),
					longitude: prob.longitude || prob.lng,
					latitude: prob.latitude || prob.lat,
					observation: prob.observation,
					lithology: prob.lithology,
					colourname: prob.colourname,
					colourcode: prob.colourcode,
					uplowrange: prob.uplowrange,
					sampleNameTop: Number(prob.sampleNameTop || 0),
					sampleNameBottom: Number(prob.sampleNameBottom || 0),
					sampleNameIsManual: prob.sampleNameIsManual,
					sampleNameManual: prob.sampleNameManual,
					layerObservation: prob.layerObservation,
					layerAnomaly: prob.layerAnomaly,
					sampleName: prob?.sampleName || '',
				};
			});
			const req = {
				excelDataList: excelDataList,
				selectedDisposalFamily: [],
				alldesposalRuleList: state.alldesposalRuleList,
				activedesposalList: activedesposalList,
				allProbingSamples: allProbingSamples,
				exceptionConst: state.exceptionConst,
			};
			const sampleValueAnalysis = sampleDisposalCalulation(req);

			const selectedPollutantFamilies = filters.selectedPollutantFamilies?.map(l => l.value);
			const allPollutants = filters.allPollutants;
			const onlyPollutatsWhoHaveDisposalThreshold = filters.onlyPollutatsWhoHaveDisposalThreshold;

			const temp_tst_arr = [];
			const test_list = [];
			const matched_sample_list = [];
			const tempAllSample = [];

			//This code is for display analysis Report in probing info modal even if there is no disposal family matched between uploaded excel and selected disposal family
			let isAnyCommonFamilyExist = true;
			if (otherSettings?.from === 'probinginfo') {
				isAnyCommonFamilyExist = findCommonElements(
					state.selectedDisposalFamily,
					state.allPollutantFamilies?.map(l => l.value)
				);
			}

			for (const sample of sampleValueAnalysis) {
				let tst_name = sample.tst_name; //pollutant name
				const tst_child_id = sample.tst_child_id; //pollutant id
				const Test_Parent_Id = sample.parent_testfamily_id; //pollutant family id

				//pollutant family should be exist in the Selected Disposal family.
				const isFamilyexistinSelDispo = state.selectedDisposalFamily.filter(
					h => h == Test_Parent_Id
				).length;

				if (
					Test_Parent_Id &&
					(onlyPollutatsWhoHaveDisposalThreshold ? sample.rule_exist : true) &&
					(allPollutants == false
						? isFamilyexistinSelDispo > 0 ||
						  (otherSettings?.from === 'probinginfo' && !isAnyCommonFamilyExist)
						: allPollutants)
				) {
					//If pollutant families are selected then generate report only for this selected families
					if (
						(selectedPollutantFamilies.length &&
							selectedPollutantFamilies.includes(Test_Parent_Id)) ||
						!selectedPollutantFamilies.length
					) {
						const sampl_finl1 = [];
						matched_sample_list.push(sample.sample_name);

						const temp_desp_res = sample.matched_desp_info;
						//Values Above Minimum Values filter of probing details page is true then only sample that is not matched with first disposal are need to be display
						if (
							otherSettings?.valGreToFirstDisThrshold == true
								? !__.isEmpty(temp_desp_res) && temp_desp_res?.desposal_id !== firstActiveDisposalId
								: true
						) {
							sampl_finl1.push({ ...sample, matched_desp: sample.matched_desp_info });
							tempAllSample.push({ ...sample, matched_desp: sample.matched_desp_info });

							var objnew = {};
							if (sampl_finl1.length) {
								//Get the actual testname instead of tag name
								const getActualTestname = state.testNameList.filter(g => g.id == tst_child_id);
								if (getActualTestname.length) {
									tst_name = getActualTestname[0].name;
								}

								objnew[tst_name] = sampl_finl1;

								const checkTempTestArr = temp_tst_arr.filter(t => Object.keys(t)[0] == tst_name);
								if (checkTempTestArr.length == 0) {
									temp_tst_arr.push(objnew);
								} else {
									//My new code (comment old code below) to find all indexes of matched testname (duplicate testname exist because same testname exist in different family)
									//So we need to find correct testname by using the parent_testfamily_id
									const getAllIndexes = getAllTestnameIndexes(temp_tst_arr, tst_name);
									let findStatus = false;
									for (let i = 0; i < getAllIndexes.length; i++) {
										const checkIndex = getAllIndexes[i];
										if (
											temp_tst_arr[checkIndex][tst_name].length > 0 &&
											temp_tst_arr[checkIndex][tst_name][0].parent_testfamily_id == Test_Parent_Id
										) {
											sampl_finl1?.map(s => {
												temp_tst_arr[checkIndex][tst_name].push(s);
											});
											findStatus = true;
											break;
										}
									}

									if (findStatus == false) {
										temp_tst_arr.push(objnew);
									}
								}
							}

							const test_family_data = state.testTypeList.filter(t => t.id == Test_Parent_Id);

							//Store only unique pollutant name and avoid to store duplicate pollutant name
							const checkTestList = test_list.filter(
								t => t.test_name == tst_name && t.family_id == Test_Parent_Id
							);
							if (checkTestList.length == 0) {
								test_list.push({
									family_name: test_family_data.length > 0 ? test_family_data[0].name : '',
									family_id: test_family_data.length > 0 ? Test_Parent_Id : '',
									test_name: tst_name,
									tst_child_id: tst_child_id,
								});
							}
						}
					}
				}
			}

			const result_data = temp_tst_arr;
			const fil_sample_list = [...new Set(matched_sample_list)];
			let fil_test_list = [...new Set(test_list)];

			//Sort selected sample names array based on sample list array for display edited excel sheet samples in correct order
			fil_sample_list.sort((a, b) => state.sample_list.indexOf(a) - state.sample_list.indexOf(b));

			let getMissingPollutant = chkMissingPollutant(fil_test_list);
			fil_test_list = getMissingPollutant.fil_test_list;
			result_data.push(...getMissingPollutant.missingPollu);

			let reportData = {
				projectid: authReducer.userData.projectid,
				campaignid: state.selCampaignsId, //all selected campaign id
				desposal_list: activedesposalList,
				sample_list: fil_sample_list, //all selected unique sample list
				test_list: fil_test_list, //excel pollutant list having its parent famili id and family name and pollutant id
				result_data: result_data, //excel pollutant name wise all samples list with match unmatch details
				tempAllSample: tempAllSample, //all samples details
				selectedPollutantFamilies: filters.selectedPollutantFamilies, //all selected pollutant families
				from: otherSettings?.from ?? '',
			};

			// let reportData = {
			// 	resp_data: resp_data,
			// };

			//I add OR condition below because Sometime there is no results when we search in analysis report, so in that case we need to display empty report
			if (
				reportData.sample_list.length > 0 ||
				(!reportData.sample_list.length && otherSettings?.findSearchValue)
			) {
				generateReportPdfPhase2(reportData);
			} else {
				//if we select Campaign 1 and polygon line 1 then api return all campaign 1 probings (and its samples inside layer property ) that exist in polygon line 1
				// But this samples are not exist in out excel sheet. then display this warning message.
				localDispatch({
					loadingHtmlMdlReport: false,
					warning: true,
					notification: {
						success: false,
						message: analysis_no_sample_matched_with_filter,
					},
				});
			}
		}
	}

	function chkMissingPollutant(fil_test_list) {
		/* ================ My code for Missing Pollutant Start ================ */

		const missingPollu = [];
		//I add this condition otherSettings?.valGreToFirstDisThrshold === false because Values Above Minimum Values filter is now added in the analysis report also
		//this filter is false by default, so display missing pollutants at that time but if its true then display only pollutant that matched with other than first disposal
		if (
			!filters.selectedPollutantFamilies.length &&
			(otherSettings?.from === 'analysis' ||
				(otherSettings?.from === 'probinginfo' && filters.allPollutants === true)) &&
			otherSettings?.valGreToFirstDisThrshold === false
		) {
			let notUploadedPollutant = state.alldesposalRuleList.filter(
				f => !fil_test_list.some(item => item.tst_child_id == f.child_id)
			);
			notUploadedPollutant = uniqBy(notUploadedPollutant, 'child_id');
			if (notUploadedPollutant.length) {
				notUploadedPollutant?.map(pdata => {
					const missingTestList = {
						family_name: pdata.test_name,
						family_id: pdata.test_id,
						test_name: pdata.child_name,
						tst_child_id: pdata.child_id,
						missingPollutant: true,
					};
					fil_test_list.push(missingTestList);

					const pollutantName = pdata.child_name;
					const pollutantId = pdata.child_id;
					const pollutantFamilyId = pdata.test_id;
					const alldisposalofPollutant = [];
					const missingPollutantInfo = [];
					const createObj = {};

					const getPollutantRules = state.alldesposalRuleList.filter(
						l => l.child_id == pollutantId
					);
					getPollutantRules?.map(rules => {
						let rules_val = '';

						if (rules.option_id == '1') {
							rules_val = rules.value;
						} else if (rules.option_id == '2') {
							rules_val = rules.value + '*';
						} else if (rules.option_id == '3') {
							rules_val = 'No Limit' + (rules?.nolimit_finalvalue ? ' *' : '');
						} else if (rules.option_id == '4') {
							rules_val =
								(rules.formula.condition == 'greter' ? '>' : '<') +
								'' +
								rules.formula.percentage +
								'% of ' +
								rules.formula.test[0].name;
						}

						alldisposalofPollutant.push({
							desposal_id: rules.desposal_id,
							rules_val: rules_val,
						});
					});
					missingPollutantInfo.push({
						all_desp_data: alldisposalofPollutant,
						parent_testfamily_id: pollutantFamilyId,
					});

					createObj[pollutantName] = missingPollutantInfo;
					missingPollu.push(createObj);
				});
			}
		}
		return {
			fil_test_list,
			missingPollu,
		};
	}

	//Report step 4
	const generateReportPdfPhase2 = reportData => {
		if (reportData.result_data.length) {
			const preparedData = prepareDataForAnalysis(reportData.tempAllSample);
			const totalSamples = reportData.sample_list.length;
			const displaySamples =
				filters.pollutantsNextToEach === true
					? totalSamples < 10
						? totalSamples
						: totalSamples
					: 10;

			const totalLoops = reportData.sample_list.length
				? Math.ceil(reportData.sample_list.length / displaySamples)
				: 0;
			reportData.dataToPrint = [];
			reportData.dataToPrintPdf = [];
			reportData.higestMatchedDisposals = [];
			for (let i = 0; i < totalLoops; i++) {
				const higestMatchedDisposals = [];
				reportData.sample_list?.map(s => {
					const getCampaignIdOfSampleArr = reportData.tempAllSample.filter(l => l.sample_name == s);
					const getCampaignIdOfSample =
						getCampaignIdOfSampleArr.length && getCampaignIdOfSampleArr[0].campaignid;
					const chkForMissingPollutant = reportData.tempAllSample.filter(
						l => l.chkForMissingPollutant === false && l.sample_name === s
					);
					higestMatchedDisposals.push({
						sample_name: s,
						sample_campaign: getCampaignIdOfSample,
						last_matched_desposal: {},
						chkForMissingPollutant: chkForMissingPollutant.length ? false : true,
						isForcedDisposal: false, //initially false, if any sample matched forcefully then it is true
					});
				});
				reportData.higestMatchedDisposals.push(higestMatchedDisposals);

				const tempTestFamilies = [];
				const blockedSamples = [];

				reportData.result_data?.map((testfamily, k) => {
					
					const test_name = Object.keys(testfamily);
					const tempObject = {};
					const parent_testfamily_id = testfamily[test_name[0]][0].parent_testfamily_id;
					const isOptionalArrData = masterReducer?.alldesposalRuleList?.filter((item) => {
						return item.test_id === parent_testfamily_id && Number(item.child_id) === testfamily[test_name[0]][0].tst_child_id;
					  });
					
					tempObject.testFamily = test_name[0];
					tempObject.testId = testfamily[test_name[0]][0].tst_child_id;
					tempObject.isOptionalArr = isOptionalArrData 
					

					tempObject.samples = [];
					tempObject.disposals = [];
					if (
						testfamily[test_name[0]] &&
						testfamily[test_name[0]].length &&
						testfamily[test_name[0]][0].all_desp_data
					) {
						tempObject.disposals = testfamily[test_name[0]][0].all_desp_data;
					}

					for (let j = 0; j < displaySamples; j++) {
						const tempSampleName = reportData.sample_list[i * displaySamples + j];
						const sample_found = testfamily[test_name[0]].filter(
							t => t.sample_name == tempSampleName
						);
						const tempDesposal = [];
						reportData.desposal_list?.map(disposal => {
							if (sample_found.length !== 0) {
								if (sample_found[0].all_desp_data) {
									const disposalFound = sample_found[0].all_desp_data.filter(
										dis => dis.desposal_id == disposal.desposal_id
									);
									if (disposalFound.length !== 0) {
										tempDesposal.push(disposalFound[0]);
									} else {
										tempDesposal.push(disposal);
									}
								} else {
									tempDesposal.push(disposal);
								}
							} else {
								tempDesposal.push(disposal);
							}
						});
						if (sample_found.length == 0) {
							if (typeof tempSampleName != 'undefined') {
								const getdtlfromotrcmpsmpl = reportData.tempAllSample.filter(
									t => t.sample_name == tempSampleName
								);
								if (getdtlfromotrcmpsmpl.length > 0) {
									tempObject.samples.push({
										all_desp_data: tempDesposal,
										colourcode: getdtlfromotrcmpsmpl[0].colourcode,
										colourname: getdtlfromotrcmpsmpl[0].colourname,
										lithology: getdtlfromotrcmpsmpl[0].lithology,
										matched_desp: {},
										parent_testfamily_id: getdtlfromotrcmpsmpl[0].parent_testfamily_id,
										probingcode: getdtlfromotrcmpsmpl[0].probingcode,
										sample_length: getdtlfromotrcmpsmpl[0].sample_length,
										sample_name: tempSampleName ? tempSampleName : '',
										sample_name_range: getdtlfromotrcmpsmpl[0].sample_name_range,
										sample_observation: getdtlfromotrcmpsmpl[0].sample_observation,
										stringnotmatched: false,
										value: '',
										layer_anomaly: getdtlfromotrcmpsmpl[0].layer_anomaly,
										layer_observation: getdtlfromotrcmpsmpl[0].layer_observation,
									});
								}
							} else {
								//My code to check the blocked disposal sample wise
								if (typeof tempSampleName != 'undefined' && sample_found.length == 0) {
									if (!blockedSamples.includes(tempSampleName)) {
										blockedSamples.push(tempSampleName);
									}
								}
								//For Column who have not sample ( for blank sample column )
								tempObject.samples.push({
									probingcode: '-',
									sample_length: 0,
									sample_name: tempSampleName ? tempSampleName : '',
									value: '',
									matched_desp: {},
									all_desp_data: tempDesposal,
								});
							}
						} else {
							if (!sample_found[0].hasOwnProperty('matched_desp')) {
								sample_found[0].matched_desp = {};
							} else {
								reportData.higestMatchedDisposals[i]?.map(h => {
									if (h.sample_name == tempSampleName) {
										//My code to only check the samples who have the rules added in any selected disposals.
										const checkThresholdrulevalinanyseldispoexist = sample_found[0].all_desp_data.filter(
											l => l.rules_val != ''
										).length;
										//sample_found[0].stringnotmatched == false do not consider the sample value like n.a, not or any string while the final disposal selection. stringnotmatched = true for all values like n.a, not etc...
										if (
											checkThresholdrulevalinanyseldispoexist > 0 &&
											sample_found[0].stringnotmatched == false
										) {
											let matchedKey = 0;
											reportData.desposal_list?.map((o, d_k) => {
												if (sample_found[0].matched_desp.desposal_id == o.desposal_id) {
													matchedKey = d_k;
												}
											});
											//My code to check the blocked disposal sample wise
											if (typeof sample_found[0].matched_desp.desposal_id == 'undefined') {
												if (!blockedSamples.includes(h.sample_name)) {
													blockedSamples.push(h.sample_name);
												}
											}
											const tempMatchedDesposal = {
												disposal_key: matchedKey,
												desposal_id: sample_found[0].matched_desp.desposal_id,
												name: sample_found[0].matched_desp.name,
												color: sample_found[0].matched_desp.color,
											};
											if (h.last_matched_desposal.hasOwnProperty('disposal_key')) {
												if (h.last_matched_desposal.disposal_key < matchedKey) {
													h.last_matched_desposal = tempMatchedDesposal;
												}
											} else {
												h.last_matched_desposal = tempMatchedDesposal;
											}
										}
										//stringnotmatched == true when there is text like na or n.a or not etc.. so in this case do not satisfied this sample with any of the disposal
										if (sample_found[0].stringnotmatched == true) {
											if (!blockedSamples.includes(h.sample_name)) {
												blockedSamples.push(h.sample_name);
											}
										}
									}
								});
							}
							sample_found[0].all_desp_data = tempDesposal;

							//My code to remove the sample background color if sample matched with first disposal
							if (Object.keys(sample_found[0].matched_desp).length > 0) {
								const matched_dispo_id = sample_found[0].matched_desp.desposal_id;
								const first_dispo_id = sample_found[0].all_desp_data[0].desposal_id;
								if (matched_dispo_id == first_dispo_id) {
									sample_found[0].matched_desp.color = '';
								}
							}
							tempObject.samples.push(sample_found[0]);
						}
					}

					tempTestFamilies.push(tempObject);
					let family_name_pdf = 'Unknown TestFamily';
					let family_id_pdf = '';
					const testF = reportData.test_list.filter(
						tf => tf.test_name == test_name[0] && tf.family_id == parent_testfamily_id
					);
					if (testF.length !== 0) {
						family_name_pdf = testF[0].family_name;
						family_id_pdf = testF[0].family_id;
					}
					let mtf = [];

					if (reportData.dataToPrintPdf[i]) {
						mtf = reportData.dataToPrintPdf[i].filter(dtp => dtp.family_name == family_name_pdf);
					}
					if (mtf.length !== 0) {
						reportData.dataToPrintPdf[i]?.map(k => {
							if (k.family_name == family_name_pdf) {
								k.tests_list.push(tempObject);
							}
						});
					} else {
						if (!reportData.dataToPrintPdf[i]) {
							reportData.dataToPrintPdf[i] = [];
						}
						reportData.dataToPrintPdf[i].push({
							family_name: family_name_pdf,
							family_id: family_id_pdf,
							tests_list: [tempObject],
						});
					}
				});

				//let disposalPollutantArr = [];
				let missingPollutantIds = [];
				//I put below if condition because if 'Values Above Minimum Values' toggle is true in modalprobing analysis report, then do not check this below logic because we still need to display final matched disposal with all pollutant values whose value is greter than first active disposal threshold value.
				if (otherSettings?.valGreToFirstDisThrshold !== true) {
					//My code to make the matched disposal blank when pollutant is not uploaded but pollutant rules exist in matched disposal
					reportData.higestMatchedDisposals[i]?.map(h => {
						let checkAllpoolutantPresent = '';
						//if any pollutant value of sample matched with no limit rule and if nolimit_finalvalue is enable then don't check missing pollutant logic (that makes matched disposal blank) for ex. : (h.chkForMissingPollutant === true)
						if (h.chkForMissingPollutant === true) {
							//Now checked by each sample wise due to user is able to upload multiple sheet for one campaign
							if (typeof h.last_matched_desposal.desposal_id != 'undefined') {
								let poolutantPresent = CompareExcelPollutantWithDisposalPollutant(
									h,
									reportData.tempAllSample,
									state.alldesposalRuleList,
									false,
									preparedData
								);
								if (poolutantPresent.requiredPollutant.length > 0) {
									missingPollutantIds.push(...poolutantPresent.requiredPollutant);
								}
								checkAllpoolutantPresent = poolutantPresent.status;
							}
							if (checkAllpoolutantPresent === false) {
								h.last_matched_desposal = {};
							}
							if (blockedSamples.includes(h.sample_name)) {
								h.last_matched_desposal = {};
							}
						}
					});
				}

				if (missingPollutantIds.length > 0) {
					missingPollutantIds = [...new Set(missingPollutantIds)];
				}

				reportData.dataToPrint.push(tempTestFamilies);

				/*================ Code start for disposal checking ================*/
				//Loop through all samples to recheck its last matched disposal that all pollutant values are satisfied with the selected disposal or not
				reportData.higestMatchedDisposals[i]?.map((sample_data, sample_index) => {
					//Check if sample matched with any disposal or not
					if (
						sample_data.last_matched_desposal != '' &&
						sample_data.chkForMissingPollutant === true
					) {
						reportData = checkSelectedDisposalMatchedWithAllPollutant(
							sample_data,
							reportData,
							sample_index,
							i
						);
					}
				});
				/*================ Code end for disposal checking ================*/
			}

			/*================== Code start for Set Forcefully Disposal to a sample ==================*/
			const fromPage = reportData?.from;
			if (fromPage !== 'sampleDisposalSetting') {
				//I put this condition because in sample disposal setting page i need to display actual matched disposal color below the forced dropdownlist at the end.
				if (state.initialForcedSampleData?.length > 0) {
					reportData.higestMatchedDisposals?.map(matchedDispo => {
						matchedDispo?.map(d => {
							if (!__.isEmpty(d.last_matched_desposal) || __.isEmpty(d.last_matched_desposal)) {
								const getForcedDispRec = state.initialForcedSampleData.find(
									f => f.sampleName === d.sample_name && f.sampleCampaign === d.sample_campaign
								);
								if (!__.isEmpty(getForcedDispRec)) {
									const getDispoIdx = reportData.desposal_list.findIndex(
										i => i.desposal_id === getForcedDispRec.desposalId
									);
									if (getDispoIdx !== -1) {
										d.last_matched_desposal = {
											...reportData.desposal_list[getDispoIdx],
											disposal_key: getDispoIdx,
										};
										d.isForcedDisposal = true; //sample is forcefully matched with a disposal
									}
								}
							}
						});
					});
				}
			}
			/*================== Code end for Set Forcefully Disposal to a sample ==================*/

			reportData.project_name = '';
			reportData.project_address = '';
			reportData.project_date = '';
			reportData.campaign_name = '';
			reportData.origin_name = '';
			reportData.destination_name = '';
			reportData.boundary_name = '';
			reportData.pollutionzone_name = '';
			const projectDetails = authReducer.companyList.filter(
				p => p.projectid == authReducer.userData.projectid
			);
			if (projectDetails.length) {
				reportData.project_name = projectDetails[0].projectname;
				reportData.project_address = projectDetails[0].address;
				reportData.project_date = projectDetails[0].startDate
					? moment(new Date(projectDetails[0].startDate)).format('DD/MM/YYYY')
					: '';
			}

			if (state.selCampaignsId.length > 0) {
				state.selCampaignsId?.map((l, i) => {
					if (i > 0) {
						reportData.campaign_name += ', ';
					}
					reportData.campaign_name += l.label;
				});
			}
			if (filters.selectedOrigins.length > 0) {
				filters.selectedOrigins?.map((l, i) => {
					if (i > 0) {
						reportData.origin_name += ', ';
					}
					reportData.origin_name += l.label;
				});
			}
			if (filters.selectedDestinations.length > 0) {
				filters.selectedDestinations?.map((l, i) => {
					if (i > 0) {
						reportData.destination_name += ', ';
					}
					reportData.destination_name += l.label;
				});
			}
			if (filters.selectedBoundaries.length > 0) {
				filters.selectedBoundaries?.map((l, i) => {
					if (i > 0) {
						reportData.boundary_name += ', ';
					}
					reportData.boundary_name += l.label;
				});
			}
			if (filters.selectedPollutionzone.length > 0) {
				filters.selectedPollutionzone?.map((l, i) => {
					if (i > 0) {
						reportData.pollutionzone_name += ', ';
					}
					reportData.pollutionzone_name += l.label;
				});
			}
			reportData.heigth =
				600 +
				20 * (reportData.test_list.length ? reportData.test_list.length : 1) +
				20 * (reportData.dataToPrintPdf.length ? reportData.dataToPrintPdf[0].length : 1);
			reportData.width =
				1820 + 100 * (reportData.desposal_list.length ? reportData.desposal_list.length : 1);
			reportData.hideDisposalInReport = filters.hideDisposalInReport;
			reportData.disposalThresholdBeforeSamples = filters.disposalThresholdBeforeSamples;
			reportData.onlyPollutatsWhoHaveDisposalThreshold =
				filters.onlyPollutatsWhoHaveDisposalThreshold;

			//when we switch on this filter (Values Above Minimum Values) then get higestMatchedDisposals from the state.tempHigestMatchedDisposals (it is stored while we generating analysis report initially)
			//Because when we switch on this filter then it only display pollutant and its value who have matched greter to first disposal.
			//So it consider only these pollutants and decide the matched disposal among these displayed pollutants and its rule. So sometime unmatched sample's are being matched with some disposal. which is wrong.
			//So i store the matched dispsoals into one state array and use it when user switch on this filter. so it use the initially matched disposals array and generate the result.
			//so it works as per client said 'this filter should only changes the display and not have any effect on the matching disposal process.'
			if (otherSettings?.valGreToFirstDisThrshold === true) {
				reportData.higestMatchedDisposals = state.tempHigestMatchedDisposals;
			}

			//============================================ START ============================================
			//Sort Testfamily order in the analysis report as per the first disposal order

			if (reportData.dataToPrintPdf.length) {
				const firstActiveDisposalId = reportData.desposal_list[0].desposal_id;
				const firstActiveDisposalArr = state.allDisposalList.find(
					l => l._id === firstActiveDisposalId
				);
				const testFmlOrdOfFrtDisp = firstActiveDisposalArr.test_list;
				const testFmlOrdShouldBe = testFmlOrdOfFrtDisp?.map(l => l._id);

				let dataToPrintPdf = [...reportData.dataToPrintPdf[0]];
				const allFamilyIdsOfExcel = dataToPrintPdf?.map(l => l.family_id); //If we checked all pollutant checkbox then there are families other than first disposal families

				if (allFamilyIdsOfExcel.length !== testFmlOrdShouldBe.length) {
					const notPresentInData = allFamilyIdsOfExcel.filter(
						val => !testFmlOrdShouldBe.includes(val)
					);
					testFmlOrdShouldBe.push(...notPresentInData);
				}

				dataToPrintPdf = dataToPrintPdf.sort(function(a, b) {
					return testFmlOrdShouldBe.indexOf(a.family_id) - testFmlOrdShouldBe.indexOf(b.family_id);
				});

				// change in allTestList map by that all testList
				//Sort TestNames order of each TestFamily in the analysis report as per the first disposal TestNames order of each TestFamily
				dataToPrintPdf?.map(l => {
					const getTstFml = state.allTestTypeList?.find(t => t._id === l.family_id);
					if (!__.isEmpty(getTstFml)) {
						const testIdOrdShouldBe = getTstFml?.children?.map(c => c.id);
						l.tests_list = l.tests_list.sort(function(a, b) {
							return testIdOrdShouldBe.indexOf(a.testId) - testIdOrdShouldBe.indexOf(b.testId);
						});
					}
				});
				reportData.dataToPrintPdf = [dataToPrintPdf]; //Assign the families array after sorting
			}

			//============================================ END ============================================//

			if (
				reportData.dataToPrint.length > 0 ||
				(!reportData.dataToPrint.length && otherSettings?.findSearchValue)
			) {
				localDispatch({
					reportData: reportData,
					tempHigestMatchedDisposals:
						otherSettings?.valGreToFirstDisThrshold === false
							? reportData.higestMatchedDisposals
							: state.tempHigestMatchedDisposals, //Store Matched Disposal in state while we generate analysis report first time
				});
				setLoading2(false);
			}
		} else {
			localDispatch({
				reportData: reportData, // for view probing details
				notExistPolFamilyinDispoFam: true,
				warning: true,
				notification: {
					success: false,
					message: analysis_pollutant_testfamily_not_matched_with_disposal_testfamily,
				},
			});
			setLoading2(false); // for view probing details
		}
	};

	const checkAllPollutantsValueMatchedWithSelectedDispo = sampleData => {
		let activedesposalList = [sampleData.last_matched_desposal]; //A sample disposal that is finally selected
		let excelDataList = state.excelDataList;
		//Find one sample to iterate
		let probingSamplesToIterate = state.allProbingSamples.filter(
			s => s.sampleNo == sampleData.sample_name
		);

		const allProbingSamples = probingSamplesToIterate?.map(prob => {
			return {
				probingcode: prob.probingcode,
				sampleNo: prob.sampleNo,
				campaignid: prob.campaignid,
				altitude: Number(prob.altitude),
				longitude: prob.longitude || prob.lng,
				latitude: prob.latitude || prob.lat,
				observation: prob.observation,
				lithology: prob.lithology,
				colourname: prob.colourname,
				colourcode: prob.colourcode,
				uplowrange: prob.uplowrange,
				sampleNameTop: Number(prob.sampleNameTop || 0),
				sampleNameBottom: Number(prob.sampleNameBottom || 0),
				sampleNameIsManual: prob.sampleNameIsManual,
				sampleNameManual: prob.sampleNameManual,
				layerObservation: prob.layerObservation,
				layerAnomaly: prob.layerAnomaly,
			};
		});
		const req = {
			excelDataList: excelDataList,
			selectedDisposalFamily: [],
			alldesposalRuleList: state.alldesposalRuleList,
			activedesposalList: activedesposalList,
			allProbingSamples: allProbingSamples,
			exceptionConst: state.exceptionConst,
		};

		const sampleValueAnalysis = sampleDisposalCalulation(req);
		let samplewithRuleExist = sampleValueAnalysis.filter(s => s.rule_exist === true);

		samplewithRuleExist = samplewithRuleExist?.map(
			({ matched_desp_info: matched_desp, matched_desp: matched_desp_info, ...rest }) => ({
				matched_desp,
				matched_desp_info,
				...rest,
			})
		);

		let getProblematicPollutnats = [];
		getProblematicPollutnats = samplewithRuleExist.filter(
			l => Object.keys(l.matched_desp).length === 0
		);

		let problematicPollutnatsOfSample = {
			sampleName: sampleData.sample_name,
			problematicPollutants: getProblematicPollutnats,
		};

		return problematicPollutnatsOfSample;
	}; //End of function

	const matchWithNextDisposals = (sampleData, problematicPollutant, allDisposalList) => {
		let lastMatchedDisposalIndex = sampleData.last_matched_desposal.disposal_key;
		let nextDisposal = allDisposalList.filter((l, i) => i > lastMatchedDisposalIndex);

		let problematicTestChildId = problematicPollutant.problematicPollutants?.map(
			value => value.tst_child_id
		);

		let activedesposalList = nextDisposal;
		let excelDataList = state.excelDataList;

		let probingSamplesToIterate = state.allProbingSamples.filter(
			s => s.sampleNo == sampleData.sample_name
		);

		const allProbingSamples = probingSamplesToIterate?.map(prob => {
			return {
				probingcode: prob.probingcode,
				sampleNo: prob.sampleNo,
				campaignid: prob.campaignid,
				altitude: Number(prob.altitude),
				longitude: prob.longitude || prob.lng,
				latitude: prob.latitude || prob.lat,
				observation: prob.observation,
				lithology: prob.lithology,
				colourname: prob.colourname,
				colourcode: prob.colourcode,
				uplowrange: prob.uplowrange,
				sampleNameTop: Number(prob.sampleNameTop || 0),
				sampleNameBottom: Number(prob.sampleNameBottom || 0),
				sampleNameIsManual: prob.sampleNameIsManual,
				sampleNameManual: prob.sampleNameManual,
				layerObservation: prob.layerObservation,
				layerAnomaly: prob.layerAnomaly,
			};
		});

		const req = {
			excelDataList: excelDataList,
			selectedDisposalFamily: [],
			alldesposalRuleList: state.alldesposalRuleList, // .filter(r => r.desposal_id in disposalIds)
			activedesposalList: activedesposalList,
			allProbingSamples: allProbingSamples,
			exceptionConst: state.exceptionConst,
		};
		let sampleValueAnalysis = sampleDisposalCalulation(req);
		sampleValueAnalysis = sampleValueAnalysis.filter(s => {
			return problematicTestChildId.includes(s.tst_child_id);
		});
		sampleValueAnalysis = sampleValueAnalysis?.map(
			({ matched_desp_info: matched_desp, matched_desp: matched_desp_info, ...rest }) => ({
				matched_desp,
				matched_desp_info,
				...rest,
			})
		);
		return sampleValueAnalysis;
	};

	const checkSelectedDisposalMatchedWithAllPollutant = (
		sample_data,
		reportData,
		sample_index,
		loop_index
	) => {
		//Check all pollutants values are satisfied with the last matched disposal or not
		let allPollutantMatchedWithDisposal = checkAllPollutantsValueMatchedWithSelectedDispo(
			sample_data
		);

		//If any sample pollutants value is not matched with selected disposal threshold value
		if (allPollutantMatchedWithDisposal.problematicPollutants.length > 0) {
			//Check all problematic pollutants (that are not satisfied with the current selected disposal) are satisfied with the all next disposals
			let matchedWithNextDisposal = matchWithNextDisposals(
				sample_data,
				allPollutantMatchedWithDisposal,
				reportData.desposal_list
			);

			//IF next disposal exist to check then it has records
			if (matchedWithNextDisposal.length > 0) {
				let matchedDisposalIndex = [];
				matchedWithNextDisposal?.map((mdata, mindex) => {
					//if pollutant matched with next disposal then replace the disposal color to this pollutant value
					if (Object.keys(mdata.matched_desp).length > 0) {
						//Store the next disposal matched index of problematic pollutants
						let disposalIndex = reportData.desposal_list.findIndex(
							t => t.desposal_id == mdata.matched_desp.desposal_id
						);
						matchedDisposalIndex.push(disposalIndex);

						/* ===== Process start to add or remove color to problematic pollutant ===== */
						//Get the actual testname instead of tag name
						let getActualTestname = state.testNameList.filter(g => g.id == mdata.tst_child_id);
						if (getActualTestname.length > 0) {
							let tst_name = getActualTestname[0].name;

							let checkTempTestArr = reportData.result_data.filter(
								t => Object.keys(t)[0] == tst_name
							);
							//Check if no duplicate testname found, if found then going to else
							if (checkTempTestArr.length == 1) {
								let getIndexe = getAllTestnameIndexes(reportData.result_data, tst_name);
								let findIndex = getIndexe[0];
								let testnamearray = Object.values(reportData.result_data[findIndex]);
								let sampleIndex = testnamearray[0].findIndex(
									t =>
										t.sample_name == mdata.sample_name &&
										t.tst_child_id == mdata.tst_child_id &&
										t.parent_testfamily_id == mdata.parent_testfamily_id
								);
								//Finally replace the matched_desp of problematic pollutant
								reportData.result_data[findIndex][tst_name][sampleIndex].matched_desp =
									mdata.matched_desp;
							} else {
								//In the case of same pollutant exist twice, duplicate pollutant name exist
								let getAllIndexes = getAllTestnameIndexes(reportData.result_data, tst_name);
								for (let i = 0; i < getAllIndexes.length; i++) {
									let findIndex = getAllIndexes[i];
									let testnamearray = Object.values(reportData.result_data[findIndex]);
									let sampleIndex = testnamearray[0].findIndex(
										t =>
											t.sample_name == mdata.sample_name &&
											t.tst_child_id == mdata.tst_child_id &&
											t.parent_testfamily_id == mdata.parent_testfamily_id
									);

									if (sampleIndex != '-1') {
										reportData.result_data[findIndex][tst_name][sampleIndex].matched_desp =
											mdata.matched_desp;
										break;
									}
								}
							}
						}
					} else {
						//Remove the color of this pollutant value when pollutant not finding any next disposal

						//Get the actual testname instead of tag name
						let getActualTestname = state.testNameList.filter(g => g.id == mdata.tst_child_id);
						if (getActualTestname.length > 0) {
							let tst_name = getActualTestname[0].name;

							let checkTempTestArr = reportData.result_data.filter(
								t => Object.keys(t)[0] == tst_name
							);
							//Check if no duplicate testname found, if found then going to else
							if (checkTempTestArr.length == 1) {
								let getIndexe = getAllTestnameIndexes(reportData.result_data, tst_name);
								let findIndex = getIndexe[0];
								let testnamearray = Object.values(reportData.result_data[findIndex]);
								let sampleIndex = testnamearray[0].findIndex(
									t =>
										t.sample_name == mdata.sample_name &&
										t.tst_child_id == mdata.tst_child_id &&
										t.parent_testfamily_id == mdata.parent_testfamily_id
								);

								//Remove the matched_desp of problematic pollutant
								if (sampleIndex != '-1') {
									reportData.result_data[findIndex][tst_name][sampleIndex].matched_desp = {};
								}
							} else {
								let getAllIndexes = getAllTestnameIndexes(reportData.result_data, tst_name);
								for (let i = 0; i < getAllIndexes.length; i++) {
									let findIndex = getAllIndexes[i];
									let testnamearray = Object.values(reportData.result_data[findIndex]);
									let sampleIndex = testnamearray[0].findIndex(
										t =>
											t.sample_name == mdata.sample_name &&
											t.tst_child_id == mdata.tst_child_id &&
											t.parent_testfamily_id == mdata.parent_testfamily_id
									);

									if (sampleIndex != '-1') {
										//Remove the matched_desp of problematic pollutant
										reportData.result_data[findIndex][tst_name][sampleIndex].matched_desp = {};
										break;
									}
								}
							}
						}
					}
					/* ===== Process end to add or remove color to problematic pollutant ===== */
				});

				//Check all problematic pollutants matched with next disposal or not
				let isAllProblematicPollutantMatchedWithNextDispo = matchedWithNextDisposal.filter(
					l => Object.keys(l.matched_desp).length === 0
				);

				//If all problematic pollutants are matched with the next disposals
				if (isAllProblematicPollutantMatchedWithNextDispo.length == 0) {
					//Finding the maximum disposal index of problematic pollutants of this sample
					let maxDisposalIndex = Math.max(...matchedDisposalIndex);
					let finalDisposal = reportData.desposal_list[maxDisposalIndex];
					finalDisposal.disposal_key = maxDisposalIndex;

					let tempSdata = {
						last_matched_desposal: finalDisposal,
						sample_campaign: sample_data.sample_campaign,
						sample_name: sample_data.sample_name,
					};

					//Check Again with the Max disposal of all problematic pollutant
					let allPollutantMatchedWithDisposal = checkAllPollutantsValueMatchedWithSelectedDispo(
						tempSdata
					);

					//Max disposal contains the problematic pollutants.
					if (allPollutantMatchedWithDisposal.problematicPollutants.length > 0) {
						//Cycling the process again by calling the same function with the Max disposal
						checkSelectedDisposalMatchedWithAllPollutant(
							tempSdata,
							reportData,
							sample_index,
							loop_index
						);
					} else {
						//If there is not any problematic pollutant in the Max disposal then Assign the next disposal to this sample
						sample_data.last_matched_desposal = finalDisposal;
						reportData.higestMatchedDisposals[loop_index][sample_index] = sample_data;
					}
				} else {
					sample_data.last_matched_desposal = {};
					reportData.higestMatchedDisposals[loop_index][sample_index] = sample_data;
				}
			} else {
				sample_data.last_matched_desposal = {};
				reportData.higestMatchedDisposals[loop_index][sample_index] = sample_data;
			}
		}
		return reportData;
	};

	const getAllTestnameIndexes = (temp_tst_arr, tst_name) => {
		const indexes = [];
		temp_tst_arr?.map((d, i) => {
			if (Object.keys(d)[0] == tst_name) {
				indexes.push(i);
			}
		});

		return indexes;
	};

	//=========================== ALL FUNCTIONS END ===========================

	return {
		reportData: state.reportData,
		loading1: loading1,
	};
}
