import React, { useEffect, useReducer, useState, useRef } from 'react';
import { Button, ButtonToolbar, Input, Label, Modal, Row, Table } from 'reactstrap';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import __ from 'underscore';
import styled from 'styled-components';

const tlang = ChangeAppLanguageFront.translateLanguage;
const cancel_btn = tlang('cancel_btn') || 'Cancel';
const user_profile_detail = tlang('user_profile_detail') || 'Details';
const excelupload_campaign = tlang('excelupload_campaign') || 'Campaign';
const analysis_file_name = tlang('analysis_file_name') || 'File name';
const analysis_sheet_name = tlang('analysis_sheet_name') || 'Sheet name';
const viewExcel_Rowofprobings = tlang('viewExcel_Rowofprobings') || 'Row of probings';
const viewExcel_Rowofprobingsnum = tlang('viewExcel_Rowofprobingsnum') || 'Row of probings number';
const probing_import_sheet = tlang('probing_import_sheet') || 'Probing Import Sheet';

const initialState = {
	cmpName: '',
	fileName: '',
	sheetName: '',
	rowRange: '',
	rowNo: '',
	sheetHeader: [],
	sheetData: [],
	tableApproxWidth: 0,
};

function reducer(state, action) {
	switch (action.type) {
		default:
			const upd = {};
			for (const key of Object.keys(action)) {
				upd[key] = action[key];
			}
			// console.log(`upd`, { upd });
			return { ...state, ...upd };
	}
}

export default function ModalProbingImportSheet({ isOpen, initialData, onClose }) {
	const [state, localDispatch] = useReducer(reducer, initialState);

	const scrollWrapper1 = (event) => {
		var wrapper1 = document.getElementById('scrollbarWrapper1');
		var wrapper2 = document.getElementById('scrollbarWrapper2');

		wrapper1.onscroll = function () {
			wrapper2.scrollLeft = wrapper1.scrollLeft;
		};
	}

	const scrollWrapper2 = (event) => {
		var wrapper1 = document.getElementById('scrollbarWrapper1');
		var wrapper2 = document.getElementById('scrollbarWrapper2');

		wrapper2.onscroll = function () {
			wrapper1.scrollLeft = wrapper2.scrollLeft;
		};
	}

	const headersList = () => {
		let allKeys = state.sheetHeader;
		let header = []
		allKeys.map((key, ind) => {
				header.push(<th key={Math.random()}>{key}</th>)
		})
		return (
			<>
				<tr key={Math.random()}>{header}</tr>
			</>
		)
	}

	const renderTableData = () => {
		let sheetHeader = state.sheetHeader;
		let sheetData = state.sheetData;
		return sheetData.map((sd) => {
			return (
				<tr key={Math.random()}>
					{sheetHeader.map((hd) => {
						return <td key={Math.random()}>{sd[hd]}</td>
					})}
				</tr>
			)
		})
	}

	useEffect(() => {
		if (!__.isEmpty(initialData)) {
			localDispatch({
				cmpName: initialData.campaignName,
				fileName: initialData.file_name,
				sheetName: initialData.sheet_name,
				rowRange: initialData.rows_for_head_probing,
				rowNo: initialData.row_for_head_probing_no,
				sheetHeader: initialData.sheet_resp_header,
				sheetData: initialData.sheet_resp_data,
				tableApproxWidth: initialData.tableApproxWidth,
			});
		}
	}, [initialData]);

	return (
		<Modal
			isOpen={isOpen}
			toggle={onClose}
			className={`modal-dialog--success modal-dialog--header`}
			style={{ width: "80%", minWidth: "80%", maxWidth: "80%" }}
		>
			<div className="modal__header">
				<button className="lnr lnr-cross modal__close-btn" type="button" onClick={onClose} />
				<h4 className="bold-text  modal__title">{probing_import_sheet + ' '+ user_profile_detail}</h4>
			</div>
			<div className="modal__body">
				<Row>
					<>
						<div className="table-responsive">
							<Sheet>
							<Table striped bordered hover>
								<thead className='sheetHeader'>
									<tr>
										<th>{excelupload_campaign}</th>
										<th>{analysis_file_name}</th>
										<th>{analysis_sheet_name}</th>
										<th>{viewExcel_Rowofprobings}</th>
										<th>{viewExcel_Rowofprobingsnum}</th>
									</tr>
								</thead>
									<tbody>
									<tr>
										<td>{state.cmpName}</td>
										<td>{state.fileName}</td>
										<td>{state.sheetName}</td>
										<td>{state.rowRange}</td>
										<td>{state.rowNo}</td>
									</tr>
								</tbody>
							</Table>
							</Sheet>
						</div>
						<div onScroll={(event) => scrollWrapper1(event)} id="scrollbarWrapper1" style={{ overflowX: "scroll" }}><div style={{ width: state.tableApproxWidth }} id="scrollbarDiv1"></div></div>
						<div onScroll={(event) => scrollWrapper2(event)} id="scrollbarWrapper2">
							<div style={{ width: state.tableApproxWidth }} id="scrollbarDiv2">
								<div style={{ marginLeft: "20px", marginTop: "20px" }}></div>
								<Sheet>
								<Table style={{ whiteSpace: "nowrap" }} striped bordered hover id="recordsTable" className="">
									<thead className='sheetHeader'>
										<>{headersList()}</>
									</thead>
									<tbody className='sheetBody'>
										<>{renderTableData()}</>
									</tbody>
								</Table>
								</Sheet>
							</div>
						</div>
					</>
				</Row>
				
			</div>
			<ButtonToolbar className="modal__footer">
				<Button color={'success'} outline={true} onClick={onClose}>
					{cancel_btn}
				</Button>
			</ButtonToolbar>
		</Modal>
	);
}



const Sheet = styled.div`
	.table tbody td,th {
		border: 1px solid #dee2e6;
		text-align: center;
	}
`;
