import React, { PureComponent } from 'react';
import {
    Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane, Button, Row, Spinner, ButtonToolbar, Modal
} from 'reactstrap';
import classnames from 'classnames';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import MUIDataTable from "mui-datatables";
import * as actions from '../../../Store/actions/index';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Icon } from '@material-ui/core';
import moment from 'moment-timezone';
import Pdf from "react-to-pdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfDocument } from "./createInvoicePdf"
import ChangeAppLanguage from '../Layout/ChangeAppLanguage';
import * as ChangeAppLanguageFront from '../Layout/ChangeAppLanguageFront';

moment.tz.setDefault('Europe/Paris');

let tlang = ChangeAppLanguageFront.translateLanguage;
const sort_text = tlang('sort_text') || 'Sort';
const rows_per_page_text = tlang('rows_per_page_text') || 'Rows per page:';
const no_match_record_found = tlang('no_match_record_found') || 'Sorry, no matching records found';
const search_text = tlang('search_text') || 'Search';
const tooltip_view = tlang('tooltip_view') || 'View';
const send_invoice = tlang('send_invoice') || 'Send invoice';
const project_companytxt = tlang('project_companytxt') || 'Company';
const user_request_status = tlang('user_request_status') || 'Status';
const client_invoice_title = tlang('client_invoice_title') || 'Title';
const client_invoice_date = tlang('client_invoice_date') || 'Invoice Date';
const client_profile_action = tlang('client_profile_action') || 'Action';
const LICENSE_REQCMPNAME_KEY = tlang('licence_request_company_name') || 'Company Name';
const USERREQUEST_EMAIL_KEY = tlang('user_request_email') || 'Email';
const signup_wizard_item = tlang('signup_wizard_item') || 'Item';
const client_invoice_cost = tlang('client_invoice_cost') || 'Cost';
const client_invoice_txt = tlang('client_invoice_txt') || 'Invoice';
const client_invoice_cmp = tlang('client_invoice_cmp') || 'Company';
const signup_wizard_grand_total = tlang('signup_wizard_grand_total') || 'Grand Total';
const client_invoice_count = tlang('client_invoice_count') || 'count';
const client_amount = tlang('client_amount') || 'Amount';
const signup_wizard_download_invoice = tlang('signup_wizard_download_invoice') || 'Download Invoice';
const client_back_btn = tlang('client_back_btn') || 'Back';
const invoice_details = tlang('invoice_details') || 'Invoice Details';
const client_cmp_status_close = tlang('client_cmp_status_close') || 'Close';
const topbar_link_invoices = tlang('topbar_link_invoices') || 'Invoices';

//master data module component
class Invoices extends PureComponent {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    constructor() {
        super();
        this.state = {
            loading: false,
            requestList: [],
            companyWiseInvoices: [],
            company_id: "",
            viewInvoiceDetailsModal: false,
            invoiceDetail: {}
        };
    }

    componentDidMount() {
        let request = {
            status: "",
            confirm: true,
        }
        this.props.getUserRequest(this.props.apolloClient.client, request)
    }

    componentWillReceiveProps(props) {

        if (props.userReducer.requestList) {
            this.setState({
                requestList: props.userReducer.requestList
            })
        }

        if (props.invoiceReducer.companyWiseInvoices) {
            this.setState({
                companyWiseInvoices: props.invoiceReducer.companyWiseInvoices
            })
        }
        if (props.notification) {
            this.setState({
                loading: false
            })
            if (props.notification.success) {

            }
        }
    }

    componentWillUnmount() {
        this.setState({
            companyWiseInvoices: []
        })
        this.props.invoiceReducer.companyWiseInvoices = []
    }

    handleCompanyChange = () => event => {
        this.setState({
            company_id: event.target.value
        }, () => {
            let request = {
                company_id: event.target.value
            }
            this.props.invoiceReducer.companyWiseInvoices = []
            this.props.getCompanyWiseInvoices(this.props.apolloClient.client, request);
            this.setState({
                loading: true,
                companyWiseInvoices: []
            }, () => this.forceUpdate())
        })

    }

    renderUsersWithPlanList = () => {
        let data = [];
        if (this.state.companyWiseInvoices) {
            this.state.companyWiseInvoices.map(val => {

                const date = moment(new Date(val.created_at) / 1000).format("DD/MM/YYYY HH:mm");
                var invoice_date = moment(date, "DD/MM/YYYY HH:mm", true).isValid();

                data.push([
                    val.title,
                    invoice_date ? moment.unix(new Date(val.created_at) / 1000).format("DD/MM/YYYY HH:mm") : moment.unix(val.created_at / 1000).format("DD/MM/YYYY HH:mm"),
                    <>
                    {val.status == "success"  ? <span className={"statusOpen"}>{val.status}</span> : <span className={"statusPending"}>{val.status}</span>}
                    </>,
                    val.status,
                    val
                ])
            })
        }
        return data
    }

    toggleViewInvoice = (event, details) => {
        let invoiceDetail = {}
        if (details) {
            details.company_email = ""
            details.company_name = ""
            let allCompany = this.state.requestList
            let cDetails = allCompany.filter(c => c.company_id == this.state.company_id)
            if (cDetails.length != 0) {
                details.company_email = cDetails[0].email
                details.company_name = cDetails[0].display_name
            }
            invoiceDetail = details
        }
        this.setState({
            invoiceDetail: invoiceDetail,
            viewInvoiceDetailsModal: !this.state.viewInvoiceDetailsModal
        }, () => this.forceUpdate())
    }

    getTotalAmount = () => {
        let total = 0;
        let invoiceDetail = this.state.invoiceDetail ? this.state.invoiceDetail.invoice_detail : []
        invoiceDetail.map((row) => {
            if (this.state.invoiceDetail.category_name === "TOKEN") {
                total += row.price;
            } else if (this.state.invoiceDetail.category_name === "SIGNUP") {
                if (row.price) {
                    total += row.count ? row.count * row.price : row.price;
                }
                if (row.plan) {
                    total += row.plan.count ? row.plan.count * row.plan.price : row.plan.price;
                }
                if (row.licence) {
                    row.licence.filter((value, index1) => value.count != 0).map((val, index) => {
                        total += val.count ? val.count * val.price : val.price
                    })
                }
            } else if (this.state.invoiceDetail.category_name === "LICENCE") {
                total += row.count || row.count == 0 ? row.count * row.price : row.price;
            }
        })
        return parseInt(total);
    }

    sendInvoice = (event, details) => {

        let allCompany = this.state.requestList
        let cDetails = allCompany.filter(c => c.company_id == this.state.company_id)
        if (cDetails.length != 0) {
            details.company_email = cDetails[0].email
            details.company_name = cDetails[0].display_name
        }
        this.setState({
            invoiceDetail: details
        }, () => {
            let invoiceDetail = this.state.invoiceDetail.invoice_detail
            let category_name = this.state.invoiceDetail.category_name
            let pdfname = this.state.invoiceDetail.title


            var created_at_date = Number(this.state.invoiceDetail.created_at);
            const date = moment(new Date(created_at_date) / 1000).format("DD/MM/YYYY HH:mm");
            var invoice_date = moment(date, "DD/MM/YYYY HH:mm", true).isValid();

            let title = ""
            if (category_name === "TOKEN") {
                title = "Project Token"
            } else if (category_name === "LICENCE") {
                title = "Licence"
            } else if (category_name === "SIGNUP") {
                title = "Plan"
            }
            let dateDispaly = invoice_date ? moment.unix(new Date(created_at_date) / 1000).format("DD/MM/YYYY HH:mm") : moment.unix(created_at_date / 1000).format("DD/MM/YYYY HH:mm")
            let invoiceHtml = '<div style="padding:10px">'
            invoiceHtml += '<p style="margin-top:20px";>' + LICENSE_REQCMPNAME_KEY + ': ' + this.state.invoiceDetail.company_name + '</p>'
            invoiceHtml += '<p style="margin-top:5px";>' + USERREQUEST_EMAIL_KEY + ': ' + this.state.invoiceDetail.company_email + '</p>'
            invoiceHtml += '<p style="margin-top:5px";>' + client_invoice_date + ':' + dateDispaly + '</p>'
            invoiceHtml += '<table style="margin-top:20px";>'
            if (category_name === "SIGNUP") {
                invoiceHtml += '<tr> <td ><h4>' + pdfname + '</h4></td> </tr>'
                invoiceHtml += '<tr style="border-bottom:1px solid #e0e0e0;text-align:left"> <th>' + signup_wizard_item + '</th> <th ></th>'
                invoiceHtml += '<th colSpan="3" style="text-align:right">' + client_invoice_cost + '</th> </tr>'
                invoiceDetail.map((val, index) => {
                    if (val.plan_name || val.planname || val.type) {
                        invoiceHtml += '<tr style="border-bottom: 1px solid #e0e0e0">'
                        invoiceHtml += '<td style="text-align:left">'
                        if (val.plan_name) {
                            invoiceHtml += val.plan_name
                        }
                        if (val.planname) {
                            invoiceHtml += val.planname
                        }
                        if (val.type === "renew") {
                            invoiceHtml += "Plan Renew"
                        }
                        if (val.type === "extend") {
                            invoiceHtml += "Plan Extend"
                        }
                        invoiceHtml += '</td>'
                        invoiceHtml += '<td></td>'
                        invoiceHtml += '<td  style="text-align:right">$' + val.price + '</td>'
                        invoiceHtml += '</tr>'
                    }
                    if (val.plan) {
                        invoiceHtml += '<tr style="border-bottom: 1px solid #e0e0e0">'
                        invoiceHtml += '<td style="text-align:left">'
                        if (val.plan.plan_name) {
                            invoiceHtml += val.plan_name
                        }
                        if (val.plan.planname) {
                            invoiceHtml += val.planname
                        }
                        if (val.plan.type === "renew") {
                            invoiceHtml += "Plan Renew"
                        }
                        if (val.plan.type === "extend") {
                            invoiceHtml += "Plan Extend"
                        }
                        invoiceHtml += '</td>'
                        invoiceHtml += '<td></td>'
                        invoiceHtml += '<td  style="text-align: right">$' + val.plan.price + '</td>'
                        invoiceHtml += '</tr>'
                    }
                    if (val.licence) {
                        val.licence.filter((value, index1) => value.count != 0).map((val, index) => {
                            invoiceHtml += '<tr style="border-bottom: "1px solid #e0e0e0">'
                            invoiceHtml += '<td style="text-align:left">'
                            invoiceHtml += val.licencename
                            invoiceHtml += '</td>'
                            invoiceHtml += val.count ? '<td>' + val.count + '</td>' : '<td> 1 </td>'
                            invoiceHtml += '<td style="text-align:right">$' + (val.count ? val.count * val.price : val.price) + '</td>'
                            invoiceHtml += '</tr>'
                        })
                    }
                })
                invoiceHtml += '<tr>'
                invoiceHtml += '<td></td>'
                invoiceHtml += '<td></td>'
                invoiceHtml += '<td  style="text-align:right">'
                let total = this.getTotalAmount()
                invoiceHtml += signup_wizard_grand_total + ": $" + total
                invoiceHtml += '</td>'
                invoiceHtml += '</tr>'
            }
            if (category_name === "TOKEN") {
                invoiceHtml += '<tr>'
                invoiceHtml += '<td colSpan="4"><h4>' + pdfname + '</h4></td>'
                invoiceHtml += '</tr>'
                invoiceHtml += '<tr style="border-bottom: 1px solid #e0e0e0">'
                invoiceHtml += '<th style="text-align:left">' + signup_wizard_item + '</th>'
                invoiceHtml += '<th colSpan="3" style="text-align:left">' + client_invoice_count + '</th>'
                invoiceHtml += '<th colSpan="3" style="text-align:left">' + client_invoice_cost + '</th>'
                invoiceHtml += '<th colSpan="3" style="text-align:right">' + client_amount + '</th>'
                invoiceHtml += '</tr>'
                invoiceDetail.map((val, index) => {
                    invoiceHtml += ' <tr style="border-bottom:1px solid #e0e0e0">'
                    invoiceHtml += '<td style="text-align:left">Token</td>'
                    invoiceHtml += '<td colSpan="3" style="text-align:left">' + val.count + '</td>'
                    invoiceHtml += '<td colSpan="3" style="text-align:left">$ ' + (val.price / val.count) + '</td>'
                    invoiceHtml += '<td colSpan="3" style="text-align:right">$' + val.price + '</td>'
                    invoiceHtml += '</tr>'
                })
                invoiceHtml += '<tr>'
                invoiceHtml += '<td></td>'
                invoiceHtml += '<td colSpan="3"></td>'
                invoiceHtml += '<td colSpan="3"></td>'
                invoiceHtml += '<td colSpan="3" style="text-align:right">'
                let total = this.getTotalAmount()
                invoiceHtml += signup_wizard_grand_total+': $' + total
                invoiceHtml += '</td>'
                invoiceHtml += '</tr>'
            }
            if (category_name === "LICENCE") {
                invoiceHtml += '<tr><td colSpan="4"><h4>' + pdfname + '</h4></td></tr>'
                invoiceHtml += '<tr style="border-bottom: 1px solid #e0e0e0">'
                invoiceHtml += '<th style="text-align:left">' + signup_wizard_item + '</th>'
                invoiceHtml += '<th colSpan="3" style="text-align:left">' + client_invoice_count + '</th>'
                invoiceHtml += '<th colSpan="3" style="text-align:left">' + client_invoice_cost + '</th>'
                invoiceHtml += '<th colSpan="3" style="text-align:right">' + client_amount + '</th>'
                invoiceHtml += '</tr>'
                invoiceDetail.filter((value, index1) => value.count != 0).map((val, index) => {
                    invoiceHtml += '<tr style="border-bottom:1px solid #e0e0e0">'
                    invoiceHtml += '<td colSpan="3" style="text-align:left">'
                    invoiceHtml += val.licencename
                    invoiceHtml += '</td>'
                    invoiceHtml += val.count ? '<td style="text-align:left">' + val.count + '</td>' : '<td style="text-align:left"> 1 </td>'
                    invoiceHtml += '<td colSpan="3" style="text-align:left">$' + val.price + '</td>'
                    invoiceHtml += '<td colSpan="3" style="text-align:right">$' + (val.count ? val.count * val.price : val.price) + '</td>'
                    invoiceHtml += '</tr>'

                })
                invoiceHtml += '<tr>'
                invoiceHtml += '<td></td>'
                invoiceHtml += '<td colSpan="3"></td>'
                invoiceHtml += '<td colSpan="3"></td>'
                invoiceHtml += '<td colSpan="3" style="text-align:right">'
                let total = this.getTotalAmount()
                invoiceHtml +=signup_wizard_grand_total+ ': $' + total
                invoiceHtml += '</td>'
                invoiceHtml += '</tr>'
            }
            invoiceHtml += '</table>'
            invoiceHtml += '</div>'

            let request = {
                company_id: this.state.invoiceDetail.company_id,
                invoice_name: this.state.invoiceDetail.title,
                invoice_html: invoiceHtml
            }
            this.props.sendInvoices(this.props.apolloClient.client, request)
        })
    }

    invoiceDetails = () => {

        const date = moment(new Date(this.state.invoiceDetail.created_at) / 1000).format("DD/MM/YYYY HH:mm");
        var invoice_date = moment(date, "DD/MM/YYYY HH:mm", true).isValid();

        let displayDate = invoice_date ? moment.unix(new Date(this.state.invoiceDetail.created_at) / 1000).format("DD/MM/YYYY HH:mm") : moment.unix(this.state.invoiceDetail.created_at / 1000).format("DD/MM/YYYY HH:mm")
        let invoiceDetail = this.state.invoiceDetail.invoice_detail
        let category_name = this.state.invoiceDetail.category_name
        let pdfname = this.state.invoiceDetail.title
        let title = ""
        if (category_name === "TOKEN") {
            title = "Project Token"
        } else if (category_name === "LICENCE") {
            title = "Licence"
        } else if (category_name === "SIGNUP") {
            title = "Plan"
        }
        return (
            <>
                <div className="">
                    <div style={{ display: "flex", flexDirection: 'column', width: "21cm", maxHeight: "29cm" }} >
                        <img src={`${process.env.PUBLIC_URL}/img/aexdo_logo.png`} style={{ width: "120px" }}></img>
                        <h1 style={{ marginLeft: "30px", marginTop: "20px" }}>{client_invoice_txt}</h1>
                        <p style={{ width: "20cm", marginTop: "20px", marginLeft: "30px" }}>{client_invoice_cmp}: {this.state.invoiceDetail.company_name}</p>
                        <p style={{ width: "20cm", marginTop: "0px", marginLeft: "30px" }}>{USERREQUEST_EMAIL_KEY}: {this.state.invoiceDetail.company_email}</p>
                        <p style={{ width: "20cm", marginLeft: "30px" }}>{client_invoice_date}: {displayDate}</p>
                        <table style={{ width: "20cm", marginTop: "20px", marginLeft: "30px" }}>
                            {category_name === "SIGNUP" &&
                                <>
                                    <tr>
                                        <td ><h4>{pdfname}</h4></td>
                                    </tr>
                                    <tr style={{ borderBottom: "1px solid #e0e0e0", }}>
                                        <th>{signup_wizard_item}</th>
                                        <th ></th>
                                        <th colSpan="3" style={{ textAlign: "right" }}>{client_invoice_cost}</th>
                                    </tr>
                                    {invoiceDetail.map((val, index) => {
                                        return (
                                            <>
                                                {(val.plan_name || val.planname || val.type) &&
                                                    <tr style={{ borderBottom: "1px solid #e0e0e0", }}>
                                                        <td>
                                                            {val.plan_name && val.plan_name}
                                                            {val.planname && val.planname}
                                                            {val.type === "renew" && "Plan Renew"}
                                                            {val.type === "extend" && "Plan Extend"}
                                                        </td>
                                                        <td></td>
                                                        <td style={{ textAlign: "right" }}>${val.price}</td>
                                                    </tr>
                                                }
                                                {val.plan &&
                                                    <tr style={{ borderBottom: "1px solid #e0e0e0", }}>
                                                        <td>
                                                            {val.plan.plan_name && val.plan.plan_name}
                                                            {val.plan.planname && val.plan.planname}
                                                            {val.plan.type === "renew" && "Plan Renew"}
                                                            {val.plan.type === "extend" && "Plan Extend"}
                                                        </td>
                                                        <td></td>
                                                        <td style={{ textAlign: "right" }}>${val.plan.price}</td>
                                                    </tr>
                                                }
                                                {val.licence && val.licence.filter((value, index1) => value.count != 0).map((val, index) => {
                                                    return (
                                                        <tr style={{ borderBottom: "1px solid #e0e0e0", }}>
                                                            <td>
                                                                {val.licencename && val.licencename}
                                                            </td>
                                                            {val.count ?
                                                                <td>{val.count}</td>
                                                                :
                                                                <td>{"1"}</td>
                                                            }
                                                            <td style={{ textAlign: "right" }}>${val.count ? val.count * val.price : val.price}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </>
                                        )
                                    })}
                                    <tr>
                                        <td></td>
                                        <td ></td>
                                        <td style={{ textAlign: "right" }}>
                                            {signup_wizard_grand_total} : ${this.getTotalAmount()}
                                        </td>
                                    </tr>
                                </>
                            }
                            {category_name === "TOKEN" &&
                                <>
                                    <tr>
                                        <td colSpan="4"><h4>{pdfname}</h4></td>
                                    </tr>
                                    <tr style={{ borderBottom: "1px solid #e0e0e0", }}>
                                        <th>{signup_wizard_item}</th>
                                        <th colSpan="3" >{client_invoice_count}</th>
                                        <th colSpan="3" >{client_invoice_cost}</th>
                                        <th colSpan="3" style={{ textAlign: "right" }}>{client_amount}</th>
                                    </tr>
                                    {invoiceDetail.map((val, index) => {
                                        return (
                                            <tr style={{ borderBottom: "1px solid #e0e0e0", }}>
                                                <td> {'Token'}</td>
                                                <td colSpan="3">{val.count}</td>
                                                <td colSpan="3">${val.price / val.count}</td>
                                                <td colSpan="3" style={{ textAlign: "right" }}>${val.price}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td></td>
                                        <td colSpan="3"></td>
                                        <td colSpan="3"></td>
                                        <td colSpan="3" style={{ textAlign: "right" }}>
                                            {signup_wizard_grand_total} : ${this.getTotalAmount()}
                                        </td>
                                    </tr>
                                </>
                            }
                            {category_name === "LICENCE" &&
                                <>
                                    <tr>
                                        <td colSpan="4"><h4>{pdfname}</h4></td>
                                    </tr>
                                    <tr style={{ borderBottom: "1px solid #e0e0e0", }}>
                                        <th>{signup_wizard_item}</th>
                                        <th colSpan="3" >{client_invoice_count}</th>
                                        <th colSpan="3" >{client_invoice_cost}</th>
                                        <th colSpan="3" style={{ textAlign: "right" }}>{client_amount}</th>
                                    </tr>
                                    {invoiceDetail.filter((value, index1) => value.count != 0).map((val, index) => {
                                        return (
                                            <tr style={{ borderBottom: "1px solid #e0e0e0", }}>
                                                <td colSpan="3">
                                                    {val.licencename && val.licencename}
                                                </td>
                                                {val.count ?
                                                    <td>{val.count}</td>
                                                    :
                                                    <td>{"1"}</td>
                                                }
                                                <td colSpan="3">${val.price}</td>
                                                <td colSpan="3" style={{ textAlign: "right" }}>${val.count ? val.count * val.price : val.price}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td></td>
                                        <td colSpan="3"></td>
                                        <td colSpan="3"></td>
                                        <td colSpan="3" style={{ textAlign: "right" }}>
                                            {signup_wizard_grand_total} : ${this.getTotalAmount()}
                                        </td>
                                    </tr>
                                </>
                            }
                        </table>
                    </div>
                    <PDFDownloadLink
                        document={<PdfDocument data={this.state.invoiceDetail} />}
                        fileName={"invoice.pdf"}
                        style={{ float: "right" }}
                    >
                        <Button
                            title={"ASMDAKMDLASMD"}
                            className={"btn btn-success mt-3"}
                            outline={false}
                            color={"success"}
                            size="sm"
                        >
                            {"sfsdfsdfsdfsd"}
                        </Button>
                    </PDFDownloadLink>
                </div>
            </>
        )
    }

    render() {
        const { t } = this.props;



        const usersWithPlansColumns = [
            {
                name: client_invoice_title
            },
            {
                name: client_invoice_date
            },
            {
                name: user_request_status
            },
            {
                name: user_request_status, options: {
                    display: false                
                }
            },
            {
                name: client_profile_action,
                options: {
                    filter: false,
                    customBodyRender: (value) => {
                        if (value) {
                            return (<>
                                <Button title={tooltip_view} className={"system-btn clear-border"} outline={true} color={'success'} onClick={(event) => this.toggleViewInvoice(event, value)}> <Icon>remove_red_eye</Icon></Button>
                                <Button title={send_invoice} className={"system-btn clear-border"} outline={true} color={'success'} onClick={(event) => this.sendInvoice(event, value)}> <Icon>mail_outline</Icon></Button>
                            </>
                            );
                        }
                        return '-';
                    }
                }
            },
        ];

        const usersWithPlansOptions = {
            filterType: 'checkbox',
            selectableRows: "none",
            print: false,
            download: false,
            viewColumns: false,
            filter: false,
            responsive: "stacked",
            textLabels: {
                body: {
                    noMatch: no_match_record_found,
                    toolTip: sort_text
                },
                pagination: {
                    rowsPerPage: rows_per_page_text,
                },
                toolbar: {
                    search: search_text
                },
            },
            customToolbar: () => {
                return (
                    <React.Fragment>
                        <TextField
                            select
                            id="standard-select-currency"
                            SelectProps={{
                                MenuProps: {
                                    className: "select-menu-width",
                                },
                            }}
                            label={project_companytxt}
                            className="material-form__field width__150"
                            value={this.state.company_id}
                            onChange={this.handleCompanyChange('company')}
                        >
                            {this.state.requestList.map(option => (
                                <MenuItem key={option.company_id} value={option.company_id}>
                                    {option.display_name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </React.Fragment>
                );
            }
        };
        
        return (
            <Col xs={12} md={12} lg={12} xl={12}>
                <h3 className="page-title" style={{ marginBottom: "20px" }}>
                    {topbar_link_invoices}
                </h3>
                <Card>
                    <CardBody>
                        <div className="div-spinner">{this.state.loading && <Spinner size="500" className="success" />}</div>
                        <MUIDataTable
                            data={this.renderUsersWithPlanList()}
                            columns={usersWithPlansColumns}
                            options={usersWithPlansOptions}
                        />
                        <Modal
                            isOpen={this.state.viewInvoiceDetailsModal}
                            toggle={(event) => this.toggleViewInvoice(event)}
                            className={`modal-dialog--success modal-dialog--header width__70`}

                        >
                            <div className="modal__header">
                                <button className="lnr lnr-cross modal__close-btn" type="button" onClick={(event) => this.toggleViewInvoice(event)} />
                                <h4 className="bold-text  modal__title">{invoice_details}</h4>
                            </div>
                            <div className="modal__body">
                                <Row>
                                    {this.invoiceDetails()}
                                </Row>
                            </div>
                            <ButtonToolbar className="modal__footer">
                                <Button outline={true} color={'success'} onClick={(event) => this.toggleViewInvoice(event)}>{client_cmp_status_close}</Button>
                            </ButtonToolbar>
                        </Modal >
                    </CardBody>
                </Card>
            </Col>
        );
    }
}


Invoices.propTypes = {
    t: PropTypes.func.isRequired,
};

//load reducers
const mapStateToProps = state => {
    return {
        authReducer: state.authReducer,
        apolloClient: state.apolloClient,
        masterReducer: state.masterReducer,
        notification: state.notification,
        userReducer: state.userReducer,
        invoiceReducer: state.invoiceReducer
    }
}

//Load actions
const mapDispatchToProps = dispatch => {
    return {
        getUserRequest: (client, request) => { dispatch(actions.getUserRequest(client, request)) },
        getCompanyWiseInvoices: (client, request) => { dispatch(actions.getCompanyWiseInvoices(client, request)) },
        sendInvoices: (client, request) => { dispatch(actions.sendInvoices(client, request)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Invoices)); //export component
