import { Icon } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Col, Input, Label } from 'reactstrap';
import styled from 'styled-components';
import * as actions from '../../../../Store/actions/index';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import gql from 'graphql-tag';
import { Encrypt } from '../../../../Store/shared/encrpt';
import checkEmailFormat from '../../../lib/checkEmailFormat';
import { getAllTranslatedRoles } from '../../Common/commonFunctions';

const tlang = ChangeAppLanguageFront.translateLanguage;
const client_project_invite_users =
	tlang('client_project_invite_users') || 'Invite Users to Project';
const client_project_invite_team_members =
	tlang('client_project_invite_team_members') || 'Invite Team Members';
const tooltip_delete = tlang('tooltip_delete') || 'Delete';
const tooltip_add = tlang('tooltip_add') || 'Add';
// const Lang_Loadingtxt = tlang('Lang_Loadingtxt') || 'Loading...';
const client_signup_wizard_users = tlang('client_signup_wizard_users') || 'Users';
const client_project_pls_enter_user = tlang('client_project_pls_enter_user') || 'Please Add User';
const client_project_invite_new_member =
	tlang('client_project_invite_new_member') || 'Outside Members';
const client_project_email = tlang('client_project_email') || 'E-mail';
const client_signup_wizard_role = tlang('client_signup_wizard_role') || 'Role';
const create_update_staff_modal_pls_enter_email =
	tlang('create_update_staff_modal_pls_enter_email') || '* Please Enter Valid Email';
const client_project_pls_enter_role = tlang('client_project_pls_enter_role') || 'Please Add Role';
const user_already_invited = tlang('user_already_invited') || 'User is already invited';

export default function InviteUsers({
	tokenType,
	memberIds = [],
	errors = {},
	onChange,
	title = client_project_invite_users,
}) {
	const dispatch = useDispatch();
	const apolloClient = useSelector(state => state.apolloClient);
	const authReducer = useSelector(state => state.authReducer);
	const masterReducer = useSelector(state => state.masterReducer);
	const memberReducer = useSelector(state => state.memberReducer);
	const [invitedUsers, setInvitedUsers] = useState([]);
	const [invitedExt, setInvitedExt] = useState([]);
	const [roleList, setRoleList] = useState([]);
	const [memberList, setMemberList] = useState([]);

	useEffect(() => {
		dispatch(actions.getRoleList(apolloClient.client, []));
		dispatch(actions.getMemberList(apolloClient.client, []));
	}, []);

	useEffect(() => {
		if (!masterReducer.roleList?.length) return;
		const roleListArr = getAllTranslatedRoles(masterReducer.roleList);
		const rl = roleListArr
			.filter(r => r.roleid !== 'AXD_RL_02')
			.map(r => ({
				value: r.roleid,
				label: r.rolename,
			}));
		setRoleList(rl);
	}, [masterReducer.roleList, masterReducer.roleList?.length]);

	useEffect(() => {
		if (!memberReducer.memberList?.length) return;
		const ml = memberReducer.memberList.map(m => ({
			value: m.userid,
			label: `${m.name} (${m.email})`,
			role: m.role_id,
			email: m.email,
		}));
		setMemberList(ml);
	}, [memberReducer.memberList, memberReducer.memberList?.length]);

	useEffect(() => {
		onChange(invitedUsers, invitedExt);
	}, [invitedUsers, invitedExt]);

	function addUser() {
		setInvitedUsers(prev => prev.concat({ id: '', role: '' }));
	}
	function removeUser(idxToRemove) {
		setInvitedUsers(prev => prev.filter((u, idx) => idx !== idxToRemove));
	}
	function addExt() {
		setInvitedExt(prev => prev.concat({ email: '', role: '' }));
	}
	function removeExt(idxToRemove) {
		setInvitedExt(prev => prev.filter((u, idx) => idx !== idxToRemove));
	}

	function handleUserName(value, idx) {
		const newIU = [...invitedUsers];
		newIU[idx].id = value.value;
		newIU[idx].role = value.role;
		setInvitedUsers(newIU);
	}

	function handleRole(value, idx, ext) {
		const newU = ext ? [...invitedExt] : [...invitedUsers];
		newU[idx].role = value.value;
		if (ext) setInvitedExt(newU);
		else setInvitedUsers(newU);
	}

	function handleEmail(value, idx) {
		const newEU = [...invitedExt];
		newEU[idx].email = value;
		setInvitedExt(newEU);
		delete errors[`email-${idx}`];
		if (!checkEmailFormat(value)) errors[`email-${idx}`] = true;
	}

	async function validateEmail(idx) {
		const client = apolloClient.project;
		const email = invitedExt[idx].email;
		if (checkEmailFormat(email)) {
			const mutation = gql`
			mutation check_email_available($email: String!) {
				check_email_available(email: $email) {
					message
					error
					success
					company_name
					display_name
					company_id
				}
			}
			`;
			const response = await client.mutate({
				mutation: mutation,
				variables: {
					email: Encrypt(email),
				},
			});
			if (response.data.check_email_available.company_name) {
				invitedExt[idx].company_name = response.data.check_email_available.company_name;
				invitedExt[idx].display_name = response.data.check_email_available.display_name;
				invitedExt[idx].company_id = response.data.check_email_available.company_id;
				setInvitedExt([...invitedExt]);
			}
		}
	}

	return (
		<>
			{tokenType && tokenType !== 'independent' && (
				<Col xs={12}>
					<h4 style={{ fontWeight: '600', color: 'var(--clr-aexdo)' }}>{title}</h4>
					<SubtitleContainer>
						<Label>{client_project_invite_team_members}</Label>
						<Button
							title={tooltip_add}
							color="success"
							onClick={() => addUser()}
							className={'system-btn clear-border'}
							outline={true}
						>
							<Icon>add</Icon>
						</Button>
					</SubtitleContainer>
					{invitedUsers
						.filter(u => !u.delete)
						.map((user, idx) => (
							<InvitedContainer key={`INVITEDEUSER-${idx}`}>
								<div className="field">
									<div className="data">
										<span className="label">{client_signup_wizard_users}</span>
										<Select
											placeholder={client_signup_wizard_users}
											value={memberList.find(m => m.value === user.id)}
											onChange={value => handleUserName(value, idx)}
											options={memberList.filter(
												m => (m.value !== authReducer.userData.userid) && (m.email !== authReducer.userData.owner) && !memberIds.concat(invitedUsers.map(iu => iu.id)).includes(m.value)
											)}
											className="react-select"
										/>
									</div>
									{errors[`user-${idx}`] && (
										<span className="error-message">{client_project_pls_enter_user}</span>
									)}
								</div>

								<div className="field">
									<div className="data">
										<span className="label">{client_signup_wizard_role}</span>
										<Select
											placeholder={client_signup_wizard_role}
											value={roleList.find(m => m.value === user.role)}
											onChange={value => handleRole(value, idx)}
											options={roleList}
											className="react-select"
											isDisabled={true}
										/>
									</div>
									{errors[`role-user-${idx}`] && (
										<span className="error-message">{client_project_pls_enter_role}</span>
									)}
								</div>
								<Button
									title={tooltip_delete}
									color="success"
									onClick={() => removeUser(idx)}
									className={'system-btn clear-border'}
									outline={true}
								>
									<Icon>delete</Icon>
								</Button>
							</InvitedContainer>
						))}
				</Col>
			)}
			{tokenType === 'open' && (
				<Col xs={12}>
					<SubtitleContainer>
						<Label>{client_project_invite_new_member}</Label>
						<Button
							title={tooltip_add}
							color="success"
							onClick={() => addExt()}
							className={'system-btn clear-border'}
							outline={true}
						>
							<Icon>add</Icon>
						</Button>
					</SubtitleContainer>
					{invitedExt
						.filter(u => !u.delete)
						.map((user, idx) => (
							<InvitedContainer key={`EXTUSER-${idx}`}>
								<div className="field">
									<div className="data">
										<span className="label">{client_project_email}</span>
										<Input
											onChange={e => handleEmail(e.target.value, idx)}
											onBlur={() => validateEmail(idx)}
											type="text"
											value={user.email}
										/>
										{user.email && (
											<Icon
												style={{
													color: errors[`email-${idx}`]
														? 'red'
														: user.company_id
															? 'green'
															: 'orange',
												}}
											>
												{errors[`email-${idx}`] ? 'close' : 'check'}
											</Icon>
										)}
									</div>
									{errors[`email-exist-${idx}`] && (
										<span className="error-message">{user_already_invited}</span>
									)}
									{errors[`email-${idx}`] && (
										<span className="error-message">
											{create_update_staff_modal_pls_enter_email}
										</span>
									)}
								</div>
								<div className="field">
									<div className="data">
										<span className="label">{client_signup_wizard_role}</span>
										<Select
											placeholder={client_signup_wizard_role}
											value={roleList.find(m => m.value === user.role)}
											onChange={value => handleRole(value, idx, true)}
											options={roleList}
											className="react-select"
										/>
									</div>
									{errors[`role-ext-${idx}`] && (
										<span className="error-message">{client_project_pls_enter_role}</span>
									)}
								</div>
								<Button
									title={tooltip_add}
									color="success"
									onClick={() => removeExt(idx)}
									className={'system-btn clear-border'}
									outline={true}
								>
									<Icon>delete</Icon>
								</Button>
							</InvitedContainer>
						))}
				</Col>
			)}
		</>
	);
}

const SubtitleContainer = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	margin-block: 0.5rem;
	label {
		line-height: 1;
		margin: 0;
	}
`;

const InvitedContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 10px;
	align-items: center;
	.field {
		display: flex;
		flex-direction: column;
		gap: 3px;
		align-items: flex-start;
		.data {
			display: flex;
			gap: 1rem;
			align-items: center;
			width: 100%;
		}
	}
	.label {
		color: var(--clr-aexdo);
		font-weight: 700;
		flex-shrink: 0;
		&::after {
			content: ' :';
		}
	}
	.value {
		font-size: 1.1em;
	}
`;
