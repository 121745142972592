import React, { PureComponent } from 'react';
import Plot from 'react-plotly.js';
import { Card, CardBody, Button, Row, Col, Input, Label  } from 'reactstrap';
import * as actions from "../../../Store/actions/index";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import '../../assets/mapbox/css/mapbox-gl-draw.css';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { Icon } from '@material-ui/core';
import * as ChangeAppLanguageFront from '../Layout/ChangeAppLanguageFront';
import TextField from '@material-ui/core/TextField';
import isNumber from 'lodash/isNumber';
import * as localeDictionary from 'plotly.js/lib/locales/fr.js'


let tlang  = ChangeAppLanguageFront.translateLanguage;
const client_pollutant_analysis = tlang('client_pollutant_analysis') || 'Pollutant Analysis';
const client_pollutant = tlang('client_pollutant') || 'POLLUTANTS';
const client_thresholds_by_sector = tlang('client_thresholds_by_sector') || 'THRESHOLDS BY SECTOR';
const client_gray_dots_were_not_analysed = tlang('client_gray_dots_were_not_analysed') || "Gray dots weren't analysed";
const client_search_pollutants = tlang('client_search_pollutants') || "Search Pollutants";
const MIDDLE_DEPTH = tlang('middle_depth') || "Middel Depth";
const client_sample_number = tlang('client_sample_number') || 'Sample Number';
const client_sample_value = tlang('client_sample_value') || 'Sample Value';
const client_sample_name = tlang('client_sample_name') || 'Sample Name';

class PollutantAnalysis extends PureComponent { 
    constructor(props) {
        super(props);
        this.state = {
            selectedPollutantsName : "",
            selected_test_parent_id : "",
            selected_test_child_id : "",
            refresh: false,
            desposalChildList : [],
            pollutantData : [],
            selectedThreshold : "",
            selectedThresholdValue : "",
            testData : [],
            manualEntry : "",
            searchText : "",
            testNameList : [],
            showGreyDotMsg : false,
            resetChart : true,
            tempChart : [],
            refreshOne : false,
            allProbingSamples: [],
            excelDataList: [],
            setDesposalListData : false,
            exceptionConst: [],
        }
    }

    componentDidMount() {
        this.props.masterReducer.desposalList = [];
        this.resetAllValue();
        this.setState({
            showGreyDotMsg : true , 
            refreshOne : true
        });

        let reqParam = {
            company_name: this.props.authReducer.userData.company_name,
        }
        this.props.getDesposal(this.props.apolloClient.masters, reqParam)

         /* get probing list */
         let requestParams = {
            campaignid: "",
            projectid: this.props.authReducer.userData.projectid
        };
        this.props.probingReducer.getProbingList = []; //Make blank before api call to solve crash issue
        this.props.probingListForProbingAnalysis(this.props.apolloClient.probing, requestParams);


        //Get all Exceptions list
        let requestData = {
            company_id: this.props.authReducer.userData.company_id
        };
        this.props.getExceptionSetting(this.props.apolloClient.masters, requestData);
    }

    resetAllValue = () => {
        this.setState({
            selectedPollutantsName : "",
            selected_test_parent_id : "",
            selected_test_child_id : "",
            // refresh: false,
            desposalChildList : [],
            pollutantData : [],
            selectedThreshold : "",
            selectedThresholdValue : "",
            testData : [],
            manualEntry : "",
            searchText : "",
            testNameList : [],
            showGreyDotMsg : false,
            resetChart : true,
            tempChart : [],
            secondLoading : false,
            alldesposalRuleList: [],
            projectWiseAllDisposal: [],
        },()=>this.forceUpdate())
    }

    componentWillReceiveProps(props) {
       
                   
        if(!this.state.setDesposalListData){

            //My code to remove other project disposal and keep selected project disposal
            let projectId = this.props.authReducer.userData.projectid;
            let projectWiseAllDisposal = props.masterReducer.desposalList.filter((l) => {
                if(l.type === 'project' && l.projectId !== projectId){
                    return false;
                }
                return true;
            })


            /* received desposal list */
        //    if (props.masterReducer.desposalList.length > 0) {
              if (projectWiseAllDisposal.length > 0) {
                let onlyDesposalId = []
             // props.masterReducer.desposalList.map((val,index)=>{
                projectWiseAllDisposal.map((val,index)=>{
                    onlyDesposalId.push(val._id);
                })

                //Get Test Rules of All Disposal Id
                let requestParams = {
                    desposal_ids: JSON.stringify(onlyDesposalId)
                };
                this.props.getRuleListOfAllDisposal(this.props.apolloClient.masters, requestParams)

               this.setState({
                   setDesposalListData : true,
                   projectWiseAllDisposal: projectWiseAllDisposal,
               },()=>this.forceUpdate())
            }else{
                setTimeout(function () {
                    this.setState({ refreshOne: false });
                }.bind(this), 2000)
           }
        }

        /* get All disposal rule list */
        if (props.masterReducer.alldesposalRuleList) {
            if (props.masterReducer.alldesposalRuleList.length > 0) {
                this.setState({
                    alldesposalRuleList: props.masterReducer.alldesposalRuleList
                },()=>this.forceUpdate())
            }
        }

         /* received get test type list data */

         if(props.analysisReducer.testWiseRuleList.length > 0){
            let desposalList = props.analysisReducer.testWiseRuleList

            //My code to remove other project disposal and keep selected project disposal
            let projectWiseAllDisposalIds = this.state.projectWiseAllDisposal.map(value => value._id);
            desposalList = desposalList.filter((l) => projectWiseAllDisposalIds.includes(l.desposal_id));
            
            this.setState({
                desposalChildList : desposalList,
                refresh : false,
                secondLoading : false,
            },()=>this.forceUpdate(),
            props.analysisReducer.testWiseRuleList = []
            )
         }else{
            this.setState({
                secondLoading : false,
            },()=>this.forceUpdate())
         }

         /* probing list */
         let getProbingList = props.probingReducer.getProbingList;
         if (getProbingList && this.state.allProbingSamples.length <= 0) {
            getProbingList = getProbingList.filter(val => val.status !== "Delete");
            let smpl_coll = [];
            for (let fil_prob_data of getProbingList) {
                for (let fil_layer of fil_prob_data.layer) {
                    for (let fil_smpl of fil_layer.sample) {
                        Object.assign(fil_smpl, {
                        probingcode: fil_prob_data.probingname || fil_prob_data.probingcode,
                        altitude: fil_prob_data.altitude,
                        longitude: fil_prob_data.longitude,
                        latitude: fil_prob_data.latitude,
                        });
                        smpl_coll.push(fil_smpl);
                    }
                }
            }

            this.setState({
                allProbingSamples:smpl_coll,
            },function(){
                if(smpl_coll.length <= 0) {
                    this.setState({refresh: false, refreshOne: false});
                    return;
                }
                /* fire get Excel Data List API after getting the result of all probing list samples */
                if (this.props.authReducer.userData.projectid) {
                    let isadmin = this.props.authReducer.userData.email == this.props.authReducer.userData.owner ? true : false;
                    let request = {
                      companyname: this.props.authReducer.userData.company_name,
                      projectid: this.props.authReducer.userData.projectid,
                      other_company:this.props.authReducer.userData.other_company,
                      isadmin: isadmin,
                    };
                    this.props.getExcelDataListForProbingAnalysis(this.props.apolloClient.probing, request);
                } 
            });

         }


         if (props.excelUploadReducer) {
            
            /* received excel Data List */
            if (props.excelUploadReducer.excelDataList) {
                if(props.excelUploadReducer.excelDataList.length > 0){
                    this.setState({
                        excelDataList: props.excelUploadReducer.excelDataList
                    },function(){
                        let excelDataList = props.excelUploadReducer.excelDataList;
                        this.calculateRules(excelDataList,true)
                        props.excelUploadReducer.excelDataList = undefined
                    });
                    this.setState({refresh: false});
                }else{
                    //Hide the loader if no excelsheet uploaded
                    this.setState({refresh: false});
                }  
            }

            //received company exception list
            if (props.excelUploadReducer.exceptionSettingsList && props.excelUploadReducer.exceptionSettingsList != null) {
                    
                if(props.excelUploadReducer.exceptionSettingsList.exception_strings.length > 0){
                let allexceptionlist = props.excelUploadReducer.exceptionSettingsList.exception_strings;

                this.setState({
                    exceptionConst: allexceptionlist
                });
                }
            }

        }
    }

    
    checkInnerRule2 = (rules,sample_val,sheet_data,fsampl,desposal_list,auto_true_arr,tst_child_id) => {


        let flag = false;
        let condition = rules.formula.condition;

        let my_rules_val = rules.value;
        if (typeof sample_val == "number") {
            if (!my_rules_val) {
                flag = false;
            } else if (condition == "less") {
                if (sample_val <= my_rules_val) {
                    flag = true;
                }
            } else if (condition == "greter") {
                if (sample_val >= my_rules_val) {
                    flag = true;
                }
            }
        }

        if(flag == false){
            let all_test_truefalse_arr = [];
            
            let checkpollutantbeingcheckedexist = rules.formula.test.filter((l) => l.id == tst_child_id).length;
            if(checkpollutantbeingcheckedexist > 0){
                flag = false;
            }
            else{
                if (typeof sample_val == "number") {
                    for (let inr_tst of rules.formula.test) {
                        let inr_flag = false;
                        let find_fil = sheet_data.filter(t=>t.Test_Child_Id == inr_tst.id && t.Test_Parent_Id == inr_tst.Test_Parent_Id);
                        if (find_fil[0] && find_fil[0][fsampl]) {
                            
                            let inr_val = find_fil[0][fsampl];
                            let inr_Test_Parent_Id = find_fil[0].Test_Parent_Id;
                            let inr_tst_child_id = find_fil[0].Test_Child_Id;
                           
                            if ((typeof inr_val == "number" || typeof inr_val == "string") && inr_val != "-") {
    
                                for (let fil_desp1 of desposal_list) {
                                    let rules1 = this.state.alldesposalRuleList.filter(t=>t.desposal_id == fil_desp1.desposal_id && t.test_id == inr_Test_Parent_Id && t.child_id == inr_tst_child_id)[0];
                                    
                                                if (typeof inr_val == "string" && inr_val != "-" && rules1) {
                                                    for (let fil_text of auto_true_arr) {
                                                        if (inr_val.includes(fil_text) && inr_flag == false) {
                                                        inr_flag = true;
                                                        all_test_truefalse_arr.push(inr_flag);
                                                        }
                                                    }
                                                }
    
                                                if (!inr_flag && rules1 && rules1.option_id == "1") {
                                                    let condition = rules1.formula.condition;
                                                    //rules_val = rules1.value;
                                                    let my_rules_val = rules1.value;
                                                    if (typeof inr_val == "number") {
                                                        if (!my_rules_val) {
                                                        inr_flag = false;
                                                        all_test_truefalse_arr.push(inr_flag);
                                                        } else if (condition == "less") {
                                                            if (inr_val <= my_rules_val) {
                                                                inr_flag = true;
                                                                all_test_truefalse_arr.push(inr_flag);
                                                            }
                                                        } else if (condition == "greter") {
                                                            if (inr_val >= my_rules_val) {
                                                                inr_flag = true;
                                                                all_test_truefalse_arr.push(inr_flag);
                                                            }
                                                        }
                                                    }
                                                }
    
                                                else if (!inr_flag && rules1 && rules1.option_id == "2") {
    
                                                    inr_flag = this.checkInnerRule2(rules1,inr_val,sheet_data,fsampl,desposal_list,auto_true_arr,tst_child_id);
                                                    all_test_truefalse_arr.push(inr_flag);
    
                                                }
    
    
                                                else if (!inr_flag && rules1 && rules1.option_id == "3") {
                                                    if (typeof inr_val == "number") {
                                                        //rules_val = rules.value;
                                                        //rules_val = "No Limit";
                                                        inr_flag = true;
                                                        all_test_truefalse_arr.push(inr_flag);
                                                    }
                                                }
    
                                                else if (!inr_flag && rules1 && rules1.option_id == "4") {
                                                    let condition = rules1.formula.condition;
                                                    //rules_val = rules.value;
                                                    //rules_val = (condition == "greter" ? ">" : "<") + "" + rules1.formula.percentage + "% of " + rules.formula.test[0].name;
                                                    if (typeof inr_val == "number") {
                                                        for (let inr_tst2 of rules1.formula.test) {
                                                            let find_fil2 = sheet_data.filter(t=>t.Test_Child_Id == inr_tst2.id && t.Test_Parent_Id == inr_tst2.Test_Parent_Id);
                                                            if (find_fil2[0] && find_fil2[0][fsampl]) {
                                                                let inr_val2 = find_fil2[0][fsampl];
                                                                if (typeof inr_val2 == "number") {//New added line condition
                                                                    let calc_val = inr_val2 * rules1.formula.percentage / 100;
                                                                    if (condition == "less" && inr_val < calc_val) {
                                                                        inr_flag = true;
                                                                        all_test_truefalse_arr.push(inr_flag);
                                                                    }
                                                                    else if (condition == "greter" && inr_val > calc_val) {
                                                                        inr_flag = true;
                                                                        all_test_truefalse_arr.push(inr_flag);
                                                                    }
                                                                }
                    
                                                            }
                                                        }
                                                    }
                                                }
                                }
    
                            }
                        }
                    }
                }
            }
           
            let trueCount = all_test_truefalse_arr.filter(Boolean).length;
            let totalAddedTest = rules.formula.test.length;
            //All added test pollutats must be satisfied to approve this current pollutant
            if(totalAddedTest == trueCount){
                flag = true;
            }
            
        }

        return flag;
    }

     /* For Calculate pollutants and threshold - display the color dots */
     calculateRules = (excelDataList,resetValue,activedesposalList) => {
        //Check if excelsheet is uploaded or not
        let allProbingSamples = this.state.allProbingSamples
        if(excelDataList.length > 0){

            let temp_tst_arr = []; 
            let test_name_list = []; 
            let req_test_name = this.state.selectedPollutantsName ? this.state.selectedPollutantsName : "";
            let req_test_id = this.state.selected_test_parent_id ? this.state.selected_test_parent_id : "";
            let tst_child_id = this.state.selected_test_child_id ? this.state.selected_test_child_id : "";
            for (let sht_tst_data_1 of excelDataList) {
                //access selected campaign uploaded sheet
                let sheet_data = sht_tst_data_1.sheet_resp_data;
                for (let sht_tst_data of sheet_data) {
                    
                    let tst_name = sht_tst_data.Testfamily; //Fixed Column Array
                    let test_family_name = sht_tst_data; //Fixed Column Array
                    let Test_Parent_Id = sht_tst_data.Test_Parent_Id;
                    let Test_Child_Id = sht_tst_data.Test_Child_Id;
                    let sampl_finl = [];
                    if (Test_Parent_Id && Test_Parent_Id == req_test_id && Test_Child_Id == tst_child_id) {
                        
                        for (let probSampl of allProbingSamples) {


                            //Sample Name calculation
                            let samplenamerange = "";
                            if(typeof probSampl.sampleNameIsManual != "undefined"){
                                if(probSampl.sampleNameIsManual == false && typeof probSampl.sampleNameTop != "undefined" && typeof probSampl.sampleNameBottom != "undefined"){
                                    
                                    let sampleNameTop = probSampl.sampleNameTop;
                                    let sampleNameBottom = probSampl.sampleNameBottom;

                                    if(this.props.authReducer.userData.default_unit != "CM" ){
                                        //sampleNameTop = sampleNameTop?(Number(sampleNameTop)/100).toFixed(2):"0"; 
                                        //sampleNameBottom = sampleNameBottom?(Number(sampleNameBottom)/100).toFixed(2):"";

                                        sampleNameTop = sampleNameTop?(Number(sampleNameTop)/100):0; 
                                        sampleNameBottom = sampleNameBottom?(Number(sampleNameBottom)/100):"";

                                        sampleNameTop = (sampleNameTop===parseInt(sampleNameTop))? sampleNameTop : parseFloat(sampleNameTop).toFixed(2);
                                        sampleNameBottom = (sampleNameBottom===parseInt(sampleNameBottom))?sampleNameBottom: parseFloat(sampleNameBottom).toFixed(2);

                                    }
                                    samplenamerange =  probSampl.probingcode + " (" + sampleNameTop + " - " + sampleNameBottom + ")";
                                }else if(probSampl.sampleNameIsManual == true){
                                    samplenamerange =  probSampl.sampleNameManual;
                                }else{
                                    samplenamerange =  probSampl.probingcode
                                }                            
                            }



                            let smpl_val = sht_tst_data[probSampl.sampleNo]; //replace fsampl.sampleNo
                           
                            let fsampl = probSampl.sampleNo;
                            
                            //If we have sample no, its value and parent id then
                            if (fsampl && (smpl_val || smpl_val == "") && Test_Parent_Id) {
                                
                                let test_done = false;
                                let temp_desp_res = {};
                                //let all_desp_data = [];
                                let desposal_list = [activedesposalList];
                                //let auto_true_arr = ["<LQ", "<", "-", "n.d.", "nd"];
                                let auto_true_arr = this.state.exceptionConst;
                                let flag = false;
                                for (let fil_desp of desposal_list) {
                                    
                                    //Fetch the rule of testnames
                                    let rules = this.state.alldesposalRuleList.filter(t=>t.desposal_id == fil_desp.desposal_id && t.test_id == Test_Parent_Id && t.child_id == tst_child_id)[0];
                                    let rules_val = 0;
                                    let sample_val = smpl_val;
                                    //if (typeof sample_val == "string" && sample_val != "-") {
                                    if (typeof sample_val == "string" && sample_val != "-" && rules) {
                                        for (let fil_text of auto_true_arr) {
                                            if (sample_val.includes(fil_text)) {
                                            flag = true;
                                            }
                                        }
                                    }


                                    if (!flag && rules && rules.option_id == "1") {
                                    // compare option 1
                                    let condition = rules.formula.condition;
                                    rules_val = rules.value;
                                        if (typeof sample_val == "number") {
                                            if (!rules_val) {
                                            flag = false;
                                            } else if (condition == "less") {
                                            if (sample_val <= rules_val) {
                                                flag = true;
                                            }
                                            } else if (condition == "greter") {
                                            if (sample_val >= rules_val) {
                                                flag = true;
                                            }
                                            }
                                        }
                                    }


                                    else if(!flag && rules && rules.option_id == "2") {
                                        let condition = rules.formula.condition;

                                        // let allnames = [];
                                        // rules.formula.test.map((t,p) => {
                                        //     allnames.push(t.name);
                                        // });
                                        
                                        // let article = " is valid )";
                                        // if(allnames.length > 1){ article = " are valid )"; }

                                        // rules_val = rules.value + " ( can still be valid if " + allnames.join() + "" + article;

                                        
                                        //rules_val = rules.value;

                                        let my_rules_val = rules.value;
                                        if (typeof sample_val == "number") {
                                            if (!my_rules_val) {
                                                flag = false;
                                            } else if (condition == "less") {
                                                if (sample_val <= my_rules_val) {
                                                    flag = true;
                                                }
                                            } else if (condition == "greter") {
                                                if (sample_val >= my_rules_val) {
                                                    flag = true;
                                                }
                                            }
                                        }

                                        

                                        if(flag == false){
                                            let all_test_truefalse_arr = [];
                                            if (typeof sample_val == "number") {
                                                for (let inr_tst of rules.formula.test) {
                                                    let inr_flag = false;
                                                    let find_fil = sheet_data.filter(t=>t.Test_Child_Id == inr_tst.id && t.Test_Parent_Id == inr_tst.Test_Parent_Id);

                                                    if (find_fil[0] && find_fil[0][fsampl]) {
                                                        let inr_val = find_fil[0][fsampl];

                                                        let inr_Test_Parent_Id = find_fil[0].Test_Parent_Id;
                                                        let inr_tst_child_id = find_fil[0].Test_Child_Id;
                                                    
                                                        if ((typeof inr_val == "number" || typeof inr_val == "string") && inr_val != "-") {

                                                            for (let fil_desp1 of desposal_list) {
                                                                let rules1 = this.state.alldesposalRuleList.filter(t=>t.desposal_id == fil_desp1.desposal_id && t.test_id == inr_Test_Parent_Id && t.child_id == inr_tst_child_id)[0];
                                                                
                                                                            if (typeof inr_val == "string" && inr_val != "-" && rules1) {
                                                                                for (let fil_text of auto_true_arr) {
                                                                                    if (inr_val.includes(fil_text) && inr_flag == false) {
                                                                                    inr_flag = true;
                                                                                    all_test_truefalse_arr.push(inr_flag);
                                                                                    }
                                                                                }
                                                                            }

                                                                            if (!inr_flag && rules1 && rules1.option_id == "1") {
                                                                                // compare option 1
                                                                                let condition = rules1.formula.condition;
                                                                                //rules_val = rules1.value;
                                                                                let my_rules_val = rules1.value;
                                                                                if (typeof inr_val == "number") {
                                                                                    if (!my_rules_val) {
                                                                                    inr_flag = false;
                                                                                    all_test_truefalse_arr.push(inr_flag);
                                                                                    } else if (condition == "less") {
                                                                                        if (inr_val <= my_rules_val) {
                                                                                            inr_flag = true;
                                                                                            all_test_truefalse_arr.push(inr_flag);
                                                                                        }
                                                                                    } else if (condition == "greter") {
                                                                                        if (inr_val >= my_rules_val) {
                                                                                            inr_flag = true;
                                                                                            all_test_truefalse_arr.push(inr_flag);
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }

                                                                            else if (!inr_flag && rules1 && rules1.option_id == "2") {
                                                                                
                                                                                inr_flag = this.checkInnerRule2(rules1,inr_val,sheet_data,fsampl,desposal_list,auto_true_arr,tst_child_id);
                                                                                all_test_truefalse_arr.push(inr_flag);

                                                                            }


                                                                            else if (!inr_flag && rules1 && rules1.option_id == "3") {
                                                                                if (typeof inr_val == "number") {
                                                                                    //rules_val = rules.value;
                                                                                    //rules_val = "No Limit";
                                                                                    inr_flag = true;
                                                                                    all_test_truefalse_arr.push(inr_flag);
                                                                                }
                                                                            }

                                                                            else if (!inr_flag && rules1 && rules1.option_id == "4") {
                                                                                let condition = rules1.formula.condition;
                                                                                //rules_val = rules.value;
                                                                                //rules_val = (condition == "greter" ? ">" : "<") + "" + rules1.formula.percentage + "% of " + rules.formula.test[0].name;
                                                                                if (typeof inr_val == "number") {
                                                                                    for (let inr_tst2 of rules1.formula.test) {
                                                                                        let find_fil2 = sheet_data.filter(t=>t.Test_Child_Id == inr_tst2.id && t.Test_Parent_Id == inr_tst2.Test_Parent_Id);
                                                                                        if (find_fil2[0] && find_fil2[0][fsampl]) {
                                                                                            let inr_val2 = find_fil2[0][fsampl];
                                                                                            if (typeof inr_val2 == "number") {//New added line condition
                                                                                                let calc_val = inr_val2 * rules1.formula.percentage / 100;
                                                                                                if (condition == "less" && inr_val < calc_val) {
                                                                                                    inr_flag = true;
                                                                                                    all_test_truefalse_arr.push(inr_flag);
                                                                                                }
                                                                                                else if (condition == "greter" && inr_val > calc_val) {
                                                                                                    inr_flag = true;
                                                                                                    all_test_truefalse_arr.push(inr_flag);
                                                                                                }
                                                                                            }
                                                
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }

                                                            }

                                                        }
                                                    }
                                                }
                                            }

                                            
                                            let trueCount = all_test_truefalse_arr.filter(Boolean).length;
                                            let totalAddedTest = rules.formula.test.length;
                                            //All added test pollutats must be satisfied to approve this current pollutant
                                            if(totalAddedTest == trueCount){
                                                flag = true;
                                            }
                                            
                                        }

                                    }


                                    else if (!flag && rules && rules.option_id == "3") {
                                        if (typeof sample_val == "number") {
                                            //rules_val = rules.value;
                                            rules_val = "No Limit";
                                            flag = true;
                                        }
                                    }

                                    else if (!flag && rules && rules.option_id == "4") {
                                        let condition = rules.formula.condition;
                                        //rules_val = rules.value;
                                        rules_val = (condition == "greter" ? ">" : "<") + "" + rules.formula.percentage + "% of " + rules.formula.test[0].name;
                                        if (typeof sample_val == "number") {
                                            for (let inr_tst of rules.formula.test) {
                                                let find_fil = sheet_data.filter(t=>t.Test_Child_Id == inr_tst.id && t.Test_Parent_Id == inr_tst.Test_Parent_Id);
                                                if (find_fil[0] && find_fil[0][fsampl]) {
                                                    let inr_val = find_fil[0][fsampl];
                                                    if (typeof inr_val == "number") {//New added line condition
                                                        let calc_val = inr_val * rules.formula.percentage / 100;
                                                        if (condition == "less" && sample_val < calc_val) {
                                                            flag = true;
                                                        }
                                                        else if (condition == "greter" && sample_val > calc_val) {
                                                            flag = true;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                

                                    //Match First Disposal initially for "<LQ", "<", "-"
                                    /*if (typeof sample_val == "string" && flag && !test_done) {//push true desposal into array
                                        if (desposal_list[0].name == fil_desp.name) {
                                            Object.assign(temp_desp_res, {
                                            desposal_id: desposal_list[0].desposal_id,
                                            name: desposal_list[0].name,
                                            color: desposal_list[0].color,
                                            rules_val: (rules) ? rules.value : '',
                                            });
                                        }
                                    }
                                    else */ if (flag && !test_done) {//push true desposal into array
                                        Object.assign(temp_desp_res, {
                                            desposal_id: fil_desp.desposal_id,
                                            name: fil_desp.name,
                                            color: fil_desp.color,
                                            rules_val: rules_val,
                                        });
                                    }

                                    if (flag) {
                                        test_done = true;
                                    }
                                }


                                if (probSampl && smpl_val) {
                                    temp_tst_arr.push({
                                        tst_name: tst_name,
                                        sample_name: probSampl.sampleNo,
                                        sample_name_range: samplenamerange,
                                        value: smpl_val,
                                        is_matched: flag
                                    });
                                }
                            }
                     }
                    }
                    else if (!req_test_name && Test_Parent_Id) {                        
                        for (let fsampl of allProbingSamples) {

                            //Sample Name calculation
                            let samplenamerange = "";
                            if(typeof fsampl.sampleNameIsManual != "undefined"){
                                if(fsampl.sampleNameIsManual == false && typeof fsampl.sampleNameTop != "undefined" && typeof fsampl.sampleNameBottom != "undefined"){
                                    
                                    let sampleNameTop = fsampl.sampleNameTop;
                                    let sampleNameBottom = fsampl.sampleNameBottom;

                                    if(this.props.authReducer.userData.default_unit != "CM" ){
                                        //sampleNameTop = sampleNameTop?(Number(sampleNameTop)/100).toFixed(2):"0"; 
                                        //sampleNameBottom = sampleNameBottom?(Number(sampleNameBottom)/100).toFixed(2):"";

                                        sampleNameTop = sampleNameTop?(Number(sampleNameTop)/100):0; 
                                        sampleNameBottom = sampleNameBottom?(Number(sampleNameBottom)/100):"";

                                        sampleNameTop = (sampleNameTop===parseInt(sampleNameTop))? sampleNameTop : parseFloat(sampleNameTop).toFixed(2);
                                        sampleNameBottom = (sampleNameBottom===parseInt(sampleNameBottom))?sampleNameBottom: parseFloat(sampleNameBottom).toFixed(2);

                                    }
                                    samplenamerange =  fsampl.probingcode + " (" + sampleNameTop + " - " + sampleNameBottom + ")";
                                }else if(fsampl.sampleNameIsManual == true){
                                    samplenamerange =  fsampl.sampleNameManual;
                                }else{
                                    samplenamerange =  fsampl.probingcode
                                }                            
                            }


                            //get filtered sample names
                            let smpl_val = sht_tst_data[fsampl.sampleNo]; //get dynamic colummn
                            if (fsampl.sampleNo && smpl_val) {
                                temp_tst_arr.push({
                                    tst_name: tst_name,
                                    sample_name: fsampl.sampleNo,
                                    sample_name_range: samplenamerange,
                                    value: smpl_val
                                });
                                test_name_list.push(test_family_name);
                            }
                        }
                    }
                }
            }
//             let final_test_name_list = [... new Set(test_name_list)];
            
            let final_test_name_list = [];
            test_name_list.map((val1,index1)=>{
                let Testfamily = val1.Testfamily
                let Test_Parent_Id = val1.Test_Parent_Id
                let Test_Child_Id = val1.Test_Child_Id
                    //let checkTest = final_test_name_list.filter((val,inx) => val.Testfamily == Testfamily && val.Test_Parent_Id == Test_Parent_Id);
                    let checkTest = final_test_name_list.filter((val,inx) => val.Test_Child_Id == Test_Child_Id && val.Test_Parent_Id == Test_Parent_Id);
                    if(checkTest.length == 0){
                        val1['create_pollutant_name'] = val1.Testfamily;
                        val1['isMultiple'] = false
                        final_test_name_list.push(val1)
                    }
            })

            final_test_name_list.map((val1,index1)=>{
                //let alreadyChild = final_test_name_list.filter((val2,index2) => val1.Testfamily == val2.Testfamily);
                let alreadyChild = final_test_name_list.filter((val2,index2) => val1.Test_Child_Id == val2.Test_Child_Id);
                if(alreadyChild.length > 1){
                    final_test_name_list[index1]['create_pollutant_name'] = val1.Testfamily +'( '+ val1.Test_Parent_Familyname +' )';
                    final_test_name_list[index1]['isMultiple'] = true;
                }
            })


            if(resetValue){
                let chartArray = this.resetChartData();
                this.setState({
                    // pollutantData : pollutantData,
                    chart : chartArray,
                    tempChart : chartArray,
                    testData : temp_tst_arr,
                    refresh : false,
                    refreshOne : false,
                    testNameList : final_test_name_list
                },()=>this.forceUpdate())
            }else{
                let chartArray = this.thresholdsValueChart(temp_tst_arr);
                this.setState({
                    chart : chartArray,
                },()=>this.forceUpdate())
            }
        }else{
            this.setState({refresh: false});
        }
    
    }


    // reser chart data - click to test family
    resetChartData = () => {

        let allProbingSamples = this.state.allProbingSamples
        let measure_unit = this.props.authReducer.userData.default_unit ? this.props.authReducer.userData.default_unit : "M"
        let chart = []
        allProbingSamples.map((val2,index2)=>{

            //Sample Name calculation
            let samplenamerange = "";
            if(typeof val2.sampleNameIsManual != "undefined"){
                if(val2.sampleNameIsManual == false && typeof val2.sampleNameTop != "undefined" && typeof val2.sampleNameBottom != "undefined"){
                    
                    let sampleNameTop = val2.sampleNameTop;
                    let sampleNameBottom = val2.sampleNameBottom;

                    if(measure_unit != "CM" ){
                        //sampleNameTop = sampleNameTop?(Number(sampleNameTop)/100).toFixed(2):"0"; 
                        //sampleNameBottom = sampleNameBottom?(Number(sampleNameBottom)/100).toFixed(2):"";

                        sampleNameTop = sampleNameTop?(Number(sampleNameTop)/100):0; 
                        sampleNameBottom = sampleNameBottom?(Number(sampleNameBottom)/100):"";

                        sampleNameTop = (sampleNameTop===parseInt(sampleNameTop))? sampleNameTop : parseFloat(sampleNameTop).toFixed(2);
                        sampleNameBottom = (sampleNameBottom===parseInt(sampleNameBottom))?sampleNameBottom: parseFloat(sampleNameBottom).toFixed(2);

                    }
                    samplenamerange =  val2?.sampleName || (val2.probingcode + " (" + sampleNameTop + " - " + sampleNameBottom + ")");
                }else if(val2.sampleNameIsManual == true){
                    samplenamerange =  val2.sampleNameManual;
                }else{
                    samplenamerange =  val2.probingcode
                }                            
            }


            let middel_depth = measure_unit == "M" ? Number(val2.middel_depth)/100 : val2.middel_depth;
            
            //let hoverHtmlTemplate = client_sample_number+": "+ val2.sampleNo +"<br>" +MIDDLE_DEPTH+": "+ middel_depth +"<br>" +"<extra></extra>";
            let hoverHtmlTemplate = client_sample_name+": "+ samplenamerange +"<br>" +MIDDLE_DEPTH+": "+ middel_depth +"<br>" +"<extra></extra>";
            
            let data = {
                name : val2.sampleNo,
                type: 'scatter3d',
                mode: 'markers',
                x : [val2.latitude],
                y : [val2.longitude],
                z : [middel_depth],
                showlegend: false,
                hovertemplate: hoverHtmlTemplate,
                marker: {
                    color: 'grey',
                    size: 5
                },
                hoverlabel : {
                    font : {
                        color : 'white'
                    }
                }
            }
            chart.push(data)
        })
        return chart;
    }

    // get test rule list data 
    visiblePollutant = (index,pollutantData) => {

        if(this.state.desposalChildList.length > 0){
          
            this.setState({
                selectedThreshold : "",
                selectedThresholdValue : "",
                manualEntry : "",
                showGreyDotMsg: true
            },()=>this.forceUpdate())
            
        }

        // select pollutnat then first clear all chart data and all threshold button  
        let chartArray = this.resetChartData();

        this.setState({
            selectedPollutantsName : pollutantData.Testfamily,
            selected_test_parent_id : pollutantData.Test_Parent_Id,
            selected_test_child_id : pollutantData.Test_Child_Id,
            secondLoading : true,
            resetChart : true,
            desposalChildList : [],
            chart : chartArray,
        })
        this.resetChartData()
        let params = {
            child_id : pollutantData.Test_Child_Id,
            parent_id : pollutantData.Test_Parent_Id
        }
        
        this.props.getTestwiseRuleList(this.props.apolloClient.masters, params)
    }

    // Thresholds button click
    visibleThresholds = (index,thresholds) => {
        let name = thresholds.desposal_name
        let value = thresholds.value

        if(name == this.state.selectedThreshold && value == this.state.selectedThresholdValue){

            // threshold button click again then clear all chart data   

            let chartArray = this.resetChartData();
            this.setState({
                showGreyDotMsg: true,
                selectedThreshold  : "",
                selectedThresholdValue  : "",
                chart : chartArray,
                manualEntry : ""
            },()=>this.forceUpdate())

        }else{

            this.setState({
                showGreyDotMsg: true,
                selectedThreshold  : name,
                selectedThresholdValue  : value,
                manualEntry : "",
            },
            this.calculateRules(this.state.excelDataList,false,thresholds),
            )
        }
    }

    // thresholds new chart 
    thresholdsValueChart = (sampleList) => {
        let chart = this.state.chart
        let showGreyDotMsg = true

        chart.map((val1,index1)=>{

            let sampleData = sampleList.filter((val, index) => val.sample_name == val1.name)
            val1.marker.color = 'grey';
            if (sampleData.length > 0) {
            
                //val1.hovertemplate = client_sample_number+ ": " + val1.name + "<br>" +MIDDLE_DEPTH +": " + val1.z[0] + "<br>" +client_sample_value+ ": " + sampleData[0]['value'] + "<br>" +"<extra></extra>";
                val1.hovertemplate = client_sample_name+ ": " + sampleData[0].sample_name_range + "<br>" +MIDDLE_DEPTH +": " + val1.z[0] + "<br>" +client_sample_value+ ": " + sampleData[0]['value'] + "<br>" +"<extra></extra>";
            
                let value = sampleData[0].value;//replace your data here
                let display_grey_dot = true;
                //let auto_true_arr = ["<LQ", "<", "-", "n.d.", "nd"];
                let auto_true_arr = this.state.exceptionConst;
                if (typeof value == "number") {
                    display_grey_dot = false;
                }
                else if (typeof value == "string" && value != "-") {
                    for (let fil_text of auto_true_arr) {
                        if (value.includes(fil_text)) {
                            // for example <LQ , <
                            display_grey_dot = false;
                        }
                    }
                }
                
                if (display_grey_dot) {
                    val1.marker.color = 'grey';
                }
                else if (sampleData[0].is_matched) {
                    val1.marker.color = 'green';
                } else {
                    val1.marker.color = 'red';
                }
            }

        })
        
        this.setState({
            showGreyDotMsg : showGreyDotMsg
        },()=>this.forceUpdate())
        return chart;
    }

    commonThresholdsChart = (thresholdvalue) => {
        let selectedPollutantsName = this.state.selectedPollutantsName
        let testData = this.state.testData
        let filterArray = testData.filter(val => val.tst_name == selectedPollutantsName);
        let chart = this.state.tempChart
        let showGreyDotMsg = false
        chart.map((val1,index1)=>{

            let sampleData = filterArray.filter((val,index) => val.sample_name == val1.name)
            val1.marker.color = 'grey';

            if (sampleData.length > 0) {

                //val1.hovertemplate = client_sample_number+": " + val1.name + "<br>" +MIDDLE_DEPTH+ ": " + val1.z[0] + "<br>" +client_sample_value+    ": " + sampleData[0]['value'] + "<br>" +"<extra></extra>";
                val1.hovertemplate = client_sample_name+ ": " + sampleData[0].sample_name_range + "<br>" +MIDDLE_DEPTH +": " + val1.z[0] + "<br>" +client_sample_value+ ": " + sampleData[0]['value'] + "<br>" +"<extra></extra>";

                let value = sampleData[0].value;//replace your data here
                let display_grey_dot = true;
                //let auto_true_arr = ["<LQ", "<", "-", "n.d.", "nd"];
                let auto_true_arr = this.state.exceptionConst;
                if (typeof value == "number") {
                    display_grey_dot = false;
                }
                else if (typeof value == "string" && value != "-") {
                    for (let fil_text of auto_true_arr) {
                        if (value.includes(fil_text)) {
                            // for example <LQ , <
                            display_grey_dot = false;
                        }
                    }
                }
                
                if (display_grey_dot) {
                    val1.marker.color = 'grey';
                }
                else if (isNumber(value)) {
                    // only number then check 
                    if(value > thresholdvalue){
                        val1.marker.color = 'red'
                    }else if(value < thresholdvalue){
                        val1.marker.color = 'green'
                    }
                }else{
                    val1.marker.color = 'green';
                }
            }
        })
        
        this.setState({
            showGreyDotMsg : showGreyDotMsg
        },()=>this.forceUpdate())
        return chart;
    }

    changeManualThreholds = (value,event) => {
        event.persist()
        let newvalue = event.target.value

        this.setState({
            manualEntry : newvalue,
            selectedThreshold : "",
            selectedThresholdValue : ""
        },()=>this.forceUpdate())
    }   

    saveManualThreholds = () => {
        let desposalChildList = this.state.desposalChildList
        let manualEntry = this.state.manualEntry
        if(manualEntry){
            let chartData = this.commonThresholdsChart(manualEntry)
            this.setState({
                showGreyDotMsg : true,
                chart : chartData,
                selectedThreshold  : "",
                selectedThresholdValue  : ""
            },()=>this.forceUpdate())
        }
    }

    handleChangeSearchPollutants = () => event => {

        let value = event.target.value 
        this.setState({
            searchText : value
        },()=>this.forceUpdate())

    }
    
    render() {
        
        let threeDConfig = localStorage.getItem('language') == 'fr' ?
        {
            displaylogo: false,
            locales: { 'fr': localeDictionary },
            locale: 'fr'
        } :
        {
            displaylogo: false,
        };

        let testNameList = this.state.testNameList.filter(val => (val.create_pollutant_name.toLowerCase().includes(this.state.searchText.toLowerCase()) || !this.state.searchText.toLowerCase()))

        let isDisabled = this.props.authReducer.userData.plan.planid == "AXD_PL_01" ? true : false;

        return (
            <>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">{client_pollutant_analysis}</h3>
                </Col>
            </Row>
            <Card>
                <CardBody style={{padding:"22px 15px"}}>
                    <Row>
                        <Col md={12}>
                            <TextField
                                // error={}
                                fullWidth
                                required
                                className="material-form__field"
                                placeholder={client_search_pollutants}
                                // label={client_project_title}
                                value={this.state.searchText}
                                onChange={this.handleChangeSearchPollutants()}
                                // error={this.state.error.includes('title')}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            
            {this.state.refresh ? <div className="panel__refresh"><LoadingIcon /></div> : ''}
            {this.state.refreshOne ? <div className="panel__refresh"><LoadingIcon /></div> : ''}
            {this.state.secondLoading ? <div className="panel__refresh"><LoadingIcon /></div> : ''}
            
            <Row style={{margin: "0px"}}>
                <Card style={{width:"20%"}}>
                    <CardBody style={{padding:"22px 10px", height:"510px", overflowY:"auto",overflowX: "hidden"}}>
                        <Col className="pollutantslist" xs="2">
                        <Button className="btnRemoveContent" id={"btn_100000"}
                            color="success" size="sm" style={{ width:"180px", borderColor: "#E64823", backgroundColor:"#333C44", marginLeft : "-8px" }}  >{client_pollutant}</Button>{' '}
                            {testNameList.map((val,index)=>{
                                    let selectedPollutantsName = this.state.selectedPollutantsName
                                    let selected_test_parent_id = this.state.selected_test_parent_id
                                    let selected_test_child_id = this.state.selected_test_child_id
                                    let setButtonColor =  val.Testfamily == selectedPollutantsName && val.Test_Parent_Id == selected_test_parent_id && val.Test_Child_Id == selected_test_child_id ? 'danger' : '';

                                    //let btnName = val.isMultiple ? val.Test_Parent_Familyname : "";
                                    let btnName = val.Test_Parent_Familyname
                                    return(
                                        <>
                                        <Row  id={val.id}>
                                            <Col style={{paddingLeft : "7px"}}>
                                                <Button 
                                                // className="btnRemoveContent" id={"btn_"+index}
                                                onClick={(e) => this.visiblePollutant(index,val)} 
                                                // color="" 
                                                size="sm" 
                                                color={setButtonColor}
                                                style={{ width:"180px", borderColor: "#E64823" }}  
                                                >{(val.Testfamily) }<br/><small><b>{btnName}</b></small>
                                                </Button>{' '}
                                            </Col>
                                        </Row>
                                        </>
                                    )
                            })}
                        </Col>
                    </CardBody>
                </Card>
                <Card style={{width:"20%"}}>
                    <CardBody className="disposallist" style={{padding:"22px 10px", height:"510px", overflowY:"auto",overflowX: "hidden"}}>
                        {/* <Col xs="2"> */}
                        <Button className="btnRemoveContent" id={"btn_100000"}
                            color="success" size="sm" style={{ width:"180px", borderColor: "#E64823", backgroundColor:"#333C44" }}  >{client_thresholds_by_sector}</Button>{' '}
                            {this.state.desposalChildList.map((val,index)=>{

                                let rules_val = 0
                                /*New added code start */
                                if (val.option_id == "1") {
                                    rules_val = val.value;
                                }
                                else if (val.option_id == "2") {
                                    let allnames = [];
                                    val.formula.test.map((t,p) => {
                                        allnames.push(t.name);
                                    });

                                    let article = " is valid )";
                                    if(allnames.length > 1){ article = " are valid )"; }

                                    rules_val = val.value + "*";
                                }
                                else if (val.option_id == "3") {
                                    rules_val = "No Limit";
                                }
                                else if (val.option_id == "4") {
                                    rules_val = (val.formula.condition == "greter" ? ">" : "<") + "" + val.formula.percentage + "% of " + val.formula.test[0].name;
                                }

                                let selectedThreshold = this.state.selectedThreshold
                                let selectedThresholdValue = this.state.selectedThresholdValue
                                let setButtonColor = selectedThreshold == val.desposal_name && selectedThresholdValue == val.value ? 'danger' : '';
                                return(
                                    <Row id={val._id}>
                                        <Col>
                                            <Button 
                                            // className="btnRemoveContent" id={"threshold_btn_"+index}
                                            onClick={(e) => this.visibleThresholds(index,val)} 
                                            // color="" 
                                            color={setButtonColor} 
                                            size="sm" 
                                            style={{ width:"180px", borderColor: "#E64823" }}  
                                            >{(val.desposal_name + ' : ')}<b>{rules_val}</b></Button>{' '}
                                        </Col>
                                    </Row>
                                )
                            })}
                            {/* {this.state.desposalChildList.length > 0 &&  */}
                            {this.state.selectedPollutantsName != "" && 
                                <Label>
                                        <Input type="text" name="manualEntry" value={this.state.manualEntry}  onChange={(e)=>this.changeManualThreholds('manualEntry',e)} disabled={isDisabled} />{' '}
                                        <Icon style={{cursor: "pointer", fontSize: "37px", color: "var(--clr-aexdo)"}} onClick={(e)=>this.saveManualThreholds()} >send</Icon>
                                </Label>
                            }
                        {/* </Col> */}
                    </CardBody>
                </Card>
                <Card style={{width:"60%"}}>
                    <CardBody>
                        {this.state.showGreyDotMsg && 
                            <Label style={{fontSize: "15px", fontWeight: "400"}}>{client_gray_dots_were_not_analysed}</Label>
                        }
                        <Plot
                            data={this.state.chart}
                            ref={plotlyRef => {
                                this.plotlyRef = plotlyRef;
                                }}
                            style={{ width: '100%', height: '100%' }}
                            layout={{
                                title : "",
                                autosize: true,
                                // width: 600, 
                                // height: 450,
                                showlegend: false,
                                margin: {
                                    l: 0,
                                    r: 0,
                                    b: 0,
                                    t: 30,
                                    pad: 0
                                  },
                            }}
                            config = {threeDConfig}
                        />
                        
                    </CardBody>
                </Card>
            </Row>
            </>
        );
    }
    
}

const mapStateToProps = (state) => {
    return {
        authReducer: state.authReducer,
        apolloClient: state.apolloClient,
        notification: state.notification,
        masterReducer: state.masterReducer,
        projectReducer: state.projectReducer,
        dataVisualizaionReducer: state.dataVisualizaionReducer,
        analysisReducer: state.analysisReducer,
        probingReducer: state.probingReducer,
        excelUploadReducer: state.excelUploadReducer
    };
};

const mapDispatchToProps = (dispatch) => {
return {
    getDesposal: (client, request) => {
        dispatch(actions.getDesposal(client, request));
    },
    getRuleListOfAllDisposal: (client, request) => {
        dispatch(actions.getRuleListOfAllDisposal(client, request));
    },
    getTestType: (client, request) => {
        dispatch(actions.getTestType(client, request));
    },
    getTestwiseRuleList: (client, request) => {
        dispatch(actions.getTestwiseRuleList(client, request));
    },
    getPollutantAnalysisGraph: (client, request) => {
        dispatch(actions.getPollutantAnalysisGraph(client, request));
    },
    probingListForProbingAnalysis: (probing, request) => {
        dispatch(actions.probingListForProbingAnalysis(probing, request));
    },
    getExcelDataListForProbingAnalysis: (client, request) => {
        dispatch(actions.getExcelDataListForProbingAnalysis(client, request))
    },
    getExceptionSetting: (masters, request) => { 
        dispatch(actions.getExceptionSetting(masters, request)) 
    },
};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("common")(PollutantAnalysis)); // export component