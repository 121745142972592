import React from 'react';
import * as actionTypes from "../actionTypes";
import { gql } from "apollo-boost";
import { Encrypt } from "../../shared/encrpt";
import * as ChangeAppLanguageFront from "../../../App/view/Layout/ChangeAppLanguageFront";
import notificationMsg  from '../../../App/view/Layout/NotificationMessage';
let tlang = ChangeAppLanguageFront.translateLanguage;

const error_something_wrong = tlang('error_something_wrong') || 'Something Went Wrong!';

// notification Fail
const notificationFail = err => {
  let msg = error_something_wrong;
  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    // msg = err.graphQLErrors[0].message;
    let translateMsg =notificationMsg(err.graphQLErrors[0].statusCode)
    msg = translateMsg;
  } else if (err.message) {
    msg = err.message;
  }
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_FAIL,
    state: msg
  };
};

// get Campaign For Map Success
const getProbing3dAnalysisGraphSuccess = data => {
  return {
    type: actionTypes.GET_3D_PROBING_ANALYSIS_GRAPH,
    state: data
  };
};

// get probing 3d analysis graph
export const getProbing3dAnalysisGraph = (campaign, request) => {
  return dispatch => {
    const query = gql`
      query generate_3d_analysis_graph($projectid: String!, $desposal_list: String!) {
        generate_3d_analysis_graph(projectid: $projectid, desposal_list: $desposal_list) { 
          status
          error
          resp_data
        }
      }
    `;
    campaign
      .query({
        query: query,
        variables: {
          projectid: Encrypt(request.projectid),
          desposal_list: Encrypt(request.desposal_list)
        }
      })
      .then(response => {
        let res=response.data.generate_3d_analysis_graph
        let data={
          status:res.status,
          graphData:res.resp_data.result_data
        }
        dispatch(getProbing3dAnalysisGraphSuccess(data));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

// get terrain analysis graph success
const getTerrainAnalysisGraphSuccess = data => {
  return {
    type: actionTypes.GET_3D_TERRAIN_ANALYSIS_GRAPH,
    state: data
  };
};

// get terrain analysis graph
export const getTerrainAnalysisGraph = (campaign, request) => {
  return dispatch => {
    const query = gql`
      query generate_terrain_graph($projectid: String!) {
        generate_terrain_graph(projectid: $projectid) { 
          success
          error
          resp_data
        }
      }
    `;
    campaign
      .query({
        query: query,
        variables: {
          projectid: Encrypt(request.projectid)
        }
      })
      .then(response => {
        dispatch(getTerrainAnalysisGraphSuccess(response.data.generate_terrain_graph.resp_data));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

// get terrain analysis graph success
const getPollutantAnalysisGraphSuccess = data => {
  return {
    type: actionTypes.GET_3D_POLLUTANT_ANALYSIS_GRAPH,
    state: data
  };
};

// get terrain analysis graph
export const getPollutantAnalysisGraph = (campaign, request) => {
  return dispatch => {
    const query = gql`
      query generate_pollutant_famliy_graph($projectid: String!, $test_name: String, $desposal_list: String!) {
        generate_pollutant_famliy_graph(projectid: $projectid, test_name: $test_name, desposal_list: $desposal_list) { 
          status
          error
          resp_data
        }
      }
    `;
    campaign
      .query({
        query: query,
        variables: {
          projectid: Encrypt(request.projectid),
          test_name: Encrypt(request.test_name),
          desposal_list: Encrypt(request.desposal_list)
        }
      })
      .then(response => {
        dispatch(getPollutantAnalysisGraphSuccess(response.data.generate_pollutant_famliy_graph.resp_data.result_data));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

// get test wise rule list for graph Success
const getTestwiseRuleListSuccess = data => {
  return {
    type: actionTypes.GET_TEST_WISE_RULE_LIST,
    state: data
  };
};
// get pollution list Success
const getPollutionSuccess = data => {
  return {
    type: actionTypes.GET_POLLUTION_LIST,
    state: data
  };
};
// get rules list Success
const getRulesSuccess = data => {
  return {
    type: actionTypes.GET_STANDARD_DESPOSAL_RULE_LIST,
    state: data
  };
};
// get test wise rule list for graph
export const getTestwiseRuleList = (campaign, request) => {
  return dispatch => {
    const query = gql`
      query get_testwise_rule_list($child_id: String!,$parent_id: String!) {
        get_testwise_rule_list(child_id: $child_id,parent_id: $parent_id) { 
          _id
          desposal_id
          desposal_name
          test_id
          test_name
          child_id
          child_name
          children
          value
          option_id
          formula
          created_at
          updated_at
        }
      }
    `;
    campaign
      .query({
        query: query,
        variables: {
          child_id: Encrypt(request.child_id),
          parent_id: Encrypt(request.parent_id)
        }
      })
      .then(response => {
        dispatch(getTestwiseRuleListSuccess(response.data.get_testwise_rule_list));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};
// get Pollution list
export const getPollutionlist = (campaign, request) => {
  return dispatch => {
    const query = gql`
      query get_mapvisualize_data_list($companyname: String! , $projectid: String!) {
        get_mapvisualize_data_list(companyname: $companyname, projectid: $projectid) { 
          _id
          lines_polygons 
          origins
          destinations        
        }
      }
    `;
    campaign
      .query({
        query: query,
        variables: {
          companyname: Encrypt(request.companyname),
          projectid: Encrypt(request.projectid)
        }
      })
      .then(response => {
        dispatch(getPollutionSuccess(response.data.get_mapvisualize_data_list));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};


// get Standard list
export const getStandardlist = (masters, request) => {
  
  return dispatch => {
    const query = gql`
      query get_standard_desposal_rule_list{
        get_standard_desposal_rule_list  { 
        _id
        name
        start
        end
        percentage
        index_no
        standard      
        color
        isused
        company_name
        type
        userId
        projectId
        test_rule_list{
            desposal_id
            desposal_name
            test_id
            test_name
            child_id
            child_name
            option_id
            value
            formula
            }
        created_at
        updated_at
        }
    }
    `;
    masters.query({query: query,  fetchPolicy: 'no-cache',     
      }).then(response => { 
        dispatch(getRulesSuccess(response.data.get_standard_desposal_rule_list));              
      }).catch(error => {
        dispatch(notificationFail(error));
      });
  };
};