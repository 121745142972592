import React, { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from "reactstrap";
import * as actions from "../../../Store/actions/index";
// import { Link } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import moment from 'moment-timezone';
import { Icon, Checkbox, Tooltip } from "@material-ui/core";
import '../../assets/scss/component/excelUpload.scss';
import {supremeRole} from '../Common/commonFunctions';
import * as ChangeAppLanguageFront from '../Layout/ChangeAppLanguageFront';
import ModalDeleteConfirmation from './components/dataVisualization/modals/deleteConfirmation';
import ModalProbingImportSheet from './Probing/modalProbingImportSheet';

moment.tz.setDefault('Europe/Paris');

let tlang = ChangeAppLanguageFront.translateLanguage;
const analysis_view_details = tlang('analysis_view_details') || 'View details';
const material_list_sr_no = tlang('material_list_sr_no') || 'Sr.No';
const analysis_file_name = tlang('analysis_file_name') || 'File name';
const analysis_sheet_name = tlang('analysis_sheet_name') || 'Sheet name';
const profile_campaign_name = tlang('profile_campaign_name') || 'Campaign Name';
const sort_text = tlang('sort_text') || 'Sort';
const rows_per_page_text = tlang('rows_per_page_text') || 'Rows per page:';
const no_match_record_found1 = tlang('no_match_record_found') || 'Sorry, no matching records found';
const search_text = tlang('search_text') || 'Search';
const tooltip_download = tlang('tooltip_download') || 'Download';
const tooltip_delete = tlang('tooltip_delete') || 'Delete';
const probing_import_sheet = tlang('probing_import_sheet') || 'Probing Import Sheet';
const uploaded = tlang('uploaded') || 'Uploaded';
const dashboard_log_date = tlang('dashboard_log_date') || 'Date';

const initialState = {
  excelDataList: [],
  campaignList: [],
  isclosed: false,
  getListLoading: true,
  deleteExcelsheetModal: false,
  deleteExcelsheetData: "",
  delete: {},
  viewProbingImportSheetModal: false,
  selectedProbingImportSheet: {},
}

function reducer(state, action) {
  switch (action.type) {
    default:
      const upd = {};
      for (const key of Object.keys(action)) {
        upd[key] = action[key];
      }
      // console.log(`upd`, { upd });
      return { ...state, ...upd };
  }
}

function ViewProbingImportSheet() {
  const [state, localDispatch] = useReducer(reducer, initialState);
  const dispatch = useDispatch();
  const probingReducer = useSelector(state => state.probingReducer);
  const authReducer = useSelector(state => state.authReducer);
  const campaignReducer = useSelector(state => state.campaignReducer);
  const notification = useSelector(state => state.notification);
  const apolloClient = useSelector(state => state.apolloClient);

  useEffect(() => {

    if (authReducer.userData.projectid) {
      let isadmin = authReducer.userData.email == authReducer.userData.owner ? true : false;
      let request = {
        companyname: authReducer.userData.company_name,
        projectid: authReducer.userData.projectid,
        other_company: authReducer.userData.other_company,
        isadmin: isadmin,
      };
      /* get Campaign List */
      dispatch(actions.getCampaignList(apolloClient.campaign, request));

      /* get Excel Data List */
      dispatch(actions.getProbingImportExcelList(apolloClient.probing, request));
    }
  }, []);

  useEffect(() => {
    if (campaignReducer.campaignList) {
      localDispatch({ campaignList: campaignReducer.campaignList });
    }

    if (notification) {
      if (notification.success) {
        localDispatch({
          deleteExcelsheetModal: false,
          deleteExcelsheetData: ""
        })
      }
    }
  }, [notification?.success]);

  useEffect(() => {
    if (campaignReducer.campaignList) {
      localDispatch({ campaignList: campaignReducer.campaignList });
    }
  }, [campaignReducer?.campaignList?.length]);

  useEffect(() => {
    //if (!excelUploadReducer?.excelDataList?.length) {console.log(33333); localDispatch({ getListLoading: false }); return } ;
    //if (excelUploadReducer.excelDataList) {
      localDispatch({ excelDataList: probingReducer.probingExcelDataList, getListLoading: false });
    //}
  }, [probingReducer?.probingExcelDataList]);

  useEffect(() => {
    if (authReducer.companyList && authReducer.companyList.length > 0) {
      let activeProject = authReducer.companyList.filter((val) => val.projectid === authReducer.userData.projectid)
      if (activeProject.length > 0) {
        localDispatch({ isclosed: activeProject[0].isclosed ? activeProject[0].isclosed : false });
      }
    }
  }, [authReducer?.companyList?.length]);

  /* render campaign list */
  const renderExceldataList = () => {
    let exceldataList = [];
    if (state.excelDataList) {
      state.excelDataList.map((val, index) => {
        let campaignName = "";
        state.campaignList.map((campaign, i) => {
          if (campaign.campaignid == val.campaignid) {
            campaignName = campaign.title;
          }
        })
        exceldataList.push([
          index + 1,
          campaignName,
          val.sheet_name,
          val.file_name,
          moment.unix(val.created_at / 1000).format('DD/MM/YYYY'),
          val
        ]);
      });
    }
    return exceldataList;
  };

  const toggleDeleteExcelsheet = (event, value = false) => {
    let objectName = value?.file_name;
    let actionName = 'excelsheet';
    localDispatch({
      delete: { actionName, objectName, value },
      deleteExcelsheetModal: !state.deleteExcelsheetModal,
      deleteExcelsheetData: value ? value : ""
    })
  }

  const deleteExcelsheet = () => {
    let request = {
      id: state.deleteExcelsheetData._id,
      campaignid: state.deleteExcelsheetData.campaignid,
      projectid: authReducer.userData.projectid
    }
    dispatch(actions.deleteProbingImportExcel(apolloClient.probing, request));

    //Remove file from couchdb
    const delDoc = [{
      filename: `${state.deleteExcelsheetData.fileName}`,
      id: 'PRO-' + authReducer.userData.projectid,
    }];
    dispatch(
      actions.deleteCampaignDocument({
        deletedoc_array: JSON.stringify(delDoc),
      })
    );

    //Refresh the List
    setTimeout(() => {
      /* get Probing Excel Data List */
      dispatch(actions.getProbingImportExcelList(apolloClient.probing, request));
    }, 3000);
  }

  const openModalViewProbingImportSheet = (value) => {
    const campaignName = state.campaignList.filter(l => l.campaignid === value.campaignid).map(c => c.title).pop() || '';
    value.campaignName = campaignName;
    let fixWidth = 0;
    let increaseWidth = 108 * value.sheet_resp_header.length;
    fixWidth = fixWidth + increaseWidth;
    value.tableApproxWidth = fixWidth;
    localDispatch({ viewProbingImportSheetModal: true, selectedProbingImportSheet: value})
  }

  const toDataURL = (url) => {
		return fetch(url).then((response) => {
			return response.blob();
		}).then(blob => {
			return URL.createObjectURL(blob);
		});
	}

  const downloadUploadedExcel = async (sheet) => {
    let NewFileName = sheet.file_name;
    const explodeFileName = NewFileName.split('.');
    const fileExt = explodeFileName.pop();
    if(fileExt !== 'xlsx') {
      NewFileName = `${explodeFileName[0]}.xlsx`; //forcefully convert file to xlsx, so it open without any warning when user download it
    }
    
		const a = document.createElement("a");
    a.href = await toDataURL(sheet.url);
		a.download = NewFileName
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

  let isDisabled = authReducer.userData.plan.planid == "AXD_PL_01" ? true : false;

  const columns = [
    {
      name: material_list_sr_no,
      options: {
        filter: false,
      }
    },
    {
      name: profile_campaign_name,
      options: {
        filter: true,
      }
    },
    {
      name: analysis_sheet_name,
      options: {
        filter: false,
      }
    },
    {
      name: analysis_file_name,
      options: {
        filter: false,
      }
    },
    {
      name: uploaded + ' ' + dashboard_log_date,
      options: {
        filter: false,
      }
    },    
    {
      name: analysis_view_details,
      options: {
        filter: false,
        customBodyRender: value => {
          if (value) {
            return (
              <>
                <Button
                  title={''}
                  className={"system-btn clear-border"}
                  outline={true}
                  color={"success"}
                  onClick={(event) => openModalViewProbingImportSheet(value)}
                >
                <Tooltip title={analysis_view_details}>
                  <Icon>remove_red_eye</Icon>
                </Tooltip>
                </Button>
                {value.url !== '' &&
                 <Button
                 title={''}
                 className={"system-btn clear-border"}
                 outline={true}
                 color={"success"}
                 onClick={(event) => downloadUploadedExcel(value)}
                >
                  <Tooltip title={tooltip_download}>
                    <Icon>download</Icon>
                  </Tooltip>
                </Button>
                }
                {state.isclosed == false && ((authReducer.userData.email == authReducer.userData.owner) || supremeRole.includes(authReducer.userData.role_id)) &&
                  <Button
                    title={""}
                    className={"system-btn clear-border"}
                    outline={true}
                    color={"success"}
                    onClick={(event) => toggleDeleteExcelsheet(event, value)}
                  >
                    <Tooltip title={tooltip_delete}>
                      <Icon>delete</Icon>
                    </Tooltip>
                  </Button>
                }
              </>
            );
          }
        }
      }
    },
  ];

  const options = {
    filterType: 'dropdown',
    selectableRows: "none",
    print: false,
    download: false,
    viewColumns: false,
    filter: true,
    responsive: "stacked",
    textLabels: {
      body: {
        noMatch: no_match_record_found1,
        toolTip: sort_text
      },
      pagination: {
        rowsPerPage: rows_per_page_text,
      },
      toolbar: {
        search: search_text
      },
    },
  };

  return (
    <>
      <div>
        <h3 className="page-title" style={{ marginBottom: "20px" }}>
          {probing_import_sheet}
        </h3>
        <div>
          {state.getListLoading && (
            <div className="div-spinner"><Spinner size="sm" className="btn-spinner spinner_color spinner_size" /></div>
          )}
          {!state.getListLoading && (
            <MUIDataTable
              data={renderExceldataList()}
              columns={columns}
              options={options}
            />
          )}
        </div>

        <div>
          {state.deleteExcelsheetModal && (
            <ModalDeleteConfirmation
              isOpen={state.deleteExcelsheetModal}
              onClose={() => localDispatch({ deleteExcelsheetModal: false, deleteExcelsheetData: "" })}
              onConfirm={() => deleteExcelsheet()}
              deleteData={state.delete}
            />
          )}
          {state.viewProbingImportSheetModal && (
            <ModalProbingImportSheet
              isOpen={state.viewProbingImportSheetModal}
              onClose={() => localDispatch({ viewProbingImportSheetModal: false, selectedProbingImportSheet: {} })}
              initialData={state.selectedProbingImportSheet}
            />
          )}
        </div>
      </div>
    </>
  );

}

export default ViewProbingImportSheet;