import React from 'react';
import { Button, Card, CardBody, Spinner } from 'reactstrap';
import moment from 'moment-timezone';
import { Icon, Tooltip } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { PdfDocument } from '../Admin/createInvoicePdf';
import * as ChangeAppLanguageFront from '../Layout/ChangeAppLanguageFront';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
moment.tz.setDefault('Europe/Paris');

let tlang = ChangeAppLanguageFront.translateLanguage;

const ref = React.createRef();

export default {
	invoiceSubComponentOne: function(_this) {
		const client_invoice_title = tlang('client_invoice_title') || 'Title';
		const client_invoice_date = tlang('client_invoice_date') || 'Invoice Date';
		const masters_actions = tlang('masters_actions') || 'Actions';
		const user_request_view_btn = tlang('user_request_view_btn') || 'View';
		const user_request_status = tlang('user_request_status') || 'Status';

		const invoiceColumns = [
			{ name: client_invoice_title },
			{ name: client_invoice_date },
			{ name: user_request_status },
			{
				name: masters_actions,
				options: {
					filter: false,
					customBodyRender: value => {
						return (
							<>
								<Button
									className={'system-btn clear-border'}
									outline={true}
									color={'success'}
									onClick={() => _this.viewInvoiceDetails(value)}
								>
									<Tooltip title={user_request_view_btn}>
										<Icon>remove_red_eye</Icon>
									</Tooltip>
								</Button>
							</>
						);
					},
				},
			},
		];

		const sort_text = tlang('sort_text') || 'Sort';
		const rows_per_page_text = tlang('rows_per_page_text') || 'Rows per page:';
		const no_match_record_found =
			tlang('no_match_record_found') || 'Sorry, no matching records found';
		const search_text = tlang('search_text') || 'Search';

		const invoiceOptions = {
			filterType: 'checkbox',
			selectableRows: 'none',
			print: false,
			download: false,
			viewColumns: false,
			filter: false,
			search: true,
			responsive: 'stacked',
			textLabels: {
				body: {
					noMatch: no_match_record_found,
					toolTip: sort_text,
				},
				pagination: {
					rowsPerPage: rows_per_page_text,
				},
				toolbar: {
					search: search_text,
				},
			},
		};
		const topbar_link_invoices = tlang('topbar_link_invoices') || 'Invoices';
		return (
			<>
				<h3 className="page-title" style={{ marginBottom: '20px' }}>
					{topbar_link_invoices}
				</h3>
				<Card>
					<CardBody>
						{_this.state.getListLoading && (
							<div className="div-spinner">
								<Spinner size="sm" className="btn-spinner spinner_color spinner_size" />
							</div>
						)}
						{!_this.state.getListLoading && (
							<MUIDataTable
								data={_this.renderInvoiceRequestList()}
								columns={invoiceColumns}
								options={invoiceOptions}
							/>
						)}
					</CardBody>
				</Card>
			</>
		);
	},
	invoiceSubComponentTwo: function(_this) {
		const client_invoice_txt = tlang('client_invoice_txt') || 'Invoice';
		const client_invoice_cmp = tlang('client_invoice_cmp') || 'Company';
		const signup_wizard_item = tlang('signup_wizard_item') || 'Item';
		const client_invoice_cost = tlang('client_invoice_cost') || 'Cost';
		const signup_wizard_grand_total = tlang('signup_wizard_grand_total') || 'Grand Total';
		const client_invoice_count = tlang('client_invoice_count') || 'Count';
		const client_amount = tlang('client_amount') || 'Amount';
		const signup_wizard_download_invoice =
			tlang('signup_wizard_download_invoice') || 'Download Invoice';
		const client_back_btn = tlang('client_back_btn') || 'Back';

		let invoiceDetail = _this.state.invoiceDetail.invoice_detail;
		let category_name = _this.state.invoiceDetail.category_name;
		let pdfname = _this.state.invoiceDetail.title;

		const date = moment(new Date(_this.state.invoiceDetail.created_at) / 1000).format(
			'DD/MM/YYYY HH:mm'
		);
		var invoice_date = moment(date, 'DD/MM/YYYY HH:mm', true).isValid();

		//    let title = ""
		//    if(category_name === "TOKEN" ){
		//         title = "Project Token"
		//     }else if(category_name === "LICENCE" ){
		//         title = "Licence"
		//     }else if(category_name === "SIGNUP" ){
		//         title = "Plan"
		//     }
		const topbar_link_invoices = tlang('topbar_link_invoices') || 'Invoices';

		const Quixote = () => (
			<Document>
				<Page>
					<Text>my document</Text>
				</Page>
			</Document>
		);

		const styles = StyleSheet.create({
			body: {
				paddingTop: 35,
				paddingBottom: 65,
				paddingHorizontal: 35,
			},
			title: {
				fontSize: 24,
				textAlign: 'center',
				fontFamily: 'Oswald',
			},
			author: {
				fontSize: 12,
				textAlign: 'center',
				marginBottom: 40,
			},
			subtitle: {
				fontSize: 18,
				margin: 12,
				fontFamily: 'Oswald',
			},
			text: {
				margin: 12,
				fontSize: 14,
				textAlign: 'justify',
				fontFamily: 'Times-Roman',
			},
			image: {
				marginVertical: 15,
				marginHorizontal: 100,
			},
			header: {
				fontSize: 12,
				marginBottom: 20,
				textAlign: 'center',
				color: 'grey',
			},
			pageNumber: {
				position: 'absolute',
				fontSize: 12,
				bottom: 30,
				left: 0,
				right: 0,
				textAlign: 'center',
				color: 'grey',
			},
		});

		return (
			<>
				<h3 className="page-title" style={{ marginBottom: '20px' }}>
					{topbar_link_invoices}
				</h3>
				<Card>
					<CardBody>
						<div className="">
							<div
								ref={ref}
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '21cm',
									maxHeight: '29cm',
								}}
							>
								<div
									className="topbar__logo"
									style={{ marginLeft: '20px', marginTop: '20px' }}
								></div>
								<h1 style={{ marginLeft: '30px', marginTop: '20px' }}>{client_invoice_txt}</h1>
								<p style={{ width: '20cm', marginTop: '20px', marginLeft: '30px' }}>
									{client_invoice_cmp}: {_this.props.authReducer.userData.display_name}
								</p>
								<p style={{ width: '20cm', marginTop: '0px', marginLeft: '30px' }}>
									Email: {_this.props.authReducer.userData.email}
								</p>
								<p style={{ width: '20cm', marginLeft: '30px' }}>
									{'Invoice Date'}:{' '}
									{invoice_date
										? moment
												.unix(new Date(_this.state.invoiceDetail.created_at) / 1000)
												.format('DD/MM/YYYY HH:mm')
										: moment
												.unix(_this.state.invoiceDetail.created_at / 1000)
												.format('DD/MM/YYYY HH:mm')}
								</p>
								<table style={{ width: '20cm', marginTop: '20px', marginLeft: '30px' }}>
									{category_name === 'SIGNUP' && (
										<>
											<tr>
												<td colSpan="4">
													<h4>{pdfname}</h4>
												</td>
											</tr>
											<tr style={{ borderBottom: '1px solid #e0e0e0' }}>
												<th>{signup_wizard_item}</th>
												<th colSpan="3"></th>
												<th colSpan="3" style={{ textAlign: 'right' }}>
													{client_invoice_cost}
												</th>
											</tr>
											{invoiceDetail.map((val, index) => {
												return (
													<>
														{(val.plan_name || val.planname || val.type) && (
															<tr style={{ borderBottom: '1px solid #e0e0e0' }}>
																<td>
																	{val.plan_name && val.plan_name}
																	{val.planname && val.planname}
																	{val.type === 'renew' && 'Plan Renew'}
																	{val.type === 'extend' && 'Plan Extend'}
																</td>
																<td colSpan="3"></td>
																<td colSpan="3" style={{ textAlign: 'right' }}>
																	${val.price}
																</td>
															</tr>
														)}
														{val.plan && (
															<tr style={{ borderBottom: '1px solid #e0e0e0' }}>
																<td>
																	{val.plan.plan_name && val.plan.plan_name}
																	{val.plan.planname && val.plan.planname}
																	{val.plan.type === 'renew' && 'Plan Renew'}
																	{val.plan.type === 'extend' && 'Plan Extend'}
																</td>
																<td colSpan="3"></td>
																<td colSpan="3" style={{ textAlign: 'right' }}>
																	${val.plan.price}
																</td>
															</tr>
														)}
														{val.licence &&
															val.licence
																.filter((value, index1) => value.count !== 0)
																.map((val, index) => {
																	return (
																		<tr style={{ borderBottom: '1px solid #e0e0e0' }}>
																			<td>{val.licencename && val.licencename}</td>
																			{val.count ? (
																				<td colSpan="3">{val.count}</td>
																			) : (
																				<td colSpan="3">{'1'}</td>
																			)}
																			<td colSpan="3" style={{ textAlign: 'right' }}>
																				${val.count ? val.count * val.price : val.price}
																			</td>
																		</tr>
																	);
																})}
													</>
												);
											})}
											<tr>
												<td></td>
												<td colSpan="3"></td>
												<td colSpan="3" style={{ textAlign: 'right' }}>
													{signup_wizard_grand_total} : ${_this.getTotalAmount()}
												</td>
											</tr>
										</>
									)}
									{category_name === 'TOKEN' && (
										<>
											<tr>
												<td colSpan="4">
													<h4>{pdfname}</h4>
												</td>
											</tr>
											<tr style={{ borderBottom: '1px solid #e0e0e0' }}>
												<th>{signup_wizard_item}</th>
												<th colSpan="3">{client_invoice_count}</th>
												<th colSpan="3">{client_invoice_cost}</th>
												<th colSpan="3" style={{ textAlign: 'right' }}>
													{client_amount}
												</th>
											</tr>
											{invoiceDetail.map((val, index) => {
												return (
													<tr style={{ borderBottom: '1px solid #e0e0e0' }}>
														<td> {'Token'}</td>
														<td colSpan="3">{val.count}</td>
														<td colSpan="3">${val.price / val.count}</td>
														<td colSpan="3" style={{ textAlign: 'right' }}>
															${val.price}
														</td>
													</tr>
												);
											})}
											<tr>
												<td></td>
												<td colSpan="3"></td>
												<td colSpan="3"></td>
												<td colSpan="3" style={{ textAlign: 'right' }}>
													{signup_wizard_grand_total} : ${_this.getTotalAmount()}
												</td>
											</tr>
										</>
									)}
									{category_name === 'LICENCE' && (
										<>
											<tr>
												<td colSpan="4">
													<h4>{pdfname}</h4>
												</td>
											</tr>
											<tr style={{ borderBottom: '1px solid #e0e0e0' }}>
												<th>{signup_wizard_item}</th>
												<th colSpan="3">{client_invoice_count}</th>
												<th colSpan="3">{client_invoice_cost}</th>
												<th colSpan="3" style={{ textAlign: 'right' }}>
													{client_amount}
												</th>
											</tr>
											{invoiceDetail
												.filter((value, index1) => value.count !== 0)
												.map((val, index) => {
													return (
														<tr style={{ borderBottom: '1px solid #e0e0e0' }}>
															<td colSpan="3">{val.licencename && val.licencename}</td>
															{val.count ? <td>{val.count}</td> : <td>{'1'}</td>}
															<td colSpan="3">${val.price}</td>
															<td colSpan="3" style={{ textAlign: 'right' }}>
																${val.count ? val.count * val.price : val.price}
															</td>
														</tr>
													);
												})}
											<tr>
												<td></td>
												<td colSpan="3"></td>
												<td colSpan="3"></td>
												<td colSpan="3" style={{ textAlign: 'right' }}>
													{signup_wizard_grand_total} : ${_this.getTotalAmount()}
												</td>
											</tr>
										</>
									)}
								</table>
							</div>
							<div style={{ width: '21cm', marginTop: '10px' }}>
								{console.log("iousioasodasuiodyo",_this.state.invoiceDetail)}
								<Button
									style={{ float: 'right', marginRight: '10px' }}
									title={signup_wizard_download_invoice}
									className={'add-new-btn btn btn-success'}
									outline={false}
									color={'success'}
									size="sm"
									onClick={() => PdfDocument(_this.state.invoiceDetail)}
								>
									{signup_wizard_download_invoice}
								</Button>
								<Button
									style={{ float: 'right', marginRight: '10px' }}
									onClick={() => {
										_this.closeScreenTwo();
									}}
									className="add-new-btn btn btn-success"
									color="success"
								>
									{client_back_btn}
								</Button>
							</div>
						</div>
					</CardBody>
				</Card>
			</>
		);
	},
};
