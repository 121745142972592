import React, { useState, useEffect, useReducer } from 'react';
import { Card, CardBody, Spinner, Collapse } from 'reactstrap';
import { Grid, Icon } from '@material-ui/core';
import * as actions from '../../../Store/actions/index';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import JsxParser from 'react-jsx-parser';
import Server from '../../../Common/Server';
import * as ChangeAppLanguageFront from '../Layout/ChangeAppLanguageFront';
import { useHistory } from 'react-router-dom';
import Notification from '../Client/components/notification';
import useNotifications from '../../lib/useNotifications';

moment.tz.setDefault('Europe/Paris');

let tlang = ChangeAppLanguageFront.translateLanguage;
const no_records_found = tlang('client_cmp_no_records_found')
    || 'No records found';
const notification_lbl = tlang('notification_lbl') || 'Notification';
const mark_all_as_read = tlang('mark_all_as_read') || 'Mark all as read';
const notification_all_tab = tlang('notification_all_tab') || 'All';
const notification_unread_tab = tlang('notification_unread_tab')
    || 'Unread';
const notification_read_tab = tlang('notification_read_tab')
    || 'Read';
const notification_see_more = tlang('notification_see_more')
    || 'See More';


const initialState = {
    notificationList: [],
    unreadNotificationCount: 0,
    skipNotificationCount: 0,
    latestNotificationList: false,
    seeMoreNotificationIsDisabled: true,
    notification_chatid: '',
    loading: false,
    collapseNotify: false,
    selectedType: '1',
    allNotificationCount: 0,
    readNotificationCount: 0,
};

function reducer(state, action) {
    switch (action.type) {
        default:
            const upd = {};
            for (const key of Object.keys(action)) {
                upd[key] = action[key];
            }
            // console.log(`upd`, { upd });
            return { ...state, ...upd };
    }
}

function HeaderNotification() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, localDispatch] = useReducer(reducer, initialState);
    const apolloClient = useSelector(state => state.apolloClient);
    const authReducer = useSelector(state => state.authReducer);
    const contentManagementReducer = useSelector(state => state.contentManagementReducer);
    const { showSuccess, showError, showInfo, showWarning } = useNotifications();
    const userId = authReducer.userData.userid;

    useEffect(() => {
        const requestParam = {
            userid: userId,
            skip: state.skipNotificationCount,
        };
        dispatch(actions.getUserNotificationList(apolloClient.client, requestParam));

        const requestParamForNotiCount = {
            userid: userId,
        };
        dispatch(actions.getUserNotificationUnreadCount(apolloClient.client, requestParamForNotiCount));

        localDispatch({ latestNotificationList: true });

    }, [])

    useEffect(() => {
        if (contentManagementReducer.userNotificationUnreadCount) {
            const unreadNotificationCount = contentManagementReducer.userNotificationUnreadCount.unread_count;
            localDispatch({ unreadNotificationCount: unreadNotificationCount });
        }
    }, [contentManagementReducer.userNotificationUnreadCount]);

    useEffect(() => {
        if (!Array.isArray(contentManagementReducer.userNotificationList)) return;

        let list = contentManagementReducer.userNotificationList;
        let notificationList = state.notificationList;

        if (list.length > 0 && state.latestNotificationList) {
            for (const val of list) {
                val.notifications.data_time = '';
                val.notifications.chatid = val._id;
                val.notifications.profile_pic = val.notifications.profile_pic
                    ? val.notifications.profile_pic
                    : `${process.env.PUBLIC_URL}/img/topbar/ava.png`;

                if (val.created_at) {
                    val.notifications.data_time = moment(parseInt(val.created_at)).format(
                        'DD/MM/YYYY HH:mm'
                    );
                }
                notificationList.push(val);
            }
            localDispatch({ latestNotificationList: false });
        }
        let seeMoreNotificationIsDisabled = true;
        if (list.length === 5) {
            seeMoreNotificationIsDisabled = false;
        }

        localDispatch({
            notificationList,
            loading: false,
            seeMoreNotificationIsDisabled: seeMoreNotificationIsDisabled,
        });


        let notification_chatid = state.notification_chatid;

        let allNotificationCount = state.notificationList.length;
        let readNotificationCount = state.notificationList.filter(
            val => val.notifications.read === true
        ).length;
        let unreadNotificationCount = state.notificationList.filter(
            val => val.notifications.read === false
        ).length;

        localDispatch({
            allNotificationCount: allNotificationCount,
            readNotificationCount: readNotificationCount,
            unreadNotificationCount: unreadNotificationCount,
        });

        if (notification_chatid) {
            let notificationList = state.notificationList;
            for (const val of notificationList) {
                if (notification_chatid === val._id) {
                    val.notifications.read = true;
                }
            }

            let allNotificationCount = notificationList.length;
            let readNotificationCount = notificationList.filter(
                val => val.notifications.read === true
            ).length;
            let unreadNotificationCount = notificationList.filter(
                val => val.notifications.read === false
            ).length;

            localDispatch({
                notificationList: notificationList,
                notification_chatid: '',
                allNotificationCount: allNotificationCount,
                readNotificationCount: readNotificationCount,
                unreadNotificationCount: unreadNotificationCount,
            });
        }
    }, [contentManagementReducer.userNotificationList])


    // more notification load
    const nextNotificationList = () => {
        let skipCount = state.skipNotificationCount + 5;
        let requestParam = {
            userid: authReducer.userData.userid,
            skip: skipCount,
        };
        dispatch(actions.getUserNotificationList(apolloClient.client, requestParam));
        localDispatch({
            skipNotificationCount: skipCount,
            latestNotificationList: true,
        });
    }

    const notificationMarkAllAsRead = (chatid = '') => {
        let request = {
            userid: authReducer.userData.userid,
            chatid: chatid,
        };
        dispatch(actions.notificationMarkAllAsRead(
            apolloClient.masters,
            apolloClient.client,
            request
        ));
        if (chatid !== '') {
            localDispatch({ loading: true, notification_chatid: chatid });
        } else {
            let notificationList = state.notificationList;
            for (const val of notificationList) {
                val.notifications.read = true;
            }

            let allNotificationCount = notificationList.length;
            let readNotificationCount = notificationList.filter(val => val.notifications.read === true)
                .length;
            let unreadNotificationCount = notificationList.filter(val => val.notifications.read === false)
                .length;

            localDispatch({
                notificationList: notificationList,
                allNotificationCount: allNotificationCount,
                readNotificationCount: readNotificationCount,
                unreadNotificationCount: unreadNotificationCount,
            });

            setTimeout(
                function () {
                    toggleNotify();
                }, 1000);
        }
    }

    const toggleNotify = () => {
        localDispatch({ collapseNotify: !state.collapseNotify });
    };

    // notification to set specific redirect page
    const redirectToPage = (event, notification) => {
        let pathname = history.location.pathname;

        let isadmin = false;
        if (authReducer.userData.email === authReducer.userData.owner) {
            isadmin = true;
        }
        if (!notification.read) {
            notificationMarkAllAsRead(notification.chatid);
        }
        let key = notification.key;
        if (key !== '' && key !== 'CHAT_MESSAGE') {
            if (
                key === 'ADD_IN_PROJECT' ||
                key === 'REMOVE_IN_PROJECT' ||
                key === 'PROJECT_REQ_ACCEPT' ||
                key === 'PROJECT_REQ_REJECT' ||
                key === 'ADD_MEMBER'
            ) {
                if (pathname !== '/client/projectList') {
                    history.push('/client/projectList');
                } else {
                    dispatch(actions.currentNotificationRedirectPageUrl('/client/projectList'));
                }
            } else if (
                key === 'PROJECT_MEMBER_REQ' &&
                authReducer.userData.projectid !== null
            ) {
                localStorage.setItem('client_request_inner_tab', '1');
                localStorage.setItem('client_request_tab', 3);
                if (pathname !== '/client/profile') {
                    history.push('/client/profile');
                } else {
                    dispatch(actions.currentNotificationRedirectPageUrl('/client/profile'));
                }
            } else if (
                (key === 'CAMPAIGN_MEMBER_REQ' ||
                    key === 'CAMPAIGN_REQ_REJECT' ||
                    key === 'CAMPAIGN_REQ_ACCEPT' ||
                    key === 'ADD_IN_CAMPAIGN') &&
                authReducer.userData.projectid !== null
            ) {
                if (pathname !== '/client/campaignList') {
                    history.push('/client/campaignList');
                } else {
                    dispatch(actions.currentNotificationRedirectPageUrl('/client/campaignList'));
                }
            } else if (key === 'PROJECT_TOKEN_REQ_ACCEPT' || key === 'PROJECT_TOKEN_REQ_REJECT') {
                if (authReducer.userData.plan.planid !== 'AXD_PL_01' && isadmin) {
                    localStorage.setItem('client_request_tab', '8');
                    if (pathname !== '/client/profile') {
                        history.push('/client/profile');
                    } else {
                        dispatch(actions.currentNotificationRedirectPageUrl('/client/profile'));
                    }
                }
            } else if (
                key === 'LICENSE_RENEW_REQ_ACCEPT' ||
                key === 'LICENSE_RENEW_REQ_REJECT' ||
                key === 'LICENSE_REQ_ACCEPT'
            ) {
                if (authReducer.userData.plan.planid !== 'AXD_PL_01' && isadmin) {
                    localStorage.setItem('client_request_tab', '7');

                    if (pathname !== '/client/profile') {
                        history.push('/client/profile');
                    } else {
                        dispatch(actions.currentNotificationRedirectPageUrl('/client/profile'));
                    }
                }
            } else if (key === 'PLAN_RENEW_REQ_ACCEPT' || key === 'PLAN_RENEW_REQ_REJECT') {
                if (isadmin) {
                    localStorage.setItem('client_request_tab', '4');

                    if (pathname !== '/client/profile') {
                        history.push('/client/profile');
                    } else {
                        dispatch(actions.currentNotificationRedirectPageUrl('/client/profile'));
                    }
                }
            } else if (key === 'SIGNUP_REQUEST') {
                // this.props.history.push('/admin/request')
                if (pathname !== '/admin/request') {
                    history.push('/admin/request');
                } else {
                    dispatch(actions.currentNotificationRedirectPageUrl('/admin/request'));
                }
            } else if (key === 'PLAN_RENEW_REQUEST') {
                localStorage.setItem('plan_request_tab', '2');

                // this.props.history.push('/admin/planRequest')
                if (pathname !== '/admin/planRequest') {
                    history.push('/admin/planRequest');
                } else {
                    dispatch(actions.currentNotificationRedirectPageUrl('/admin/planRequest'));
                }
            } else if (key === 'PROJECT_TOKEN_REQUEST') {
                // this.props.history.push('/admin/projectToken')
                if (pathname !== '/admin/projectToken') {
                    history.push('/admin/projectToken');
                } else {
                    dispatch(actions.currentNotificationRedirectPageUrl('/admin/projectToken'));
                }
            } else if (key === 'LICENSE_REQUEST') {
                localStorage.setItem('licence_request_tab', '1');
                if (pathname !== '/admin/licenceRequest') {
                    history.push('/admin/licenceRequest');
                } else {
                    dispatch(actions.currentNotificationRedirectPageUrl('/admin/licenceRequest'));
                }
            } else if (key === 'LICENSE_RENEW_REQUEST') {
                localStorage.setItem('licence_request_tab', '4');
                // this.props.history.push('/admin/licenceRequest')
                if (pathname !== '/admin/licenceRequest') {
                    history.push('/admin/licenceRequest');
                } else {
                    dispatch(actions.currentNotificationRedirectPageUrl('/admin/licenceRequest'));
                }
            }
        }
        setTimeout(
            function () {
                toggleNotify();
            }, 1000);
    }

    const RequestType = type => {
        localDispatch({ selectedType: type });
    };

    let currentLanguage = localStorage.getItem('language');

    const { selectedType, collapseNotify } = state;

    const notificationCounter = {
        padding: '2px 3px',
        backgroundColor: 'rgb(230, 72, 35)',
        color: 'white',
        fontSize: '12px',
        position: 'absolute',
        top: '7px',
        right: '2px',
        border: '2px solid white',
        borderRadius: '5px',
    };

    return (
        <>
            <button className="topbar__btn" type="button" onClick={() => toggleNotify()}>
                <Icon style={{ color: state.unreadNotificationCount > 0 ? 'var(--clr-aexdo)' : '#b1c3c8' }} className={'bellIcon'}>notifications</Icon>
                {state.unreadNotificationCount !== 0 && (
                    <span style={notificationCounter}>{state.unreadNotificationCount}</span>
                )}
            </button>
            {collapseNotify && (
                <button className="topbar__back" type="button" onClick={() => toggleNotify()} />
            )}
            <Collapse isOpen={collapseNotify} className="topbar__collapse-content">
                <div className="topbar__collapse-title-wrap">
                    <p className="topbar__collapse-title">{notification_lbl} </p>
                    {state.unreadNotificationCount !== 0 && (
                        <span>
                            <a
                                className="markasread topbar__collapse-link"
                                href="#"
                                onClick={e => e.preventDefault() || notificationMarkAllAsRead()}
                                disabled={state.loading}
                            >
                                {state.loading && (
                                    <Spinner size="sm" className="btn-spinner" style={{ color: '#E64823' }} />
                                )}
                                {mark_all_as_read}
                            </a>
                        </span>
                    )}
                </div>
                <div className="notification-size">
                    <div>
                        <Grid
                            container
                            className="admin_request"
                            style={{ margin: '-10px', textAlign: 'center' }}
                        >
                            <Grid item xs={12} sm={12} md={12}>
                                <Card style={{ paddingBottom: '0px' }}>
                                    <CardBody>
                                        <div
                                            className={`requestTagBadge ${selectedType === '1' ? ' badge-danger' : ''}`}
                                            onClick={() => RequestType('1')}
                                        >
                                            {notification_all_tab}
                                            <span className="requestTag">{state.allNotificationCount}</span>
                                        </div>
                                        <div
                                            className={`requestTagBadge ${selectedType === '2' ? ' badge-danger' : ''}`}
                                            onClick={() => RequestType('2')}
                                        >
                                            {notification_unread_tab}
                                            <span className="requestTag">{state.unreadNotificationCount}</span>
                                        </div>
                                        <div
                                            className={`requestTagBadge ${selectedType === '3' ? ' badge-danger' : ''}`}
                                            onClick={() => RequestType('3')}
                                        >
                                            {notification_read_tab}
                                            <span className="requestTag">{state.readNotificationCount}</span>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                    {state.notificationList.length ? (
                        <>
                            {state.notificationList.map((notification, index) => {
                                let msg = '';
                                let nData = notification.notifications;
                                let tranTags = nData.tags;
                                if (
                                    notification.notification_template.length &&
                                    notification.notification_template[0].lang_desc &&
                                    notification.notification_template[0].lang_desc[0]
                                ) {
                                    let lang_desc = notification.notification_template[0].lang_desc[0];
                                    for (var key in lang_desc) {
                                        if (key === currentLanguage) {
                                            msg = lang_desc[key];
                                        }
                                    }
                                } else {
                                    msg = nData.message;
                                }
                                msg = msg && msg !== '' ? msg.replace('}}', '').replace('{{', '') : '';
                                for (const key in tranTags) {
                                    msg = msg.replace(key, tranTags[key]);
                                }

                                msg = msg && msg !== '' ? msg.replace('}}', '').replace('{{', '') : '';

                                let url_prefix = Server.IMAGE_URL + '/' + Server.COUCH_DATABASE + '/';
                                let defImg = url_prefix + '3358e2d8-5f3c-4b03-9c24-c009d7822cbe/user_pic.jpg';
                                let notificationIcon =
                                    typeof nData.profile_pic !== 'undefined' ? nData.profile_pic : defImg;

                                return (
                                    <div key={`NOTIFICATION-${index}`}>
                                        {selectedType === '1' ? (
                                            <div
                                                className="topbar__collapse-item cursor-pointer"
                                                key={index}
                                                style={{ background: nData.read ? '#ffffff' : '#bad0f299' }}
                                                onClick={event => redirectToPage(event, nData)}
                                            >
                                                <div className="topbar__collapse-img-wrap">
                                                    <div
                                                        className="topbar__collapse-img"
                                                        style={{
                                                            //backgroundImage: 'url(' + notificationIcon + ')',
                                                            backgroundSize: 'cover',
                                                            height: '100%',
                                                        }}
                                                    >
                                                        <img
                                                            src={notificationIcon}
                                                            onError={e => {
                                                                e.target.onerror = null;
                                                                e.target.src = defImg;
                                                            }}
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="topbar__collapse-message">
                                                    <JsxParser jsx={msg} />
                                                    {nData.key === 'CHAT_MESSAGE' && nData.docurl !== '' && (
                                                        <a href={nData.docurl} target="_blank" rel="noreferrer">
                                                            <Icon>archive</Icon> {nData.filename} <br></br>{' '}
                                                        </a>
                                                    )}
                                                    <span className="topbar__collapse-name">{nData.data_time} </span>
                                                </div>
                                            </div>
                                        ) : selectedType === '2' ? (
                                            <>
                                                {state.unreadNotificationCount > 0
                                                    ? !nData.read && (
                                                        <div
                                                            className="topbar__collapse-item cursor-pointer"
                                                            key={index}
                                                            style={{ background: '#bad0f299' }}
                                                            onClick={event => redirectToPage(event, nData)}
                                                        >
                                                            <div className="topbar__collapse-img-wrap">
                                                                <div
                                                                    className="topbar__collapse-img"
                                                                    style={{
                                                                        //backgroundImage: 'url(' + notificationIcon + ')',
                                                                        backgroundSize: 'cover',
                                                                        height: '100%',
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={notificationIcon}
                                                                        onError={e => {
                                                                            e.target.onerror = null;
                                                                            e.target.src = defImg;
                                                                        }}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="topbar__collapse-message">
                                                                <JsxParser jsx={msg} />
                                                                {nData.key === 'CHAT_MESSAGE' && nData.docurl !== '' && (
                                                                    <a href={nData.docurl} target="_blank" rel="noreferrer">
                                                                        <Icon>archive</Icon> {nData.filename} <br></br>{' '}
                                                                    </a>
                                                                )}
                                                                <span className="topbar__collapse-name">
                                                                    {nData.data_time}{' '}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )
                                                    : index === 0 && (
                                                        <p className="m-3 text-center">
                                                            <b>{no_records_found}</b>
                                                        </p>
                                                    )}
                                            </>
                                        ) : selectedType === '3' ? (
                                            <>
                                                {state.readNotificationCount > 0
                                                    ? nData.read && (
                                                        <div
                                                            className="topbar__collapse-item cursor-pointer"
                                                            key={index}
                                                            style={{ background: '#ffffff' }}
                                                            onClick={event => redirectToPage(event, nData)}
                                                        >
                                                            <div className="topbar__collapse-img-wrap">
                                                                <div
                                                                    className="topbar__collapse-img"
                                                                    style={{
                                                                        //backgroundImage: 'url(' + notificationIcon + ')',
                                                                        backgroundSize: 'cover',
                                                                        height: '100%',
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={notificationIcon}
                                                                        onError={e => {
                                                                            e.target.onerror = null;
                                                                            e.target.src = defImg;
                                                                        }}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="topbar__collapse-message">
                                                                <JsxParser jsx={msg} />
                                                                {nData.key === 'CHAT_MESSAGE' && nData.docurl !== '' && (
                                                                    <a href={nData.docurl} target="_blank" rel="noreferrer">
                                                                        <Icon>archive</Icon> {nData.filename} <br></br>{' '}
                                                                    </a>
                                                                )}
                                                                <span className="topbar__collapse-name">
                                                                    {nData.data_time}{' '}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )
                                                    : index === 0 && (
                                                        <p className="m-3 text-center">
                                                            <b>{no_records_found}</b>
                                                        </p>
                                                    )}
                                            </>
                                        ) : (
                                            <h1>other List</h1>
                                        )}
                                    </div>
                                );
                            })}
                            <hr />
                            {state.seeMoreNotificationIsDisabled ? (
                                <p className="m-3 text-center" style={{ cursor: 'pointer' }}>
                                    <b>{notification_see_more}</b>
                                </p>
                            ) : (
                                <p
                                    className="m-3 text-center"
                                    style={{ cursor: 'pointer' }}
                                    onClick={e => nextNotificationList()}
                                >
                                    <b>{notification_see_more}</b>
                                </p>
                            )}
                        </>
                    ) : (
                        <p className="m-3 text-center">
                            <b>{no_records_found}</b>
                        </p>
                    )}
                </div>
            </Collapse>
            <Notification />
        </>
    )

}

export default HeaderNotification;