import React from 'react';
import {
	Button,
	ButtonToolbar,
	Modal,
	Row,
	Col,
	Spinner,
	Card,
	CardBody,
	CustomInput,
} from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import GetAppIcon from '@material-ui/icons/GetApp';
import 'react-phone-number-input/style.css';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment-timezone';
import Dropzone from 'react-dropzone';
import MenuItem from '@material-ui/core/MenuItem';
import { freePlan } from '../../Common/commonFunctions';
import { Icon, Tooltip } from '@material-ui/core';
import 'react-phone-number-input/style.css';
import Checkbox from '@material-ui/core/Checkbox';
import MUIDataTable from 'mui-datatables';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Select from 'react-select';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import PhoneInput from "react-phone-number-input";

moment.tz.setDefault('Europe/Paris');

let tlang = ChangeAppLanguageFront.translateLanguage;

const add_member = tlang('add_member') || 'Add Member';
const client_profile_name = tlang('client_profile_name') || 'Name';
const client_name_validate = tlang('client_name_validate') || 'Please Add Name';
const client_profile_email = tlang('client_profile_email') || 'Email';
const create_update_staff_modal_pls_enter_email =
	tlang('create_update_staff_modal_pls_enter_email') || '* Please Enter Valid Email';
const client_add_member_expected_role = tlang('client_add_member_expected_role') || 'Expected Role';
const client_project_pls_enter_role = tlang('client_project_pls_enter_role') || 'Please Add Role';
const signup_wizard_save = tlang('signup_wizard_save') || 'Save';

const delete_member_modal_title = tlang('delete_member_modal_title') || 'Delete Member';
const delete_member_modal_message =
	tlang('delete_member_modal_message') || 'Are you sure want to delete this Member';
const delete_btn = tlang('delete_btn') || 'Delete';
const cancel_btn = tlang('cancel_btn') || 'Cancel';

const accept_invite_modal_title = tlang('accept_invite_modal_title') || 'Request Detail';
const accept_invite_modal_user_member = tlang('accept_invite_modal_user_member') || 'User Member';
const accept_invite_modal_pls_add_user =
	tlang('accept_invite_modal_pls_add_user') || 'Please Add Project User';
const accept_btn = tlang('accept_btn') || 'Accept';
//const cancel_btn = tlang('cancel_btn')||'Cancel';

const reject_invite_modal_title = tlang('reject_invite_modal_title') || 'Request Details';
const reject_invite_modal_message =
	tlang('reject_invite_modal_message') || 'Are you sure want to delete this Invite?';
const client_sign_in_up_form_drag_legal_docs =
	tlang('client_sign_in_up_form_drag_legal_docs') || 'Drop Your Legal Documents Here.';
const tooltip_delete = tlang('tooltip_delete') || 'Delete';
const tooltip_add = tlang('tooltip_add') || 'Add';
const tooltip_remove = tlang('tooltip_remove') || 'Remove';
const client_profile_licence_code = tlang('client_profile_licence_code') || 'Licence Code';
const project_request = tlang('project_request') || 'Project Request';
const project_request_do_you_want_msg =
	tlang('project_request_do_you_want_msg') || 'Do you want to continue working on this project?';
const common_btn_no = tlang('common_btn_no') || 'No';
const common_btn_yes = tlang('common_btn_yes') || 'Yes';
const common_btn_okay = tlang('common_btn_okay') || 'Okay';
const send_btn = tlang('client_send_btn') || 'Send';
const message_txt = tlang('message_txt') || 'Message';
const client_probing_method = tlang('client_probing_method') || 'Probing Method';
const sort_text = tlang('sort_text') || 'Sort';
const rows_per_page_text = tlang('rows_per_page_text') || 'Rows per page:';
const no_match_record_found = tlang('no_match_record_found') || 'Sorry, no matching records found';
const client_send_btn = tlang('client_send_btn') || 'Send';
const signup_wizard_month = tlang('signup_wizard_month') || 'month';
const profile_token_request = tlang('profile_token_request') || 'Token Request';
const profile_token_count = tlang('profile_token_count') || 'Token Count';
const profile_price = tlang('profile_price') || 'Price';
const common_btn_send = tlang('common_btn_send') || 'Send';
const assign_licence_request_modal_title =
	tlang('assign_licence_request_modal_title') || 'Request Details';
const assign_licence_request_modal_user = tlang('assign_licence_request_modal_user') || 'User';
const assign_licence_request_modal_pls_enter_user =
	tlang('assign_licence_request_modal_pls_enter_user') || 'Please Add User';
const plan_details = tlang('plan_details') || 'Plan Details';
const signup_wizard_mo = tlang('signup_wizard_mo') || 'mo';
const signup_wizard_users = tlang('signup_wizard_users') || 'Users';
const signup_wizard_gb_storage = tlang('signup_wizard_gb_storage') || 'Gb storage';
const signup_wizard_monthly_update = tlang('signup_wizard_monthly_update') || 'Monthly update';
const signup_wizard_free_support = tlang('signup_wizard_free_support') || 'Free support';
const signup_wizard_renew_plan = tlang('signup_wizard_renew_plan') || 'Renew Plan';
const signup_wizard_confirm_next = tlang('signup_wizard_confirm_next') || 'Confirm & Next';
const member_details_request_modal_sr_no = tlang('member_details_request_modal_sr_no') || 'Sr.No';
const member_details_request_modal_project_name =
	tlang('member_details_request_modal_project_name') || 'Project Name';
const member_details_request_modal_project_status =
	tlang('member_details_request_modal_project_status') || 'Status';
const member_details_request_modal_company_name =
	tlang('member_details_request_modal_company_name') || 'Company Name';
const member_details_request_modal_role = tlang('member_details_request_modal_role') || 'Role';
const member_details_request_modal_action =
	tlang('member_details_request_modal_action') || 'Action';
const member_details_request_modal_title =
	tlang('member_details_request_modal_title') || 'User Project Details';
const common_btn_close = tlang('common_btn_close') || 'Close';
const delete_invited_member_request_modal_title =
	tlang('delete_invited_member_request_modal_title') || 'Request Details';
const delete_invited_member_request_modal_message =
	tlang('delete_invited_member_request_modal_message') || 'Are you sure want to remove a user from this project ?';
const reject_campaign_invite_request_modal_title =
	tlang('reject_campaign_invite_request_modal_title') || 'Request Details';
const reject_campaign_invite_are_you_sure_msg =
	tlang('reject_campaign_invite_are_you_sure_msg') ||
	'Are you sure want to delete this campaign Invite?';
const client_upload_documents = tlang('client_upload_documents') || 'Upload Document';
const client_uplolad_btn = tlang('client_uplolad_btn') || 'Upload';
const client_upload_files_validate =
	tlang('client_upload_files_validate') || '* Please enter files.';
const accept_Campaign_Invite_Modal_Title =
	tlang('accept_Campaign_Invite_Modal_Title') || 'Campaign request';
const share_document_txt = tlang('share_document_txt') || 'Share Document';
const sharedoc_please_select_user = tlang('sharedoc_please_select_user') || 'Please select user';
const share_btn = tlang('share_btn') || 'Share';
const allDocumentsModal_Filename = tlang('allDocumentsModal_Filename') || 'Filename';
const allDocumentsModal_Size = tlang('allDocumentsModal_Size') || 'Size';
const allDocumentsModal_Uploadedby = tlang('allDocumentsModal_Uploadedby') || 'Uploaded by';
const allDocumentsModal_Date = tlang('allDocumentsModal_Date') || 'Date';
const allDocumentsModal_Download = tlang('allDocumentsModal_Download') || 'Download';
const allDocumentsModal_alldoc = tlang('allDocumentsModal_alldoc') || 'All Documents';
const allDocumentsModal_Actions = tlang('allDocumentsModal_Actions') || 'Actions';
const deleteDocumentModal_title = tlang('deleteDocumentModal_title') || 'Delete Document';
const deleteDocumentModal_are_you_sure_msg =
	tlang('deleteDocumentModal_are_you_sure_msg') || 'Are you sure want to delete this document';
const deleteDocumentFromChatModal_title =
	tlang('deleteDocumentFromChatModal_title') || 'Delete Document';
const deleteDocumentFromChatModal_are_you_sure_msg =
	tlang('deleteDocumentFromChatModal_are_you_sure_msg') ||
	'Are you sure want to delete this document';
const my_document = tlang('my_document') || 'My Document';
const no_any_document_for_upload =
	tlang('no_any_document_for_upload') || 'No any document for upload';
const Length_txt = tlang('Length_txt') || 'Length';
const project_document = tlang('project_document') || 'Project Document';
const delete_probing = tlang('delete_probing') || 'Delete Probing';
const restore_probing = tlang('restore_probing') || 'Restore Probing';
const delete_probing_are_you_sure =
	tlang('delete_probing_are_you_sure') || 'Are you sure you want to delete this Probing';
const restore_probing_are_you_sure =
	tlang('restore_probing_are_you_sure') || 'Are you sure you want to restore this Probing';
const restore_btn = tlang('restore_btn') || 'Restore';
const client_open_probing_message =
	tlang('client_open_probing_message') || 'Are you sure you want to open this Probing';
const same_expected_depth_and_layer_length =
	tlang('same_expected_depth_and_layer_length') ||
	'Your expected depth and total number of layers depth is same. Would you like to close the probing?';
const clientmenu_probing = tlang('clientmenu_probing') || 'Probing';
const client_probing_lithology = tlang('client_probing_lithology') || 'Lithology';
const client_import_probing_altitude = tlang('client_import_probing_altitude') || 'Altitude';
const client_probing_expecteddepth = tlang('client_probing_expecteddepth') || 'Expected depth';
const common_probing_list = tlang('common_probing_list') || 'Probing List';
const campaign_stret_view = tlang('campaign_stret_view') || 'Street View';
const client_probing_pid = tlang('client_probing_pid') || 'PID';
const label_layer = tlang('label_layer') || 'Layer';
const client_probing_Layer_Length = tlang('client_probing_Layer_Length') || 'Layer Length';
const search_text = tlang('search_text') || 'Search';
const client_campaign_name = tlang('client_campaign_name') || 'Campaign Name';
const free_licence = tlang('free_licence') || 'Free licence';
const sure_to_free_licence = tlang('sure_to_free_licence') || 'Are you sure to Free this licence';
const btn_yes = tlang('btn_yes') || 'Yes';
const topbar_link_logout = tlang('topbar_link_logout') || 'Logout';
const client_profile_select_licence_validate =
	tlang('client_profile_select_licence_validate') || 'Please Select Licence';
const assign_licence_modal_title = tlang('assign_licence_modal_title') || 'Assign licence';
const assign_licence_modal_licence = tlang('assign_licence_modal_licence') || 'Licence';
const client_profile_licence_id = tlang('client_profile_licence_id') || 'Licence Id';
const client_profile_price = tlang('client_profile_price') || 'Price';
const client_profile_expiry_date = tlang('client_profile_expiry_date') || 'Expiry Date';
const client_profile_action = tlang('client_profile_action') || 'Actions';
const client_profile_status = tlang('client_profile_status') || 'Status';
const client_profile_active_licence_details =
	tlang('client_profile_active_licence_details') || 'Active Licence Details';
const client_renew_btn = tlang('client_renew_btn') || 'Renew';
const client_renew_licence_duration =
	tlang('client_renew_licence_duration') || 'Renew Licence Duration';
const client_select_month = tlang('client_select_month') || 'Select Month';
const client_month = tlang('client_month') || 'Month';
const dashboard_select = tlang('dashboard_select') || 'Select';
const signup_wizard_back = tlang('signup_wizard_back') || 'Back';

const profile_no_of_licence = tlang('profile_no_of_licence') || 'No of Licence';
const licence_name = tlang('client_modal_licence_name') || 'Licence Name';
const client_total_price = tlang('client_total_price') || 'Total Price';
const client_licence_duration = tlang('client_licence_duration') || 'Licence Duration';
const add_new_licences = tlang('add_new_licences') || 'Add New Licences';

const add_new_tokens = tlang('add_new_tokens') || 'Add New Tokens';
const client_token_name = tlang('client_token_name') || 'Token Name';
const client_no_of_token = tlang('client_no_of_token') || 'No of Token';
const remove_from_project = tlang('remove_from_project') || 'Remove from project';
const submit_btn = tlang('submit_btn') || 'Submit';

const client_personal_documents = tlang('client_personal_documents') || 'Personal Documents';
const client_shared_documents = tlang('client_shared_documents') || 'Shared Documents';
const company_documents = tlang('company_documents') || 'Company Documents';
const client_project_documents = tlang('client_project_documents') || 'Project Documents';
const lbl_sure_to_remove = tlang('lbl_sure_to_remove') || 'Are you sure to remove member';
const lbl_from_project = tlang('lbl_from_project') || 'from project';
const profile_mobile_phone = tlang('profile_mobile_phone') || 'Mobile Phone';
const profile_pls_add_phone_number = tlang('profile_pls_add_phone_number') || 'Please Enter Valid Mobile Number';

export default {
	addMemberModal: function (modalData) {
		const { t } = modalData.props;

		return (
			<Modal
				isOpen={modalData.state.addMemberModal}
				toggle={() => modalData.toggleCreateMember()}
				className={`modal-dialog--success modal-dialog--header`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={modalData.toggleCreateMember}
					/>
					<h4 className="bold-text  modal__title">{add_member}</h4>
				</div>
				<div className="modal__body">
					<span>
						<Row>
							<Col md={12} lg={12} className="theme-light">
								<TextField
									required
									fullWidth
									error={modalData.state.error.includes('name')}
									className="material-form__field"
									label={client_profile_name}
									value={modalData.state.addNewMember.name}
									onChange={modalData.handleChangeAddMember('name')}
									margin="normal"
								/>
								{modalData.state.error.includes('name') && (
									<span class="error-message">{client_name_validate}</span>
								)}
							</Col>
							<Col md={12} lg={12} className="theme-light">
								<div className="form__form-group">
									<TextField
										required
										fullWidth
										error={modalData.state.error.includes('email')}
										className="material-form__field"
										label={client_profile_email}
										value={modalData.state.addNewMember.email}
										onChange={modalData.handleChangeAddMember('email')}
										margin="normal"
									/>
									{modalData.state.error.includes('email') && (
										<span class="error-message">{create_update_staff_modal_pls_enter_email}</span>
									)}
								</div>
							</Col>
							<Col md={12} lg={12} className="theme-light">
								<div className="form__form-group margin-top-30">
									<PhoneInput
										defaultCountry="FR"
										error={modalData.state.error.includes(
											"contact_number"
										)}
										placeholder={profile_mobile_phone}
										value={modalData.state.contact_number}
										onChange={modalData.handleChangeAddMember('contact_number')}
										className={`phoneInput ${modalData.state.error.includes("contact_number") ? "text-danger" : ""}`}
									/>
									{modalData.state.error.includes("contact_number") && (
										<span class="error-message">
											{profile_pls_add_phone_number}
										</span>
									)}
								</div>
							</Col>
							<Col md={12} lg={12} className="theme-light">
								<TextField
									required
									error={modalData.state.error.includes('role')}
									select
									id="standard-select-currency"
									SelectProps={{
										MenuProps: {
											className: 'select-menu-width',
										},
									}}
									label={client_add_member_expected_role}
									className="material-form__field full__width"
									value={modalData.state.addNewMember.role}
									InputLabelProps={{ shrink: modalData.state.addNewMember.role ? true : false }}
									onChange={modalData.handleChangeAddMember('role')}
									margin="normal"
								>
									{modalData.state.roleList
										.filter(option => option.roleid != 'AXD_RL_02')
										.map(option => (
											<MenuItem key={option.rolename} value={option}>
												{option.rolename}
											</MenuItem>
										))}
								</TextField>
								{modalData.state.error.includes('role') && (
									<span class="error-message">{client_project_pls_enter_role}</span>
								)}
							</Col>
						</Row>
					</span>
				</div>
				<ButtonToolbar className="modal__footer">
					<Button
						color={'success'}
						onClick={() => modalData.registerNewMember()}
						disabled={modalData.state.loading}
					>
						{signup_wizard_save}
						{modalData.state.loading ? (
							<Spinner size="sm" style={{ marginLeft: '10px', color: '#fff' }} />
						) : null}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	deleteMemberModal: function (modalData) {
		const { t } = modalData.props;

		let memberName = modalData.state.memberName;

		return (
			<Modal
				isOpen={modalData.state.deleteModal}
				toggle={() => modalData.toggleDeleteModal()}
				className={`modal-dialog--success modal-dialog--header`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={modalData.toggleDeleteModal}
					/>
					<h4 className="bold-text  modal__title">{delete_member_modal_title}</h4>
				</div>
				<div className="modal__body">
					{delete_member_modal_message + ' ( ' + memberName + ' ) ?'}
				</div>
				<ButtonToolbar className="modal__footer">
					<Button color={'success'} outline={true} onClick={() => modalData.toggleDeleteModal()}>
						{cancel_btn}
					</Button>
					<Button
						color={'success'}
						onClick={() => modalData.deleteMember()}
						disabled={modalData.state.loading}
					>
						{delete_btn}
						{modalData.state.loading && <Spinner size="sm" className="btn-spinner" />}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	acceptInviteModal: function (modalData) {
		const { t } = modalData.props;

		return (
			<Modal
				isOpen={modalData.state.acceptModal}
				toggle={() => modalData.toggleAcceptModal()}
				className={`modal-dialog--success modal-dialog--header`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={modalData.toggleAcceptModal}
					/>
					<h4 className="bold-text  modal__title">{accept_invite_modal_title}</h4>
				</div>
				<div className="modal__body">
					<Row>
						<Col md={8} lg={8} className="theme-light">
							<TextField
								error={modalData.state.error.includes('user')}
								select
								required
								id="standard-select-currency"
								SelectProps={{
									MenuProps: {
										className: 'select-menu-width',
									},
								}}
								label={accept_invite_modal_user_member}
								className="material-form__field full__width"
								value={modalData.state.acceptData.user}
								onChange={modalData.handleAcceptData('user')}
							>
								{modalData.props.memberReducer.requestMemberList &&
									modalData.props.memberReducer.requestMemberList.map(option => (
										<MenuItem key={option.rolename} value={option}>
											{option.name}({option.email})
										</MenuItem>
									))}
							</TextField>
							{modalData.state.error.includes('user') && (
								<span class="error-message">{accept_invite_modal_pls_add_user}</span>
							)}
						</Col>
					</Row>
				</div>
				<ButtonToolbar className="modal__footer">
					<Button color={'success'} outline={true} onClick={() => modalData.toggleAcceptModal()}>
						{cancel_btn}
					</Button>
					<Button
						color={'success'}
						onClick={() => modalData.approveProjectRequest()}
						disabled={modalData.state.loading}
					>
						{accept_btn} {modalData.state.loading && <Spinner size="sm" className="btn-spinner" />}{' '}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	rejectInviteModal: function (modalData) {
		const { t } = modalData.props;

		return (
			<Modal
				isOpen={modalData.state.declineModal}
				toggle={() => modalData.toggleDeclineModal()}
				className={`modal-dialog--success modal-dialog--header`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={modalData.toggleDeclineModal}
					/>
					<h4 className="bold-text  modal__title">{reject_invite_modal_title}</h4>
				</div>
				<div className="modal__body">{reject_invite_modal_message}</div>
				<ButtonToolbar className="modal__footer">
					<Button color={'success'} outline={true} onClick={() => modalData.toggleDeclineModal()}>
						{cancel_btn}
					</Button>
					<Button
						color={'success'}
						onClick={() => modalData.rejectProjectRequest()}
						disabled={modalData.state.loading}
					>
						{delete_btn} {modalData.state.loading && <Spinner size="sm" className="btn-spinner" />}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	projectRequest: function (modalData) {
		const { t } = modalData.props;

		return (
			<Modal
				isOpen={modalData.state.projectRequestModal}
				toggle={() => modalData.createNewProject()}
				className={`modal-dialog--success modal-dialog--header`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={modalData.createNewProject}
					/>
					<h4 className="bold-text  modal__title">{project_request}</h4>
				</div>
				<div className="modal__body">{project_request_do_you_want_msg}</div>
				<ButtonToolbar className="modal__footer">
					<Button
						color={'success'}
						outline={true}
						onClick={() => modalData.saveProject('no')}
						disabled={modalData.state.loading}
					>
						{common_btn_no}
						{modalData.state.loading && <Spinner size="sm" className="btn-spinner" />}
					</Button>
					<Button
						color={'success'}
						onClick={() => modalData.saveProject('yes')}
						disabled={modalData.state.saveProjectloading}
					>
						{common_btn_yes}
						{modalData.state.saveProjectloading && <Spinner size="sm" className="btn-spinner" />}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	sendMessageModal: function (modalData) {
		return (
			<Modal
				isOpen={modalData.state.sendMessagePopup}
				toggle={() => modalData.sendMessagePopupClose()}
				className={`modal-dialog--success modal-dialog--header width__50`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={() => modalData.sendMessagePopupClose()}
					/>
					<h4 className="bold-text  modal__title">
						{send_btn} {message_txt} To
					</h4>
				</div>
				<div className="modal__body" style={{ paddingTop: 0 }}>
					<Row>
						<Col xs={12}>
							<TextField
								multiline
								rows={4}
								fullWidth
								id="bodyEmail"
								style={{ marginTop: '21px' }}
								label={message_txt}
								value={modalData.state.bodyEmail}
								onChange={modalData.handleChange('bodyEmail')}
								error={modalData.state.error.includes('bodyEmail')}
							/>
							{modalData.state.error.includes('bodyEmail') && (
								<span className="error-message">{'This field is required'}</span>
							)}
						</Col>
					</Row>
				</div>
				<ButtonToolbar className="modal__footer">
					<Button color={'success'} onClick={() => modalData.sendMessageToUser()}>
						{send_btn}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	requestLicenceModal: function (modalData) {
		const { t } = modalData.props;
		let renewMonths = modalData.state.renewMonths;
		let allLicenceList = modalData.state.allLicenceList;
		let totalPrice = 0;
		allLicenceList.map(l => {
			totalPrice += l.amount * l.cost * l.month;
		});

		return (
			<Modal
				isOpen={modalData.state.requestLicenceModal}
				toggle={() => modalData.toggleRequestLicence()}
				className={`modal-dialog--success modal-dialog--header width__70`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={modalData.toggleRequestLicence}
					/>
					<h4 className="bold-text  modal__title">{add_new_licences}</h4>
				</div>
				<div className="modal__body">
					<Row>
						<Col md={3} xl={3} sm={3}>
							<label style={{ marginLeft: '18%', color: '#E64823' }}>{licence_name}</label>
						</Col>
						<Col md={2} xl={2} sm={2}>
							<label style={{ marginLeft: '23%', color: '#E64823' }}>{profile_no_of_licence}</label>
						</Col>
						<Col md={4} xl={4} sm={4}>
							<label style={{ marginLeft: '5%', color: '#E64823' }}>
								{client_licence_duration}
							</label>
						</Col>
						<Col md={3} xl={3} sm={3}>
							<label style={{ color: '#E64823' }}>{client_profile_price}</label>
						</Col>
					</Row>
					{modalData.state.allLicenceList.map((row, index) => {
						return (
							<Row key={index} className="licence-list" style={{ border: '1px solid #dddddd' }}>
								<Col md={3} xl={3} sm={3}>
									{row.licencename}
								</Col>
								<Col md={2} xl={2} sm={2}>
									<Button
										onClick={() => modalData.removeLicenceAmount(index)}
										className={'system-btn clear-border'}
										outline={true}
									>
										<Icon>remove</Icon>
									</Button>
									<span className="mr-3">{row.amount ? row.amount : 0}</span>
									<Button
										onClick={() => modalData.addLicenceAmount(index)}
										className={'system-btn clear-border'}
										outline={true}
									>
										<Icon>add</Icon>
									</Button>
								</Col>
								<Col md={4} xl={4} sm={4}>
									<Row>
										<Col md={8} lg={8} className="theme-light">
											<TextField
												select
												required
												id="standard-select-currency"
												SelectProps={{
													MenuProps: {
														className: 'select-menu-width',
													},
												}}
												style={{ width: '100%' }}
												label={client_select_month}
												className="material-form__field"
												value={row.month}
												//onChange={modalData.handleRenewLicence('selMonth')}
												onChange={event => modalData.addLicenceMonth(index, event)}
												error={modalData.state.error.includes('selMonth')}
											//InputLabelProps={{ shrink: modalData.state.acceptLicence.user ? true : false }}
											>
												{renewMonths &&
													renewMonths.map(option => (
														<MenuItem key={option} value={option}>
															{option}
														</MenuItem>
													))}
											</TextField>
										</Col>
									</Row>
								</Col>
								<Col md={3} xl={3} sm={3}>
									${row.cost}/{signup_wizard_month} ( {row.month} {signup_wizard_month} ) = $
									{row.amount ? row.cost * row.amount * row.month : 0}
								</Col>
							</Row>
						);
					})}
					<Row style={{ paddingTop: '2%' }}>
						<Col md={3} xl={3} sm={3}></Col>
						<Col md={2} xl={2} sm={2}></Col>
						<Col md={4} xl={4} sm={4}></Col>
						<Col md={3} xl={3} sm={3}>
							<label style={{ marginLeft: '4rem', color: '#E64823' }}>
								{client_total_price}
								{' = '}
								{'$' + totalPrice}
							</label>
						</Col>
					</Row>
				</div>
				<ButtonToolbar className="modal__footer">
					<span style={{ color: 'red', margin: '0 auto' }}>
						{modalData.state.licenceWarning != '' ? modalData.state.licenceWarning : ''}
					</span>

					<Button color={'success'} outline={true} onClick={() => modalData.toggleRequestLicence()}>
						{cancel_btn}
					</Button>
					<Button
						color={'success'}
						onClick={() => modalData.requestLicence()}
						disabled={modalData.state.loading}
					>
						{client_send_btn}{' '}
						{modalData.state.loading && <Spinner size="sm" className="btn-spinner" />}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	requestTokenModalNew: function (modalData) {
		const { t } = modalData.props;
		let allTokenList = modalData.state.allTokenList;
		let totalPrice = 0;
		allTokenList.map(l => {
			totalPrice += l.amount * l.cost;
		});

		return (
			<Modal
				isOpen={modalData.state.requestTokenModalNew}
				toggle={() => modalData.toggleRequestTokenNew()}
				className={`modal-dialog--success modal-dialog--header width__70`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={modalData.toggleRequestTokenNew}
					/>
					<h4 className="bold-text  modal__title">{add_new_tokens}</h4>
				</div>
				<div className="modal__body">
					<Row>
						<Col md={3} xl={3} sm={3}>
							<label style={{ marginLeft: '18%', color: '#E64823' }}>{client_token_name}</label>
						</Col>
						<Col md={3} xl={3} sm={3}>
							<label style={{ marginLeft: '23%', color: '#E64823' }}>{client_no_of_token}</label>
						</Col>
						<Col md={3} xl={3} sm={3}>
							<label style={{ color: '#E64823' }}>{client_profile_price}</label>
						</Col>
					</Row>
					{modalData.state.allTokenList.map((row, index) => {
						return (
							<Row key={index} className="licence-list" style={{ border: '1px solid #dddddd' }}>
								<Col md={3} xl={3} sm={3}>
									{row.tokenName}
								</Col>
								<Col md={3} xl={3} sm={3}>
									<Button
										onClick={() => modalData.removeTokenAmount(index)}
										className={'system-btn clear-border'}
										outline={true}
									>
										<Icon>remove</Icon>
									</Button>
									<span className="mr-3">{row.amount ? row.amount : 0}</span>
									<Button
										onClick={() => modalData.addTokenAmount(index)}
										className={'system-btn clear-border'}
										outline={true}
									>
										<Icon>add</Icon>
									</Button>
								</Col>
								<Col md={3} xl={3} sm={3}>
									${row.amount ? row.cost * row.amount : 0}
								</Col>
							</Row>
						);
					})}
					<Row style={{ paddingTop: '2%' }}>
						<Col md={3} xl={3} sm={3}></Col>
						<Col md={2} xl={2} sm={2}></Col>
						<Col md={3} xl={3} sm={3}>
							<label style={{ marginLeft: '1rem', color: '#E64823' }}>
								{client_total_price}
								{' = '}
								{'$' + totalPrice}
							</label>
						</Col>
						<Col md={3} xl={3} sm={3}></Col>
					</Row>
				</div>
				<ButtonToolbar className="modal__footer">
					<span style={{ color: 'red', margin: '0 auto' }}>
						{modalData.state.tokenWarning != '' ? modalData.state.tokenWarning : ''}
					</span>

					<Button
						color={'success'}
						outline={true}
						onClick={() => modalData.toggleRequestTokenNew()}
					>
						{cancel_btn}
					</Button>
					<Button
						color={'success'}
						onClick={() => modalData.requestTokenNew()}
						disabled={modalData.state.loading}
					>
						{client_send_btn}{' '}
						{modalData.state.loading && <Spinner size="sm" className="btn-spinner" />}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	requestTokenModal: function (modalData) {
		const { t } = modalData.props;
		let projectToken = modalData.state.projectToken;

		return (
			<Modal
				isOpen={modalData.state.requestTokenModal}
				toggle={() => modalData.toggleRequestToken()}
				className={`modal-dialog--success modal-dialog--header`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={modalData.toggleRequestToken}
					/>
					<h4 className="bold-text  modal__title">{profile_token_request}</h4>
				</div>
				<div className="modal__body">
					<Row className="licence-list" style={{ textAlign: 'center' }}>
						<Col md={6} xl={6} sm={6}>
							{profile_token_count}
						</Col>
						<Col md={6} xl={6} sm={6}>
							{profile_price}
						</Col>
						<Col md={6} xl={6} sm={6}>
							<Button
								onClick={() =>
									projectToken.tokenCount && projectToken.tokenCount > 0 && modalData.removeToken()
								}
								className={'system-btn clear-border'}
								outline={true}
								style={{ marginLeft: '10px' }}
							>
								<Icon>remove</Icon>
							</Button>
							<span className="mr-3">{projectToken.tokenCount ? projectToken.tokenCount : 0}</span>
							<Button
								onClick={() => modalData.addToken()}
								className={'system-btn clear-border'}
								outline={true}
							>
								<Icon>add</Icon>
							</Button>
						</Col>
						<Col md={6} xl={6} sm={6}>
							{projectToken.price ? projectToken.price : 0}
							{'$'}
						</Col>
					</Row>
				</div>
				<ButtonToolbar className="modal__footer">
					<span style={{ color: 'red', margin: '0 auto' }}>
						{modalData.state.tokenWarning != '' ? modalData.state.tokenWarning : ''}
					</span>

					<Button color={'success'} outline={true} onClick={() => modalData.toggleRequestToken()}>
						{cancel_btn}
					</Button>
					<Button
						color={'success'}
						onClick={() => modalData.requestToken()}
						disabled={modalData.state.loading}
					>
						{common_btn_send}{' '}
						{modalData.state.loading && <Spinner size="sm" className="btn-spinner" />}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	renewLicenceModal: function (modalData) {
		let renewMonths = modalData.state.renewMonths;

		return (
			<Modal
				isOpen={modalData.state.renewLicenceModal}
				toggle={() => modalData.toggleRenewLicenceModal()}
				className={`modal-dialog--success modal-dialog--header`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={modalData.toggleRenewLicenceModal}
					/>
					<h4 className="bold-text  modal__title">
						{client_renew_licence_duration}
						{' - '}
						{modalData.state.selRenewLicDet.licencename}
					</h4>
				</div>
				<div className="modal__body">
					<Row>
						<Col md={7} lg={7} className="theme-light">
							<TextField
								select
								required
								id="standard-select-currency"
								SelectProps={{
									MenuProps: {
										className: 'select-menu-width',
									},
								}}
								style={{ width: '100%' }}
								label={client_select_month}
								className="material-form__field"
								value={modalData.state.selRenewLicDet.selMonth}
								onChange={modalData.handleRenewLicence('selMonth')}
								error={modalData.state.error.includes('selMonth')}
							//InputLabelProps={{ shrink: modalData.state.acceptLicence.user ? true : false }}
							>
								{renewMonths &&
									renewMonths.map(option => (
										<MenuItem key={option} value={option}>
											{option}
										</MenuItem>
									))}
							</TextField>
							{modalData.state.error.includes('user') && (
								<span class="error-message">{assign_licence_request_modal_pls_enter_user}</span>
							)}
						</Col>
						<Col md={5} lg={5} className="theme-light">
							<div>
								{client_profile_price} : {'$' + modalData.state.selRenewLicDet.totalCost} /{' '}
								{modalData.state.selRenewLicDet.selMonth + ' ' + client_month}
							</div>
						</Col>
					</Row>
				</div>

				<ButtonToolbar className="modal__footer">
					<Button
						color={'success'}
						outline={true}
						onClick={() => modalData.toggleRenewLicenceModal()}
					>
						{cancel_btn}
					</Button>
					<Button
						color={'success'}
						onClick={() => modalData.renewActiveLicence()}
						disabled={modalData.state.loading}
					>
						{accept_btn} {modalData.state.loading && <Spinner size="sm" className="btn-spinner" />}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	assignLicenceModal: function (modalData) {
		const { t } = modalData.props;

		//If user is not admin then only display his email in the assign licence modal
		let companyMembers = modalData.props.memberReducer.memberList;
		let isadmin =
			modalData.props.authReducer.userData.email == modalData.props.authReducer.userData.owner
				? true
				: false;
		if (isadmin == false) {
			companyMembers = companyMembers.filter(
				l => l.email == modalData.props.authReducer.userData.email
			);
		}

		return (
			<Modal
				isOpen={modalData.state.assignLicenceModal}
				toggle={() => modalData.toggleAssignLicenceModal()}
				className={`modal-dialog--success modal-dialog--header`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={modalData.toggleLicenceAssign}
					/>
					<h4 className="bold-text  modal__title">{assign_licence_request_modal_title}</h4>
				</div>
				<div className="modal__body">
					<Row>
						{/* <Col md={4} lg={4} className="theme-light">
                            <TextField
                                select
                                required
                                id="standard-select-currency"
                                SelectProps={{
                                    MenuProps: {
                                        className: "select-menu-width",
                                    },
                                }}
                                style={{ width: "100%" }}
                                label={assign_licence_request_modal_project}
                                className="material-form__field"
                                value={modalData.state.acceptLicence.project}
                                InputLabelProps={{ shrink: modalData.state.acceptLicence.project ? true : false }}
                                onChange={modalData.handleAssignLicenceData('project')}
                                error={modalData.state.error.includes('project')}
                            >
                                {modalData.props.masterReducer.activeProjcetList && modalData.props.masterReducer.activeProjcetList.filter(p=>p.isclosed==false).map(option => (
                                    <MenuItem key={option.displayname} value={option}>
                                        {option.displayname} {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {modalData.state.error.includes("project") &&
                                <span class='error-message'>{assign_licence_request_modal_pls_enter_project}</span>
                            }
                        </Col> */}
						<Col md={8} lg={8} className="theme-light">
							<TextField
								select
								required
								id="standard-select-currency"
								SelectProps={{
									MenuProps: {
										className: 'select-menu-width',
									},
								}}
								style={{ width: '100%' }}
								label={assign_licence_request_modal_user}
								className="material-form__field"
								value={modalData.state.acceptLicence.user}
								onChange={modalData.handleAssignLicenceData('user')}
								error={modalData.state.error.includes('user')}
								InputLabelProps={{ shrink: modalData.state.acceptLicence.user ? true : false }}
							>
								{companyMembers &&
									companyMembers.map(option => (
										<MenuItem key={option.role_name} value={option}>
											{option.name} ( {option.email} )
										</MenuItem>
									))}
							</TextField>
							{modalData.state.error.includes('user') && (
								<span class="error-message">{assign_licence_request_modal_pls_enter_user}</span>
							)}
						</Col>

						{/* <Col md={4} lg={4} className="theme-light">
                            <TextField
                                select
                                required
                                id="standard-select-currency"
                                SelectProps={{
                                    MenuProps: {
                                        className: "select-menu-width",
                                    },
                                }}
                                fullWidth
                                label={assign_licence_request_modal_role}
                                className="material-form__field"
                                value={modalData.state.acceptLicence.role}
                                onChange={modalData.handleAssignLicenceData('role')}
                                error={modalData.state.error.includes('role')}
                                InputLabelProps={{ shrink: modalData.state.acceptLicence.role ? true : false }}
                            >
                                {modalData.props.masterReducer.roleList.map(option => (
                                    <MenuItem key={option.rolename} value={option}>
                                        {option.rolename}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {modalData.state.error.includes("role") &&
                                <span class='error-message'>{assign_licence_request_modal_pls_enter_role}</span>
                            }
                        </Col> */}
					</Row>
				</div>

				<ButtonToolbar className="modal__footer">
					<Button color={'success'} outline={true} onClick={() => modalData.toggleLicenceAssign()}>
						{cancel_btn}
					</Button>
					<Button
						color={'success'}
						onClick={() => modalData.approveAssignLicence()}
						disabled={modalData.state.loading}
					>
						{accept_btn} {modalData.state.loading && <Spinner size="sm" className="btn-spinner" />}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	requestPlanModal: function (modalData) {
		const { t } = modalData.props;

		let isplanexp = modalData.props.authReducer.userData.isplanexp;

		let icons = [
			`${process.env.PUBLIC_URL}/img/pricing_cards/004-paper-plane.svg`,
			`${process.env.PUBLIC_URL}/img/pricing_cards/001-rocket.svg`,
			`${process.env.PUBLIC_URL}/img/pricing_cards/003-airplane.svg`,
			`${process.env.PUBLIC_URL}/img/pricing_cards/002-hot-air-balloon.svg`,
		];

		let renewMonths = modalData.state.renewMonths;

		const marginTop30 = {
			marginTop: '30%',
		};

		return (
			<Modal
				isOpen={modalData.state.requestPlanModal}
				toggle={isplanexp == false ? () => modalData.toggleRequestPlan() : ''}
				className={`modal-dialog--success modal-dialog--header width__80`}
			>
				{modalData.props.authReducer.userData.company_name != '["superadmin"]' && (
					<>
						<div className="modal__header">
							{isplanexp == false && (
								<button
									className="lnr lnr-cross modal__close-btn"
									type="button"
									onClick={modalData.toggleRequestPlan}
								/>
							)}
							{isplanexp && (
								<Button
									style={{ position: 'absolute', right: '10px', top: '5px' }}
									color={'success'}
									onClick={modalData.logoutUser}
								>
									{topbar_link_logout}
								</Button>
							)}
							<h4 className="bold-text  modal__title">{plan_details}</h4>
						</div>
						<div className="modal__body">
							<Row>
								{modalData.props.masterReducer.planList.map((plan, index) => {
									return (
										<>
											{(modalData.state.plan == null || modalData.state.plan == index) && (
												<>
													<Col xl={3} md={6} sm={6}>
														<Card className="pricing-card">
															<CardBody
																className="pricing-card__body"
																style={{ paddingTop: '25px' }}
															>
																<img className="pricing-card__img" src={icons[index]} alt="" />
																<h3 className="pricing-card__plan">{plan.planname}</h3>
																<hr />
																<p className="pricing-card__price">
																	${plan.price}
																	<span>/{signup_wizard_mo}</span>
																</p>
																<p className="pricing-card__feature">
																	{plan.usersize} {signup_wizard_users}
																</p>
																<p className="pricing-card__feature">
																	{plan.uploadsize} {signup_wizard_gb_storage}
																</p>
																<p className="pricing-card__feature">
																	{signup_wizard_monthly_update}
																</p>
																<p className="pricing-card__feature">
																	{signup_wizard_free_support}
																</p>
																{modalData.state.plan == null &&
																	modalData.props.authReducer.userData.plan.planid !==
																	plan.planid && (
																		<Button
																			onClick={() => modalData.choosePlan(index)}
																			className="pricing-card__button"
																			color="success"
																		>
																			{dashboard_select}
																		</Button>
																	)}
																{modalData.state.plan == null &&
																	modalData.props.authReducer.userData.plan.planid ==
																	plan.planid && (
																		<Button
																			onClick={() => modalData.choosePlan(index)}
																			className="pricing-card__button"
																			color="success"
																		>
																			{signup_wizard_renew_plan}
																		</Button>
																	)}
																{modalData.state.plan != null && (
																	<Button
																		onClick={() => modalData.choosePlan(null)}
																		className="pricing-card__button"
																		outline={true}
																		color="success"
																	>
																		{signup_wizard_back}
																	</Button>
																)}

																{modalData.state.plan != null && (
																	<Button
																		style={{ padding: '10px 23px', marginTop: '5%' }}
																		onClick={modalData.requestPlan}
																		color="success"
																		disabled={modalData.state.loading}
																	>
																		{signup_wizard_confirm_next}{' '}
																		{modalData.state.loading && (
																			<Spinner size="sm" className="btn-spinner" />
																		)}
																	</Button>
																)}
															</CardBody>
														</Card>
													</Col>
													{modalData.state.plan == index ? (
														<Col xl={9} md={9} sm={9}>
															<Row style={marginTop30}>
																<Col md={3} lg={3} className="theme-light">
																	<TextField
																		select
																		required
																		id="standard-select-currency"
																		SelectProps={{
																			MenuProps: {
																				className: 'select-menu-width',
																			},
																		}}
																		style={{ width: '100%' }}
																		label={client_select_month}
																		className="material-form__field"
																		value={modalData.state.selectedMonth}
																		onChange={e => modalData.handleChangeInfo('selectedMonth', e)}
																		error={modalData.state.error.includes('selectedMonth')}
																	//InputLabelProps={{ shrink: modalData.state.acceptLicence.user ? true : false }}
																	>
																		{renewMonths &&
																			renewMonths.map(option => (
																				<MenuItem key={option} value={option}>
																					{option}
																				</MenuItem>
																			))}
																	</TextField>
																	{modalData.state.error.includes('user') && (
																		<span class="error-message">
																			{assign_licence_request_modal_pls_enter_user}
																		</span>
																	)}
																</Col>
																<Col md={5} lg={5} className="theme-light">
																	<div>
																		{client_profile_price} :{' '}
																		{'$' + plan.price * modalData.state.selectedMonth} /{' '}
																		{modalData.state.selectedMonth + ' ' + client_month}
																	</div>
																</Col>
															</Row>
														</Col>
													) : null}
												</>
											)}
										</>
									);
								})}
							</Row>
						</div>
						<ButtonToolbar className="modal__footer">
							{isplanexp == false && (
								<Button
									outline={true}
									color={'success'}
									onClick={() => modalData.toggleRequestPlan()}
								>
									{cancel_btn}
								</Button>
							)}
							{/* {modalData.state.plan != null &&
                        <Button onClick={modalData.requestPlan} color="success" disabled={modalData.state.loading}>{signup_wizard_confirm_next} {modalData.state.loading && <Spinner size="sm" className="btn-spinner" />}</Button>
                    } */}
							{/* <Button color={'success'} onClick={() => modalData.requestLicence()}>{"Send"}</Button> */}
						</ButtonToolbar>
					</>
				)}
			</Modal>
		);
	},
	memberDetailsModal: function (modalData) {
		const { t } = modalData.props;
		const { files } = modalData.state;

		const columns = [
			{ name: member_details_request_modal_sr_no },
			{ name: member_details_request_modal_project_name },
			{ name: member_details_request_modal_company_name },
			{ name: member_details_request_modal_project_status },
			{ name: member_details_request_modal_role },
			// { name: member_details_request_modal_licence },
			{
				name: member_details_request_modal_action,
				options: {
					filter: false,
					customBodyRender: value => {
						// let licence_type_free = 'none';
						// if(value.isclosed && value.companylicenceid == ""){ // close and no licence
						//   licence_type_free = 'none';
						// }else if(value.isclosed && value.companylicenceid){ // close and licence
						//   licence_type_free = true
						// }else if(!value.isclosed && value.companylicenceid){ // open and licence
						//   licence_type_free = true
						// }else if(!value.isclosed && value.companylicenceid == ""){ // open and no licence
						//   licence_type_free = false
						// }
						return (
							<>
								{value && (
									<>
										{/* {modalData.state.memberIsOwner == false &&  */}
										{typeof modalData.state.memberDetailForView.role_id != 'undefined' &&
											modalData.state.memberDetailForView.role_id != '' &&
											(
												modalData.state.memberDetailForView.role_id != 'AXD_RL_01' //Company Owner can able to remove his company members from company projects
												||
												modalData.state.memberDetailForView.company_name !== value.company_name //Company Owner can remove his company members OR remove himself from Other Invited Company projects
											)

											&& (
												<Button
													className={'system-btn clear-border'}
													outline={true}
													color={'success'}
													onClick={() => modalData.toggleDeleteInvitedMemberModal(value)}
												>
													<Tooltip title={remove_from_project}>
														<Icon>logout</Icon>
													</Tooltip>
												</Button>
											)}
										{/* {licence_type_free != 'none' && (
                                                (
                                                    <>
                                                        {licence_type_free ?
                                                            (
                                                                <ButtonToolbar>
                                                                    <Button
                                                                    outline={true}
                                                                    color={"success"}
                                                                    onClick={() => modalData.tooglefreeLicenceModal(value)}
                                                                    style={{ padding: "5px 15px" }}
                                                                    >
                                                                    {free_licence}
                                                                    </Button>
                                                                </ButtonToolbar>
                                                            )    
                                                        :
                                                        (
                                                            <Button
                                                                outline={true}
                                                                color={"success"}
                                                                onClick={() => modalData.toogleAssignLicenceForProjectModal(value)}
                                                                style={{ padding: "5px 15px" }}
                                                            >
                                                                {assign_licence_btn}
                                                            </Button>
                                                        )}
                                                    </>
                                                )
                                            )} */}
									</>
								)}
							</>
						);
					},
				},
			},
		];

		const options = {
			filterType: 'checkbox',
			selectableRows: 'none',
			print: false,
			download: false,
			viewColumns: false,
			filter: false,
			responsive: 'stacked',
			pagination: false,
			search: false,
			textLabels: {
				body: {
					noMatch: no_match_record_found,
					toolTip: sort_text,
				},
				pagination: {
					rowsPerPage: rows_per_page_text,
				},
				toolbar: {
					search: search_text,
				},
			},
		};

		return (
			<Modal
				isOpen={modalData.state.memberDetailModal}
				toggle={() => modalData.closeMemberDetails()}
				className={`modal-dialog--success modal-dialog--header width__70`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={modalData.closeMemberDetails}
					/>
					<h4 className="bold-text  modal__title">{member_details_request_modal_title} - {modalData.state.memberDetailForView.name}</h4>
				</div>
				<div className="modal__body">
					<Row>
						<Col xs={12} sm={12} md={12} style={{ marginTop: '40px' }}>
							<MUIDataTable
								data={modalData.renderMemberDetails()}
								columns={columns}
								options={options}
							/>
						</Col>
					</Row>
				</div>
				<ButtonToolbar className="modal__footer">
					<Button color={'success'} outline={true} onClick={() => modalData.closeMemberDetails()}>
						{common_btn_close}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	memberLicenceDetailsModal: function (modalData) {
		const { t } = modalData.props;
		const isDisabled = freePlan.includes(modalData.props.authReducer.userData.plan.planid)
			? true
			: false;
		const optionsActiveLicences = {
			filterType: 'checkbox',
			selectableRows: 'none',
			print: false,
			download: false,
			viewColumns: false,
			filter: false,
			search: false,
			responsive: 'stacked',
			textLabels: {
				body: {
					noMatch: no_match_record_found,
					toolTip: sort_text,
				},
				pagination: {
					rowsPerPage: rows_per_page_text,
				},
				toolbar: {
					search: search_text,
				},
			},
		};

		const ActiveLicences = [
			{
				name: client_profile_licence_id,
			},
			{
				name: client_profile_licence_code,
			},
			{
				name: client_profile_name,
			},
			{
				name: client_profile_price,
			},
			{
				name: client_profile_expiry_date,
			},
			{
				name: client_profile_status,
			},
			{
				name: client_profile_action,
				options: {
					filter: false,
					customBodyRender: value => {
						if (moment() < moment.unix(value.expirydate)) {
							return (
								<>
									<Button
										outline={true}
										className={'add-new-btn'}
										color={'success'}
										disabled={isDisabled}
										onClick={() => modalData.tooglefreeLicenceModal(value)}
									>
										{free_licence}
									</Button>
								</>
							);
						} else {
							return (
								<>
									<Button
										className={'add-new-btn'}
										color={'success'}
										disabled={isDisabled}
										//onClick={() => modalData.renewActiveLicence(value)}
										onClick={() => modalData.toggleRenewLicenceModal(value)}
									>
										{client_renew_btn}
										{modalData.state.loadingRenew[value._id] && (
											<Spinner size="sm" className="btn-spinner" style={{ color: '#ffffff' }} />
										)}
									</Button>
								</>
							);
						}
					},
				},
			},
		];

		return (
			<Modal
				isOpen={modalData.state.memberLicenceDetailModal}
				toggle={() => modalData.closeMemberLicenceDetails()}
				className={`modal-dialog--success modal-dialog--header width__70`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={modalData.closeMemberLicenceDetails}
					/>
					<h4 className="bold-text  modal__title">
						{client_profile_active_licence_details}
						{' - '}
						{modalData.state.memberLicenceDetails.name}
					</h4>
				</div>
				<div className="modal__body">
					<Row>
						<Col xs={12} sm={12} md={12} style={{ marginTop: '40px' }}>
							<MUIDataTable
								data={modalData.renderActiveLicenceDetails()}
								columns={ActiveLicences}
								options={optionsActiveLicences}
							/>
						</Col>
					</Row>
				</div>
				<ButtonToolbar className="modal__footer">
					<Button
						color={'success'}
						outline={true}
						onClick={() => modalData.closeMemberLicenceDetails()}
					>
						{common_btn_close}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	deleteInvitedMemberModal: function (modalData) {
		const { t } = modalData.props;
		const userName = modalData.state.deleteInvitedMemberData.current_user || modalData.state.memberDetailForView?.name;
		const prjName = modalData.state.deleteInvitedMemberData.name || modalData.state.deleteInvitedMemberData.projectname;
		const msg = `${lbl_sure_to_remove} <b>${userName}</b> ${lbl_from_project} <b>${prjName}</b> ?`;
		return (
			<Modal
				isOpen={modalData.state.deleteInvitedMemberModal}
				toggle={() => modalData.toggleCloseInvitedMemberModal()}
				className={`modal-dialog--success modal-dialog--header`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={modalData.toggleCloseInvitedMemberModal}
					/>
					<h4 className="bold-text  modal__title">{remove_from_project}</h4>
				</div>
				<div className="modal__body" dangerouslySetInnerHTML={{ __html: msg }} />
				<ButtonToolbar className="modal__footer">
					<Button
						color={'success'}
						outline={true}
						onClick={() => modalData.toggleCloseInvitedMemberModal()}
					>
						{cancel_btn}
					</Button>
					<Button
						className="disable__btn"
						color={'success'}
						onClick={() => modalData.deleteInvitedMember()}
						disabled={modalData.state.loading}
					>
						{submit_btn} {modalData.state.loading && <Spinner size="sm" className="btn-spinner" />}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	rejectCampaignInviteModal: function (modalData) {
		const { t } = modalData.props;

		return (
			<Modal
				isOpen={modalData.state.declineCampaignInvitesModal}
				toggle={() => modalData.toggleDeclineCampaignInvitesModal()}
				className={`modal-dialog--success modal-dialog--header`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={modalData.toggleDeclineCampaignInvitesModal}
					/>
					<h4 className="bold-text  modal__title">{reject_campaign_invite_request_modal_title}</h4>
				</div>
				<div className="modal__body">{reject_campaign_invite_are_you_sure_msg}</div>
				<ButtonToolbar className="modal__footer">
					<Button
						color={'success'}
						outline={true}
						onClick={() => modalData.toggleDeclineCampaignInvitesModal()}
					>
						{cancel_btn}
					</Button>
					<Button
						color={'success'}
						onClick={() => modalData.rejectCampaignInviteRequest()}
						disabled={modalData.state.loading}
					>
						{delete_btn} {modalData.state.loading && <Spinner size="sm" className="btn-spinner" />}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	acceptCampaignInviteModal: function (modalData) {
		const { t } = modalData.props;

		return (
			<Modal
				isOpen={modalData.state.acceptCampaignInvitesModal}
				toggle={() => modalData.toggleAcceptCampaignInvitesModal(null)}
				className={`modal-dialog--success modal-dialog--header`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={() => modalData.toggleAcceptCampaignInvitesModal(null)}
					/>
					<h4 className="bold-text  modal__title">{accept_Campaign_Invite_Modal_Title}</h4>
				</div>
				<div className="modal__body">
					<Row>
						<Col md={6} lg={6} className="theme-light">
							<TextField
								error={modalData.state.error.includes('user')}
								select
								required
								id="standard-select-currency"
								SelectProps={{
									MenuProps: {
										className: 'select-menu-width',
									},
								}}
								label={accept_invite_modal_user_member}
								className="material-form__field full__width"
								value={modalData.state.acceptCampaignData.user}
								onChange={modalData.handleAcceptCampaignData('user')}
							>
								{modalData.props.memberReducer.requestMemberList &&
									modalData.props.memberReducer.requestMemberList.map(option => (
										<MenuItem key={option.rolename} value={option}>
											{option.name}({option.email})
										</MenuItem>
									))}
							</TextField>
							{modalData.state.error.includes('user') && (
								<span class="error-message">{accept_invite_modal_pls_add_user}</span>
							)}
						</Col>
						{/* {modalData.props.authReducer.userData.plan.planid != "AXD_PL_01" &&
                            <Col md={6} lg={6} className="theme-light">
                                <TextField
                                    error={modalData.state.error.includes("licence")}
                                    select
                                    required
                                    id="standard-select-currency"
                                    SelectProps={{
                                        MenuProps: {
                                            className: "select-menu-width",
                                        },
                                    }}
                                    label={accept_invite_modal_licence}
                                    className="material-form__field full__width"
                                    value={modalData.state.acceptCampaignData.licence}
                                    onChange={modalData.handleAcceptCampaignData('licence')}
                                >
                                    {modalData.state.licenceList.map(option => {
                                            return(
                                                <MenuItem disabled={option.count == 0} key={option.licenceid} value={option}>
                                                    {option.licencename} ({option.count})
                                                </MenuItem>
                                    )})}
                                </TextField>
                                {modalData.state.error.includes("licence") &&
                                    <span class='error-message'>{accept_invite_modal_pls_add_licence}</span>
                                }
                            </Col>
                        } */}
					</Row>
				</div>
				<ButtonToolbar className="modal__footer">
					<Button
						color={'success'}
						outline={true}
						onClick={() => modalData.toggleAcceptCampaignInvitesModal(null)}
					>
						{cancel_btn}
					</Button>
					<Button
						color={'success'}
						onClick={() => modalData.approveCampaignRequest()}
						disabled={modalData.state.loading}
					>
						{accept_btn} {modalData.state.loading && <Spinner size="sm" className="btn-spinner" />}{' '}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	allDocumentsModal: function (modalData) {
		const { t } = modalData.props;
		const { projectDetails, alldocuments } = modalData.state;

		const columns = [
			{ name: allDocumentsModal_Filename },
			{ name: allDocumentsModal_Size },
			{ name: allDocumentsModal_Uploadedby },
			{ name: allDocumentsModal_Date },
			{
				name: allDocumentsModal_Download,
				options: {
					filter: false,
					customBodyRender: value => {
						// console.log("value ", value);
						if (value) {
							return (
								<>
									<a target="_blank" download href={value.url} rel="noreferrer">
										<Button
											title={allDocumentsModal_Download}
											className={'system-btn clear-border'}
											style={{ width: '50px' }}
											outline={true}
											color={'success'}
										>
											<GetAppIcon style={{ height: '20px', width: '20px', margin: '0 0 0 0' }} />
										</Button>
									</a>
								</>
							);
						}
					},
				},
			},
			{
				name: allDocumentsModal_Actions,
				options: {
					filter: false,
					customBodyRender: value => {
						if (projectDetails.ownerid === modalData.props.authReducer.userData.userid) {
							return (
								<>
									<Button
										title={tooltip_add}
										className={'system-btn clear-border'}
										outline={true}
										color={'success'}
										onClick={() => modalData.toggleDeleteDocumentFromChat(value)}
									>
										<Icon>delete</Icon>
									</Button>
								</>
							);
						} else if (value.senderid === modalData.props.authReducer.userData.userid) {
							return (
								<>
									<Button
										title={tooltip_delete}
										className={'system-btn clear-border'}
										outline={true}
										color={'success'}
										onClick={() => modalData.toggleDeleteDocumentFromChat(value)}
									>
										<Icon>delete</Icon>
									</Button>
								</>
							);
						}
					},
				},
			},
		];

		const options = {
			selectableRows: 'none',
			print: false,
			download: false,
			viewColumns: false,
			filter: false,
			responsive: 'stacked',
			pagination: false,
			search: false,
			textLabels: {
				body: {
					noMatch: no_match_record_found,
					toolTip: sort_text,
				},
				pagination: {
					rowsPerPage: rows_per_page_text,
				},
				toolbar: {
					search: search_text,
				},
			},
		};
		return (
			<Modal
				isOpen={modalData.state.allDocumentModal}
				toggle={() => modalData.toggleAllDocumentOfProject()}
				className={`modal-dialog--success modal-dialog--header width__70`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={() => modalData.toggleAllDocumentOfProject()}
					/>
					<h4 className="bold-text  modal__title">{allDocumentsModal_alldoc}</h4>
				</div>
				<div className="modal__body">
					<Row>
						<Col xs={12} sm={12} md={12}>
							<MUIDataTable
								data={modalData.renderAllDocuments()}
								columns={columns}
								options={options}
							/>
						</Col>
					</Row>
				</div>
				<ButtonToolbar className="modal__footer">
					<Button
						color={'success'}
						outline={true}
						onClick={() => modalData.toggleAllDocumentOfProject()}
					>
						{cancel_btn}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	deleteDocumentFromChatModal: function (modalData) {
		const { t } = modalData.props;

		let docName = modalData.state.deleteDocValue.filename;

		return (
			<Modal
				isOpen={modalData.state.deleteDocumentFromChatModal}
				toggle={() => modalData.toggleDeleteDocumentFromChat()}
				className={`modal-dialog--success modal-dialog--header`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={modalData.toggleDeleteDocumentFromChat}
					/>
					<h4 className="bold-text  modal__title">{deleteDocumentFromChatModal_title}</h4>
				</div>
				<div className="modal__body">
					{deleteDocumentFromChatModal_are_you_sure_msg + ' ( ' + docName + ' ) ?'}
				</div>
				<ButtonToolbar className="modal__footer">
					<Button
						color={'success'}
						outline={true}
						onClick={() => modalData.toggleDeleteDocumentFromChat()}
					>
						{cancel_btn}
					</Button>
					<Button
						color={'success'}
						onClick={() => modalData.deleteDocumentFromChat()}
						disabled={modalData.state.deleteLoading}
					>
						{delete_btn}
						{modalData.state.deleteLoading && (
							<Spinner size="sm" className="btn-spinner" style={{ color: '#e64823' }} />
						)}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	workRoomDocumentModal: function (modalData) {
		const { t } = modalData.props;

		return (
			<Modal
				isOpen={modalData.state.documentModal}
				toggle={() => modalData.toggleDeclineDocumentModal()}
				className={`modal-dialog--success modal-dialog--header`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={modalData.toggleDeclineDocumentModal}
					/>
					<h4 className="bold-text  modal__title">{my_document}</h4>
				</div>
				<div className="modal__body" style={{ overflowY: 'auto', maxHeight: '436px' }}>
					{modalData.state.documentList.length > 0 ? (
						<List>
							{modalData.state.documentList.map((value, index) => {
								const labelId = `checkbox-list-label-${value}`;
								return (
									<ListItem key={value} role={undefined} dense button>
										<ListItemIcon>
											<Checkbox
												edge="start"
												// checked={checked.indexOf(value) !== -1}
												tabIndex={-1}
												disableRipple
												id={index}
												style={{ padding: '0px 10px', color: '#c00' }}
												color="primary"
												inputProps={{ 'aria-labelledby': labelId }}
												onChange={modalData.handleCheckbox}
											/>
										</ListItemIcon>
										<ListItemText
											id={labelId}
											primary={value.file_name}
											secondary={
												<React.Fragment>
													<Typography
														component="span"
														variant="body2"
														color="textPrimary"
														style={{ display: 'inline' }}
													>
														{Length_txt}:
													</Typography>
													{value.length}
												</React.Fragment>
											}
										/>
									</ListItem>
								);
							})}
						</List>
					) : (
						<p>{no_any_document_for_upload}</p>
					)}
				</div>
				<ButtonToolbar className="modal__footer">
					<Button
						color={'success'}
						outline={true}
						onClick={() => modalData.toggleDeclineDocumentModal()}
					>
						{cancel_btn}
					</Button>
					<Button
						color={'success'}
						onClick={() => modalData.handleUpload()}
						disabled={modalData.state.loading || modalData.state.documentList.length === 0}
					>
						{client_uplolad_btn}{' '}
						{modalData.state.loading && <Spinner size="sm" className="btn-spinner" />}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	workRoomProjectDocumentModal: function (modalData) {
		const { t } = modalData.props;

		return (
			<Modal
				isOpen={modalData.state.projectDocumentModal}
				toggle={() => modalData.toggleDeclineDocumentModal()}
				className={`modal-dialog--success modal-dialog--header`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={modalData.toggleDeclineDocumentModal}
					/>
					<h4 className="bold-text  modal__title">
						{modalData.state.documentModal == true ? my_document : project_document}
					</h4>
				</div>
				<div className="modal__body">
					{modalData.state.documentList.length > 0 ? (
						<List>
							{modalData.state.documentList.map((value, index) => {
								const labelId = `checkbox-list-label-${value}`;
								return (
									<ListItem key={value} role={undefined} dense button>
										<ListItemIcon>
											<Checkbox
												edge="start"
												// checked={checked.indexOf(value) !== -1}
												tabIndex={-1}
												disableRipple
												id={index}
												style={{ padding: '0px 10px', color: '#c00' }}
												color="primary"
												inputProps={{ 'aria-labelledby': labelId }}
												onChange={modalData.handleCheckbox}
											/>
										</ListItemIcon>
										<ListItemText
											id={labelId}
											primary={value.file_name}
											secondary={
												<React.Fragment>
													<Typography
														component="span"
														variant="body2"
														color="textPrimary"
														style={{ display: 'inline' }}
													>
														{Length_txt}:
													</Typography>
													{value.length}
												</React.Fragment>
											}
										/>
									</ListItem>
								);
							})}
						</List>
					) : (
						<p>{no_any_document_for_upload}</p>
					)}
				</div>
				<ButtonToolbar className="modal__footer">
					<Button
						color={'success'}
						outline={true}
						onClick={() => modalData.toggleDeclineDocumentModal()}
					>
						{cancel_btn}
					</Button>
					<Button
						color={'success'}
						onClick={() => modalData.handleUpload()}
						disabled={modalData.state.loading || modalData.state.documentList.length === 0}
					>
						{client_uplolad_btn}{' '}
						{modalData.state.loading && <Spinner size="sm" className="btn-spinner" />}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	deleteProbingModal: function (modalData) {
		const { t } = modalData.props;

		let probingStatus = modalData.state.probingStatus;
		let deleteProbingcode = modalData.state.deleteProbingcode;
		let modalHeader = probingStatus == 'Delete' ? delete_probing : restore_probing;
		let modalMessage =
			probingStatus == 'Delete' ? delete_probing_are_you_sure : restore_probing_are_you_sure;
		let modalBtn = probingStatus == 'Delete' ? delete_btn : restore_btn;
		return (
			<Modal
				isOpen={modalData.state.deleteProbingModal}
				toggle={() => modalData.toggleDeleteProbing()}
				className={`modal-dialog--success modal-dialog--header`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={modalData.toggleDeleteProbing}
					/>
					<h4 className="bold-text  modal__title">{modalHeader}</h4>
				</div>
				<div className="modal__body">{modalMessage + ' ( ' + deleteProbingcode + ' ) ?'}</div>
				<ButtonToolbar className="modal__footer">
					<Button color={'success'} outline={true} onClick={() => modalData.toggleDeleteProbing()}>
						{cancel_btn}
					</Button>
					<Button color={'success'} onClick={() => modalData.deleteProbing()}>
						{modalBtn}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	openProbingModalMain: function (modalData) {
		const { t } = modalData.props;

		let deleteProbingcode = modalData.state.deleteProbingcode;
		return (
			<Modal
				isOpen={modalData.state.openProbingModalMain}
				toggle={() => modalData.toggleOpenProbingMain()}
				className={`modal-dialog--success modal-dialog--header`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={modalData.toggleOpenProbingMain}
					/>
					<h4 className="bold-text  modal__title">{'Open Probing'}</h4>
				</div>
				<div className="modal__body">
					{client_open_probing_message + ' ( ' + deleteProbingcode + ' ) ?'}
				</div>
				<ButtonToolbar className="modal__footer">
					<Button
						color={'success'}
						outline={true}
						onClick={() => modalData.toggleOpenProbingMain()}
					>
						{cancel_btn}
					</Button>
					<Button color={'success'} onClick={() => modalData.openProbingMain()}>
						{common_btn_yes}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	endProbingModal: function (modalData) {
		const { t } = modalData.props;

		return (
			<Modal
				isOpen={modalData.state.endProbingModal}
				toggle={() => modalData.toggleendProbing()}
				className={`modal-dialog--success modal-dialog--header`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={modalData.toggleendProbing}
					/>
					<h4 className="bold-text  modal__title">{'Auto end probing'}</h4>
				</div>
				<div className="modal__body">{same_expected_depth_and_layer_length}</div>
				<ButtonToolbar className="modal__footer">
					<Button
						color={'success'}
						outline="true"
						onClick={() => modalData.toggleendProbing('no')}
						size="sm"
					>
						{'No'}
					</Button>
					<Button color={'success'} onClick={() => modalData.toggleendProbing('yes')} size="sm">
						{'Yes'}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	viewProbingListModal: function (modalData) {
		const { t } = modalData.props;
		let state = modalData.state;
		let probing = modalData.state.probing;
		const getMuiTheme = () =>
			createTheme({
				overrides: {
					MUIDataTableHeadCell: {
						root: {
							fontSize: '18px',
							fontWeight: 'bold',
						},
					},
				},
			});

		const Campaign = [
			{
				name: clientmenu_probing,
			},
			{
				name: client_import_probing_altitude,
			},
			{
				name: client_probing_expecteddepth,
			},
			{
				name: client_probing_method,
			},
			{
				name: 'temp',
				options: {
					display: false,
					filter: false,
					customBodyRender: value => {
						if (value) {
							return value;
						}
					},
				},
			},
		];

		const options = {
			selectableRows: 'none',
			print: false,
			responsive: 'scrollMaxHeight',
			download: false,
			viewColumns: false,
			search: false,
			filter: false,
			filterType: 'dropdown',
			pagination: false,
			expandableRows: true,
			expandableRowsHeader: false,
			expandableRowsOnClick: true,
			tableBodyMaxHeight: '500px',
			tableBodyHeight: '500px',
			textLabels: {
				body: {
					noMatch: no_match_record_found,
					toolTip: sort_text,
				},
				pagination: {
					rowsPerPage: rows_per_page_text,
				},
				toolbar: {
					search: search_text,
				},
			},
			renderExpandableRow: (rowData, rowMeta) => {
				var layers = rowData[4].layer;
				const colSpan = rowData.length + 1;
				return (
					<TableRow>
						<TableCell colSpan={colSpan}>
							<table id="tableCustom">
								<tr>
									<th>{label_layer}</th>
									<th>{client_probing_Layer_Length}</th>
									<th>{client_probing_pid}</th>
									<th>{client_probing_lithology}</th>
								</tr>

								{layers &&
									layers.map((val2, ind4) => {
										var length = '';
										var lithologyString = [];
										var lithologyList = modalData.state.lithologyList;
										if (val2.lithology.length > 0 && val2.lithology[0].isDropDoun == false) {
											lithologyString.push(val2.lithology[0].from);
										} else {
											val2.lithology.map((v, k) => {
												var array = [];
												var singleData = lithologyList.filter(function (element) {
													if (element._id == v.from) {
														return element;
													}
												});
												var singleData1 = lithologyList.filter(function (element) {
													if (element._id == v.to) {
														return element;
													}
												});
												var singleData2 = lithologyList.filter(function (element) {
													if (element._id == v.inclutions) {
														return element;
													}
												});
												if (singleData.length > 0) {
													array.push(singleData[0].name);
												}
												if (singleData1.length > 0) {
													array.push(singleData1[0].name);
												}
												if (singleData2.length > 0) {
													array.push(singleData2[0].name);
												}
												lithologyString.push(array.join(', '));
											});
										}

										if (modalData.props.authReducer.userData.default_unit != 'CM') {
											//val2.generalDetails.layerLength = val2.generalDetails.layerLength?Number(val2.generalDetails.layerLength)/100:""
											length = val2.generalDetails.layerLength
												? parseFloat(val2.generalDetails.layerLength) / 100
												: '';
										}

										return (
											<>
												<tr>
													<td>{ind4 + 1}</td>
													<td>{length}</td>
													<td>{val2.generalDetails.pid}</td>
													<td>{lithologyString.join(' | ')}</td>
												</tr>
											</>
										);
									})}
							</table>
						</TableCell>
					</TableRow>
				);
			},
			onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) =>
				console.log(curExpanded, allExpanded, rowsExpanded),
		};

		return (
			<Modal
				isOpen={modalData.state.isOpenProbingListModal}
				toggle={() => modalData.toggleProbingListModal()}
				className={`modal-dialog--success modal-dialog--header max__width_90`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={modalData.closeProbingListModal}
					/>
					<h4 className="bold-text  modal__title">{common_probing_list}</h4>
				</div>
				<div className="modal__body">
					<>
						{modalData.state.isLoadingProbing && (
							<Row>
								<Col xs={12} sm={12} md={12} className="margin__top text-center">
									<Spinner size="sm" className="btn-spinner spinner_color spinner_size" />
								</Col>
							</Row>
						)}
						{!modalData.state.isLoadingProbing && (
							<>
								<h4 className="bold-text  modal__title" style={{ marginBottom: '10px' }}>
									<b>{client_campaign_name}</b> : {modalData.state.selectedCampaignData.title}
								</h4>
								<MuiThemeProvider theme={getMuiTheme()}>
									<MUIDataTable
										data={modalData.renderProbingList()}
										columns={Campaign}
										options={options}
									/>
								</MuiThemeProvider>
							</>
						)}

						<br />
						{!modalData.state.isLoadingProbing && (
							<div
								className="card"
								style={{
									boxShadow:
										'0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
								}}
							>
								<div className="card-body">
									{!modalData.state.isLoadingProbing &&
										modalData.state.mapprobings.map(probdata => (
											<Button
												className="btnRemoveContent"
												style={{ padding: '5px 19px', borderColor: '#E64823' }}
												color={'success'}
												size="sm"
												id={'popupbtn_' + probdata.probingcode}
												onClick={e => modalData.handleChangePopupMapProbing(e, probdata)}
											>
												{probdata.probingcode}
											</Button>
										))}
									<div className="changeMapStyle_dashboard">
										<CustomInput
											type="checkbox"
											name="mapstylepopup"
											value="mapstylepopup"
											id="mapstylepopup"
											label={campaign_stret_view}
											onChange={e => modalData.handleChangePopupMapStyle(e)}
										/>
									</div>
									<div
										ref={el => (this.mapContainerPopup = el)}
										style={{ height: '500px' }}
										id="mapContainerPopup"
									></div>
								</div>
							</div>
						)}
					</>
				</div>
				<ButtonToolbar className="modal__footer">
					<Button color={'success'} onClick={() => modalData.closeProbingListModal()} size="sm">
						{common_btn_okay}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	freeLicenceModal: function (modalData) {
		const { t } = modalData.props;
		let freelicenceName = modalData.state.deleteLicenceData.licencename;

		return (
			<Modal
				isOpen={modalData.state.freeLicenceModal}
				toggle={() => modalData.tooglefreeLicenceModal()}
				className={`modal-dialog--success modal-dialog--header`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={modalData.tooglefreeLicenceModal}
					/>
					<h4 className="bold-text  modal__title">{free_licence}</h4>
				</div>
				<div className="modal__body">{sure_to_free_licence + ' ( ' + freelicenceName + ' ) ?'}</div>
				<ButtonToolbar className="modal__footer">
					<Button
						color={'success'}
						outline={true}
						onClick={() => modalData.tooglefreeLicenceModal()}
					>
						{cancel_btn}
					</Button>
					<Button
						color={'success'}
						onClick={() => modalData.freeLicence()}
						disabled={modalData.state.loading}
					>
						{btn_yes}
						{modalData.state.loading && <Spinner size="sm" className="btn-spinner" />}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
	assignLicenceForProjectModal: function (modalData) {
		const { t } = modalData.props;
		let availableLicenceList = modalData.state.availableLicenceList;

		return (
			<Modal
				isOpen={modalData.state.assignLicenceForProjectModal}
				toggle={() => modalData.toogleAssignLicenceForProjectModal()}
				className={`modal-dialog--success modal-dialog--header`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={() => modalData.toogleAssignLicenceForProjectModal()}
					/>
					<h4 className="bold-text  modal__title">{assign_licence_modal_title}</h4>
				</div>
				<div className="modal__body">
					<Row style={{ marginTop: '20px' }}>
						<Col xs={6}>
							<TextField
								select
								required
								id="projects"
								SelectProps={{
									MenuProps: {
										className: 'select-menu-width',
									},
								}}
								style={{ width: '100%' }}
								label={assign_licence_modal_licence}
								className="material-form__field"
								value={modalData.state.licenceDetails}
								onChange={modalData.handleChange('licenceDetails')}
								InputLabelProps={{
									shrink: modalData.state.licenceDetails ? true : false,
								}}
							>
								{availableLicenceList &&
									availableLicenceList.map((option, index) => (
										<MenuItem key={index} value={option}>
											{`${option.licencename} ( ${option.companylicenceid} )`}
										</MenuItem>
									))}
							</TextField>
							{modalData.state.error && modalData.state.error.includes('licenceid') && (
								<span className="error-message">{client_profile_select_licence_validate}</span>
							)}
						</Col>
					</Row>
				</div>
				<ButtonToolbar className="modal__footer">
					<Button
						color={'success'}
						outline={true}
						onClick={() => modalData.toogleAssignLicenceForProjectModal()}
					>
						{cancel_btn}
					</Button>
					<Button
						color={'success'}
						onClick={() => modalData.saveAssignLicence()}
						disabled={modalData.state.loading}
					>
						{btn_yes}
						{modalData.state.loading && <Spinner size="sm" className="btn-spinner" />}
					</Button>
				</ButtonToolbar>
			</Modal>
		);
	},
};
