import React from "react";
import {
  Button,
  ButtonToolbar,
  Modal,
  Row,
  Col,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
} from "reactstrap";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "react-phone-number-input/style.css";
import PlacesAutocomplete from "react-places-autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
import "react-phone-number-input/style.css";
import "react-flags-select/scss/react-flags-select.scss";
import Checkbox from "@material-ui/core/Checkbox";
import { Select, ListItemText } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import ColorPicker from "material-ui-color-picker";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Select2 from 'react-select';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import * as classNames from 'classnames';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';

let tlang  = ChangeAppLanguageFront.translateLanguage;

const add_new_desposal = tlang('add_new_desposal') || 'Add New Disposal';
const edit_desposal = tlang('edit_desposal') || 'Edit Disposal';
const copy_desposal = tlang('copy_desposal') || 'Copy Disposal';
const test_masters_name = tlang('test_masters_name') || 'Name';
const test_masters_name_validate = tlang('test_masters_name_validate') || 'Please Add Name';
const test_masters_disposal_name_already_exist = tlang('test_masters_disposal_name_already_exist') || 'Disposal name already exist';
const language_savebtn = tlang('language_savebtn') || 'Save';
const user_request_company_address = tlang('user_request_company_address') || 'Address';
const Address_label = tlang('Address_label') || 'Address';
const pls_add_address = tlang('pls_add_address') || 'Please Add Address';
const user_request_country = tlang('user_request_country') || 'Country';
const pls_enter_country = tlang('pls_enter_country') || 'Please Enter Country';
const state = tlang('state') || 'State';
const state_validate = tlang('state_validate') || 'Please Enter State';
const Standard_label = tlang('Standard_label') || 'Standard';
const color_label = tlang('color_label') || 'Color';
const color_validate =  tlang('color_validate') || 'Please Enter Color';
const color_hexvalidate = tlang('color_hexvalidate') || 'Please enter color in hex format';
const desposal_select_many_test = tlang('desposal_select_many_test') || 'Select as many tests';
const please_enter_test = tlang('please_enter_test') || 'Please Enter Test';
const cancel_btn = tlang('cancel_btn') || 'Cancel';
const sr_no = tlang('user_request_detail_admin_sr_no') || 'Sr.No';
const desposal_testfamily_name = tlang('desposal_testfamily_name') || 'Test family';
const desposal_keys = tlang('desposal_keys') || 'Test name';
const desposal_short_description = tlang('desposal_short_description') || 'Short Description';
const desposal_description = tlang('desposal_description') || 'Description';
const desposal_long_description = tlang('desposal_long_description') || 'Long Description';
const desposal_tags = tlang('desposal_tags') || 'Tags';
const desposal_search_testfamily = tlang('desposal_search_testfamily') || 'Search Test Family';
const desposal_details = tlang('desposal_details') || 'Disposal Details';
const threshold_Value = tlang('threshold_Value') || 'Value';
const delete_desposal = tlang('delete_desposal') || 'Delete Disposal';
const are_you_sure_you_want_to_delete_desposal = tlang('are_you_sure_you_want_to_delete_desposal') || 'Are you sure you want to delete this Disposal';
const delete_staff_user_modal_cancel = tlang('delete_staff_user_modal_cancel') || 'Cancel';
const delete_staff_user_modal_delete = tlang('delete_staff_user_modal_delete') || 'Delete';
const thresholdModal_title = tlang('thresholdModal_title') || 'Add Threshold';
const save_btn = tlang('save_btn') || 'Save';
const thresholdFormulaModal_title = tlang('thresholdFormulaModal_title') || 'Add Threshold Value';
const threshold_Less_than_or_greater_than = tlang('threshold_Less_than_or_greater_than') || 'Less than or greater than';
const threshold_Percentage = tlang('threshold_Percentage') || 'Percentage';
const threshold_Sum_of_all_test = tlang('threshold_Sum_of_all_test') || 'Sum of all test';
const threshold_Formula = tlang('threshold_Formula') || 'Formula';
const threshold_Less_than = tlang('threshold_Less_than') || 'Less than';
const threshold_Greater_than = tlang('threshold_Greater_than') || 'Greater than';
const threshold_Test = tlang('threshold_Test') || 'Test';
const threshold_Sum = tlang('threshold_Sum') || 'Sum';
const threshold_Pls_select_formula = tlang('threshold_Pls_select_formula') || 'Please select formula';
const threshold_Select_Test = tlang('threshold_Select_Test') || 'Select Test';
const threshold_pls_Select_Test = tlang('threshold_pls_Select_Test') || 'Please select test';
const threshold_Pls_select_percentage = tlang('threshold_Pls_select_percentage') || 'Please select percentage';
const threshold_Add_Test_Rule = tlang('threshold_Add_Test_Rule') || 'Add Test Rule';
const threshold_Select_as_many_tests = tlang('threshold_Select_as_many_tests') || 'Select as many tests';
const Lang_Loadingtxt = tlang('Lang_Loadingtxt')  || 'Loading...';
const sort_text = tlang('sort_text') || 'Sort';
const rows_per_page_text = tlang('rows_per_page_text') || 'Rows per page:';
const no_match_record_found = tlang('no_match_record_found') || 'Sorry, no matching records found';
const search_text = tlang('search_text') || 'Search';
const client_disposal = tlang('client_disposal') || 'Disposal';
const set_standard_order = tlang('set_standard_order') || 'Set Standard Disposal Order';
const final_value = tlang('final_value') || 'Final Value';
const client_searchby_testfamily_or_pollutant_name = tlang('client_searchby_testfamily_or_pollutant_name') || 'Search by testfamily or pollutant name';
const client_select_testfamily = tlang('client_select_testfamily') || 'Select Testfamily';

let customStyle = {
  maxHeight: "400px",
  overflowY: "scroll",
};

const select2ColourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "default"
        : isFocused
        ? "#E64823"
        : null,
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? "#ff0000" : "#e64823a6"),
      },
    };
  },
};

export default {
   
  //this function is not longer used, it is converted to functional component
  standardDisposalModal: function (modalData) {
    const { t } = modalData.props;
    let standardDesposal = modalData.state.standardDisposalArr;
    let activeCat = modalData.state.disposalCateogy[modalData.state.activeTab];
    let displayCategory = activeCat.charAt(0).toUpperCase() + activeCat.slice(1);

    return (
      <Modal
        isOpen={modalData.state.standardDisposalModal}
        toggle={() => modalData.toggleStandardDesposalOrder()}
        className={`modal-dialog--success modal-dialog--header width__50`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleStandardDesposalOrder()}
          />
          <h4 className="bold-text  modal__title">{set_standard_order + " - " + displayCategory}</h4>
        </div>
        <div className="modal__body">
        <Row>
        {standardDesposal.length > 0 && 
        <Card style={{width:"50%", margin: "0 auto"}}>
                <CardBody style={{height:"auto", overflowY:"auto",overflowX: "hidden"}}>
                    <Col xs="12">
                    <DragDropContext onDragStart={modalData.onDragStart}  onDragEnd={modalData.onDragEnd}>
                        <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                            ref={provided.innerRef}
                            className={classNames('list1', snapshot.isDraggingOver && 'draggingOver')}
                            {...provided.droppableProps}
                            >
                            {standardDesposal.map((item, index) => {
                            //let activeGraph = item.visible ? item.color : ''
                            let activeGraph = item.color
                            return(
                                
                                <Draggable key={item.name} draggableId={`${item.name}-id`} index={index} isDragDisabled={item.visible == true ? false : false}>
                                    
                                {(provided, snapshot) => {
                                
                                provided.draggableProps.style = {...provided.draggableProps.style, backgroundColor: activeGraph, cursor: "pointer"};

                                return (
                                    <div 
                                    id={"btn_"+index}
                                    //onClick={(e) => modalData.visibleChartNew(index,item.visible)} 
                                    className={classNames('item1 btnRemoveContent', snapshot.isDragging && 'dragging')}
                                    style={provided.draggableProps.style}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    >
                                    {item.name}
                                    </div>
                                )}}
                                </Draggable>
                            )})}
                            {provided.placeholder}
                            </div>
                        )}
                        </Droppable>
                    </DragDropContext>
                    </Col>
                </CardBody>
                </Card>
        }

        {standardDesposal.length == 0 &&
          <h4 style={{margin: "0 auto"}}>Sorry!, No any standard disposal exist.</h4>
        }
        </Row>

        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            style={{width: "47%"}}
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleStandardDesposalOrder()}
          >
            {cancel_btn}
          </Button>
          <Button
            style={{width: "47%"}}
            color={"success"}
            onClick={() => modalData.saveStandardDesposalOrder()}
            disabled={modalData.state.loading || standardDesposal.length == 0}
          >
            {language_savebtn}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },

  desposalAddEditModal: function(modalData) {
    const { t } = modalData.props;
    let desposal = modalData.state.desposal;
    let _id = modalData.state.desposal._id;
    
    let disposalCateogy = modalData.state.disposalCateogy;

    let activeCat = modalData.state.disposalCateogy[modalData.state.activeTab];
    let displayCategory = activeCat.charAt(0).toUpperCase() + activeCat.slice(1);

    let copyToCategory = Object.values(disposalCateogy).filter((l) => l != "master");
    
    let titleName = _id ? edit_desposal : (add_new_desposal + " - " + displayCategory);

    if(modalData.state.isCopyDispo == true){
      titleName = copy_desposal + " - " + desposal.name;
    }
    
    if(_id != ""){
      titleName = edit_desposal + " - " + desposal.name;
    }

    const columns = [
      { name: sr_no,
        options: {
          setCellProps: () => ({ style: { minWidth: "2rem", maxWidth: "2rem" }})
        },
      },
      { name: desposal_testfamily_name,
        options: {
          setCellProps: () => ({ style: { minWidth: "15rem", maxWidth: "15rem" }})
        },
      },
      { name: desposal_keys },
      // { name: desposal_description },
      // { name: desposal_short_description },
      // { name: desposal_long_description },
      // { name: desposal_tags },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      print: false,
      download: false,
      viewColumns: false,
      filter: false,
      responsive: "stacked",
      search: false,
      textLabels: {
        body: {
        noMatch: no_match_record_found,
        toolTip: sort_text
        },
        pagination: {
        rowsPerPage: rows_per_page_text,
        },
        toolbar: {
        search: search_text
        },
    },
    };
    let testFamilyOptions = []    
    if(desposal.test_list){
      desposal.test_list.map((test,i)=>{
        testFamilyOptions.push({label:test.name,value:test._id})
      })      
    }
    let testFamilySelectedOptions=[]    
    if(modalData.state.testFamilyList){
      modalData.state.testFamilyList.map((test,i)=>{
        testFamilySelectedOptions.push({label:test.name,value:test._id})
      })
    }

    return (
      <Modal
        isOpen={modalData.state.desposalAddEditModal}
        //toggle={() => modalData.toggleDesposalAddEditModal()}
        className={`modal-dialog--success modal-dialog--header width__70`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleDesposalAddEditModal()}
          />
          <h4 className="bold-text  modal__title">{titleName}</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col xs={12} sm={12} md={12} className="">
              {modalData.state.getDetailsLoading && (
                <div className="div-spinner"><Spinner size="sm" className="btn-spinner spinner_color spinner_size" /></div>
              ) }
              {!modalData.state.getDetailsLoading && desposal && (
                <>
                  <Row>
                    <Col xs={12}>
                      <TextField
                        required
                        fullWidth
                        className="material-form__field "
                        label={test_masters_name}
                        name="name"
                        margin="normal"
                        onChange={modalData.handleInputDesposalChange("name")}
                        value={desposal.name}
                        error={
                          desposal.error && desposal.error.includes("name")
                        }
                      />
                      {desposal.error && desposal.error.includes("name") && (
                        <p class="error-message">
                          {test_masters_name_validate}
                        </p>
                      )}
                      {desposal.error && desposal.error.includes("nameexist") && (
                        <p class="error-message">
                          {test_masters_disposal_name_already_exist}
                        </p>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <PlacesAutocomplete
                        value={desposal.address}
                        onChange={modalData.handleChangeaddress("address")}
                        onSelect={modalData.handleSelect}
                        id="address"
                        name="address"
                        multiline
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <React.Fragment>
                            <TextField
                              fullWidth
                              {...getInputProps({
                                className: "location-search-input",
                              })}
                              label={Address_label}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>{Lang_Loadingtxt}</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <MenuItem value={""}>
                                      {suggestion.description}
                                    </MenuItem>
                                  </div>
                                );
                              })}
                            </div>
                          </React.Fragment>
                        )}
                      </PlacesAutocomplete>
                      {/* {desposal.error && desposal.error.includes("address") && (
                        <span class="error-message">{pls_add_address}</span>
                      )} */}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <TextField
                        margin="dense"
                        error={
                          desposal.error && desposal.error.includes("country")
                        }
                        fullWidth
                        label={user_request_country}
                        value={desposal.country}
                        onChange={modalData.handleInputDesposalChange(
                          "country"
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <ColorPicker
                        required
                        fullWidth
                        value={desposal.color}
                        InputProps={{ value: desposal.color }}
                        label={color_label}
                        TextFieldProps={{
                          style: {
                            width: "100%",
                          },
                        }}
                        className="material-form__field full__width"
                        onChange={modalData.handleInputDesposalChange("color")}
                      />
                      {desposal.error && desposal.error.includes("color") && (
                        <span class="error-message">{color_validate}</span>
                      )}
                      {desposal.error && desposal.error.includes("colorhex") && (
                        <span class="error-message">{color_hexvalidate}</span>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} style={{ margin: "10px 0px 0px -15px" }}>
                      <FormControlLabel
                        margin="dense"
                        label={Standard_label}
                        labelPlacement="start"
                        control={<Checkbox style={{ color: '#c00' }} />}
                        checked={desposal.standard}
                        onChange={modalData.handleInputDesposalChange(
                          "standard",
                          desposal.standard
                        )}
                      />
                    </Col>
                  </Row>

                  {modalData.state.isCopyDispo == true && (
                  <>
                  <Row>
                    <Col xs={12}>
                        <TextField
                          select
                          required
                          id="projects"
                          SelectProps={{
                            MenuProps: {
                              className: "select-menu-width",
                            },
                          }}
                          style={{ width: "100%" }}
                          label={"Copy to"}
                          className="material-form__field"
                          value={desposal.type}
                          onChange={modalData.handleInputDesposalChange("type")}
                          error={
                            desposal.error && desposal.error.includes("type")
                          }
                        >
                          {copyToCategory &&
                            copyToCategory.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option.charAt(0).toUpperCase() + option.slice(1)} {client_disposal}
                              </MenuItem>
                            ))}
                        </TextField>
                        {desposal.error && desposal.error.includes("type") && (
                          <p class="error-message">
                            {"Please select Copy to"}
                          </p>
                        )}
                      </Col>
                    </Row>
                    </>
                  )}

                  <Row style={{ marginTop: "10px" }}>
                    <Col xs={12} style={{ margin: "5px 0px 15px 0" }}>
                      <TextField
                        //label={desposal_search_testfamily}
                        style={{width: "38%"}}
                        label={client_searchby_testfamily_or_pollutant_name}
                        value={modalData.state.searchTestFamilyTexts}
                        onChange={(event)=>modalData.handleTestFamilySearch(event)}
                        onBlur={(event)=>modalData.searchTestFamily(event)}
                        disabled={modalData.state.loading}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>                              
                              <IconButton>       
                                { modalData.state.loading && (
                                  <Spinner size="sm" className="btn-spinner" style={{color:"#E64823"}} />
                                )}
                                <SearchIcon onClick={(event)=>modalData.searchTestFamily(event)} disabled={modalData.state.loading}/>
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />                      
                    </Col>
                    <Col xs={12}>
                    <Select2 options={testFamilySelectedOptions}  
                      required        
                      isMulti
                      styles={select2ColourStyles}
                      name="testFamilys"
                      placeholder={`${client_select_testfamily} *`}
                      value={testFamilyOptions}
                      onChange={modalData.handleTestFamilyChange}
                      closeMenuOnSelect={false}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: '#e34a26b0',
                          primary: '#e34a26',
                        },
                      })}                   
                    />
                    {desposal.error &&
                      desposal.error.includes("test_list") && (
                        <span class="error-message">
                          {please_enter_test}
                        </span>
                      )}
                    </Col>
                     
                  </Row>
                  { testFamilyOptions.length !=0 &&
                    <Row>
                      <Col xs={12} sm={12} md={12} style={{marginTop:"15px",padding:"0px",zIndex:"0"}}>
                        <MUIDataTable
                          data={modalData.renderSelectedTestFamilyDetails()}
                          columns={columns}
                          options={options}
                        />
                      </Col>
                    </Row>
                  } 
                </>
              )}
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleDesposalAddEditModal()}
          >
            {cancel_btn}
          </Button>
          {!modalData.state.getDetailsLoading && desposal && (
            <Button
              color={"success"}
              onClick={() => modalData.saveDesposal()}
              disabled={modalData.state.loading}
            >
              {language_savebtn}
              {modalData.state.loading && (
                <Spinner size="sm" className="btn-spinner" />
              )}
            </Button>
          )}
        </ButtonToolbar>
      </Modal>
    );
  },
  desposalViewModal: function(modalData) {
    const { t } = modalData.props;
    let desposal = modalData.state.desposal;
    let _id = modalData.state.desposal._id;
    
    const columns = [
      { name: sr_no },
      { name: desposal_testfamily_name },
      { name: desposal_keys },
      { name: threshold_Value },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      print: false,
      download: false,
      viewColumns: false,
      filter: false,
      responsive: "stacked",
      search: false,
      textLabels: {
        body: {
        noMatch: no_match_record_found,
        toolTip: sort_text
        },
        pagination: {
        rowsPerPage: rows_per_page_text,
        },
        toolbar: {
        search: search_text
        },
    },
    };
    let viewSelectedTestFamily = []    
    if(desposal.test_list){
      desposal.test_list.map((test,i)=>{
        viewSelectedTestFamily.push(test.name)
      })      
    }
    
    return (
      <Modal
        isOpen={modalData.state.desposalViewModal}
        toggle={() => modalData.toggleDesposalViewModal()}
        className={`modal-dialog--success modal-dialog--header width__70`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleDesposalViewModal()}
          />
          <h4 className="bold-text  modal__title">{desposal_details}</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col xs={12} sm={12} md={12} className="">
              {modalData.state.getDetailsLoading && (
                <div className="div-spinner"><Spinner size="sm" className="btn-spinner spinner_color spinner_size" /></div>
              ) }
              {!modalData.state.getDetailsLoading && desposal && (
                <>
                  <Row>
                    <Col xs={12} sm={6} md={6} >
                      <label className="m-0">{test_masters_name}</label>
                      <p className="m-0">{desposal.name}</p>
                    </Col>
                    <Col xs={12} sm={6} md={6}>
                      <label className="m-0">{Address_label}</label>
                      <p className="m-0">{desposal.address}</p>
                    </Col>
                  </Row>                  
                  <Row  style={{marginTop:"10px"}}>
                    <Col xs={12} sm={6} md={6}>
                      <label className="m-0">{user_request_country}</label>
                      <p className="m-0">{desposal.country}</p>
                    </Col>
                    <Col xs={12} sm={6} md={6}>
                      <label className="m-0">{state}</label>
                      <p className="m-0">{desposal.state}</p>
                    </Col>
                  </Row>
                  <Row  style={{marginTop:"10px"}}>
                    <Col xs={12} sm={6} md={6}>
                      <label className="m-0">{Standard_label}</label>
                      <FormControlLabel
                        disabled={true}
                        margin="dense"
                        label={Standard_label}
                        labelPlacement="start"
                        control={<Checkbox color="primary" />}
                        checked={desposal.standard}
                        className="m-0"
                      />
                    </Col>
                    <Col xs={12} sm={6} md={6}>
                      <label className="m-0">{color_label}</label>
                      <p className="m-0">{desposal.color}</p>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>                     
                    <Col xs={12} sm={12} md={12}>
                      <label className="m-0">{desposal_testfamily_name}</label>
                      <p>{viewSelectedTestFamily.join(", ")}</p>                    
                    </Col> 
                  </Row>
                  { viewSelectedTestFamily.length !=0 &&
                    <Row>
                      <Col xs={12} sm={12} md={12} style={{marginTop:"15px",padding:"0px",zIndex:"0"}}>
                        <MUIDataTable
                          data={modalData.renderSelectedTestFamilyValues()}
                          columns={columns}
                          options={options}
                        />
                      </Col>
                    </Row>
                  }            
                </>
              )}
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleDesposalViewModal()}
          >
            {cancel_btn}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  desposalDeleteModal: function(modalData) {
    const { t } = modalData.props;
    let row1 = modalData.state.master;

    let deleteDesposalName = modalData.state.deleteDesposalName;

    return (
      <Modal
        isOpen={modalData.state.desposalDeleteModal}
        toggle={() => modalData.toggleDesposalDeleteModal()}
        className={`modal-dialog--danger modal-dialog--header`}
      >
        <div className="modal__header" style={{ backgroundColor:"#E64823" }}>
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleDesposalDeleteModal()}
          />
          <h4 className="bold-text  modal__title">{delete_desposal}</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col md={12} lg={12} className="theme-light">
              {are_you_sure_you_want_to_delete_desposal + " ( "+deleteDesposalName+" ) ?"}  
            </Col>
          </Row>
        </div>

        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleDesposalDeleteModal()}
          >
            {delete_staff_user_modal_cancel}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.deleteDesposal()}
            disabled={modalData.state.loading}
          >
            {delete_staff_user_modal_delete}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  thresholdValueModal: function(modalData) {
    const { t } = modalData.props;
    let row1 = modalData.state.master;

    return (
      <Modal
        isOpen={modalData.state.thresholdValueModal}
        toggle={() => modalData.toggleThresholdValueModal()}
        className={`modal-dialog--success modal-dialog--header width__70`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleThresholdValueModal()}
          />
          <h4 className="bold-text  modal__title">{thresholdModal_title} - {modalData.state.desposal.name}</h4>
        </div>
        <div className="modal__body" style={customStyle}>
          <Row>
            <table style={{ overflowX: "auto" }}>
              <tbody>
                {/* <tr key={50}>{modalData.renderThresholdValueHeader()}</tr> */}
                <tr key={50}>{modalData.renderNote()}</tr>
                {modalData.renderThresholdValue()}
              </tbody>
            </table>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleThresholdValueModal()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.saveThresholdValue()}
            disabled={modalData.state.loading}
          >
            {save_btn}
            {modalData.state.loading && (
                <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>          
        </ButtonToolbar>
      </Modal>
    );
  },
  testRuleModal: function (modalData) {
    const { t } = modalData.props;
    let row1 = modalData.state.master;
    let testRule = modalData.state.testRule;
    let formula = modalData.state.testRule.formula;
    let childArray = modalData.state.childArray;
    let newChildArray = [];
    let childArrayForRule = [];
    let value = 15;

    const select2ColourStyles = {
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? "default"
            : isFocused
            ? "#E64823"
            : null,
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled && (isSelected ? "#ff0000" : "#e64823a6"),
          },
        };
      },
    };

      let childArraySelectedOptions = []
      // if (childArray) {
      //   childArray.map((test, i) => {
      //     childArraySelectedOptions.push({ label: test.name, value: test.id })
      //   })
      // }

      if(modalData.state.desposal.test_list.length > 0){
        modalData.state.desposal.test_list.map((pollutant, pindex) => {
          let myObj = {};
          myObj.label = pollutant.name;
            if(pollutant.children.length > 0){
              let temparr = [];
                    pollutant.children.map((children, cindex) => {
                      let arr = {};
                        if(children.id != testRule.child_id){
                          Object.assign(arr, { label: children.name, value: children.id });
                          temparr.push(arr);
                        }
                })
                myObj.options = temparr;
            }
            childArraySelectedOptions.push(myObj);
        })
      }

    let childArrayOptions = []
    if (formula.test) {
      formula.test.map((test, i) => {
        childArrayOptions.push({ label: test.name, value: test.id })
      })
    }
    
    let testId = testRule.child_id;
    let des_id = testRule.desposal_id;
    let typeId = testRule.test_id
    let child = testRule.desposalArray.test_list.filter((l) => l._id == testRule.test_id)[0].children;
    let nolimit_finalvalue = testRule.nolimit_finalvalue;

    return (
      <Modal
        isOpen={modalData.state.testRuleModal}
        toggle={() => modalData.toggleTestRuleCloseModal()}
        className={`modal-dialog--success modal-dialog--header width__70`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleTestRuleCloseModal()}
          />
          <h4 className="bold-text  modal__title">{threshold_Add_Test_Rule} {"of"} {testRule.child_name}</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col xs={3} sm={3} md={3} className="">
              <RadioGroup
                aria-label="threshold"
                name="radio1"
                value={modalData.state.radioValue}
                onChange={modalData.handleChangeRadio("radioValue")}
              >
                <FormControlLabel
                  key={"radio1"}
                  value="radio1"
                  control={<Radio style={{ color: '#c00' }} />}
                  label={"Add Threshold Value"}
                />
                <FormControlLabel
                  key={"radio2"}
                  value="radio2"
                  control={<Radio style={{ color: '#c00' }} />}
                  label={"Multiple Pollutant"}
                />
                <FormControlLabel
                  key={"radio3"}
                  value="radio3"
                  control={<Radio style={{ color: '#c00' }} />}
                  label={"No Limit"}
                />
                <FormControlLabel
                  key={"radio4"}
                  value="radio4"
                  control={<Radio style={{ color: '#c00' }} />}
                  label={threshold_Percentage}
                />
              </RadioGroup>
            </Col>
            <Col xs={9} sm={9} md={9} className="">
              <Row key={1}>
                {modalData.state.radioValue &&
                  modalData.state.radioValue === "radio1" && (
                    <>
                      <Col xs={4} sm={4} md={4} className="">

                       <TextField
                          key={testId}
                          variant="outlined"
                          name={"name_" + des_id + "_" + testId}
                          type="number"
                          placeholder="Threshold Value"
                          onChange={(event) => modalData.handleChangeRule(child, typeId, testId, des_id, event)}
                          onKeyPress={(e) => modalData.keyPress(e)}
                          value={modalData.state["name_" + des_id + "_" + testId]}
                          style={{ marginRight: "5px" }}
                          onBlur={(event)=>modalData.changedTestFamily(child, typeId, testId, des_id, event)}
                      /> 
                      {testRule.error &&
                        testRule.error.includes("radio1_thresholdvalue") && (
                          <span className="error-message">
                            {"Please enter threshold value"}
                          </span>
                      )}
                      {/* <TextField
                        select
                        id="standard-select-currency"
                        SelectProps={{
                          MenuProps: {
                            className: "select-menu-width",
                          },
                        }}
                        fullWidth
                        label={threshold_Formula}
                        className="material-form__field"
                        value={formula.condition}
                        onChange={modalData.handleChangeTestFormula1(
                          "radio1",
                          "condition"
                        )}
                      >
                        <MenuItem key={"1"} value={"less"}>
                          {threshold_Less_than}{" "}
                        </MenuItem>
                        <MenuItem key={"2"} value={"greter"}>
                          {threshold_Greater_than}{" "}
                        </MenuItem>
                      </TextField>
                      {testRule.error &&
                        testRule.error.includes("radio1_condition") && (
                          <span className="error-message">
                            {threshold_Pls_select_formula}
                          </span>
                        )} */}
                      </Col>
                    </>
                  )}
              </Row>
              <Row key={2} style={{ marginTop: "25px" }}>
                {modalData.state.radioValue &&
                  modalData.state.radioValue === "radio2" && (
                    <>
                        <Col xs={4} sm={4} md={4} className="">
                          <TextField
                            key={testId}
                            variant="outlined"
                            name={"name_" + des_id + "_" + testId}
                            //disabled={true}
                            type="number"
                            placeholder="Threshold Value"
                            onChange={(event) => modalData.handleChangeRule(child, typeId, testId, des_id, event)}
                            onKeyPress={(e) => modalData.keyPress(e)}
                            value={modalData.state["name_" + des_id + "_" + testId]}
                            style={{ marginRight: "5px" }}
                            onBlur={(event)=>modalData.changedTestFamily(child, typeId, testId, des_id, event)}
                          /> 
                          {testRule.error &&
                            testRule.error.includes("radio2_thresholdvalue") && (
                              <span className="error-message">
                                {"Please enter threshold value"}
                              </span>
                          )}
                        </Col>
                        
                        <Col xs={8}>
                        <Select2 options={childArraySelectedOptions}
                          isMulti
                          styles={select2ColourStyles}
                          name="testFamilys"
                          value={childArrayOptions}
                          closeMenuOnSelect={false}
                          onChange={modalData.handleChangeTestFormula1SrcInp}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Select Multiple Pollutant..."
                          theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: '#e34a26b0',
                              primary: '#e34a26',
                            },
                          })}
                        />
                        {testRule.error &&
                        testRule.error.includes("radio2_test") && (
                          <span className="error-message">
                            {threshold_pls_Select_Test}
                          </span>
                        )}
                        </Col>

                       {/* <Col xs={4} sm={4} md={4} style={{ marginTop: "10px" }}>
                        <FormControl style={{ display: "flex" }}>
                          <InputLabel id="mutiple-child-name-label">
                            {threshold_Select_as_many_tests}
                          </InputLabel>
                          <Select
                            margin="dense"
                            labelId="mutiple-child-name-label"
                            isSearchable={true}
                            isMulti={true}
                            multiple
                            label={threshold_Test}
                            displayEmpty
                            fullWidth
                            className="material-form__field"
                            value={formula.test ? formula.test : []}
                            onChange={modalData.handleChangeTestFormula1(
                              "radio2",
                              "test"
                            )}
                            error={
                              testRule.error &&
                              testRule.error.includes("radio2_test")
                            }
                            renderValue={(event) =>
                              modalData.selectedChildList(event)
                            }
                          >
                            {childArray &&
                              childArray.map((child, index) => (
                                <MenuItem
                                  key={child.id}
                                  value={child}
                                  name={child.name}
                                >
                                  <ListItemText primary={child.name} />
                                </MenuItem>
                              ))}
                          </Select>
                          {testRule.error &&
                            testRule.error.includes("radio2_test") && (
                              <span className="error-message">
                                {threshold_pls_Select_Test}
                              </span>
                            )}
                        </FormControl>
                      </Col> */}

                      


                      {/* <Col xs={4} sm={4} md={4} className="">
                        <TextField
                          select
                          id="standard-select-currency"
                          SelectProps={{
                            MenuProps: {
                              className: "select-menu-width",
                            },
                          }}
                          fullWidth
                          label={threshold_Formula}
                          className="material-form__field"
                          value={formula.condition}
                          onChange={modalData.handleChangeTestFormula1(
                            "radio2",
                            "condition"
                          )}
                        >
                          <MenuItem key={"1"} value={"less"}>
                            {threshold_Less_than}{" "}
                          </MenuItem>
                          <MenuItem key={"2"} value={"greter"}>
                            {threshold_Greater_than}{" "}
                          </MenuItem>
                        </TextField>
                        {testRule.error &&
                          testRule.error.includes("radio2_condition") && (
                            <span className="error-message">
                              {threshold_Pls_select_formula}
                            </span>
                          )}
                      </Col> */}
                    </>
                  )}
              </Row>

              <Row key={3} style={{ marginTop: "75px" }}>
                {modalData.state.radioValue &&
                  modalData.state.radioValue === "radio3" && (
                    <>  
                    <h4 className="bold-text  modal__title">{"Any pollutant value will match"}</h4>
                    <FormControlLabel
                        label={final_value}
                        control={
                          <Checkbox color="primary" style={{color:"#c00"}}/>
                        }
                        checked={nolimit_finalvalue}
                        disabled={false}
                        onChange={modalData.handleChangeFinalValue("nolimit_finalvalue")}
                        labelPlacement="end"
                        style={{marginLeft: '7%', marginTop: '-1%'}}
                    />
                    </>
                  )}
                  </Row>


              <Row key={4} style={{ marginTop: "33px" }}>
                {modalData.state.radioValue &&
                  modalData.state.radioValue === "radio4" && (
                    <>
                      <Col xs={3} sm={3} md={3} className="">
                        <TextField
                          select
                          id="standard-select-currency"
                          SelectProps={{
                            MenuProps: {
                              className: "select-menu-width",
                            },
                          }}
                          fullWidth
                          label={threshold_Formula}
                          className="material-form__field"
                          value={formula.condition}
                          onChange={modalData.handleChangeTestFormula1(
                            "radio4",
                            "condition"
                          )}
                        >
                          <MenuItem key={"1"} value={"less"}>
                            {threshold_Less_than}{" (<) "}
                          </MenuItem>
                          <MenuItem key={"2"} value={"greter"}>
                            {threshold_Greater_than}{" (>) "}
                          </MenuItem>
                        </TextField>
                        {testRule.error &&
                          testRule.error.includes("radio4_condition") && (
                            <span className="error-message">
                              {threshold_Pls_select_formula}
                            </span>
                          )}
                      </Col>
                      <Col xs={3} sm={3} md={3} className="">
                        <TextField
                          id="standard-select-currency"
                          number
                          SelectProps={{
                            MenuProps: {
                              className: "select-menu-width",
                            },
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                          }}
                          fullWidth
                          label={threshold_Percentage}
                          className="material-form__field"
                          value={formula.percentage}
                          onChange={modalData.handleChangeTestFormula1(
                            "radio4",
                            "percentage"
                          )}
                        ></TextField>
                        {testRule.error &&
                          testRule.error.includes("radio4_percentage") && (
                            <span className="error-message">
                              {threshold_Pls_select_percentage}
                            </span>
                          )}
                      </Col>
                      <Col xs={6} sm={6} md={6} className="">
                        {/* <FormControl style={{ display: "flex" }}>
                          <InputLabel
                            id="mutiple-child-name-label-radio4"
                            shrink={formula.test[0] ? true : false}
                          >
                            {threshold_Select_Test}
                          </InputLabel>
                          <Select
                            margin="dense"
                            labelId="mutiple-child-name-label-radio4"
                            label={threshold_Test}
                            displayEmpty
                            fullWidth
                            className="material-form__field"
                            value={formula.test[0] ? formula.test[0] : null}
                            onChange={modalData.handleChangeTestFormula1(
                              "radio4",
                              "test"
                            )}
                            error={
                              testRule.error &&
                              testRule.error.includes("radio4_test")
                            }
                            renderValue={(event) =>
                              modalData.selectedChildList(event)
                            }
                          >
                            {childArray &&
                              childArray.map((child, index) => (
                                <MenuItem
                                  key={child.id}
                                  value={child}
                                  name={child.name}
                                >
                                  <ListItemText primary={child.name} />
                                </MenuItem>
                              ))}
                          </Select>
                          {testRule.error &&
                            testRule.error.includes("radio4_test") && (
                              <span className="error-message">
                                {threshold_pls_Select_Test}
                              </span>
                            )}
                        </FormControl> */}

                          <Select2 options={childArraySelectedOptions}
                          isMulti={false}
                          styles={select2ColourStyles}
                          name="testFamilys"
                          value={childArrayOptions}
                          onChange={modalData.handleChangeTestFormula1SrcInp}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Select a Pollutant..."
                          theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: '#e34a26b0',
                              primary: '#e34a26',
                            },
                          })}
                        />
                        {testRule.error &&
                        testRule.error.includes("radio4_test") && (
                          <span className="error-message">
                            {"Please select a pollutant"}
                          </span>
                        )}

                      </Col>
                    </>
                  )}
              </Row>
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleTestRuleCloseModal()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.saveTestRule()}
            disabled={modalData.state.loading}
          >
            {save_btn}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
};
