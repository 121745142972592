import React from 'react';
import * as actionTypes from '../actionTypes';
import { gql } from "apollo-boost";
import { Encrypt } from "../../shared/encrpt";
import * as types from '../types';
import fingerprint from 'fingerprintjs'
import jwt from 'jsonwebtoken';
import axios from 'axios';
import Server from '../../../Common/Server';
import notificationMsg  from '../../../App/view/Layout/NotificationMessage';
import * as ChangeAppLanguageFront from '../../../App/view/Layout/ChangeAppLanguageFront';
let tlang = ChangeAppLanguageFront.translateLanguage;

const staffcreate = tlang('notificationSuccess_staffcreate') || 'User Created Successfully';
const staffupdate = tlang('notificationSuccess_staffupdate') || 'Staff Updated Successfully';
const staffdelete = tlang('notificationSuccess_staffdelete') || 'User Deleted Successfully';
const staffUserBlock = tlang('notificationSuccess_staffUserBlock') || 'User is Blocked';
const staffUserUnBlock = tlang('notificationSuccess_staffUserUnBlock') || 'User is Unblocked';
const error_something_wrong = tlang('error_something_wrong') || 'Something Went Wrong!';
const creating_account_reviewed_msg = tlang('other_notification_sucess_your_request_for_creating_account_has_been_reviewed') || 'Your request for creating account has been received. You will get an email on your email from registration@aexdo.com once the request has been reviewed';
const UserSettingChanged = tlang('notificationSuccess_auth_UserSettingChanged') || 'User Setting is changed successfully';

// notification Success
const notificationSuccess = (msg) => {
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_SUCCESS,
    state: msg,
  }
}

// notification Fail
const notificationFail = (err) => {
  let msg = error_something_wrong;
  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    // msg = err.graphQLErrors[0].message;
    let translateMsg =notificationMsg(err.graphQLErrors[0].statusCode)
    msg = translateMsg;
  } else if (err.message) {
    msg = err.message;
  }
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_FAIL,
    state: msg
  }
}

//upload file
const uploadFile = async (data, method) => {
  return new Promise(async (resolve, reject) => {

    axios.post(Server.ImageAPI + method, data)
      .then(function (response) {
        resolve(creating_account_reviewed_msg);
      })
      .catch(function (error) {
        resolve(creating_account_reviewed_msg);
        // reject(error);
      })
  })
}

// get List Success
const getListSuccess = (data) => {
  return {
    type: actionTypes.GET_STAFF_LIST,
    state: data
  }
}

// get staff list action
export const getStaffList = (client, request) => {
  return dispatch => {
    const query = gql`{
            get_staff_list {
                _id
                email
                firstname
                lastname
                last_login
                phone_number
                accesspages{
                    user_list {
                      status
                    }
                    user_request {
                      status
                      sub {
                        view_user
                        user_accept
                        add_user
                      }
                    }
                    plan {
                      status
                    }
                    language {
                      status
                    }
                    staff {
                      status
                      sub {
                        add_staff
                        view_staff
                        update_staff
                        block_staff
                        delete_staff
                      }
                    }
                    masters {
                      status
                    }
                    project {
                      status
                    }
                    licence_request{
                      status
                    }
                    plan_request{
                        status
                    }
                    project_token{
                        status
                    }
                    send_notification{
                        status
                    }
                    email_management{
                        status
                    }
                    notification_management{
                        status
                    }
                  }
                active
                created_at
                updated_at
                employee_id
            }
          }
        `;
    client.query({
      query: query,
    }).then(response => {
      dispatch(getListSuccess(response.data.get_staff_list))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

//create staff action
export const createStaffUser = (client, request) => {
  return dispatch => {
    const mutation = gql`
        mutation create_staff($email: String!, $firstname: String!, $lastname: String!, $accesspages: String, $phone_number: String!) {
            create_staff(email: $email, firstname: $firstname, lastname: $lastname, accesspages: $accesspages,phone_number:$phone_number) {
              _id
              email
              firstname
              lastname
              last_login
              phone_number
              accesspages{
                user_list {
                  status
                }
                user_request {
                  status
                  sub {
                    view_user
                    user_accept
                    add_user
                  }
                }
                plan {
                  status
                }
                language {
                  status
                }
                staff {
                  status
                  sub {
                    add_staff
                    view_staff
                    update_staff
                    block_staff
                    delete_staff
                  }
                }
                masters {
                  status
                }
                project {
                  status
                }
                licence_request{
                    status
                }
                plan_request{
                    status
                }
                project_token{
                    status
                }
                send_notification{
                    status
                }
                email_management{
                    status
                }
                notification_management{
                    status
                }
              }
              active
              created_at
              updated_at
              employee_id
            }
          }
        `;
    client.mutate({
      mutation: mutation,
      variables: {
        email: Encrypt(request.email),
        firstname: Encrypt(request.firstname),
        lastname: Encrypt(request.lastname),
        phone_number: Encrypt(request.phone_number),
        accesspages: Encrypt(JSON.stringify(request.accesspages)),
      }
    })
      .then(response => {
        dispatch(notificationSuccess(staffcreate))
        dispatch(getListSuccess(response.data.create_staff, types.Create))
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}

// on Login Succeed
const onLoginSucceed = (data) => {
  return {
    type: actionTypes.LOGIN_SUCCEED,
    state: data
  }
}

// store User Data
const storeUserData = (resp) => {
  let finderprint = new fingerprint().get();
  return jwt.sign(resp, finderprint.toString())
}

// update staff user action
export const updateStaffUser = (client, request) => {
  return dispatch => {
    const mutation = gql`
        mutation edit_staff($id: String!,$firstname: String!, $lastname: String!, $accesspages: String,$phone_number: String!) {
            edit_staff(id: $id,firstname: $firstname, lastname: $lastname, accesspages: $accesspages,phone_number: $phone_number) {
              _id
              email
              firstname
              lastname
              last_login
              phone_number
              accesspages{
                user_list {
                  status
                }
                user_request {
                  status
                  sub {
                    view_user
                    user_accept
                    add_user
                  }
                }
                plan {
                  status
                }
                language {
                  status
                }
                staff {
                  status
                  sub {
                    add_staff
                    view_staff
                    update_staff
                    block_staff
                    delete_staff
                  }
                }
                masters {
                  status
                }
                project {
                  status
                }
                licence_request{
                    status
                }
                plan_request{
                    status
                }
                project_token{
                    status
                }
                send_notification{
                    status
                }
                email_management{
                    status
                }
                notification_management{
                    status
                }
              }
              active
              created_at
              updated_at
              employee_id
            }
          }
        `;
    client.mutate({
      mutation: mutation,
      variables: {
        id: Encrypt(request._id),
        firstname: Encrypt(request.firstname),
        lastname: Encrypt(request.lastname),
        phone_number: Encrypt(request.phone_number),
        accesspages: request.accesspages ? Encrypt(JSON.stringify(request.accesspages)) : undefined,
      }
    })
      .then(response => {

        if (request.is_user_update) {
          var credential = localStorage.getItem("_user_");
          if (credential) {
            let finderprint = new fingerprint().get();
            credential = jwt.verify(credential, finderprint.toString());

            let storageData = {
              ...credential,
              firstname: request.firstname,
              lastname: request.lastname,
              phone_number: request.phone_number,
            }
            localStorage.setItem("_user_", storeUserData(storageData))
            dispatch(onLoginSucceed(storageData))
          }
        }
        if (typeof (request.profile_pics) === "object") {

          if (request.profile_pics && request.userid) {
            var data = new FormData();
            data.append('files', request.profile_pics[0], 'user_pic.jpg');

            data.append('userid', request.userid);

            axios.post(Server.ImageAPI + 'user_profile_pic/', data)
            .then(function (response) {
              var credential = localStorage.getItem("_user_");
              if (credential) {
                let finderprint = new fingerprint().get();
                credential = jwt.verify(credential, finderprint.toString());

                let pic = JSON.stringify([{ user_pic: response.data.url }]);
                let storageData = {
                  ...credential,
                  userattachments: pic
                }
                localStorage.setItem("_user_", storeUserData(storageData))
                dispatch(onLoginSucceed(storageData))
                // window.location.reload()
              }
            })
            .catch(function (error) {
              dispatch(notificationFail(error))
            })

            // uploadFile(data, 'user_profile_pic/').then((response) => {
            //   window.location.reload()
            // }).catch((err) => {
            //   dispatch(notificationFail(err))
            // })
          }
        }
        if(request.action_type == 'profile_update'){
          dispatch(notificationSuccess(UserSettingChanged))
        }
        if(request.action_type == 'staff_update'){
          dispatch(notificationSuccess(staffupdate))
        }
        dispatch(getListSuccess(response.data.edit_staff, types.Update))

      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}

//delete staff user action
export const deleteStaffUser = (client, request) => {
  return dispatch => {
    const mutation = gql`
        mutation delete_staff($id: String!) {
            delete_staff(id: $id) {
              _id
              email
              firstname
              lastname
              last_login
              phone_number
              accesspages{
                user_list {
                  status
                }
                user_request {
                  status
                  sub {
                    view_user
                    user_accept
                    add_user
                  }
                }
                plan {
                  status
                }
                language {
                  status
                }
                staff {
                  status
                  sub {
                    add_staff
                    view_staff
                    update_staff
                    block_staff
                    delete_staff
                  }
                }
                masters {
                  status
                }
                project {
                  status
                }
                licence_request{
                  status
                }
                plan_request{
                    status
                }
                project_token{
                    status
                }
                send_notification{
                    status
                }
                email_management{
                    status
                }
                notification_management{
                    status
                }
              }
              active
              created_at
              updated_at
              employee_id
            }
          }          
        `;
    client.mutate({
      mutation: mutation,
      variables: {
        id: Encrypt(request._id),
      }
    })
      .then(response => {
        dispatch(notificationSuccess(staffdelete))
        dispatch(getListSuccess(response.data.delete_staff, types.Delete))
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}

//block user action
export const blockStaffUser = (client, request) => {
  return dispatch => {
    const mutation = gql`
        mutation block_staff($id: String!, $active: Boolean!) {
            block_staff(id: $id, active:$active) {
              _id
              email
              firstname
              lastname
              last_login
              phone_number
              accesspages{
                user_list {
                  status
                }
                user_request {
                  status
                  sub {
                    view_user
                    user_accept
                    add_user
                  }
                }
                plan {
                  status
                }
                language {
                  status
                }
                staff {
                  status
                  sub {
                    add_staff
                    view_staff
                    update_staff
                    block_staff
                    delete_staff
                  }
                }
                masters {
                  status
                }
                project {
                  status
                }
                licence_request{
                  status
                }
                plan_request{
                    status
                }
                project_token{
                    status
                }
                send_notification{
                    status
                }
                email_management{
                    status
                }
                notification_management{
                    status
                }
              }
              active
              created_at
              updated_at
              employee_id
            }
          }       
        `;
    client.mutate({
      mutation: mutation,
      variables: {
        id: Encrypt(request._id),
        active: !request.active
      }
    })
      .then(response => {
        dispatch(notificationSuccess(request.active ? staffUserBlock : staffUserUnBlock))
        dispatch(getListSuccess(response.data.block_staff))
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}