import moment from 'moment-timezone';
import React, { Fragment, useReducer, useState } from 'react';
import {
	Button,
	ButtonToolbar,
	Card,
	CardBody,
	Col,
	Container,
	Input,
	Label,
	Modal,
	Nav,
	NavItem,
	NavLink,
	Row,
	Spinner,
	TabContent,
	TabPane,
} from 'reactstrap';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	FormGroup,
	Icon,
	Tooltip,
} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HighchartsReact from 'highcharts-react-official';
import { useEffect } from 'react';
import Dropzone from 'react-dropzone';
import Lightbox from 'react-images';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import styled from 'styled-components';
import __ from 'underscore';
import * as actions from '../../../../Store/actions/index';
import formatDepth, { formatDepthNumber, formatLngLat } from '../../../lib/formatDepth';
import useCreateGraphProbings from '../../../lib/useCreateGraphProbings';
import { getMaterialListfilter } from '../../Common/materialList';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import ModalTodo from '../Campaign/todo';
import ModalHistory from '../Campaign/todoHistory';
import DatePicker, { TimePicker } from '../components/DatePicker';
import FormGroupRequired from '../components/formGroupRequired';
import TodoCheck from '../components/TodoCheck';
import ColorPicker from '../components/colorPicker';
import ModalMapProbing from './mapProbing';
import ModalDeleteConfirmation from '../components/dataVisualization/modals/deleteConfirmation';
import Form from '../components/form';
import TwoLabelsSwitch from '../components/TwoLabelsSwitch';
import useLocalStorage from '../../../lib/useLocalStorage';

moment.tz.setDefault('Europe/Paris');

let tlang = ChangeAppLanguageFront.translateLanguage;

const client_project_longtitude = tlang('client_project_longtitude') || 'Longitude';
const client_project_latitude = tlang('client_project_latitude') || 'Latitude';
const client_campaign_reference = tlang('client_campaign_reference') || 'Reference';
const client_probing_category = tlang('client_probing_category') || 'Category';
const client_probing_lithology = tlang('client_probing_lithology') || 'Lithology';
const client_Moisture = tlang('client_Moisture') || 'Moisture';
const common_btn_okay = tlang('common_btn_okay') || 'Okay';
const client_lithology_from = tlang('client_lithology_from') || 'From';
const client_lithology_to = tlang('client_lithology_to') || 'To';
const client_Inclutions = tlang('client_Inclutions') || 'Inclutions';
const client_Anomaly = tlang('client_Anomaly') || 'Anomaly';
const laboratory_txt = tlang('laboratory_txt') || 'Laboratory';
const client_Observation = tlang('client_Observation') || 'Observation';
const client_probing_inputtext = tlang('client_probing_inputtext') || 'Input text';
//const client_apparent_depth = tlang('client_apparent_depth') || 'Apparent Depth';
const client_probing_date = tlang('client_probing_date') || 'Probing Date';
const client_probing_lastname = tlang('client_probing_lastname') || 'Last Name';
const client_probing_Contact_Number = tlang('client_probing_Contact_Number') || 'Contact Number';
const client_probing_remarks = tlang('client_probing_remarks') || 'Remarks';
const client_probing_actual_depth = tlang('client_probing_actual_depth') || 'Actual Depth';
const client_probing_field_engineer = tlang('client_probing_field_engineer') || 'Field Engineer';
const client_probing_firstname = tlang('client_probing_firstname') || 'First Name';
const client_viewprobing_General_Details =
	tlang('client_viewprobing_General_Details') || 'General Details';
const client_sample_number = tlang('client_sample_number') || 'Sample Number';
const client_sample_name = tlang('client_sample_name') || 'Sample Name';
const client_Depth = tlang('client_Depth') || 'Depth';
const client_probing_depth_in_probing =
	tlang('client_probing_depth_in_probing') || 'Depth in Probing';
const client_Length = tlang('client_Length') || 'Length';
const client_probing_comment = tlang('client_probing_comment') || 'Comments';
const client_probing_audio_files = tlang('client_probing_audio_files') || 'Audio files';
const client_probing_sample_altitude = tlang('client_probing_sample_altitude') || 'Sample altitude';
const client_probing_remark = tlang('client_probing_remark') || 'Remark';
const client_probing_complete_layer = tlang('client_probing_complete_layer') || 'Complete layer';
const client_probing_expecteddepth = tlang('client_probing_expecteddepth') || 'Expected depth';
const client_probing_upper_depth = tlang('client_probing_upper_depth') || 'Upper Depth';
const client_probing_lower_depth = tlang('client_probing_lower_depth') || 'Lower Depth';
const client_probing_Layer_Length = tlang('client_probing_Layer_Length') || 'Layer Length';
const client_probing_Layer_Image = tlang('client_probing_Layer_Image') || 'Layer Image';
const client_campaign_color = tlang('client_campaign_color') || 'Color';
const client_probing_pid = tlang('client_probing_pid') || 'PID';
const mapbox_images = tlang('mapbox_images') || 'Images';
const client_project_altitude = tlang('client_project_altitude') || 'Altitude';
const client_probing_layer = tlang('client_probing_layer') || 'Layer';
const label_layer_column_chart = tlang('label_layer_column_chart') || 'Layer column chart';
const client_Close_Probing = tlang('client_Close_Probing') || 'Close Probing';
const todo_history = tlang('todo_history') || 'Todo history';
const client_todo_list_btn = tlang('client_todo_list_btn') || 'Todo List';
const enter_latitude = tlang('enter_latitude') || 'Please enter latitude';
const enter_longitude = tlang('enter_longitude') || 'Please enter longitude';
const client_probing_please_add_altitude =
	tlang('client_probing_please_add_altitude') || 'Please add altitude';
const client_probing_please_add_probing_date =
	tlang('client_probing_please_add_probing_date') || 'Please add probing date';
const client_probing_please_add_layer_length =
	tlang('client_probing_please_add_layer_length') || 'Please add layer length';
const client_edit_probing = tlang('client_edit_probing') || 'Edit Probing';
const upload_probing_image = tlang('upload_probing_image') || 'Upload Probing Image';
const tooltip_delete = tlang('tooltip_delete') || 'Delete';
const tooltip_edit = tlang('tooltip_edit') || 'Edit';
const client_probing_operator = tlang('client_probing_operator') || 'Operator';
const client_add_layer = tlang('client_add_layer') || 'Add Layer';
const client_delete_layer = tlang('client_delete_layer') || 'Delete Layer';
const client_default = tlang('client_default') || 'Default';
const client_manual = tlang('client_manual') || 'Manual';
const probing_upload_layer_img = tlang('probing_upload_layer_img') || 'Upload Your Layer Image';
const material_masters_soil_family = tlang('materail_master_soil_family') || 'Soil Family';
const add_sample_btn = tlang('add_sample_btn') || 'Add Sample';
const client_probing_time_of_sampling =
	tlang('client_probing_time_of_sampling') || 'Time of sampling';
const client_close_probing_message =
	tlang('client_close_probing_message') || 'Are you sure you want to close this Probing';
const client_Update_Probing = tlang('client_Update_Probing') || 'Update Probing';
const client_back_btn = tlang('client_back_btn') || 'Back';
const cancel_btn = tlang('cancel_btn') || 'Cancel';
const same_expected_depth_and_layer_length =
	tlang('same_expected_depth_and_layer_length') ||
	'Your expected depth and total number of layers depth is same. Would you like to close the probing?';
const common_btn_no = tlang('common_btn_no') || 'No';
const tooltip_add = tlang('tooltip_add') || 'Add';
const label_probing_name = tlang('label_probing_name') || 'Probing Name';
const client_probing_name_validate =
	tlang('client_probing_name_validate') || 'Please Add Probing Name';

const STYLE_IMAGE_DROPBOX = {
	minHeight: '45px',
	width: '90%',
	height: '45px',
	border: 'solid 1px #dadada',
	zIndex: '1',
};

const masterData = [
	'category',
	'color',
	'lithology',
	'anomaly',
	'geology',
	'nature',
	'organoleptic',
	'moisture',
	'reason',
	//'observation',
];

function reducer(state, action) {
	try {
		switch (action.type) {
			case 'addImage': {
				const newImages = action.image.map(fl =>
					Object.assign(fl, { preview: URL.createObjectURL(fl) })
				);
				const images = state.images.concat(
					newImages.map(newImage => ({ url: newImage.preview, file: newImage }))
				);
				return { ...state, images };
			}
			case 'deleteImage': {
				const images = state.images.filter((i, idx) => idx !== action.idx);
				const delImage = state.images.find((i, idx) => idx === action.idx);
				if (delImage?.image?.filename) state.delImgsProbing.push(delImage);
				return { ...state, images };
			}
			case 'addLayer': {
				state.layer.push({
					anomaly: { selectedanomaly: [] },
					category: { isDropDoun: true },
					color: [
						{
							isDropDoun: true,
							selectedcolorfrom: {},
							selectedcolorto: {},
							selectedcolorinclutions: {},
						},
					],
					extraInformation: {},
					generalDetails: { sampleImage: [], layerLength: 0 },
					layerName: '',
					layerNumber: '',
					lithology: [
						{
							isDropDoun: true,
							selectedanomaly: [],
							selectedlithologyfrom: {},
							selectedlithologyto: {},
						},
					],
					moisture: [{ isDropDoun: true, selectedmoisturelist: [] }],
					observation: { isDropDoun: false, selectedobservation: [] },
					organoleptic: { color: [] },
					sample: [],
				});
				return { ...state };
			}
			case 'deleteLayer': {
				const layer = state.layer.filter((l, idx) => idx !== action.idx);
				return { ...state, layer };
			}
			case 'addImageLayer': {
				const newImages = action.image.map(fl =>
					Object.assign(fl, { preview: URL.createObjectURL(fl) })
				);
				const gd = state.layer[action.layer].generalDetails;
				gd.sampleImage.push(
					...newImages.map(newImage => ({ url: newImage.preview, file: newImage }))
				);
				const layerImages = state.layerImages.concat(
					newImages.map(newImage => ({
						url: newImage.preview,
						file: newImage,
						layerindex: action.layer,
					}))
				);
				return { ...state, layerImages };
				//return { ...state };
			}
			case 'deleteImageLayer': {
				const gd = state.layer[action.layer].generalDetails;
				const delImgsLayer = gd.sampleImage
					.filter((i, idx) => idx === action.idx)
					.map(fl => Object.assign(fl, { layerindex: action.layer }));
				if (delImgsLayer[0]?.filename) state.delImgsLayer.push(delImgsLayer[0]);
				const layerImages = state.layerImages.filter(f => f.url !== delImgsLayer[0].url);
				gd.sampleImage = gd.sampleImage.filter((i, idx) => idx !== action.idx);
				return { ...state, layerImages };
			}

			case 'updateLayer': {
				const layer = state.layer[action.idx];
				if (layer) {
					const keys = action.field.split('.');
					let obj = layer;
					for (let k = 0; k < keys.length - 1; k++) {
						const isArray = keys[k].indexOf('[');
						if (isArray >= 0) {
							const key = keys[k].substring(0, isArray);
							const idArray = keys[k].substring(isArray + 1, keys[k].length - 1);
							obj = obj[key][idArray] ?? {};
						} else {
							obj = obj[keys[k]] ?? {};
						}
					}
					const lastKey = keys[keys.length - 1];
					obj[lastKey] = action.value;
				}
				return { ...state };
			}
			case 'addSample': {
				console.log('add sample to layer', action.layer);
				const layer = state.layer[action.layer];
				const samplePrec = layer.sample.length ? layer.sample[layer.sample.length - 1] : {};
				layer.sample.push({
					isCompleteLayer: !layer.sample.length ? true : false,
					samplingTime: new Date().toISOString(),
					labactive: samplePrec.labactive || false,
					laboratory: samplePrec.laboratory || '',
					upper_depth: samplePrec.lower_depth || 0,
					lower_depth: Number(layer.generalDetails.layerLength || 0),
					sampleNameTop: '',
					sampleNameBottom: '',
					sampleNameIsManual: false,
					sampleNameManual: '',
					sample_depth: '',
					sample_depth_in_probing: '',
					sample_length: '',
					middel_depth: '',
				});
				return { ...state };
			}
			case 'updateSample': {
				const layer = state.layer[action.layer];
				if (layer) {
					const sample = layer.sample[action.sample];
					if (sample) sample[action.field] = action.value;
					if (action.field === 'sampleNameManual') sample.sampleNameIsManual = true;
				}
				return { ...state };
			}
			case 'deleteSample': {
				const layer = state.layer[action.layerIdx];
				const sample = layer.sample.filter((l, idx) => idx !== action.sampleIdx);
				layer.sample = [...sample];
				return { ...state };
			}
			case 'addLithology': {
				const layer = state.layer[action.layerIdx];
				layer.lithology.push({
					from: '',
					inclutions: '',
					isDropDoun: true,
					isactive: false,
					selectedanomaly: [],
					selectedlithologyfrom: { value: '', label: '' },
					selectedlithologyinclutions: '',
					selectedlithologyto: '',
					soil_family: '',
					to: '',
				});
				return { ...state };
			}
			case 'removeLithology': {
				const layer = state.layer[action.layerIdx];
				layer.lithology = layer.lithology.filter((l, i) => i !== action.idx);
				return { ...state };
			}
			case 'addColor': {
				const layer = state.layer[action.layerIdx];
				layer.color.push({
					from: '',
					fromname: '',
					inclutions: '',
					inclutionsname: '',
					isDropDoun: true,
					isactive: false,
					selectedcolorfrom: { value: '', label: '' },
					selectedcolorinclutions: '',
					selectedcolorto: '',
					to: '',
					toname: '',
				});
				return { ...state };
			}
			case 'removeColor': {
				const layer = state.layer[action.layerIdx];
				layer.color = layer.color.filter((l, i) => i !== action.idx);
				return { ...state };
			}
			case 'addMoisture': {
				const layer = state.layer[action.layerIdx];
				layer.moisture.push({
					isDropDoun: true,
					isactive: false,
					manualMoisture: [],
					selectedmoisturelist: '',
					value: '',
				});
				return { ...state };
			}
			case 'removeMoisture': {
				const layer = state.layer[action.layerIdx];
				layer.moisture = layer.moisture.filter((l, i) => i !== action.idx);
				return { ...state };
			}
			default:
				const upd = {};
				for (const key of Object.keys(action)) {
					upd[key] = action[key];
				}
				// console.log(`upd`, { upd });
				return { ...state, ...upd };
		}
	} catch (e) {
		console.error(e);
		return state;
	}
}

const initialState = {
	campaignid: '',
	probingid: '',
	probingname: '',
	probingnameIsManual: false,
	probingcode: '',
	longitude: 0,
	latitude: 0,
	newLongitude: 0,
	newLatitude: 0,
	apparentdepth: '',
	actualdepth: '',
	status: '',
	onsiteExpertName: '',
	fieldEngineer: '',
	// probingDate: Date.now(),
	probingDate: moment().valueOf(),
	probingTool: '',
	firstname: '',
	lastname: '',
	contactnumber: '',
	layer: [],
	endstatus: false,
	inputtext: '',
	altitude: '',
	expecteddepth: '',
	remark: '',
	images: [],
	audios: [],
	delImgsProbing: [],
	delImgsLayer: [],
	layerImages: [],
};

export default function ModalEditProbing({ isOpen, onClose, probingIds, selectedId }) {
	const dispatch = useDispatch();
	const apolloClient = useSelector(state => state.apolloClient);
	const authReducer = useSelector(state => state.authReducer);
	const masterReducer = useSelector(state => state.masterReducer);
	const probingReducer = useSelector(state => state.probingReducer);
	const notification = useSelector(state => state.notification);
	const [lightBoxUrl, setLightBoxUrl] = useState('');
	const [activeTab, setActiveTab] = useState(0);
	const [probingIdx, setProbingIdx] = useState(-1);
	const [lists, setLists] = useState({});
	const [showTodo, setShowTodo] = useState(false);
	const [showHistory, setShowHistory] = useState(false);
	const [showMap, setShowMap] = useState(false);
	const [state, localDispatch] = useReducer(reducer, initialState);
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [updating, setUpdating] = useState(false);
	const [editSample, setEditSample] = useState(-1);
	const [showLock, setShowLock] = useState('');
	const [userConfirmEndProbing, setUserConfirmEndProbing] = useState('');
	const [display, setDisplay] = useLocalStorage('probing-display', 'c');

	const { chartData, Highcharts, loading: loadingGraph } = useCreateGraphProbings(
		state,
		state.probingname + ' ' + label_layer_column_chart,
		'layer',
		true,
		true
	);

	const unit = authReducer.userData.default_unit || 'M';
	const canEdit = authReducer.userData?.useraccesspage?.probing_management && !state.endstatus;

	if (chartData.chart) chartData.chart.height = '900px';

	useEffect(() => {
		setUpdating(false);
		localDispatch({ delImgsProbing: [], delImgsLayer: [], layerImages: [] });
	}, [notification]);

	useEffect(() => {
		const params = {
			type_list: JSON.stringify(masterData),
			lang_code: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
		};
		dispatch(actions.getTypeWiseOtherMatreials(apolloClient.masters, params));
	}, []);

	useEffect(() => {
		if (!Array.isArray(probingIds)) return;
		if (probingIdx === -1) {
			let idx = probingIds.findIndex(p => p === selectedId);
			if (idx < 0) idx = 0;
			setProbingIdx(idx);
		}
	}, [probingIds, selectedId]);

	useEffect(() => {
		const l = {};
		// colorlist from master
		const colorList = masterReducer.colorList;
		if (Array.isArray(colorList))
			if (lists?.colorList?.length !== colorList.length)
				l.colorList = getMaterialListfilter(colorList)
					.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
					.map(c => ({
						value: c._id,
						label: c.name,
						color: c.value,
					}));
		// geology list from master
		const geologyList = masterReducer.geologyList;
		if (Array.isArray(geologyList))
			if (lists?.geologyList?.length !== geologyList.length)
				l.geologyList = getMaterialListfilter(geologyList).map(c => ({
					value: c._id,
					label: c.name,
				}));
		// lithology list from master
		const lithologyList = masterReducer.lithologyList;
		if (Array.isArray(lithologyList))
			if (lists?.lithologyList?.length !== lithologyList.length)
				l.lithologyList = getMaterialListfilter(lithologyList)
					.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
					.map(c => ({
						value: c._id,
						label: c.name,
						geologyId: c.value,
					}));
		// category list from master
		const categoryList = masterReducer.categoryList;
		if (Array.isArray(categoryList))
			if (lists?.categoryList?.length !== categoryList.length)
				l.categoryList = getMaterialListfilter(categoryList)
					.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
					.map(c => ({
						value: c._id,
						label: c.name,
					}));
		// moisture list from master
		const moistureList = masterReducer.moistureList;
		if (Array.isArray(moistureList))
			if (lists?.moistureList?.length !== moistureList.length)
				l.moistureList = getMaterialListfilter(moistureList)
					.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
					.map(c => ({
						value: c._id,
						label: c.name,
					}));
		// organoleptic list from master
		const organolepticList = masterReducer.organolepticList;
		if (Array.isArray(organolepticList))
			if (lists?.organolepticList?.length !== organolepticList.length)
				l.organolepticList = getMaterialListfilter(organolepticList).map(c => ({
					value: c._id,
					label: c.name,
				}));
		// anomaly list from master
		const anomalyList = masterReducer.anomalyList;
		if (Array.isArray(anomalyList))
			if (lists?.anomalyList?.length !== anomalyList.length)
				l.anomalyList = getMaterialListfilter(anomalyList)
					.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
					.map(c => ({
						value: c._id,
						label: c.name,
					}));
		// reason list from master
		const reasonList = masterReducer.reasonList;
		if (Array.isArray(reasonList))
			if (lists?.reasonList?.length !== reasonList.length)
				l.reasonList = getMaterialListfilter(reasonList).map(c => ({
					value: c._id,
					label: c.name,
				}));
		// observation list from master
		// const observationList = masterReducer.observationList;
		// if (Array.isArray(observationList))
		// 	if (lists?.observationList?.length !== observationList.length)
		// 		l.observationList = getMaterialListfilter(observationList).map(c => ({
		// 			value: c._id,
		// 			label: c.name,
		// 		}));
		if (!__.isEmpty(l)) setLists(ls => ({ ...ls, ...l }));
	}, [
		masterReducer.colorList?.length,
		masterReducer.geologyList?.length,
		masterReducer.lithologyList?.length,
		masterReducer.categoryList?.length,
		masterReducer.moistureList?.length,
		masterReducer.organolepticList?.length,
		masterReducer.anomalyList?.length,
		masterReducer.reasonList?.length,
		//masterReducer.observationList?.length,
	]);

	useEffect(() => {
		if (!Array.isArray(probingIds)) return;
		if (!probingIds[probingIdx]) return;
		const request = { probingid: probingIds[probingIdx] };
		dispatch(actions.getProbingDetail(apolloClient.probing, request));
		setLoading(true);
	}, [probingIdx]);

	useEffect(() => {
		if (!probingReducer.getProbingDetail) return;
		setLoading(false);
		const probing = probingReducer.getProbingDetail;
		console.log('probing :>> ', probing);
		const images = JSON.parse(probing.probingimage);
		const probingImages = [];
		for (const image of images) {
			const keys = Object.keys(image);
			const url = image[keys[0]];
			probingImages.push({ image, url });
		}
		const audios = JSON.parse(probing.probingaudio);
		const probingAudios = [];
		for (const audio of audios) {
			const keys = Object.keys(audio);
			const url = audio[keys[0]];
			probingAudios.push(url);
		}
		for (const layer of probing.layer) {
			if (layer?.generalDetails) {
				for (const img of layer.generalDetails.sampleImage) {
					const keys = Object.keys(img);
					const url = img[keys[0]];
					img.image = { ...img };
					img.url = url;
				}
			}
		}


		localDispatch({
			campaignid: probing.campaignid,
			probingid: probing.probingid,
			probingname: probing.probingname || probing.probingcode,
			probingcode: probing.probingcode,
			longitude: probing.longitude,
			latitude: probing.latitude,
			newLongitude: probing.longitude,
			newLatitude: probing.latitude,
			altitude: Number(probing.altitude),
			apparentdepth: Number(probing.apparentdepth),
			expecteddepth: Number(probing.expecteddepth),
			actualdepth: Number(probing.actualdepth),
			status: probing.status,
			onsiteExpertName: probing.onsiteExpertName || '',
			fieldEngineer: probing.fieldEngineer || '',
			// probingDate: moment.isDate(probing.probingDate) ? new Date(probing.probingDate) : Date.now(),
			probingDate: probing?.probingDate && moment.unix(probing?.probingDate).isValid() ? Number(probing.probingDate) : (probing?.startdate ? moment.utc(probing.startdate).valueOf() : moment().valueOf()),
			probingTool: probing.probingTool || '',
			firstname: probing.firstname || '',
			lastname: probing.lastname || '',
			contactnumber: probing.contactnumber || '',
			layer: probing.layer,
			endstatus: probing.endstatus,
			inputtext: probing.inputtext || '',
			remark: probing.remark || '',
			images: probingImages,
			audios: probingAudios,
			// probingaudio: probing.probingaudio,
		});
		setActiveTab(0);
	}, [probingReducer.getProbingDetail]);

	function validate(fld, value) {
		if (['altitude', 'expecteddepth', 'apparentdepth', 'actualdepth'].includes(fld)) {
			let n = Number(value ?? 0);
			if (unit === 'M') n = n * 100;
			localDispatch({ [fld]: n });
		} else {
			localDispatch({ [fld]: value });
		}
		if (Array.isArray(value)) {
			if (!value.length) {
				setErrors(e => ({ ...e, [fld]: true }));
				return;
			}
		} else {
			if (value === '' || value === null || value === undefined) {
				setErrors(e => ({ ...e, [fld]: true }));
				return;
			}
		}
		setErrors(e => ({ ...e, [fld]: false }));
	}

	function handleClickPrevious() {
		let nextIdx = probingIdx - 1;
		if (nextIdx < 0) nextIdx = probingIds.length - 1;
		setProbingIdx(nextIdx);
	}

	function handleClickNext() {
		let nextIdx = probingIdx + 1;
		if (nextIdx >= probingIds.length) nextIdx = 0;
		setProbingIdx(nextIdx);
	}

	function handleCloseProbing() {
		if (showLock === 'lock') {
			const requestParams = {
				projectid: authReducer.userData.projectid,
				campaignid: state.campaignid || '',
				probingid: state.probingid,
				reasonid: '',
				reasonname: '',
				detail: '',
				endstatus: true,
				campaignidForList: state.campaignid || '',
			};
			dispatch(actions.closeProbing(apolloClient.probing, requestParams));
		}
		if (showLock === 'confirm') {
			setUserConfirmEndProbing('yes');
		}
		setShowLock('');
	}

	function calSampleInfo(layerIdx, sampleIdx) {
		const layer = state.layer[layerIdx];
		const currentLayerLength = Number(layer.generalDetails.layerLength || 0);
		const sample = layer.sample[sampleIdx];
		let sampleObj = {
			sample_depth: '',
			sample_length: '',
			sample_depth_in_probing: '',
			sampleNameTop: '',
			sampleNameBottom: '',
			middel_depth: '',
		};
		//If first sample of each layer is complete layer
		if (sample.isCompleteLayer) {
			sample.upper_depth = 0;
			sample.lower_depth = currentLayerLength;
		}

		//Total Previous layer length calculation
		let totalpreLayerLength = 0;
		for (let idx = 0; idx < layerIdx; idx++) {
			const layerDetails = state.layer[idx];
			const layerLength = Number(layerDetails.generalDetails.layerLength || 0);
			totalpreLayerLength += layerLength;
		}

		const lyrStartAltitude = state.altitude - totalpreLayerLength;
		if (!isNaN(sample.upper_depth) && !isNaN(sample.lower_depth)) {
			sampleObj.sampleNameTop = Number(totalpreLayerLength) + Number(sample.upper_depth);
			sampleObj.sampleNameBottom = Number(totalpreLayerLength) + Number(sample.lower_depth);
			sampleObj.sample_depth = (Number(sample.lower_depth) + Number(sample.upper_depth)) / 2;
			sampleObj.sample_length = Number(sample.lower_depth) - Number(sample.upper_depth);
			sampleObj.sample_depth_in_probing =
				Number(totalpreLayerLength) + Number(sampleObj.sample_depth);
			sampleObj.middel_depth = lyrStartAltitude - sampleObj.sample_depth;
		}
		return sampleObj;
	}

	function prepareSample(layerIdx, sampleIdx) {
		const layer = state.layer[layerIdx];
		const sample = layer.sample[sampleIdx];
		sample.sampleNo = sample.sampleNo || `${state.probingcode}-${layerIdx + 1}-${sampleIdx + 1}`;

		const sampleInfo = calSampleInfo(layerIdx, sampleIdx);
		sample.sampleNameTop = sampleInfo.sampleNameTop;
		sample.sampleNameBottom = sampleInfo.sampleNameBottom;
		sample.sample_length = sampleInfo.sample_length;
		sample.sample_depth = sampleInfo.sample_depth;
		sample.sample_depth_in_probing = sampleInfo.sample_depth_in_probing;
		sample.middel_depth = sampleInfo.middel_depth;

		let sampleName = '';
		if (!sample.sampleNameIsManual) {
			if (!isNaN(sample.sampleNameTop) && !isNaN(sample.sampleNameBottom))
				sampleName = `${state.probingname} (${formatDepth(sample.sampleNameTop, unit)}-${formatDepth(
					sample.sampleNameBottom, unit
				)})`;
			else sampleName = state.probingcode;
		} else {
			sampleName = sample.sampleNameManual || '';
		}

		sample.sampleName = !sample.sampleNameIsManual ? sampleName || sample?.sampleName : sampleName; //If sampleName already created once then use it, If it is new sample then calculate sampleName
		const currentLayerLength = Number(layer.generalDetails.layerLength || 0);

		let betweenStart = 0;
		let betweenEnd = currentLayerLength;
		if (sampleIdx === 0) {
			betweenStart = 0;
		} else {
			const prevSample = layer.sample[sampleIdx - 1];
			betweenStart = prevSample.lower_depth || 0;
		}
		return { betweenStart, betweenEnd };
	}

	function calculateActualDepth() {
		setTimeout(function () {
			const probing = state;
			let totalLayerLength = 0;
			for (const layer of probing.layer) {
				if (layer?.generalDetails)
					totalLayerLength += Number(layer.generalDetails.layerLength || 0);
			}
			const actualdepth = formatDepthNumber(totalLayerLength, unit);
			validate('actualdepth', actualdepth);
		}, 100);
	}

	useEffect(() => {
		calculateActualDepth();
	}, [state.layer.length, probingReducer.getProbingDetail]);

	useEffect(() => {
		if (userConfirmEndProbing !== '') updateProbing();
	}, [userConfirmEndProbing]);

	function delProbingImages(type) {
		const probing = state;
		const imgArr = probing[`delImgs${type}`].map(i => ({
			filename: i.image.filename,
			filerevisionid: i.image.rev_id,
			layerindex: i.layerindex ?? '',
		}));
		const req = {
			deletedoc: JSON.stringify(imgArr),
			id:
				type === 'Layer'
					? probing.probingid + '-' + imgArr[0].layerindex
					: 'IMG-' + probing.probingid,
		};
		dispatch(actions.deleteLayerImage(apolloClient.client, req));
	}

	function updateProbing() {

		const probing = state;

		const errs = {};
		if (!state.longitude) errs.longitude = true;
		if (!state.latitude) errs.latitude = true;
		if (!state.altitude) errs.altitude = true;
		if (!state.probingDate) errs.probingDate = true;
		if (!state.probingname.trim()) errs.probingname = true;

		probing.layer.map((l, lindex) => {
			if (l.generalDetails.layerLength === 0) {
				errs['layerLength_' + lindex] = true;
			}
		})

		setErrors(errs);
		if (!__.isEmpty(errs)) return;

		const languageCode = localStorage.getItem('language') || 'en';

		console.log('state :>> ', state);


		const probingOrg = probingReducer.getProbingDetail;

		const existingImages = probing.images.filter(i => i.image).map(i => i.image);
		const newImages = probing.images.filter(i => i.file).map(i => i.file);
		const probing_images = existingImages.concat(newImages);
		console.log('probing_images', probing_images);

		// const existingSampleImages = probing.layer
		// 	.map((l, layerindex) =>
		// 		l.generalDetails.sampleImage.filter(i => i.image).map(i => ({ ...i.image, layerindex }))
		// 	)
		// 	.flat();
		// const newSampleImages = probing.layer
		// 	.map((l, layerindex) =>
		// 		l.generalDetails.sampleImage.filter(i => i.file).map(i => ({ ...i.file, layerindex }))
		// 	)
		// 	.flat();
		// const files = existingSampleImages.concat(newSampleImages);
		const files = probing.layerImages;
		console.log('files', files);

		// check if the probing is completed and ask for confirmation if necessary
		let totalLength = 0;
		for (const layer of state.layer) totalLength += Number(layer.generalDetails.layerLength || 0);
		if (totalLength >= Number(state.expecteddepth)) {
			if (!state.endstatus && userConfirmEndProbing === '') {
				setShowLock('confirm');
				return;
			}
			if (userConfirmEndProbing !== '') {
				probing.endstatus = userConfirmEndProbing === 'yes';
			}
		}

		//Delete Probing Layer Images
		if (probing.delImgsLayer.length) delProbingImages('Layer');

		//Delete Probing Images
		if (probing.delImgsProbing.length) delProbingImages('Probing');

		for (const lay of probing.layer) {
			for (const lit of lay.lithology) {
				if (lit.isDropDoun) {
					// if (lit.selectedlithologyfrom?.value) lit.from = lit.selectedlithologyfrom.value;
					// if (lit.selectedlithologyto?.value) lit.to = lit.selectedlithologyto.value;
					if (lit.selectedlithologyinclutions?.value)
						lit.inclutions = lit.selectedlithologyinclutions.value;
				}
			}
		}

		const requestParams = {
			probingid: probing.probingid,
			probingname: probing.probingname,
			probingcode: probing.probingcode,
			campaignid: probing.campaignid,
			projectid: probingOrg.projectid,
			companyid: authReducer.userData.company_id,
			latitude: formatLngLat(probing.latitude),
			longitude: formatLngLat(probing.longitude),
			apparentdepth: probing.apparentdepth || '',
			actualdepth: totalLength,
			onsiteExpertName: probing.onsiteExpertName || '',
			fieldEngineer: probing.fieldEngineer || '',
			probingDate: moment(probing.probingDate).valueOf() || '',
			firstname: probing.firstname || '',
			lastname: probing.lastname || '',
			number: probing.contactnumber || '',
			status: 'Open',
			layer: JSON.stringify(probing.layer),
			inputtext: probing.inputtext || '',
			altitude: probing.altitude || '',
			expecteddepth: probing.expecteddepth || '',
			remark: probing.remark || '',
			endstatus: probing.endstatus,
			files,
			probing_images,
			listCampaignid: probing.campaignid || '',
		};
		dispatch(actions.updateProbing(apolloClient.probing, requestParams));

		/** manage TODO history **/

		const todoHistory = [];
		const bulkMaterials = [];
		const masterList = new Set();
		for (let lay = 0; lay < probing.layer.length; lay++) {
			const layer = probing.layer[lay];
			const prevLayer = probingOrg.layer[lay];
			if (
				layer.category.catisactive &&
				(layer.category.catisactive !== prevLayer?.category?.catisactive ||
					layer.category.selectedCategory !== prevLayer?.category?.selectedCategory ||
					layer.category.manualCategory !== prevLayer?.category?.manualCategory ||
					layer.category.isDropDoun !== prevLayer?.category?.isDropDoun)
			)
				todoHistory.push({
					section: 'category',
					created_date: moment().valueOf(),
					isTodoCompleted: false,
					layer_index: lay,
					isactive: layer.category.catisactive,
					data: {
						value: layer.category.selectedCategory,
						selectedCategory: layer.category.selectedCategory,
						manualCategory: layer.category.manualCategory,
						isDropDoun: layer.category.isDropDoun,
					},
				});
			if (!layer.category.isDropDoun && layer.category.manualCategory) {
				// new category ?
				const cat = layer.category.manualCategory.toLowerCase();
				if (!lists.categoryList.find(c => c.label.toLowerCase() === cat)) {
					masterList.add('category');
					bulkMaterials.push({
						id: '',
						type: 'category',
						name: cat.trim(),
						value: '',
						key: [],
						tag: [],
						lang_data:
							languageCode !== 'en'
								? [{ lang_code: languageCode, lang_text: layer.category.manualCategory.trim() }]
								: [],
						lang_code: languageCode,
					});
				}
			}
			for (let l = 0; l < layer.lithology.length; l++) {
				const litho = layer.lithology[l];
				const prevLitho = prevLayer.lithology[l];
				if (
					litho.isactive &&
					(litho.isactive !== prevLitho?.isactive ||
						litho.isDropDoun !== prevLitho?.isDropDoun ||
						litho.from !== prevLitho?.from ||
						litho.to !== prevLitho?.to ||
						litho.selectedanomaly !== prevLitho?.selectedanomaly ||
						litho.soil_family !== prevLitho?.soil_family)
				)
					todoHistory.push({
						section: 'lithology',
						created_date: moment().valueOf(),
						isTodoCompleted: false,
						layer_index: lay,
						index: l,
						isactive: litho.isactive,
						isDropDoun: litho.isDropDoun,
						data: {
							from: litho.from,
							inclutions: litho.inclutions,
							to: litho.to,
							soil_family: litho.soil_family,
							selectedanomaly: litho.selectedanomaly,
						},
					});
				if (!litho.isDropDoun) {
					// new lithos ?
					const newLithos = new Map();
					['from', 'to'].forEach(key => {
						if (litho[key] && litho.soil_family) {
							if (
								!lists.lithologyList.find(c => c.label.toLowerCase() === litho[key].toLowerCase())
							) {
								masterList.add('lithology');
								newLithos.set(litho[key], {
									id: '',
									type: 'lithology',
									name: litho[key],
									value: key === 'from' ? litho.soil_family : litho.soil_family_to,
									key: [],
									tag: [],
									lang_data:
										languageCode !== 'en'
											? [{ lang_code: languageCode, lang_text: litho.soil_family.trim() }]
											: [],
									lang_code: languageCode,
								});
							}
						}
					});
					if (litho.manualAnomaly) {
						if (
							!lists.anomalyList.find(
								c => c.label.toLowerCase() === litho.manualAnomaly.toLowerCase()
							)
						) {
							masterList.add('anomaly');
							newLithos.set('ANOMALY', {
								id: '',
								type: 'anomaly',
								name: litho.manualAnomaly,
								value: '',
								key: [],
								tag: [],
								lang_data:
									languageCode !== 'en'
										? [{ lang_code: languageCode, lang_text: litho.manualAnomaly }]
										: [],
								lang_code: languageCode,
							});
						}
					}

					bulkMaterials.push(...Array.from(newLithos.values()));
				}
			}
			for (let l = 0; l < layer.color.length; l++) {
				const color = layer.color[l];
				const prevColor = prevLayer.color[l];
				if (
					color.isactive &&
					(color.isactive !== prevColor?.isactive ||
						color.isDropDoun !== prevColor?.isDropDoun ||
						color.from !== prevColor?.from ||
						color.to !== prevColor?.to ||
						color.inclutions !== prevColor?.inclutions ||
						color.fromname !== prevColor?.fromname ||
						color.toname !== prevColor?.toname ||
						color.inclutionsname !== prevColor?.inclutionsname)
				)
					todoHistory.push({
						section: 'color',
						created_date: moment().valueOf(),
						isTodoCompleted: false,
						layer_index: lay,
						index: l,
						isactive: color.isactive,
						isDropDoun: color.isDropDoun,
						data: {
							from: color.from,
							inclutions: color.inclutions,
							to: color.to,
							fromname: color.fromname,
							toname: color.toname,
							inclutionsname: color.inclutionsname,
						},
					});
				if (!color.isDropDoun) {
					// new colors ?
					const newColors = new Map();
					['from', 'to', 'inclutions'].forEach(key => {
						const keyname = `${key}name`;
						if (color[key] && color[keyname]) {
							if (!lists.colorList.find(c => c.color === color[key].toLowerCase())) {
								masterList.add('color');
								newColors.set(color[keyname], {
									id: '',
									type: 'color',
									name: color[keyname].trim(),
									value: color[key].trim(),
									key: [],
									tag: [],
									lang_data:
										languageCode !== 'en'
											? [{ lang_code: languageCode, lang_text: color[keyname].trim() }]
											: [],
									lang_code: languageCode,
								});
							}
						}
					});
					bulkMaterials.push(...Array.from(newColors.values()));
				}
			}
			if (
				layer.extraInformation.isactive &&
				(layer.extraInformation.isactive !== prevLayer?.extraInformation?.isactive ||
					layer.extraInformation.moisture !== prevLayer?.extraInformation?.moisture ||
					layer.extraInformation.value !== prevLayer?.extraInformation?.value)
			)
				todoHistory.push({
					section: 'extraInformation',
					created_date: moment().valueOf(),
					isTodoCompleted: false,
					layer_index: lay,
					isactive: layer.extraInformation.isactive,
					data: {
						value: layer.extraInformation.value,
						moisture: layer.extraInformation.moisture,
					},
				});
			if (
				layer.observation.isactive &&
				(layer.observation.isactive !== prevLayer?.observation?.isactive ||
					layer.observation.selectedobservation !== prevLayer?.observation?.selectedobservation ||
					layer.observation.value !== prevLayer?.observation?.value)
			)
				todoHistory.push({
					section: 'observation',
					created_date: moment().valueOf(),
					isTodoCompleted: false,
					layer_index: lay,
					isactive: layer.observation.isactive,
					isDropDoun: layer.observation.isDropDoun,
					data: {
						value: layer.observation.value,
						selectedobservation: layer.observation.selectedobservation,
					},
				});
			for (let l = 0; l < layer.moisture.length; l++) {
				if (!layer.moisture[l].isDropDoun && Array.isArray(layer.moisture[l].manualMoisture)) {
					// new moisture ?
					const newMoisture = new Map();
					layer.moisture[l].manualMoisture.forEach(moisture => {
						if (moisture) {
							if (!lists.moistureList.find(c => c.label.toLowerCase() === moisture.toLowerCase())) {
								masterList.add('moisture');
								newMoisture.set(moisture, {
									id: '',
									type: 'moisture',
									name: moisture.trim(),
									value: '',
									key: [],
									tag: [],
									lang_data:
										languageCode !== 'en'
											? [{ lang_code: languageCode, lang_text: moisture.trim() }]
											: [],
									lang_code: languageCode,
								});
							}
						}
					});
					bulkMaterials.push(...Array.from(newMoisture.values()));
				}
			}

			for (let l = 0; l < layer.sample.length; l++) {
				const sample = layer.sample[l];
				const prevSample = prevLayer.sample[l];
				if (
					sample.labactive &&
					(sample.labactive !== prevSample?.labactive ||
						sample.laboratory !== prevSample?.laboratory)
				)
					todoHistory.push({
						section: 'sample',
						created_date: moment().valueOf(),
						isTodoCompleted: false,
						layer_index: lay,
						index: l,
						isactive: sample.labactive,
						data: { laboratory: sample.laboratory },
					});
			}
		}

		if (todoHistory.length) {
			const historyReq = {
				probingid: probing.probingid,
				todo_log_array: JSON.stringify(
					todoHistory.map(th => ({
						campaignid: probing.campaignid,
						probingid: probing.probingid,
						projectid: probingOrg.projectid,
						todo_details: th,
					}))
				),
			};

			dispatch(actions.createMultipleProbingTodoLog(apolloClient.probing, historyReq));
		}
		if (bulkMaterials.length) {
			const requestMaterial = {
				master_data: JSON.stringify(bulkMaterials),
				type_list: JSON.stringify([...masterList]),
				lang_code: languageCode,
			};
			// add extra master in super admin
			dispatch(actions.createBulkMaterial(apolloClient.masters, requestMaterial));
		}
		setUpdating(true);
		setUserConfirmEndProbing('');
	}

	if (loading || loadingGraph)
		return (
			<div className="div-spinner">
				<Spinner size="500" className="success" />
			</div>
		);

	return (
		<>
			{showTodo && (
				<ModalTodo
					isOpen={showTodo}
					onClose={() => setShowTodo(false)}
					campaignId={state.campaignid}
					probingId={state.probingid}
				/>
			)}
			{showHistory && (
				<ModalHistory
					isOpen={showHistory}
					onClose={() => setShowHistory(false)}
					campaignId={state.campaignid}
					probingId={state.probingid}
				/>
			)}
			{showMap && (
				<ModalMapProbing
					isOpen={showMap}
					onClose={() => setShowMap(false)}
					selectedProbing={{
						id: state.probingid,
						lng: state.newLongitude || state.longitude,
						lat: state.newLatitude || state.latitude,
					}}
					onChangeLngLat={(id, lng, lat) => {
						if (id === state.probingid) {
							validate('newLongitude', formatLngLat(lng));
							validate('newLatitude', formatLngLat(lat));
							validate('longitude', formatLngLat(lng));
							validate('latitude', formatLngLat(lat));
						}
					}}
				/>
			)}
			{showLock !== '' && (
				<ModalDeleteConfirmation
					isOpen={showLock !== ''}
					onClose={() => {
						if (showLock === 'confirm') setUserConfirmEndProbing('no');
						setShowLock('');
					}}
					deleteData={{
						title: client_Close_Probing,
						deleteMessage:
							showLock === 'confirm'
								? same_expected_depth_and_layer_length
								: client_close_probing_message,
						actionName: '',
						objectName: state.probingcode,
						buttonCancelText: showLock === 'confirm' ? common_btn_no : '',
					}}
					onConfirm={() => handleCloseProbing()}
				/>
			)}
			<Modal
				size="lg"
				isOpen={isOpen}
				toggle={onClose}
				className={`modal-dialog--success modal-dialog--header`}
				style={{ maxWidth: '80%' }}
			>
				<div className="modal__header">
					<button className="lnr lnr-cross modal__close-btn" type="button" onClick={onClose} />
					<h4 className="bold-text  modal__title">{client_edit_probing}</h4>
				</div>
				<div style={{ maxHeight: 'calc(90vh - 54px - 31px)', overflowY: 'auto' }}>
					<Card>
						<CardBody>
							<NavigationRow>
								{probingIds.length > 1 && (
									<Button
										className={'system-btn clear-border'}
										outline={true}
										color={'success'}
										onClick={handleClickPrevious}
										disabled={probingIdx <= 0}
									>
										<Icon>skip_previous</Icon>
									</Button>
								)}
								<div className="code">{state.probingname || state.probingcode}</div>
								{probingIds.length > 1 && (
									<Button
										className={'system-btn clear-border'}
										outline={true}
										color={'success'}
										onClick={handleClickNext}
										disabled={probingIdx >= probingIds.length - 1}
									>
										<Icon>skip_next</Icon>
									</Button>
								)}
							</NavigationRow>
							<Row style={{ gap: '10px', paddingBlock: '1rem' }}>
								<Col xs={12}>
									<Tooltip title={client_Close_Probing}>
										<Button
											color="success"
											onClick={() => setShowLock('lock')}
											className={'add-new-btn btn btn-outline-success'}
											outline={true}
											disabled={!canEdit}
										>
											<Icon>{state.endstatus ? 'lock' : 'lock_open'}</Icon>
										</Button>
									</Tooltip>
									<Tooltip title="map">
										<Button
											color="success"
											onClick={() => setShowMap(true)}
											className={'add-new-btn btn btn-outline-success'}
											outline={true}
										>
											<Icon>room</Icon>
										</Button>
									</Tooltip>
									<Button
										className={'add-new-btn btn btn-outline-success'}
										color={'success'}
										outline={true}
										onClick={() => setShowTodo(true)}
										disabled={!canEdit}
									>
										{client_todo_list_btn}
									</Button>
									<Button
										className={'add-new-btn btn btn-outline-success'}
										color={'success'}
										outline={true}
										onClick={() => setShowHistory(true)}
									>
										{todo_history}
									</Button>
									{/* <TwoLabelsSwitch
										value={display}
										onChange={value => setDisplay(value)}
										options={[
											{ label: 'Simplified', value: 's' },
											{ label: 'Complete', value: 'c' },
										]}
										fullWidth={false}
										style={{ display: 'inline-flex' }}
									/> */}
								</Col>
							</Row>
							<Row>
								<Col xs={9}>
									<Form>
										<FormGroupRequired>
											<Label>{label_probing_name}</Label>
											<div
												style={{
													display: 'flex',
													gap: '1em',
													width: '16rem',
												}}
											>
												<Input
													type="text"
													value={state.probingname}
													onChange={e => validate('probingname', e.target.value)}
													disabled={!canEdit || !state.probingnameIsManual}
												/>
												<Tooltip title={state.probingnameIsManual ? common_btn_okay : tooltip_edit}>
													<Button
														className={'system-btn clear-border'}
														outline={true}
														color={'success'}
														onClick={() =>
															localDispatch({
																probingnameIsManual: !state.probingnameIsManual,
															})
														}
													>
														<Icon>{state.probingnameIsManual ? 'check' : 'edit'}</Icon>
													</Button>
												</Tooltip>
											</div>
											{errors.probingname && (
												<span className="error-message">{client_probing_name_validate}</span>
											)}
										</FormGroupRequired>
										<FlexRow>
											<div className="col-input required">
												<div className="label">{client_project_longtitude}</div>
												<Input
													type="number"
													value={formatLngLat(state.longitude)}
													onChange={e => validate('longitude', e.target.value)}
													disabled={!canEdit}
												/>
												{errors.longitude && (
													<span className="error-message">{enter_longitude}</span>
												)}
											</div>
											<div className="col-input required">
												<div className="label">{client_project_latitude}</div>
												<Input
													type="number"
													value={formatLngLat(state.latitude)}
													onChange={e => validate('latitude', e.target.value)}
													disabled={!canEdit}
												/>
												{errors.latitude && <span className="error-message">{enter_latitude}</span>}
											</div>
											<div className="col-input">
												<div className="label">{client_probing_inputtext}</div>
												<Input
													type="text"
													value={state.inputtext}
													onChange={e => validate('inputtext', e.target.value)}
													disabled={!canEdit}
												/>
											</div>
											<div className="col-input required">
												<div className="label">
													{client_project_altitude} ({unit})
												</div>
												<Input
													type="number"
													value={formatDepthNumber(state.altitude, unit)}
													onChange={e => validate('altitude', e.target.value)}
													disabled={!canEdit}
												/>
												{errors.altitude && (
													<span className="error-message">
														{client_probing_please_add_altitude}
													</span>
												)}
											</div>
											<div className="col-input">
												<div className="label">
													{client_probing_expecteddepth} ({unit})
												</div>
												<Input
													type="number"
													value={formatDepthNumber(state.expecteddepth, unit)}
													onChange={e => validate('expecteddepth', e.target.value)}
													disabled={!canEdit}
												/>
											</div>
											<div className="col-input">
												<div className="label">
													{client_probing_actual_depth} ({unit})
												</div>
												<Input
													type="number"
													value={formatDepthNumber(state.actualdepth, unit)}
													onChange={e => validate('actualdepth', e.target.value)}
													disabled
												/>
											</div>
											<div className="col-input">
												<div className="label">{client_probing_field_engineer}</div>
												<Input
													type="text"
													value={state.fieldEngineer}
													onChange={e => validate('fieldEngineer', e.target.value)}
													disabled={!canEdit}
												/>
											</div>
											<div className="col-input required">
												<div className="label">{client_probing_date}</div>
												<DatePicker
													startDate={state.probingDate}
													onChange={date => validate('probingDate', date)}
													disabled={!canEdit}
												/>
												{errors.probingDate && (
													<span className="error-message">
														{client_probing_please_add_probing_date}
													</span>
												)}
											</div>
											<div className="col-input">
												<div className="label">{client_probing_remarks}</div>
												<Input
													type="text"
													value={state.remark}
													onChange={e => validate('remark', e.target.value)}
													disabled={!canEdit}
												/>
											</div>
										</FlexRow>

										<Row>
											<Col xs={12}>
												{Array.isArray(state.audios) && state.audios.length ? (
													<>
														<h4 style={{ color: 'var(--clr-aexdo)', minHeight: 35 }}>
															{client_probing_audio_files}
														</h4>
														<div className="data-container">
															{Array.isArray(state.audios) &&
																state.audios.map((audio, idx) => (
																	<audio key={`AUDIO-${idx}`} controls controlsList="nodownload">
																		<source src={audio} />
																	</audio>
																))}
														</div>
														<br />
													</>
												) : null}
											</Col>
										</Row>
										<h4 style={{ color: 'var(--clr-aexdo)' }}>{mapbox_images}</h4>
										<Row>
											<Col
												xs={3}
												style={{
													minHeight: 70,
													display: 'flex',
													height: '100%',
													alignItems: 'center',
												}}
											>
												<Dropzone
													accept="image/jpeg, image/png"
													multiple={false}
													onDrop={fileToUpload =>
														localDispatch({ type: 'addImage', image: fileToUpload })
													}
													disabled={!canEdit}
												>
													{({ getRootProps, getInputProps }) => (
														<div
															{...getRootProps()}
															className="dropzone__input"
															style={STYLE_IMAGE_DROPBOX}
														>
															<div
																className="dropzone__drop-here"
																style={{
																	textAlign: 'center',
																}}
															>
																<span className="lnr lnr-upload" /> {upload_probing_image}
															</div>
															<input {...getInputProps()} />
														</div>
													)}
												</Dropzone>
											</Col>
											<Col xs={9} className="d-flex flex-wrap">
												{Array.isArray(state.images) &&
													state.images.map((img, idx) => (
														<ImageContainer key={`IMAGE${idx}`}>
															<button onClick={() => setLightBoxUrl(img.url)}>
																<img src={img.url} alt="" />
															</button>
															<Lightbox
																images={[{ src: lightBoxUrl }]}
																isOpen={lightBoxUrl !== ''}
																showImageCount={false}
																backdropClosesModal={true}
																onClose={() => setLightBoxUrl('')}
																width={1100}
															/>
															<div className="button">
																<Tooltip title={tooltip_delete}>
																	<Button
																		className={'system-btn clear-border'}
																		outline={true}
																		color={'success'}
																		onClick={() => localDispatch({ type: 'deleteImage', idx })}
																		disabled={!canEdit}
																	>
																		<Icon>delete</Icon>
																	</Button>
																</Tooltip>
															</div>
														</ImageContainer>
													))}
											</Col>
										</Row>
										<h4 style={{ color: 'var(--clr-aexdo)' }}>{client_probing_operator}</h4>
										<FlexRow>
											<div className="col-input">
												<div className="label">{client_probing_firstname}</div>
												<Input
													type="text"
													value={state.firstname}
													onChange={e => validate('firstname', e.target.value)}
													disabled={!canEdit}
												/>
											</div>
											<div className="col-input">
												<div className="label">{client_probing_lastname}</div>
												<Input
													type="text"
													value={state.lastname}
													onChange={e => validate('lastname', e.target.value)}
													disabled={!canEdit}
												/>
											</div>
											<div className="col-input">
												<div className="label">{client_probing_Contact_Number}</div>
												<Input
													type="text"
													value={state.contactnumber}
													onChange={e => validate('contactnumber', e.target.value)}
													disabled={!canEdit}
												/>
											</div>
										</FlexRow>
										<Row>
											<Col xs={12} className="margin__top" style={{ textAlign: 'end' }}>
												<Button
													title={client_add_layer}
													color="success"
													onClick={() => localDispatch({ type: 'addLayer' })}
													className={'add-new-btn btn btn-outline-success btn btn-outline-success'}
													outline={true}
													disabled={!canEdit}
												>
													{client_add_layer}
												</Button>
												{state.layer.length > 1 && activeTab > 0 && (
													<Button
														title={client_delete_layer}
														color="success"
														onClick={() => {
															localDispatch({ type: 'deleteLayer', idx: activeTab });
															setActiveTab(0);
														}}
														className={
															'add-new-btn btn btn-outline-success btn btn-outline-success'
														}
														outline={true}
														disabled={!canEdit}
													>
														{client_delete_layer}
													</Button>
												)}
											</Col>
										</Row>
										<Row>
											<Col xs={12}>
												<div className="tabs tabs--bordered-top">
													<div className="tabs__wrap">
														<Nav tabs>
															{Array.isArray(state.layer) &&
																state.layer.map((layer, layerIdx) => (
																	<NavItem key={`TAB-${layerIdx}`}>
																		<NavLink
																			className={activeTab === layerIdx ? 'active' : ''}
																			onClick={() => setActiveTab(layerIdx)}
																		>
																			{`${client_probing_layer} - ${layerIdx + 1}`}
																		</NavLink>
																	</NavItem>
																))}
														</Nav>
														<TabContent activeTab={activeTab}>
															{Array.isArray(state.layer) &&
																state.layer.map((layer, layerIdx) => (
																	<TabPane
																		tabId={layerIdx}
																		key={`PANE-${layerIdx}`}
																		disabled={!canEdit}
																	>
																		<Row>
																			<Col xs={12}>
																				<div className="border_box">
																					<BoxTitle>
																						<h4>{client_viewprobing_General_Details}</h4>
																						<Button
																							title={
																								layer.category?.isDropDoun
																									? client_manual
																									: client_default
																							}
																							color="success"
																							onClick={() =>
																								localDispatch({
																									type: 'updateLayer',
																									idx: layerIdx,
																									field: 'category.isDropDoun',
																									value: !layer.category?.isDropDoun,
																								})
																							}
																							className={
																								'add-new-btn btn btn-outline-success btn btn-outline-success'
																							}
																							outline={true}
																						>
																							{layer.category?.isDropDoun
																								? client_manual
																								: client_default}
																						</Button>
																					</BoxTitle>
																					{layer?.category && (
																						<FlexRow>
																							<div className="col-input">
																								<div className="label">
																									{client_probing_category}
																								</div>
																								<div className="input">
																									{layer.category?.isDropDoun ? (
																										<Select
																											value={lists.categoryList.find(
																												c =>
																													c.value ===
																													layer?.category?.selectedCategory
																											)}
																											onChange={value =>
																												localDispatch({
																													type: 'updateLayer',
																													idx: layerIdx,
																													field: 'category.selectedCategory',
																													value: value.value,
																												})
																											}
																											options={lists.categoryList}
																											isClearable={true}
																											styles={{
																												container: provided => ({
																													...provided,
																													flex: '1 0 250px;',
																													zIndex: 101,
																												}),
																											}}
																										/>
																									) : (
																										<Input
																											type="text"
																											value={layer.category.manualCategory}
																											onChange={e =>
																												localDispatch({
																													type: 'updateLayer',
																													idx: layerIdx,
																													field: 'category.manualCategory',
																													value: e.target.value,
																												})
																											}
																										/>
																									)}
																								</div>
																							</div>
																							<TodoCheck
																								initialValue={layer.category.catisactive}
																								onChange={checked =>
																									localDispatch({
																										type: 'updateLayer',
																										idx: layerIdx,
																										field: 'category.catisactive',
																										value: checked,
																									})
																								}
																							/>
																							{layer?.generalDetails && (
																								<div className="col-input">
																									<div className="label">{client_probing_pid}</div>
																									<div className="input">
																										<Input
																											type="number"
																											value={layer.generalDetails.pid ?? ''}
																											onChange={e =>
																												localDispatch({
																													type: 'updateLayer',
																													idx: layerIdx,
																													field: 'generalDetails.pid',
																													value: e.target.value,
																												})
																											}
																										/>
																									</div>
																								</div>
																							)}
																						</FlexRow>
																					)}
																					{layer?.generalDetails && (
																						<FlexRow>
																							<div className="col-input">
																								<div className="label">{`${client_probing_Layer_Length} (${unit})`}</div>
																								<div className="input">
																									<Input
																										type="number"
																										value={formatDepthNumber(
																											layer.generalDetails.layerLength,
																											unit
																										)}
																										onChange={e => {
																											localDispatch({
																												type: 'updateLayer',
																												idx: layerIdx,
																												field: 'generalDetails.layerLength',
																												value:
																													Number(e.target.value) *
																													(unit === 'M' ? 100 : 1),
																											});
																											calculateActualDepth();
																											errors['layerLength_'+layerIdx] && setErrors(e => ({ ...e, ['layerLength_'+layerIdx]: false }));
																										}}
																									/>
																								</div>
																								{errors['layerLength_' + layerIdx] && (
																									<span className="error-message">
																										{client_probing_please_add_layer_length}
																									</span>
																								)}
																							</div>
																							{display === 's' && Array.isArray(layer.moisture) && (
																								<div className="col-input">
																									<div className="label">{client_Moisture}</div>
																									<div className="input">
																										<Input
																											type="text"
																											value={layer.moisture[0]?.manualMoisture?.[0]}
																											onChange={e =>
																												localDispatch({
																													type: 'updateLayer',
																													idx: layerIdx,
																													field: `moisture[0].manualMoisture`,
																													value: [e.target.value],
																												})
																											}
																										/>
																									</div>
																								</div>
																							)}
																						</FlexRow>
																					)}
																					<h4>{client_probing_Layer_Image}</h4>
																					<Row>
																						<Col
																							xs={3}
																							style={{
																								minHeight: 70,
																								display: 'flex',
																								height: '100%',
																								alignItems: 'center',
																							}}
																						>
																							<Dropzone
																								accept="image/jpeg, image/png"
																								multiple={false}
																								onDrop={fileToUpload =>
																									localDispatch({
																										type: 'addImageLayer',
																										layer: layerIdx,
																										image: fileToUpload,
																									})
																								}
																							>
																								{({ getRootProps, getInputProps }) => (
																									<div
																										{...getRootProps()}
																										className="dropzone__input"
																										style={STYLE_IMAGE_DROPBOX}
																									>
																										<div
																											className="dropzone__drop-here"
																											style={{
																												textAlign: 'center',
																											}}
																										>
																											<span className="lnr lnr-upload" />
																											{probing_upload_layer_img}
																										</div>
																										<input {...getInputProps()} />
																									</div>
																								)}
																							</Dropzone>
																						</Col>
																						<Col xs={9} className="d-flex flex-wrap">
																							{Array.isArray(layer.generalDetails?.sampleImage) &&
																								layer.generalDetails.sampleImage.map((img, idx) => (
																									<ImageContainer key={`IMAGE${idx}`}>
																										<button onClick={() => setLightBoxUrl(img.url)}>
																											<img src={img.url} alt="" />
																										</button>
																										<Lightbox
																											images={[{ src: lightBoxUrl }]}
																											isOpen={lightBoxUrl !== ''}
																											showImageCount={false}
																											backdropClosesModal={true}
																											onClose={() => setLightBoxUrl('')}
																											width={1100}
																										/>
																										<div className="button">
																											<Tooltip title={tooltip_delete}>
																												<Button
																													className={'system-btn clear-border'}
																													outline={true}
																													color={'success'}
																													onClick={() =>
																														localDispatch({
																															type: 'deleteImageLayer',
																															layer: layerIdx,
																															idx,
																														})
																													}
																												>
																													<Icon>delete</Icon>
																												</Button>
																											</Tooltip>
																										</div>
																									</ImageContainer>
																								))}
																						</Col>
																					</Row>
																				</div>
																				{Array.isArray(layer.lithology) && (
																					<div className="border_box">
																						<BoxTitle>
																							<h4 className="title">{client_probing_lithology}</h4>
																							<TodoCheck
																								initialValue={layer.lithology[0]?.isactive}
																								onChange={checked =>
																									localDispatch({
																										type: 'updateLayer',
																										idx: layerIdx,
																										field: `lithology[0].isactive`,
																										value: checked,
																									})
																								}
																							/>
																						</BoxTitle>
																						{layer.lithology.map((lithology, idx) => (
																							<Fragment key={`LITHO-${idx}`}>
																								<FlexRow>
																									{display === 'c' ? (
																										<>
																											<div className="soilFamSec col-input">
																												<div className="label">
																													{material_masters_soil_family}
																												</div>
																												<div className="input">
																													<Select
																														value={lists.geologyList.find(
																															c => c.value === lithology.soil_family
																														)}
																														onChange={value =>
																															localDispatch({
																																type: 'updateLayer',
																																idx: layerIdx,
																																field: `lithology[${idx}].soil_family`,
																																value: value?.value,
																															})
																														}
																														options={lists.geologyList}
																														isClearable={true}
																													/>
																												</div>
																											</div>
																											<div className="soilFamSec col-input">
																												<div className="label">
																													{client_lithology_from}
																												</div>
																												<div className="input">
																													{lithology.isDropDoun ? (
																														<Select
																															value={lists.lithologyList.find(
																																c =>
																																	c.value ===
																																	lithology?.selectedlithologyfrom
																																		?.value &&
																																	(lithology.soil_family === '' ||
																																		c.geologyId ===
																																		lithology.soil_family)
																															)}
																															onChange={value => {
																																localDispatch({
																																	type: 'updateLayer',
																																	idx: layerIdx,
																																	field: `lithology[${idx}].selectedlithologyfrom`,
																																	value,
																																});
																															}}
																															options={lists.lithologyList.filter(
																																l =>
																																	!lithology.soil_family ||
																																	l.geologyId ===
																																	lithology.soil_family
																															)}
																															isClearable={true}
																														/>
																													) : (
																														<Input
																															type="text"
																															value={lithology.from}
																															onChange={e =>
																																localDispatch({
																																	type: 'updateLayer',
																																	idx: layerIdx,
																																	field: `lithology[${idx}].from`,
																																	value: e.target.value,
																																})
																															}
																														/>
																													)}
																												</div>
																											</div>
																											<div className="soilFamSec col-input">
																												<div className="label">
																													{material_masters_soil_family}
																												</div>
																												<div className="input">
																													<Select
																														value={lists.geologyList.find(
																															c => c.value === lithology.soil_family_to
																														)}
																														onChange={value =>
																															localDispatch({
																																type: 'updateLayer',
																																idx: layerIdx,
																																field: `lithology[${idx}].soil_family_to`,
																																value: value?.value,
																															})
																														}
																														options={lists.geologyList}
																														isClearable={true}
																													/>
																												</div>
																											</div>
																											<div className="soilFamSec col-input">
																												<div className="label">
																													{client_lithology_to}
																												</div>
																												<div className="input">
																													{lithology.isDropDoun ? (
																														<Select
																															value={lists.lithologyList.find(
																																c =>
																																	c.value ===
																																	lithology?.selectedlithologyto
																																		?.value &&
																																	(lithology.soil_family_to === '' ||
																																		c.geologyId ===
																																		lithology.soil_family_to)
																															)}
																															onChange={value => {
																																localDispatch({
																																	type: 'updateLayer',
																																	idx: layerIdx,
																																	field: `lithology[${idx}].selectedlithologyto`,
																																	value,
																																});
																															}}
																															options={lists.lithologyList.filter(
																																l =>
																																	!lithology.soil_family_to ||
																																	l.geologyId ===
																																	lithology.soil_family_to
																															)}
																															isClearable={true}
																														/>
																													) : (
																														<Input
																															type="text"
																															value={lithology.to}
																															onChange={e =>
																																localDispatch({
																																	type: 'updateLayer',
																																	idx: layerIdx,
																																	field: `lithology[${idx}].to`,
																																	value: e.target.value,
																																})
																															}
																														/>
																													)}
																												</div>
																											</div>
																											{/*!lithology.isDropDoun && (
																												<div className="col-input">
																													<div className="label">
																														{material_masters_soil_family}
																													</div>
																													<div className="input">
																														<Select
																															value={lists.geologyList.find(
																																c =>
																																	c.value === lithology.soil_family
																															)}
																															onChange={value =>
																																localDispatch({
																																	type: 'updateLayer',
																																	idx: layerIdx,
																																	field: `lithology[${idx}].soil_family`,
																																	value: value?.value,
																																})
																															}
																															options={lists.geologyList}
																															isClearable={true}
																														/>
																													</div>
																												</div>
																														)*/}
																											<div className="col-input">
																												<div className="label">
																													{client_Anomaly}
																												</div>
																												<div className="input">
																													{lithology.isDropDoun ? (
																														<Select
																															value={lithology.selectedanomaly}
																															onChange={v =>
																																localDispatch({
																																	type: 'updateLayer',
																																	idx: layerIdx,
																																	field: `lithology[${idx}].selectedanomaly`,
																																	value: v,
																																})
																															}
																															options={lists.anomalyList}
																															className="basic-multi-select"
																															isSearchable={true}
																															isMulti={true}
																															closeMenuOnSelect={false}
																														/>
																													) : (
																														<Input
																															type="text"
																															value={lithology?.manualAnomaly || ''}
																															onChange={e =>
																																localDispatch({
																																	type: 'updateLayer',
																																	idx: layerIdx,
																																	field: `lithology[${idx}].manualAnomaly`,
																																	value: e.target.value,
																																})
																															}
																														/>
																													)}
																												</div>
																											</div>
																										</>
																									) : (
																										<>
																											<div className="col-input">
																												<div className="label">
																													{material_masters_soil_family}
																												</div>
																												<div className="input">
																													<Select
																														value={lists.geologyList.find(
																															c => c.value === lithology.soil_family
																														)}
																														onChange={value =>
																															localDispatch({
																																type: 'updateLayer',
																																idx: layerIdx,
																																field: `lithology[${idx}].soil_family`,
																																value: value?.value,
																															})
																														}
																														options={lists.geologyList}
																														isClearable={true}
																													/>
																												</div>
																											</div>
																											<div className="col-input">
																												<div className="label">
																													{client_probing_lithology}
																												</div>
																												<div className="input">
																													<Select
																														value={lists.lithologyList.find(
																															c =>
																																c.value ===
																																lithology?.selectedlithologyfrom
																																	?.value &&
																																(lithology.soil_family === '' ||
																																	c.geologyId ===
																																	lithology.soil_family)
																														)}
																														onChange={value => {
																															localDispatch({
																																type: 'updateLayer',
																																idx: layerIdx,
																																field: `lithology[${idx}].selectedlithologyfrom`,
																																value,
																															});
																														}}
																														options={lists.lithologyList.filter(
																															l =>
																																!lithology.soil_family ||
																																l.geologyId ===
																																lithology.soil_family
																														)}
																														isClearable={true}
																													/>
																												</div>
																											</div>
																										</>
																									)}
																									<div>
																										{idx === 0 ? (
																											<Tooltip title={tooltip_add}>
																												<Button
																													className={'system-btn clear-border'}
																													outline={true}
																													color={'success'}
																													onClick={() =>
																														localDispatch({
																															type: 'addLithology',
																															layerIdx,
																														})
																													}
																												>
																													<Icon>add</Icon>
																												</Button>
																											</Tooltip>
																										) : (
																											<Tooltip title={tooltip_delete}>
																												<Button
																													className={'system-btn clear-border'}
																													outline={true}
																													color={'success'}
																													onClick={() =>
																														localDispatch({
																															type: 'removeLithology',
																															layerIdx,
																															idx,
																														})
																													}
																												>
																													<Icon>delete</Icon>
																												</Button>
																											</Tooltip>
																										)}
																										{display === 'c' ? (
																											<Button
																												title={
																													lithology.isDropDoun
																														? client_manual
																														: client_default
																												}
																												color="success"
																												onClick={() =>
																													localDispatch({
																														type: 'updateLayer',
																														idx: layerIdx,
																														field: `lithology[${idx}].isDropDoun`,
																														value: !lithology.isDropDoun,
																													})
																												}
																												className={
																													'add-new-btn btn btn-outline-success btn btn-outline-success'
																												}
																												outline={true}
																											>
																												{lithology.isDropDoun
																													? client_manual
																													: client_default}
																											</Button>
																										) : null}
																									</div>
																								</FlexRow>
																							</Fragment>
																						))}
																					</div>
																				)}
																				{Array.isArray(layer.color) && (
																					<div className="border_box">
																						<BoxTitle>
																							<h4 className="title">{client_campaign_color}</h4>
																							<TodoCheck
																								initialValue={layer.color[0]?.isactive}
																								onChange={checked =>
																									localDispatch({
																										type: 'updateLayer',
																										idx: layerIdx,
																										field: `color[0].isactive`,
																										value: checked,
																									})
																								}
																							/>
																						</BoxTitle>

																						{layer.color.map((color, idx) => (
																							<FlexRow key={`COLOR-${idx}`}>
																								{color.isDropDoun ? (
																									<>
																										<div className="col-input">
																											<div className="label">
																												{client_lithology_from}
																											</div>
																											<Select
																												value={lists.colorList.find(
																													c =>
																														c.value ===
																														color?.selectedcolorfrom?.value
																												)}
																												onChange={value => {
																													localDispatch({
																														type: 'updateLayer',
																														idx: layerIdx,
																														field: `color[${idx}].selectedcolorfrom`,
																														value,
																													});
																												}}
																												options={lists.colorList}
																												isClearable={true}
																											/>
																										</div>
																										{display === 'c' ? (
																											<>
																												<div className="col-input">
																													<div className="label">
																														{client_lithology_to}
																													</div>
																													<Select
																														value={lists.colorList.find(
																															c =>
																																c.value ===
																																color?.selectedcolorto?.value
																														)}
																														onChange={value => {
																															localDispatch({
																																type: 'updateLayer',
																																idx: layerIdx,
																																field: `color[${idx}].selectedcolorto`,
																																value,
																															});
																														}}
																														options={lists.colorList}
																														isClearable={true}
																													/>
																												</div>
																												<div className="col-input">
																													<div className="label">
																														{client_Inclutions}
																													</div>
																													<Select
																														value={lists.colorList.find(
																															c =>
																																c.value ===
																																color?.selectedcolorinclutions
																																	?.value
																														)}
																														onChange={value => {
																															localDispatch({
																																type: 'updateLayer',
																																idx: layerIdx,
																																field: `color[${idx}].selectedcolorinclutions`,
																																value,
																															});
																														}}
																														options={lists.colorList}
																														isClearable={true}
																													/>
																												</div>
																											</>
																										) : null}
																									</>
																								) : (
																									<>
																										<div className="col-input">
																											<ColorPicker
																												label={client_lithology_from}
																												value={color.from}
																												onChange={color =>
																													localDispatch({
																														type: 'updateLayer',
																														idx: layerIdx,
																														field: `color[${idx}].from`,
																														value: color,
																													})
																												}
																											/>
																											<Input
																												type="text"
																												value={color.fromname}
																												onChange={e =>
																													localDispatch({
																														type: 'updateLayer',
																														idx: layerIdx,
																														field: `color[${idx}].fromname`,
																														value: e.target.value,
																													})
																												}
																											/>
																										</div>
																										{display === 'c' ? (
																											<>
																												<div className="col-input">
																													<ColorPicker
																														label={client_lithology_to}
																														value={color.to}
																														onChange={color =>
																															localDispatch({
																																type: 'updateLayer',
																																idx: layerIdx,
																																field: `color[${idx}].to`,
																																value: color,
																															})
																														}
																													/>
																													<Input
																														type="text"
																														value={color.toname}
																														onChange={e =>
																															localDispatch({
																																type: 'updateLayer',
																																idx: layerIdx,
																																field: `color[${idx}].toname`,
																																value: e.target.value,
																															})
																														}
																													/>
																												</div>
																												<div className="col-input">
																													<ColorPicker
																														label={client_Inclutions}
																														value={color.inclutions}
																														onChange={color =>
																															localDispatch({
																																type: 'updateLayer',
																																idx: layerIdx,
																																field: `color[${idx}].inclutions`,
																																value: color,
																															})
																														}
																													/>
																													<Input
																														type="text"
																														value={color.inclutionsname}
																														onChange={e =>
																															localDispatch({
																																type: 'updateLayer',
																																idx: layerIdx,
																																field: `color[${idx}].inclutionsname`,
																																value: e.target.value,
																															})
																														}
																													/>
																												</div>
																											</>
																										) : null}
																									</>
																								)}

																								{idx === 0 ? (
																									<Tooltip title={tooltip_add}>
																										<Button
																											className={'system-btn clear-border'}
																											outline={true}
																											color={'success'}
																											onClick={() =>
																												localDispatch({
																													type: 'addColor',
																													layerIdx,
																												})
																											}
																										>
																											<Icon>add</Icon>
																										</Button>
																									</Tooltip>
																								) : (
																									<Tooltip title={tooltip_delete}>
																										<Button
																											className={'system-btn clear-border'}
																											outline={true}
																											color={'success'}
																											onClick={() =>
																												localDispatch({
																													type: 'removeColor',
																													layerIdx,
																													idx,
																												})
																											}
																										>
																											<Icon>delete</Icon>
																										</Button>
																									</Tooltip>
																								)}
																								<Button
																									title={
																										color.isDropDoun
																											? client_manual
																											: client_default
																									}
																									color="success"
																									onClick={() =>
																										localDispatch({
																											type: 'updateLayer',
																											idx: layerIdx,
																											field: `color[${idx}].isDropDoun`,
																											value: !color.isDropDoun,
																										})
																									}
																									className={
																										'add-new-btn btn btn-outline-success btn btn-outline-success'
																									}
																									outline={true}
																								>
																									{color.isDropDoun
																										? client_manual
																										: client_default}
																								</Button>
																							</FlexRow>
																						))}
																					</div>
																				)}
																				{display === 'c' && Array.isArray(layer.moisture) && (
																					<div className="border_box">
																						<BoxTitle>
																							<h4 className="title">{client_Moisture}</h4>
																							<TodoCheck
																								initialValue={layer.moisture[0]?.isactive}
																								onChange={checked =>
																									localDispatch({
																										type: 'updateLayer',
																										idx: layerIdx,
																										field: `moisture[0].isactive`,
																										value: checked,
																									})
																								}
																							/>
																						</BoxTitle>

																						{layer.moisture.map((moisture, idx) => (
																							<FlexRow key={`MOISTURE-${idx}`}>
																								<div className="col-input">
																									<div className="label">{client_Moisture}</div>
																									{moisture.isDropDoun ? (
																										<Select
																											value={moisture.selectedmoisturelist}
																											onChange={v =>
																												localDispatch({
																													type: 'updateLayer',
																													idx: layerIdx,
																													field: `moisture[${idx}].selectedmoisturelist`,
																													value: v,
																												})
																											}
																											options={lists.moistureList}
																											className="basic-multi-select"
																											isSearchable={true}
																											isMulti={true}
																											closeMenuOnSelect={false}
																										/>
																									) : (
																										<Input
																											type="text"
																											value={moisture?.manualMoisture?.[0]}
																											onChange={e =>
																												localDispatch({
																													type: 'updateLayer',
																													idx: layerIdx,
																													field: `moisture[${idx}].manualMoisture`,
																													value: [e.target.value],
																												})
																											}
																										/>
																									)}
																								</div>
																								{moisture.isactive && (
																									<div className="col-input">
																										<div className="label">Todo note</div>
																										<Input
																											type="text"
																											value={moisture?.value}
																											onChange={e =>
																												localDispatch({
																													type: 'updateLayer',
																													idx: layerIdx,
																													field: `moisture[${idx}].value`,
																													value: e.target.value,
																												})
																											}
																										/>
																									</div>
																								)}
																								{idx === 0 ? (
																									<Tooltip title={tooltip_add}>
																										<Button
																											className={'system-btn clear-border'}
																											outline={true}
																											color={'success'}
																											onClick={() =>
																												localDispatch({
																													type: 'addMoisture',
																													layerIdx,
																												})
																											}
																										>
																											<Icon>add</Icon>
																										</Button>
																									</Tooltip>
																								) : (
																									<Tooltip title={tooltip_delete}>
																										<Button
																											className={'system-btn clear-border'}
																											outline={true}
																											color={'success'}
																											onClick={() =>
																												localDispatch({
																													type: 'removeMoisture',
																													layerIdx,
																													idx,
																												})
																											}
																										>
																											<Icon>delete</Icon>
																										</Button>
																									</Tooltip>
																								)}

																								<Button
																									title={
																										moisture.isDropDoun
																											? client_manual
																											: client_default
																									}
																									color="success"
																									onClick={() =>
																										localDispatch({
																											type: 'updateLayer',
																											idx: layerIdx,
																											field: `moisture[${idx}].isDropDoun`,
																											value: !moisture.isDropDoun,
																										})
																									}
																									className={
																										'add-new-btn btn btn-outline-success btn btn-outline-success'
																									}
																									outline={true}
																								>
																									{moisture.isDropDoun
																										? client_manual
																										: client_default}
																								</Button>
																							</FlexRow>
																						))}
																					</div>
																				)}
																				<div className="border_box">
																					<BoxTitle>
																						<h4>{client_Observation}</h4>
																						<TodoCheck
																							initialValue={layer.observation.isactive}
																							onChange={checked =>
																								localDispatch({
																									type: 'updateLayer',
																									idx: layerIdx,
																									field: `observation.isactive`,
																									value: checked,
																								})
																							}
																						/>
																					</BoxTitle>
																					<FlexRow>
																						{layer.observation?.isDropDoun ? (
																							<Input
																								type="text"
																								value={layer.observation?.value}
																								onChange={e =>
																									localDispatch({
																										type: 'updateLayer',
																										idx: layerIdx,
																										field: `observation.value`,
																										value: e.target.value,
																									})
																								}
																							/>
																						) : (
																							<Input
																								type="text"
																								value={layer.observation?.value}
																								onChange={e =>
																									localDispatch({
																										type: 'updateLayer',
																										idx: layerIdx,
																										field: `observation.value`,
																										value: e.target.value,
																									})
																								}
																							/>
																						)}
																					</FlexRow>
																				</div>
																			</Col>
																		</Row>
																		<Row>
																			<Col
																				xs={12}
																				className="margin__top"
																				style={{ textAlign: 'end' }}
																			>
																				<Button
																					title={add_sample_btn}
																					color="success"
																					onClick={() =>
																						localDispatch({ type: 'addSample', layer: layerIdx })
																					}
																					className={
																						'add-new-btn btn btn-outline-success btn btn-outline-success'
																					}
																					outline={true}
																					disabled={(function () {
																						const maxLength = Number(
																							layer.generalDetails.layerLength || 0
																						);
																						for (const sample of layer.sample) {
																							if (sample.isCompleteLayer) return true;
																							if (sample.lower_depth >= maxLength) return true;
																						}
																						return false;
																					})()}
																				>
																					{add_sample_btn}
																				</Button>
																			</Col>
																		</Row>
																		{Array.isArray(layer.sample) &&
																			layer.sample.map((sample, sampleIdx) => {
																				const { betweenStart, betweenEnd } = prepareSample(
																					layerIdx,
																					sampleIdx
																				);
																				return (
																					<Row
																						key={`SAMPLE-${layerIdx}-${sampleIdx}`}
																						className="margin__top d-flex align-items-center"
																					>
																						<Col xs={11}>
																							<Row key={`SAMPLE-${sampleIdx}`}>
																								<Col className="margin__top" xs={12}>
																									<Accordion style={{ padding: '0px' }}>
																										<AccordionSummary
																											expandIcon={<ExpandMoreIcon />}
																											aria-controls="panel1a-content"
																											id="panel1a-header"
																										>
																											<Row>
																												<Col xs={2}>
																													<ProbData
																														label={client_sample_number}
																														data={sample.sampleNo}
																														column
																													/>
																												</Col>
																												<Col xs={2}>
																													<ProbData
																														label={client_Depth}
																														data={
																															sample.sample_depth
																																? formatDepth(
																																	sample.sample_depth,
																																	unit
																																)
																																: '-'
																														}
																														column
																													/>
																												</Col>
																												<Col xs={2}>
																													<ProbData
																														label={client_probing_depth_in_probing}
																														data={
																															sample.sample_depth_in_probing
																																? formatDepth(
																																	sample.sample_depth_in_probing,
																																	unit
																																)
																																: '-'
																														}
																														column
																													/>
																												</Col>
																												<Col xs={2}>
																													<ProbData
																														label={client_Length}
																														data={
																															sample.sample_length
																																? formatDepth(
																																	sample.sample_length,
																																	unit
																																)
																																: '-'
																														}
																														column
																													/>
																												</Col>
																												<Col xs={2}>
																													<ProbData
																														label={client_probing_comment}
																														data={sample.comments || '-'}
																														column
																													/>
																												</Col>
																												<Col xs={2}>
																													<ProbData
																														label={client_probing_sample_altitude}
																														data={
																															sample.middel_depth
																																? formatDepth(
																																	sample.middel_depth,
																																	unit
																																)
																																: '-'
																														}
																														column
																													/>
																												</Col>
																											</Row>
																										</AccordionSummary>
																										<AccordionDetails>
																											<Container fluid>
																												<Row>
																													<Col xs={12} sm={6} md={4}>
																														<FormGroupRequired>
																															<Label>{client_sample_number}</Label>
																															<Input
																																type="text"
																																defaultValue={sample.sampleNo}
																																disabled
																															/>
																														</FormGroupRequired>
																													</Col>
																													<Col xs={12} sm={6} md={4}>
																														<FormGroupRequired>
																															<Label>{`${client_probing_upper_depth} (${unit})`}</Label>
																															<Input
																																type="number"
																																value={formatDepthNumber(
																																	sample.upper_depth,
																																	unit
																																)}
																																onChange={e =>
																																	localDispatch({
																																		type: 'updateSample',
																																		field: 'upper_depth',
																																		layer: layerIdx,
																																		sample: sampleIdx,
																																		value:
																																			e.target.value *
																																			(unit === 'M' ? 100 : 1),
																																	})
																																}
																																disabled={sample.isCompleteLayer}
																															/>
																														</FormGroupRequired>
																													</Col>
																													<Col xs={12} sm={6} md={4}>
																														{layer.sample.length === 1 && (
																															<FormGroup>
																																<Label>
																																	{client_probing_complete_layer}
																																</Label>
																																<Switch
																																	style={{
																																		color: sample.isCompleteLayer
																																			? '#1098F7'
																																			: '',
																																	}}
																																	checked={sample.isCompleteLayer}
																																	name="isCompleteLayer"
																																	color="primary"
																																	onChange={e =>
																																		localDispatch({
																																			type: 'updateSample',
																																			field: 'isCompleteLayer',
																																			layer: layerIdx,
																																			sample: sampleIdx,
																																			value: e.target.checked,
																																		})
																																	}
																																/>
																															</FormGroup>
																														)}
																													</Col>
																													<Col xs={12} sm={6} md={4}>
																														<FormGroupRequired>
																															<Label>{client_sample_name}</Label>
																															<div
																																style={{
																																	display: 'flex',
																																	gap: '1em',
																																}}
																															>
																																<Input
																																	type="text"
																																	value={sample.sampleName}
																																	onChange={e =>
																																		localDispatch({
																																			type: 'updateSample',
																																			field: 'sampleNameManual',
																																			layer: layerIdx,
																																			sample: sampleIdx,
																																			value: e.target.value,
																																		})
																																	}
																																	disabled={
																																		!sample.sampleNameIsManual &&
																																		editSample !== sampleIdx
																																	}
																																/>
																																<Tooltip
																																	title={
																																		editSample === sampleIdx
																																			? common_btn_okay
																																			: tooltip_edit
																																	}
																																>
																																	<Button
																																		className={
																																			'system-btn clear-border'
																																		}
																																		outline={true}
																																		color={'success'}
																																		onClick={() =>
																																			setEditSample(
																																				editSample === -1
																																					? sampleIdx
																																					: -1
																																			)
																																		}
																																	>
																																		<Icon>
																																			{editSample === sampleIdx
																																				? 'check'
																																				: 'edit'}
																																		</Icon>
																																	</Button>
																																</Tooltip>
																															</div>
																														</FormGroupRequired>
																													</Col>
																													<Col xs={12} sm={6} md={4}>
																														<FormGroupRequired>
																															<Label>{`${client_probing_lower_depth} (${unit})`}</Label>
																															<div
																																style={{
																																	display: 'flex',
																																	flexDirection: 'column',
																																}}
																															>
																																<Input
																																	type="number"
																																	value={formatDepthNumber(
																																		sample.lower_depth,
																																		unit
																																	)}
																																	onChange={e =>
																																		localDispatch({
																																			type: 'updateSample',
																																			field: 'lower_depth',
																																			layer: layerIdx,
																																			sample: sampleIdx,
																																			value:
																																				e.target.value *
																																				(unit === 'M' ? 100 : 1),
																																		})
																																	}
																																	disabled={sample.isCompleteLayer}
																																/>
																																{!sample.isCompleteLayer && (
																																	<p
																																		style={{
																																			fontSize: '0.8em',
																																			margin: 0,
																																		}}
																																	>
																																		{`[ ${formatDepth(
																																			betweenStart,
																																			unit
																																		)} (${unit}) > ${formatDepth(
																																			betweenEnd,
																																			unit
																																		)} (${unit}) ]`}
																																	</p>
																																)}
																															</div>
																														</FormGroupRequired>
																													</Col>
																												</Row>
																												<Row>
																													<Col xs={12} sm={6} md={4}>
																														<FormGroup>
																															<Label>
																																{client_probing_comment}
																															</Label>
																															<Input
																																type="text"
																																value={sample.comments}
																																onChange={e =>
																																	localDispatch({
																																		type: 'updateSample',
																																		field: 'comments',
																																		layer: layerIdx,
																																		sample: sampleIdx,
																																		value: e.target.value,
																																	})
																																}
																															/>
																														</FormGroup>
																													</Col>
																													<Col xs={12} sm={6} md={4}>
																														<FormGroup>
																															<Label>
																																{client_probing_time_of_sampling}
																															</Label>
																															<TimePicker
																																startTime={
																																	new Date(sample.samplingTime)
																																}
																																onChange={time =>
																																	localDispatch({
																																		type: 'updateSample',
																																		field: 'samplingTime',
																																		layer: layerIdx,
																																		sample: sampleIdx,
																																		value: time,
																																	})
																																}
																															/>
																														</FormGroup>
																													</Col>
																													<Col xs={12} sm={6} md={4}>
																														<FormGroup>
																															<Label>{laboratory_txt}</Label>
																															<div
																																style={{
																																	display: 'flex',
																																	gap: '1em',
																																}}
																															>
																																<Input
																																	type="text"
																																	value={sample.laboratory}
																																	onChange={e =>
																																		localDispatch({
																																			type: 'updateSample',
																																			field: 'laboratory',
																																			layer: layerIdx,
																																			sample: sampleIdx,
																																			value: e.target.value,
																																		})
																																	}
																																/>
																																<TodoCheck
																																	initialValue={sample.labactive}
																																	onChange={checked =>
																																		localDispatch({
																																			type: 'updateSample',
																																			field: 'labactive',
																																			layer: layerIdx,
																																			sample: sampleIdx,
																																			value: checked,
																																		})
																																	}
																																/>
																															</div>
																														</FormGroup>
																													</Col>
																													<Col xs={12} sm={6} md={4}>
																														<FormGroup>
																															<Label>
																																{client_campaign_reference}
																															</Label>
																															<Input
																																type="text"
																																value={sample.reference}
																																onChange={e =>
																																	localDispatch({
																																		type: 'updateSample',
																																		field: 'reference',
																																		layer: layerIdx,
																																		sample: sampleIdx,
																																		value: e.target.value,
																																	})
																																}
																															/>
																														</FormGroup>
																													</Col>
																													<Col xs={12} sm={6} md={4}>
																														<FormGroup>
																															<Label>{client_probing_remark}</Label>
																															<Input
																																type="text"
																																value={sample.remark}
																																onChange={e =>
																																	localDispatch({
																																		type: 'updateSample',
																																		field: 'remark',
																																		layer: layerIdx,
																																		sample: sampleIdx,
																																		value: e.target.value,
																																	})
																																}
																															/>
																														</FormGroup>
																													</Col>
																													<Col xs={12} sm={6} md={4}>
																														<FormGroup>
																															<Label>{client_Observation}</Label>
																															<Input
																																type="text"
																																value={sample.observation}
																																onChange={e =>
																																	localDispatch({
																																		type: 'updateSample',
																																		field: 'observation',
																																		layer: layerIdx,
																																		sample: sampleIdx,
																																		value: e.target.value,
																																	})
																																}
																															/>
																														</FormGroup>
																													</Col>
																												</Row>
																											</Container>
																										</AccordionDetails>
																									</Accordion>
																								</Col>
																							</Row>
																						</Col>
																						<Col xs={1}>
																							<Tooltip title={tooltip_delete}>
																								<Button
																									className={'system-btn clear-border'}
																									outline={true}
																									color={'success'}
																									onClick={() =>
																										localDispatch({
																											type: 'deleteSample',
																											layerIdx,
																											sampleIdx,
																										})
																									}
																								>
																									<Icon>delete</Icon>
																								</Button>
																							</Tooltip>
																						</Col>
																					</Row>
																				);
																			})}
																	</TabPane>
																))}
														</TabContent>
													</div>
												</div>
											</Col>
										</Row>
									</Form>
								</Col>

								<Col xs={3}>
									<div style={{ position: 'sticky', top: '10px' }}>
										<HighchartsReact highcharts={Highcharts} options={chartData} />
									</div>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</div>
				<ButtonToolbar className="modal__footer">
					<Button outline={true} onClick={() => onClose()} color="success">
						{cancel_btn}
					</Button>
					<Button onClick={updateProbing} color="success" disabled={!canEdit || updating}>
						{client_Update_Probing}
						{updating && <Spinner size="sm" className="btn-spinner" />}
					</Button>
				</ButtonToolbar>
			</Modal>
		</>
	);
}

const ProbData = ({ label, data, column }) => {
	return (
		<div className={`data-container ${column ? 'column' : ''}`}>
			<span className="label">{label}</span>
			<span className="data">{data}</span>
		</div>
	);
};

const ImageContainer = styled.div`
	position: relative;
	button {
		border: none;
		background: none;
	}
	img {
		min-height: 70px;
		width: 120px;
		height: 70px;
		border: 'solid 1px #dadada';
		z-index: 1;
		margin-inline-end: 2px;
		object-fit: cover;
	}
	.button {
		position: absolute;
		right: 15px;
		bottom: 5px;
		button {
			background-color: rgba(255, 255, 255, 0.4);
		}
	}
`;

const NavigationRow = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	gap: 10px;
	font-size: 1.2rem;
	.btn {
		margin: 0;
	}
	.code {
		font-size: 1.5rem;
	}
`;

const BoxTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	.title {
		display: flex;
		gap: 1rem;
		align-items: center;
	}
`;

export const FlexRow = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 0.5rem;
	.soilFamSec {
		min-width: calc(50% - 10px) !important;
	  }
	.col-input {
		display: flex;
		flex-direction: column;
		flex: 1;
		min-width: 200px;
		&.required {
			.label::after {
				content: '*';
				color: red;
				margin-inline-start: 0.5em;
			}
		}
	}
`;
