import React, { useEffect, useReducer, useState } from 'react';
import { Button, Col, Input, Modal, ModalFooter, Row, Spinner } from 'reactstrap';
import __ from 'underscore';
import styled from 'styled-components';
import randomString from '../../../../../lib/randomString';
import * as ChangeAppLanguageFront from '../../../../Layout/ChangeAppLanguageFront';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Icon, Tooltip, Switch } from '@material-ui/core';
import uuid from 'uuid/v4';
import { useSelector } from 'react-redux';

const ALL_DISPOSALLIST = 'All';
const DEFAULT_DISPOSALLIST = 'Default';

let tlang = ChangeAppLanguageFront.translateLanguage;
const cancel_btn = tlang('cancel_btn') || 'Cancel';
const submit_btn = tlang('submit_btn') || 'Submit';
const set_standard_order = tlang('set_standard_order') || 'Set Standard Disposal Order';
const set_standard_list = tlang('set_standard_list') || 'Set Standard Disposal List';
const disposal_modal_main_disposal_list_is_required = tlang('disposal_modal_main_disposal_list_is_required') || 'Disposal list name is required';
const btn_add_disposal_list = tlang('btn_add_disposal_list') || 'Add Disposal List';
const warning_empty_active_disposallist = tlang('warning_empty_active_disposallist') || 'Active disposal list should require at least one disposal';
const tooltip_delete = tlang('tooltip_delete') || 'Delete';
const tooltip_edit = tlang('tooltip_edit') || 'Edit';
const client_set_as_active_disposal = tlang('client_set_as_active_disposal') || 'Set as active disposal';

const initialState = {
	actDispListIdx: '',
	actDispListName: '',
	category: {},
	selDisposalType: '',
	mainCatVal: '',
	editCatIdx: '',
	subCatVal: '',
	errors: {},
	activeCatIdx : '',
};

function reducer(state, action) {
	switch (action.type) {
		default:
			const upd = {};
			for (const key of Object.keys(action)) {
				upd[key] = action[key];
			}
			// console.log(`upd`, { upd });
			return { ...state, ...upd };
	}
}

export default function ModalStandardDisposalOrder({ isOpen, onClose, initialData, isloading, selDisposalType, onSave }) {
	const [state, localDispatch] = useReducer(reducer, initialState);
	const [errors, setErrors] = useState({});

	const authReducer = useSelector(state => state.authReducer);
	const selDispListName = authReducer.userData.default_disposal_list != "undefined" ? authReducer.userData.default_disposal_list : '';
	const default_disposal_type = authReducer.userData.default_disposal_type != "undefined" ? authReducer.userData.default_disposal_type : 'master';
	
	useEffect(() => {
		if (!__.isEmpty(initialData)) {

			// initialData.map((initData, idx) => {
			// 	initialData[idx]['unqid'] = uuid();
			// })

			let actDispListIdx = '';
			let actDispListName = '';
			if (selDisposalType === default_disposal_type) {
				if (selDispListName != '') {
					let allNames = Object.keys(initialData);
					actDispListIdx = allNames.findIndex((l) => l === selDispListName);
					actDispListName = allNames[actDispListIdx];
				}
			}

			for (let catName in initialData) {
				let allItems = initialData[catName];
				allItems && allItems.map((l, i) => {
					l.unqid = uuid();
				})
			}

			localDispatch({
				//category: { 'Default': initialData },
				category: initialData,
				selDisposalType: selDisposalType,
				actDispListIdx: actDispListIdx,
				actDispListName: actDispListName,
				activeCatIdx: actDispListIdx,
				errors: {},
				editCatIdx: '',
			});
		} else {
			localDispatch({ selDisposalType: selDisposalType, errors: {}, editCatIdx: '' });
		}
	}, [initialData]);


	const reorder = (catName, startIndex, endIndex) => {
		const result = Array.from(catName);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const copy = (source, destination, droppableSource, droppableDestination) => {
		const sourceClone = Array.from(source);
		const destClone = Array.from(destination);
		const item = sourceClone[droppableSource.index];

		destClone.splice(droppableDestination.index, 0, { ...item, unqid: uuid() });
		return destClone;
	};

	const move = (source, destination, droppableSource, droppableDestination) => {
		const sourceClone = Array.from(source);
		const destClone = Array.from(destination);
		const [removed] = sourceClone.splice(droppableSource.index, 1);

		destClone.splice(droppableDestination.index, 0, removed);

		const result = {};
		result[droppableSource.droppableId] = sourceClone;
		result[droppableDestination.droppableId] = destClone;

		return result;
	};


	const onDragEnd = result => {
		const { source, destination } = result;

		//console.log('==> result', result);

		// dropped outside the catName
		if (!destination) {
			return;
		}

		switch (source.droppableId) {
			case destination.droppableId: {
				let allCategory = { ...state.category };
				let destinationItems = [...state.category[destination.droppableId]];
				let reorderItems = reorder(
					state.category[source.droppableId],
					source.index,
					destination.index
				)

				destinationItems = reorderItems;
				allCategory[destination.droppableId] = destinationItems;

				localDispatch({ category: allCategory })
				break;
			}
			case 'ITEMS': {

				let allCategory = { ...state.category };
				let destinationItems = [...state.category[destination.droppableId]];
				let allSourceItems = [...state.category[source.droppableId]];
				let sourceItemIndex = source.index;
				let sourceItemName = allSourceItems[sourceItemIndex].name;

				let checkExistItem = destinationItems.filter((l) => l.name === sourceItemName);

				if (checkExistItem.length <= 0) {
					let copyresult = copy(
						allSourceItems,
						state.category[destination.droppableId],
						source,
						destination
					)

					destinationItems = copyresult;
					allCategory[destination.droppableId] = destinationItems;

					localDispatch({
						category: allCategory
					})

				}
				break;
			}
			default: {

				//User can not drop items in Default list (standard disposal list)
				if (destination.droppableId === DEFAULT_DISPOSALLIST) return;

				let allCategory = { ...state.category };
				let destinationItems = [...state.category[destination.droppableId]];
				let allSourceItems = [...state.category[source.droppableId]];
				let sourceItemIndex = source.index;
				let sourceItemName = allSourceItems[sourceItemIndex].name;

				let checkExistItem = destinationItems.filter((l) => l.name === sourceItemName);

				if (checkExistItem.length <= 0) {
					let copyresult = copy(
						allSourceItems,
						state.category[destination.droppableId],
						source,
						destination
					)

					destinationItems = copyresult;
					allCategory[destination.droppableId] = destinationItems;

					localDispatch({
						category: allCategory
					})

				}

				//     move(
				//         state.category[source.droppableId],
				//         state.category[destination.droppableId],
				//         source,
				//         destination
				//     )

				break;
			}
		}
	};

	const handleChange = (keyName, e) => {
		let inpVal = e.target.value;
		localDispatch({ [keyName]: inpVal });
	}

	const addCategory = (e) => {
		let allCategory = { ...state.category };
		let allErrors = { ...state.errors }
		let allCatName = Object.keys(allCategory);
		allCatName = allCatName.map(element => {
			return element.toLowerCase();
		})
		let mainCatVal = state.mainCatVal;
		if (isNaN(mainCatVal) == true && mainCatVal != "" && !allCatName.includes(state.mainCatVal.toLowerCase().trim())) {
			allCategory[mainCatVal] = [];
			allErrors['mainCatVal'] = false;
			localDispatch({ category: allCategory, mainCatVal: "", errors: allErrors });
		} else {
			allErrors['mainCatVal'] = true;
			localDispatch({ errors: allErrors });
		}
	}

	function updtCatLabel(idx, catName) {

		let allCategory = { ...state.category };
		let allErrors = { ...state.errors }
		let allCatName = Object.keys(allCategory).filter((l) => l != catName);
		allCatName = allCatName.map(element => {
			return element.toLowerCase();
		})
		let inpVal = state.subCatVal;
		if (isNaN(inpVal) == true && inpVal != "" && !allCatName.includes(state.subCatVal.toLowerCase().trim())) {
			// var index = 0;
			// for (let key in allCategory) {
			// 	if (index === idx) {
			// 		let temp = allCategory[key];
			// 		delete allCategory[key];
			// 		allCategory[inpVal] = temp;
			// 	}
			// 	index++;
			// }

			let allNames = Object.keys(allCategory);
			allNames[idx] = inpVal.trim();

			let tempCategory = {};
			let i = 0;
			for (var prop in allCategory) {
				let name = allNames[i];
				tempCategory[name] = allCategory[prop];
				i++;
			}

			allErrors[`subCatVal${idx}`] = false;
			localDispatch({ category: tempCategory, editCatIdx: "", errors: allErrors });
		} else {
			allErrors[`subCatVal${idx}`] = true;
			localDispatch({ errors: allErrors });
		}
	}

	function editCategory(idx, catName) {
		localDispatch({ editCatIdx: idx, subCatVal: catName });
	}

	function delCategory(idx, catName) {
		let allCategory = { ...state.category };
		delete allCategory[catName];

		let allErrors = { ...state.errors }
		if (typeof allErrors[`subCatVal${idx}`] != "undefined") {
			delete allErrors[`subCatVal${idx}`];
		}

		let curActDispListIdx = state.actDispListIdx;
		if (state.actDispListIdx != '' && curActDispListIdx > idx) {
			curActDispListIdx = curActDispListIdx - 1;
		}

		localDispatch({ category: allCategory, errors: allErrors, editCatIdx: "", actDispListIdx: curActDispListIdx });
	}

	function removeItem(catName, idx) {
		let allCategory = { ...state.category };
		const [removed] = allCategory[catName].splice(idx, 1);
		localDispatch({ category: allCategory });
	}

	function saveDisposalList() {
		let isupdate_default_disposal_list = '';
		let category = { ...state.category };
		let allNames = Object.keys(category);
		let allErrors = { ...state.errors }

		if (state.actDispListIdx != '' && state.actDispListName != '') {
			let newName = allNames[state.actDispListIdx];

			//Check Active disposal list have atleast one disposal
			if (typeof newName != 'undefined' && newName != DEFAULT_DISPOSALLIST && !category[newName].length) {
				allErrors['isEmptyActDisList'] = true;
				localDispatch({ errors: allErrors });
				return;
			} else {
				allErrors['isEmptyActDisList'] = false;
				localDispatch({ errors: allErrors });
			}

			if (typeof newName != 'undefined' && newName != state.actDispListName) {
				isupdate_default_disposal_list = newName;
			}
		}

		//Remove 'All' Disposal list as it only use for filled other disposal list
		if (typeof category[ALL_DISPOSALLIST] != 'undefined') {
			delete category[ALL_DISPOSALLIST];
		}

		let activeCatName = ''; //toggle active disposal category name
		if(allNames[state.activeCatIdx]){
			activeCatName = allNames[state.activeCatIdx];
		}
		onSave(category, isupdate_default_disposal_list, activeCatName)
	}

	function checkHandleColor(hexcolor) {
		let color = hexcolor.substring(1);
		let r = parseInt(color.substr(0, 2), 16);
		let g = parseInt(color.substr(2, 2), 16);
		let b = parseInt(color.substr(4, 2), 16);
		let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
		return yiq > 200 ? '' : hexcolor;
	}

	function checkTextColor(hexcolor) {
		let color = hexcolor.substring(1);
		let r = parseInt(color.substr(0, 2), 16);
		let g = parseInt(color.substr(2, 2), 16);
		let b = parseInt(color.substr(4, 2), 16);
		let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
		return yiq > 200 ? '' : '#fff';
	}

	function toggleActiveCatgory(checked, idx){
		if(checked) localDispatch({ activeCatIdx: idx });
		else localDispatch({ activeCatIdx: state.actDispListIdx });
	}

	return (

		<DragDropContext onDragEnd={onDragEnd}>
			<Modal
				isOpen={isOpen}
				toggle={onClose}
				className={`modal-dialog--success modal-dialog--header max__width_90`}
			>
				<div className="modal__header">
					<button className="lnr lnr-cross modal__close-btn" type="button" onClick={onClose} />
					<h4 className="bold-text  modal__title">{set_standard_list + " - " + state.selDisposalType}</h4>
				</div>
				<div className="modal__body">

					<Content>
						<Row>
							<Col xs={5}>
								<Input
									type="text"
									value={state.mainCatVal}
									onChange={e => handleChange('mainCatVal', e)}
								/>
								{state.errors.mainCatVal && (
									<span className="error-message">{disposal_modal_main_disposal_list_is_required}</span>
								)}
								{state.errors.isEmptyActDisList && (
									<span style={inline} className="error-message">{warning_empty_active_disposallist}</span>
								)}
							</Col>
							<Col xs={7}>
								<Button color="success" size="sm" onClick={addCategory}>
									{btn_add_disposal_list}
								</Button>
							</Col>
						</Row>

						{Object.keys(state.category).map((catName, i) => {
							return (
								<Droppable key={catName} droppableId={catName} isDropDisabled={false}>
									{(provided, snapshot) => (
										<>
											<Container
												ref={provided.innerRef}
												isDraggingOver={
													snapshot.isDraggingOver
												}>
												<div style={catHeader}>
													{state.editCatIdx !== "" && (catName != DEFAULT_DISPOSALLIST && catName != ALL_DISPOSALLIST) && state.editCatIdx == i ? (
														<>
															<Input
																type="text"
																value={state.subCatVal}
																onChange={e => handleChange('subCatVal', e)}
															/>
															{state.errors[`subCatVal${i}`] && (
																<div className="error-message" style={fontSmall}>
																	{disposal_modal_main_disposal_list_is_required}
																</div>
															)}
														</>
													) : (<DisposalListName isactive={(state.actDispListIdx !== '' && state.actDispListIdx === i)}>{catName}</DisposalListName>)
													}
												</div>
												<div style={catHeader}>
													{state.editCatIdx !== "" && (catName != DEFAULT_DISPOSALLIST && catName != ALL_DISPOSALLIST) && state.editCatIdx == i ?
														<Button outline={true} color={'success'} className={"system-btn clear-border"} onClick={() => updtCatLabel(i, catName)} ><Tooltip title={tooltip_edit}><Icon>check</Icon></Tooltip></Button>
														:
														(catName != DEFAULT_DISPOSALLIST && catName != ALL_DISPOSALLIST) &&
														<Button outline={true} color={'success'} className={"system-btn clear-border"} onClick={() => editCategory(i, catName)} ><Tooltip title={tooltip_edit}><Icon>edit</Icon></Tooltip></Button>
													}
													{(catName != DEFAULT_DISPOSALLIST && catName != ALL_DISPOSALLIST) && state.actDispListIdx !== i &&
														<Button outline={true} color={'success'} className={"system-btn clear-border"} onClick={() => delCategory(i, catName)} ><Tooltip title={tooltip_delete}><Icon>delete</Icon></Tooltip></Button>
													}
													{(catName != ALL_DISPOSALLIST) &&
														<Switch
															checked={i === state.activeCatIdx ? true : false}
															onChange={e => toggleActiveCatgory(e.target.checked, i)}
															title={client_set_as_active_disposal}
															disabled={state.category[catName].length ? false : true}
														/>
													}
												</div>

												{state.category[catName].length
													? state.category[catName].map(

														(item, index) => (

															<Draggable
																key={item.unqid}
																draggableId={item.unqid}
																index={index}>
																{(
																	provided,
																	snapshot
																) => (
																	<Item
																		ref={
																			provided.innerRef
																		}
																		{...provided.draggableProps}
																		isDragging={
																			snapshot.isDragging
																		}
																		color={
																			item.color
																		}
																		style={
																			provided
																				.draggableProps
																				.style
																		}>
																		<Handle
																			{...provided.dragHandleProps}>
																			<svg
																				width="24"
																				//color={checkHandleColor(item.color)}
																				color={'var(--clr-aexdo)'}
																				height="29"
																				viewBox="0 0 24 24">
																				<path
																					fill="currentColor"
																					d="M3,15H21V13H3V15M3,19H21V17H3V19M3,11H21V9H3V11M3,5V7H21V5H3Z"
																				/>
																			</svg>
																		</Handle>
																		<span style={{ color: checkTextColor(item.color), fontSize: 'medium' }}>{item.name}</span>
																		{(catName != DEFAULT_DISPOSALLIST && catName != ALL_DISPOSALLIST) &&
																			<Button style={{ marginLeft: "auto", cursor: "Pointer", color: checkTextColor(item.color) }} outline={true} color={'success'} className={"system-btn clear-border"} onClick={() => removeItem(catName, index)} ><Tooltip title={tooltip_delete}><Icon>close</Icon></Tooltip></Button>
																		}
																	</Item>
																)}
															</Draggable>
														)
													)
													: !provided.placeholder && (
														<Notice>
															Drop items here
														</Notice>
													)}
												{provided.placeholder}
											</Container>
										</>
									)}
								</Droppable>
							);
						})}
					</Content>

				</div>
				<ModalFooter>
					<Button outline={true} color="success" size="sm" onClick={onClose}>
						{cancel_btn}
					</Button>
					<Button color="success" size="sm" onClick={() => saveDisposalList()}>
						{submit_btn}
						{isloading && (<Spinner size="sm" className="btn-spinner" />)}
					</Button>
				</ModalFooter>
			</Modal>
		</DragDropContext>
	);
}


const DisposalListName = styled.div`
    margin-right: 25px;
	font-weight: ${props => (props.isactive ? '900' : '100')};
`;

const Content = styled.div`
    
`;

const Item = styled.div`
    display: flex;
    user-select: none;
    padding: 0.5rem;
    margin: 0 0 0.5rem 0;
    align-items: flex-start;
    align-content: flex-start;
    line-height: 1.5;
    border-radius: 3px;
    background: ${props => (props.color ? props.color : '#fff')};
    border: 1px ${props => (props.isDragging ? 'dashed #4099ff' : 'solid #ddd')};
`;

const Handle = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    user-select: none;
    margin: -0.5rem 0.5rem -0.5rem -0.5rem;
    padding: 0.5rem;
    line-height: 1.5;
    border-radius: 3px 0 0 3px;
    background: #fff;
    border-right: 1px solid #ddd;
    color: #000;
`;

const List = styled.div`
    border: 1px
        ${props => (props.isDraggingOver ? 'dashed #000' : 'solid #ddd')};
    background: #fff;
    padding: 0.5rem 0.5rem 0;
    border-radius: 3px;
    flex: 0 0 150px;
    font-family: sans-serif;
    display: inline-table;
`;

const Container = styled(List)`
    margin: 0.5rem 0.5rem 1.5rem;
    background: #ccc;
    width: fit-content;
    min-width: 20%;
	float: left;
`;

const Notice = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 0.5rem;
    margin: 0 0.5rem 0.5rem;
    border: 1px solid transparent;
    line-height: 1.5;
    color: #aaa;
`;

const iconClose = {
	marginLeft: "auto",
	cursor: "Pointer",
	color: '#fff',
}

const iconDelete = {
	cursor: "Pointer",
	color: 'var(--clr-aexdo)',
}

const iconEdit = {
	marginRight: "10px",
	cursor: "Pointer",
	color: 'var(--clr-aexdo)',
}

const catHeader = {
	display: "inline-flex",
	fontSize: "x-large"
}

const catHeaderName = {
	fontSize: "x-large",
	float: "left"
}

const marginRight25 = {
	marginRight: "25px"
}

const fontSmall = {
	fontSize: "small"
}

const inline = {
	display: 'inline-block'
}

