import React, { useEffect, useReducer, useState } from 'react';
import {
	Button,
	ButtonToolbar,
	Col,
	Container,
	FormGroup,
	Input,
	Label,
	Modal,
	Row,
	Spinner,
} from 'reactstrap';
import styled from 'styled-components';
import { Checkbox, FormControlLabel, Grid, Icon, Tooltip } from '@material-ui/core';
import __ from 'underscore';
import * as ChangeAppLanguageFront from '../../../../Layout/ChangeAppLanguageFront';
import Form from '../../../components/form';
import ModalDisposalThresholdSingleRule from './disposalThresholdSingleRule';

const tlang = ChangeAppLanguageFront.translateLanguage;

const thresholdModal_title = tlang('thresholdModal_title') || 'Add Threshold';
const threshold_defaultrule_message =
	tlang('threshold_defaultrule_message') ||
	'When rule has not been set for a pollutant yet and we typed value in the blank pollutant input then system is by default consider Rule -1.';
const save_btn = tlang('save_btn') || 'Save';
const cancel_btn = tlang('cancel_btn') || 'Cancel';
const btn_set_rule = tlang('btn_set_rule') || 'Set Rule';
const btn_edit_rule = tlang('btn_edit_rule') || 'Edit Rule';
const btn_remove_rule = tlang('btn_remove_rule') || 'Remove Rule';
const lbl_applied_rule = tlang('lbl_applied_rule') || 'Applied Rule';
const lbl_threshold_value = tlang('lbl_threshold_value') || 'Threshold Value';
const lbl_multiple_pollutants = tlang('lbl_multiple_pollutants') || 'Multiple Pollutants';
const lbl_no_limit = tlang('lbl_no_limit') || 'No Limit';
const lbl_percentage = tlang('lbl_percentage') || 'Percentage';

const initialState = {
	allFamilyList: [],
	pollutantDetails: [],
	deleteRulesArr: [],
	testRule: [],
	singleRuleModal: false,
};

function reducer(state, action) {
	switch (action.type) {
		default:
			const upd = {};
			for (const key of Object.keys(action)) {
				upd[key] = action[key];
			}
			return { ...state, ...upd };
	}
}

export default function ModalDisposalThreshold({
	isOpen,
	onClose,
	disposal,
	testRuleList,
	onSave,
}) {
	const [isLoading, setIsLoading] = useState(true);
	const [errors, setErrors] = useState({});
	const [state, localDispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		if (__.isEmpty(disposal)) return;
		let allFamilyList = disposal.test_list;
		let pollutantDetails = [];

		//replace _id key to id
		testRuleList = testRuleList.map(({ _id: id, ...rest }) => ({
			id,
			...rest,
		}));

		allFamilyList.map(family => {
			let familyId = family._id;
			family.children.map(pollutant => {
				let pollutantId = pollutant.id;
				let rule = testRuleList.find(
					r => Number(r.child_id) === pollutantId && r.test_id === familyId
				);
				if (rule) {
					pollutantDetails.push(rule);
				} else {
					let getPollutant = getNewPollutant(pollutant, family);
					pollutantDetails.push(getPollutant);
				}
			});
		});
		localDispatch({ allFamilyList: allFamilyList, pollutantDetails: pollutantDetails });
		setIsLoading(false);
	}, [disposal, testRuleList]);

	function getNewPollutant(pollutant, family) {
		let pollutantObj = {
			id: '',
			desposal_id: disposal._id,
			desposal_name: disposal.name,
			test_id: family._id,
			test_name: family.name,
			child_id: pollutant.id.toString(),
			child_name: pollutant.name,
			children: pollutant,
			value: '',
			option_id: '',
			nolimit_finalvalue: false,
			formula: {
				condition: 'less',
				test: [],
				percentage: 0,
				nolimit: false,
			},
		};
		return pollutantObj;
	}
	function getPollutantDetails(familyId, pollutantId) {
		const pollutantDetails = [...state.pollutantDetails];
		let findRule = pollutantDetails.find(p => p.test_id == familyId && p.child_id == pollutantId);
		return findRule;
	}
	function openTestRuleModal(familyId, pollutantId) {
		let findPollutant = getPollutantDetails(familyId, pollutantId);
		localDispatch({ testRule: findPollutant, singleRuleModal: true });
	}
	function closeTestRuleModal() {
		localDispatch({ testRule: [], singleRuleModal: false });
	}
	function deleteRule(familyId, pollutantId) {
		let findPollutant = getPollutantDetails(familyId, pollutantId);
		let deleteRulesArr = [...state.deleteRulesArr];
		let obj = {
			familyId: familyId,
			pollutantId: pollutantId,
			ruleId: findPollutant.id,
		};
		if (findPollutant.id) deleteRulesArr.push(obj);

		const family = {
			_id: findPollutant.test_id,
			name: findPollutant.test_name,
		};
		let getPollutant = [getNewPollutant(findPollutant.children, family)];
		let pollutantDetails = [...state.pollutantDetails];
		pollutantDetails = pollutantDetails.map(
			obj => getPollutant.find(o => o.child_id === obj.child_id && o.test_id === obj.test_id) || obj
		);

		localDispatch({ pollutantDetails: pollutantDetails, deleteRulesArr: deleteRulesArr });
	}
	function validate(familyId, pollutantId, value) {
		let findPollutant = getPollutantDetails(familyId, pollutantId);
		if (value.key === 'value') {
			findPollutant.value = value.val;
		} else if (value.key === 'isOptional') {
			findPollutant.isOptional = value.val;
		} else {
			findPollutant.isOptional = false;
		}

		if (!findPollutant.option_id) findPollutant.option_id = '1';
		findPollutant = [findPollutant];

		let pollutantDetails = [...state.pollutantDetails];
		pollutantDetails = pollutantDetails.map(
			obj =>
				findPollutant.find(o => o.child_id === obj.child_id && o.test_id === obj.test_id) || obj
		);
		localDispatch({ pollutantDetails: pollutantDetails });
	}
	function saveSingleTestRule(rule) {
		let pollutantDetails = [...state.pollutantDetails];
		let getPollutant = [rule];
		pollutantDetails = pollutantDetails.map(
			obj => getPollutant.find(o => o.child_id === obj.child_id && o.test_id === obj.test_id) || obj
		);
		localDispatch({ pollutantDetails: pollutantDetails });
	}

	function handleSave() {
		let pollutantDetails = [...state.pollutantDetails];
		pollutantDetails = pollutantDetails.filter(l => l.option_id !== '');
		pollutantDetails = pollutantDetails.map(detail => {
			detail.isOptional = detail?.isOptional === true;
			return detail;
		});
		let deleteRulesArr = state.deleteRulesArr.map(value => value.ruleId);
		console.log("PollutantDetails",pollutantDetails)
		onSave(pollutantDetails, deleteRulesArr);
		onClose();
	}

	return (
		<>
			<Modal
				isOpen={isOpen}
				//toggle={() => onClose()}
				className={`modal-dialog--success modal-dialog--header width__70`}
			>
				<div className="modal__header">
					<button
						className="lnr lnr-cross modal__close-btn"
						type="button"
						onClick={() => onClose()}
					/>
					<h4 className="bold-text  modal__title">
						{`${thresholdModal_title} - ${disposal.name}`}
					</h4>
				</div>
				<div className="modal__body">
					<Form>
						<Container>
							{isLoading ? (
								<Row>
									<Col xs={12} className="margin__top text-center">
										<Spinner size="sm" className="btn-spinner spinner_color spinner_size" />
									</Col>
								</Row>
							) : (
								<Row>
									<Col xs={12} md={12}>
										<h5 className="bold-text">{`${threshold_defaultrule_message}`}</h5>
									</Col>
									{state.allFamilyList.map((family, fidx) => {
										return (
											<>
												<Col xs={12} md={12} className="margin__top margin__bottom">
													<h4 className="bold-text">{`${family.name}`}</h4>
												</Col>
												{family.children.map((pollutant, pidx) => {
													const polluDetls = getPollutantDetails(family._id, pollutant.id);
													const isDeleted =
														state.deleteRulesArr.length &&
														state.deleteRulesArr.filter(
															l =>
																l.familyId == polluDetls.test_id &&
																l.pollutantId.toString() == polluDetls.child_id
														);
													return (
														<>
															<Col xs={12} md={6} className="">
																<Grid style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
																	<Grid style={{ width: '95%' }}>
																		<FormGroup>
																			<Label>{pollutant.name}</Label>
																			<Input
																				onChange={e =>
																					validate(family._id, pollutant.id, {
																						key: 'value',
																						val: e.target.value,
																					})
																				}
																				type="number"
																				value={polluDetls.value}
																				disabled={
																					polluDetls.option_id === '3' ||
																					polluDetls.option_id === '4' ||
																					isDeleted.length
																						? true
																						: false
																				}
																			/>
																		</FormGroup>
																	</Grid>
																	<Grid
																		style={{ width: '5%', display: 'flex', alignItems: 'center' }}
																	>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={polluDetls?.isOptional === true}
																					color="primary"
																					style={{ color: '#c00' }}
																				/>
																			}
																			disabled={
																				polluDetls.option_id === '3' ||
																				polluDetls.option_id === '4' ||
																				isDeleted.length
																					? true
																					: false
																			}
																			onChange={e =>
																				validate(family._id, pollutant.id, {
																					key: 'isOptional',
																					val: e.target.checked,
																				})
																			}
																			style={{ marginLeft: '7%', height: '30px', width: '30px' }}
																		/>
																	</Grid>
																</Grid>
															</Col>
															<Col xs={12} md={6} className="">
																{!isDeleted.length &&
																	(polluDetls.option_id === '' ? (
																		<OperationTool>
																			<Button
																				className={'system-btn m-0'}
																				outline={true}
																				color="success"
																				size="sm"
																				onClick={event =>
																					openTestRuleModal(family._id, pollutant.id)
																				}
																			>
																				{btn_set_rule}
																			</Button>
																		</OperationTool>
																	) : (
																		<OperationTool>
																			<Button
																				className={'system-btn m-0'}
																				outline={true}
																				color="success"
																				size="sm"
																				onClick={event =>
																					openTestRuleModal(family._id, pollutant.id)
																				}
																			>
																				{btn_edit_rule}
																			</Button>
																			<Button
																				style={{ left: '10px' }}
																				outline={true}
																				color={'success'}
																				className={'system-btn clear-border'}
																				onClick={() => deleteRule(family._id, pollutant.id)}
																			>
																				<Tooltip title={btn_remove_rule}>
																					<Icon>delete</Icon>
																				</Tooltip>
																			</Button>
																			<RuleDetails polluDetls={polluDetls} />
																		</OperationTool>
																	))}
															</Col>
														</>
													);
												})}
											</>
										);
									})}
								</Row>
							)}
						</Container>
					</Form>
				</div>
				<ButtonToolbar className="modal__footer">
					<Button color={'success'} outline={true} onClick={() => onClose()}>
						{cancel_btn}
					</Button>
					<Button color={'success'} onClick={() => handleSave()} disabled={isLoading}>
						{save_btn}
						{isLoading && <Spinner size="sm" className="btn-spinner" />}
					</Button>
				</ButtonToolbar>
			</Modal>

			{state.singleRuleModal && (
				<ModalDisposalThresholdSingleRule
					isOpen={state.singleRuleModal}
					onClose={() => closeTestRuleModal()}
					testRule={state.testRule}
					allFamilyList={state.allFamilyList}
					onSave={rule => saveSingleTestRule(rule)}
				/>
			)}
		</>
	);
}

function RuleDetails({ polluDetls }) {
	let rule_nolimit_finalvalue = false;
	if (polluDetls?.nolimit_finalvalue) rule_nolimit_finalvalue = true;
	let rule_option_name;
	if (polluDetls.option_id == '1') {
		rule_option_name = lbl_threshold_value;
	} else if (polluDetls.option_id == '2') {
		rule_option_name = lbl_multiple_pollutants;
	} else if (polluDetls.option_id == '3') {
		rule_option_name = lbl_no_limit + (rule_nolimit_finalvalue ? ' *' : '');
	} else if (polluDetls.option_id == '4') {
		rule_option_name = lbl_percentage;
	}
	return (
		<h5>
			{lbl_applied_rule}
			{' - '} {polluDetls.option_id} {'( ' + rule_option_name + ' ) '}
		</h5>
	);
}

const OperationTool = styled.div`
	display: flex;
	align-items: center;
	margin-top: 20px;
	h5 {
		color: var(--clr-aexdo);
		font-weight: 500;
	}
`;
