import React from "react";
import {
  Button,
  ButtonToolbar,
  Modal,
  Row,
  Col,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Label,
} from "reactstrap";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "react-phone-number-input/style.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Switch from "@material-ui/core/Switch";
import moment from "moment-timezone";
import Dropzone from "react-dropzone";
import PlacesAutocomplete from "react-places-autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
import { Icon } from "@material-ui/core";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import ReactFlagsSelect from "react-flags-select";
import "react-flags-select/scss/react-flags-select.scss";
import Checkbox from "@material-ui/core/Checkbox";
import { Select, ListItemText } from "@material-ui/core";
import classnames from "classnames";
import MUIDataTable from "mui-datatables";
import Chips from "react-chips";
import Input from "@material-ui/core/TextField";
import ColorPicker from "material-ui-color-picker";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumericInput from "react-numeric-input";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import MultiSelect from "react-multi-select-component";
import InputLabel from "@material-ui/core/InputLabel";
import ChipInput from 'material-ui-chip-input'
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Select2 from 'react-select';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import { withStyles } from '@material-ui/core/styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import * as classNames from 'classnames';
import Lightbox from 'react-images';
import styled from 'styled-components';

moment.tz.setDefault('Europe/Paris');

const RedSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: '#cc0000',
    },
    '&$checked + $track': {
      backgroundColor: "#E64823",
    },
  },
  checked: {},
  track: {},
})(Switch);

const theme = createTheme({
  palette: {
    primary: {
      light: '#E64823',
      main: '#E64823',
      dark: '#E64823',
      contrastText: '#fff',
    },
  },
});
let customStyle = {
  maxHeight: "400px",
  overflowY: "scroll",
};

let testFamilyDetailsBox = {
  padding: "10px 0px",
  border: "1px solid #949494",
  borderRadius: "5px",
  margin: "10px 0px"
}

let tlang = ChangeAppLanguageFront.translateLanguage;

const sign_in_up_from_mobile_phone = tlang('sign_in_up_from_mobile_phone') || 'Mobile Phone';
const user_request_modal_request_detail = tlang('user_request_modal_request_detail') || 'Request Details';
const user_request_modal_company_logo = tlang('user_request_modal_company_logo') || 'Company Logo';
const user_request_modal_name_of_company = tlang('user_request_modal_name_of_company') || 'Name of company';
const user_request_modal_address = tlang('user_request_modal_address') || 'Address';
const user_request_modal_country = tlang('user_request_modal_country') || 'Country';
const user_request_modal_account_manager = tlang('user_request_modal_account_manager') || 'Account Manager';
const client_add_todo_btn = tlang('client_add_todo_btn') || 'Add Todo';
const user_request_modal_email = tlang('user_request_modal_email') || 'Email';
const user_request_modal_phone = tlang('user_request_modal_phone') || 'Phone';
const user_request_modal_request_time = tlang('user_request_modal_request_time') || 'Request Time';
const user_request_modal_attechments = tlang('user_request_modal_attechments') || 'Attechments';
const common_btn_okay = tlang('common_btn_okay') || 'Okay';
const view_staff_details_modal_staff_user_detail = tlang('view_staff_details_modal_staff_user_detail') || 'Staff User Details';
const view_staff_details_modal_first_name = tlang('view_staff_details_modal_first_name') || 'First Name';
const view_staff_details_modal_last_name = tlang('view_staff_details_modal_last_name') || 'Last Name';
const view_staff_details_modal_email = tlang('view_staff_details_modal_email') || 'Email';
const view_staff_details_modal_phone = tlang('view_staff_details_modal_phone') || 'Phone';
const view_staff_details_modal_access_module = tlang('view_staff_details_modal_access_module') || 'Access Module';
//const common_btn_okay = tlang('common_btn_okay') || 'Okay';
const create_update_staff_modal_create_new_staff_user = tlang('create_update_staff_modal_create_new_staff_user') || 'Create New Staff User';
const create_update_staff_modal_update_staff_user = tlang('create_update_staff_modal_update_staff_user') || 'Edit Staff User Details';
const create_update_staff_modal_firstname = tlang('create_update_staff_modal_firstname') || 'Firstname';
const create_update_staff_modal_pls_enter_fname = tlang('create_update_staff_modal_pls_enter_fname') || '* Please Enter Firstname';
const create_update_staff_modal_lastname = tlang('create_update_staff_modal_lastname') || 'Lastname';
const create_update_staff_modal_pls_enter_lname = tlang('create_update_staff_modal_pls_enter_lname') || '* Please Enter Lastname';
const create_update_staff_modal_email = tlang('create_update_staff_modal_email') || 'Email';
const create_update_staff_modal_pls_enter_email = tlang('create_update_staff_modal_pls_enter_email') || '* Please Enter Valid Email';
const create_update_staff_modal_pls_enter_mobile = tlang('create_update_staff_modal_pls_enter_mobile') || '* Please Enter Valid Mobile Number';
const create_update_staff_modal_access_page = tlang('create_update_staff_modal_access_page') || 'Access Page';
const create_btn = tlang('create_btn') || 'Create';
const create_update_staff_modal_update = tlang('create_update_staff_modal_update') || 'Update';
const mobile_phone = tlang('mobile_phone') || 'Mobile Phone';
const cancel_btn = tlang('cancel_btn') || 'Cancel';
const delete_staff_user_modal_delete_staff_user = tlang('delete_staff_user_modal_delete_staff_user') || 'Delete Staff User';
const delete_staff_user_modal_sure_want_delete = tlang('delete_staff_user_modal_sure_want_delete') || 'Are you sure you want to delete';
const delete_staff_user_modal_cancel = tlang('delete_staff_user_modal_cancel') || 'Cancel';
const delete_staff_user_modal_delete = tlang('delete_staff_user_modal_delete') || 'Delete';
const request_handle_modal_approve_request = tlang('request_handle_modal_approve_request') || 'Approve Request';
const request_handle_modal_reject_request = tlang('request_handle_modal_reject_request') || 'Reject Request';
const request_handle_modal_sure_want_approve = tlang('request_handle_modal_sure_want_approve') || 'Are you sure you want to approve the company request';
const request_handle_modal_sure_want_reject = tlang('request_handle_modal_sure_want_reject') || 'Are you sure you want to reject/modification the company request';
const request_handle_modal_decline_msg = tlang('request_handle_modal_decline_msg') || 'Reason For reject/Modification request';
const request_handle_modal_cancel = tlang('request_handle_modal_cancel') || 'Cancel';
const request_handle_modal_approve = tlang('request_handle_modal_approve') || 'Approve';
const request_handle_modal_reject = tlang('request_handle_modal_reject') || 'Reject';
const request_handle_modal_resend = tlang('request_handle_modal_resend') || 'Resend for modifications';
const block_staff_user_modal_block_staff_user = tlang('block_staff_user_modal_block_staff_user') || 'Block Staff User';
const block_staff_user_modal_do_you_want_to = tlang('block_staff_user_modal_do_you_want_to') || 'Do you want to';
const block_staff_user_modal_block = tlang('block_staff_user_modal_block') || 'block';
const block_staff_user_modal_unblock = tlang('block_staff_user_modal_unblock') || 'unblock';
const block_staff_user_modal_this_user = tlang('block_staff_user_modal_this_user') || 'this user';
const block_staff_user_modal_block_ = tlang('block_staff_user_modal_block_') || 'Block';
const block_staff_user_modal_unblock_ = tlang('block_staff_user_modal_unblock_') || 'Unblock';
const block_btn = tlang('block_btn') || 'Block';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
const Signup_yourpastattachment = tlang('Signup_yourpastattachment') || 'Your Past Attachments';
const create_new_user_modal_add_new_company = tlang('create_new_user_modal_add_new_company') || 'Add New Company';
const sign_in_up_form_company_name = tlang('sign_in_up_form_company_name') || 'Name of the Company';
const sign_in_up_form_company_address = tlang('sign_in_up_form_company_address') || 'Address of Company';
const sign_in_up_form_registration_country = tlang('sign_in_up_form_registration_country') || 'Country of Registration';
const sign_in_up_form_name = tlang('sign_in_up_form_name') || 'Name of Account Manager';
const sign_in_up_form_email = tlang('sign_in_up_form_email') || 'E-mail of Account Manager';
const sign_in_up_form_mobile = tlang('sign_in_up_form_mobile') || 'Mobile Phone';
const sign_in_up_form_company_strength = tlang('sign_in_up_form_company_strength') || 'Strength of Company';
const company_name_validate = tlang('company_name_validate') || '* Please enter company name.';
const create_new_user_modal_enter_company_address = tlang('create_new_user_modal_enter_company_address') || '* Please enter company address.';
const create_new_user_modal_enter_registration_country = tlang('create_new_user_modal_enter_registration_country') || '* Please enter registration country.';
const create_new_user_modal_enter_name = tlang('create_new_user_modal_enter_name') || '* Please enter name.';
const create_new_user_modal_enter_proper_email = tlang('create_new_user_modal_enter_proper_email') || '* Please enter proper email.';
const create_new_user_modal_enter_valid_mobile_number = tlang('create_new_user_modal_enter_valid_mobile_number') || '* Please enter Valid Mobile number.';
const create_new_user_modal_enter_company_strength = tlang('create_new_user_modal_enter_company_strength') || '* Please enter company strength.';
const create_new_user_modal_your_company_logo = tlang('create_new_user_modal_your_company_logo') || 'Your Company Logo.*';
const create_new_user_modal_drop_company_logo = tlang('create_new_user_modal_drop_company_logo') || 'Drop Your Company Logo Here.';
const create_new_user_modal_remove = tlang('create_new_user_modal_remove') || 'Remove';
const create_new_user_modal_upload_legal_company_proof = tlang('create_new_user_modal_upload_legal_company_proof') || 'Upload legal company proof';
const create_new_user_modal_company_proof_description = tlang('create_new_user_modal_company_proof_description') || 'For ex. company registration, license certificate, VAT certificate, etc';
const create_new_user_modal_drop_legal_document = tlang('create_new_user_modal_drop_legal_document') || 'Drop Your Legal Documents Here.';
//const create_btn = tlang('create_btn') || 'Create';
const addcmp_enter_profilepic = tlang('addcmp_enter_profilepic') || '* Please enter profile pic.';
const create_new_user_modal_enter_files = tlang('create_new_user_modal_enter_files') || '* Please enter files.';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
//const mobile_phone = tlang('mobile_phone') || 'Mobile Phone';
const company_title = tlang('user_request_detail_admin_company_title') || 'Company Details';
const user_request_accounts = tlang('user_request_accounts') || 'Accounts';
const user_request_detail_admin_invites = tlang('user_request_detail_admin_invites') || 'Company Members';
const user_request_detail_admin_membership = tlang('user_request_detail_admin_membership') || 'Membership';
const user_request_detail_admin_email = tlang('user_request_detail_admin_email') || 'Email';
const user_request_detail_admin_contact = tlang('user_request_detail_admin_contact') || 'Contact';
const user_request_detail_admin_company = tlang('user_request_detail_admin_company') || 'Company';
const user_request_detail_admin_sr_no = tlang('user_request_detail_admin_sr_no') || 'Sr.No';
const user_request_detail_admin_name = tlang('user_request_detail_admin_name') || 'Name';
const user_request_detail_admin_role = tlang('user_request_detail_admin_role') || 'Role';
const user_request_detail_admin_plan = tlang('user_request_detail_admin_plan') || 'Membership Plan';
const user_request_detail_admin_nooflicence = tlang('user_request_detail_admin_nooflicence') || 'No of Licenses';
const user_request_detail_admin_nooftoken = tlang('user_request_detail_admin_nooftoken') || 'No of Tokens';
const user_request_detail_admin_payment_due = tlang('user_request_detail_admin_payment_due') || 'Payment due date';
const user_request_detail_admin_confirm_date = tlang('user_request_detail_admin_confirm_date') || 'Confirmation date';
const user_request_detail_admin_amount_paid = tlang('user_request_detail_admin_amount_paid') || 'Amount Paid';
const user_request_detail_admin_current_plan = tlang('user_request_detail_admin_current_plan') || 'Current Plan';
//const common_btn_okay = tlang('common_btn_okay') || 'Okay';
const user_detail_step_modal_sr_no = tlang('user_detail_step_modal_sr_no') || 'Sr.No';
const user_detail_step_modal_email = tlang('user_detail_step_modal_email') || 'Email';
const user_detail_step_modal_name = tlang('user_detail_step_modal_name') || 'Name';
const user_detail_step_modal_role = tlang('user_detail_step_modal_role') || 'Role';
const user_detail_step_modal_request_title = tlang('user_detail_step_modal_request_title') || 'Payment request detail';
const user_detail_step_modal_details = tlang('user_detail_step_modal_details') || 'Details';
const user_detail_step_modal_invites = tlang('user_detail_step_modal_invites') || 'Company Members';
const user_detail_step_modal_contact = tlang('user_detail_step_modal_contact') || 'Contact';
const user_detail_step_modal_company = tlang('user_detail_step_modal_company') || 'Company';
const user_detail_step_modal_plan = tlang('user_detail_step_modal_plan') || 'Membership Plan';
const user_detail_step_modal_no_licence = tlang('user_detail_step_modal_no_licence') || 'No of Licenses';
const user_detail_step_modal_total_licence = tlang('user_detail_step_modal_total_licence') || 'Total Invoice';
const user_detail_step_modal_no_user_licence = tlang('user_detail_step_modal_no_user_licence') || 'User did not submitted details yet.';
const user_detail_step_modal_confirmact = tlang('user_detail_step_modal_confirmact') || 'Confirm Account & Payment';
//const common_btn_okay = tlang('common_btn_okay') || 'Okay';
const confirm_account_modal_confirm_title = tlang('confirm_account_modal_confirm_title') || 'Confirm Request';
const confirm_account_modal_confirm_user = tlang('confirm_account_modal_confirm_user') || 'Are you sure you want to confirm the user request?';
const approve_btn = tlang('approve_btn') || 'Approve';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
const block_account_modal_block_title = tlang('block_account_modal_block_title') || 'Block Company Account';
const block_account_modal_unblock_title = tlang('block_account_modal_unblock_title') || 'Unblock Company Account';
const block_account_modal_block_msg = tlang('block_account_modal_block_msg') || 'Are you sure you want to Block Company Account?';
const block_account_modal_unblock_msg = tlang('block_account_modal_unblock_msg') || 'Are you sure you want to Unblock Company Account?';
//const block_btn = tlang('block_btn') || 'Block';
const unblock_btn = tlang('unblock_btn') || 'Unblock';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
const update_plan_modal_title = tlang('update_plan_modal_title') || 'Edit Plan';
const update_plan_modal_planname = tlang('update_plan_modal_planname') || 'Plan Name';
const update_plan_modal_pls_add_plan_name = tlang('update_plan_modal_pls_add_plan_name') || 'Please enter plan name.';
const update_plan_modal_price = tlang('update_plan_modal_price') || 'Price';
const update_plan_modal_pls_add_price = tlang('update_plan_modal_pls_add_price') || 'Please enter price.';
const update_plan_modal_pls_add_valid_price = tlang('update_plan_modal_pls_add_valid_price') || 'Please enter valid price.';
const update_plan_modal_store_capacity = tlang('update_plan_modal_store_capacity') || 'Maximum Allocated Storage Capacity (in GB)';
const update_plan_modal_pls_add_uploadsize = tlang('update_plan_modal_pls_add_uploadsize') || 'Please enter uploadsize.';
const update_plan_modal_pls_add_valid_uploadsize = tlang('update_plan_modal_pls_add_valid_uploadsize') || 'Please enter valid uploadsize.';
const update_plan_modal_max_user_create = tlang('update_plan_modal_max_user_create') || 'Maximum User Create';
const update_plan_modal_pls_add_usersize = tlang('update_plan_modal_pls_add_usersize') || 'Please enter usersize.';
const update_plan_modal_pls_add_valid_usersize = tlang('update_plan_modal_pls_add_valid_usersize') || 'Please enter valid usersize.';
const language_savebtn = tlang('language_savebtn') || 'Save';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
const update_licence_modal_title = tlang('update_licence_modal_title') || 'Edit Licence';
const update_licence_modal_licence_name = tlang('update_licence_modal_licence_name') || 'Licence Name';
const update_licence_modal_pls_add_licence_name = tlang('update_licence_modal_pls_add_licence_name') || 'Please enter licence name.';
const update_licence_modal_cost = tlang('update_licence_modal_cost') || 'Cost';
const update_licence_modal_pls_add_cost = tlang('update_licence_modal_pls_add_cost') || 'Please enter cost.';
const update_licence_modal_pls_add_valid_cost = tlang('update_licence_modal_pls_add_valid_cost') || 'Please enter valid cost';
//const language_savebtn = tlang('language_savebtn') || 'Save';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
const project_sr_no = tlang('project_sr_no') || 'Sr.No';
const project_name = tlang('project_name') || 'Name';
const project_licence = tlang('project_licence') || 'Licence';
const project_role = tlang('project_role') || 'Role';
const project_company = tlang('project_company') || 'Company';
const project_status = tlang('project_status') || 'Status';
const LICENCE_STATUS = tlang('project_status') || 'Status';
const project_project_details = tlang('project_project_details') || 'Project Details';
const project_project_name = tlang('project_project_name') || 'Project Name';
const project_purpose = tlang('project_purpose') || 'Project Purpose';
const project_latitude = tlang('project_latitude') || '';
const project_start_date = tlang('project_start_date') || 'Start Date';
const project_project_address = tlang('project_project_address') || 'Address';
const project_longtitude = tlang('project_longtitude') || 'Longitude';
const project_end_date = tlang('project_end_date') || 'End Date';
//const common_btn_okay = tlang('common_btn_okay') || 'Okay';
const label_attachments = tlang('label_attachments') || 'Attachments';
const project_attachments = tlang('project_attachments') || 'Attachments';
const material_model_add_material_title = tlang('material_model_add_material_title') || 'Add New Material Master';
const material_model_edit_material_title = tlang('material_model_edit_material_title') || 'Edit Material Master';
const material_model_type = tlang('material_model_type') || 'Type';
const material_model_pls_add_material_type = tlang('material_model_pls_add_material_type') || 'Please Add Material Type';
const material_model_color = tlang('material_model_color') || 'Color';
const material_model_color_validate = tlang('material_model_color_validate') || 'Please Add Material Color Code';
const material_model_key = tlang('material_model_key') || 'Key';
const material_model_tag = tlang('material_model_tag') || 'Tag';
//const language_savebtn = tlang('language_savebtn') || 'Save';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
const delete_material_master_title = tlang('delete_material_master_title') || 'Delete Material Master';
const delete_material_master_areyoumsg = tlang('delete_material_master_areyoumsg') || 'Are you sure you want to delete?';
//const delete_staff_user_modal_delete = tlang('delete_staff_user_modal_delete') || 'Delete';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
const material_model_add_new_color = tlang('material_model_add_new_color') || 'Add New Color Master';
const material_model_edit_color_txt = tlang('material_model_edit_color_txt') || 'Edit Color Master';
const material_model_add_new_nature = tlang('material_model_add_new_nature') || 'Add New Nature Master';
const material_model_edit_new_nature = tlang('material_model_edit_new_nature') || 'Edit Nature Master';
const material_model_add_new_geology = tlang('material_model_add_new_geology') || 'Add New Geology Master';
const material_model_edit_geology_text = tlang('material_model_edit_geology_text') || 'Edit Geology Master';
const material_model_add_new_organoleptic = tlang('material_model_add_new_organoleptic') || 'Add New Organoleptic Master';
const material_model_edit_organoleptic_text = tlang('material_model_edit_organoleptic_text') || 'Edit Organoleptic Master';
const material_masters_name = tlang('material_masters_name') || 'Name';
const material_masters_default_name = tlang('material_masters_default_name') || 'Default Name';
const material_masters_pattern_svg_code = tlang('material_masters_pattern_svg_code') || 'Pattern SVG Code';
const material_masters_pattern_width = tlang('material_masters_pattern_width') || 'Pattern Width';
const material_masters_pattern_height = tlang('material_masters_pattern_height') || 'Pattern Height';
const material_masters_pattern_svg_code_validate = tlang('material_masters_pattern_svg_code_validate') || 'Please add pattern SVG code';
const material_masters_pattern_width_validate = tlang('material_masters_pattern_width_validate') || 'Please add pattern width';
const material_masters_pattern_height_validate = tlang('material_masters_pattern_height_validate') || 'Please add pattern height';
const material_masters_name_validate = tlang('material_masters_name_validate') || 'Please Add Name';
const material_masters_default_name_validate = tlang('material_masters_default_name_validate') || 'Please Add Default Name';
const material_masters_description_validate = tlang('material_masters_description_validate') || 'Please Add Description';
const material_masters_color_code = tlang('material_masters_color_code') || 'Color Code';
const material_masters_color_code_validate = tlang('material_masters_color_code_validate') || 'Please Add Color Code';
const material_masters_value = tlang('material_masters_value') || 'Value';
const material_masters_value_validate = tlang('material_masters_value_validate') || 'Please Add Value';
//const language_savebtn = tlang('language_savebtn') || 'Save';
//const material_model_key = tlang('material_model_key') || 'Key';
//const material_model_tag = tlang('material_model_tag') || 'Tag';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
const material_model_add_new_category = tlang('material_model_add_new_category') || 'Add New Category Master';
const material_model_edit_new_category = tlang('material_model_edit_new_category') || 'Edit Category Master';
const material_model_add_new_lithology = tlang('material_model_add_new_lithology') || 'Add New Lithology Master';
const material_model_edit_new_lithology = tlang('material_model_edit_new_lithology') || 'Edit Lithology Master';
const material_model_add_new_moisture = tlang('material_model_add_new_moisture') || 'Add New Moisture Master';
const material_model_edit_new_moisture = tlang('material_model_edit_new_moisture') || 'Edit Moisture Master';
const material_model_add_new_anomaly = tlang('material_model_add_new_anomaly') || 'Add New Anomaly Master';
const material_model_edit_new_anomaly = tlang('material_model_edit_new_anomaly') || 'Edit Anomaly Master';
const material_model_add_new_reason = tlang('material_model_add_new_reason') || 'Add New Reason Master';
const material_model_edit_new_reason = tlang('material_model_edit_new_reason') || 'Edit Reason Master';
const material_model_add_new_observation = tlang('material_model_add_new_observation') || 'Add New Observation Master';
const material_model_edit_new_observation = tlang('material_model_edit_new_observation') || 'Edit Observation Master';
const material_masters_pattern_validate = tlang('material_masters_pattern_validate') || 'Please Add Pattern';
const material_masters_soil_family = tlang('materail_master_soil_family') || 'Soil Family';
const material_model_add_new_soil_family = tlang('material_model_add_new_soil_family') || 'Add New Soil Family Master';
const material_model_edit_soil_family = tlang('material_model_edit_soil_family') || 'Edit Soil Family Master';
const material_model_add_new_pattern = tlang('material_model_add_new_pattern') || 'Add New Pattern Master';
const material_model_edit_pattern = tlang('material_model_edit_pattern') || 'Edit Pattern Master';
const description = tlang('description') || 'Description';
const label_language = tlang('label_language') || 'Language';
const delete_color_masters = tlang('delete_color_masters') || 'Delete Color Master';
const delete_nature_masters = tlang('delete_nature_masters') || 'Delete Nature Master';
const delete_geology_masters = tlang('delete_geology_masters') || 'Delete Geology Master';
const delete_organoleptic_masters = tlang('delete_organoleptic_masters') || 'Delete Organoleptic Master';
//const delete_material_master_areyoumsg = tlang('delete_material_master_areyoumsg') || 'Are you sure you want to delete?';
//const delete_staff_user_modal_delete = tlang('delete_staff_user_modal_delete') || 'Delete';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
const delete_soil_family_masters = tlang('delete_soil_family_masters') || 'Delete Soil Family Master';
const delete_category_masters = tlang('delete_category_masters') || 'Delete Category Master';
const delete_lithology_masters = tlang('delete_lithology_masters') || 'Delete Lithology Master';
const delete_moisture_masters = tlang('delete_moisture_masters') || 'Delete Moisture Master';
const delete_anomaly_masters = tlang('delete_anomaly_masters') || 'Delete Anomaly Master';
const delete_reason_masters = tlang('delete_reason_masters') || 'Delete Reason Master';
const delete_observation_masters = tlang('delete_observation_masters') || 'Delete Observation Master';
const delete_pattern_masters = tlang('delete_pattern_masters') || 'Delete Pattern Master';
const material_model_add_new_probing_method = tlang('material_model_add_new_probing_method') || 'Add New Probing Method';
const material_model_edit_probing_method = tlang('material_model_edit_probing_method') || 'Edit Probing Method';
//const material_masters_name = tlang('material_masters_name') || 'Name';
//const material_masters_name_validate = tlang('material_masters_name_validate') || 'Please Add Name';
//const language_savebtn = tlang('language_savebtn') || 'Save';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
const delete_probing_method = tlang('delete_probing_method') || 'Delete Probing Method';
//const delete_material_master_areyoumsg = tlang('delete_material_master_areyoumsg') || 'Are you sure you want to delete';
//const delete_staff_user_modal_delete = tlang('delete_staff_user_modal_delete') || 'Delete';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
const add_new_test_family_type = tlang('add_new_test_family_type') || 'Add New Test Family Type';
const edit_test_family_type = tlang('edit_test_family_type') || 'Edit Test Family Type';
const test_masters_name = tlang('test_masters_name') || 'Name';
const test_masters_name_validate = tlang('test_masters_name_validate') || 'Please Add Name';
const test_masters_disposal_name_already_exist = tlang('test_masters_disposal_name_already_exist') || 'Disposal name already exist';
const add_new_btn = tlang('add_new_btn') || 'Add New';
//const language_savebtn = tlang('language_savebtn') || 'Save';
const tooltip_remove = tlang('tooltip_remove') || 'Remove';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
const language_setting_language = tlang('language_setting_language') || 'Language';
//const description = tlang('description') || 'Description';
const add_description = tlang('add_description') || 'Add Description';
const short_description = tlang('short_description') || 'Short Description';
const add_short_description = tlang('add_short_description') || 'Add Short Description';
const long_description = tlang('long_description') || 'Long Description';
const add_long_description = tlang('add_long_description') || 'Add Long Description';
const add_type = tlang('add_type') || 'Add Type';
const delete_test_family_type = tlang('delete_test_family_type') || 'Delete Test Family Type';
const are_you_sure_you_want_to_delete = tlang('are_you_sure_you_want_to_delete') || 'Are you sure you want to delete';
//const delete_staff_user_modal_cancel = tlang('delete_staff_user_modal_cancel') || 'Cancel';
//const delete_staff_user_modal_delete = tlang('delete_staff_user_modal_delete') || 'Delete';
const add_new_desposal = tlang('add_new_desposal') || 'Add New Disposal';
const edit_desposal = tlang('edit_desposal') || 'Edit Disposal';
//const test_masters_name = tlang('test_masters_name') || 'Name';
//const test_masters_name_validate = tlang('test_masters_name_validate') || 'Please Add Name';
//const language_savebtn = tlang('language_savebtn') || 'Save';
const user_request_company_address = tlang('user_request_company_address') || 'Address';
const Address_label = tlang('Address_label') || 'Address';
const pls_add_address = tlang('pls_add_address') || 'Please Add Address';
const user_request_country = tlang('user_request_country') || 'Country';
const pls_enter_country = tlang('pls_enter_country') || 'Please Enter Country';
const state = tlang('state') || 'State';
const state_validate = tlang('state_validate') || 'Please Enter State';
const Standard_label = tlang('Standard_label') || 'Standard';
const color_label = tlang('color_label') || 'Color';
const color_validate = tlang('color_validate') || 'Please Enter Color';
const color_hexvalidate = tlang('color_hexvalidate') || 'Please enter color in hex format';
const desposal_select_many_test = tlang('desposal_select_many_test') || 'Select as many tests';
const please_enter_test = tlang('please_enter_test') || 'Please Enter TestFamily';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
const user_request_srno = tlang('user_request_srno') || 'Sr no';
const desposal_testfamily_name = tlang('desposal_testfamily_name') || 'Test family';
//const description = tlang('description') || 'Description';
//const short_description = tlang('short_description') || 'Short Description';
//const long_description = tlang('long_description') || 'Long Description';
const desposal_tags = tlang('desposal_tags') || 'Tags';
const desposal_keys = tlang('desposal_keys') || 'Test name';
const delete_desposal = tlang('delete_desposal') || 'Delete Disposal';
//const are_you_sure_you_want_to_delete = tlang('are_you_sure_you_want_to_delete') || 'Are you sure you want to delete';
//const delete_staff_user_modal_cancel = tlang('delete_staff_user_modal_cancel') || 'Cancel';
//const delete_staff_user_modal_delete = tlang('delete_staff_user_modal_delete') || 'Delete';
const thresholdModal_title = tlang('thresholdModal_title') || 'Add Threshold';
const save_btn = tlang('save_btn') || 'Save';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
//const thresholdModal_title = tlang('thresholdModal_title') || 'Add Threshold';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
//const save_btn = tlang('save_btn') || 'Save';
const thresholdFormulaModal_title = tlang('thresholdFormulaModal_title') || 'Add Threshold Value';
const threshold_Less_than_or_greater_than = tlang('threshold_Less_than_or_greater_than') || 'Less than or greater than';
const threshold_Percentage = tlang('threshold_Percentage') || 'Percentage';
const threshold_Sum_of_all_test = tlang('threshold_Sum_of_all_test') || 'Sum of all test';
const threshold_Formula = tlang('threshold_Formula') || 'Formula';
const threshold_Less_than = tlang('threshold_Less_than') || 'Less than';
const threshold_Greater_than = tlang('threshold_Greater_than') || 'Greater than';
const threshold_Test = tlang('threshold_Test') || 'Test';
const threshold_Value = tlang('threshold_Value') || 'Value';
const threshold_Sum = tlang('threshold_Sum') || 'Sum';
const threshold_Pls_select_formula = tlang('threshold_Pls_select_formula') || 'Please select formula';
const threshold_Select_Test = tlang('threshold_Select_Test') || 'Select Test';
const threshold_pls_Select_Test = tlang('threshold_pls_Select_Test') || 'Please select Test';
const threshold_Pls_select_percentage = tlang('threshold_Pls_select_percentage') || 'Please enter percentage';
const threshold_Add_Test_Rule = tlang('threshold_Add_Test_Rule') || 'Set Test Rule';
const threshold_Select_as_many_tests = tlang('threshold_Select_as_many_tests') || 'Select as many tests';
//const save_btn = tlang('save_btn') || 'Save';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
//const thresholdFormulaModal_title = tlang('thresholdFormulaModal_title') || 'Add Threshold Value';
//const threshold_Less_than_or_greater_than = tlang('threshold_Less_than_or_greater_than') || 'Less than or greater than';
//const threshold_Percentage = tlang('threshold_Percentage') || 'Percentage';
//const threshold_Sum_of_all_test = tlang('threshold_Sum_of_all_test') || 'Sum of all test';
//const threshold_Formula = tlang('threshold_Formula') || 'Formula';
//const threshold_Less_than = tlang('threshold_Less_than') || 'Less than';
//const threshold_Greater_than = tlang('threshold_Greater_than') || 'Greater than';
//const threshold_Test = tlang('threshold_Test') || 'Test';
//const threshold_Value = tlang('threshold_Value') || 'Value';
//const threshold_Sum = tlang('threshold_Sum') || 'Sum';
//const common_btn_okay = tlang('common_btn_okay') || 'Okay';
const testAddEditModal_edit_title = tlang('testAddEditModal_edit_title') || 'Edit Test Family';
const testAddEditModal_add_title = tlang('testAddEditModal_add_title') || 'Add New Test Family';
const testAddEditModal_name = tlang('testAddEditModal_name') || 'Name';
const testAddEditModal_pls_add_name = tlang('testAddEditModal_pls_add_name') || 'Please Add Name';
const testAddEditModal_Test_Family_Type = tlang('testAddEditModal_Test_Family_Type') || 'Test Family Type';
const testAddEditModal_pls_add_type = tlang('testAddEditModal_pls_add_type') || 'Please Add Type';
//const save_btn = tlang('save_btn') || 'Save';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
let titleName = tlang('project_token_price') || 'Project Token Price';
const token_price = tlang('token_price') || 'Token Price';
const token_price_add_valid_price = tlang('token_price_add_valid_price') || 'Please Add Valid Price';
//const language_savebtn = tlang('language_savebtn') || 'Save';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
const testDeleteModal_title = tlang('testDeleteModal_title') || 'Delete Test';
const testDeleteModal_are_you_msg = tlang('testDeleteModal_are_you_msg') || 'Are you sure you want to delete?';
//const delete_staff_user_modal_cancel = tlang('delete_staff_user_modal_cancel') || 'Cancel';
//const delete_staff_user_modal_delete = tlang('delete_staff_user_modal_delete') || 'Delete';
const accept_licence_request_modal_title = tlang('accept_licence_request_modal_title') || 'Request Details';
const accept_licence_request_modal_message = tlang('accept_licence_request_modal_message') || 'Are you sure want to accept this Licence Request';
//const delete_staff_user_modal_cancel = tlang('delete_staff_user_modal_cancel') || 'Cancel';
const accept_btn = tlang('accept_btn') || 'Accept';
const total_amount = tlang('total_amount') || 'Total Amount';
const reject_licence_request_modal_title = tlang('reject_licence_request_modal_title') || 'Request Details';
const reject_licence_request_modal_message = tlang('reject_licence_request_modal_message') || 'Are you sure want to delete this Licence Request';
//const delete_staff_user_modal_delete = tlang('delete_staff_user_modal_delete') || 'Delete';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
//const accept_licence_request_modal_title = tlang('accept_licence_request_modal_title') || 'Request Details';
const accept_licence_request_modal_plan_message = tlang('accept_licence_request_modal_plan_message') || 'Are you sure want to accept this Plan Request';
//const delete_staff_user_modal_cancel = tlang('delete_staff_user_modal_cancel') || 'Cancel';
//const accept_btn = tlang('accept_btn') || 'Accept';
const renewextendmodel_price = tlang('renewextendmodel_price') || 'Price';
const renewextendmodel_price_validate = tlang('renewextendmodel_price_validate') || 'Please Add Price';
const renewextendmodel_comment = tlang('renewextendmodel_comment') || 'Comment';
//const language_savebtn = tlang('language_savebtn') || 'Save';
const plan_renew_request = tlang('plan_renew_request') || 'Plan Renew Request';
const plan_extend_request = tlang('plan_extend_request') || 'Plan Extend Request';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
const licence_renew_request = tlang('licence_renew_request') || 'Licence Renew Request';
const licence_extend_request = tlang('licence_extend_request') || 'Licence Extend Request';
//const renewextendmodel_price = tlang('renewextendmodel_price') || 'Price';
//const renewextendmodel_price_validate = tlang('renewextendmodel_price_validate') || 'Please Add Price';
//const renewextendmodel_comment = tlang('renewextendmodel_comment') || 'Comment';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
//const accept_btn = tlang('accept_btn') || 'Accept';
//const reject_licence_request_modal_title = tlang('reject_licence_request_modal_title') || 'Request Details';
const reject_licence_request_modal_plan_message = tlang('reject_licence_request_modal_plan_message') || 'Are you sure want to delete this Plan Request';
//const delete_staff_user_modal_delete = tlang('delete_staff_user_modal_delete') || 'Delete';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
//const reject_licence_request_modal_title = tlang('reject_licence_request_modal_title') || 'Request Details';
const common_btn_import = tlang('common_btn_import') || 'Import';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
const accept_project_token_request = tlang('accept_project_token_request') || 'Accept Project Token Request';
const project_token_areyoumsg = tlang('project_token_areyoumsg') || 'Are you sure you want to accept this request';
//const language_savebtn = tlang('language_savebtn') || 'Save';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
const reject_project_token_request = tlang('reject_project_token_request') || 'Reject Project Token Request';
const reject_project_token_request_areyoumsg = tlang('reject_project_token_request_areyoumsg') || 'Are you sure you want to reject this request';
//const delete_staff_user_modal_delete = tlang('delete_staff_user_modal_delete') || 'Delete';
//const cancel_btn = tlang('cancel_btn') || 'Cancel';
const LANGUAGE_ADD_NEW_LABEL_KEY = tlang('language_add_new_label') || 'Add New Label';
const LANGUAGE_ADD_KEYTEXT_KEY = tlang('language_add_key_text') || 'Add Key';
const LANGUAGE_ADD_KEYVAL_KEY = tlang('language_add_key_value') || 'Add Value';
const DELSTAFFUSERMODAL_KEY = tlang('delete_staff_user_modal_cancel') || 'Cancel';
const COMMONBTNSAVE_KEY = tlang('common_btn_save') || 'Save';
const LANGADDKEY_VALIDATE = tlang('LANGADDKEY_VALIDATE') || 'Please Add Key';
const LANGADDVALUE_VALIDATE = tlang('LANGADDVALUE_VALIDATE') || 'Please Add Value';
const language_setting_btn = tlang('language_setting_btn') || 'Language Setting';
const language_setting_srno = tlang('language_setting_srno') || 'Sr no';
const language_setting_code = tlang('language_setting_code') || 'Code';
//const language_setting_language = tlang('language_setting_language') || 'Language';
const language_setting_isVisible = tlang('language_setting_isVisible') || 'Is Visible';
const sort_text = tlang('sort_text') || 'Sort';
const rows_per_page_text = tlang('rows_per_page_text') || 'Rows per page:';
const no_match_record_found = tlang('no_match_record_found') || 'Sorry, no matching records found';
//const common_btn_okay = tlang('common_btn_okay') || 'Okay';   
const language_add_new_lang_label = tlang('language_add_new_lang_label') || 'Add New Language';
const pls_select_lang_valid = tlang('pls_select_lang_valid') || 'Please select language';
//const DELSTAFFUSERMODAL_KEY = tlang('delete_staff_user_modal_cancel') || 'Cancel';
//const COMMONBTNSAVE_KEY = tlang('common_btn_save') || 'Save';
const select_Language_txt = tlang('select_Language_txt') || 'Select Language';
const deleteLanguageLabelModal_title = tlang('deleteLanguageLabelModal_title') || 'Delete Language Label';
const deleteLanguageLabelModal_msg = tlang('deleteLanguageLabelModal_msg') || 'Are you sure you want to delete this label?';
//const delete_staff_user_modal_cancel = tlang('delete_staff_user_modal_cancel') || 'Cancel';
const delete_btn = tlang('delete_btn') || 'Delete';
const excelupload_continue_modal_title = tlang('excelupload_continue_modal_title') || 'Do you want to continue?';
const btn_no = tlang('btn_no') || 'No';
const btn_yes = tlang('btn_yes') || 'Yes';
const language_tab_continue_message = tlang('language_tab_continue_message') || 'The change you have made will be lost.';
const Invited_users = tlang('Invited_users') || 'Invited users';
const set_standard_order = tlang('set_standard_order') || 'Set Standard Disposal Order';
const Lang_Loadingtxt = tlang('Lang_Loadingtxt') || 'Loading...';
const search_text = tlang('search_text') || 'Search';
const master_licence_access_page_header_title = tlang('master_licence_access_page_header_title') || 'Allow/Restrict Access Pages';
const common_btn_close = tlang('common_btn_close') || 'Close';
const client_profile_unusedlicence = tlang('client_profile_unusedlicence') || 'Un-Used Licence';
const client_profile_usedlicence = tlang('client_profile_usedlicence') || 'Used / Expired Licence';
const client_profile_unusedtoken = tlang('client_profile_unusedtoken') || 'Un-Used Token';
const client_profile_usedtoken = tlang('client_profile_usedtoken') || 'Used Token';
const client_month = tlang('client_month') || 'Month';
const dashboard_log_details = tlang('dashboard_log_details') || 'Details';
const MASTERS_TOKENNAME_KEY = tlang('masters_token_name') || 'Token Name';
const admin_edit_token = tlang('admin_edit_token') || 'Edit Token';
const update_token_modal_pls_add_token_name = tlang('update_token_modal_pls_add_token_name') || 'Please enter token name';
const accept_token_request_modal_message = tlang('accept_token_request_modal_message') || 'Are you sure want to accept this Token Request';
const reject_token_request_modal_message = tlang('reject_token_request_modal_message') || 'Are you sure want to delete this Token Request';
const final_value = tlang('final_value') || 'Final Value';
const tooltip_accept = tlang('tooltip_accept') || 'Accept';
const tooltip_reject = tlang('request_handle_modal_reject') || 'Reject';

const ImageContainer = styled.div`
	button {
		border: none;
		background: none;
	}
	img {
		min-height: 70px;
		width: 120px;
		height: 70px;
		border: 'solid 1px #dadada';
		z-index: 1;
		margin-inline-end: 2px;
	}
`;

//// success warning danger primary  -- diffrent colours
export default {
  userRequestDetail: function (modalData) {
    // -- user Request Details --
    const { t } = modalData.props;
    const items = [];
    let cmpLogo = '';

    if (modalData.state.requestDetail && modalData.state.openRequestDetail) {
      let atc = JSON.parse(modalData.state.requestDetail.attachments);
      for (var i = 0; i < atc.length; i++) {
        let obj = atc[i];
        let key = Object.keys(atc[i])[0];
        const ext = Object.values(atc[i])[0].split('.').pop();
        const fileName = key.split('-')[0] + '.' + ext;
        if (i !== atc.length - 1) {
          items.push(
            <li>
              <a href={obj[key]} target="_blank" download>
                {fileName}
              </a>
            </li>
          );
        } else {
          cmpLogo = obj[key];
        }
      }
    }
    return (
      <Modal
        isOpen={modalData.state.openRequestDetail}
        toggle={() => modalData.toggleRequestDetailModal()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={modalData.toggleRequestDetailModal}
          />
          <h4 className="bold-text  modal__title">
            {user_request_modal_request_detail}
          </h4>
        </div>
        <div className="modal__body">
          {modalData.state.requestDetail && (
            <span>
              <Table>
                <TableBody>
                  <TableRow key={1}>
                    <TableCell align="left" className="font-weight-500">
                      {user_request_modal_company_logo}
                    </TableCell>
                    <TableCell align="left">
                      <ImageContainer>
                        <button onClick={() => modalData.setLightBoxUrl(cmpLogo)}>
                          <img src={cmpLogo} alt="" />
                        </button>
                        <Lightbox
                          images={[{ src: modalData.state.lightBoxUrl }]}
                          isOpen={modalData.state.lightBoxUrl !== ''}
                          showImageCount={false}
                          backdropClosesModal={true}
                          onClose={() => modalData.setLightBoxUrl('')}
                          width={1100}
                        />
                      </ImageContainer>
                    </TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell align="left" className="font-weight-500">
                      {user_request_modal_name_of_company}
                    </TableCell>
                    <TableCell align="left">
                      {modalData.state.requestDetail.display_name}
                    </TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell align="left" className="font-weight-500">
                      {user_request_modal_address}
                    </TableCell>
                    <TableCell align="left">
                      {modalData.state.requestDetail.company_address}
                    </TableCell>
                  </TableRow>

                  <TableRow key={4}>
                    <TableCell align="left" className="font-weight-500">
                      {user_request_modal_country}
                    </TableCell>
                    <TableCell align="left">
                      {modalData.state.requestDetail.registration_country}
                    </TableCell>
                  </TableRow>

                  <TableRow key={5}>
                    <TableCell align="left" className="font-weight-500">
                      {user_request_modal_account_manager}
                    </TableCell>
                    <TableCell align="left">
                      {modalData.state.requestDetail.name}
                    </TableCell>
                  </TableRow>
                  <TableRow key={6}>
                    <TableCell align="left" className="font-weight-500">
                      {user_request_modal_email}
                    </TableCell>
                    <TableCell align="left">
                      {modalData.state.requestDetail.email}
                    </TableCell>
                  </TableRow>
                  <TableRow key={7}>
                    <TableCell align="left" className="font-weight-500">
                      {user_request_modal_phone}
                    </TableCell>
                    <TableCell align="left">
                      {modalData.state.requestDetail.contact_number}
                    </TableCell>
                  </TableRow>
                  <TableRow key={8}>
                    <TableCell align="left" className="font-weight-500">
                      {"Company Strength"}
                    </TableCell>
                    <TableCell align="left">
                      {modalData.state.requestDetail.company_strength}
                    </TableCell>
                  </TableRow>
                  <TableRow key={9}>
                    <TableCell align="left" className="font-weight-500">
                      {user_request_modal_request_time}
                    </TableCell>
                    <TableCell align="left">
                      {moment
                        .unix(modalData.state.requestDetail.created_at / 1000)
                        .format("DD/MM/YYYY HH:mm")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{user_request_modal_attechments}</TableCell>
                    <TableCell align="left">
                      <ul> {items}</ul>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </span>
          )}
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            color={"success"}
            outline={true}
            onClick={() => modalData.toggleRequestDetailModal()}
          >
            {common_btn_okay}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.toggleReqestHandle(modalData.state.requestDetail, 'approve')}
          >
            {tooltip_accept}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.toggleReqestHandle(modalData.state.requestDetail, 'reject')}
          >
            {tooltip_reject}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  viewStaffDetails: function (modalData) {
    // -- view Staff Details --
    const { t } = modalData.props;

    return (
      <Modal
        isOpen={modalData.state.viewModal}
        toggle={() => modalData.toggleView()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleView()}
          />
          <h4 className="bold-text  modal__title">
            {view_staff_details_modal_staff_user_detail}
          </h4>
        </div>
        <div className="modal__body">
          {modalData.state.viewData && (
            <span>
              <Table>
                <TableBody>
                  <TableRow key={1}>
                    <TableCell align="left" className="font-weight-500">
                      {view_staff_details_modal_first_name}
                    </TableCell>
                    <TableCell align="left">
                      {modalData.state.viewData.firstname}
                    </TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell align="left" className="font-weight-500">
                      {view_staff_details_modal_last_name}
                    </TableCell>
                    <TableCell align="left">
                      {modalData.state.viewData.lastname}
                    </TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell align="left" className="font-weight-500">
                      {view_staff_details_modal_email}
                    </TableCell>
                    <TableCell align="left">
                      {modalData.state.viewData.email}
                    </TableCell>
                  </TableRow>
                  <TableRow key={4}>
                    <TableCell align="left" className="font-weight-500">
                      {view_staff_details_modal_phone}
                    </TableCell>
                    <TableCell align="left">
                      {modalData.state.viewData.phone_number}
                    </TableCell>
                  </TableRow>
                  <TableRow key={5}>
                    <TableCell align="left" className="font-weight-500">
                      {view_staff_details_modal_access_module}
                    </TableCell>
                    <TableCell align="left">
                      {Object.keys(modalData.state.accessList).map((key, i) => {
                        if (modalData.state.viewData.accesspages[key] != undefined && modalData.state.viewData.accesspages[key].status) {
                          return (
                            <span key={i}>
                              • {modalData.state.accessList[key].page} <br />
                            </span>
                          );
                        }
                      })}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </span>
          )}
        </div>

        <ButtonToolbar className="modal__footer">
          <Button color={"success"} onClick={() => modalData.toggleView()}>
            {common_btn_okay}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  createUpdateStaff: function (modalData) {
    // -- create-Update Staff --
    const { t } = modalData.props;

    let header = modalData.state.isUpdate
      ? create_update_staff_modal_update_staff_user
      : create_update_staff_modal_create_new_staff_user;
    let loginuser = modalData.state.staffModalData.email === modalData.props.authReducer.userData.email ? true : false

    return (
      <Modal
        isOpen={modalData.state.openModal}
        toggle={() => modalData.toggleCreateUpdateModal()}
        className={`modal-dialog--success modal-dialog--header create-staff-popup`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleCreateUpdateModal()}
          />
          <h4 className="bold-text  modal__title">{header}</h4>
        </div>

        <div className="modal__body">
          <Row>
            <Col md={6} lg={6} className="theme-light">
              <Col md={12} lg={12} className="theme-light">
                <TextField
                  fullWidth
                  required
                  error={modalData.state.error.includes("firstname")}
                  className="material-form__field"
                  label={create_update_staff_modal_firstname}
                  value={modalData.state.staffModalData.firstname}
                  onChange={modalData.handleChange("firstname")}
                  margin="normal"
                />
                {modalData.state.error.includes("firstname") && (
                  <span className="error-message">
                    {create_update_staff_modal_pls_enter_fname}
                  </span>
                )}
              </Col>
              <Col md={12} lg={12} className="theme-light">
                <TextField
                  fullWidth
                  required
                  error={modalData.state.error.includes("lastname")}
                  className="material-form__field"
                  label={create_update_staff_modal_lastname}
                  value={modalData.state.staffModalData.lastname}
                  onChange={modalData.handleChange("lastname")}
                  margin="normal"
                />
                {modalData.state.error.includes("lastname") && (
                  <span className="error-message">
                    {create_update_staff_modal_pls_enter_lname}
                  </span>
                )}
              </Col>
              <Col md={12} lg={12} className="theme-light">
                <TextField
                  margin="normal"
                  fullWidth
                  required
                  disabled={modalData.state.isUpdate}
                  error={modalData.state.error.includes("email")}
                  className="material-form__field"
                  label={create_update_staff_modal_email}
                  value={modalData.state.staffModalData.email}
                  onChange={modalData.handleChange("email")}
                />
                {modalData.state.error.includes("email") && (
                  <span className="error-message">
                    {create_update_staff_modal_pls_enter_email}
                  </span>
                )}
              </Col>
              <Col md={12} lg={12} className="theme-light">
                <div className="phone-input-staff">
                  <PhoneInput
                    country="FR"
                    required
                    placeholder={mobile_phone}
                    error={modalData.state.error.includes("phone_number")}
                    value={modalData.state.staffModalData.phone_number}
                    onChange={(phoneNo) => modalData.handlePhoneChange(phoneNo)}
                    className={`phoneInput ${modalData.state.phoneError ? "text-danger" : ""
                      }`}
                  />
                  {modalData.state.error.includes("phone_number") && (
                    <span className="error-message">
                      {create_update_staff_modal_pls_enter_mobile}
                    </span>
                  )}
                </div>
              </Col>
            </Col>
            <Col md={6} lg={6} className="theme-light">
              <h4>{create_update_staff_modal_access_page}</h4>
              <div className={"access-staff-div"}>
                {modalData.state.staffModalData.accesspages &&
                  Object.keys(modalData.state.accessList).map(function (key, i) {
                    let row = modalData.state.accessList[key];
                    return (
                      <>

                        <FormControlLabel
                          key={i}
                          fullWidth
                          className="switch-staff"
                          control={
                            <RedSwitch
                              disabled={loginuser}
                              checked={
                                modalData.state.staffModalData.accesspages[key] == undefined ? false :
                                  modalData.state.staffModalData.accesspages[key]
                                    .status
                              }
                              onClick={(event) =>
                                modalData.handleAccessPage(event, key)
                              }
                              value={key}
                            // color="secondary"
                            />
                          }
                          label={row.page}
                        />
                        <div>
                          {modalData.state.staffModalData.accesspages[key] != undefined && modalData.state.staffModalData.accesspages[key]
                            .status &&
                            modalData.state.accessList[key].sub.map((row, i) => {
                              return (
                                <FormControlLabel
                                  key={i}
                                  className="switch-staff-sub"
                                  control={
                                    <Checkbox
                                      disabled={loginuser}
                                      checked={
                                        modalData.state.staffModalData
                                          .accesspages[key].sub[row.key]
                                      }
                                      onClick={(event) =>
                                        modalData.handleAccessSubPage(
                                          event,
                                          key
                                        )
                                      }
                                      value={row.key}
                                      style={{ color: '#c00' }}
                                    />
                                  }
                                  label={row.page}
                                />
                              );
                            })}
                        </div>
                      </>
                    );
                  })}
              </div>
            </Col>
          </Row>
        </div>

        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleCreateUpdateModal()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() =>
              modalData.state.isUpdate
                ? modalData.updateUser()
                : modalData.createUser()
            }
            disabled={modalData.state.loading}
          >
            {modalData.state.isUpdate
              ? create_update_staff_modal_update
              : create_btn}
            {modalData.state.loading ? (
              <Spinner size="sm" className="margin__left_10 btn-spinner" />
            ) : null}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  deleteStaffUser: function (modalData) {
    // -- delete Staff User -- 
    const { t } = modalData.props;

    let staffdeleteuserName = (typeof modalData.state.deleteUser != 'undefined' && modalData.state.deleteUser != null) ? modalData.state.deleteUser.firstname + " " + modalData.state.deleteUser.lastname : "";

    return (
      <Modal
        isOpen={modalData.state.deleteModal}
        toggle={() => modalData.toggleDeleteUser()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleDeleteUser()}
          />
          <h4 className="bold-text  modal__title">
            {delete_staff_user_modal_delete_staff_user}
          </h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col md={12} lg={12} className="theme-light">
              {delete_staff_user_modal_sure_want_delete + " ( " + staffdeleteuserName + ") ?"}
            </Col>
          </Row>
        </div>

        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleDeleteUser()}
          >
            {delete_staff_user_modal_cancel}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.deleteUser()}
            disabled={modalData.state.loading}
          >
            {delete_staff_user_modal_delete}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  requestHandle: function (modalData) {
    // -- request Handle --

    const { t } = modalData.props;
    let reqUserName = modalData.state.requestData.display_name;
    let reqUserRejectName = modalData.state.requestData.display_name;
    return (
      <Modal
        isOpen={modalData.state.requestHandle}
        toggle={() => modalData.toggleReqestHandle()}
        className={
          modalData.state.requestStatus == "approve"
            ? "modal-dialog--success modal-dialog--header"
            : "modal-dialog--success modal-dialog--header"
        }
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleReqestHandle()}
          />
          <h4 className="bold-text  modal__title">
            {modalData.state.requestStatus == "approve"
              ? request_handle_modal_approve_request
              : request_handle_modal_reject_request}
          </h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col md={12} lg={12} className="theme-light">
              {modalData.state.requestStatus == "approve"
                ? request_handle_modal_sure_want_approve + " ( " + reqUserName + " ) ?"
                : request_handle_modal_sure_want_reject + " ( " + reqUserRejectName + " ) ?"}
            </Col>
            {modalData.state.requestStatus != "approve" && (
              <Col md={12} lg={12} className="theme-light">
                <TextField
                  fullWidth
                  id="filled-multiline-static"
                  multiline
                  rows="3"
                  margin="normal"
                  variant="filled"
                  label={request_handle_modal_decline_msg}
                  value={modalData.state.declineMsg}
                  onChange={modalData.handleChange("declineMsg")}
                />
              </Col>
            )}
          </Row>
        </div>

        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={
              modalData.state.requestStatus == "approve" ? "success" : "success"
            }
            onClick={() => modalData.toggleReqestHandle()}
          >
            {request_handle_modal_cancel}
          </Button>
          {modalData.state.requestStatus == "approve" ? (
            <Button
              color={"success"}
              onClick={() => modalData.approveRjectReq()}
              disabled={modalData.state.loading}
            >
              {request_handle_modal_approve}{" "}
              {modalData.state.loading && (
                <Spinner size="sm" className="btn-spinner" />
              )}
            </Button>
          ) : (
            <>

              <Button
                color={"success"}
                onClick={() => modalData.approveRjectReq()}
                disabled={modalData.state.loading}
              >
                {request_handle_modal_reject}{" "}
                {modalData.state.loading && (
                  <Spinner size="sm" className="btn-spinner" />
                )}
              </Button>
              <Button
                color={"success"}
                onClick={() => modalData.resendUrl()}
                disabled={modalData.state.loadingUrl}
              >
                {request_handle_modal_resend}{" "}
                {modalData.state.loadingUrl && (
                  <Spinner size="sm" className="btn-spinner" />
                )}
              </Button>
            </>
          )}
        </ButtonToolbar>
      </Modal>
    );
  },
  blockStaffUser: function (modalData) {
    // -- block Staff User -- 
    const { t } = modalData.props;

    let blockUserName = (typeof modalData.state.blockUser != 'undefined' && modalData.state.blockUser != null) ? modalData.state.blockUser.firstname + " " + modalData.state.blockUser.lastname : "";

    return (
      <Modal
        isOpen={modalData.state.blockModal}
        toggle={() => modalData.toggleBlockUser()}
        className={
          "modal-dialog--success modal-dialog--header"
        }
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleBlockUser()}
          />
          <h4 className="bold-text  modal__title">
            {block_staff_user_modal_block_staff_user}
          </h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col md={12} lg={12} className="theme-light">
              {block_staff_user_modal_do_you_want_to} {" "}
              {modalData.state.blockUser
                ? modalData.state.blockUser.active
                  ? block_staff_user_modal_block
                  : block_staff_user_modal_unblock
                : block_staff_user_modal_block}{" "}
              {block_staff_user_modal_this_user + " ( " + blockUserName + " ) ?"}
            </Col>
          </Row>
        </div>

        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleBlockUser()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.blockUser()}
            disabled={modalData.state.loading}
          >
            {modalData.state.blockUser
              ? modalData.state.blockUser.active
                ? block_btn
                : block_staff_user_modal_unblock_
              : block_staff_user_modal_block}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  createNewUserModal: function (modalData) {
    // -- create New User Modal --
    const { t } = modalData.props;
    const { files, profile_pics } = modalData.state;

    return (
      <Modal
        isOpen={modalData.state.createNewModal}
        toggle={() => modalData.toggleCreate()}
        className={`modal-dialog--success modal-dialog--header min__width_fit`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleCreate()}
          />
          <h4 className="bold-text  modal__title">
            {create_new_user_modal_add_new_company}
          </h4>
        </div>
        <div className="modal__body">
          <form className="material-form card-body-width">
            <Row>
              <Col xs={12} md={6} lg={6} xl={6}>
                <div>
                  <TextField
                    required
                    margin="dense"
                    error={modalData.state.error.includes("company_name")}
                    fullWidth
                    label={sign_in_up_form_company_name}
                    value={modalData.state.company_name}
                    onChange={modalData.handleChange("company_name")}
                  />
                  {modalData.state.error.includes("company_name") && (
                    <span className="error-message">{company_name_validate}</span>
                  )}
                </div>

                <div>
                  <PlacesAutocomplete
                    value={modalData.state.company_address}
                    onChange={modalData.handleChangeaddress("company_address")}
                    onSelect={modalData.handleSelect}
                    id="address"
                    name="address"
                    multiline
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <React.Fragment>
                        <TextField
                          required
                          fullWidth
                          {...getInputProps({
                            className: "location-search-input",
                          })}
                          label={sign_in_up_form_company_address}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>{Lang_Loadingtxt}</div>}
                          {suggestions.map((suggestion, i) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            const style = suggestion.active
                              ? {
                                backgroundColor: "#fafafa",
                                cursor: "pointer",
                              }
                              : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer",
                              };
                            return (
                              <div
                                key={i}
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <MenuItem value={""}>
                                  {suggestion.description}
                                </MenuItem>
                              </div>
                            );
                          })}
                        </div>
                      </React.Fragment>
                    )}
                  </PlacesAutocomplete>
                  {modalData.state.error.includes("company_address") && (
                    <span className="error-message">
                      {create_new_user_modal_enter_company_address}
                    </span>
                  )}
                </div>

                <div>
                  <TextField
                    required
                    margin="dense"
                    error={modalData.state.error.includes(
                      "registration_country"
                    )}
                    fullWidth
                    label={sign_in_up_form_registration_country}
                    value={modalData.state.registration_country}
                    onChange={modalData.handleChange("registration_country")}
                  />
                  {modalData.state.error.includes("registration_country") && (
                    <span className="error-message">
                      {create_new_user_modal_enter_registration_country}
                    </span>
                  )}
                </div>

                <div>
                  <TextField
                    required
                    margin="dense"
                    fullWidth
                    error={modalData.state.error.includes("name")}
                    label={sign_in_up_form_name}
                    value={modalData.state.name}
                    onChange={modalData.handleChange("name")}
                  />
                  {modalData.state.error.includes("name") && (
                    <span className="error-message">
                      {create_new_user_modal_enter_name}
                    </span>
                  )}
                </div>
                <div>
                  <TextField
                    required
                    margin="dense"
                    error={modalData.state.error.includes("email")}
                    fullWidth
                    label={sign_in_up_form_email}
                    value={modalData.state.email}
                    onChange={modalData.handleChange("email")}
                  />
                  {modalData.state.error.includes("email") && (
                    <span className="error-message">
                      {create_new_user_modal_enter_proper_email}
                    </span>
                  )}
                </div>
                <div className="phone-input">
                  <PhoneInput
                    country="FR"
                    placeholder={sign_in_up_from_mobile_phone}
                    value={modalData.state.contact_number}
                    onChange={(phoneNo) => modalData.handlePhoneChange(phoneNo)}
                    className={`phoneInput ${modalData.state.phoneError ? "text-danger" : ""
                      }`}
                  />
                  {modalData.state.error.includes("contact_number") && (
                    <span className="error-message">
                      {create_new_user_modal_enter_valid_mobile_number}
                    </span>
                  )}
                </div>
                <div>
                  <TextField
                    required
                    error={modalData.state.error.includes("company_strength")}
                    fullWidth
                    margin="normal"
                    inputProps={{ maxLength: 5 }}
                    label={sign_in_up_form_company_strength}
                    value={modalData.state.company_strength}
                    onChange={modalData.handleChange("company_strength")}
                  />
                  {modalData.state.error.includes("company_strength") && (
                    <span className="error-message">
                      {create_new_user_modal_enter_company_strength}
                    </span>
                  )}
                </div>
              </Col>
              <Col xs={12} md={6} lg={6} xl={6} className={`theme-light`}>
                <Row>
                  <Col
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={"dropdown-label-text-2"}
                  >
                    {create_new_user_modal_your_company_logo}
                  </Col>
                  <Col xs={12} md={12} lg={12} xl={12}>
                    <div
                      className={`dropzone dropzone--single drop-signup-height`}
                    >
                      <Dropzone
                        accept="image/jpeg, image/png"
                        multiple={false}
                        onDrop={(fileToUpload) => {
                          modalData.onProfileDrop(fileToUpload);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps()}
                            className="dropzone__input drop-signup-height"
                          >
                            {(!profile_pics || profile_pics.length === 0) && (
                              <div className="dropzone__drop-here ">
                                <span className="lnr lnr-upload" />{" "}
                                {create_new_user_modal_drop_company_logo}
                              </div>
                            )}
                            <input {...getInputProps()} />
                          </div>
                        )}
                      </Dropzone>
                      {profile_pics &&
                        Array.isArray(profile_pics) &&
                        profile_pics.length > 0 && (
                          <aside className="dropzone__img">
                            <img src={profile_pics[0].preview} alt="drop-img" />
                            <p className="dropzone__img-name">
                              {profile_pics[0].name}
                            </p>
                            <button
                              className="dropzone__img-delete"
                              type="button"
                              onClick={(e) => modalData.removeProfileFile(0, e)}
                            >
                              {create_new_user_modal_remove}
                            </button>
                          </aside>
                        )}
                    </div>
                  </Col>
                  {modalData.state.error.includes("profile_pics") && (
                    <span className="img-error error-message">
                      {addcmp_enter_profilepic}
                    </span>
                  )}
                </Row>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={"dropdown-label-text-2"}
                >
                  {create_new_user_modal_upload_legal_company_proof}
                  <br />({create_new_user_modal_company_proof_description})
                </Col>
                <Row>
                  <Col
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={"dropdown-label-text"}
                  >
                    <div
                      className={`dropzone dropzone--single drop-signup-height-2`}
                    >
                      <Dropzone
                        accept="image/jpeg, image/png, .pdf, .ppt, .doc, .docx"
                        multiple={true}
                        onDrop={(fileToUpload) => {
                          modalData.onDrop(fileToUpload);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps()}
                            className="dropzone__input drop-signup-height-3"
                          >
                            {(!files || files.length === 0) && (
                              <div className="dropzone__drop-here">
                                <span className="lnr lnr-upload" />{" "}
                                {create_new_user_modal_drop_legal_document}
                              </div>
                            )}
                            <input {...getInputProps()} />
                          </div>
                        )}
                      </Dropzone>
                      {files && Array.isArray(files) && files.length > 0 && (
                        <>
                          {files.map((val, index) => {
                            return (
                              <aside
                                key={index}
                                className="dropzone__img more-image"
                                style={{
                                  height: "60px !important",
                                  width: "60px !important",
                                }}
                              >
                                <Icon>file_copy</Icon>
                                <p>{val.name}</p>
                                <button
                                  className="dropzone__img-delete"
                                  type="button"
                                  onClick={(e) =>
                                    modalData.removeFile(index, e)
                                  }
                                >
                                  {create_new_user_modal_remove}
                                </button>
                              </aside>
                            );
                          })}
                        </>
                      )}
                    </div>
                    {modalData.state.error.includes("files") && (
                      <span className="img-error error-message">
                        {create_new_user_modal_enter_files}
                      </span>
                    )}
                  </Col>
                  <h5 style={{ textAlign: "center", width: "100%", marginTop: "20px" }}>{Signup_yourpastattachment} {"*"}</h5>
                </Row>
              </Col>
            </Row>
          </form>
        </div>

        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleCreate()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.registerNewUserRequest()}
            disabled={modalData.state.loading}
          >
            {create_btn}{" "}
            {modalData.state.loading ? (
              <Spinner size="sm" color="primary" className="margin__left_10" />
            ) : null}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  userRequestDetailAdmin: function (modalData) {
    const { t } = modalData.props;
    const activeTab = modalData.state.activeTab;

    const Invites = [
      { name: user_request_detail_admin_sr_no },
      { name: user_request_detail_admin_email },
      { name: user_request_detail_admin_name },
      {
        name: user_request_detail_admin_role,
        options: {
          filter: false,
          customBodyRender: (value) => {
            if (value !== "-") {
              return <>{value}</>;
            }
            return (
              <span className="othercompany__superadmin">Other Company</span>
            );
          },
        },
      },
      // { name: t("user_request_detail_admin.action"), },
    ];
    const Plans = [
      { name: user_request_detail_admin_sr_no },
      { name: user_request_detail_admin_plan },
      { name: user_request_detail_admin_nooflicence },
      { name: user_request_detail_admin_nooftoken },
      { name: user_request_detail_admin_confirm_date },
      { name: user_request_detail_admin_payment_due },
      { name: user_request_detail_admin_amount_paid },
    ];
    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      print: false,
      download: false,
      viewColumns: false,
      filter: false,
      search: false,
      responsive: "stacked",
      textLabels: {
        body: {
          noMatch: no_match_record_found,
          toolTip: sort_text
        },
        pagination: {
          rowsPerPage: rows_per_page_text,
        },
        toolbar: {
          search: search_text
        },
      },
    };
    return (
      <Modal
        isOpen={modalData.state.openRequestDetail}
        toggle={() => modalData.toggleRequestDetailModal()}
        className={`modal-dialog--success modal-dialog--header max__width_90`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={modalData.toggleRequestDetailModal}
          />
          <h4 className="bold-text  modal__title">{company_title}</h4>
        </div>
        <div className="modal__body">
          {modalData.props.userReducer.requestDetails && (
            <Col xs={12} md={12} lg={12} xl={12}>
              <Card>
                <CardBody>
                  <div className="tabs tabs--bordered-top">
                    <div className="tabs__wrap">
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              modalData.toggle("1");
                            }}
                          >
                            {user_request_accounts}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              modalData.toggle("2");
                            }}
                          >
                            {user_request_detail_admin_invites}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "5",
                            })}
                            onClick={() => {
                              modalData.toggle("5");
                            }}
                          >
                            {user_request_detail_admin_membership}
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <div className="profile__information">
                            <div className="profile__avatar">
                              <img
                                src={modalData.state.company_profile}
                                alt="avatar"
                              />
                            </div>
                            <div className="profile__data">
                              <p className="profile__name">
                                {
                                  modalData.props.userReducer.requestDetails
                                    .name
                                }
                              </p>
                              <p className="profile__work">
                                {user_request_detail_admin_email}:{" "}
                                {
                                  modalData.props.userReducer.requestDetails
                                    .email
                                }
                              </p>
                              <p className="profile__contact">
                                {user_request_detail_admin_contact}:{" "}
                                {
                                  modalData.props.userReducer.requestDetails
                                    .contact_number
                                }
                              </p>
                              <p className="profile__contact">
                                {user_request_detail_admin_company}:{" "}
                                {
                                  modalData.props.userReducer.requestDetails
                                    .display_name
                                }
                              </p>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <MUIDataTable
                            data={modalData.renderInviteUsers()}
                            columns={Invites}
                            options={options}
                          />
                        </TabPane>
                        <TabPane tabId="4">
                          <div className="profile__information">
                            <div className="profile__avatar">
                              <img
                                src={modalData.state.company_profile}
                                alt="avatar"
                              />
                            </div>
                            <div className="profile__data">
                              <p
                                className="profile__name"
                                className="profile-display-name"
                              >
                                {
                                  modalData.props.userReducer.requestDetails
                                    .display_name
                                }
                              </p>
                              <p className="profile__work">
                                {
                                  modalData.props.userReducer.requestDetails
                                    .email
                                }
                              </p>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="5">
                          <div className="profile__data">
                            <p className="profile__name">
                              {user_request_detail_admin_current_plan}:{" "}
                              {modalData.props.userReducer.requestDetails.plan
                                ? modalData.props.userReducer.requestDetails
                                  .plan.planname
                                : ""}
                            </p>
                            <p className="profile__work"></p>
                            {/* <p className="profile__work">
                              {user_request_detail_admin_nooflicence}:{" "}
                              {modalData.getTotalLicense()}
                            </p> */}
                            <p style={{ float: "left", marginRight: "5%" }} className="profile__work">
                              {client_profile_unusedlicence}:{" "}
                              {modalData.getTotalUnUsedLicense()}
                            </p>
                            <p className="profile__work">
                              {client_profile_usedlicence}:{" "}
                              {modalData.getTotalUsedLicense()}
                            </p>
                            <p style={{ float: "left", marginRight: "5%" }} className="profile__work">
                              {client_profile_unusedtoken}:{" "}
                              {modalData.getTotalUnUsedToken()}
                            </p>
                            <p className="profile__work">
                              {client_profile_usedtoken}:{" "}
                              {modalData.getTotalUsedToken()}
                            </p>
                          </div>
                          <MUIDataTable
                            data={modalData.renderPlans()}
                            columns={Plans}
                            options={options}
                          />
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            color={"success"}
            onClick={() => modalData.toggleRequestDetailModal()}
          >
            {common_btn_okay}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  userDetailstep: function (modalData) {
    const { t } = modalData.props;
    const activeTab = modalData.state.activeTab;

    const Invites = [
      { name: user_detail_step_modal_sr_no },
      { name: user_detail_step_modal_email },
      { name: user_detail_step_modal_name },
      { name: user_detail_step_modal_role },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      print: false,
      download: false,
      viewColumns: false,
      filter: false,
      search: false,
      responsive: "stacked",
      textLabels: {
        body: {
          noMatch: no_match_record_found,
          toolTip: sort_text
        },
        pagination: {
          rowsPerPage: rows_per_page_text,
        },
        toolbar: {
          search: search_text
        },
      },
    };

    return (
      <Modal
        isOpen={modalData.state.openDetailStep}
        toggle={() => modalData.toggleopenDetailStep()}
        className={`modal-dialog--success modal-dialog--header max__width_90`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={modalData.toggleopenDetailStep}
          />
          <h4 className="bold-text  modal__title">
            {user_detail_step_modal_request_title}
          </h4>
        </div>
        <div className="modal__body">
          {modalData.props.userReducer.requestDetails && (
            <Col xs={12} md={12} lg={12} xl={12}>
              <Card>
                <CardBody>
                  <div className="tabs tabs--bordered-top">
                    <div className="tabs__wrap">
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              modalData.toggle("1");
                            }}
                          >
                            {user_detail_step_modal_details}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              modalData.toggle("2");
                            }}
                          >
                            {user_detail_step_modal_invites}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "3",
                            })}
                            onClick={() => {
                              modalData.toggle("3");
                            }}
                          >
                            {Invited_users}
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <Row>
                            <Col xs={12} md={6} lg={6} xl={6}>
                              <div className="profile__information">
                                <div className="profile__avatar">
                                  <img
                                    src={modalData.state.company_profile}
                                    alt="avatar"
                                  />
                                </div>
                                <div className="profile__data">
                                  <p
                                    className="profile__name"
                                  >
                                    {user_detail_step_modal_company}:{" "}
                                    {
                                      modalData.props.userReducer.requestDetails
                                        .display_name
                                    }
                                  </p>
                                  <p className="profile__work">
                                    {
                                      modalData.props.userReducer.requestDetails
                                        .company_address
                                    }
                                  </p>
                                  <p className="profile__name profile__contact">
                                    {
                                      modalData.props.userReducer.requestDetails
                                        .name
                                    }
                                  </p>
                                  <p className="profile__work profile__contact">
                                    {
                                      modalData.props.userReducer.requestDetails
                                        .email
                                    }
                                  </p>

                                  <p className="profile__contact">
                                    {user_detail_step_modal_contact}:{" "}
                                    {
                                      modalData.props.userReducer.requestDetails
                                        .contact_number
                                    }
                                  </p>
                                </div>
                              </div>
                            </Col>
                            <Col xs={12} md={6} lg={6} xl={6}>
                              {modalData.props.userReducer.requestDetails
                                .plan ? (
                                <div className="">

                                  <p className="profile__name">
                                    {user_detail_step_modal_plan}{" "}{dashboard_log_details}{" "}
                                    {/* {modalData.props.userReducer.requestDetails
                                      .plan
                                      ? modalData.props.userReducer
                                        .requestDetails.plan.planname +
                                      "- $" +
                                      (modalData.props.userReducer
                                        .requestDetails.plan.price * modalData.props.userReducer
                                        .requestDetails.plan.month)
                                      : ""}{" / "}{modalData.props.userReducer.requestDetails.plan.month}{" "}{client_month} */}
                                  </p>
                                  <p className="profile__work"></p>

                                  <table>
                                    <tr key={1}>
                                      <td style={{ width: "120px" }}>{modalData.props.userReducer.requestDetails.plan ? modalData.props.userReducer.requestDetails.plan.planname : null}</td>
                                      <td> - </td>
                                      <td>{modalData.props.userReducer.requestDetails.plan ? modalData.props.userReducer.requestDetails.plan.month : null}{" "}{client_month} </td>
                                      <td style={{ width: "110px" }}> (${modalData.props.userReducer.requestDetails.plan.price}/{client_month})</td>
                                      <td>{" = $"}{modalData.props.userReducer.requestDetails.plan.price * Number(modalData.props.userReducer.requestDetails.plan.month)}</td>
                                    </tr>
                                  </table>

                                  <p className="profile__work"></p>
                                  <p className="profile__name">
                                    {"Licence " + dashboard_log_details}
                                  </p>
                                  <p className="profile__work"></p>
                                  <p className="profile__work">
                                    {user_detail_step_modal_no_licence}:{" "}
                                    {modalData.getTotalLicense()}
                                  </p>
                                  <table>
                                    {modalData.props.userReducer.requestDetails.licence.map((row, index) => {
                                      return (
                                        <tr key={index}>
                                          {/* <p className="profile__work" key={index}> */}
                                          <td>{row.licencename}</td><td>- {row.count} - </td><td>{row.month}{" "}{client_month} </td><td> (${row.cost}/{client_month})</td><td>{" = $"}{row.cost * row.count * Number(row.month)}</td>
                                          {/* </p> */}
                                        </tr>
                                      );
                                    }
                                    )}
                                    <tr style={{ borderTop: "1px solid black" }} className="profile__name">
                                      <td></td><td></td><td></td>
                                      <td className="float-right">{user_detail_step_modal_total_licence}</td>
                                      <td >   = ${modalData.getTotalAmount()}</td>
                                    </tr>
                                  </table>
                                </div>
                              ) : (
                                <div className="profile__data">
                                  {/* <p className="profile__name">
                                    {user_detail_step_modal_company}:{" "}
                                    {
                                      modalData.props.userReducer.requestDetails
                                        .company_name
                                    }
                                  </p> */}
                                  <p className="profile__work">
                                    {user_detail_step_modal_no_user_licence}
                                  </p>
                                </div>
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="2">
                          <MUIDataTable
                            data={modalData.renderMemberUsers()}
                            columns={Invites}
                            options={options}
                          />
                        </TabPane>
                        <TabPane tabId="3">
                          <MUIDataTable
                            data={modalData.renderInviteUsers2()}
                            columns={Invites}
                            options={options}
                          />
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}
        </div>
        <ButtonToolbar className="modal__footer">
          <>
            <Button
              outline={true}
              color={"success"}
              onClick={() => modalData.toggleopenDetailStep()}
            >
              {common_btn_close}
            </Button>
            {modalData.props.userReducer.requestDetails &&
              modalData.props.userReducer.requestDetails.plan && (
                <Button
                  color={"success"}
                  onClick={() =>
                    modalData.toggleConfirmAccount(modalData.state.requestDetail)
                  }
                >
                  {user_detail_step_modal_confirmact}
                </Button>
              )}
          </>
        </ButtonToolbar>
      </Modal>
    );
  },
  confirmAccountModal: function (modalData) {
    const { t } = modalData.props;

    return (
      <Modal
        isOpen={modalData.state.confirmAccountModal}
        toggle={() => modalData.toggleConfirmAccount()}
        className={"modal-dialog--success modal-dialog--header"}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleConfirmAccount()}
          />
          <h4 className="bold-text  modal__title">
            {confirm_account_modal_confirm_title}
          </h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col md={12} lg={12} className="theme-light">
              {confirm_account_modal_confirm_user}
            </Col>
          </Row>
        </div>

        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleConfirmAccount()}
          >
            {cancel_btn}
          </Button>
          <Button color={"success"} onClick={() => modalData.confirmCompany()} disabled={modalData.state.loading}>
            {approve_btn}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  blockAccountModal: function (modalData) {
    const { t } = modalData.props;
    let companyName = typeof modalData.state.requestDetail != "undefined" ? modalData.state.requestDetail.display_name : "";

    return (
      <Modal
        isOpen={modalData.state.openBlockModal}
        toggle={() => modalData.toggleBlockModal()}
        className="modal-dialog--success modal-dialog--header"
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleBlockModal()}
          />
          <h4 className="bold-text  modal__title">
            {modalData.state.requestDetail &&
              modalData.state.requestDetail.account_status
              ? block_account_modal_block_title
              : block_account_modal_unblock_title}
          </h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col md={12} lg={12} className="theme-light">
              {modalData.state.requestDetail &&
                modalData.state.requestDetail.account_status
                ? block_account_modal_block_msg + " ( " + companyName + " ) ?"
                : block_account_modal_unblock_msg + " ( " + companyName + " ) ?"}
            </Col>
          </Row>
        </div>

        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleBlockModal()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.blockAccount()}
            disabled={modalData.state.loading}
          >
            {modalData.state.requestDetail &&
              modalData.state.requestDetail.account_status
              ? block_btn
              : unblock_btn}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  updatePlan: function (modalData) {
    const { t } = modalData.props;
    const isDisabled = modalData.state.planEdit && modalData.state.planEdit.planid === "AXD_PL_01" ? true : false
    return (
      <Modal
        isOpen={modalData.state.editPlanModal}
        toggle={() => modalData.toggleEditPlan()}
        className="modal-dialog--success modal-dialog--header"
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleEditPlan()}
          />
          <h4 className="bold-text  modal__title">{update_plan_modal_title}</h4>
        </div>
        <div className="modal__body">
          {modalData.state.planEdit && (
            <Row>
              <Col md={12} lg={12} className="theme-light">
                <TextField
                  fullWidth
                  required
                  error={modalData.state.error.includes("planname")}
                  className="material-form__field"
                  label={update_plan_modal_planname}
                  value={modalData.state.planEdit.planname}
                  onChange={modalData.handleChangePlan("planname")}
                  margin="normal"
                />
                {modalData.state.error &&
                  modalData.state.error.includes("planname") && (
                    <span className="error-message">
                      {update_plan_modal_pls_add_plan_name}
                    </span>
                  )}
                <TextField
                  fullWidth
                  disabled={isDisabled}
                  required
                  error={
                    modalData.state.error.includes("price") ||
                    modalData.state.error.includes("valid_price")
                  }
                  InputProps={{
                    startAdornment: <IconButton style={{ fontSize: "17px", padding: "1%", backgroundColor: "#E64823", color: "#fff", marginRight: "1%", borderRadius: "3px" }}>{"$"}</IconButton>
                  }}
                  className="material-form__field"
                  label={update_plan_modal_price}
                  value={modalData.state.planEdit.price}
                  onChange={modalData.handleChangePlan("price")}
                  margin="normal"
                />
                {modalData.state.error &&
                  modalData.state.error.includes("price") && (
                    <span className="error-message">
                      {update_plan_modal_pls_add_price}
                    </span>
                  )}
                {modalData.state.error &&
                  modalData.state.error.includes("valid_price") && (
                    <span className="error-message">
                      {update_plan_modal_pls_add_valid_price}
                    </span>
                  )}
                <TextField
                  fullWidth
                  disabled={isDisabled}
                  required
                  error={
                    modalData.state.error.includes("uploadsize") ||
                    modalData.state.error.includes("valid_uploadsize")
                  }
                  InputProps={{
                    startAdornment: <IconButton style={{ fontSize: "17px", padding: "1%", backgroundColor: "#E64823", color: "#fff", marginRight: "1%", borderRadius: "3px" }}>{"GB"}</IconButton>
                  }}
                  className="material-form__field"
                  label={update_plan_modal_store_capacity}
                  value={modalData.state.planEdit.uploadsize}
                  onChange={modalData.handleChangePlan("uploadsize")}
                  margin="normal"
                />
                {modalData.state.error &&
                  modalData.state.error.includes("uploadsize") && (
                    <span className="error-message">
                      {update_plan_modal_pls_add_uploadsize}
                    </span>
                  )}
                {modalData.state.error &&
                  modalData.state.error.includes("valid_uploadsize") && (
                    <span className="error-message">
                      {update_plan_modal_pls_add_valid_uploadsize}
                    </span>
                  )}
                <TextField
                  fullWidth
                  disabled={isDisabled}
                  required
                  error={
                    modalData.state.error.includes("usersize") ||
                    modalData.state.error.includes("valid_usersize")
                  }
                  className="material-form__field"
                  label={update_plan_modal_max_user_create}
                  value={modalData.state.planEdit.usersize}
                  onChange={modalData.handleChangePlan("usersize")}
                  margin="normal"
                />
                {modalData.state.error &&
                  modalData.state.error.includes("usersize") && (
                    <span className="error-message">
                      {update_plan_modal_pls_add_usersize}
                    </span>
                  )}
                {modalData.state.error &&
                  modalData.state.error.includes("valid_usersize") && (
                    <span className="error-message">
                      {update_plan_modal_pls_add_valid_usersize}
                    </span>
                  )}
              </Col>
            </Row>
          )}
        </div>

        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleEditPlan()}
          >
            {cancel_btn}
          </Button>
          <Button color={"success"} onClick={() => modalData.editPlan()}>
            {language_savebtn}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  updateLicence: function (modalData) {
    const { t } = modalData.props;

    return (
      <Modal
        isOpen={modalData.state.editLicenceModal}
        toggle={() => modalData.toggleEditLicence()}
        className="modal-dialog--success modal-dialog--header"
        style={{ maxWidth: '700px', width: '100%' }}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleEditLicence()}
          />
          <h4 className="bold-text  modal__title">
            {update_licence_modal_title}
          </h4>
        </div>
        <div className="modal__body">
          {modalData.state.licenceEdit && (
            <Row>

              <Col md={6} lg={6} className="theme-light">
                <Col md={12} lg={12} className="theme-light">
                  <TextField
                    fullWidth
                    required
                    error={modalData.state.error.includes("licencename")}
                    className="material-form__field"
                    label={update_licence_modal_licence_name}
                    value={modalData.state.licenceEdit.licencename}
                    onChange={modalData.handleChangeLicence("licencename")}
                    margin="normal"
                  />
                  {modalData.state.error &&
                    modalData.state.error.includes("licencename") && (
                      <span className="error-message">
                        {update_licence_modal_pls_add_licence_name}
                      </span>
                    )}
                </Col>
                <Col md={12} lg={12} className="theme-light">
                  <TextField
                    fullWidth
                    required
                    error={
                      modalData.state.error.includes("cost") ||
                      modalData.state.error.includes("valid_cost")
                    }
                    InputProps={{
                      startAdornment: <IconButton style={{ fontSize: "17px", padding: "1%", backgroundColor: "#E64823", color: "#fff", marginRight: "1%", borderRadius: "3px" }}>{"$"}</IconButton>
                    }}
                    className="material-form__field"
                    label={update_licence_modal_cost}
                    value={modalData.state.licenceEdit.cost}
                    onChange={modalData.handleChangeLicence("cost")}
                    margin="normal"
                  />
                  {modalData.state.error &&
                    modalData.state.error.includes("cost") && (
                      <span className="error-message">
                        {update_licence_modal_pls_add_cost}
                      </span>
                    )}
                  {modalData.state.error &&
                    modalData.state.error.includes("valid_cost") && (
                      <span className="error-message">
                        {update_licence_modal_pls_add_valid_cost}
                      </span>
                    )}
                </Col>
                <Row>
                  <Col md={4} lg={4} className="theme-light" style={{ marginTop: "3%", marginLeft: "5%" }}>
                    <h6>{LICENCE_STATUS}</h6>
                  </Col>
                  <Col md={6} lg={6} className="theme-light">
                    <FormControlLabel
                      //key={i}
                      fullWidth
                      className="switch-staff"
                      control={
                        <RedSwitch
                          disabled={false}
                          checked={
                            modalData.state.licenceEdit.status
                          }
                          onClick={(event) =>
                            modalData.handleChangeStatus(event, 'status')
                          }
                          value={modalData.state.licenceEdit.status}
                        // color="secondary"
                        />
                      }
                    //label={LICENCE_STATUS}
                    />
                  </Col>
                </Row>

              </Col>

              <Col md={6} lg={6} className="theme-light">


                <Col md={12} lg={12} className="theme-light">
                  <h4 style={{ textAlign: "center" }}>{master_licence_access_page_header_title}</h4>
                  <div className={"access-staff-div"}>
                    {modalData.state.accessPage &&
                      Object.keys(modalData.state.accessPage).map(function (key, i) {
                        let row = modalData.state.accessPageName[key];

                        if (row !== undefined) {
                          return (
                            <>
                              <FormControlLabel
                                key={i}
                                fullWidth
                                className="switch-staff"
                                control={
                                  <RedSwitch
                                    disabled={false}
                                    checked={
                                      modalData.state.accessPage[key] == undefined ? false :
                                        modalData.state.accessPage[key]
                                    }
                                    onClick={(event) =>
                                      modalData.handleAccessPage(event, key)
                                    }
                                    value={key}
                                  // color="secondary"
                                  />
                                }
                                label={row.page}
                              />
                            </>
                          );
                        }


                      })}
                  </div>
                </Col>



              </Col>

            </Row>
          )}
        </div>

        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleEditLicence()}
          >
            {cancel_btn}
          </Button>
          <Button color={"success"} onClick={() => modalData.editLicence()}>
            {language_savebtn}
            {modalData.state.loading ? <Spinner size="sm" style={{ marginLeft: "10px", color: "#fff" }} /> : null}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  updateToken: function (modalData) {
    const { t } = modalData.props;

    return (
      <Modal
        isOpen={modalData.state.editTokenModal}
        toggle={() => modalData.toggleEditToken()}
        className="modal-dialog--success modal-dialog--header"
        style={{ maxWidth: '700px', width: '100%' }}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleEditToken()}
          />
          <h4 className="bold-text  modal__title">
            {admin_edit_token}
          </h4>
        </div>
        <div className="modal__body">
          {modalData.state.tokenEdit && (
            <Row>

              <Col md={6} lg={6} className="theme-light">
                <Col md={12} lg={12} className="theme-light">
                  <TextField
                    fullWidth
                    required
                    error={modalData.state.error.includes("tokenname")}
                    className="material-form__field"
                    label={MASTERS_TOKENNAME_KEY}
                    value={modalData.state.tokenEdit.tokenName}
                    onChange={modalData.handleChangeToken("tokenName")}
                    margin="normal"
                  />
                  {modalData.state.error &&
                    modalData.state.error.includes("tokenName") && (
                      <span className="error-message">
                        {update_token_modal_pls_add_token_name}
                      </span>
                    )}
                </Col>
                <Col md={12} lg={12} className="theme-light">
                  <TextField
                    fullWidth
                    required
                    error={
                      modalData.state.error.includes("cost") ||
                      modalData.state.error.includes("valid_cost")
                    }
                    InputProps={{
                      startAdornment: <IconButton style={{ fontSize: "17px", padding: "1%", backgroundColor: "#E64823", color: "#fff", marginRight: "1%", borderRadius: "3px" }}>{"$"}</IconButton>
                    }}
                    className="material-form__field"
                    label={update_licence_modal_cost}
                    value={modalData.state.tokenEdit.cost}
                    onChange={modalData.handleChangeToken("cost")}
                    margin="normal"
                  />
                  {modalData.state.error &&
                    modalData.state.error.includes("cost") && (
                      <span className="error-message">
                        {update_licence_modal_pls_add_cost}
                      </span>
                    )}
                  {modalData.state.error &&
                    modalData.state.error.includes("valid_cost") && (
                      <span className="error-message">
                        {update_licence_modal_pls_add_valid_cost}
                      </span>
                    )}
                </Col>
              </Col>

            </Row>
          )}
        </div>

        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleEditToken()}
          >
            {cancel_btn}
          </Button>
          <Button color={"success"} onClick={() => modalData.editToken()}>
            {language_savebtn}
            {modalData.state.loading ? <Spinner size="sm" style={{ marginLeft: "10px", color: "#fff" }} /> : null}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  projectDetailsModal: function (modalData) {
    const { t } = modalData.props;
    const { files } = modalData.state;

    const columns = [
      { name: project_sr_no },
      { name: project_name },
      { name: project_licence },
      { name: project_role },
      { name: project_company },
      { name: project_status },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      print: false,
      download: false,
      viewColumns: false,
      filter: false,
      responsive: "stacked",
      pagination: false,
      search: false,
      textLabels: {
        body: {
          noMatch: no_match_record_found,
          toolTip: sort_text
        },
        pagination: {
          rowsPerPage: rows_per_page_text,
        },
        toolbar: {
          search: search_text
        },
      },
    };

    return (
      <Modal
        isOpen={modalData.state.projectDetailModal}
        toggle={() => modalData.viewProjectDetails()}
        className={`modal-dialog--success modal-dialog--header width__70`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={(e) => modalData.viewProjectDetails()}
          />
          <h4 className="bold-text  modal__title">{project_project_details}</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col xs={12} sm={6} md={6} >
              <div>
                <p className="profile__work mb-2"><b>{project_project_name}</b>:{" "} {modalData.state.title}</p>
                <p className="profile__work" style={{ height: '20px', overflow: 'auto' }}><b>{project_purpose}</b>: {modalData.state.purpose}</p>
                <p className="profile__work"><b>{project_latitude}</b>: {modalData.state.lat}</p>
                <p className="profile__work"><b>{project_start_date}</b>:{" "}{moment(modalData.state.start).format("DD/MM/YYYY HH:mm")}</p>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6}>
              <p className="profile__work mb-2 "><b>{project_status}</b>:{" "} {modalData.state.isclosed}</p>
              <p className="profile__work mb-2 "><b>{project_project_address}</b>:{" "} {modalData.state.address}</p>
              <p className="profile__work"><b>{project_longtitude}</b>: {modalData.state.lng}</p>
              <p className="profile__work"><b>{project_end_date}</b>:{" "}{moment(modalData.state.end).format("DD/MM/YYYY HH:mm")}</p>
            </Col>
            <Col xs={12} md={12} style={{ textAlign: "left", height: '185px', overflow: 'auto' }}>
              <p className="mb-2 profile__work">
                {modalData.state.attachments && modalData.state.attachments.length ?
                  project_attachments
                  : ""}
              </p>
              {modalData.state.attachments &&
                modalData.state.attachments.map((val, index) => {
                  let key = Object.keys(val);
                  return (
                    <Col key={index} xs={6} md={6} lg={6} xl={6} style={{ textAlign: "left" }} >
                      {index + 1}.
                      <a href={val[key[0]]} target="_blank">
                        {key[0]}
                      </a>
                    </Col>
                  );
                })}
            </Col>
            <Col xs={12} sm={12} md={12} style={{ marginTop: "40px" }}>
              <MUIDataTable
                data={modalData.renderActiveUser()}
                columns={columns}
                options={options}
              />
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            color={"success"}
            onClick={() => modalData.viewProjectDetails()}
          >
            {common_btn_okay}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  materialAddEditModal: function (modalData) {
    const { t } = modalData.props;
    let row1 = modalData.state.materialEdit;
    let _id = modalData.state.materialEdit._id;

    let titleName = _id
      ? material_model_edit_material_title
      : material_model_add_material_title;
    return (
      <Modal
        isOpen={modalData.state.materialAddEditModal}
        toggle={() => modalData.toggleMaterialAddEditModal()}
        className={`modal-dialog--success modal-dialog--header width__70`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleMaterialAddEditModal()}
          />
          <h4 className="bold-text  modal__title">{titleName}</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col xs={12} sm={12} md={12}>
              {row1 && (
                <Row>
                  <Col xs={6}>
                    <TextField
                      fullWidth
                      className="material-form__field maxWidth__250"
                      label={material_model_type}
                      name="type"
                      margin="normal"
                      onChange={modalData.handleInputChange("type")}
                      value={row1.type}
                      error={row1.error && row1.error.includes("type")}
                    />
                    {row1.error && row1.error.includes("type") && (
                      <p className="error-message">
                        {material_model_pls_add_material_type}
                      </p>
                    )}
                  </Col>
                  <Col xs={6}>
                    <ColorPicker
                      fullWidth
                      margin="normal"
                      value={row1.color}
                      InputProps={{ value: row1.color }}
                      label={material_model_color}
                      margin="normal"
                      TextFieldProps={{
                        style: {
                          width: "100%",
                          marginTop: "16px",
                        },
                      }}
                      className="material-form__field full__width"
                      onChange={(val) =>
                        modalData.handleMaterialNewValueColor(val, "color")
                      }
                    />
                    {row1.error && row1.error.includes("color") && (
                      <p className="error-message">
                        {material_model_color_validate}
                      </p>
                    )}
                  </Col>
                  <Col xs={6}>
                    <div className="material-box">
                      <label>{material_model_key}</label>
                      <Chips
                        value={row1.key}
                        onChange={(val) =>
                          modalData.handleNewKeysTags(val, "key")
                        }
                        fromSuggestionsOnly={false}
                        createChipKeys={[9, 13]}
                        error={row1.error && row1.error.includes("key")}
                      />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="material-box">
                      <label>{material_model_tag}</label>
                      <Chips
                        value={row1.tag}
                        onChange={(val) =>
                          modalData.handleNewKeysTags(val, "tag")
                        }
                        fromSuggestionsOnly={false}
                        createChipKeys={[9, 13]}
                        error={row1.error && row1.error.includes("tag")}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleMaterialAddEditModal()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.saveMaterial()}
            disabled={modalData.state.loading}
          >
            {language_savebtn}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  materialDeleteModal: function (modalData) {
    const { t } = modalData.props;

    return (
      <Modal
        isOpen={modalData.state.materialDeleteModal}
        toggle={() => modalData.toggleMaterialDeleteModal()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleMaterialDeleteModal()}
          />
          <h4 className="bold-text  modal__title">
            {delete_material_master_title}
          </h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col md={12} lg={12} className="theme-light">
              {delete_material_master_areyoumsg}
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleMaterialDeleteModal()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.deleteMaterial()}
            disabled={modalData.state.loading}
          >
            {delete_staff_user_modal_delete}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  otherMaterialAddEditModal: function (modalData) {
    let lang = modalData.props.languagecodeReducer.languagecodeLabelList
    let row1 = modalData.state.master;
    let lang_data = row1.lang_data;
    let Languages = lang.filter((val, index) => val.code !== "us")

    let filterLabelArray = ""
    if (lang_data) {
      filterLabelArray = lang_data.map(val => {
        if (val.lang_code) {
          return val.lang_code;
        }
      })
    }

    let _this = modalData;
    let geologyList = modalData.state.geologyList;
    let patternList = modalData.state.patternList;
    let _id = modalData.state.master._id;



    let titleName = "";
    if (modalData.state.masterType === "color") {
      titleName = _id
        ? material_model_edit_color_txt
        : material_model_add_new_color;
    } else if (modalData.state.masterType === "nature") {
      titleName = _id
        ? material_model_edit_new_nature
        : material_model_add_new_nature;
    } else if (modalData.state.masterType === "geology") {
      titleName = _id
        ? material_model_edit_soil_family
        : material_model_add_new_soil_family;
    } else if (modalData.state.masterType === "pattern") {
      titleName = _id
        ? material_model_edit_pattern
        : material_model_add_new_pattern;
    } else if (modalData.state.masterType === "organoleptic") {
      titleName = _id
        ? material_model_edit_organoleptic_text
        : material_model_add_new_organoleptic;
    } else if (modalData.state.masterType === "category") {
      titleName = _id
        ? material_model_edit_new_category
        : material_model_add_new_category;
    } else if (modalData.state.masterType === "lithology") {
      titleName = _id
        ? material_model_edit_new_lithology
        : material_model_add_new_lithology;
    } else if (modalData.state.masterType === "moisture") {
      titleName = _id
        ? material_model_edit_new_moisture
        : material_model_add_new_moisture;
    } else if (modalData.state.masterType === "anomaly") {
      titleName = _id
        ? material_model_edit_new_anomaly
        : material_model_add_new_anomaly;
    } else if (modalData.state.masterType === "reason") {
      titleName = _id
        ? material_model_edit_new_reason
        : material_model_add_new_reason;
    } else if (modalData.state.masterType === "observation") {
      titleName = _id
        ? material_model_edit_new_observation
        : material_model_add_new_observation;
    }

    var flag = false;
    var isReasonList = false;

    if (
      modalData.state.masterType == "category" ||
      modalData.state.masterType == "lithology" ||
      modalData.state.masterType == "moisture" ||
      modalData.state.masterType == "anomaly" ||
      modalData.state.masterType == "observation"

    ) {

      flag = true
    }

    if (modalData.state.masterType == "reason") {
      isReasonList = true;
    }

    return (
      <Modal
        isOpen={modalData.state.otherMaterialAddEditModal}
        toggle={() => modalData.toggleOtherMaterialAddEditModal()}
        className={`modal-dialog--success modal-dialog--header ${flag ? "width__50" : "width__70"}`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleOtherMaterialAddEditModal()}
          />
          <h4 className="bold-text  modal__title">{titleName}</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col xs={12} sm={12} md={12} className="">
              {modalData.state.otherMaterialAddEditModal && row1 && (
                <>
                  <Row>
                    <Col xs={(flag && modalData.state.masterType !== "lithology") || (isReasonList) ? 12 : 6}>
                      <Row>
                        <Col xs={isReasonList ? 4 : 9} sm={isReasonList ? 4 : 9}>
                          <TextField
                            fullWidth
                            className="material-form__field "
                            label={material_masters_default_name}
                            name="colorName"
                            margin="normal"
                            onChange={modalData.handleInputMasterChange("name")}
                            value={row1.name}
                            error={row1.error && row1.error.includes("name")}
                          />
                          {row1.error && row1.error.includes("name") && (
                            <p className="error-message">
                              {material_masters_default_name_validate}
                            </p>
                          )}
                        </Col>

                        {isReasonList && (
                          <>
                            <Col xs={4} sm={4}>
                              <TextField
                                fullWidth
                                className="material-form__field "
                                label={description}
                                name="description"
                                margin="normal"
                                onChange={modalData.handleInputMasterChange("value")}
                                value={row1.value}
                                error={row1.error && row1.error.includes("value")}
                              />
                              {row1.error && row1.error.includes("value") && (
                                <p className="error-message">
                                  {material_masters_description_validate}
                                </p>
                              )}
                            </Col>
                          </>
                        )}



                        <Col xs={3} sm={3}>
                          <Button
                            title={"Add Name"}
                            color="success"
                            onClick={() => _this.addOtherMasterName("lang_data")}
                            className={
                              "add-new-btn btn btn-outline-success"
                            }
                            outline={true}
                            style={{ marginTop: "30px" }}
                          >
                            <Icon>add</Icon>
                          </Button>
                        </Col>
                      </Row>
                      {lang_data.length > 0 &&
                        lang_data.map((value1, index1) => {
                          return (
                            <>
                              <Row key={index1}>
                                <Col xs={isReasonList ? 3 : 4} sm={isReasonList ? 3 : 4} md={isReasonList ? 3 : 4} >
                                  <TextField
                                    select
                                    id={"standard-select-" + index1}
                                    SelectProps={{
                                      MenuProps: {
                                        className: "select-menu-width",
                                      },
                                    }}
                                    style={{ width: "100%" }}
                                    label={label_language}
                                    className="material-form__field"
                                    value={value1.lang_code}
                                    onChange={_this.handleInputOtherMasterName(index1, "lang_code")}
                                    name={"language"}
                                    error={row1.error && row1.error.includes(index1 + "_lang_code")}
                                  >
                                    {Languages.filter(option => (!filterLabelArray.includes(option.code) || value1.lang_code == option.code)).map((lang) => (
                                      <MenuItem
                                        key={lang._id}
                                        value={lang.code}
                                      >
                                        {lang.language}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </Col>
                                <Col xs={isReasonList ? 3 : 6} sm={isReasonList ? 3 : 6} md={isReasonList ? 3 : 6} >
                                  <TextField
                                    fullWidth
                                    // id={"description_texts" + de_i}
                                    label={material_masters_name}
                                    value={value1.lang_text}
                                    onChange={_this.handleInputOtherMasterName(index1, "lang_text")}
                                    error={row1.error && row1.error.includes(index1 + "_lang_text")}
                                  />
                                </Col>

                                {isReasonList && (
                                  <>
                                    <Col xs={3} sm={3} md={3} >
                                      <TextField
                                        fullWidth
                                        // id={"description_texts" + de_i}
                                        label={"Description"}
                                        value={value1.lang_desc}
                                        onChange={_this.handleInputOtherMasterName(index1, "lang_desc")}
                                        error={row1.error && row1.error.includes(index1 + "_lang_desc")}
                                      />
                                    </Col>
                                  </>
                                )}


                                <Col xs={isReasonList ? 3 : 2} sm={isReasonList ? 3 : 2} md={isReasonList ? 3 : 2} >
                                  <Button
                                    // title={tooltip_remove}
                                    color="success"
                                    // onClick={() => _this.removeFieldInTestfamily("description", index1,)}
                                    onClick={_this.removeOtherMasterName(index1, 'remove')}
                                    className={"system-btn clear-border remove-btn"}
                                    outline={true}
                                  >
                                    <Icon>close</Icon>
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          )
                        })}
                      {/* <TextField
                        fullWidth
                        className="material-form__field "
                        label={material_masters_name}
                        name="colorName"
                        margin="normal"
                        onChange={modalData.handleInputMasterChange("name")}
                        value={row1.name}
                        error={row1.error && row1.error.includes("name")}
                      />
                      {row1.error && row1.error.includes("name") && (
                        <p className="error-message">
                          {material_masters_name_validate}
                        </p>
                      )} */}
                    </Col>
                    {modalData.state.masterType === "lithology" && (
                      <Col xs={6}>
                        <TextField
                          fullWidth
                          select
                          margin="normal"
                          id="standard-select-currency"
                          SelectProps={{
                            MenuProps: {
                              className: "select-menu-width",
                            },
                          }}
                          label={material_masters_soil_family}
                          className="material-form__field"
                          value={row1.value}
                          InputLabelProps={{ shrink: row1.value ? true : false }}
                          onChange={modalData.handleInputMasterChange("value")}
                          error={row1.error && row1.error.includes("value")}
                        >
                          {geologyList.map(option => (
                            <MenuItem key={option.name} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        {row1.error && row1.error.includes("value") && (
                          <p className="error-message">
                            {material_masters_value_validate}
                          </p>
                        )}
                      </Col>
                    )}

                    {!flag && (
                      <>
                        {modalData.state.masterType === "color" ? (
                          <Col xs={6}>
                            <ColorPicker
                              fullWidth
                              margin="normal"
                              value={row1.value}
                              InputProps={{ value: row1.value }}
                              label={material_masters_color_code}
                              margin="normal"
                              TextFieldProps={{
                                style: {
                                  width: "100%",
                                  marginTop: "16px",
                                },
                              }}
                              className="material-form__field full__width"
                              onChange={(val) =>
                                modalData.handleNewValueColor(val, "value")
                              }
                            />
                            {row1.error && row1.error.includes("value") && (
                              <p className="error-message">
                                {material_masters_color_code_validate}
                              </p>
                            )}
                          </Col>
                        ) : (
                          <>
                            {modalData.state.masterType === "organoleptic" && (
                              <Col xs={6}>
                                <TextField
                                  fullWidth
                                  className="material-form__field "
                                  label={material_masters_value}
                                  name="value"
                                  margin="normal"
                                  onChange={modalData.handleInputMasterChange("value")}
                                  value={row1.value}
                                  error={row1.error && row1.error.includes("value")}
                                />
                                {row1.error && row1.error.includes("value") && (
                                  <p className="error-message">
                                    {material_masters_value_validate}
                                  </p>
                                )}
                              </Col>
                            )}
                            {modalData.state.masterType === "geology" && (
                              <Col xs={6}>
                                <TextField
                                  fullWidth
                                  select
                                  margin="normal"
                                  id="standard-select-currency"
                                  SelectProps={{
                                    MenuProps: {
                                      className: "select-menu-width",
                                    },
                                  }}
                                  label={'Pattern'}
                                  className="material-form__field"
                                  value={row1.pattern_id}
                                  InputLabelProps={{ shrink: row1.pattern_id ? true : false }}
                                  onChange={modalData.handleInputMasterChange("pattern_id")}
                                  error={row1.error && row1.error.includes("pattern_id")}
                                >
                                  {patternList.map(option => (
                                    <MenuItem key={option.pattern_name} value={option.pattern_id}>
                                      {option.pattern_name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                                {row1.error && row1.error.includes("pattern_id") && (
                                  <p className="error-message">
                                    {material_masters_pattern_validate}
                                  </p>
                                )}
                              </Col>
                            )}

                            {modalData.state.masterType === "pattern" && (
                              <>
                                <Col xs={6}>

                                  <Row>

                                    <Col xs={12} sm={12}>

                                      <TextField
                                        required
                                        fullWidth
                                        id="master_pattern_code"
                                        type="text"
                                        label={material_masters_pattern_svg_code}
                                        value={row1.pattern_code}
                                        onChange={modalData.handleInputMasterChange("pattern_code")}
                                        error={row1.error && row1.error.includes("pattern_code")}
                                      />
                                      {row1.error && row1.error.includes("pattern_code") && (
                                        <p className="error-message">
                                          {material_masters_pattern_svg_code_validate}
                                        </p>
                                      )}

                                    </Col>

                                    <Col xs={12} sm={12}>

                                      <TextField
                                        required
                                        fullWidth
                                        id="master_pattern_width"
                                        type="number"
                                        label={material_masters_pattern_width}
                                        value={row1.pattern_width}
                                        onChange={modalData.handleInputMasterChange("pattern_width")}
                                        error={row1.error && row1.error.includes("pattern_width")}
                                      />
                                      {row1.error && row1.error.includes("pattern_width") && (
                                        <p className="error-message">
                                          {material_masters_pattern_width_validate}
                                        </p>
                                      )}

                                    </Col>

                                    <Col xs={12} sm={12}>

                                      <TextField
                                        required
                                        fullWidth
                                        id="master_pattern_height"
                                        type="number"
                                        label={material_masters_pattern_height}
                                        value={row1.pattern_height}
                                        onChange={modalData.handleInputMasterChange("pattern_height")}
                                        error={row1.error && row1.error.includes("pattern_height")}
                                      />
                                      {row1.error && row1.error.includes("pattern_height") && (
                                        <p className="error-message">
                                          {material_masters_pattern_height_validate}
                                        </p>
                                      )}

                                    </Col>

                                  </Row>

                                </Col>


                              </>

                            )}

                          </>
                        )}
                      </>
                    )}
                  </Row>
                  {!flag && !isReasonList && (
                    <Row>
                      <Col xs={6}>
                        <div className="material-box">
                          <label>{material_model_key}</label>
                          <Chips
                            value={row1.key}
                            onChange={(val) =>
                              modalData.handleNewKeysTagsOther(val, "key")
                            }
                            fromSuggestionsOnly={false}
                            createChipKeys={[9, 13]}
                            error={row1.error && row1.error.includes("key")}
                          />
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="material-box">
                          <label>{material_model_tag}</label>
                          <Chips
                            value={row1.tag}
                            onChange={(val) =>
                              modalData.handleNewKeysTagsOther(val, "tag")
                            }
                            fromSuggestionsOnly={false}
                            createChipKeys={[9, 13]}
                            error={row1.error && row1.error.includes("tag")}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleOtherMaterialAddEditModal()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.saveOtherMaterial()}
            disabled={modalData.state.loading}
          >
            {language_savebtn}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  otherMaterialDeleteModal: function (modalData) {
    const { t } = modalData.props;
    let row1 = modalData.state.master;
    let _id = modalData.state.master._id;
    let titleName = "";

    let deletemasterPopupName = typeof modalData.state.othermasterialDlt != 'undefined' ? " ( " + modalData.state.othermasterialDlt.name + " ) " : "";

    if (modalData.state.masterType === "color") {
      deletemasterPopupName = "";
      titleName = delete_color_masters;
    } else if (modalData.state.masterType === "nature") {
      titleName = delete_nature_masters;
    } else if (modalData.state.masterType === "geology") {
      titleName = delete_soil_family_masters;
    } else if (modalData.state.masterType === "organoleptic") {
      titleName = delete_organoleptic_masters;
    } else if (modalData.state.masterType === "category") {
      titleName = delete_category_masters;
    } else if (modalData.state.masterType === "lithology") {
      deletemasterPopupName = "";
      titleName = delete_lithology_masters;
    } else if (modalData.state.masterType === "moisture") {
      titleName = delete_moisture_masters;
    } else if (modalData.state.masterType === "anomaly") {
      deletemasterPopupName = "";
      titleName = delete_anomaly_masters;
    } else if (modalData.state.masterType === "reason") {
      titleName = delete_reason_masters;
    } else if (modalData.state.masterType === "observation") {
      titleName = delete_observation_masters;
    } else if (modalData.state.masterType === "pattern") {
      titleName = delete_pattern_masters;
    }

    return (
      <Modal
        isOpen={modalData.state.otherMaterialDeleteModal}
        toggle={() => modalData.toggleOtherMaterialDeleteModal()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleOtherMaterialDeleteModal()}
          />
          <h4 className="bold-text  modal__title">{titleName}</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col md={12} lg={12} className="theme-light">
              {delete_material_master_areyoumsg + deletemasterPopupName + " ?"}
            </Col>
          </Row>
        </div>

        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleOtherMaterialDeleteModal()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.deleteOtherMaterial()}
            disabled={modalData.state.loading}
          >
            {delete_staff_user_modal_delete}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },

  roleAddEditModal: function (modalData) {
    let lag = modalData.props.languagecodeReducer.languagecodeLabelList;
    let row1 = modalData.state.roleListData;
    let lang_data = row1.lang_data;
    let Languages = lag.filter((val, index) => val.code !== "us")

    let filterLabelArray = ""
    if (lang_data) {
      filterLabelArray = lang_data.map(val => {
        if (val.lang_code) {
          return val.lang_code;
        }
      })
    }


    let _this = modalData;
    let _id = modalData.state.roleListData._id;

    let titleName = _id
      ? "Edit Role"
      : "Add New Role";



    return (
      <Modal
        isOpen={modalData.state.roleAddEditModal}
        toggle={() => modalData.toggleRoleAddEditModal()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleRoleAddEditModal()}
          />
          <h4 className="bold-text  modal__title">{titleName}</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col xs={12} sm={12} md={12} className="">
              {row1 && (
                <>
                  <Row>
                    <Col xs={9} sm={9}>
                      <TextField
                        fullWidth
                        className="material-form__field "
                        label={material_masters_default_name}
                        name="name"
                        margin="normal"
                        onChange={modalData.handleInputRoleChange("name")}
                        value={row1.name}
                        error={row1.error && row1.error.includes("name")}
                      />
                      {row1.error && row1.error.includes("name") && (
                        <p className="error-message">
                          {material_masters_default_name_validate}
                        </p>
                      )}
                    </Col>

                    <Col xs={3} sm={3}>
                      <Button
                        title={"Add Name"}
                        color="success"
                        onClick={() => _this.addOtherMasterNameRole("lang_data")}
                        className={
                          "add-new-btn btn btn-outline-success"
                        }
                        outline={true}
                        style={{ marginTop: "30px" }}
                      >
                        <Icon>add</Icon>
                      </Button>
                    </Col>
                  </Row>


                  {lang_data.length > 0 &&
                    lang_data.map((value1, index1) => {
                      return (
                        <>
                          <Row key={index1}>
                            <Col xs={4} sm={4} md={4} >
                              <TextField
                                select
                                id={"standard-select-" + index1}
                                SelectProps={{
                                  MenuProps: {
                                    className: "select-menu-width",
                                  },
                                }}
                                style={{ width: "100%" }}
                                label={"Language"}
                                className="material-form__field"
                                value={value1.lang_code}
                                onChange={_this.handleInputOtherMasterNameRole(index1, "lang_code")}
                                name={"language"}
                                error={row1.error && row1.error.includes(index1 + "_lang_code")}
                              >
                                {Languages.filter(option => (!filterLabelArray.includes(option.code) || value1.lang_code == option.code)).map((lang) => (
                                  <MenuItem
                                    key={lang._id}
                                    value={lang.code}
                                  >
                                    {lang.language}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Col>
                            <Col xs={6} sm={6} md={6} >
                              <TextField
                                fullWidth
                                // id={"description_texts" + de_i}
                                label={"Name"}
                                value={value1.lang_text}
                                onChange={_this.handleInputOtherMasterNameRole(index1, "lang_text")}
                                error={row1.error && row1.error.includes(index1 + "_lang_text")}
                              />
                            </Col>
                            <Col xs={2} sm={2} md={2} >
                              <Button
                                // title={tooltip_remove}
                                color="success"
                                // onClick={() => _this.removeFieldInTestfamily("description", index1,)}
                                onClick={_this.removeOtherMasterNameRole(index1, 'remove')}
                                className={"system-btn clear-border remove-btn"}
                                outline={true}
                              >
                                <Icon>close</Icon>
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )
                    })}
                  <Row>
                    <Col style={{margin: "5% 0", padding: "0"}}>
                      <FormControlLabel
                        key={'roleStatus'}
                        fullWidth
                        className="switch-staff"
                        control={
                          <RedSwitch
                            disabled={false}
                            checked={
                              row1.status
                            }
                            onClick={(event) =>
                              modalData.handleChangeRoleStatus(event, "status")
                            }

                            value={row1.status}
                          // color="secondary"
                          />
                        }
                        label={"Status"}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleRoleAddEditModal()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.saveRole()}
            disabled={modalData.state.loading}
          >
            {language_savebtn}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },


  probingMethodAddEditModal: function (modalData) {
    let lag = modalData.props.languagecodeReducer.languagecodeLabelList;
    let row1 = modalData.state.probingMethod;
    let lang_data = row1.lang_data;
    let Languages = lag.filter((val, index) => val.code !== "us")

    let filterLabelArray = ""
    if (lang_data) {
      filterLabelArray = lang_data.map(val => {
        if (val.lang_code) {
          return val.lang_code;
        }
      })
    }


    let _this = modalData;
    let _id = modalData.state.probingMethod._id;

    let titleName = _id
      ? material_model_edit_probing_method
      : material_model_add_new_probing_method;



    return (
      <Modal
        isOpen={modalData.state.probingMethodAddEditModal}
        toggle={() => modalData.toggleProbingMethodAddEditModal()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleProbingMethodAddEditModal()}
          />
          <h4 className="bold-text  modal__title">{titleName}</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col xs={12} sm={12} md={12} className="">
              {row1 && (
                <>
                  <Row>
                    <Col xs={9} sm={9}>
                      <TextField
                        fullWidth
                        className="material-form__field "
                        label={material_masters_default_name}
                        name="name"
                        margin="normal"
                        onChange={modalData.handleInputProbingChange("name")}
                        value={row1.name}
                        error={row1.error && row1.error.includes("name")}
                      />
                      {row1.error && row1.error.includes("name") && (
                        <p className="error-message">
                          {material_masters_default_name_validate}
                        </p>
                      )}
                    </Col>

                    <Col xs={3} sm={3}>
                      <Button
                        title={"Add Name"}
                        color="success"
                        onClick={() => _this.addOtherMasterNameProbingMethod("lang_data")}
                        className={
                          "add-new-btn btn btn-outline-success"
                        }
                        outline={true}
                        style={{ marginTop: "30px" }}
                      >
                        <Icon>add</Icon>
                      </Button>
                    </Col>
                  </Row>


                  {lang_data.length > 0 &&
                    lang_data.map((value1, index1) => {
                      return (
                        <>
                          <Row key={index1}>
                            <Col xs={4} sm={4} md={4} >
                              <TextField
                                select
                                id={"standard-select-" + index1}
                                SelectProps={{
                                  MenuProps: {
                                    className: "select-menu-width",
                                  },
                                }}
                                style={{ width: "100%" }}
                                label={"Language"}
                                className="material-form__field"
                                value={value1.lang_code}
                                onChange={_this.handleInputOtherMasterNameProbingMethod(index1, "lang_code")}
                                name={"language"}
                                error={row1.error && row1.error.includes(index1 + "_lang_code")}
                              >
                                {Languages.filter(option => (!filterLabelArray.includes(option.code) || value1.lang_code == option.code)).map((lang) => (
                                  <MenuItem
                                    key={lang._id}
                                    value={lang.code}
                                  >
                                    {lang.language}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Col>
                            <Col xs={6} sm={6} md={6} >
                              <TextField
                                fullWidth
                                // id={"description_texts" + de_i}
                                label={"Name"}
                                value={value1.lang_text}
                                onChange={_this.handleInputOtherMasterNameProbingMethod(index1, "lang_text")}
                                error={row1.error && row1.error.includes(index1 + "_lang_text")}
                              />
                            </Col>
                            <Col xs={2} sm={2} md={2} >
                              <Button
                                // title={tooltip_remove}
                                color="success"
                                // onClick={() => _this.removeFieldInTestfamily("description", index1,)}
                                onClick={_this.removeOtherMasterNameProbingMethod(index1, 'remove')}
                                className={"system-btn clear-border remove-btn"}
                                outline={true}
                              >
                                <Icon>close</Icon>
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )
                    })}



                </>
              )}
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleProbingMethodAddEditModal()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.saveProbingMethod()}
            disabled={modalData.state.loading}
          >
            {language_savebtn}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  probingMethodDeleteModal: function (modalData) {
    const { t } = modalData.props;
    let row1 = modalData.state.master;

    let dltProbingName = modalData.state.dltProbingName;

    return (
      <Modal
        isOpen={modalData.state.probingMethodDeleteModal}
        toggle={() => modalData.toggleProbingMethodDeleteModal()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleProbingMethodDeleteModal()}
          />
          <h4 className="bold-text  modal__title">{delete_probing_method}</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col md={12} lg={12} className="theme-light">
              {delete_material_master_areyoumsg + " ( " + dltProbingName + " ) ?"}
            </Col>
          </Row>
        </div>

        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleProbingMethodDeleteModal()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.deleteProbingMethod()}
            disabled={modalData.state.loading}
          >
            {delete_staff_user_modal_delete}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  testTypeAddEditModal: function (modalData) {
    const { t } = modalData.props;
    let row1 = modalData.state.testType;
    //  console.log("row1 ", row1);
    let _id = modalData.state.testType._id;
    let Languages = t.languagecodeReducer.languagecodeLabelList

    let titleName = _id ? edit_test_family_type : add_new_test_family_type;

    return (
      <Modal
        isOpen={modalData.state.testTypeAddEditModal}
        toggle={() => modalData.toggleTestTypeAddEditModal()}
        className={`modal-dialog--success modal-dialog--header width__70`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleTestTypeAddEditModal()}
          />
          <h4 className="bold-text  modal__title">{titleName}</h4>
        </div>
        <div className="modal__body" style={customStyle}>
          <Row>
            <Col xs={12} sm={12} md={12} className="">
              {row1 && (
                <>
                  <Row>
                    <Col xs={12}>
                      <TextField
                        fullWidth
                        className="material-form__field "
                        label={test_masters_name}
                        name="name"
                        margin="normal"
                        onChange={modalData.handleInputTestTypeChange("name")}
                        value={row1.name}
                        error={row1.error && row1.error.includes("name")}
                      />
                      {row1.error && row1.error.includes("name") && (
                        <p className="error-message">
                          {test_masters_name_validate}
                        </p>
                      )}
                    </Col>
                  </Row>
                  <Button
                    title={add_type}
                    style={{ marginTop: "15px" }}
                    color="success"
                    onClick={() => modalData.addTestFamilyType()}
                    className={
                      "add-new-btn btn btn-outline-success btn btn-outline-success"
                    }
                    outline={true}
                  >
                    {add_new_btn}
                  </Button>
                  {row1.children &&
                    row1.children.map((value1, index1) => {
                      return (
                        <Row style={testFamilyDetailsBox} key={index1}>
                          <Col xs={10}>
                            <TextField
                              fullWidth
                              className="material-form__field "
                              label={test_masters_name}
                              name="name"
                              margin="normal"
                              onChange={modalData.handleInputNewTestTypeChange(
                                index1
                              )}
                              value={value1.name}
                              error={
                                value1.error && value1.error.includes("name")
                              }
                            />
                            {value1.error && value1.error.includes("name") && (
                              <p className="error-message">
                                {test_masters_name_validate}
                              </p>
                            )}
                          </Col>
                          <Col xs={2} sm={2} md={2} style={{ marginTop: "10px" }} >
                            <Button
                              title={tooltip_remove}
                              color="success"
                              onClick={() =>
                                modalData.removeTestFamilyType(index1)
                              }
                              className={"system-btn clear-border remove-btn"}
                              outline={true}
                              style={{ marginTop: "15px !important" }}
                            >
                              <Icon>delete</Icon>
                            </Button>
                          </Col>
                          {/* --------description----------- */}
                          <Col xs={6} sm={6} md={12} style={{ marginTop: "15px" }}>
                            <Row>
                              <Col xs={6} sm={6} md={2}>
                                <label style={{ color: "#E64823" }}>{description}</label>
                              </Col>
                              <Col xs={6} sm={6} md={10}>
                                <Button
                                  title={add_description}
                                  color="success"
                                  onClick={() => modalData.addFieldInTestfamily("description", index1)}
                                  className={
                                    "add-new-btn btn btn-outline-success"
                                  }
                                  outline={true}
                                >
                                  <Icon>add</Icon>
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                          {value1.description &&
                            value1.description.map((desc, de_i) => {
                              return (
                                <>
                                  <Col xs={3} sm={3} md={3} key={de_i} >
                                    <TextField
                                      select
                                      id={"standard-select-" + de_i}
                                      SelectProps={{
                                        MenuProps: {
                                          className: "select-menu-width",
                                        },
                                      }}
                                      style={{ width: "100%" }}
                                      label={language_setting_language}
                                      className="material-form__field"
                                      value={desc.lang}
                                      onChange={modalData.onChangeTestFamilyFiled(index1, "description", de_i, "lang")}
                                      name={"language"}
                                      error={value1.error && value1.error.includes(index1 + "_description_" + de_i + "_lang")}
                                    >
                                      {Languages.map((lang) => (
                                        <MenuItem
                                          key={lang._id}
                                          value={lang.code}
                                        >
                                          {lang.language}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </Col>
                                  <Col xs={7} sm={7} md={7} >
                                    <TextField
                                      fullWidth
                                      id={"description_texts" + de_i}
                                      label={description}
                                      value={desc.texts}
                                      onChange={modalData.onChangeTestFamilyFiled(index1, "description", de_i, "texts")}
                                      error={value1.error && value1.error.includes(index1 + "_description_" + de_i + "_texts")}
                                    />
                                  </Col>
                                  <Col xs={2} sm={2} md={2} >
                                    <Button
                                      title={tooltip_remove}
                                      color="success"
                                      onClick={() => modalData.removeFieldInTestfamily("description", index1, de_i)}
                                      className={"system-btn clear-border remove-btn"}
                                      outline={true}
                                    >
                                      <Icon>close</Icon>
                                    </Button>
                                  </Col>
                                </>
                              )
                            })
                          }
                          {/* --------description----------- */}
                          {/* --------Short description----------- */}
                          <Col xs={6} sm={6} md={12} style={{ marginTop: "15px" }}>
                            <Row>
                              <Col xs={6} sm={6} md={2}>
                                <label style={{ color: "#E64823" }}>{short_description}</label>
                              </Col>
                              <Col xs={6} sm={6} md={10}>
                                <Button
                                  title={add_short_description}
                                  color="success"
                                  onClick={() => modalData.addFieldInTestfamily("short_description", index1)}
                                  className={
                                    "add-new-btn btn btn-outline-success"
                                  }
                                  outline={true}
                                >
                                  <Icon>add</Icon>
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                          {value1.short_description &&
                            value1.short_description.map((sdesc, sde_i) => {
                              return (
                                <>
                                  <Col xs={3} sm={3} md={3} key={sde_i}>
                                    <TextField
                                      select
                                      id={"standard-select-" + sde_i}
                                      SelectProps={{
                                        MenuProps: {
                                          className: "select-menu-width",
                                        },
                                      }}
                                      style={{ width: "100%" }}
                                      label={language_setting_language}
                                      className="material-form__field"
                                      value={sdesc.lang}
                                      onChange={modalData.onChangeTestFamilyFiled(index1, "short_description", sde_i, "lang")}
                                      name={"language"}
                                      error={value1.error && value1.error.includes(index1 + "_short_description_" + sde_i + "_lang")}
                                    >
                                      {Languages.map((lang) => (
                                        <MenuItem
                                          key={lang._id}
                                          value={lang.code}
                                        >
                                          {lang.language}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </Col>
                                  <Col xs={7} sm={7} md={7} >
                                    <TextField
                                      fullWidth
                                      id={"description_texts" + sde_i}
                                      label={short_description}
                                      value={sdesc.texts}
                                      onChange={modalData.onChangeTestFamilyFiled(index1, "short_description", sde_i, "texts")}
                                      error={value1.error && value1.error.includes(index1 + "_short_description_" + sde_i + "_texts")}
                                    />
                                  </Col>
                                  <Col xs={2} sm={2} md={2} >
                                    <Button
                                      title={tooltip_remove}
                                      color="success"
                                      onClick={() => modalData.removeFieldInTestfamily("short_description", index1, sde_i)}
                                      className={"system-btn clear-border remove-btn"}
                                      outline={true}
                                    >
                                      <Icon>close</Icon>
                                    </Button>
                                  </Col>
                                </>
                              )
                            })
                          }
                          {/* --------Short description----------- */}
                          {/* --------Long description----------- */}
                          <Col xs={6} sm={6} md={12} style={{ marginTop: "15px" }}>
                            <Row>
                              <Col xs={6} sm={6} md={2}>
                                <label style={{ color: "#E64823" }}>{long_description}</label>
                              </Col>
                              <Col xs={6} sm={6} md={10}>
                                <Button
                                  title={add_long_description}
                                  color="success"
                                  onClick={() => modalData.addFieldInTestfamily("long_description", index1)}
                                  className={
                                    "add-new-btn btn btn-outline-success"
                                  }
                                  outline={true}
                                >
                                  <Icon>add</Icon>
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                          {value1.long_description &&
                            value1.long_description.map((ldesc, lde_i) => {
                              return (
                                <>
                                  <Col xs={3} sm={3} md={3} key={lde_i} >
                                    <TextField
                                      select
                                      id={"standard-select-" + lde_i}
                                      SelectProps={{
                                        MenuProps: {
                                          className: "select-menu-width",
                                        },
                                      }}
                                      style={{ width: "100%" }}
                                      label={language_setting_language}
                                      className="material-form__field"
                                      value={ldesc.lang}
                                      onChange={modalData.onChangeTestFamilyFiled(index1, "long_description", lde_i, "lang")}
                                      name={"language"}
                                      error={value1.error && value1.error.includes(index1 + "_long_description_" + lde_i + "_lang")}
                                    >
                                      {Languages.map((lang) => (
                                        <MenuItem
                                          key={lang._id}
                                          value={lang.code}
                                        >
                                          {lang.language}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </Col>
                                  <Col xs={7} sm={7} md={7} >
                                    <TextField
                                      fullWidth
                                      id={"description_texts" + lde_i}
                                      label={long_description}
                                      value={ldesc.texts}
                                      onChange={modalData.onChangeTestFamilyFiled(index1, "long_description", lde_i, "texts")}
                                      error={value1.error && value1.error.includes(index1 + "_long_description_" + lde_i + "_texts")}
                                    />
                                  </Col>
                                  <Col xs={2} sm={2} md={2} >
                                    <Button
                                      title={tooltip_remove}
                                      color="success"
                                      onClick={() => modalData.removeFieldInTestfamily("long_description", index1, lde_i)}
                                      className={"system-btn clear-border remove-btn"}
                                      outline={true}
                                    >
                                      <Icon>close</Icon>
                                    </Button>
                                  </Col>
                                </>
                              )
                            })
                          }
                          {/* --------Long description----------- */}
                          <Col xs={12} sm={12} md={12} style={{ marginTop: "15px" }}>
                            <label style={{ color: "#E64823" }}>{"Tags"}</label>
                            <ChipInput
                              value={value1.tags ? value1.tags : []}
                              onAdd={(tag) => modalData.handleChangeTestFamilyTags(tag, index1)}
                              onDelete={(tag, index) => modalData.handleDeleteChip(tag, index1, index)}
                            />
                          </Col>
                        </Row>
                      );
                    })}
                </>
              )}
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleTestTypeAddEditModal()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.saveTestType()}
            disabled={modalData.state.loading}
          >
            {language_savebtn}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  testTypeDeleteModal: function (modalData) {
    const { t } = modalData.props;
    let row1 = modalData.state.master;

    let testFamilydltName = modalData.state.testFamilydltName;

    return (
      <Modal
        isOpen={modalData.state.testTypeDeleteModal}
        toggle={() => modalData.toggleTestTypeDeleteModal()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleTestTypeDeleteModal()}
          />
          <h4 className="bold-text  modal__title">{delete_test_family_type}</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col md={12} lg={12} className="theme-light">
              {are_you_sure_you_want_to_delete + " ( " + testFamilydltName + " ) ?"}
            </Col>
          </Row>
        </div>

        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleTestTypeDeleteModal()}
          >
            {delete_staff_user_modal_cancel}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.deleteTestType()}
            disabled={modalData.state.loading}
          >
            {delete_staff_user_modal_delete}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  standardDisposalModal: function (modalData) {
    const { t } = modalData.props;
    let standardDesposal = modalData.state.standardDisposalArr;

    return (
      <Modal
        isOpen={modalData.state.standardDisposalModal}
        toggle={() => modalData.toggleStandardDesposalOrder()}
        className={`modal-dialog--success modal-dialog--header width__50`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleStandardDesposalOrder()}
          />
          <h4 className="bold-text  modal__title">{set_standard_order}</h4>
        </div>
        <div className="modal__body">
          <Row>
            {standardDesposal.length > 0 &&
              <Card style={{ width: "50%", margin: "0 auto" }}>
                <CardBody style={{ height: "auto", overflowY: "auto", overflowX: "hidden" }}>
                  <Col xs="12">
                    <DragDropContext onDragStart={modalData.onDragStart} onDragEnd={modalData.onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            className={classNames('list1', snapshot.isDraggingOver && 'draggingOver')}
                            {...provided.droppableProps}
                          >
                            {standardDesposal.map((item, index) => {
                              //let activeGraph = item.visible ? item.color : ''
                              let activeGraph = item.color
                              return (

                                <Draggable key={item.name} draggableId={`${item.name}-id`} index={index} isDragDisabled={item.visible == true ? false : false}>

                                  {(provided, snapshot) => {

                                    provided.draggableProps.style = { ...provided.draggableProps.style, backgroundColor: activeGraph, cursor: "pointer" };

                                    return (
                                      <div
                                        id={"btn_" + index}
                                        //onClick={(e) => modalData.visibleChartNew(index,item.visible)} 
                                        className={classNames('item1 btnRemoveContent', snapshot.isDragging && 'dragging')}
                                        style={provided.draggableProps.style}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        {item.name}
                                      </div>
                                    )
                                  }}
                                </Draggable>
                              )
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Col>
                </CardBody>
              </Card>
            }

            {standardDesposal.length == 0 &&
              <h4 style={{ margin: "0 auto" }}>Sorry!, No any standard disposal exist.</h4>
            }
          </Row>

        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            style={{ width: "47%" }}
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleStandardDesposalOrder()}
          >
            {cancel_btn}
          </Button>
          <Button
            style={{ width: "47%" }}
            color={"success"}
            onClick={() => modalData.saveStandardDesposalOrder()}
            disabled={modalData.state.loading}
          >
            {language_savebtn}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );

  },



  desposalAddEditModal: function (modalData) {
    const { t } = modalData.props;
    let desposal = modalData.state.desposal;
    let _id = modalData.state.desposal._id;

    let titleName = _id ? edit_desposal : add_new_desposal;

    const columns = [
      {
        name: user_request_srno,
        options: {
          setCellProps: () => ({ style: { minWidth: "2rem", maxWidth: "2rem" } })
        },
      },
      {
        name: desposal_testfamily_name,
        options: {
          setCellProps: () => ({ style: { minWidth: "15rem", maxWidth: "15rem" } })
        },
      },
      { name: desposal_keys },
      // { name: description },
      // { name: short_description },
      // { name: long_description },
      // { name: desposal_tags },
    ];

    const select2ColourStyles = {
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
              ? "default"
              : isFocused
                ? "#E64823"
                : null,
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled && (isSelected ? "#ff0000" : "#e64823a6"),
          },
        };
      },
    };

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      print: false,
      download: false,
      viewColumns: false,
      filter: false,
      responsive: "stacked",
      search: false,
      textLabels: {
        body: {
          noMatch: no_match_record_found,
          toolTip: sort_text
        },
        pagination: {
          rowsPerPage: rows_per_page_text,
        },
        toolbar: {
          search: search_text
        },
      },
    };

    let testFamilyOptions = []
    if (desposal.test_list) {
      desposal.test_list.map((test, i) => {
        testFamilyOptions.push({ label: test.name, value: test._id })
      })
    }
    let testFamilySelectedOptions = []
    if (modalData.state.testFamilyList) {
      modalData.state.testFamilyList.map((test, i) => {
        testFamilySelectedOptions.push({ label: test.name, value: test._id })
      })
    }


    return (
      <Modal
        isOpen={modalData.state.desposalAddEditModal}
        //toggle={() => modalData.toggleDesposalAddEditModal()}
        className={`modal-dialog--success modal-dialog--header width__70`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleDesposalAddEditModal()}
          />
          <h4 className="bold-text  modal__title">{titleName}</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col xs={12} sm={12} md={12} className="">
              {modalData.state.getDisposalDetailsLoading && (
                <div className="div-spinner"><Spinner size="sm" className="btn-spinner spinner_color spinner_size" /></div>
              )}
              {!modalData.state.getDisposalDetailsLoading && desposal && (
                <>
                  <Row>
                    <Col xs={12}>
                      <TextField
                        required
                        fullWidth
                        className="material-form__field "
                        label={test_masters_name}
                        name="name"
                        margin="normal"
                        onChange={modalData.handleInputDesposalChange("name")}
                        value={desposal.name}
                        error={
                          desposal.error && desposal.error.includes("name")
                        }
                      />
                      {desposal.error && desposal.error.includes("name") && (
                        <span className="error-message">
                          {test_masters_name_validate}
                        </span>
                      )}
                      {desposal.error && desposal.error.includes("nameexist") && (
                        <span className="error-message">
                          {test_masters_disposal_name_already_exist}
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <PlacesAutocomplete
                        required
                        value={desposal.address}
                        onChange={modalData.handleChangeaddress("address")}
                        onSelect={modalData.handleSelect}
                        id="address"
                        name="address"
                        multiline
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <React.Fragment>
                            <TextField
                              fullWidth
                              {...getInputProps({
                                className: "location-search-input",
                              })}
                              label={Address_label}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>{Lang_Loadingtxt}</div>}
                              {suggestions.map((suggestion, i) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                const style = suggestion.active
                                  ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                  : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                                return (
                                  <div
                                    key={i}
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <MenuItem value={""}>
                                      {suggestion.description}
                                    </MenuItem>
                                  </div>
                                );
                              })}
                            </div>
                          </React.Fragment>
                        )}
                      </PlacesAutocomplete>
                      {/* {desposal.error && desposal.error.includes("address") && (
                        <span className="error-message">{pls_add_address}</span>
                      )} */}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <TextField
                        margin="dense"
                        error={
                          desposal.error && desposal.error.includes("country")
                        }
                        fullWidth
                        label={user_request_country}
                        value={desposal.country}
                        onChange={modalData.handleInputDesposalChange(
                          "country"
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <ColorPicker
                        required
                        fullWidth
                        value={desposal.color}
                        InputProps={{ value: desposal.color }}
                        label={color_label}
                        TextFieldProps={{
                          style: {
                            width: "100%",
                          },
                        }}
                        className="material-form__field full__width"
                        onChange={modalData.handleInputDesposalChange("color")}
                      />
                      {desposal.error && desposal.error.includes("color") && (
                        <span className="error-message">{color_validate}</span>
                      )}
                      {desposal.error && desposal.error.includes("colorhex") && (
                        <span class="error-message">{color_hexvalidate}</span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} style={{ margin: "10px 0px 0px -15px" }}>
                      <FormControlLabel
                        margin="dense"
                        label={Standard_label}
                        labelPlacement="start"
                        control={<Checkbox style={{ color: '#c00' }} />}
                        checked={desposal.standard}
                        onChange={modalData.handleInputDesposalChange(
                          "standard",
                          desposal.standard
                        )}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "20px" }}>
                    <Col xs={12} style={{ margin: "5px 0px 15px 0" }}>
                      <TextField
                        style={{ width: "38%" }}
                        label="Search by TestFamily or Pollutant name"
                        value={modalData.state.searchTestFamilyTexts}
                        onChange={(event) => modalData.handleTestFamilySearch(event)}
                        onBlur={(event) => modalData.searchTestFamily(event)}
                        disabled={modalData.state.loading}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <IconButton>
                                {modalData.state.loading && (
                                  <Spinner size="sm" className="btn-spinner" style={{ color: "#E64823" }} />
                                )}
                                <SearchIcon onClick={(event) => modalData.searchTestFamily(event)} disabled={modalData.state.loading} />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Col>
                    <Col xs={12}>
                      <Select2 options={testFamilySelectedOptions}
                        isMulti
                        styles={select2ColourStyles}
                        name="testFamilys"
                        placeholder="Select TestFamily *"
                        value={testFamilyOptions}
                        onChange={modalData.handleTestFamilyChange}
                        closeMenuOnSelect={false}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        theme={theme => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: '#e34a26b0',
                            primary: '#e34a26',
                          },
                        })}
                      />
                      {desposal.error &&
                        desposal.error.includes("test_list") && (
                          <span className="error-message">
                            {please_enter_test}
                          </span>
                        )}
                    </Col>
                  </Row>
                  {testFamilyOptions.length != 0 &&
                    <Row>
                      <Col xs={12} sm={12} md={12} style={{ marginTop: "15px", padding: "0px", zIndex: "0" }}>
                        <MUIDataTable
                          data={modalData.renderSelectedTestFamilyDetails()}
                          columns={columns}
                          options={options}
                        />
                      </Col>
                    </Row>
                  }
                </>
              )}
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleDesposalAddEditModal()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.saveDesposal()}
            disabled={modalData.state.loading}
          >
            {language_savebtn}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  desposalDeleteModal: function (modalData) {
    const { t } = modalData.props;
    let row1 = modalData.state.master;

    let dltDesposalName = modalData.state.dltDesposalName;

    return (
      <Modal
        isOpen={modalData.state.desposalDeleteModal}
        toggle={() => modalData.toggleDesposalDeleteModal()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleDesposalDeleteModal()}
          />
          <h4 className="bold-text  modal__title">{delete_desposal}</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col md={12} lg={12} className="theme-light">
              {are_you_sure_you_want_to_delete + " ( " + dltDesposalName + " ) ?"}
            </Col>
          </Row>
        </div>

        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleDesposalDeleteModal()}
          >
            {delete_staff_user_modal_cancel}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.deleteDesposal()}
            disabled={modalData.state.loading}
          >
            {delete_staff_user_modal_delete}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  thresholdModal: function (modalData) {
    const { t } = modalData.props;
    let row1 = modalData.state.master;

    return (
      <Modal
        isOpen={modalData.state.thresholdModal}
        toggle={() => modalData.toggleThresholdModal()}
        className={`modal-dialog--success modal-dialog--header width__70`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleThresholdModal()}
          />
          <h4 className="bold-text  modal__title">{thresholdModal_title}</h4>
        </div>
        <div className="modal__body">
          <Row>
            <table style={{ textAlign: "center", overflowX: "auto" }}>
              <tbody>
                <tr key={50}>{modalData.renderThresholdHeader()}</tr>
                {modalData.renderThreshold()}
              </tbody>
            </table>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleThresholdModal()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.saveThreshold()}
            disabled={modalData.state.loading}
          >
            {save_btn}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  thresholdValueModal: function (modalData) {
    const { t } = modalData.props;
    let row1 = modalData.state.master;

    return (
      <Modal
        isOpen={modalData.state.thresholdValueModal}
        toggle={() => modalData.toggleThresholdValueModal()}
        className={`modal-dialog--success modal-dialog--header width__70`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleThresholdValueModal()}
          />
          <h4 className="bold-text  modal__title">{thresholdModal_title} - {modalData.state.desposal.name}</h4>
        </div>
        <div className="modal__body" style={customStyle}>
          <Row>
            {modalData.state.thresholdValueModal &&
              <table style={{ overflowX: "auto" }}>
                <tbody>
                  {/* <tr key={50}>{modalData.renderThresholdValueHeader()}</tr> */}
                  <tr key={50}>{modalData.renderNote()}</tr>
                  {modalData.renderThresholdValue()}
                </tbody>
              </table>
            }
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleThresholdValueModal()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.saveThresholdValue()}
            disabled={modalData.state.loading}
          >
            {save_btn}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  testRuleModal: function (modalData) {
    const { t } = modalData.props;
    let row1 = modalData.state.master;
    let testRule = modalData.state.testRule;
    let formula = modalData.state.testRule.formula;
    let childArray = modalData.state.childArray;
    let newChildArray = [];
    let childArrayForRule = [];
    let value = 15;
    const select2ColourStyles = {
      menuList: base => ({
        ...base,
        height: '150px',
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
              ? "default"
              : isFocused
                ? "#E64823"
                : null,
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled && (isSelected ? "#ff0000" : "#e64823a6"),
          },
        };
      },
    };

    let childArraySelectedOptions = []
    // if (childArray) {
    //   childArray.map((test, i) => {
    //     childArraySelectedOptions.push({ label: test.name, value: test.id })
    //   })
    // }


    if (modalData.state.desposal.test_list.length > 0) {
      modalData.state.desposal.test_list.map((pollutant, pindex) => {
        let myObj = {};
        myObj.label = pollutant.name;
        if (pollutant.children.length > 0) {
          let temparr = [];
          pollutant.children.map((children, cindex) => {
            let arr = {};
            if (children.id != testRule.child_id) {
              Object.assign(arr, { label: children.name, value: children.id });
              temparr.push(arr);
            }
          })
          myObj.options = temparr;
        }
        childArraySelectedOptions.push(myObj);
      })
    }




    let childArrayOptions = []
    if (formula.test) {
      formula.test.map((test, i) => {
        childArrayOptions.push({ label: test.name, value: test.id })
      })
    }

    let testId = testRule.child_id;
    let des_id = testRule.desposal_id;
    let typeId = testRule.test_id
    let child = testRule.desposalArray.test_list.filter((l) => l._id == testRule.test_id)[0].children;
    let nolimit_finalvalue = testRule.nolimit_finalvalue;

    return (
      <Modal
        isOpen={modalData.state.testRuleModal}
        toggle={() => modalData.toggleTestRuleCloseModal()}
        className={`modal-dialog--success modal-dialog--header width__70`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleTestRuleCloseModal()}
          />
          <h4 className="bold-text  modal__title">{threshold_Add_Test_Rule} {"of"} {testRule.child_name}</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col xs={3} sm={3} md={3} className="">
              <RadioGroup
                aria-label="threshold"
                name="radio1"
                value={modalData.state.radioValue}
                onChange={modalData.handleChangeRadio("radioValue")}
              >
                <FormControlLabel
                  key={"radio1"}
                  value="radio1"
                  control={<Radio style={{ color: '#c00' }} />}
                  label={"Add Threshold Value"}
                />
                <FormControlLabel
                  key={"radio2"}
                  value="radio2"
                  control={<Radio style={{ color: '#c00' }} />}
                  label={"Multiple Pollutant"}
                />
                <FormControlLabel
                  key={"radio3"}
                  value="radio3"
                  control={<Radio style={{ color: '#c00' }} />}
                  label={"No Limit"}
                />
                <FormControlLabel
                  key={"radio4"}
                  value="radio4"
                  control={<Radio style={{ color: '#c00' }} />}
                  label={threshold_Percentage}
                />
              </RadioGroup>
            </Col>
            <Col xs={9} sm={9} md={9} className="">
              <Row key={1}>
                {modalData.state.radioValue &&
                  modalData.state.radioValue === "radio1" && (
                    <>
                      <Col xs={4} sm={4} md={4} className="">

                        <TextField
                          key={testId}
                          variant="outlined"
                          name={"name_" + des_id + "_" + testId}
                          type="number"
                          placeholder="Threshold Value"
                          onChange={(event) => modalData.handleChangeRule(child, typeId, testId, des_id, event)}
                          onKeyPress={(e) => modalData.keyPress(e)}
                          value={modalData.state["name_" + des_id + "_" + testId]}
                          style={{ marginRight: "5px" }}
                          onBlur={(event) => modalData.changedTestFamily(child, typeId, testId, des_id, event)}
                        />
                        {testRule.error &&
                          testRule.error.includes("radio1_thresholdvalue") && (
                            <span className="error-message">
                              {"Please enter threshold value"}
                            </span>
                          )}
                        {/* <TextField
                        select
                        id="standard-select-currency"
                        SelectProps={{
                          MenuProps: {
                            className: "select-menu-width",
                          },
                        }}
                        fullWidth
                        label={threshold_Formula}
                        className="material-form__field"
                        value={formula.condition}
                        onChange={modalData.handleChangeTestFormula1(
                          "radio1",
                          "condition"
                        )}
                      >
                        <MenuItem key={"1"} value={"less"}>
                          {threshold_Less_than}{" "}
                        </MenuItem>
                        <MenuItem key={"2"} value={"greter"}>
                          {threshold_Greater_than}{" "}
                        </MenuItem>
                      </TextField>
                      {testRule.error &&
                        testRule.error.includes("radio1_condition") && (
                          <span className="error-message">
                            {threshold_Pls_select_formula}
                          </span>
                        )} */}
                      </Col>
                    </>
                  )}
              </Row>
              <Row key={2} style={{ marginTop: "25px" }}>
                {modalData.state.radioValue &&
                  modalData.state.radioValue === "radio2" && (
                    <>
                      <Col xs={4} sm={4} md={4} className="">
                        <TextField
                          key={testId}
                          variant="outlined"
                          name={"name_" + des_id + "_" + testId}
                          //disabled={true}
                          type="number"
                          placeholder="Threshold Value"
                          onChange={(event) => modalData.handleChangeRule(child, typeId, testId, des_id, event)}
                          onKeyPress={(e) => modalData.keyPress(e)}
                          value={modalData.state["name_" + des_id + "_" + testId]}
                          style={{ marginRight: "5px" }}
                          onBlur={(event) => modalData.changedTestFamily(child, typeId, testId, des_id, event)}
                        />
                        {testRule.error &&
                          testRule.error.includes("radio2_thresholdvalue") && (
                            <span className="error-message">
                              {"Please enter threshold value"}
                            </span>
                          )}
                      </Col>

                      <Col xs={8}>
                        <Select2 options={childArraySelectedOptions}
                          isMulti
                          styles={select2ColourStyles}
                          name="testFamilys"
                          value={childArrayOptions}
                          closeMenuOnSelect={false}
                          onChange={modalData.handleChangeTestFormula1SrcInp}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Select Multiple Pollutant..."
                          theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: '#e34a26b0',
                              primary: '#e34a26',
                            },
                          })}
                        />
                        {testRule.error &&
                          testRule.error.includes("radio2_test") && (
                            <span className="error-message">
                              {threshold_pls_Select_Test}
                            </span>
                          )}
                      </Col>

                      {/* <Col xs={4} sm={4} md={4} style={{ marginTop: "10px" }}>
                        <FormControl style={{ display: "flex" }}>
                          <InputLabel id="mutiple-child-name-label">
                            {threshold_Select_as_many_tests}
                          </InputLabel>
                          <Select
                            margin="dense"
                            labelId="mutiple-child-name-label"
                            isSearchable={true}
                            isMulti={true}
                            multiple
                            label={threshold_Test}
                            displayEmpty
                            fullWidth
                            className="material-form__field"
                            value={formula.test ? formula.test : []}
                            onChange={modalData.handleChangeTestFormula1(
                              "radio2",
                              "test"
                            )}
                            error={
                              testRule.error &&
                              testRule.error.includes("radio2_test")
                            }
                            renderValue={(event) =>
                              modalData.selectedChildList(event)
                            }
                          >
                            {childArray &&
                              childArray.map((child, index) => (
                                <MenuItem
                                  key={child.id}
                                  value={child}
                                  name={child.name}
                                >
                                  <ListItemText primary={child.name} />
                                </MenuItem>
                              ))}
                          </Select>
                          {testRule.error &&
                            testRule.error.includes("radio2_test") && (
                              <span className="error-message">
                                {threshold_pls_Select_Test}
                              </span>
                            )}
                        </FormControl>
                      </Col> */}




                      {/* <Col xs={4} sm={4} md={4} className="">
                        <TextField
                          select
                          id="standard-select-currency"
                          SelectProps={{
                            MenuProps: {
                              className: "select-menu-width",
                            },
                          }}
                          fullWidth
                          label={threshold_Formula}
                          className="material-form__field"
                          value={formula.condition}
                          onChange={modalData.handleChangeTestFormula1(
                            "radio2",
                            "condition"
                          )}
                        >
                          <MenuItem key={"1"} value={"less"}>
                            {threshold_Less_than}{" "}
                          </MenuItem>
                          <MenuItem key={"2"} value={"greter"}>
                            {threshold_Greater_than}{" "}
                          </MenuItem>
                        </TextField>
                        {testRule.error &&
                          testRule.error.includes("radio2_condition") && (
                            <span className="error-message">
                              {threshold_Pls_select_formula}
                            </span>
                          )}
                      </Col> */}
                    </>
                  )}
              </Row>

              <Row key={3} style={{ marginTop: "75px" }}>
                {modalData.state.radioValue &&
                  modalData.state.radioValue === "radio3" && (
                    <>
                      <h4 className="bold-text  modal__title">{"Any pollutant value will match"}</h4>
                      <FormControlLabel
                        label={final_value}
                        control={
                          <Checkbox color="primary" style={{ color: "#c00" }} />
                        }
                        checked={nolimit_finalvalue}
                        disabled={false}
                        onChange={modalData.handleChangeFinalValue("nolimit_finalvalue")}
                        labelPlacement="end"
                        style={{ marginLeft: '7%', marginTop: '-1%' }}
                      />

                    </>
                  )}
              </Row>


              <Row key={4} style={{ marginTop: "33px" }}>
                {modalData.state.radioValue &&
                  modalData.state.radioValue === "radio4" && (
                    <>
                      <Col xs={3} sm={3} md={3} className="">
                        <TextField
                          select
                          id="standard-select-currency"
                          SelectProps={{
                            MenuProps: {
                              className: "select-menu-width",
                            },
                          }}
                          fullWidth
                          label={threshold_Formula}
                          className="material-form__field"
                          value={formula.condition}
                          onChange={modalData.handleChangeTestFormula1(
                            "radio4",
                            "condition"
                          )}
                        >
                          <MenuItem key={"1"} value={"less"}>
                            {threshold_Less_than}{" (<) "}
                          </MenuItem>
                          <MenuItem key={"2"} value={"greter"}>
                            {threshold_Greater_than}{" (>) "}
                          </MenuItem>
                        </TextField>
                        {testRule.error &&
                          testRule.error.includes("radio4_condition") && (
                            <span className="error-message">
                              {threshold_Pls_select_formula}
                            </span>
                          )}
                      </Col>
                      <Col xs={3} sm={3} md={3} className="">
                        <TextField
                          id="standard-select-currency"
                          number
                          SelectProps={{
                            MenuProps: {
                              className: "select-menu-width",
                            },
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                          }}
                          fullWidth
                          label={threshold_Percentage}
                          className="material-form__field"
                          value={formula.percentage}
                          onChange={modalData.handleChangeTestFormula1(
                            "radio4",
                            "percentage"
                          )}
                        ></TextField>
                        {testRule.error &&
                          testRule.error.includes("radio4_percentage") && (
                            <span className="error-message">
                              {threshold_Pls_select_percentage}
                            </span>
                          )}
                      </Col>
                      <Col xs={6} sm={6} md={6} className="">
                        {/* <FormControl style={{ display: "flex" }}>
                          <InputLabel
                            id="mutiple-child-name-label-radio4"
                            shrink={formula.test[0] ? true : false}
                          >
                            {threshold_Select_Test}
                          </InputLabel>
                          <Select
                            margin="dense"
                            labelId="mutiple-child-name-label-radio4"
                            label={threshold_Test}
                            displayEmpty
                            fullWidth
                            className="material-form__field"
                            value={formula.test[0] ? formula.test[0] : null}
                            onChange={modalData.handleChangeTestFormula1(
                              "radio4",
                              "test"
                            )}
                            error={
                              testRule.error &&
                              testRule.error.includes("radio4_test")
                            }
                            renderValue={(event) =>
                              modalData.selectedChildList(event)
                            }
                          >
                            {childArray &&
                              childArray.map((child, index) => (
                                <MenuItem
                                  key={child.id}
                                  value={child}
                                  name={child.name}
                                >
                                  <ListItemText primary={child.name} />
                                </MenuItem>
                              ))}
                          </Select>
                          {testRule.error &&
                            testRule.error.includes("radio4_test") && (
                              <span className="error-message">
                                {threshold_pls_Select_Test}
                              </span>
                            )}
                        </FormControl> */}

                        <Select2 options={childArraySelectedOptions}
                          isMulti={false}
                          styles={select2ColourStyles}
                          name="testFamilys"
                          value={childArrayOptions}
                          onChange={modalData.handleChangeTestFormula1SrcInp}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Select a Pollutant..."
                          theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: '#e34a26b0',
                              primary: '#e34a26',
                            },
                          })}
                        />
                        {testRule.error &&
                          testRule.error.includes("radio4_test") && (
                            <span className="error-message">
                              {"Please select a pollutant"}
                            </span>
                          )}

                      </Col>
                    </>
                  )}
              </Row>
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleTestRuleCloseModal()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.saveTestRule()}
            disabled={modalData.state.loading}
          >
            {save_btn}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  thresholdFormulaModal: function (modalData) {
    const { t } = modalData.props;
    let row1 = modalData.state.master;

    let childArray = modalData.state.childArray;
    let newChildArray = [];
    childArray.map((val, inx) => {
      let value = { value: val.id };
      let label = { label: val.name };
      let newObject = Object.assign(label, value);
      newChildArray.push(newObject);
    });
    return (
      <Modal
        isOpen={modalData.state.thresholdFormulaModal}
        toggle={() => modalData.toggleThresholdFormulaCloseModal()}
        className={`modal-dialog--success modal-dialog--header width__70`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleThresholdFormulaCloseModal()}
          />
          <h4 className="bold-text  modal__title">
            {thresholdFormulaModal_title}
          </h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col xs={3} sm={3} md={3} className="">
              <RadioGroup
                aria-label="threshold"
                name="radio1"
                value={modalData.state.radioValue}
                onChange={modalData.handleChangeRadio("radioValue")}
              >
                <FormControlLabel
                  color={"success"}
                  key={"radio1"}
                  value="radio1"
                  control={<Radio color={"success"} />}
                  label={threshold_Less_than_or_greater_than}
                />
                <FormControlLabel
                  color={"success"}
                  key={"radio2"}
                  value="radio2"
                  control={<Radio color={"success"} />}
                  label={threshold_Percentage}
                />
                <FormControlLabel
                  color={"success"}
                  key={"radio3"}
                  value="radio3"
                  control={<Radio color={"success"} />}
                  label={threshold_Sum_of_all_test}
                />
              </RadioGroup>
            </Col>
            <Col xs={9} sm={9} md={9} className="">
              <Row key={1}>
                {modalData.state.radioValue &&
                  modalData.state.radioValue === "radio1" && (
                    <>
                      <Col xs={4} sm={4} md={4} className="">
                        <TextField
                          select
                          id="standard-select-currency"
                          SelectProps={{
                            MenuProps: {
                              className: "select-menu-width",
                            },
                          }}
                          fullWidth
                          label={threshold_Formula}
                          className="material-form__field"
                          value={modalData.state.radio1.lg}
                          onChange={modalData.handleChangeRadioValue1(
                            "radio1",
                            "lg"
                          )}
                        >
                          <MenuItem key={"1"} value={"less"}>
                            {threshold_Less_than}{" "}
                          </MenuItem>
                          <MenuItem key={"2"} value={"greater"}>
                            {threshold_Greater_than}{" "}
                          </MenuItem>
                        </TextField>
                      </Col>
                    </>
                  )}
              </Row>
              <Row key={2} style={{ marginTop: "50px" }}>
                {modalData.state.radioValue &&
                  modalData.state.radioValue === "radio2" && (
                    <>
                      <Col xs={3} sm={3} md={3} className="">
                        <TextField
                          select
                          id="select-material-method"
                          SelectProps={{
                            MenuProps: {
                              className: "select-menu-width",
                            },
                          }}
                          style={{ width: "100%" }}
                          label={threshold_Test}
                          className="material-form__field"
                          value={modalData.state.radio2.val}
                          onChange={modalData.handleChangeRadioValue2(
                            "radio2",
                            "val"
                          )}
                        >
                          {modalData.state.childArray &&
                            modalData.state.childArray.map((value1, inx1) => (
                              <MenuItem key={inx1} value={value1.id}>
                                {value1.name}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Col>
                      <Col xs={3} sm={3} md={3} className="">
                        <TextField
                          select
                          id="standard-select-currency"
                          SelectProps={{
                            MenuProps: {
                              className: "select-menu-width",
                            },
                          }}
                          fullWidth
                          label={threshold_Formula}
                          className="material-form__field"
                          value={modalData.state.radio2.lg}
                          onChange={modalData.handleChangeRadioValue2(
                            "radio2",
                            "lg"
                          )}
                        >
                          <MenuItem key={"1"} value={"less"}>
                            {threshold_Less_than}{" "}
                          </MenuItem>
                          <MenuItem key={"2"} value={"greater"}>
                            {threshold_Greater_than}{" "}
                          </MenuItem>
                        </TextField>
                      </Col>
                      <Col xs={3} sm={3} md={3} className="">
                        <TextField
                          id="standard-select-currency"
                          number
                          SelectProps={{
                            MenuProps: {
                              className: "select-menu-width",
                            },
                          }}
                          fullWidth
                          label={threshold_Percentage}
                          className="material-form__field"
                          value={modalData.state.radio2.per}
                          onChange={modalData.handleChangeRadioValue2(
                            "radio2",
                            "per"
                          )}
                        ></TextField>
                      </Col>
                      <Col xs={3} sm={3} md={3} className="">
                        <TextField
                          disabled
                          id="standard-select-currency"
                          SelectProps={{
                            MenuProps: {
                              className: "select-menu-width",
                            },
                          }}
                          fullWidth
                          label={threshold_Value}
                          className="material-form__field"
                          value={modalData.state.radio2.finalVal}
                        ></TextField>
                      </Col>
                    </>
                  )}
              </Row>
              <Row key={3} style={{ marginTop: "60px" }}>
                {modalData.state.radioValue &&
                  modalData.state.radioValue === "radio3" && (
                    <>
                      <Col xs={4} sm={4} md={4} style={{ marginTop: "10px" }}>
                        <MultiSelect
                          options={newChildArray}
                          value={modalData.state.radio3.multiTest}
                          onChange={modalData.handleChangeRadioValue3(
                            "radio3",
                            "multiTest"
                          )}
                        />
                      </Col>
                      <Col xs={4} sm={4} md={4} className="">
                        <TextField
                          select
                          id="standard-select-currency"
                          SelectProps={{
                            MenuProps: {
                              className: "select-menu-width",
                            },
                          }}
                          fullWidth
                          label={threshold_Formula}
                          className="material-form__field"
                          value={modalData.state.radio3.lg}
                          onChange={modalData.handleChangeRadioValue3(
                            "radio3",
                            "lg"
                          )}
                        >
                          <MenuItem key={"1"} value={"less"}>
                            {threshold_Less_than}{" "}
                          </MenuItem>
                          <MenuItem key={"2"} value={"greater"}>
                            {threshold_Greater_than}{" "}
                          </MenuItem>
                        </TextField>
                      </Col>
                      <Col xs={4} sm={4} md={4} className="">
                        <TextField
                          disabled
                          id="standard-select-currency"
                          SelectProps={{
                            MenuProps: {
                              className: "select-menu-width",
                            },
                          }}
                          fullWidth
                          label={threshold_Sum}
                          className="material-form__field"
                          value={modalData.state.radio3.sum}
                        ></TextField>
                      </Col>
                    </>
                  )}
              </Row>
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            color={"success"}
            onClick={() => modalData.toggleThresholdFormulaCloseModal()}
            disabled={modalData.state.loading}
          >
            {common_btn_okay}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  testAddEditModal: function (modalData) {
    const { t } = modalData.props;
    let row1 = modalData.state.test;
    let _id = modalData.state.test._id;

    let titleName = _id
      ? testAddEditModal_edit_title
      : testAddEditModal_add_title;

    return (
      <Modal
        isOpen={modalData.state.testAddEditModal}
        toggle={() => modalData.toggleTestAddEditModal()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleTestAddEditModal()}
          />
          <h4 className="bold-text  modal__title">{titleName}</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col xs={12} sm={12} md={12} className="">
              {row1 && (
                <>
                  <Row>
                    <Col xs={6}>
                      <TextField
                        fullWidth
                        className="material-form__field "
                        label={testAddEditModal_name}
                        name="name"
                        margin="normal"
                        onChange={modalData.handleInputTestChange("name")}
                        value={row1.name}
                        error={row1.error && row1.error.includes("name")}
                      />
                      {row1.error && row1.error.includes("name") && (
                        <p className="error-message">
                          {testAddEditModal_pls_add_name}
                        </p>
                      )}
                    </Col>
                    <Col xs={6}>
                      <TextField
                        select
                        id="standard-select-currency"
                        margin="normal"
                        SelectProps={{
                          MenuProps: {
                            className: "select-menu-width",
                          },
                        }}
                        fullWidth
                        label={testAddEditModal_Test_Family_Type}
                        className="material-form__field"
                        onChange={modalData.handleInputTestChange("type")}
                        value={row1.type}
                        error={row1.error && row1.error.includes("type")}
                      >
                        {modalData.props.masterReducer.testTypeList &&
                          modalData.props.masterReducer.testTypeList.map(
                            (option) => (
                              <MenuItem key={option._id} value={option._id}>
                                {option.name}
                              </MenuItem>
                            )
                          )}
                      </TextField>
                      {row1.error && row1.error.includes("type") && (
                        <span className="error-message">
                          {testAddEditModal_pls_add_type}
                        </span>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleTestAddEditModal()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.saveTest()}
            disabled={modalData.state.loading}
          >
            {save_btn}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  projectTokenPriceModal: function (modalData) {
    const { t } = modalData.props;
    let row1 = modalData.state.projectTokenPrice;

    return (
      <Modal
        isOpen={modalData.state.projectTokenPriceModal}
        toggle={() => modalData.toggleAddEditProjectTokenPrice()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleAddEditProjectTokenPrice()}
          />
          <h4 className="bold-text  modal__title">{titleName}</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col xs={12} sm={12} md={12} className="">
              {row1 && (
                <>
                  <Row>
                    <Col className="margin__top">
                      <p>{token_price}</p>
                      <NumericInput
                        fullWidth
                        className="form-control material-form__field"
                        min={0}
                        max={1000}
                        step={1}
                        precision={0}
                        size={5}
                        maxLength={4}
                        margin="normal"
                        onChange={modalData.handleInputProjectTokenChange(
                          "tokenprice"
                        )}
                        value={row1.tokenprice}
                        error={row1.error && row1.error.includes("tokenprice")}
                      />
                      {row1.error && row1.error.includes("tokenprice") && (
                        <p className="error-message">
                          {token_price_add_valid_price}
                        </p>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleAddEditProjectTokenPrice()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.saveProjectTokenPrice()}
            disabled={modalData.state.loading}
          >
            {language_savebtn}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  testDeleteModal: function (modalData) {
    const { t } = modalData.props;
    let row1 = modalData.state.master;

    return (
      <Modal
        isOpen={modalData.state.testDeleteModal}
        toggle={() => modalData.toggleTestDeleteModal()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleTestDeleteModal()}
          />
          <h4 className="bold-text  modal__title">{testDeleteModal_title}</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col md={12} lg={12} className="theme-light">
              {testDeleteModal_are_you_msg}
            </Col>
          </Row>
        </div>

        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleTestDeleteModal()}
          >
            {delete_staff_user_modal_cancel}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.deleteTest()}
            disabled={modalData.state.loading}
          >
            {delete_staff_user_modal_delete}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  acceptProjectTokenModalNew: function (modalData) {
    const { t } = modalData.props;
    let total = 0;

    let acceptTokenRequestName = (typeof modalData.state.requestStatusData != 'undefined' && modalData.state.requestStatusData != null) ? modalData.state.requestStatusData.requestid : "";

    return (
      <Modal
        isOpen={modalData.state.acceptProjectTokenModalNew}
        toggle={() => modalData.toggleAcceptProjectTokenNew()}
        className={`modal-dialog--success modal-dialog--header`}
        style={{ maxWidth: "700px" }}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={modalData.toggleAcceptProjectTokenNew}
          />
          <h4 className="bold-text  modal__title">
            {accept_licence_request_modal_title}
          </h4>
        </div>
        <div className="modal__body">
          {accept_token_request_modal_message + " ( " + acceptTokenRequestName + " ) ?"}
          <Row className="margin__top">
            {modalData.state.tokenRequestDetails &&
              modalData.state.tokenRequestDetails.map((val, i) => {
                total += Math.abs(val.price * val.count);
                return (
                  <Col xs={12} key={i}>
                    <Row>
                      <Col xs={4} sm={4} md={4}>
                        {val.tokenname}{" - ( "}{val.count} {" )"}
                      </Col>

                      <Col xs={3} sm={3} md={3}>
                        {" "}
                        ${val.price} / Token
                      </Col>
                      <Col xs={2} sm={2} md={2}>
                        ${Math.abs(val.price * val.count)}
                      </Col>
                    </Row>
                  </Col>
                );
              })}
          </Row>
          <Row className="margin__top">
            <Col xs={12}>
              <Row style={{ paddingTop: "8px", float: "right", marginRight: "35%", borderTop: "1px solid #d7dae0" }}>
                {total_amount}: ${total}
              </Row>
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            color={"success"}
            outline={true}
            onClick={() => modalData.toggleAcceptProjectTokenNew()}
          >
            {delete_staff_user_modal_cancel}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.approveTokenRequest()}
            disabled={modalData.state.loading}
          >
            {accept_btn}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  acceptLicenceReqModal: function (modalData) {
    const { t } = modalData.props;
    let total = 0;

    let acceptLicenceRequestName = (typeof modalData.state.requestStatusData != 'undefined' && modalData.state.requestStatusData != null) ? modalData.state.requestStatusData.requestid : "";

    return (
      <Modal
        isOpen={modalData.state.acceptModal}
        toggle={() => modalData.toggleAcceptLicenceReqModal()}
        className={`modal-dialog--success modal-dialog--header`}
        style={{ maxWidth: "700px" }}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={modalData.toggleAcceptLicenceReqModal}
          />
          <h4 className="bold-text  modal__title">
            {accept_licence_request_modal_title}
          </h4>
        </div>
        <div className="modal__body">
          {accept_licence_request_modal_message + " ( " + acceptLicenceRequestName + " ) ?"}
          <Row className="margin__top">
            {modalData.state.licenceRequestDetails &&
              modalData.state.licenceRequestDetails.map((val, i) => {
                total += Math.abs(val.price * val.month * val.count);
                return (
                  <Col xs={12} key={i}>
                    <Row>
                      <Col xs={4} sm={4} md={4}>
                        {val.licencename}{" - ( "}{val.count} {" )"}
                      </Col>
                      <Col xs={3} sm={3} md={3}>
                        {" "}
                        {val.month}{" Month"}
                      </Col>
                      <Col xs={3} sm={3} md={3}>
                        {" "}
                        ${val.price} / Month
                      </Col>
                      <Col xs={2} sm={2} md={2}>
                        ${Math.abs(val.price * val.month * val.count)}
                      </Col>
                    </Row>
                  </Col>
                );
              })}
          </Row>
          <Row className="margin__top">
            <Col xs={12}>
              <Row style={{ paddingTop: "8px", float: "right", marginRight: "30px", borderTop: "1px solid #d7dae0" }}>
                {total_amount}: ${total}
              </Row>
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            color={"success"}
            outline={true}
            onClick={() => modalData.toggleAcceptLicenceReqModal()}
          >
            {delete_staff_user_modal_cancel}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.approveLicenceRequest()}
            disabled={modalData.state.loading}
          >
            {accept_btn}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  rejectLicenceReqModal: function (modalData) {
    const { t } = modalData.props;

    let dltLicenceRequestName = (typeof modalData.state.requestStatusData != 'undefined' && modalData.state.requestStatusData != null) ? modalData.state.requestStatusData.requestid : "";

    return (
      <Modal
        isOpen={modalData.state.rejectModal}
        toggle={() => modalData.toggleRejectLicenceReqModal()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={modalData.toggleRejectLicenceReqModal}
          />
          <h4 className="bold-text  modal__title">
            {reject_licence_request_modal_title}
          </h4>
        </div>
        <div className="modal__body">
          {reject_licence_request_modal_message + " ( " + dltLicenceRequestName + " ) ?"}
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            color={"success"}
            outline={true}
            onClick={() => modalData.toggleRejectLicenceReqModal()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.rejectLicenceRequest()}
            disabled={modalData.state.loading}
          >
            {delete_staff_user_modal_delete}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  rejectProjectTokenModalNew: function (modalData) {
    const { t } = modalData.props;

    let dltTokenRequestName = (typeof modalData.state.requestStatusData != 'undefined' && modalData.state.requestStatusData != null) ? modalData.state.requestStatusData.requestid : "";

    return (
      <Modal
        isOpen={modalData.state.rejectProjectTokenModalNew}
        toggle={() => modalData.toggleRejectProjectTokenNew()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={modalData.toggleRejectProjectTokenNew}
          />
          <h4 className="bold-text  modal__title">
            {reject_licence_request_modal_title}
          </h4>
        </div>
        <div className="modal__body">
          {reject_token_request_modal_message + " ( " + dltTokenRequestName + " ) ?"}
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            color={"success"}
            outline={true}
            onClick={() => modalData.toggleRejectProjectTokenNew()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.rejectTokenRequest()}
            disabled={modalData.state.loading}
          >
            {delete_staff_user_modal_delete}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  acceptPlanReqModal: function (modalData) {
    const { t } = modalData.props;
    let total = 0;

    let acceptplanreqName = (typeof modalData.state.statusData != 'undefined' && modalData.state.statusData != null) ? modalData.state.statusData.planname : "";

    return (
      <Modal
        isOpen={modalData.state.acceptModal}
        toggle={() => modalData.toggleAcceptPlanReqModal()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={modalData.toggleAcceptPlanReqModal}
          />
          <h4 className="bold-text  modal__title">
            {accept_licence_request_modal_title}
          </h4>
        </div>
        <div className="modal__body">
          {accept_licence_request_modal_plan_message + " ( " + acceptplanreqName + " ) ?"}
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            color={"success"}
            outline={true}
            onClick={() => modalData.toggleAcceptPlanReqModal()}
          >
            {delete_staff_user_modal_cancel}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.approvePlanRequest()}
            disabled={modalData.state.loading}
          >
            {accept_btn}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  planRenewExtendModal: function (modalData) {
    const { t } = modalData.props;

    let renewExtend = modalData.state.renewExtend;
    let type = renewExtend.type;
    let titleName = type === "renew" ? plan_renew_request : plan_extend_request;

    return (
      <Modal
        isOpen={modalData.state.planRenewExtendModal}
        toggle={() => modalData.togglePlanRenewExtendModal()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={modalData.togglePlanRenewExtendModal}
          />
          <h4 className="bold-text  modal__title">{titleName}</h4>
        </div>
        <div className="modal__body">
          {type === "extend" && (
            <>
              <Row>
                <Col xs={12}>
                  <MuiThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        fullWidth
                        required
                        minDate={moment()}
                        margin="normal"
                        label={"Date"}
                        format={"dd/MM/yyyy"}
                        autoOk={true}
                        value={renewExtend.date}
                        onChange={modalData.handleChange("date")}
                      />
                    </MuiPickersUtilsProvider>
                  </MuiThemeProvider>
                </Col>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <Col xs={12}>
                  <TextField
                    type="number"
                    required
                    fullWidth
                    id="apparent-depth"
                    label={renewextendmodel_price}
                    value={renewExtend.price}
                    onChange={modalData.handleChange("price")}
                    error={renewExtend.error.includes("price")}
                  />
                  {renewExtend.error.includes("price") && (
                    <span className="error-message">
                      {renewextendmodel_price_validate}
                    </span>
                  )}
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col xs={12}>
              <TextField
                fullWidth
                id="standard-multiline-static"
                label={renewextendmodel_comment}
                multiline
                rows="4"
                margin="normal"
                value={renewExtend.comment}
                onChange={modalData.handleChange("comment")}
              />
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            color={"success"}
            outline={true}
            onClick={() => modalData.togglePlanRenewExtendModal()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.saveRenewExtendPlanRequest()}
            disabled={modalData.state.loading}
          >
            {language_savebtn}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  licenceRenewExtendModal: function (modalData) {
    const { t } = modalData.props;

    let renewExtend = modalData.state.renewExtend;
    let type = renewExtend.type;
    let titleName =
      type === "renew" ? licence_renew_request : licence_extend_request;

    return (
      <Modal
        isOpen={modalData.state.licenceRenewExtendModal}
        toggle={() => modalData.toggleLicenceRenewExtendModal()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={modalData.toggleLicenceRenewExtendModal}
          />
          <h4 className="bold-text  modal__title">{titleName}</h4>
        </div>
        <div className="modal__body">
          {type === "extend" && (
            <>
              <Row>
                <Col xs={12}>
                  <MuiThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        fullWidth
                        minDate={moment()}
                        margin="normal"
                        label={"Date"}
                        format={"dd/MM/yyyy"}
                        autoOk={true}
                        value={renewExtend.date}
                        onChange={modalData.handleChange("date")}
                      />
                    </MuiPickersUtilsProvider>
                  </MuiThemeProvider>
                </Col>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <Col xs={12}>
                  <TextField
                    type="number"
                    required
                    fullWidth
                    id="apparent-depth"
                    label={renewextendmodel_price}
                    value={renewExtend.price}
                    onChange={modalData.handleChange("price")}
                    error={renewExtend.error.includes("price")}
                  />
                  {renewExtend.error.includes("price") && (
                    <span className="error-message">
                      {renewextendmodel_price_validate}
                    </span>
                  )}
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col xs={12}>
              <TextField
                fullWidth
                //required
                id="standard-multiline-static"
                label={renewextendmodel_comment}
                multiline
                rows="4"
                margin="normal"
                value={renewExtend.comment}
                onChange={modalData.handleChange("comment")}
              />
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            color={"success"}
            outline={true}
            onClick={() => modalData.toggleLicenceRenewExtendModal()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.saveRenewExtendLicenceRequest()}
            disabled={modalData.state.loading}
          >
            {accept_btn}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  rejectPlanReqModal: function (modalData) {
    const { t } = modalData.props;

    let planreqName = (typeof modalData.state.statusData != 'undefined' && modalData.state.statusData != null) ? modalData.state.statusData.planname : "";

    return (
      <Modal
        isOpen={modalData.state.rejectModal}
        toggle={() => modalData.toggleRejectPlanReqModal()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={modalData.toggleRejectPlanReqModal}
          />
          <h4 className="bold-text  modal__title">
            {reject_licence_request_modal_title}
          </h4>
        </div>
        <div className="modal__body">
          {reject_licence_request_modal_plan_message + " ( " + planreqName + " ) ?"}
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            color={"success"}
            outline={true}
            onClick={() => modalData.toggleRejectPlanReqModal()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.rejectPlanRequest()}
            disabled={modalData.state.loading}
          >
            {delete_staff_user_modal_delete}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  importMaterialModal: function (modalData) {
    const { t } = modalData.props;

    return (
      <Modal
        isOpen={modalData.state.importModal}
        toggle={() => modalData.toggleCloseImportModal()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={modalData.toggleCloseImportModal}
          />
          <h4 className="bold-text  modal__title">
            {reject_licence_request_modal_title}
          </h4>
        </div>
        <div className="modal__body">
          <form className="material-form card-body-width">
            <Row>
              <Col xs={12} md={6} lg={6} xl={6}>
                <div>
                  <input
                    type="file"
                    onChange={(e) => modalData.handleChange(e.target.files[0])}
                  />
                </div>
              </Col>
            </Row>
          </form>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            color={"success"}
            outline={true}
            onClick={() => modalData.toggleCloseImportModal()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.handleImport()}
            disabled={modalData.state.loading}
          >
            {common_btn_import}{" "}
            {modalData.state.loading && (
              <Spinner size="sm" className="btn-spinner" />
            )}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  acceptProjectTokenModal: function (modalData) {
    const { t } = modalData.props;

    let acceptTokenName = modalData.state.acceptTokenName;

    return (
      <Modal
        isOpen={modalData.state.acceptProjectTokenModal}
        toggle={() => modalData.toggleAcceptProjectToken()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={modalData.toggleAcceptProjectToken}
          />
          <h4 className="bold-text  modal__title">
            {accept_project_token_request}
          </h4>
        </div>
        <div className="modal__body">{project_token_areyoumsg + " ( " + acceptTokenName + " ) ?"}</div>
        <ButtonToolbar className="modal__footer">
          <Button
            color={"success"}
            outline={true}
            onClick={() => modalData.toggleAcceptProjectToken()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.acceptProjectToken()}
          >
            {language_savebtn}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  rejectProjectTokenModal: function (modalData) {
    const { t } = modalData.props;

    let deleteTokenName = modalData.state.deleteTokenName;

    return (
      <Modal
        isOpen={modalData.state.rejectProjectTokenModal}
        toggle={() => modalData.toggleRejectProjectToken()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={modalData.toggleRejectProjectToken}
          />
          <h4 className="bold-text  modal__title">
            {reject_project_token_request}
          </h4>
        </div>
        <div className="modal__body">
          {reject_project_token_request_areyoumsg + " ( " + deleteTokenName + " ) ?"}
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            color={"success"}
            outline={true}
            onClick={() => modalData.toggleRejectProjectToken()}
          >
            {cancel_btn}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.rejectProjectToken()}
          >
            {delete_staff_user_modal_delete}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  addLanguageLabelModal: function (modalData) {
    const { t } = modalData.props;
    let label = modalData.state.label;

    return (
      <Modal
        isOpen={modalData.state.addLanguageLabelModal}
        toggle={() => modalData.toggleAddLanguageLabel()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleAddLanguageLabel()}
          />
          <h4 className="bold-text  modal__title">
            {LANGUAGE_ADD_NEW_LABEL_KEY}
          </h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col md={12} lg={12} className="theme-light">
              <TextField
                key={2}
                fullWidth
                required
                className="material-form__field"
                label={LANGUAGE_ADD_KEYTEXT_KEY}
                onChange={modalData.handleLabelChange("key")}
                value={label.key}
                InputLabelProps={{ shrink: true }}
                error={label.error.includes("key")}
              />
              {label.error.includes("key") && (
                <span className="error-message">{LANGADDKEY_VALIDATE}</span>
              )}
            </Col>
            <Col md={12} lg={12} className="theme-light">
              <TextField
                key={3}
                required
                fullWidth
                className="material-form__field"
                label={LANGUAGE_ADD_KEYVAL_KEY}
                onChange={modalData.handleLabelChange("en")}
                value={label.language.en}
                InputLabelProps={{ shrink: true }}
                error={label.error.includes("en")}
              />
              {label.error.includes("en") && (
                <span className="error-message">{LANGADDVALUE_VALIDATE}</span>
              )}
            </Col>

            <Col md={12} lg={12} className="theme-light">
              <TextField
                key={4}
                fullWidth
                className="material-form__field"
                label={"Description"}
                onChange={modalData.handleLabelChange("description")}
                value={label.description}
                InputLabelProps={{ shrink: true }}
                error={label.error.includes("description")}
              />
              {label.error.includes("description") && (
                <span className="error-message">Please add description</span>
              )}
            </Col>
            <Col md={12} lg={12} className="theme-light">
              <TextField
                select
                id="standard-select-pagename"
                SelectProps={{
                  MenuProps: {
                    className: "select-menu-width",
                  },
                }}
                style={{ width: "100%" }}
                label="page"
                className="material-form__field"
                value={label.page_name ? label.page_name : ""}
                name={"page_name"}
                InputLabelProps={{ shrink: true }}
                onChange={modalData.handleLabelChange('page_name')}
              >
                {modalData.state.allpages.map((page) => (
                  <MenuItem
                    key={page.name}
                    value={page.value}
                  >
                    {page.name}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
          </Row>
        </div>

        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleAddLanguageLabel()}
          >
            {DELSTAFFUSERMODAL_KEY}
          </Button>
          <Button color={"success"} onClick={() => modalData.saveLanguage()}>
            {COMMONBTNSAVE_KEY}
            {modalData.state.loading ? (
              <Spinner size="sm" className="margin__left_10 btn-spinner" />
            ) : null}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  manageNewLanguageModal: function (modalData) {
    const { t } = modalData.props;

    const columns = [
      { name: language_setting_srno },
      { name: language_setting_code },
      { name: language_setting_language },
      {
        name: language_setting_isVisible,
        options: {
          filter: false,
          customBodyRender: (value) => {
            return (
              <>
                <FormControlLabel
                  fullWidth
                  className="switch-language"
                  control={
                    <RedSwitch
                      checked={value.isvisible}
                      onClick={() => modalData.toggleManageLanguage(value)}
                      value={value.isvisible}
                      // color="default"
                      disabled={value.code === "en"}
                    />
                  }
                />
              </>
            );
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      print: false,
      download: false,
      viewColumns: false,
      filter: false,
      responsive: "stacked",
      rowsPerPage: 10,
      textLabels: {
        body: {
          noMatch: no_match_record_found,
          toolTip: sort_text
        },
        pagination: {
          rowsPerPage: rows_per_page_text,
        },
        toolbar: {
          search: search_text
        },
      },
      search: false,
    };

    return (
      <Modal
        isOpen={modalData.state.manageNewLanguageModal}
        toggle={() => modalData.toggleManageNewLanguage()}
        className={`modal-dialog--success modal-dialog--header width__70`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={modalData.toggleManageNewLanguage}
          />
          <h4 className="bold-text  modal__title">{language_setting_btn}</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col xs={12} sm={12} md={12} style={{ marginTop: "40px" }}>
              <MUIDataTable
                data={modalData.renderLanguages()}
                columns={columns}
                options={options}
              />
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            color={"success"}
            onClick={() => modalData.toggleManageNewLanguage()}
          >
            {common_btn_okay}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  addNewLanguageModal: function (modalData) {
    const { t } = modalData.props;
    let codelabel = modalData.state.codelabel;

    return (
      <Modal
        isOpen={modalData.state.addNewLanguageModal}
        toggle={() => modalData.toggleAddNewLanguage()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleAddNewLanguage()}
          />
          <h4 className="bold-text  modal__title">
            {language_add_new_lang_label}
          </h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col md={12} lg={12} className="theme-light">
              <ReactFlagsSelect
                countries={[
                  "US",
                  "GB",
                  "FR",
                  "DE",
                  "IT",
                  "ES",
                  "AF",
                  "AT",
                  "BE",
                  "BR",
                  "BG",
                  "HR",
                  "CZ",
                  "DK",
                  "ET",
                  "FI",
                  "GE",
                  "GR",
                  "HU",
                  "IN",
                  "LV",
                  "LT",
                  "NL",
                  "NO",
                  "PL",
                  "PT",
                  "RO",
                  "RU",
                  "RS",
                  "SK",
                  "SI",
                  "SE",
                  "TH",
                  "TR",
                ]}
                customLabels={{
                  US: "EN-US",
                  GB: "EN-GB",
                  FR: "FR",
                  DE: "DE",
                  IT: "IT",
                  ES: "Spanish",
                  AF: "Afrikaans",
                  AT: "German (Austria)",
                  BE: "French (Belgium)",
                  BR: "Portuguese (Brazil)",
                  BG: "Bulgarian",
                  HR: "Croatian",
                  CZ: "Czech (Czech Republic)",
                  DK: "Danish (Denmark)",
                  ET: "Estonian",
                  FI: "Finnish",
                  GE: "Georgian",
                  GR: "Greek",
                  HU: "Hungarian",
                  IN: "Hindi (India)",
                  LV: "Latvian",
                  LT: "Lithuanian",
                  NL: "Dutch (Netherlands)",
                  NO: "Norwegian (Bokm?l) (Norway)",
                  PL: "Polish",
                  PT: "Portuguese (Portugal)",
                  RO: "Romanian",
                  RU: "Russian",
                  RS: "Serbian (Cyrillic) (Serbia and Montenegro)",
                  SK: "Slovak (Slovakia)",
                  SI: "Slovenian (Slovenia)",
                  SE: "Swedish (Sweden)",
                  TH: "Thai (Thailand)",
                  TR: "Turkish (Turkey)",
                }}
                placeholder={select_Language_txt}
                onSelect={modalData.onSelectFlag}
                error={codelabel.error.includes("code")}
              />
              {codelabel.error.includes("code") && (
                <span className="error-message">{pls_select_lang_valid}</span>
              )}
            </Col>
          </Row>
        </div>

        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleAddNewLanguage()}
          >
            {DELSTAFFUSERMODAL_KEY}
          </Button>
          <Button
            color={"success"}
            onClick={() => modalData.saveLanguageCode()}
          >
            {COMMONBTNSAVE_KEY}
            {modalData.state.loading ? (
              <Spinner size="sm" className="margin__left_10 btn-spinner" />
            ) : null}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  deleteLanguageLabelModal: function (modalData) {
    const { t } = modalData.props;
    let label = modalData.state.label;

    return (
      <Modal
        isOpen={modalData.state.deleteLanguageLabelModal}
        toggle={() => modalData.toggleDeleteLanguageLabel()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.toggleDeleteLanguageLabel()}
          />
          <h4 className="bold-text  modal__title">
            {deleteLanguageLabelModal_title}
          </h4>
        </div>
        <div className="modal__body">{deleteLanguageLabelModal_msg}</div>

        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.toggleDeleteLanguageLabel()}
          >
            {delete_staff_user_modal_cancel}
          </Button>
          <Button color={"success"} onClick={() => modalData.deleteLanguage()}>
            {delete_btn}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
  languageTabChangeConfirmModal: function (modalData) {
    const { t } = modalData.props;
    let label = modalData.state.label;

    return (
      <Modal
        isOpen={modalData.state.languageTabChangeConfirmModal}
        toggle={() => modalData.cancelLanguageTabChange()}
        className={`modal-dialog--success modal-dialog--header`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => modalData.cancelLanguageTabChange()}
          />
          <h4 className="bold-text  modal__title">
            {excelupload_continue_modal_title}
          </h4>
        </div>
        <div className="modal__body">{language_tab_continue_message}</div>
        <ButtonToolbar className="modal__footer">
          <Button
            outline={true}
            color={"success"}
            onClick={() => modalData.cancelLanguageTabChange()}
          >
            {btn_no}
          </Button>
          <Button color={"success"} onClick={() => modalData.confirmLanguageTabChange()}>
            {btn_yes}
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  },
};
