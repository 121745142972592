export const sampleDisposalCalulation = request => {
	const checkInnerRule2 = (
		rules,
		sample_val,
		sheet_data,
		fsampl,
		desposal_list,
		auto_true_arr,
		tst_child_id,
		inr_tst_child_id_1
	) => {
		//Here in above argument tst_child_id is the child id of pollutant beign checked.
		//so if child id of pollutant being checked found in rule-2 test pollutants rules then pollutant beign checked getting false automatically.
		//inr_tst_child_id_1 is the childid of the testname that is selected in the dropdown in rule 2
		//so solve the Infinite loops error in Circulr Rule-2 and page getting stuck error.

		let flag = false;
		let condition = rules.formula.condition;

		let my_rules_val = rules.value;
		if (typeof sample_val === 'number') {
			if (!my_rules_val) {
				flag = false;
			} else if (condition === 'less') {
				if (sample_val <= my_rules_val) {
					flag = true;
				}
			} else if (condition === 'greter') {
				if (sample_val >= my_rules_val) {
					flag = true;
				}
			}
		}

		if (!flag) {
			let all_test_truefalse_arr = [];

			let checkpollutantbeingcheckedexist = rules.formula.test.filter(l => l.id === tst_child_id)
				.length; //Check this to solve the infinite loop issue
			let checkpollutantbeingcheckedexist1 = rules.formula.test.filter(
				l => l.id === inr_tst_child_id_1
			).length; //Check this to solve the infinite loop issue
			if (checkpollutantbeingcheckedexist > 0 || checkpollutantbeingcheckedexist1 > 0) {
				flag = false;
			} else {
				if (typeof sample_val === 'number') {
					for (let inr_tst of rules.formula.test) {
						let inr_flag = false;
						let find_fil = sheet_data.filter(
							t => t.Test_Child_Id === inr_tst.id && t.Test_Parent_Id === inr_tst.Test_Parent_Id
						);
						if (find_fil[0] && find_fil[0][fsampl]) {
							let inr_val = find_fil[0][fsampl];
							let inr_Test_Parent_Id = find_fil[0].Test_Parent_Id;
							let inr_tst_child_id = find_fil[0].Test_Child_Id;

							if ((typeof inr_val === 'number' || typeof inr_val === 'string') && inr_val !== '-') {
								for (let fil_desp1 of desposal_list) {
									let rules1 = this.state.alldesposalRuleList.filter(
										t =>
											t.desposal_id === fil_desp1.desposal_id &&
											t.test_id === inr_Test_Parent_Id &&
											t.child_id === inr_tst_child_id
									)[0];

									if (typeof inr_val === 'string' && inr_val !== '-' && rules1) {
										for (let fil_text of auto_true_arr) {
											if (inr_val.includes(fil_text) && !inr_flag) {
												inr_flag = true;
												all_test_truefalse_arr.push(inr_flag);
											}
										}
									}

									if (!inr_flag && rules1 && rules1.option_id === '1') {
										let condition = rules1.formula.condition;
										//rules_val = rules1.value;
										let my_rules_val = rules1.value;
										if (typeof inr_val === 'number') {
											if (!my_rules_val) {
												inr_flag = false;
												all_test_truefalse_arr.push(inr_flag);
											} else if (condition === 'less') {
												if (inr_val <= my_rules_val) {
													inr_flag = true;
													all_test_truefalse_arr.push(inr_flag);
												}
											} else if (condition === 'greter') {
												if (inr_val >= my_rules_val) {
													inr_flag = true;
													all_test_truefalse_arr.push(inr_flag);
												}
											}
										}
									} else if (!inr_flag && rules1 && rules1.option_id === '2') {
										inr_flag = checkInnerRule2(
											rules1,
											inr_val,
											sheet_data,
											fsampl,
											desposal_list,
											auto_true_arr,
											tst_child_id,
											inr_tst_child_id_1
										);
										all_test_truefalse_arr.push(inr_flag);
									} else if (!inr_flag && rules1 && rules1.option_id === '3') {
										if (typeof inr_val === 'number') {
											//rules_val = rules.value;
											//rules_val = "No Limit";
											inr_flag = true;
											all_test_truefalse_arr.push(inr_flag);
										}
									} else if (!inr_flag && rules1 && rules1.option_id === '4') {
										let condition = rules1.formula.condition;
										//rules_val = rules.value;
										//rules_val = (condition === "greter" ? ">" : "<") + "" + rules1.formula.percentage + "% of " + rules.formula.test[0].name;
										if (typeof inr_val === 'number') {
											for (let inr_tst2 of rules1.formula.test) {
												let find_fil2 = sheet_data.filter(
													t =>
														t.Test_Child_Id === inr_tst2.id &&
														t.Test_Parent_Id === inr_tst2.Test_Parent_Id
												);
												if (find_fil2[0] && find_fil2[0][fsampl]) {
													let inr_val2 = find_fil2[0][fsampl];
													if (typeof inr_val2 === 'number') {
														//New added line condition
														let calc_val = (inr_val2 * rules1.formula.percentage) / 100;
														if (condition === 'less' && inr_val < calc_val) {
															inr_flag = true;
															all_test_truefalse_arr.push(inr_flag);
														} else if (condition === 'greter' && inr_val > calc_val) {
															inr_flag = true;
															all_test_truefalse_arr.push(inr_flag);
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}

			let trueCount = all_test_truefalse_arr.filter(Boolean).length;
			let totalAddedTest = rules.formula.test.length;
			//All added test pollutats must be satisfied to approve this current pollutant
			if (totalAddedTest === trueCount) {
				flag = true;
			}
		}

		return flag;
	};

	// ========================== Code Start ==========================

	let excelDataList = request.excelDataList;
	let selectedDisposalFamily = request.selectedDisposalFamily;
	let alldesposalRuleList = request.alldesposalRuleList;
	let activedesposalList = request.activedesposalList;
	let allProbingSamples = request.allProbingSamples;
	let exceptionConst = request.exceptionConst;
	let default_unit = request.default_unit;

	if (excelDataList.length > 0) {
		// console.log("excelDataList ", excelDataList);
		// console.log("selectedDisposalFamily ", selectedDisposalFamily);
		// console.log("alldesposalRuleList ", alldesposalRuleList);
		// console.log("activedesposalList ", activedesposalList);
		// console.log("allProbingSamples ", allProbingSamples);
		// console.log("exceptionConst ", exceptionConst);

		let sampl_finl1 = [];

		//Loop through all excel sheet uploaded by user
		for (let sht_tst_data_1 of excelDataList) {
			//access selected campaign uploaded sheet
			let sheet_data = sht_tst_data_1.sheet_resp_data;
			for (let sht_tst_data of sheet_data) {
				let tst_name = sht_tst_data.Testfamily;
				let tst_child_id = sht_tst_data.Test_Child_Id;
				let Test_Parent_Id = sht_tst_data.Test_Parent_Id;

				//check excel pollutant family exist in the selected disposal family
				let isFamilyexistinSelDispo = 0;
				if (selectedDisposalFamily.length > 0) {
					isFamilyexistinSelDispo = selectedDisposalFamily.filter(h => h === Test_Parent_Id).length;
				}

				//selectedDisposalFamily is blank when page load so display all dots in grey color and when select disposal then check the pollutat family exist in the selected disposal family
				if (
					selectedDisposalFamily.length <= 0 ||
					(selectedDisposalFamily.length > 0 && isFamilyexistinSelDispo > 0)
				) {
					//for (let fil_smpl_coll of this.state.allProbingSamples) {
					for (let fil_smpl_coll of allProbingSamples) {
						//Sample Name calculation
						let samplenamerange = '';
						if (
							!fil_smpl_coll.sampleNameIsManual &&
							typeof fil_smpl_coll.sampleNameTop !== 'undefined' &&
							typeof fil_smpl_coll.sampleNameBottom !== 'undefined'
						) {
							let sampleNameTop = fil_smpl_coll.sampleNameTop;
							let sampleNameBottom = fil_smpl_coll.sampleNameBottom;

							if (default_unit !== 'CM') {
								//sampleNameTop = sampleNameTop?(Number(sampleNameTop)/100).toFixed(2):"0";
								//sampleNameBottom = sampleNameBottom?(Number(sampleNameBottom)/100).toFixed(2):"";

								sampleNameTop = sampleNameTop ? Number(sampleNameTop) / 100 : 0;
								sampleNameBottom = sampleNameBottom ? Number(sampleNameBottom) / 100 : '';

								sampleNameTop =
									sampleNameTop === parseInt(sampleNameTop)
										? sampleNameTop
										: parseFloat(sampleNameTop).toFixed(2);
								sampleNameBottom =
									sampleNameBottom === parseInt(sampleNameBottom)
										? sampleNameBottom
										: parseFloat(sampleNameBottom).toFixed(2);
							}
							samplenamerange = fil_smpl_coll?.sampleName ||
								fil_smpl_coll.probingcode + ' (' + sampleNameTop + ' - ' + sampleNameBottom + ')';
						} else if (fil_smpl_coll.sampleNameIsManual) {
							samplenamerange = fil_smpl_coll.sampleNameManual;
						} else {
							samplenamerange = fil_smpl_coll.probingcode;
						}

						let lithology = fil_smpl_coll?.lithology || '';
						let colourname = fil_smpl_coll?.colourname || '';
						let colourcode = fil_smpl_coll?.colourcode || '';
						let probingcode = fil_smpl_coll?.probingcode || '';
						let altitude = fil_smpl_coll.altitude || '';
						let longitude = fil_smpl_coll?.longitude || '';
						let latitude = fil_smpl_coll?.latitude || '';
						let middel_depth = fil_smpl_coll?.middel_depth || '';
						let sample_observation = fil_smpl_coll?.observation || '';
						let sample_uplowrange = fil_smpl_coll?.uplowrange || '';
						let layer_observation = fil_smpl_coll?.layerObservation;
						let layer_anomaly = fil_smpl_coll?.layerAnomaly;

						let fsampl = fil_smpl_coll.sampleNo;
						let campaignid = fil_smpl_coll.campaignid;
						let smpl_val = sht_tst_data[fsampl]; //get dynamic colummn

						//If we have sample no, its value and parent id then
						if (fsampl && (smpl_val || smpl_val === '' || smpl_val === 0) && Test_Parent_Id) {
							let test_done = false;
							let temp_desp_res = {};
							let desposal_list = activedesposalList;
							//let auto_true_arr = ["<LQ", "<", "-", "n.d.", "nd"];
							let auto_true_arr = exceptionConst;
							let sampleRuleExistInAnyDispo = false;
							let all_desp_data = [];
							let stringnotmatched = false;
							let chkForMissingPollutant = true;
							for (let fil_desp of desposal_list) {
								//Fetch the rule of testnames
								let rules = alldesposalRuleList.filter(
									t =>
										t.desposal_id === fil_desp.desposal_id &&
										t.test_id === Test_Parent_Id &&
										Number(t.child_id) === Number(tst_child_id)
								)[0];

								//Check Rule exist for pollutant
								if (rules !== undefined) {
									sampleRuleExistInAnyDispo = true;
								}

								let flag = false;
								let rules_val = 0;
								let sample_val = smpl_val;
								if (typeof sample_val === 'string' && sample_val !== '-' && rules) {
									for (let fil_text of auto_true_arr) {
										if (sample_val.includes(fil_text)) {
											flag = true;

											/*New added code start */
											if (rules.option_id == '1') {
												rules_val = rules.value;
											} else if (rules.option_id == '2') {
												// let allnames = [];
												// rules.formula.test.map((t,p) => {
												//     allnames.push(t.name);
												// });

												// let article = " is valid )";
												// if(allnames.length > 1){ article = " are valid )"; }

												//rules_val = rules.value + " ( can still be valid if " + allnames.join() + "" + article;
												rules_val = rules.value + '*';
											} else if (rules.option_id == '3') {
												rules_val = 'No Limit' + (rules?.nolimit_finalvalue ? ' *' : '');
											} else if (rules.option_id == '4') {
												rules_val =
													(rules.formula.condition == 'greter' ? '>' : '<') +
													'' +
													rules.formula.percentage +
													'% of ' +
													rules.formula.test[0].name;
											}
											/*New added code end */
										}
									}
									if (!flag) {
										if (sample_val !== '') {
											stringnotmatched = true; //for string that is not matched like n.a, not etc have lightgrey background
										}
									}
								}

								if (!flag && rules && rules.option_id === '1') {
									// compare option 1
									let condition = rules.formula.condition;
									rules_val = rules.value;
									if (typeof sample_val === 'number') {
										if (!rules_val) {
											flag = false;
										} else if (condition === 'less') {
											if (sample_val <= rules_val) {
												flag = true;
											}
										} else if (condition === 'greter') {
											if (sample_val >= rules_val) {
												flag = true;
											}
										}
									}
								} else if (!flag && rules && rules.option_id === '2') {
									let condition = rules.formula.condition;

									rules_val = rules.value + '*';
									let my_rules_val = rules.value;
									if (typeof sample_val === 'number') {
										if (!my_rules_val) {
											flag = false;
										} else if (condition === 'less') {
											if (sample_val <= my_rules_val) {
												flag = true;
											}
										} else if (condition === 'greter') {
											if (sample_val >= my_rules_val) {
												flag = true;
											}
										}
									}

									if (!flag) {
										let all_test_truefalse_arr = [];
										if (typeof sample_val === 'number') {
											for (let inr_tst of rules.formula.test) {
												let inr_flag = false;
												//Check Added pollutant is exist in the uploaded excelsheet
												let find_fil = sheet_data.filter(
													t =>
														t.Test_Child_Id === inr_tst.id &&
														t.Test_Parent_Id === inr_tst.Test_Parent_Id
												);
												if (find_fil[0] && find_fil[0][fsampl]) {
													let inr_val = find_fil[0][fsampl]; //Get the value of added pollutant sample
													let inr_Test_Parent_Id = find_fil[0].Test_Parent_Id; //Get added pollutant parent id
													let inr_tst_child_id = find_fil[0].Test_Child_Id; //Get added pollutant child id

													let mainLoopDisposal = [fil_desp];
													if (
														(typeof inr_val === 'number' || typeof inr_val === 'string') &&
														inr_val !== '-'
													) {
														//Loop in main parent single disposal array
														for (let fil_desp1 of mainLoopDisposal) {
															//Get the Rules of Added Pollutant
															let rules1 = alldesposalRuleList.filter(
																t =>
																	t.desposal_id === fil_desp1.desposal_id &&
																	t.test_id === inr_Test_Parent_Id &&
																	t.child_id === String(inr_tst_child_id)
															)[0];

															if (typeof inr_val === 'string' && inr_val !== '-' && rules1) {
																for (let fil_text of auto_true_arr) {
																	if (inr_val.includes(fil_text) && !inr_flag) {
																		inr_flag = true;
																		all_test_truefalse_arr.push(inr_flag);
																	}
																}
															}

															if (!inr_flag && rules1 && rules1.option_id === '1') {
																// compare option 1
																let condition = rules1.formula.condition;

																let my_rules_val = rules1.value;
																if (typeof inr_val === 'number') {
																	if (!my_rules_val) {
																		inr_flag = false;
																		all_test_truefalse_arr.push(inr_flag);
																	} else if (condition === 'less') {
																		if (inr_val <= my_rules_val) {
																			inr_flag = true;
																			all_test_truefalse_arr.push(inr_flag);
																		}
																	} else if (condition === 'greter') {
																		if (inr_val >= my_rules_val) {
																			inr_flag = true;
																			all_test_truefalse_arr.push(inr_flag);
																		}
																	}
																}
															} else if (!inr_flag && rules1 && rules1.option_id === '2') {
																inr_flag = checkInnerRule2(
																	rules1,
																	inr_val,
																	sheet_data,
																	fsampl,
																	mainLoopDisposal,
																	auto_true_arr,
																	tst_child_id,
																	inr_tst_child_id
																);
																all_test_truefalse_arr.push(inr_flag);
															} else if (!inr_flag && rules1 && rules1.option_id === '3') {
																if (typeof inr_val === 'number') {
																	inr_flag = true;
																	all_test_truefalse_arr.push(inr_flag);
																}
															} else if (!inr_flag && rules1 && rules1.option_id === '4') {
																let condition = rules1.formula.condition;

																if (typeof inr_val === 'number') {
																	for (let inr_tst2 of rules1.formula.test) {
																		let find_fil2 = sheet_data.filter(
																			t =>
																				t.Test_Child_Id === inr_tst2.id &&
																				t.Test_Parent_Id === inr_tst2.Test_Parent_Id
																		);
																		if (find_fil2[0] && find_fil2[0][fsampl]) {
																			let inr_val2 = find_fil2[0][fsampl];
																			if (typeof inr_val2 === 'number') {
																				//New added line condition
																				let calc_val = (inr_val2 * rules1.formula.percentage) / 100;
																				if (condition === 'less' && inr_val < calc_val) {
																					inr_flag = true;
																					all_test_truefalse_arr.push(inr_flag);
																				} else if (condition === 'greter' && inr_val > calc_val) {
																					inr_flag = true;
																					all_test_truefalse_arr.push(inr_flag);
																				}
																			}
																		}
																	}
																}
															}
														}
													}
												}
											}
										}

										let trueCount = all_test_truefalse_arr.filter(Boolean).length;
										let totalAddedTest = rules.formula.test.length;

										//All added test pollutats must be satisfied to approve this current pollutant
										if (totalAddedTest === trueCount) {
											flag = true;
										}
									}
								} else if (!flag && rules && rules.option_id === '3') {
									rules_val = 'No Limit' + (rules?.nolimit_finalvalue ? '*' : '');
									if (typeof sample_val === 'number') {
										//if value matched with this rule and nolimit_finalvalue = true then Don't check logic For Missing Pollutant as it remove matched dispsoal
										if(rules?.nolimit_finalvalue === true && !test_done) {
											chkForMissingPollutant = false;
										}
										flag = true;
									}
								} else if (!flag && rules && rules.option_id === '4') {
									let condition = rules.formula.condition;

									rules_val =
										(condition === 'greter' ? '>' : '<') +
										'' +
										rules.formula.percentage +
										'% of ' +
										rules.formula.test[0].name;
									if (typeof sample_val === 'number') {
										for (let inr_tst of rules.formula.test) {
											let find_fil = sheet_data.filter(
												t =>
													t.Test_Child_Id === inr_tst.id &&
													t.Test_Parent_Id === inr_tst.Test_Parent_Id
											);
											if (find_fil[0] && find_fil[0][fsampl]) {
												let inr_val = find_fil[0][fsampl];
												if (typeof inr_val === 'number') {
													//New added line condition
													let calc_val = (inr_val * rules.formula.percentage) / 100;
													if (condition === 'less' && sample_val < calc_val) {
														flag = true;
													} else if (condition === 'greter' && sample_val > calc_val) {
														flag = true;
													}
												}
											}
										}
									}
								}

								if (flag && !test_done) {
									//push true desposal into array
									Object.assign(temp_desp_res, {
										desposal_id: fil_desp.desposal_id,
										name: fil_desp.name,
										color: fil_desp.color,
										rules_val: rules_val,
									});
								}

								if (flag) {
									test_done = true;
								}

								all_desp_data.push({
									desposal_id: fil_desp.desposal_id,
									color: fil_desp.color,
									//rules_val: (rules) ? rules.value : 0,
									rules_val: rules ? rules_val : '',
								});
							}

							/*============================= */
							//prepare all one by one sample list against all testnames.
							//if there are 2 sample and 2 pollutant then there is 4 records in sampl_finl1 array 1 for each sample
							// console.log('temp_desp_res.desposal_id', temp_desp_res.desposal_id);
							sampl_finl1.push({
								sample_name: fsampl,
								sample_name_range: samplenamerange,
								campaignid: campaignid,
								tst_name: tst_name,
								tst_child_id: tst_child_id,
								parent_testfamily_id: Test_Parent_Id,
								matched_desp: temp_desp_res.desposal_id ? temp_desp_res.desposal_id : '', //different than analysis report page
								rule_exist: sampleRuleExistInAnyDispo,
								stringnotmatched: stringnotmatched,
								matched_desp_info: temp_desp_res, //I add this
								probingcode: probingcode,
								sample_length: sample_uplowrange,
								sample_observation: sample_observation,
								layer_observation: layer_observation,
								layer_anomaly: layer_anomaly,
								value: smpl_val,
								lithology: lithology,
								colourname: colourname,
								colourcode: colourcode,
								all_desp_data: all_desp_data,
								altitude: altitude,
								longitude: longitude,
								latitude: latitude,
								middel_depth: middel_depth,
								chkForMissingPollutant: chkForMissingPollutant
							});
						}
					}
				}
			}
		}

		return sampl_finl1;
	}
}; //End of export
