import React, { PureComponent } from "react";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import * as actions from "../../../../Store/actions/index";
import { Card, CardBody, Spinner } from 'reactstrap';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import moment from "moment-timezone";

moment.tz.setDefault('Europe/Paris');

//start language
let tlang = ChangeAppLanguageFront.translateLanguage;
const assign_role = tlang('assign_licence_request_modal_role') || 'Role';
const masters_name = tlang('test_masters_name') || 'Name';
const company_name = tlang('client_profile_company_name') || 'Company Name';
const USERREQUEST_SRNO_KEY = tlang('user_request_srno') || 'Sr.No';
const USERREQUEST_EMAIL_KEY = tlang('user_request_email') || 'Email';
const sort_text = tlang('sort_text') || 'Sort';
const rows_per_page_text = tlang('rows_per_page_text') || 'Rows per page:';
const no_match_record_found = tlang('no_match_record_found') || 'Sorry, no matching records found';
const search_text = tlang('search_text') || 'Search';
const client_signup_wizard_users = tlang('client_signup_wizard_users') || 'Users';
const USERREQUEST_CREATEDON_KEY = tlang('user_request_created_on') || 'Requested Time';

const theme = createTheme({
    overrides: {
        MUIDataTableFilterList: {
            root: {
                marginBottom: "10px"
            }
        },
        MuiPaper: {
            elevation4: {
                boxShadow: "none",
            },
        },
        MuiTableCell: {
       
            head: {
              color: "#646777",
              fontWeight: "700",
              textAlign: 'left',
              backgroundColor: "#fafbfe !important",
              borderBottom: "1px solid #c6c6c6",
              borderTop: "1px solid #c6c6c6",
            }
        },
    }
});


// this will manage the request of new clients.
class allCompanyUsers extends PureComponent {
    static propTypes = {
        i18n: PropTypes.shape({ changeLanguage: PropTypes.func }).isRequired,
        t: PropTypes.func.isRequired,
    };

    /* Load init states */
    constructor() {
        super();
        this.state = {
            usersList: [],
            loading: true,
        };
    }

    componentDidMount() {
        /* Load init actions */
        this.props.getUsersListToSendNotification(this.props.apolloClient.client, []);
    }

    componentWillReceiveProps(props) {
        if (props.contentManagementReducer.usersList) {
            if (props.contentManagementReducer.usersList.length) {
                this.setState({
                    loading: false,
                    usersList: props.contentManagementReducer.usersList
                })
            }
        }
    }

    /* render  Users */
    renderUsers = (data) => {
        let renderData = [];
        if (data) {
            data.map((val, index) => {               
                renderData.push([
                    Math.abs(index + 1),
                    val.name,
                    val.email,
                    val.display_name.length ? val.display_name[0] : "-",
                    moment.unix(val.created_at / 1000).format("DD/MM/YYYY HH:mm"),
                ]);
                return null;
            });
        }
        return renderData;
    };
    /* render component */
    render() {
        const { t } = this.props;
        const state = this.state;
        const columns = [
            {
                name: USERREQUEST_SRNO_KEY,
                options: {
                    filter: false,
                    sort: false,
                },
            }, {
                name: "Name",
                label: masters_name,
                options: {
                    filter: false,
                    sort: false,
                },
            }, {
                name: "Email",
                label: USERREQUEST_EMAIL_KEY,
                options: {
                    filter: false,
                    sort: false,
                },
            },
            {
                name: "company_name",
                label: company_name,
                options: {
                    filter: true,
                    filterList: [],
                },
            },   
            {
                name: "created_at",
                label: USERREQUEST_CREATEDON_KEY,
                options: {
                    filter: false,
                    sort: false,
                },
            },
        ];

        const options = {
            filterType: 'dropdown',
            selectableRows: "none",
            print: false,
            download: false,
            viewColumns: false,
            filter: true,
            responsive: "stacked",
            textLabels: {
                body: {
                    noMatch: no_match_record_found,
                    toolTip: sort_text
                },
                pagination: {
                    rowsPerPage: rows_per_page_text,
                },
                toolbar: {
                    search: search_text
                },
            },
            rowsPerPage: 10,

        };
        return (
            <>
            <h3 className="page-title" style={{ marginBottom: "20px" }}>
                {client_signup_wizard_users}
            </h3>
            <Card >
                <CardBody >
                    <div className="div-spinner">
                        {this.state.loading && <Spinner size="500" className="success" />}
                    </div>
                    {!this.state.loading && (
                        <MuiThemeProvider theme={theme}>
                            <MUIDataTable
                                data={this.renderUsers(this.state.usersList)}
                                columns={columns}
                                options={options}
                            />
                        </MuiThemeProvider>
                    )}
                </CardBody>
            </Card>
            </>
        );
    }
}

allCompanyUsers.propTypes = {
    t: PropTypes.func.isRequired,
};

/* load reducers */
const mapStateToProps = (state) => {
    return {
        authReducer: state.authReducer,
        apolloClient: state.apolloClient,
        userReducer: state.userReducer,
        notification: state.notification,
        contentManagementReducer: state.contentManagementReducer,

    };
};

/* Load actions */
const mapDispatchToProps = (dispatch) => {
    return {
        getUsersListToSendNotification: (client, request) => {
            dispatch(actions.getUsersListToSendNotification(client, request));
        },
    }

};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("common")(allCompanyUsers)); // export component
