import React, { PureComponent } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, ButtonToolbar, Col,Spinner } from "reactstrap";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import * as actions from "../../../../Store/actions/index";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import { Icon,Tooltip } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import InfoIcon from '@material-ui/icons/Info';

let tlang  = ChangeAppLanguageFront.translateLanguage;

const material_list_sr_no = tlang('material_list_sr_no') || 'Sr.No';
const analysis_row_header = tlang('analysis_row_header') || 'Row of test family Header';
const analysis_sheet_name = tlang('analysis_sheet_name') || 'Sheet name';
const analysis_row_number_header = tlang('analysis_row_number_header') || 'Row number of test family Header';
const analysis_row_probing = tlang('analysis_row_probing') || "Row of Probing's header";
const analysis_row_number_probing = tlang('analysis_row_number_probing') || "Row number of probing's Header";  
const user_request_actions = tlang('user_request_actions') || 'Actions';
const analysis_update_setting = tlang('analysis_update_setting') || 'Update excel setting';
const analysis_add_setting = tlang('analysis_add_setting') || 'Add excel setting';   
const analysis_add_sheet_name = tlang('analysis_add_sheet_name') || 'Please add sheet name'; 
const analysis_row_of_header = tlang('analysis_row_of_header') || 'Row of test family Header (Ex.A-G)';   
const analysis_row_number_of_header = tlang('analysis_row_number_of_header') || 'Row number of test family header (Ex.10)';
const analysis_add_row_number_of_header = tlang('analysis_add_row_number_of_header') || 'Please add row number of header';  
const analysis_row_of_probing = tlang('analysis_row_of_probing') || "Row of probing's header (Ex.H-I)";
const analysis_add_row_of_probing = tlang('analysis_add_row_of_probing') || "Please add row of probing's header";
const analysis_row_number_of_probing = tlang('analysis_row_number_of_probing') || "Row number of probing's header (Ex.7)"; 
const analysis_add_row_number_of_probing = tlang('analysis_add_row_number_of_probing') || "Please add row number of probing's header";  
const save_btn = tlang('save_btn') || 'Save';
const common_btn_close = tlang('common_btn_close') || 'Close';
const analysis_delete_setting = tlang('analysis_delete_setting') || 'Delete Setting';
const analysis_delete_setting_are_you_sure_msg = tlang('analysis_delete_setting_are_you_sure_msg') || 'Are you sure you want to delete?';
const delete_staff_user_modal_cancel = tlang('delete_staff_user_modal_cancel') || 'Cancel';
const delete_staff_user_modal_delete = tlang('delete_staff_user_modal_delete') || 'Delete';
const sort_text = tlang('sort_text') || 'Sort';
const rows_per_page_text = tlang('rows_per_page_text') || 'Rows per page:';
const no_match_record_found = tlang('no_match_record_found') || 'Sorry, no matching records found';
const search_text = tlang('search_text') || 'Search';
const analysis_sheet_settings = tlang('analysis_sheet_settings') || 'Excel Settings';
const test_family_txt = tlang('test_family_txt')  || 'Test Family';
const client_sample = tlang('client_sample')  || 'Sample';
const client_settings_testfamily_header_desc = tlang('client_settings_testfamily_header_desc')  || 'Please add test family header starting row , ending row and - is required between both like (A-G)';
const client_settings_testfamily_row_desc = tlang('client_settings_testfamily_row_desc')  || 'Please add starting row number of test family header like 10';
const client_settings_sample_header_desc = tlang('client_settings_sample_header_desc')  || 'Please add sample header starting row , ending row and - is required between both like (H-I)';
const client_settings_sample_row_desc = tlang('client_settings_sample_row_desc')  || 'Please add starting row number of sample header like 7';
const cancel_btn = tlang('cancel_btn') || 'Cancel';  
const client_add_new_exception = tlang('client_add_new_exception') || 'Add a new Exception'; 
const client_add_more_exception = tlang('client_add_more_exception') || 'Add more Exception';
const client_add_exception_settings = tlang('client_add_exception_settings') || 'Add Exception Settings'; 
const client_exception_note_text = tlang('client_exception_note_text') || 'Note: These exceptions are considered as valid exceptions in analysis related processes.'; 

const IconWithTooltip = (props) => (
  <Tooltip title={props.text}>
    <InfoIcon color="error" fontSize="medium" />
  </Tooltip>
);
class ExcelSettings extends PureComponent {
  static propTypes = {
    i18n: PropTypes.shape({ changeLanguage: PropTypes.func }).isRequired,
    t: PropTypes.func.isRequired
  };
  constructor() {
    super();
    this.state = {
      setting:{
        id:"",
        sheet_name:"",
        rows_for_header:"",
        row_for_header_no:"",
        rows_for_head_probing:"",
        row_for_head_probing_no:""
      },
      settingAddEditModal:false,
      settingDeleteModal:false,
      exceptionAddEditModal:false,
      exceptionConst:[
        {
            constval: ""
        }
      ],
      deleteId:"",
      settingsList: [],
      error: [],
      getListLoading:true,
      removeExcelsheetName: ""  
    };
  }

  componentDidMount() {
     
  }

  componentWillMount() {
    /* load init actions */
    /* get Excel Settings List */
    if (this.props.authReducer.userData.projectid) {
      let request = {
        company_name: this.props.authReducer.userData.company_name
      };
      this.props.getExcelSettingsList(this.props.apolloClient.masters, request);
    }    
  }

  componentWillReceiveProps(props) {

    this.setState({
      userAccess: props.authReducer.userData.useraccesspage
    }, () => this.forceUpdate())

    /* receive notification */
    if (props.notification) {
      this.setState({
        loading: false
      });
      if (props.notification.success) {
        this.setState({
          settingAddEditModal:false,
          settingDeleteModal:false,
          exceptionAddEditModal: false
        })
      }
    }

    if (props.excelUploadReducer) {
      /* receive excel settings list */
      if (props.excelUploadReducer.settingsList) {
        this.setState({
          settingsList: props.excelUploadReducer.settingsList,
          getListLoading:false
        });
      }

      if (props.excelUploadReducer.exceptionSettingsList && props.excelUploadReducer.exceptionSettingsList != null) {
        
        if(props.excelUploadReducer.exceptionSettingsList.exception_strings.length > 0){
          let allexceptionlist = props.excelUploadReducer.exceptionSettingsList.exception_strings;

          let exceptionConst = [];
          allexceptionlist.map((p,l) => {
            let obj = {constval : p};
            exceptionConst.push(obj);
          })

          this.setState({
            exceptionConst: exceptionConst
          });
        }
      }



    }

  }

  /* render campaign list */
  rendersettingsList = () => {
    let settingsList = [];
    if (this.state.settingsList) {
      this.state.settingsList.map((val, index) => {
        settingsList.push([
          index + 1,
          val.sheet_name,
          val.rows_for_header,
          val.row_for_header_no,
          val.rows_for_head_probing,
          val.row_for_head_probing_no,
          val
        ]);
      });
    }
    return settingsList;
  };
 
  /* toggl Add New Analysis */
  togglAddNewAnalysis = (event, value = false) => {
    this.setState({
      addAnalysisModal: !this.state.addAnalysisModal
    })
    if (value) {
      this.setState({ selectedRecord: value })
    } else {
      this.setState({ selectedRecord: false })
    }
  }

  /* handle Change */
  handleChange = (field) => (event) => {
    let fieldValue = event.target.value
    let setting = this.state.setting
    let  error=this.state.error
   if(fieldValue){
   error= error.filter((e) =>e!==field)
   }
    if(field == "rows_for_header" || field == "rows_for_head_probing"){
      setting[field] = fieldValue.toUpperCase()
    } else {
      setting[field] = fieldValue
    }    
    this.setState({
      error:error,
      setting: setting,

    }, this.forceUpdate())
  }
  
  /* save Settings */
  saveSettings = () => {
    let error = [];
    if (this.state.setting.sheet_name == "") {
      error.push("sheet_name")
    }
    if (this.state.setting.rows_for_header == "") {
      error.push("rows_for_header")
    }
    if(this.state.setting.rows_for_header !== "" && (/(,|-)/.test(this.state.setting.rows_for_header) || this.state.setting.rows_for_header.length > 1)){
      error.push("rows_for_header")
    }
    if (this.state.setting.row_for_header_no == "") {
      error.push("row_for_header_no")
    }
    if (this.state.setting.rows_for_head_probing == "") {
      error.push("rows_for_head_probing")
    }
    if (this.state.setting.row_for_head_probing_no == "") {
      error.push("row_for_head_probing_no")
    }
    this.setState({
      error: error
    })
    if (error.length <= 0) {
      let request = {
        company_name:this.props.authReducer.userData.company_name,
        id: this.state.setting.id,
        sheet_name: this.state.setting.sheet_name,
        rows_for_header: this.state.setting.rows_for_header,
        row_for_header_no: this.state.setting.row_for_header_no,
        rows_for_head_probing: this.state.setting.rows_for_head_probing,
        row_for_head_probing_no: this.state.setting.row_for_head_probing_no,
      }
      this.props.saveExcelSetting(this.props.apolloClient.masters, request)
      this.setState({
        loading:true
      })
    }
  }
  
  /* toggle Setting Add/Edit Modal */
  toggleSettingAddEditModal = (event,value = false) => {
    
    let setting = this.state.setting
      if(value){        
          setting.id = value._id
          setting.sheet_name = value.sheet_name
          setting.rows_for_header = value.rows_for_header
          setting.row_for_header_no = value.row_for_header_no
          setting.rows_for_head_probing = value.rows_for_head_probing
          setting.row_for_head_probing_no = value.row_for_head_probing_no        
      } else {
          setting.id = ""
          setting.sheet_name = ""
          setting.rows_for_header = ""
          setting.row_for_header_no = ""
          setting.rows_for_head_probing = ""
          setting.row_for_head_probing_no = ""
      }
      this.setState({
        setting:setting,
        settingAddEditModal: !this.state.settingAddEditModal,
        error: [],
      })
  }


  toggleExceptionHandlingModal = (event,value=false) => {
    if(value){
      let requestInfo = {
        company_id: this.props.authReducer.userData.company_id
      };
      this.props.getExceptionSetting(this.props.apolloClient.masters, requestInfo);

      this.setState({
        exceptionAddEditModal: !this.state.exceptionAddEditModal,
        error: [],
      })
    }else{
      this.setState({
        exceptionAddEditModal: !this.state.exceptionAddEditModal,
        error: [],
      })
    }
  }


  handleChangeExceptionConstant = (keyname,index) => (event) => {
    let value = ""
    let tempExceptionConst = [...this.state.exceptionConst];
        value = event.target.value

        tempExceptionConst.map((exceVal,eind) => {
            if(eind == index){
              exceVal.constval = value;
            }
        })

      this.setState({
        exceptionConst: tempExceptionConst
      });

  }
  
  removeExceptionConstant = (index) => {

    let filteredExceptionConst = this.state.exceptionConst.filter((e_val,e_ind) => e_ind != index);

    this.setState({
      exceptionConst: filteredExceptionConst,
    });
    
}

  addNewExceptionConstant = () => {
    let myObj = {
            constval: ""
        };
    this.setState({
      exceptionConst: [...this.state.exceptionConst, myObj]
    });
  }



  saveExceptionSettings = () => {

    let listException = this.state.exceptionConst;
    let error = [];

    listException.map((p,i) => {

      //Replace comma to dot for ex. >0,09 replaced with >0.09
      if(p.constval.indexOf(',') > -1){
        p.constval = p.constval.replace(/,/g, '.');
      }

      if(p.constval.toString().trim() == ""){
        error.push(i);
      }
      p.constval = p.constval.toString().trim();
    })
    this.setState({
      error: error
    })
    
    if (error.length <= 0) {
      let allExceptions = listException.map(value => value.constval);

      let request = {
        company_id:this.props.authReducer.userData.company_id,
        exception_strings: JSON.stringify(allExceptions),
      }
      this.props.saveExceptionSetting(this.props.apolloClient.masters, request)
      this.setState({
        loading:true
      })

    }
    

  }

  /* toggle Setting Delete Modal */
  toggleSettingDeleteModal = (event,value = false) =>{

    let removeExcelsheetName = "";
    let deleteId = ""
    if(value){
      removeExcelsheetName = value.sheet_name;
      deleteId = value._id
    }
    this.setState({
      settingDeleteModal: !this.state.settingDeleteModal,
      deleteId:deleteId,
      removeExcelsheetName: removeExcelsheetName
    })
  }

  /* delete Setting */
  deleteSetting = () => {
      let request = {
        company_name: this.props.authReducer.userData.company_name,
        id:this.state.deleteId
      }
      this.props.deleteExcelSetting(this.props.apolloClient.masters, request)
      this.setState({
        loading:true
      })
  }

  render() {
    const { t } = this.props;

    let isDisabled = this.props.authReducer.userData.plan.planid == "AXD_PL_01" ? true : false;
    const columns = [
      {
        name: material_list_sr_no 
      },
      {
        name: analysis_sheet_name
      },
      {
        name: analysis_row_header
      },
      {
        name: analysis_row_number_header
      },
      {
        name: analysis_row_probing
      },
      {
        name: analysis_row_number_probing
      },
      {
        name: user_request_actions,
        options: {
          filter: false,
          customBodyRender: value => {
            if (value) {
              return (
                <>
                  <Button
                    title={analysis_update_setting}
                    className={"system-btn clear-border"}
                    outline={true}
                    color={"success"}
                    onClick={(event) => this.toggleSettingAddEditModal(event,value)}
                    disabled={isDisabled}
                  >
                    <Icon>edit</Icon>
                  </Button>
                  <Button
                    title={delete_staff_user_modal_delete}
                    className={"system-btn clear-border"}
                    outline={true}
                    color={"success"}
                    onClick={(event) => this.toggleSettingDeleteModal(event,value)}
                    disabled={isDisabled}
                  >
                    <Icon>delete</Icon>
                  </Button>
                </>
              );
            }
          }
        }
      }
    ];

    const options = {
      filterType: 'checkbox',
      selectableRows: "none",
      print: false,
      download: false,
      viewColumns: false,
      filter: false,
      responsive: "stacked",
      textLabels: {
        body: {
          noMatch: no_match_record_found,
          toolTip: sort_text
        },
        pagination: {
          rowsPerPage: rows_per_page_text,
        },
        toolbar: {
          search: search_text
        },
      },
      customToolbar: () => {
        return (
            <React.Fragment>
              {this.props.authReducer.userData.other_company == false &&
              <>
                <Button outline={true} color={'success'} className={"add-new-btn"} onClick={(event) => this.toggleSettingAddEditModal(event)} disabled={isDisabled}><Tooltip title={analysis_add_setting}><Icon>playlist_add</Icon></Tooltip></Button>
                <Button outline={true} color={'success'} className={"add-new-btn"} onClick={(event) => this.toggleExceptionHandlingModal(event,true)} disabled={isDisabled}><Tooltip title={client_add_exception_settings}><Icon>settings</Icon></Tooltip></Button>
              </>
              }
                
            </React.Fragment>
        );
      }
    };

    return (
      <div>
      <h3 className="page-title" style={{ marginBottom: "20px" }}>
        {analysis_sheet_settings}
      </h3>
        <div>
          {this.state.getListLoading && (
            <div className="div-spinner"><Spinner size="sm" className="btn-spinner spinner_color spinner_size" /></div>
          )}
          {!this.state.getListLoading && (
          <MUIDataTable
            data={this.rendersettingsList()}
            columns={columns}
            options={options}
          />
          )}
        </div>
        <div>
        <Modal
            isOpen={this.state.exceptionAddEditModal}
            toggle={(event) => this.toggleExceptionHandlingModal(event)}
            className={`modal-dialog--success modal-dialog--header width__70`}
          >
            <div className="modal__header">
              <button className="lnr lnr-cross modal__close-btn" type="button" onClick={(event) => this.toggleExceptionHandlingModal(event)} />
              <h4 className="bold-text  modal__title">
              {client_add_exception_settings}
              </h4>
            </div>
            <div className="modal__body">
            <Row>
              <Col xs={8} sm={8} md={8} style={{marginBottom: "4%"}} className="margin__top_15">
                <label>{client_exception_note_text}</label>
              </Col>

              <Col xs={4} sm={4} md={4} style={{marginBottom: "4%"}} className="margin__top_15">
                <a style={{color: "#E64823", float: "right"}} href="javascript:void(0)" onClick={ this.addNewExceptionConstant }>{client_add_more_exception}</a>
              </Col>
            </Row>
            
            <Row>
                {this.state.exceptionConst.map((exceVal, eindex) => {

                  let constval = exceVal.constval;
                  return (
                    <>
                  
                        <Col style={{}} xs={3} sm={3} md={3} className="">
                                <TextField
                                    required
                                    fullWidth
                                    id={"constval"+eindex}
                                    type='text'
                                    label={client_add_new_exception}
                                    value={constval}
                                    onChange={this.handleChangeExceptionConstant("constval", eindex)}
                                    error={this.state.error.includes(eindex)}
                                    inputProps={{  }}
                                />
                          </Col> 
                          <Col style={{float: "left"}} xs={1} sm={1} md={1} className="margin__top_15">
                              <Button className={"system-btn clear-border"} outline={true} color="success" size="sm" onClick={(e)=>this.removeExceptionConstant(eindex)}>
                                  <Tooltip title={cancel_btn}><Icon>highlight_off</Icon></Tooltip>
                              </Button>
                          </Col>
                    </>
                  );

                })}
            </Row>

            </div>
            <ButtonToolbar className="modal__footer">              
              <Button outline={true} color={'success'} onClick={(event) => this.toggleExceptionHandlingModal(event)}>{common_btn_close}</Button>
              <Button color={'success'} onClick={(event) => this.saveExceptionSettings(event)} disabled={this.state.loading}>{this.state.loading && <Spinner size="sm" className="btn-spinner" />}{save_btn}</Button>
            </ButtonToolbar>
          </Modal>



          <Modal
            isOpen={this.state.settingAddEditModal}
            toggle={(event) => this.toggleSettingAddEditModal(event)}
            className={`modal-dialog--success modal-dialog--header width__70`}
          >
            <div className="modal__header">
              <button className="lnr lnr-cross modal__close-btn" type="button" onClick={(event) => this.toggleSettingAddEditModal(event)} />
              <h4 className="bold-text  modal__title">
              { this.state.setting.id != "" ? analysis_update_setting : analysis_add_setting}
              </h4>
            </div>
            <div className="modal__body">
              <Row>
                <Col xs={12} sm={12} md={12} className="margin__top_15">
                  <TextField
                    required
                    fullWidth                    
                    id="sheet_name"
                    label={analysis_sheet_name}
                    value={this.state.setting.sheet_name}
                    onChange={this.handleChange("sheet_name")}
                    error={this.state.error.includes("sheet_name")}
                  />
                  {this.state.error.includes("sheet_name") && (
                    <span className="error-message">
                      {analysis_add_sheet_name}
                    </span>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={2} md={2} className="margin__top_15">
                  <h5 className="bold-text orange_color margin__top_15">{test_family_txt}</h5>
                </Col>
                <Col xs={12} sm={5} md={5} className="margin__top_15">
                  <TextField
                    required
                    fullWidth
                    id="rows_for_header"
                    label={analysis_row_of_header}
                    value={this.state.setting.rows_for_header}
                    onChange={this.handleChange("rows_for_header")}
                    error={this.state.error.includes("rows_for_header")}
                    InputProps={{
                        endAdornment: (
                          <IconWithTooltip
                            text={client_settings_testfamily_header_desc}
                            placement="top"
                          />
                        )
                    }}
                  />
                  {this.state.error.includes("rows_for_header") && (
                    <span className="error-message">
                      {analysis_row_of_header}
                    </span>
                  )}
                </Col>
                <Col xs={12} sm={5} md={5}  className="margin__top_15">
                  <TextField
                    required
                    fullWidth
                    type="number"
                    id="row_for_header_no"
                    label={analysis_row_number_of_header}
                    value={this.state.setting.row_for_header_no}
                    onChange={this.handleChange("row_for_header_no")}
                    error={this.state.error.includes("row_for_header_no")}
                    InputProps={{
                      endAdornment: (
                        <IconWithTooltip
                          text={client_settings_testfamily_row_desc}
                          placement="top"
                        />
                      )
                    }}
                  />
                  {this.state.error.includes("row_for_header_no") && (
                    <span className="error-message">
                      {analysis_add_row_number_of_header}
                    </span>
                  )}
                </Col>
                </Row>
                <Row>
                <Col xs={12} sm={2} md={2} className="margin__top_15">
                  <h5 className="bold-text orange_color margin__top_15">{client_sample}</h5>
                </Col>
                <Col xs={12} sm={5} md={5} className="margin__top_15">
                  <TextField
                    required
                    fullWidth
                    id="rows_for_head_probing"
                    label={analysis_row_of_probing}
                    value={this.state.setting.rows_for_head_probing}
                    onChange={this.handleChange("rows_for_head_probing")}
                    error={this.state.error.includes("rows_for_head_probing")}
                    InputProps={{
                      endAdornment: (
                        <IconWithTooltip
                          text={client_settings_sample_header_desc}
                          placement="top"
                        />
                      )
                    }}
                  />
                  {this.state.error.includes("rows_for_head_probing") && (
                    <span className="error-message">
                      {analysis_add_row_of_probing}
                    </span>
                  )}
                </Col>
                <Col xs={12} sm={5} md={5} className="margin__top_15">
                  <TextField
                    required
                    fullWidth
                    type="number"                    
                    id="row_for_head_probing_no"
                    label={analysis_row_number_of_probing}
                    value={this.state.setting.row_for_head_probing_no}
                    onChange={this.handleChange("row_for_head_probing_no")}
                    error={this.state.error.includes("row_for_head_probing_no")}
                    InputProps={{
                      endAdornment: (
                        <IconWithTooltip
                          text={client_settings_sample_row_desc}
                          placement="top"
                        />
                      )
                    }}
                  />
                  {this.state.error.includes("row_for_head_probing_no") && (
                    <span className="error-message">
                      {analysis_add_row_number_of_probing}
                    </span>
                  )}
                </Col>
              </Row>
            </div>
            <ButtonToolbar className="modal__footer">              
              <Button outline={true} color={'success'} onClick={(event) => this.toggleSettingAddEditModal(event)}>{common_btn_close}</Button>
              <Button color={'success'} onClick={(event) => this.saveSettings(event)} disabled={this.state.loading}>{this.state.loading && <Spinner size="sm" className="btn-spinner" />}{save_btn}</Button>
            </ButtonToolbar>
          </Modal>


          <Modal
                isOpen={this.state.settingDeleteModal}
                toggle={(event) => this.toggleSettingDeleteModal(event)}
                className={`modal-dialog--danger modal-dialog--header`}
            >
                <div className="modal__header">
                    <button className="lnr lnr-cross modal__close-btn" type="button" onClick={(event) => this.toggleSettingDeleteModal(event)} />
                    <h4 className="bold-text  modal__title">{analysis_delete_setting}</h4>
                </div>
                <div className="modal__body">
                    <Row>
                        <Col md={12} lg={12} className="theme-light">
                            {analysis_delete_setting_are_you_sure_msg + " ( "+this.state.removeExcelsheetName+" ) ?"}  
                        </Col>
                    </Row>
                </div>

                <ButtonToolbar className="modal__footer">
                    <Button outline={true} color={'success'} onClick={(event) => this.toggleSettingDeleteModal(event)}>{delete_staff_user_modal_cancel}</Button>
                    <Button color={'success'} onClick={() => this.deleteSetting()} disabled={this.state.loading}>{this.state.loading && <Spinner size="sm" className="btn-spinner" />} {delete_staff_user_modal_delete}</Button>
                </ButtonToolbar>
            </Modal>

        </div>
      </div>
    );
  }
}

/* load reducers */
const mapStateToProps = state => {
  return {
    authReducer: state.authReducer,
    apolloClient: state.apolloClient,
    notification: state.notification,
    masterReducer: state.masterReducer,
    excelUploadReducer: state.excelUploadReducer
  };
};

/* Load actions */
const mapDispatchToProps = dispatch => {
  return {
    getExcelSettingsList: (masters, request) => {
      dispatch(actions.getExcelSettingsList(masters, request))
    },
    getExceptionSetting: (masters, request) => {
      dispatch(actions.getExceptionSetting(masters, request))
    },
    saveExcelSetting: (masters, request) => {
      dispatch(actions.saveExcelSetting(masters, request))
    },
    saveExceptionSetting: (masters, request) => {
      dispatch(actions.saveExceptionSetting(masters, request))
    },
    deleteExcelSetting: (masters, request) => {
      dispatch(actions.deleteExcelSetting(masters, request))
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(ExcelSettings)); // export component
