/**
 * prepare data for compare analysis
 * campaignId: string,
 * 	parentIds: [
 * 		parentIds: [
 * 			parentId: string,
 * 			childIds: string[]
 * 		]
 * 		samples: [: string[]
 * ]
 *
 * @param {array} all_excel_uploaded_sample
 */

//For checking selected disposal family and child ids with excel analysis data that all pollutants of selected disposal are present in analysis campaignwise
export const CompareExcelPollutantWithDisposalPollutant = (
	h,
	all_excel_uploaded_sample,
	alldesposalRuleList,
	needMissingPollutantsArr,
	preparedData
) => {
	const getChildPollutantIdsOfMatchedDisposal = (d, matchedDisposalId, alldesposalRuleList) => {
		let pollutantDataOfFamily = alldesposalRuleList.filter(
			l => l.desposal_id === matchedDisposalId && d === l.test_id
		);
		return pollutantDataOfFamily.map(value => value.child_id.toString());
	};

	const getChildPollutantIdsOfAnalysis = (d, all_excel_uploaded_sample, h, preparedData) => {
		if (preparedData?.size) {
			const camp = preparedData.get(h.sample_campaign);
			if (camp) {
				const sample = camp.samples.get(h.sample_name);
				if (sample) {
					const test = sample.tests.get(d);
					if (test) return [...test.childIds];
				}
			}
			return [];
		}

		let strArr = [];
		let pollutantDataOfFamily = all_excel_uploaded_sample.filter(
			l =>
				l.parent_testfamily_id === d &&
				l.sample_name === h.sample_name &&
				l.campaignid === h.sample_campaign
		);
		if (pollutantDataOfFamily.length > 0) {
			let childpollutantIdsOfFamily = pollutantDataOfFamily.map(value => value.tst_child_id);
			strArr = childpollutantIdsOfFamily.map(function(e) {
				return e.toString();
			});
			strArr = [...new Set(strArr)];
			return strArr;
		} else {
			return strArr;
		}
	};

	if (typeof h.last_matched_desposal.desposal_id !== 'undefined') {
		//Initialization of object
		let missingPollutant = {
			status: true,
			requiredPollutant: [],
		};

		//Get all unique parent family ids from analysis
		let analysisFamilyIds = [];
		if (preparedData?.size) {
			const camp = preparedData.get(h.sample_campaign);
			if (camp) analysisFamilyIds = [...camp.parentIds];
		} else {
			analysisFamilyIds = [
				...new Set(
					all_excel_uploaded_sample.map(
						item => item.campaignid === h.sample_campaign && item.parent_testfamily_id
					)
				),
			];
			analysisFamilyIds = analysisFamilyIds.filter(x => x !== false);
		}

		//Get matched disposal id of sample
		let matchedDisposalId = h.last_matched_desposal.desposal_id;

		//Get all unique parent family ids from selected disposal
		let disposalFamilyIds = [
			...new Set(
				alldesposalRuleList.map(item => item.desposal_id === matchedDisposalId && item.test_id)
			),
		];
		disposalFamilyIds = disposalFamilyIds.filter(x => x !== false);

		//Get all unique parent family ids from analysis

		//analysis parent family ids must be greate than or equal selected disposal ids of particular sample
		if (analysisFamilyIds.length < disposalFamilyIds.length) {
			missingPollutant.status = false;
		}

		//List all families who exist in matched disposal but not uploaded in excel analysis
		let notExistPollutantFamilyInAnalysis = disposalFamilyIds.filter(
			n => analysisFamilyIds.indexOf(n) < 0
		);
		if (notExistPollutantFamilyInAnalysis.length) {
			missingPollutant.status = false;
		}

		//analysis parent family ids greate than or equal selected disposal ids OR if user needs missing pollutants ids
		// if (
		// 	(analysisFamilyIds.length >= disposalFamilyIds.length &&
		// 		missingPollutant.status === true) ||
		// 	needMissingPollutantsArr
		// )
		if (analysisFamilyIds.length >= disposalFamilyIds.length || needMissingPollutantsArr) {
			//Loop through matched disposal parent family ids to check all childs of disposal parent family present in uploaded excel

			disposalFamilyIds.forEach(d => {
				let childPollutantIdsOfMatchedDisposal = getChildPollutantIdsOfMatchedDisposal(
					d,
					matchedDisposalId,
					alldesposalRuleList
				); //child ids of looped disposal family
				let childPollutantIdsOfAnalysis = getChildPollutantIdsOfAnalysis(
					d,
					all_excel_uploaded_sample,
					h,
					preparedData
				); //child ids from uploaded excel data campaign wise

				//length of analysis child family ids of this looped family must be greater than or equal selected disposal childs ids
				if (childPollutantIdsOfAnalysis.length < childPollutantIdsOfMatchedDisposal.length) {
					var getNotUploadedPollutantIds = childPollutantIdsOfMatchedDisposal.filter(function(n) {
						return !this.has(n);
					}, new Set(childPollutantIdsOfAnalysis));
					if (getNotUploadedPollutantIds.length > 0) {
						missingPollutant.requiredPollutant.push(...getNotUploadedPollutantIds);
					}

					missingPollutant.status = false;
				}

				//length of analysis child family ids of this looped family greater than or equal selected disposal childs ids
				if (childPollutantIdsOfAnalysis.length >= childPollutantIdsOfMatchedDisposal.length) {
					//Check all child ids of looped parent family of selected disposal present in the analysis parent child ids array
					var res1 = childPollutantIdsOfMatchedDisposal.filter(function(n) {
						return !this.has(n);
					}, new Set(childPollutantIdsOfAnalysis));
					if (res1.length > 0) {
						missingPollutant.requiredPollutant.push(...res1);
						missingPollutant.status = false;
					}
				}
			});
		}

		return missingPollutant;
	} else {
		return '';
	}
};

export const prepareDataForAnalysis = all_excel_uploaded_sample => {
	const newData = new Map();
	console.time('prepareData');
	for (const item of all_excel_uploaded_sample) {
		let data = newData.get(item.campaignid);
		if (!data) {
			newData.set(item.campaignid, {
				campaignId: item.campaignid,
				parentIds: new Set(),
				samples: new Map(),
			});
			data = newData.get(item.campaignid);
		}
		let sample = data.samples.get(item.sample_name);
		if (!sample) {
			data.samples.set(item.sample_name, { sample_name: item.sample_name, tests: new Map() });
			sample = data.samples.get(item.sample_name);
		}
		data.parentIds.add(item.parent_testfamily_id);
		let test = sample.tests.get(item.parent_testfamily_id);
		if (!test) {
			sample.tests.set(item.parent_testfamily_id, {
				parentId: item.parent_testfamily_id,
				childIds: new Set(),
			});
			test = sample.tests.get(item.parent_testfamily_id);
		}
		// data.parentIds.add(item.parent_testfamily_id);
		test.childIds.add(item.tst_child_id.toString());
	}
	// console.log('newData', newData);
	// const ret = Array.from(newData.values()).map(e => ({
	// 	campaignId: e.campaignId,
	// 	samples: Array.from(e.samples.values()).map(s => ({
	// 		sample_name: s.sample_name,
	// 		tests: Array.from(s.tests.values()).map(t => ({
	// 			parentId: t.parentId,
	// 			childIds: [...t.childIds],
	// 		})),
	// 	})),
	// }));
	// console.log('ret', ret);
	console.timeEnd('prepareData');
	return newData;
};

export const compareAnalysisAndDisposalPollutant = request => {
	// ================================ LOGIC START HERE ================================

	//let disposalPollutantArr = [];
	let missingPollutantIds = [];
	let returnInfo = {};

	//Get all Requested parameters
	let all_smpl_info = request.all_smpl_info;
	let all_excel_uploaded_sample = request.all_excel_uploaded_sample;
	let alldesposalRuleList = request.alldesposalRuleList;
	let needMissingPollutantsArr = request.needMissingPollutantsArr;
	const preparedData = request.preparedData;

	//Loop through all samples that all pollutants are uploaded in excel file as compare to selected disposal family
	all_smpl_info.forEach(sampData => {
		if (
			sampData.desposal_id !== '' &&
			typeof sampData.desposal_id !== 'undefined' &&
			sampData?.chkForMissingPollutant === true
		) {
			let h = {
				sample_name: sampData.sample_name,
				sample_campaign: sampData.sample_campaign,
				last_matched_desposal: {
					desposal_id: sampData.desposal_id,
				},
			};

			let checkAllpoolutantPresent = '';

			//check that pollutant checking process has checked earlier if yes then no need to check it again and if no then check it
			// let checkExist = disposalPollutantArr.filter(
			// 	l =>
			// 		l.campaignid === h.sample_campaign && l.disposalid === h.last_matched_desposal.desposal_id
			// );
			// if (checkExist.length === 0) {
			// 	//Get Missing pollutant status and its ids
			// 	let poolutantPresent = CompareExcelPollutantWithDisposalPollutant(
			// 		h,
			// 		all_excel_uploaded_sample,
			// 		alldesposalRuleList,
			// 		needMissingPollutantsArr
			// 	);

			// 	if (poolutantPresent.requiredPollutant.length > 0) {
			// 		missingPollutantIds.push(...poolutantPresent.requiredPollutant);
			// 	}
			// 	checkAllpoolutantPresent = poolutantPresent.status;

			// 	disposalPollutantArr.push({
			// 		campaignid: h.sample_campaign,
			// 		disposalid: h.last_matched_desposal.desposal_id,
			// 		disposalstatus: checkAllpoolutantPresent,
			// 	});
			// } else {
			// 	//Get the status directly, no need to check it again for another sample.
			// 	checkAllpoolutantPresent = checkExist[0].disposalstatus;
			// }

			//Get Missing pollutant status and its ids (Now checked each sample wise due to user is able to upload multiple sheet for one campaign)
			let poolutantPresent = CompareExcelPollutantWithDisposalPollutant(
				h,
				all_excel_uploaded_sample,
				alldesposalRuleList,
				needMissingPollutantsArr,
				preparedData
			);

			if (poolutantPresent.requiredPollutant.length > 0) {
				missingPollutantIds.push(...poolutantPresent.requiredPollutant);
			}
			checkAllpoolutantPresent = poolutantPresent.status;

			//Make blank selected disposal id
			if (checkAllpoolutantPresent === false) {
				sampData.desposal_id = '';

				if (typeof sampData.desposal_name !== 'undefined') {
					sampData.desposal_name = '';
				}

				if (typeof sampData.desposal_color !== 'undefined') {
					sampData.desposal_color = '';
				}
			}
		}
	});

	//Make unique arr of missing pollutants ids
	if (missingPollutantIds.length > 0) {
		missingPollutantIds = [...new Set(missingPollutantIds)];
	}

	returnInfo.all_smpl_info = all_smpl_info;

	//send missingPollutantIds arr only if user wants.
	if (needMissingPollutantsArr) {
		returnInfo.missingPollutantIds = missingPollutantIds;
	}

	return returnInfo;

	// ================================ LOGIC END HERE ================================
};
