import * as turf from '@turf/turf';
import proj4 from './proj4';

export function drawGridOnCanvas(grid) {
	const bw = 40 * grid.columns;
	const bh = 40 * grid.rows;

	const p = 0; //padding
	const cw = bw + p * 2 + 1;
	const ch = bh + p * 2 + 1;

	const canvas = document.createElement('canvas');
	canvas.id = grid.id;
	canvas.width = cw;
	canvas.height = ch;
	document.body.appendChild(canvas);
	const context = canvas.getContext('2d');
	if (!context) {
		console.error('context');
		return;
	}

	for (let x = 0; x <= bw; x += 40) {
		context.moveTo(0.5 + x + p, p);
		context.lineTo(0.5 + x + p, bh + p);
	}
	for (let x = 0; x <= bh; x += 40) {
		context.moveTo(p, 0.5 + x + p);
		context.lineTo(bw + p, 0.5 + x + p);
	}
	context.strokeStyle = grid.strokecolor;
	context.stroke();
	return canvas;
}

export function createGridFeature(grid) {
	const coords = [...grid.coordinates, grid.coordinates[0]];
	const polygon = turf.polygon([coords], {
		name: grid.name,
		strokecolor: grid.strokecolor,
		rows: Number(grid.rows),
		columns: Number(grid.columns),
	});
	return polygon;
}

function calculateGrid(grid) {
	// extract the coordinates from the grid
	const pt1 = grid.coordinates[0];
	const pt2 = grid.coordinates[1];
	const pt3 = grid.coordinates[2];
	const pt4 = grid.coordinates[3];
	// calculate the distance between the points
	const line1 = turf.lineString([pt1, pt2]);
	const line2 = turf.lineString([pt4, pt3]);
	const distc = turf.distance(pt1, pt2, { units: 'kilometers' });
	const distr = turf.distance(pt2, pt3, { units: 'kilometers' });
	const nbc = grid.meta.columns;
	const nbr = grid.meta.rows;
	// step
	const dc = distc / nbc;
	const dr = distr / nbr;

	// calculate points on the grid
	const pts = [];
	pts.push(turf.point(pt1, { layer: 'first', row: 0, col: 0 }));
	for (let c = 1; c < nbc; c++) {
		const pt = turf.along(line1, c * dc, { units: 'kilometers' });
		pt.properties.layer = 'first';
		pt.properties.row = 0;
		pt.properties.col = c;
		pts.push(pt);
	}
	pts.push(turf.point(pt2, { layer: 'first', row: 0, col: nbc }));
	pts.push(turf.point(pt4, { layer: 'last', row: nbr, col: 0 }));
	for (let c = 1; c < nbc; c++) {
		const pt = turf.along(line2, c * dc, { units: 'kilometers' });
		pt.properties.layer = 'last';
		pt.properties.row = nbr;
		pt.properties.col = c;
		pts.push(pt);
	}
	pts.push(turf.point(pt3, { layer: 'last', row: nbr, col: nbc }));
	for (let c = 0; c <= nbc; c++) {
		const line = turf.lineString([
			pts[c].geometry.coordinates,
			pts[nbc + 1 + c].geometry.coordinates,
		]);
		for (let r = 1; r < nbr; r++) {
			const pt = turf.along(line, r * dr, { units: 'kilometers' });
			pt.properties.layer = `intermediate`;
			pt.properties.row = r;
			pt.properties.col = c;
			pts.push(pt);
		}
	}
	// create grid cells as polygons
	const polys = [];
	function findPt(r, c) {
		const pt = pts.find(pt => pt.properties.row === r && pt.properties.col === c);
		if (pt) return pt.geometry.coordinates;
		return [0, 0];
	}

	for (let c = 0; c < nbc; c++) {
		for (let r = 0; r < nbr; r++) {
			const pt1 = findPt(r, c);
			const pt2 = findPt(r, c + 1);
			const pt3 = findPt(r + 1, c + 1);
			const pt4 = findPt(r + 1, c);
			const poly = turf.polygon([[pt1, pt2, pt3, pt4, pt1]], { ...grid.meta, row: r, col: c });
			polys.push(poly);
		}
	}
	return polys;
}

export class Grid {
	constructor(boundaries, probing, gridsObjects, coordDest, coordOrg) {
		this.probing = probing;
		this.boundaries = boundaries;
		this.gridsObjects = gridsObjects;
		this.poly = [];
		this.converted = [];
		this.coordDest = coordDest;
		this.coordOrg = coordOrg;
	}

	calcul = () => {
		// boundary polygon
		for (const boundary of this.boundaries) {
			if (!boundary.poly) this.makeBoundaryPolygon(boundary);
		}
		const bboxFeatures = turf.featureCollection(this.boundaries.map(b => b.poly));
		for (let b = 0; b < bboxFeatures.features.length; b += 1)
			bboxFeatures.features[b].properties.id = b;
		// grid
		const probingGrid = [];
		for (const grdObj of this.gridsObjects) {
			const polys = calculateGrid(grdObj);
			probingGrid.push(...polys);
		}

		// boundary constraint
		const probingGridPolyList = [];
		for (const cell of probingGrid) {
			if (cell) {
				cell.properties.probs = [];
				let avgZ = 0;
				for (const prob of this.probing) {
					if (prob) {
						const pointLL = proj4(this.coordOrg, this.coordDest, [prob.x, prob.y]);
						const pt = turf.point(pointLL);
						if (turf.booleanContains(cell, pt)) {
							cell.properties.probs.push({ id: prob.id, z: prob.z });
							avgZ += prob.z;
							// for (const bbox of bboxFeatures.features) {
							// 	const poly = turf.intersect(cell, bbox);
							// 	if (poly) {
							// 		poly.properties = {
							// 			meta: { ref: prob.id, z: prob.z, idBound: bbox.properties.id },
							// 		};
							// 		probingGridPolyList.push(poly);
							// 	}
							// }
						}
					}
				}
				cell.properties.z = cell.properties.probs.length ? avgZ / cell.properties.probs.length : 0;
				cell.properties.ref = `GRID-${cell.properties.probs.length + 1}`;
				probingGridPolyList.push(cell);
			}
		}
		this.poly = probingGridPolyList;
	};

	makeBoundaryPolygon(boundary) {
		const boundaryCoordinates = boundary.map(b => [b.x, b.y]);

		//Make sure that first and last coordinates are equal
		if (
			boundaryCoordinates[0][0] !== boundaryCoordinates[boundaryCoordinates.length - 1][0] &&
			boundaryCoordinates[0][1] !== boundaryCoordinates[boundaryCoordinates.length - 1][1]
		) {
			boundaryCoordinates.push(boundaryCoordinates[0]);
		}

		const boundaryPoly = turf.polygon([boundaryCoordinates], {
			name: 'BoundaryPoly',
		});
		boundary.poly = { ...boundaryPoly };
	}

	convert(coordonneesOrg, coordonneesDest = 'WGS84') {
		for (const feat of this.poly) {
			for (let p = 0; p < feat.geometry.coordinates[0].length; p++)
				feat.geometry.coordinates[0][p] = proj4(coordonneesOrg, coordonneesDest, [
					feat.geometry.coordinates[0][p][0],
					feat.geometry.coordinates[0][p][1],
				]);
			this.converted.push({ ...feat });
		}
		return this.converted;
	}
}
