import React, { useState } from 'react';
import { Button, ButtonToolbar, Col, Modal, Row, Spinner } from 'reactstrap';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import * as actions from '../../../../Store/actions/index';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import moment from 'moment-timezone';
import __ from 'underscore';
import MapComponent from '../components/viewMap';
import styled from 'styled-components';
import StakeHolder from '../components/campaign/stakeHolder';
import { getCoordsFromAddress } from '../../../lib/proj4';

moment.tz.setDefault('Europe/Paris');

let tlang = ChangeAppLanguageFront.translateLanguage;

const project_description = tlang('project_description') || 'Project Description';
const client_project_project_details = tlang('client_project_project_details') || 'Project Details';
const common_btn_okay = tlang('common_btn_okay') || 'Okay';
const client_sign_in_up_form_project_address =
	tlang('client_sign_in_up_form_project_address') || 'Address of Project';
const client_project_start_date = tlang('client_project_start_date') || 'Start Date';
const client_project_end_date = tlang('client_project_end_date') || 'End Date';
const client_project_token = tlang('client_project_token') || 'Token';
const project_stakeholders = tlang('project_stakeholders') || 'Stakeholders';
const no_records_found = tlang('client_cmp_no_records_found') || 'No records found';
const project_documents = tlang('project_documents') || 'Documents';
const client_cmp_document_pdf = tlang('client_cmp_document_pdf') || 'PDF';
const client_cmp_document_excel = tlang('client_cmp_document_excel') || 'Excel';
const client_cmp_document_images = tlang('client_cmp_document_images') || 'Images';
const client_cmp_document_other = tlang('client_cmp_document_other') || 'Other';
const client_project_county_number = tlang('client_project_county_number') || 'County Number';

const DOCUMENTS_FILTERS = {
	pdf: ['pdf'],
	excel: ['xls', 'xlsx', 'xlsm', 'xlsb', 'xltx'],
	images: ['jpeg', 'jpg', 'png', 'bmp'],
};

export function getDocumentList(attachments) {
	const documents = { all: [], pdf: [], excel: [], images: [], other: [] };

	for (const att of attachments) {
		const doc = { name: Object.keys(att)[0], displayName: Object.keys(att)[0].split('-')[0], url: Object.values(att)[0] };
		const ext = doc.url.split('.').pop().toLowerCase();
		doc.name = `${doc.name}.${ext}`;
		doc.displayName = `${doc.displayName}.${ext}`;
		Object.assign(doc, { ext: ext, rev_id: att.rev_id })
		if (DOCUMENTS_FILTERS.pdf.includes(ext)) {
			documents.pdf = [...documents.pdf, doc];
		} else if (DOCUMENTS_FILTERS.excel.includes(ext)) {
			documents.excel = [...documents.excel, doc];
		} else if (DOCUMENTS_FILTERS.images.includes(ext)) {
			documents.images = [...documents.images, doc];
		} else {
			documents.other = [...documents.other, doc];
		}
	}
	return documents;
}

export default function ModalProjectDetails({ isOpen, onClose, projectId }) {
	const dispatch = useDispatch();
	const apolloClient = useSelector(state => state.apolloClient);
	const authReducer = useSelector(state => state.authReducer);
	const projectReducer = useSelector(state => state.projectReducer);
	const [project, setProject] = useState({});
	const [documents, setDocuments] = useState({
		pdf: [],
		excel: [],
		images: [],
		other: [],
	});

	useEffect(() => {
		if (!projectId) return;
		const req = {
			id: projectId,
			company: authReducer.userData.company_name,
		};
		dispatch(actions.getprojectDetails(apolloClient.project, req));
	}, [projectId]);

	useEffect(() => {
		if (!projectReducer.projectDetails || !projectReducer.projectDetails.length) return;
		const project = projectReducer.projectDetails[0];
		const attachments = JSON.parse(project.attachments);

		setProject({
			title: project.name,
			purpose: project.purpose,
			address: project.address,
			county: project.county,
			lat: project.latitude,
			lng: project.longitude,
			projectToken: project.companytokenid,
			start: moment(project.startDate),
			end: moment(project.endDate),
			members: project.active_member.concat(project.invite_member),
			projectid: project.projectid,
			attachments,
			getDetailsLoading: false,
		});

		setDocuments(getDocumentList(attachments));
	}, [projectReducer?.projectDetails]);

	return (
		<Modal
			isOpen={isOpen}
			toggle={onClose}
			className={`modal-dialog--success modal-dialog--header width__70`}
		>
			<div className="modal__header">
				<button className="lnr lnr-cross modal__close-btn" type="button" onClick={onClose} />
				<h4 className="bold-text modal__title">{client_project_project_details}</h4>
			</div>
			<div className="modal__body" style={{ paddingLeft: '37px' }}>
				{__.isEmpty(project) ? (
					<Row>
						<Col xs={12} className="margin__top text-center">
							<Spinner size="sm" className="btn-spinner spinner_color spinner_size" />
						</Col>
					</Row>
				) : (
					<>
						<Row>
							<Col xs={12}>
								<h3 className="bold-text orange_color">{project.title}</h3>
							</Col>
						</Row>
						<Row>
							<Col xs={12} sm={8} className=" border-class">
								<MapComponent projectId={projectId} control={'toggle'} />
							</Col>
							<Col xs={12} sm={4}>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<ProjectData label={project_description} data={project.purpose} />
									<ProjectData
										label={client_sign_in_up_form_project_address}
										data={project.address}
									/>
									<ProjectData
										label={client_project_county_number}
										data={
											project.county
												? `${project.county} (${getCoordsFromAddress(
													project.county,
													project.address
												) || '-'})`
												: '-'
										}
									/>
									<ProjectData
										label={client_project_start_date}
										data={moment(project.start).format('DD/MM/YYYY HH:mm')}
									/>
									<ProjectData
										label={client_project_end_date}
										data={moment(project.end).isValid() && moment(project.end).format('DD/MM/YYYY HH:mm')}
									/>
									<ProjectData label={client_project_token} data={project.projectToken} />
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} className="margin__top">
								<h3 className="bold-text orange_color">{project_stakeholders}</h3>
							</Col>
							<Col xs={12}>
								<StakeHolderContainer>
									{project.members.length === 0 ? (
										<p>{no_records_found}</p>
									) : (
										project.members.map((member, idx) => (
											<StakeHolder
												key={member.id}
												member={member}
												idx={idx}
												title={project.title}
											/>
										))
									)}
								</StakeHolderContainer>
							</Col>
						</Row>
						<Row>
							<Col xs={12} className="margin__top">
								<h3 className="bold-text orange_color">{project_documents}</h3>
							</Col>
						</Row>
						<Row>
							<Col xs={3}>
								<div className="border-class p-3">
									<h4 className="bold-text orange_color">{client_cmp_document_pdf}</h4>
									<ol>
										{documents.pdf.map(doc => (
											<li>
												<a key={doc.name} href={doc.url} target="_blank" rel="noreferrer">
													{doc.name}
												</a>
											</li>
										))}
									</ol>
								</div>
							</Col>
							<Col xs={3}>
								<div className="border-class p-3">
									<h4 className="bold-text orange_color">{client_cmp_document_excel}</h4>
									<ol>
										{documents.excel.map(doc => (
											<li>
												<a key={doc.name} href={doc.url} target="_blank" rel="noreferrer">
													{doc.name}
												</a>
											</li>
										))}
									</ol>
								</div>
							</Col>
							<Col xs={3}>
								<div className="border-class p-3">
									<h4 className="bold-text orange_color">{client_cmp_document_images}</h4>
									<ol>
										{documents.images.map(doc => (
											<li>
												<a key={doc.name} href={doc.url} target="_blank" rel="noreferrer">
													{doc.name}
												</a>
											</li>
										))}
									</ol>
								</div>
							</Col>
							<Col xs={3}>
								<div className="border-class p-3">
									<h4 className="bold-text orange_color">{client_cmp_document_other}</h4>
									<ol>
										{documents.other.map(doc => (
											<li>
												<a key={doc.name} href={doc.url} target="_blank" rel="noreferrer">
													{doc.name}
												</a>
											</li>
										))}
									</ol>
								</div>
							</Col>
						</Row>
					</>
				)}
			</div>
			<ButtonToolbar className="modal__footer_project_detail">
				<Button color={'success'} onClick={onClose}>
					{common_btn_okay}
				</Button>
			</ButtonToolbar>
		</Modal>
	);
}

const StakeHolderContainer = styled.div`
	display: flex;
	gap: 1rem;
	padding: 5px;
`;

function ProjectData({ label, data }) {
	return (
		<ProjectDataContainer>
			<h5>{label} : </h5>
			<p>{data}</p>
		</ProjectDataContainer>
	);
}

const ProjectDataContainer = styled.div`
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid var(--clr-aexdo);
	padding-block: 5px;
	h5 {
		color: var(--clr-aexdo);
		font-weight: 700;
	}
	p {
		margin: 0;
	}
`;
